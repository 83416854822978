import { Avatar } from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";

import { useCompanyUsers } from "@/api/queries/users";
import { useMainStore } from "@/contexts/Store";

function UsersCell({ userIds }: { userIds: number[] }) {
  const {
    context: { companyID },
  } = useMainStore();
  const { data } = useCompanyUsers(Number(companyID), {
    view: "all",
  });
  const users = userIds.map(
    (userId) =>
      data?.data.find((u) => u.id === userId) || {
        id: 0,
        icon_color_index: 0,
        full_name: "Not Found",
        initials: "NF",
        is_active: false,
      },
  );

  return (
    <div className="tw-flex tw-items-center tw-gap-1.5 tw-overflow-hidden tw-font-semibold">
      {users.map((user) => (
        <Avatar
          key={user.id}
          size="md"
          colorIndex={user.icon_color_index}
          variant={user.is_active ? undefined : "pending"}
        >
          {user.initials}
        </Avatar>
      ))}
    </div>
  );
}

export default observer(UsersCell);
