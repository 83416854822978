import { PreviewTable } from "@themis/ui";
import React from "react";
import { useParams } from "react-router-dom";

import { useWorkspaceContracts } from "@/api/queries/contracts";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import LoadingWrapper from "@/components/loading-wrapper";
import { CONTRACTS_COLUMNS } from "@/features/accounts/config/contractsTable";
import AccountsHeader from "@/features/accounts/pages/AccountsHeader";

function ContractsContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-h-full tw-min-w-96 tw-flex-col tw-gap-4">
      {children}
    </div>
  );
}

function MessageContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-self-stretch tw-rounded-md tw-border tw-border-dashed tw-border-primaryDim-300 tw-px-3 tw-py-2">
      {children}
    </div>
  );
}

export function AllContracts() {
  const { workspace_id } = useParams<{ workspace_id: string }>();

  const { isLoading, data, error } = useWorkspaceContracts(
    Number(workspace_id),
  );

  return (
    <PageLayout>
      <Header title="Contracts" />
      <AccountsHeader />
      <PageContent>
        <ContractsContainer>
          <MessageContainer>
            <p className="tw-text-sm tw-font-semibold tw-text-primaryDim-300">
              A table of all Contracts that are attached to your Accounts in
              Themis
            </p>
          </MessageContainer>
          <LoadingWrapper
            loadingLayout="table"
            loading={isLoading}
            errorText={
              error ? "There was an issue loading the contracts data." : ""
            }
          >
            <PreviewTable rows={data?.data} columns={CONTRACTS_COLUMNS} />
          </LoadingWrapper>
        </ContractsContainer>
      </PageContent>
    </PageLayout>
  );
}
