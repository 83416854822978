import { Label, LinkButton, PreviewTable, useToast } from "@themis/ui";
import React from "react";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import {
  Account,
  CreateAccountMutationRequest,
  ModifyAccountRequest,
} from "@/api";
import { useCreateAccount, useUpdateAccount } from "@/api/queries/accounts";
import { InfoPreviewSection } from "@/components/InfoPreviewSection";
import DocumentUploadButton from "@/features/accounts/components/Documents/DocumentUploadButton";

import { ACCOUNTS_LIST_PATH } from "..";
import {
  AccountInfoForm,
  AccountInfoSchema,
} from "../../components/AccountInfo/AccountInfoForm";
import { ActiveTag } from "../../components/ActiveTag";
import { PREVIEW_CHILD_ACCOUNTS_COLUMNS } from "../../config/accountsTable";
import { PREVIEW_CONTACTS_COLUMNS } from "../../config/contactsTable";
import { PREVIEW_CONTRACTS_COLUMNS } from "../../config/contractsTable";
import { PREVIEW_DOCUMENTS_COLUMNS } from "../../config/documentsTable";
import regex from "../../config/regex";
import { ACCOUNT_DETAILS_ROUTES, AccountLocationState } from "./AccountDetails";

function FormContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-min-w-96 tw-flex-col tw-gap-8 tw-px-32">
      {children}
    </div>
  );
}

function StatusContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5">
      {children}
    </div>
  );
}

function AccountInfoContainer({ children }: { children: React.ReactNode }) {
  return <div className="tw-flex tw-flex-col tw-gap-2">{children}</div>;
}

export function AccountInfo({ account }: { account?: Account }) {
  const toast = useToast();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { workspace_id, accountId } = useParams<{
    workspace_id: string;
    accountId: string;
  }>();

  const isNewAccount = !account;

  const {
    search,
    pathname,
    state: locationState,
  } = useLocation<AccountLocationState>();
  const searchParams = new URLSearchParams(search);
  /**
   * If exists, redirect to the specified URL after saving the account
   * (e.g. when creating an account from the vendor page, redirect back to the vendor page)
   */
  const redirectTo = searchParams.get("redirectTo");

  const accountsListPath = generatePath(ACCOUNTS_LIST_PATH, {
    workspace_id,
  });

  const { mutateAsync: createAccount } = useCreateAccount({
    workspaceId: Number(workspace_id),
  });

  const { mutateAsync: updateAccount } = useUpdateAccount({
    workspaceId: Number(workspace_id),
    accountId: Number(accountId),
  });

  function getFromLocation(newAccountId?: number) {
    if (isNewAccount) {
      return redirectTo
        ? `${redirectTo}?account_id=${newAccountId}`
        : accountsListPath;
    }

    return redirectTo || locationState?.from || accountsListPath;
  }

  async function handleCreateAccount(
    newAccountData: CreateAccountMutationRequest,
  ) {
    try {
      const response = await createAccount(newAccountData);

      history.push(getFromLocation(response.data?.id));

      toast({
        content: `Account "${newAccountData.account.name}" has been added!`,
        variant: "success",
      });
    } catch {
      toast({
        content: "Something went wrong. Could not create account.",
        variant: "error",
      });
    }
  }

  async function handleUpdateAccount(newAccountData: ModifyAccountRequest) {
    try {
      await updateAccount(newAccountData);

      history.push(getFromLocation());

      toast({
        content: `Account "${newAccountData.name}" has been updated!`,
        variant: "success",
      });
    } catch {
      toast({
        content: "Something went wrong. Could not update account.",
        variant: "error",
      });
    }
  }

  function handleSubmitForm(values: AccountInfoSchema) {
    const newAccountData = {
      ...values,
      // API takes array of types but we only allow a single selection
      account_types: values.account_types ? [values.account_types] : undefined,
    };

    // API only accepts signed_id provided by direct upload so we remove logo from payload if its not
    if (regex.URL.test(String(newAccountData.logo)) || !newAccountData.logo) {
      delete newAccountData.logo;
    }

    if (!account) {
      handleCreateAccount({ account: newAccountData });
    } else {
      handleUpdateAccount(newAccountData);
    }
  }

  return (
    <FormContainer>
      <div className="tw-flex tw-flex-row tw-gap-2">
        <StatusContainer>
          <Label
            id="status-label"
            className="tw-font-semibold tw-text-neutral-500"
          >
            Status
          </Label>
          <ActiveTag
            aria-labelledby="status-label"
            isActive={account?.status === "active"}
          />
        </StatusContainer>
        <StatusContainer>
          <Label className="tw-font-semibold tw-text-neutral-500">
            Parent Account
          </Label>
          <p className="tw-text-neutral-300">
            {account?.parent_account_name || "N/A"}
          </p>
        </StatusContainer>
      </div>
      <AccountInfoContainer>
        <h2 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
          Account Info
        </h2>
        <AccountInfoForm
          defaultValues={account}
          contacts={account?.contacts}
          onSubmit={handleSubmitForm}
        />
      </AccountInfoContainer>
      <InfoPreviewSection
        title="Contacts"
        disabled={isNewAccount}
        count={account?.contacts?.length}
        actions={
          <>
            <LinkButton
              to={{
                pathname: `${url}${ACCOUNT_DETAILS_ROUTES.contacts}/new`,
                state: { from: pathname },
              }}
              size="sm"
            >
              Add
            </LinkButton>
            <LinkButton
              to={`${url}${ACCOUNT_DETAILS_ROUTES.contacts}`}
              color="tertiary"
              size="sm"
            >
              See More
            </LinkButton>
          </>
        }
        content={
          Boolean(account?.contacts?.length) && (
            <PreviewTable
              responsive
              columns={PREVIEW_CONTACTS_COLUMNS}
              rows={account?.contacts?.slice(0, 4)}
            />
          )
        }
      />
      <InfoPreviewSection
        title="Contracts"
        disabled={isNewAccount}
        count={account?.contracts?.length}
        actions={
          <>
            <LinkButton
              to={{
                pathname: `${url}${ACCOUNT_DETAILS_ROUTES.contracts}/new`,
                state: { from: pathname },
              }}
              size="sm"
            >
              Add
            </LinkButton>
            <LinkButton
              to={`${url}${ACCOUNT_DETAILS_ROUTES.contracts}`}
              color="tertiary"
              size="sm"
            >
              See More
            </LinkButton>
          </>
        }
        content={
          Boolean(account?.contracts?.length) && (
            <PreviewTable
              responsive
              columns={PREVIEW_CONTRACTS_COLUMNS}
              rows={account?.contracts?.slice(0, 4)}
            />
          )
        }
      />
      <InfoPreviewSection
        title="Sub Accounts"
        disabled={isNewAccount}
        count={account?.child_accounts?.length}
        countDescription="Account"
        actions={
          <>
            <LinkButton
              to={{
                pathname: `${url}${ACCOUNT_DETAILS_ROUTES.childAccounts}/new`,
                state: { from: pathname },
              }}
              size="sm"
            >
              Add
            </LinkButton>
            <LinkButton
              to={`${url}${ACCOUNT_DETAILS_ROUTES.childAccounts}`}
              color="tertiary"
              size="sm"
            >
              See More
            </LinkButton>
          </>
        }
        content={
          Boolean(account?.child_accounts?.length) && (
            <PreviewTable
              responsive
              columns={PREVIEW_CHILD_ACCOUNTS_COLUMNS}
              rows={account?.child_accounts?.slice(0, 4)}
            />
          )
        }
      />
      <InfoPreviewSection
        title="Documents"
        disabled={isNewAccount}
        actions={<DocumentUploadButton />}
        count={account?.documents?.length}
        content={
          Boolean(account?.documents?.length) && (
            <PreviewTable
              responsive
              columns={PREVIEW_DOCUMENTS_COLUMNS}
              rows={account?.documents}
            />
          )
        }
      />
    </FormContainer>
  );
}
