import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { MainStore } from "../Main";

export default class HomeStore {
  mainStore: MainStore;

  sections = [];
  hasLoaded = false;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      sections: observable,
      hasLoaded: observable,
      updateSections: action,
      setHasLoaded: action,
    });

    this.mainStore = mainStore;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceId' implicitly has an 'any' ty... Remove this comment to see the full error message
  async fetchSections(workspaceId) {
    this.setHasLoaded(false);

    try {
      const response = await legacyApi({
        method: "GET",
        url: `/api/react/report/workspace/${workspaceId}/dashboard_tiles`,
        headers: this.mainStore.getHeaders(),
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.updateSections([]);
      } else {
        // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
        this.updateSections([response.data]);
      }
    } catch (error) {
      this.updateSections([]);
      window.console.log(`"Home#fetchSections" error ${error}`);
    }
    this.setHasLoaded(true);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'hasLoaded' implicitly has an 'any' type... Remove this comment to see the full error message
  setHasLoaded(hasLoaded) {
    this.hasLoaded = hasLoaded;
  }

  updateSections(sections = []) {
    this.sections = [...sections];
  }

  // Store Helpers

  cleanup() {
    this.updateSections([]);
    this.setHasLoaded(false);
  }
}
