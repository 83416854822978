import orderBy from "lodash/orderBy";

import { AggregateScores } from "@/features/risk-assessment/types/assessment";
import {
  QuestionChoiceRead,
  QuestionRead,
  RiskType,
} from "@/features/risk-assessment/types/questionnaire";
import {
  RiskMethodology,
  RiskRating,
} from "@/features/risk-assessment/types/risk-methodology";

export function getDefinitionsFromScores(
  scores: AggregateScores,
  methodology: RiskMethodology,
) {
  if (!methodology) {
    return {
      overallRiskRating: undefined,
      inherentRiskRating: undefined,
      controlRiskRating: undefined,
      residualRiskRating: undefined,
    };
  }
  return {
    overallRiskRating: methodology.overall_risk_ratings.find(
      (rating) => rating.id === scores?.overall_rating_id,
    ),
    inherentRiskRating: methodology.inherent_risk_ratings.find(
      (rating) => rating.id === scores?.inherent_rating_id,
    ),
    controlRiskRating: methodology.control_risk_ratings.find(
      (rating) => rating.id === scores?.control_rating_id,
    ),
    residualRiskRating: methodology.residual_risks.find(
      (rating) => rating.id === scores?.residual_risk_id,
    ),
  };
}

export function getRatingForChoice(
  methodology: RiskMethodology,
  question: QuestionRead,
  choice?: QuestionChoiceRead,
): RiskRating | null {
  if (!choice) {
    return null;
  }

  let ratingOptions =
    question.risk_type === RiskType.CONTROL
      ? methodology.control_risk_ratings
      : methodology.inherent_risk_ratings;

  ratingOptions = orderBy(
    ratingOptions,
    (option) => option.higher_range,
    "asc",
  );
  const matchingRating = ratingOptions.find(
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    (rating) => choice.base_score <= rating.higher_range,
  );

  return matchingRating || ratingOptions[ratingOptions.length - 1];
}

// @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessment' implicitly has an 'any'... Remove this comment to see the full error message
export function calculateRiskRating(riskAssessment) {
  const levels = riskAssessment?.risk_levels || [];
  // @ts-expect-error TS(7006) FIXME: Parameter 'total' implicitly has an 'any' type.
  const score = riskAssessment?.risk_themes?.reduce((total, theme) => {
    const { risk_descriptions: descriptions } = theme;
    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    const index = descriptions.findIndex((item) => item.selected) + 1;
    const subTotal = (index / levels.length) * (theme.weighting / 100);

    return total + subTotal;
  }, 0);

  const parsedScore = Math.round(score * 100);
  const ratings = riskAssessment?.risk_assessment_ratings || [];
  // @ts-expect-error TS(7006) FIXME: Parameter 'rating' implicitly has an 'any' type.
  const correspondingLevel = ratings.find((rating) => {
    const { lower_range: lowerRange, higher_range: higherRange } = rating;
    return (
      lowerRange &&
      higherRange &&
      lowerRange <= parsedScore &&
      higherRange >= parsedScore
    );
  });

  return { correspondingLevel, score: parsedScore };
}
