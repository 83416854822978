import React from "react";

import { Button, Typography } from "../../Elements";
import DynamicSizeInput from "../../shared/DynamicSizeInput";

const PLACEHOLDER = "Enter the workspace name";
const DEFAULT_WIDTH = 335;
const MAX_LENGTH = 40;

type NameWorkspaceProps = {
  name: string;
  onNameChange: (name: string) => void;
  onContinue: () => void;
  onBack: () => void;
};

export const NameWorkspace = (props: NameWorkspaceProps) => (
  <div className="name-workspace">
    <DynamicSizeInput
      defaultWidth={DEFAULT_WIDTH}
      placeholder={PLACEHOLDER}
      onChange={props.onNameChange}
      value={props.name}
      maxLength={MAX_LENGTH}
    />
    <div className="name-workspace__suggestions-wrapper">
      <Typography label="Common examples:" />
      <ul className="name-workspace__suggestions">
        <li className="name-workspace__suggestion">Bank + Fintech</li>
        <li className="name-workspace__suggestion">Company X</li>
        <li className="name-workspace__suggestion">Due dilligence docs</li>
      </ul>
      <Typography label="*Max 40 characters" />
    </div>
    <div className="name-workspace__actions">
      <Button label="Back" theme="tertiary" onClick={props.onBack} />
      <Button
        label="Continue"
        onClick={props.onContinue}
        disabled={!props.name}
      />
    </div>
  </div>
);
