import { useQuery } from "@tanstack/react-query";

import { FeatureFlagResponse } from "@/stores/types/feature-flag-types";

import api from "../legacy/api";

export const FEATURE_FLAGS_QUERY_KEY = "feature_flags";
export function useFeatureFlags() {
  return useQuery({
    queryKey: [FEATURE_FLAGS_QUERY_KEY],
    queryFn: () =>
      api.get<FeatureFlagResponse>("/features/api/features", {
        baseURL: "/",
      }),
  });
}
