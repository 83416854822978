import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  DatePicker,
  FilePicker,
  FilePickerProps,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Switch,
  TextArea,
} from "@themis/ui";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import React, { useRef, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Contract } from "@/api";

import { zodDateCheck } from "../../utils";
import { ContractTermsSelect } from "./ContractTermsSelect";

const formSchema = z
  .object({
    renewal: z.boolean(),
    file: z.string().min(1, "Contract is required"),
    start_date: z
      .string({ required_error: "Start Date is required" })
      .refine(zodDateCheck),
    review_date: z
      .string({ required_error: "Review Date is required" })
      .refine(zodDateCheck),
    end_date: z
      .string({ required_error: "End Date is required" })
      .refine(zodDateCheck),
    terms: z.string().min(1, "Terms are required"),
    notes: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.start_date && data.end_date) {
        return new Date(data.start_date) < new Date(data.end_date);
      }
      return true;
    },
    { message: "End date must be after start date", path: ["end_date"] },
  );

type ContractInfoSchema = z.infer<typeof formSchema>;

function ContractInfoForm({
  onSubmit,
  contract,
}: {
  onSubmit: (values: ContractInfoSchema) => void;
  contract?: Contract;
}) {
  const contractFilePicker = useRef<HTMLButtonElement>(null);
  const contractToUpload = useRef<File>();

  const [contractFile, setContractFile] = useState<FilePickerProps["file"]>(
    contract?.file
      ? {
          name: contract?.file.file_name,
          url: contract?.file.file_url,
          type: contract?.file.content_type,
        }
      : undefined,
  );

  const form = useForm<ContractInfoSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      renewal: contract?.renewal ?? true,
      file: contract?.file?.signed_id || "",
      start_date: contract?.start_date,
      review_date: contract?.review_date,
      end_date: contract?.end_date,
      terms: contract?.terms || "",
      notes: contract?.notes || "",
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.handleSubmit(onSubmit)();
  };

  function handleUploadSuccess(signedIds: string[]) {
    if (!contractToUpload.current) {
      return;
    }

    contractFilePicker.current?.click();

    setContractFile(contractToUpload.current);
    form.setValue("file", signedIds[0]);

    contractToUpload.current = undefined;
  }

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit}
        className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-3"
      >
        <FormField
          required
          control={form.control}
          name="renewal"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Renewal Option</FormLabel>
              <FormControl>
                <div className="tw-flex tw-justify-center tw-rounded-md tw-bg-neutral-50 tw-py-1.5">
                  <Switch
                    checked={field.value}
                    onValueChange={(value) => field.onChange(value)}
                    size="sm"
                    labelType="tag"
                    left={{ label: "Manual", value: false }}
                    right={{ label: "Automatic", value: true }}
                  />
                </div>
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="file"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Contract</FormLabel>
              <FormControl>
                <DirectUploadProvider
                  onSuccess={handleUploadSuccess}
                  render={({ handleUpload, uploads }) => (
                    <FilePicker
                      ref={contractFilePicker}
                      className={classNames({
                        "tw-bg-warning-50 tw-text-warning-300":
                          fieldState.error,
                      })}
                      isLoading={["uploading", "waiting"].includes(
                        uploads[0]?.state,
                      )}
                      percentage={Math.round(uploads[0]?.progress) || 0}
                      onSelectFile={(selectedFile) => {
                        // DirectUploadProvider does not return file onSuccess so we have to save a ref of it to use it in handleUploadSuccess
                        contractToUpload.current = selectedFile;
                        handleUpload([selectedFile]);
                      }}
                      onRemoveFile={() => {
                        setContractFile(undefined);
                        field.onChange("");
                      }}
                      file={contractFile}
                    />
                  )}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="start_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Contract Start Date</FormLabel>
              <FormControl>
                <DatePicker
                  closeOnDateSelect
                  calendarProps={{
                    mode: "single",
                    // Need to parseISO to avoid accidentally moving a day
                    // forward/backward because new Date() will convert to UTC timezone
                    selected: field.value ? parseISO(field.value) : undefined,
                    onSelect: (date) =>
                      date && field.onChange(format(date, "yyyy-MM-dd")),
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="review_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Contract Review Date</FormLabel>
              <FormControl>
                <DatePicker
                  closeOnDateSelect
                  calendarProps={{
                    mode: "single",
                    selected: field.value ? parseISO(field.value) : undefined,
                    onSelect: (date) =>
                      date && field.onChange(format(date, "yyyy-MM-dd")),
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="end_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Contract End Date</FormLabel>
              <FormControl>
                <DatePicker
                  closeOnDateSelect
                  calendarProps={{
                    mode: "single",
                    selected: field.value ? parseISO(field.value) : undefined,
                    onSelect: (date) =>
                      date && field.onChange(format(date, "yyyy-MM-dd")),
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="terms"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Terms of Termination Notice</FormLabel>
              <FormControl>
                <ContractTermsSelect
                  field={field}
                  fieldError={fieldState.error}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Termination Details</FormLabel>
              <FormControl>
                <TextArea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          className="tw-col-span-2 tw-mt-5 tw-place-self-end"
          type="submit"
        >
          Save Contract
        </Button>
      </form>
    </Form>
  );
}

export { ContractInfoForm, type ContractInfoSchema };
