import React, { useEffect, useRef, useState } from "react";

import { useAddTextareaHeightBehavior } from "../../../../detailView/helpers";

type Props = {
  className?: string;
  dataPrivate?: string;
  dataTestID?: string;
  defaultValue?: string;
  disabled?: boolean;
  maxLength?: number;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyDown?: (...args: any[]) => any;
  placeholder?: string;
  rows?: string;
  value?: string;
};

function Textarea({
  disabled,
  name,
  className,
  rows,
  onChange,
  onBlur,
  onKeyDown,
  dataTestID,
  dataPrivate,
  defaultValue,
  value,
  placeholder,
  maxLength,
}: Props) {
  // Refs
  const textareaRef = useRef();

  // State
  const [refToTextarea, setRefToTextarea] = useState();

  useEffect(() => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'MutableRefObject<undefined>' is ... Remove this comment to see the full error message
    setRefToTextarea(textareaRef);
  }, [textareaRef]);

  return (
    <textarea
      disabled={disabled}
      name={name}
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      rows={rows}
      className={className}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      data-testid={dataTestID}
      data-private={dataPrivate}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      maxLength={maxLength}
      // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
      ref={textareaRef}
      // @ts-expect-error TS(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      onInput={useAddTextareaHeightBehavior(refToTextarea)}
      readOnly={disabled}
    />
  );
}

export default Textarea;
