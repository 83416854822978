import { Avatar } from "@themis/ui";
import React from "react";

import { User } from "@/api";

export function FindingOwnerCell({ owner }: { owner: User }) {
  return (
    <div className="tw-flex tw-items-center tw-gap-1.5 tw-overflow-hidden">
      <Avatar size="lg" colorIndex={owner.icon_color_index}>
        {owner.initials}
      </Avatar>
      <p className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-font-semibold">
        {owner.full_name}
      </p>
    </div>
  );
}
