import "./styles.scss";

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";

import { Flex, IconButton, MiniTag, Typography } from "@/components/Elements";
import {
  formatTimeAfterLongDate,
  stringToDateAndTimeSSSZ,
} from "@/components/helpers/DateFormatters";
import EditCommentReply from "@/components/settings/notificationsCenter/tasks/EditCommentReply";
import { ReplyHeader } from "@/components/settings/notificationsCenter/tasks/ReplyHeader";
import UserMentionsInput from "@/components/table/shared/UserMentionsInput";
import { UsersCircle } from "@/components/users-circle";
import { useMainStore } from "@/contexts/Store";

type Props = {
  closeSlideMenu: React.DOMAttributes<HTMLButtonElement>["onClick"];
  taskID: number;
  workspaceID: number;
  /**
   * Used in task detail page as the cleanup of private users list
   * would result in the loading indicator displaying for the private user.
   */
  skipCleanupOfPrivateUsers?: boolean;
};

function ImmutableTaskComment({
  taskID,
  closeSlideMenu,
  workspaceID,
  skipCleanupOfPrivateUsers,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { canAddComments } = mainStore.userPermissions;

  useEffect(() => {
    if (task) {
      mainStore.comments.getPrivateUsers(task.module_workspace_id);
    }

    if (!skipCleanupOfPrivateUsers) {
      return () => {
        mainStore.comments.cleanup();
      };
    }
  }, []);

  // State
  const [commentInput, setCommentInput] = useState("");
  const [commentEditId, setCommentEditId] = useState<number | null>(null);

  // Variables
  const task = mainStore.tasks.allTasks?.find(
    (userTask) => userTask.id === taskID,
  );

  // Hooks
  if (!task) {
    return null;
  }

  const formattedTaskCreateDate = formatTimeAfterLongDate(
    stringToDateAndTimeSSSZ(task.created_at),
    { removeAt: true, monthFormat: "short" },
  );

  // Functions
  async function handleCreateReply(
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (event.key === "Enter" && task) {
      await mainStore.comments.create(
        { content: commentInput, original_comment_id: taskID },
        {
          commentableID: task.commentable_id,
          commentableType: task.commentable_type,
          moduleWorkspaceID: task.module_workspace_id,
        },
      );
      await mainStore.tasks.myTasks({ workspace_id: workspaceID });
      setCommentInput("");
    }
  }

  async function handleDeleteReply(id: number) {
    await mainStore.comments.delete(id, task?.module_workspace_id);
    await mainStore.tasks.myTasks({ workspace_id: workspaceID });
  }

  function handleEditCommentClick(id: number) {
    setCommentEditId(id);
  }

  const renderTaskDescription = (
    <div className="task-comments-description">
      <div className="task-name comments-content">
        <MentionsInput value={task.content} className="mentions" disabled>
          <Mention data={[]} trigger="@" />
        </MentionsInput>
      </div>
    </div>
  );

  const renderReplies = task.replies?.map((reply) => {
    return (
      <div className="reply-comment-container" key={reply.id}>
        <ReplyHeader
          comment={reply}
          onConfirmDelete={handleDeleteReply}
          onEditCommentClick={handleEditCommentClick}
        />
        <EditCommentReply
          key={[commentEditId, reply.content].join()}
          isPrivate={task.private}
          workspaceId={workspaceID}
          reply={reply}
          replyEditId={commentEditId}
          setReplyEditId={setCommentEditId}
        />
      </div>
    );
  });

  return (
    <div className="task-comments-slide-menu">
      <div className="task-comments-slide-menu__record-header">
        <Flex alignCenter columnGap={10}>
          <Typography
            type="h3"
            size="md"
            weight="bold"
            label="Comments"
            color="generalMidnightDark"
          />
          <MiniTag
            label={task.global_id}
            fontSize="xs"
            theme="navy"
            fontWeight="medium"
          />
        </Flex>
        <IconButton
          iconSize="md"
          size="sm"
          transparent
          icon="close"
          onClick={closeSlideMenu}
        />
      </div>
      <div className="task-comments-slide-menu__comments-container">
        <div className="task-comments-slide-menu__comment-header">
          <UsersCircle
            initials={task.author.initials}
            iconColorIndex={task.author.icon_color_index}
          />
          <Flex column>
            <Typography
              label={task.author.full_name}
              weight="semiBold"
              color="generalMidnightDark"
              size="sm"
            />
            <Typography
              label={formattedTaskCreateDate}
              weight="semiBold"
              color="extrasBlueGrayDarker"
              size="xs"
            />
          </Flex>
        </div>
        {renderTaskDescription}
        <div className="comment-block">{renderReplies}</div>
        {canAddComments && (
          <div className="comments-main-content">
            <UserMentionsInput
              editable
              privateUsers={task.private}
              content={commentInput}
              onKeyDown={handleCreateReply}
              onChange={(event) => setCommentInput(event.target.value)}
              placeholder="Reply"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(ImmutableTaskComment);
