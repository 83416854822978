import { matchesProperty } from "lodash";

import {
  CreateEventNotificationRuleRequest,
  EventNotificationsListResponse,
} from "@/api/legacy/event-notifications/types";
import { EventNotificationFormValues } from "@/features/notifications/pages/single/event/types";

export function formatResponseToForm(
  notificationId: string,
  response: EventNotificationsListResponse,
): EventNotificationFormValues {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'EventNotif... Remove this comment to see the full error message
    data: { events, recipients },
    // @ts-expect-error TS(2339) FIXME: Property 'enabled' does not exist on type 'EventNo... Remove this comment to see the full error message
    enabled,
  } = response.event_notification_rules.find(
    matchesProperty("id", Number(notificationId)),
  );

  return { enabled, recipients, event: events[0] };
}

export function parseFormForRequest(
  data: EventNotificationFormValues,
): CreateEventNotificationRuleRequest {
  const { enabled, event, recipients } = data;

  return {
    enabled,
    data: { recipients, events: [event] },
  };
}
