import "./question-choice.scss";

import classNames from "classnames";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { Icon, IconButton, Typography } from "@/components/Elements";
import Dropdown, {
  DropdownOption,
} from "@/components/Elements/Dropdown/Dropdown";
import type { IconName } from "@/components/Elements/Icon";
import ModalWrapper from "@/components/shared/ModalWrapper";
import { ConfirmationPopup } from "@/modules/shared/confirmation-popup/ConfirmationPopup";

import { SELECTABLE_QUESTION_TYPES } from "../../../../config";
import {
  QuestionType,
  QuestionChoice as TQuestionChoice,
} from "../../../../types/questionnaire";

type QuestionChoiceProps = {
  option: TQuestionChoice;
  icon: IconName;
  disabled: boolean;
  className: string;
  onAddTriggerQuestion?: (questionType: QuestionType) => void;
  onNameChange: (name: string) => void;
  onRatingChange: (id: string) => void;
  onRemove: () => void;
  onClickFlag: () => void;
  isFlagged?: boolean;
  riskRatingOptions: DropdownOption[];
};

function QuestionChoice(props: QuestionChoiceProps) {
  const riskRating = props.riskRatingOptions.find(
    (dropdownOption) =>
      (props.option.base_score || "NaN").toString() === dropdownOption.id,
  );
  const [isTriggerPopupOpen, setIsTriggerPopupOpen] = useState(false);

  const handleAddTriggerQuestion = (questionType: QuestionType) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    props.onAddTriggerQuestion(questionType);
    setIsTriggerPopupOpen(false);
  };

  const getPointForChoice = (optionId: string): string => {
    if (optionId === "NaN") {
      return "N/A";
    }
    return optionId;
  };

  return (
    <div
      className={classNames("questionnaire-question-choice", props.className)}
    >
      <Icon name={props.icon} className="questionnaire-question-choice__icon" />
      <div className="questionnaire-question-choice__text-wrapper">
        <input
          type="text"
          defaultValue={props.option.text}
          disabled={props.disabled}
          onBlur={(e) => props.onNameChange(e.target.value)}
          placeholder="- Choice -"
        />
        {props.onAddTriggerQuestion && (
          <div className="questionnaire-question-choice__add-trigger-wrapper">
            <Popup
              trigger={
                <div>
                  <IconButton
                    icon="plus"
                    className="questionnaire-question-choice__add-trigger"
                  />
                </div>
              }
              position="bottom left"
              keepTooltipInside
              onOpen={() => setIsTriggerPopupOpen(true)}
              onClose={() => setIsTriggerPopupOpen(false)}
              open={isTriggerPopupOpen}
            >
              <ModalWrapper width={200}>
                <ul className="questionnaire-question-choice__add-trigger-options">
                  {SELECTABLE_QUESTION_TYPES.map((question) => (
                    <li
                      key={question.type}
                      onClick={() => handleAddTriggerQuestion(question.type)}
                    >
                      {question.name}
                    </li>
                  ))}
                </ul>
              </ModalWrapper>
            </Popup>
          </div>
        )}
      </div>
      <Dropdown
        className="questionnaire-question-choice__dropdown"
        options={props.riskRatingOptions}
        selected={
          riskRating && {
            ...riskRating,
            element: (
              <div className="questionnaire-question-choice__selected-rating">
                {getPointForChoice(riskRating.id.toString())}
                <Typography label="Rating" />
              </div>
            ),
          }
        }
        onChange={(selected) => props.onRatingChange(selected.id.toString())}
      />

      <div className="questionnaire-question-choice__actions-container">
        <IconButton
          className={classNames({ active: props.isFlagged })}
          active={props.isFlagged}
          icon="flagLinear"
          onClick={props.onClickFlag}
          data-tooltip-id="tooltip"
          data-tooltip-place="left"
          data-tooltip-content="Display a red flag at the review stage if this answer is chosen."
        />
        <ConfirmationPopup
          text="Are you sure you want to delete this option?"
          onConfirm={props.onRemove}
          trigger={<IconButton disabled={props.disabled} icon="trash" />}
        />
      </div>
    </div>
  );
}

export default QuestionChoice;
