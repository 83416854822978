import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { useMainStore } from "@/contexts/Store";

import closeIcon from "../../../images/table-image/icon/close-black-icon.svg";

type Props = {
  checkboxSelection?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emailSlideClose?: (...args: any[]) => any;
  moduleWorkspaceID?: number;
};

function BulkSubmitSlideout({
  emailSlideClose,
  moduleWorkspaceID,
  checkboxSelection,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { user } = mainStore.users;
  const { current: finraContact } = mainStore.finraContacts;

  // State
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [fullName, setFullName] = useState(
    // @ts-expect-error TS(2339) FIXME: Property 'full_name' does not exist on type '{}'.
    finraContact.full_name || user.full_name,
  );
  // @ts-expect-error TS(2339) FIXME: Property 'phone' does not exist on type '{}'.
  const [phone, setPhone] = useState(finraContact.phone || user.phone || "");

  // Hooks
  useEffect(() => {
    const [firstName, lastName] = (fullName || "").split(" ");
    const hasRecordsSelected =
      mainStore.dynamicTable.selectedRecordVersionIDs.length > 0;
    const hasValidFullName =
      (fullName?.length > 0 &&
        firstName?.length > 0 &&
        lastName?.length > 0 &&
        lastName?.length < 30) === true;
    const hasValidPhoneNumber =
      (phone?.length >= 7 && phone?.length < 20 && /^\d+$/.test(phone)) ===
      true;
    const disableButton = !(
      hasRecordsSelected &&
      hasValidFullName &&
      hasValidPhoneNumber
    );

    setButtonDisabled(disableButton);
  }, [mainStore.dynamicTable.selectedRecordVersionIDs, fullName, phone]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleInputChange(event) {
    event.preventDefault();

    const { name, value } = event.target;

    if (name === "phone") {
      setPhone(value);
    }

    if (name === "fullName") {
      setFullName(value);
    }
  }

  function onSelectAllReadyForSubmission() {
    mainStore.finraComplaints.selectComplaintsReadyForSubmission();
  }

  function onSend() {
    // Bulk submit FINRA complaints
    if (moduleWorkspaceID) {
      const params = {
        record_version_ids: mainStore.dynamicTable.selectedRecordVersionIDs,
      };

      if (fullName && phone) {
        mainStore.finraComplaints.bulkSubmitToFINRA(params, fullName, phone);
      }

      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      emailSlideClose();
    }
  }

  const buttonClasses = classNames({ active: !buttonDisabled });

  const fullNameClasses = classNames({
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    "full-name-initials": user.full_name.length > 0,
    active: fullName,
  });
  const phoneClasses = classNames({ active: phone && phone.length > 0 });

  return (
    <div className="table-dropdown table-email-dropdown">
      <div className="email-dropdown-head">
        <h3>Submit in Bulk</h3>
        <div onClick={emailSlideClose}>
          <img
            src={closeIcon}
            alt="close-icon"
            data-testid="close-submit-bulk"
          />{" "}
        </div>
      </div>
      {checkboxSelection && (
        <>
          <hr />
          <div className="initial-text-paragraph">
            Select complaints you want to submit by checking the checkboxes on
            the left
          </div>
          <p style={{ textAlign: "center", marginBottom: "10px" }}>or</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              className={classNames({ active: true })}
              style={{ marginBottom: "12px", marginTop: "0px" }}
              onClick={onSelectAllReadyForSubmission}
            >
              Select All Ready for Submission
            </button>
          </div>
          <p>
            Please fill in the following contact info if they are missing /
            not-updated.
          </p>
          <p>A user with a full first & last name is required.</p>
        </>
      )}
      {mainStore.dynamicTable.selectedRecordVersionIDs.length > 0 && (
        <>
          <hr />
          <div className="table-email-select">
            <div className="table-email-block">
              <div className="table-email-element">
                {mainStore.dynamicTable.selectedRecordVersionIDs.length}{" "}
                Complaints Selected
              </div>
            </div>
          </div>
        </>
      )}
      <hr />
      <div className="email-dropdown-wrap">
        <div className="complaints-submit-form">
          <label className="full-name">
            {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
            {user.initials.length > 0 && <span>{user.initials}</span>}

            <input
              type="text"
              autoComplete="off"
              name="fullName"
              className={fullNameClasses}
              value={fullName}
              onChange={handleInputChange}
              placeholder="Full name"
            />
          </label>

          <input type="text" name="email" disabled value={user.email} />

          <input
            type="phone"
            autoComplete="off"
            name="phone"
            className={phoneClasses}
            value={phone}
            onChange={handleInputChange}
            placeholder="Contact number"
          />

          <button
            className={buttonClasses}
            disabled={buttonDisabled}
            onClick={onSend}
          >
            Submit
          </button>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default observer(BulkSubmitSlideout);
