import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import * as EventNotificationsAPI from "@/api/legacy/event-notifications";
import { Flex, Typography } from "@/components/Elements";
import LoadingWrapper from "@/components/loading-wrapper";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import { useMainStore } from "@/contexts/Store";
import {
  MODULE_NOTIFICATIONS_BASE_PATH,
  MODULE_NOTIFICATIONS_CREATE_PATH,
  MODULE_NOTIFICATIONS_EDIT_PATH,
  MODULE_NOTIFICATIONS_TAB_PATH,
} from "@/features/notifications/pages/constants";
import { initialCreateValues } from "@/features/notifications/pages/single/event/constants";
import {
  formatResponseToForm,
  parseFormForRequest,
} from "@/features/notifications/pages/single/event/helpers";
import { EventNotificationFormValues } from "@/features/notifications/pages/single/event/types";
import {
  ModuleNotificationLocationState,
  ModuleNotificationParams,
} from "@/features/notifications/types/types";
import { useLoadableData } from "@/hooks/useLoadableData";

import { NotificationDetailsHeader } from "../../../components/header";
import NotificationRecipients from "../../../components/recipients";
import RightActionBar from "../../../components/right-action-bar";
import SelectField from "../../../components/select-field";

function EventNotificationsSinglePage({
  moduleWorkspaceID,
}: {
  moduleWorkspaceID: number;
}) {
  const mainStore = useMainStore();
  const history = useHistory();
  const location = useLocation<ModuleNotificationLocationState>();
  const params = useParams<ModuleNotificationParams>();
  const createMatch = useRouteMatch(MODULE_NOTIFICATIONS_CREATE_PATH);

  const eventNotifications = useLoadableData({
    request: () =>
      EventNotificationsAPI.getEventNotifications(moduleWorkspaceID),
    enabled: !!moduleWorkspaceID,
    onError: () =>
      "There was an issue loading the notifications data. Please refresh to try again.",
  });

  const { control, handleSubmit, reset, formState } =
    useForm<EventNotificationFormValues>({
      defaultValues: initialCreateValues,
    });

  useEffect(() => {
    if (eventNotifications.data && !createMatch) {
      reset(
        formatResponseToForm(params.notificationId, eventNotifications.data),
      );
    }
  }, [eventNotifications.data]);

  function handleDelete() {
    return EventNotificationsAPI.deleteEventNotification(
      moduleWorkspaceID,
      params.notificationId,
    );
  }

  function handleCreate(data: EventNotificationFormValues) {
    return EventNotificationsAPI.createEventNotification(
      moduleWorkspaceID,
      parseFormForRequest(data),
    );
  }

  function handleUpdate(data: EventNotificationFormValues) {
    return EventNotificationsAPI.updateEventNotification(
      moduleWorkspaceID,
      params.notificationId,
      parseFormForRequest(data),
    );
  }

  const onValid: SubmitHandler<EventNotificationFormValues> = async (data) => {
    try {
      const response = await (createMatch
        ? handleCreate(data)
        : handleUpdate(data));

      reset({}, { keepValues: true });

      mainStore.toast.setInfoText(
        `Event Notification was successfully ${
          createMatch ? "created" : "updated"
        }.`,
      );

      if (createMatch) {
        history.push(
          generatePath(MODULE_NOTIFICATIONS_EDIT_PATH, {
            ...params,
            notificationId: response.event_notification_rule.id,
          }),
          location.state,
        );
      }
    } catch (error) {
      mainStore.toast.setErrorText(
        `An unexpected error occurred while ${
          createMatch ? "saving" : "updating"
        } the notification.`,
      );
      window.console.log(error);
    }
  };

  const onInvalid: SubmitErrorHandler<EventNotificationFormValues> = () => {
    mainStore.toast.setErrorText(
      "There is something not right! Please check if all necessary fields have been filled properly!",
    );
  };

  return (
    <DashboardContent>
      <form
        onSubmit={handleSubmit(onValid, onInvalid)}
        className="notification-details--form"
      >
        <DashboardHeader
          title={`${createMatch ? "Add New" : "Edit"} Event Notification`}
          onBackClick={() => {
            history.push(
              generatePath(
                location.state?.fromAllTab
                  ? MODULE_NOTIFICATIONS_BASE_PATH
                  : MODULE_NOTIFICATIONS_TAB_PATH,
                {
                  ...params,
                },
              ),
            );
          }}
          RightActionBar={
            <RightActionBar
              isDirty={formState.isDirty}
              onDelete={handleDelete}
            />
          }
        />
        <DashboardContentWrapper>
          <LoadingWrapper
            loadingLayout="small-table"
            errorText={eventNotifications.errorText}
            loading={eventNotifications.loading}
          >
            {eventNotifications.data && (
              <Flex column className="notification-details">
                <NotificationDetailsHeader control={control} name="enabled" />
                <Flex column rowGap={12} className="notification-details--box">
                  <Flex alignCenter columnGap={12}>
                    <Typography
                      label="If"
                      size="md"
                      color="generalMidnightDark"
                    />
                    <SelectField
                      control={control}
                      name="event"
                      moduleIcon
                      placeholder="- This happens -"
                      rules={{ required: true }}
                      options={eventNotifications.data.events}
                    />
                    <Typography
                      label=", then send an email."
                      size="md"
                      color="generalMidnightDark"
                    />
                  </Flex>
                </Flex>
                <NotificationRecipients
                  name="recipients"
                  control={control}
                  columnOptions={eventNotifications.data.recipients.columns}
                />
              </Flex>
            )}
          </LoadingWrapper>
        </DashboardContentWrapper>
      </form>
    </DashboardContent>
  );
}

export default observer(EventNotificationsSinglePage);
