import { Avatar } from "@themis/ui";
import React from "react";

import { Contact } from "@/api";

export function AccountContactCell({
  contact,
  display,
}: {
  contact: Contact;
  display: "name" | "email";
}) {
  return (
    <div className="tw-flex tw-items-center tw-gap-1.5 tw-overflow-hidden">
      {display === "name" && (
        <Avatar size="lg" colorIndex={contact.id}>
          {contact.initials}
        </Avatar>
      )}
      <p className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-font-semibold">
        {display === "name" ? contact.full_name : contact.email}
      </p>
    </div>
  );
}
