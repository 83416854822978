import "./GroupScoring/create-scoring-group-menu.scss";

import classNames from "classnames";
import React, { useState } from "react";

import { QuestionnaireAPI } from "@/api/legacy/risk-assessment";
import { Button, Icon } from "@/components/Elements";
import TextInput from "@/components/form/TextInput";
import { getWorkspaceLogo } from "@/components/helpers/getWorkspaceLogo";
import { useMainStore } from "@/contexts/Store";
import { Workspace } from "@/stores/types/workspace-types";

interface PartnerSendToCwMenuProps {
  questionnaireId: number;
  onClose: () => void;
}

function PartnerSendToCwMenu(props: PartnerSendToCwMenuProps) {
  const mainStore = useMainStore();
  const { moduleWorkspaceID } = mainStore.context;
  const workspaces = mainStore.workspaces.list.filter(
    (workspace) => !workspace.is_archived && !workspace.is_internal,
  );

  const [filter, setFilter] = useState("");
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>();

  const handleSelectChange = (workspace: Workspace) => {
    setSelectedWorkspace(workspace);
  };

  const submit = async () => {
    if (!moduleWorkspaceID && selectedWorkspace) {
      return;
    }
    try {
      await QuestionnaireAPI.sendQuestionnaireToCW(props.questionnaireId, [
        selectedWorkspace!.id,
      ]);
      mainStore.toast.setInfoText(
        `Questionnaire successfully sent to ${
          selectedWorkspace!.name
        } Workspace`,
      );
    } catch {
      mainStore.toast.setErrorText("Something went wrong");
    }

    props.onClose();
  };
  return (
    <div className="create-scoring-group-menu">
      <div className="section title">
        <p>Send to Collaborative Workspace</p>
        <Icon name="close" size="lg" onClick={() => props.onClose()} />
      </div>
      <div className="section-no-border group-name-input">
        <p>Select 1 workspace to share this questionnaire</p>
        <TextInput
          placeholder="- Search -"
          name="filterInput"
          inputRef={null}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <div className="section item-select">
        {workspaces?.length === 0 ? (
          <p className="emptystate">
            You do not have any available collaborative workspaces.
          </p>
        ) : (
          workspaces
            ?.filter((ws) =>
              ws.name.toLowerCase().includes(filter.toLowerCase()),
            )
            .map((ws) => (
              <label
                key={`cw-radio-select-${ws.id}`}
                className={classNames("radio", {
                  "option-selected": ws.id === selectedWorkspace?.id,
                })}
              >
                <input
                  name="radio"
                  type="radio"
                  onChange={() => handleSelectChange(ws!)}
                />
                <span>{ws.name}</span>
                <img
                  src={getWorkspaceLogo(ws).logo}
                  alt="logo"
                  className="workspace-row__workspace-logo item-logo"
                />
              </label>
            ))
        )}
      </div>
      <div className="section">
        <Button
          label="Confirm"
          disabled={!selectedWorkspace}
          onClick={submit}
        />
      </div>
    </div>
  );
}

export default PartnerSendToCwMenu;
