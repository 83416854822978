import React from "react";
import { NavLink } from "react-router-dom";

type DropdownOptionsProps = {
  options: Array<{
    label: string;
    destination: string;
  }>;
};

export const DropdownOptions = (props: DropdownOptionsProps) => (
  <div className="dropdown-options">
    {props.options.map((option) => (
      <NavLink
        className="dropdown-options__option"
        key={option.destination}
        to={option.destination}
      >
        {option.label}
      </NavLink>
    ))}
  </div>
);
