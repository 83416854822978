import { useMutation } from "@tanstack/react-query";

import {
  CompanyModuleRecordsReportPathParamsModuleIdentifier,
  IssuesReportQueryParams,
} from "@/api";
import { companyModuleRecordsReport } from "@/api/gen/axios/companyModuleRecordsReportController";

import { issuesReport } from "../gen/axios/issuesReportController";
import { questionnairePdf } from "../gen/axios/questionnairesPdfController";
import { QuestionnaireAPI } from "../legacy/risk-assessment";

export function useIssuesReport({
  workspaceId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  return useMutation({
    mutationFn: (params: IssuesReportQueryParams) =>
      issuesReport(workspaceId, params),
    onSuccess: () => {
      onSuccess();
    },
    onError,
  });
}

export function useQuestionnairePdfReport({
  questionnaireId,
  onSuccess = () => {},
  onError = () => {},
}: {
  questionnaireId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  return useMutation({
    mutationFn: () => questionnairePdf(questionnaireId),
    onSuccess: () => {
      onSuccess();
    },
    onError,
  });
}

export function useQuestionnaireXlsxReport({
  questionnaireId,
  onSuccess = () => {},
  onError = () => {},
}: {
  questionnaireId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  return useMutation({
    mutationFn: () => QuestionnaireAPI.downloadQuestionnaire(questionnaireId),
    onSuccess: () => {
      onSuccess();
    },
    onError,
  });
}

export function useCompanyModuleRecordsReport({
  companyId,
  moduleIdentifier,
  onSuccess = () => {},
  onError = () => {},
}: {
  companyId: number;
  moduleIdentifier: CompanyModuleRecordsReportPathParamsModuleIdentifier;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  return useMutation({
    mutationFn: () => companyModuleRecordsReport(companyId, moduleIdentifier),
    onSuccess: () => {
      onSuccess();
    },
    onError,
  });
}
