import { zodResolver } from "@hookform/resolvers/zod";
import {
  Avatar,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  ImagePicker,
  Select,
  SelectProps,
  TextArea,
  TextInput,
} from "@themis/ui";
import React, { useEffect, useMemo } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { Account, Contact } from "@/api";
import { useCompanyUsers } from "@/api/queries/users";
import { useCountryStateCity } from "@/hooks/useCountryStateCity";
import mainStore from "@/stores/Main";

import regex from "../../config/regex";
import { ACCOUNT_ID_PARAM } from "../../pages";
import { AccountTypeTag } from "../AccountTypeTag";

const ACCOUNT_TYPES: SelectProps["items"] = [
  {
    label: "Vendor",
    value: "vendor",
    Component: ({ value }) => <AccountTypeTag type={value} />,
  },
  {
    label: "Partner",
    value: "partner",
    Component: ({ value }) => <AccountTypeTag type={value} />,
  },
];

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  account_types: z.string().min(1, "Type is required"),
  logo: z.string().optional(),
  description: z.string().optional(),
  mailing_city: z.string().optional(),
  mailing_postal_code: z.string().optional(),
  legal_name: z.string().optional(),
  website: z.string().regex(regex.URL, "Valid URL is required"),
  phone: z.string().optional(),
  mailing_street: z.string().optional(),
  mailing_state: z.string().optional(),
  mailing_country: z.string().optional(),
  account_owner_id: z.number().optional(),
  main_contact_id: z.number().optional(),
  mainContactPhone: z.string(),
  mainContactEmail: z.string(),
});

type AccountInfoSchema = z.infer<typeof formSchema>;

function AccountInfoForm({
  contacts = [],
  defaultValues,
  onSubmit,
}: {
  contacts?: Contact[];
  onSubmit: (values: AccountInfoSchema) => void;
  defaultValues?: Account;
}) {
  const { workspace_id, accountId } = useParams<{
    workspace_id: string;
    [ACCOUNT_ID_PARAM]: string;
  }>();
  const companyID = mainStore.context.companyID!;
  const { data: activeUsers } = useCompanyUsers(companyID, {
    workspace_id: Number(workspace_id),
  });

  function getMainContact(mainContactId: number) {
    return contacts.find(({ id }) => id === mainContactId);
  }

  const mainContact = defaultValues?.main_contact_id
    ? getMainContact(defaultValues.main_contact_id)
    : undefined;

  function mapDefaultValuesToForm(values?: Account) {
    return {
      name: values?.name || "",
      description: values?.description || "",
      mailing_city: values?.mailing_city || "",
      account_types: values?.account_types.length
        ? values?.account_types[0]
        : "",
      logo: values?.logo?.file_url || "",
      mailing_postal_code: values?.mailing_postal_code || "",
      legal_name: values?.legal_name || "",
      website: values?.website || "",
      phone: values?.phone || "",
      mailing_street: values?.mailing_street || "",
      mailing_state: values?.mailing_state || "",
      mailing_country: values?.mailing_country || "",
      account_owner_id: values?.account_owner_id || undefined,
      main_contact_id: values?.main_contact_id || undefined,
      mainContactPhone: mainContact?.phone || "",
      mainContactEmail: mainContact?.email || "",
    };
  }

  const form = useForm<AccountInfoSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: mapDefaultValuesToForm(defaultValues),
  });

  useEffect(() => {
    // Reset form values when accountId changes.
    // This is necessary when navigating from parent to child account
    // to ensure the form is updated with the new account details.
    form.reset(mapDefaultValuesToForm(defaultValues));
  }, [accountId]);

  const { countries, states } = useCountryStateCity(
    form.watch("mailing_country"),
  );

  const countryOptions = useMemo(
    () =>
      countries.map(({ name, isoCode }) => ({ label: name, value: isoCode })),
    [countries],
  );

  const stateOptions = useMemo(
    () => states.map(({ name, isoCode }) => ({ label: name, value: isoCode })),
    [states],
  );

  const handleLogoChange = async (signedIds: Array<string>) => {
    form.setValue("logo", signedIds[0]);
    // Explicitly trigger form validation after logo upload
    await form.trigger("logo");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.handleSubmit(onSubmit)();
  };

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit}
        className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-3"
      >
        <FormField
          required
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="legal_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Legal Entity Name</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="account_types"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Type</FormLabel>
              <FormControl>
                <Select
                  items={ACCOUNT_TYPES}
                  selected={field.value}
                  onSelect={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="website"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Company Website</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="logo"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Logo</FormLabel>
              <FormControl>
                <DirectUploadProvider
                  onSuccess={handleLogoChange}
                  render={({ handleUpload, ready }) => (
                    <ImagePicker
                      readOnly={!ready}
                      className="tw-h-36"
                      onSelectFile={(file) => handleUpload([file])}
                      defaultUrl={field.value}
                      placeholder="Logo will be displayed here."
                      placeholderSubtext="Drop file here or click to select."
                    />
                  )}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Company Phone</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <TextArea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mailing_street"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Street</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mailing_country"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Country</FormLabel>
              <FormControl>
                <Select
                  searchable
                  items={countryOptions}
                  selected={field.value}
                  onSelect={(value) => {
                    // Clear the mailing_state field if the country changes.
                    if (value !== form.getValues("mailing_country")) {
                      form.setValue("mailing_state", "");
                    }

                    field.onChange(value);
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mailing_state"
          render={({ field }) => (
            <FormItem>
              <FormLabel>State</FormLabel>
              <FormControl>
                <Select
                  searchable
                  readOnly={Boolean(!stateOptions.length)}
                  items={stateOptions}
                  selected={field.value}
                  onSelect={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mailing_city"
          render={({ field }) => (
            <FormItem>
              <FormLabel>City</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mailing_postal_code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Postal Code</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="account_owner_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Account Owner</FormLabel>
              <FormControl>
                <Select
                  searchable
                  items={
                    activeUsers?.data.map(
                      ({ id, full_name, initials, icon_color_index }) => ({
                        label: full_name || "",
                        value: String(id),
                        Component: () => (
                          <div className="tw-flex tw-items-center tw-gap-2">
                            <Avatar size="md" colorIndex={icon_color_index}>
                              {initials}
                            </Avatar>
                            {full_name}
                          </div>
                        ),
                      }),
                    ) || []
                  }
                  selected={field.value ? String(field.value) : undefined}
                  onSelect={(value) => field.onChange(Number(value))}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-2">
          <h2 className="tw-pb- tw-pt-5 tw-text-lg tw-font-semibold tw-text-neutral-500">
            Main Contact
          </h2>
          <div className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-3">
            <FormField
              control={form.control}
              name="main_contact_id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Main Contact</FormLabel>
                  <FormControl>
                    <Select
                      searchable
                      items={contacts.map(({ id, full_name, initials }) => ({
                        label: full_name,
                        value: String(id),
                        Component: () => (
                          <div className="tw-flex tw-items-center tw-gap-2">
                            <Avatar size="md" colorIndex={id}>
                              {initials}
                            </Avatar>
                            {full_name}
                          </div>
                        ),
                      }))}
                      selected={field.value ? String(field.value) : undefined}
                      onSelect={(value) => {
                        field.onChange(Number(value));

                        const newMainContact = getMainContact(Number(value));

                        form.setValue(
                          "mainContactPhone",
                          newMainContact?.phone || "",
                        );

                        form.setValue(
                          "mainContactEmail",
                          newMainContact?.email || "",
                        );
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mainContactPhone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone</FormLabel>
                  <FormControl>
                    <TextInput readOnly {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="mainContactEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <TextInput readOnly {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <Button
          className="tw-col-span-2 tw-mt-5 tw-place-self-end"
          type="submit"
        >
          Save Account Info
        </Button>
      </form>
    </Form>
  );
}

export { AccountInfoForm, type AccountInfoSchema };
