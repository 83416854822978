import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import deleteIcon from "../../../images/table-image/icon/delete-two-icon.svg";

type Props = {
  recordID?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  afterDelete?: (...args: any[]) => any;
  tableName?: string;
};

function ArchivedPopupDelete({ afterDelete, recordID, tableName }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);

  // vars
  const { canDeleteRecords } = mainStore.userPermissions;
  const isAttestation = tableName === "Attestation";

  // funcs
  function onOpen() {
    setShowPopup(true);
  }

  function onClose() {
    setShowPopup(false);
  }

  async function onYes() {
    if (isAttestation) {
      await mainStore.attestations.delete(recordID, { all_users: true });
    } else {
      await mainStore.records.delete(recordID);
    }

    mainStore.toast.setText(`${tableName} permanently deleted!`);

    if (typeof afterDelete === "function") {
      afterDelete();
    }
  }

  function onNo() {
    onClose();
  }

  const deleteTrigger = (
    <button
      className="button-archived-popup"
      data-testid="button-delete-archived-record"
      data-record-id={recordID?.toString()}
      disabled={!canDeleteRecords}
    >
      <img src={deleteIcon} alt="delete-icon" />
    </button>
  );

  return (
    <Popup
      position="bottom left"
      trigger={deleteTrigger}
      open={showPopup}
      onOpen={onOpen}
      onClose={onClose}
      disabled={!canDeleteRecords}
      keepTooltipInside
    >
      <div
        className="table-dropdown"
        data-testid="archived-record-delete-confimation"
      >
        <h4>Delete Archived {tableName}</h4>
        <p>
          Are you sure you want to permanently delete this archived {tableName}?
          Actions are not reversible.
        </p>
        <div className="confirmation">
          <button onClick={onYes}>Yes</button>
          <button onClick={onNo}>No</button>
        </div>
      </div>
    </Popup>
  );
}

export default ArchivedPopupDelete;
