import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { BUILTIN_THEMIS_MODULES } from "@/components/settings/Modules/TableContents";
import { useMainStore } from "@/contexts/Store";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { workspaceIcons } from "../helpers/workspaceIcons";
import InviteUsersToWorkspace from "./InviteUsersToWorkspace/InviteUsersToWorkspace";
import NameWorkspace from "./NameWorkspace/NameWorkspace";
import ReviewWorkspaceInfo from "./ReviewWorkspaceInfo/ReviewWorkspaceInfo";
import SelectCollaborativeWorkspaceModules from "./SelectCollaborativeWorkspaceModules/SelectCollaborativeWorkspaceModules";
import SelectWorkspaceIcon from "./SelectWorkspaceIcon/SelectWorkspaceIcon";

function SetupWorkspace() {
  // Import MobX stores
  const mainStore = useMainStore();
  // Router
  const history = useHistory();

  // State
  const [workspaceStep, setWorkspaceStep] = useState(1);
  const [name, setName] = useState("");
  const [logo, setLogo] = useState<{ url: string } | null>(null);
  const [selectedUserIDs, setSelectedUserIDs] = useState([]);
  const [selectedModules, setSelectedModules] = useState<ModuleIdentifier[]>([
    ...BUILTIN_THEMIS_MODULES,
  ]);
  const [iconID, setIconID] = useState(20);
  const [isCreateWorkspaceButtonEnabled, setIsCreateWorkspaceButtonEnabled] =
    useState(true);

  async function onWorkspaceSubmit() {
    setIsCreateWorkspaceButtonEnabled(false);

    const companyID = mainStore.companies.company?.id;

    if (companyID) {
      const workspace = await mainStore.workspaces.create(companyID, {
        workspace: {
          icon_index: iconID,
          name,
          user_ids: selectedUserIDs,
          suggested_logo: logo?.url,
        },
        themis_module_identifiers: selectedModules,
      });

      if (workspace) {
        history.push(`/workspaces/${workspace.id}/home`);
      }
    }
  }

  // Renderers
  const workspaceIconLogo = logo ? (
    <div className="big-logo-block" key={1}>
      <img src={logo.url} alt="workspace-big-logo" />
    </div>
  ) : (
    workspaceIcons
      .filter((element) => element.id === iconID)
      .map((icon) => (
        <div className="big-logo-block" key={icon.id}>
          <img src={icon.url} alt={`workspace-big-logo${icon.id}`} />
        </div>
      ))
  );

  const renderStep = () => {
    switch (workspaceStep) {
      case 1:
        return (
          <NameWorkspace
            setName={setName}
            setWorkspaceStep={setWorkspaceStep}
          />
        );
      case 2:
        return (
          <InviteUsersToWorkspace
            selectedUserIDs={selectedUserIDs}
            setSelectedUserIDs={setSelectedUserIDs}
            setWorkspaceStep={setWorkspaceStep}
          />
        );
      case 3:
        return (
          <SelectCollaborativeWorkspaceModules
            selectedModules={selectedModules}
            setSelectedModules={setSelectedModules}
            setWorkspaceStep={setWorkspaceStep}
          />
        );
      case 4:
        return (
          <SelectWorkspaceIcon
            workspaceIconLogo={workspaceIconLogo}
            // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<null>>' is not assig... Remove this comment to see the full error message
            setLogo={setLogo}
            iconID={iconID}
            // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<number>>' is not ass... Remove this comment to see the full error message
            setIconID={setIconID}
            setWorkspaceStep={setWorkspaceStep}
          />
        );
      case 5:
        return (
          <ReviewWorkspaceInfo
            workspaceIconLogo={workspaceIconLogo}
            name={name}
            selectedModules={selectedModules}
            setWorkspaceStep={setWorkspaceStep}
            isCreateWorkspaceButtonEnabled={isCreateWorkspaceButtonEnabled}
            onWorkspaceSubmit={onWorkspaceSubmit}
          />
        );
      default:
        return (
          <NameWorkspace
            setName={setName}
            setWorkspaceStep={setWorkspaceStep}
          />
        );
    }
  };

  return (
    <div className="setting-workspace-content">
      <h3>Setting up new collaborative workspace</h3>

      {renderStep()}
    </div>
  );
}

export default observer(SetupWorkspace);
