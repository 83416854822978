import "./subpage-tab.scss";

import classNames from "classnames";
import { isNil } from "lodash";
import React, { ComponentProps, ReactNode } from "react";

import { MiniTag, Typography } from "@/components/Elements";

type SubpageTabProps = {
  label: ReactNode;
  count?: number;
  active: boolean;
} & ComponentProps<"button">;

function SubpageTab({ label, count, active, ...rest }: SubpageTabProps) {
  return (
    <button
      className={classNames("subpage-tab", { "subpage-tab--active": active })}
      {...rest}
    >
      <Typography size="sm" weight="semiBold" label={label} />
      {!isNil(count) && (
        <MiniTag
          className={classNames("subpage-tab__count", {
            "subpage-tab__count--active": active,
          })}
          label={String(count)}
          fontSize="xs"
          fontWeight="semiBold"
        />
      )}
    </button>
  );
}

export default SubpageTab;
