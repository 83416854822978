import "./progress-breadcrumbs.scss";

import React from "react";

import { Icon } from "@/components/Elements";

type ProgressBreadcrumbsProps = {
  children: JSX.Element[];
};

function ProgressBreadcrumbs(props: ProgressBreadcrumbsProps) {
  return (
    <div className="progress-breadcrumbs">
      {props.children.map((child, i) => (
        <React.Fragment key={i}>
          {child}
          {props.children.length - 1 !== i && <Icon name="chevronRight" />}
        </React.Fragment>
      ))}
    </div>
  );
}

export default ProgressBreadcrumbs;
