import React, { useState } from "react";

import { Icon } from "@/components/Elements";

import { ModuleSummaryInfo } from "../../../stores/types/workspace-types";
import { Typography } from "../../Elements";
import { iconForThemisModuleIdentifier } from "../../helpers/iconForThemisModuleIdentifier";
import SectionNode from "./SectionNode";

type ModuleSummaryItemProps = {
  summary: ModuleSummaryInfo;
  canCopyOver: boolean;
};

const ModuleSummaryItem = (props: ModuleSummaryItemProps) => {
  const [showSummary, setShowSummary] = useState(false);

  const { identifier } = props.summary;
  const isDocument = identifier === "documents";
  const omitTopSection = [
    "risk_register",
    "qa_tests_development",
    "audits",
    "risk_register",
    "new_product_approval",
  ].includes(identifier);

  return (
    <div className="module-summary-item">
      <div className="module-summary-item__title">
        <div className="module-summary-title">
          <img
            className="module-summary-icon"
            width="20px"
            src={iconForThemisModuleIdentifier(identifier)}
            alt=""
          />
          <Typography
            label={props.summary.name}
            weight="semiBold"
            size="sm"
            color="generalMidnightDark"
          />
        </div>
        <div>
          {props.canCopyOver && (
            <Icon
              data-testid={`show-section-${identifier}`}
              onClick={() => setShowSummary(!showSummary)}
              name={showSummary ? "chevronUp" : "chevronDown"}
            />
          )}
        </div>
      </div>
      {props.canCopyOver && showSummary && (
        <>
          <div className="module-summary-copy-desc">
            {" "}
            {isDocument
              ? "Copy over folders and related record names"
              : "Copy over record names"}{" "}
          </div>
          {[
            omitTopSection
              ? undefined
              : {
                  id: -1,
                  title: `Top Level ${isDocument ? "Folder" : "Section"}`,
                  sections: [],
                },
            ...props.summary.sections,
          ]
            .filter((i) => i)
            .map((section) => (
              <SectionNode
                // @ts-expect-error TS(2322) FIXME: Type 'ModuleSectionNode | undefined' is not assign... Remove this comment to see the full error message
                summary={section}
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                key={section.id}
                icon={isDocument ? "folderLinear" : "listBullet"}
              />
            ))}
        </>
      )}
    </div>
  );
};

export default ModuleSummaryItem;
