import { observer } from "mobx-react";
import React from "react";

import { getWorkspaceLogo } from "@/components/helpers/getWorkspaceLogo";
import { useMainStore } from "@/contexts/Store";

function WorkspaceCell({ workspaceId }: { workspaceId: number }) {
  const mainStore = useMainStore();
  const { list: workspaces } = mainStore.workspaces;
  const workspace = workspaces.find((w) => w.id === workspaceId) || {
    name: "Workspace Not Found",
    icon_index: 0,
    logo: "",
    is_internal: false,
  };

  return (
    <div className="tw-flex tw-max-w-[100%] tw-items-center tw-gap-2">
      <img
        className="tw-w-[20px] tw-rounded"
        src={getWorkspaceLogo(workspace).logo}
      />
      <span className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
        {workspace.name}
      </span>
    </div>
  );
}

export default observer(WorkspaceCell);
