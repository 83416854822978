import { uniqBy } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { RiskAreaApi } from "@/api/legacy/risk-assessment/RiskAreaApi";
import { Flex, Icon } from "@/components/Elements";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import Table from "@/features/risk-assessment/components/Table/GenericTable/Table";
import TableHeaderCell from "@/features/risk-assessment/components/Table/GenericTable/TableHeaderCell";

import { RiskArea } from "../../../types/risk-area";
import RiskAreaRow from "../RiskAreaRow";

function RiskAreaTable() {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const [riskAreas, setRiskAreas] = useState<RiskArea[]>([]);
  const [editState, setEditState] = useState({
    isEditing: false,
    editingRiskAreaId: 0,
  });
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!workspaceID) {
      return;
    }

    RiskAreaApi.getAll(String(workspaceID)).then(setRiskAreas);
  }, [workspaceID]);

  const onRiskAreaSave = async (updatedRiskArea: RiskArea) => {
    try {
      if (!updatedRiskArea.name) {
        return mainStore.toast.setErrorText("Risk Area name is required.");
      }
      setIsSaving(true);
      if (mainStore.context.workspaceID !== null) {
        const riskAreaData = await RiskAreaApi.upsert(
          mainStore.context.workspaceID,
          updatedRiskArea,
          updatedRiskArea.id,
        );

        setRiskAreas(uniqBy([riskAreaData, ...riskAreas], "id"));
        setEditState({ isEditing: false, editingRiskAreaId: 0 });
      }
    } catch (err) {
      mainStore.toast.setErrorText(
        "There was an error saving. Please try again.",
      );
    } finally {
      setIsSaving(false);
    }
  };

  const addRiskArea = () => {
    setEditState({ isEditing: true, editingRiskAreaId: 0 });
  };

  if (!riskAreas) {
    return <Loading loadingLayout="small-table" />;
  }

  return (
    <>
      <AddRecordHeader addRecord={addRiskArea} recordName="Risk Area" />
      <div className="risk-methodology-page">
        <Flex column rowGap={10} fullWidth>
          <Table
            header={
              <>
                <TableHeaderCell
                  title="Risk Area"
                  leftIcon={<Icon color="extrasBlueGrayDarker" name="text" />}
                  firstDataHeader
                />
                <TableHeaderCell
                  title="Definition"
                  leftIcon={<Icon color="extrasBlueGrayDarker" name="text" />}
                  lastDataHeader
                />
              </>
            }
            actionRow={
              !(editState.isEditing && editState.editingRiskAreaId === 0)
                ? {
                    text: "Add Risk Area",
                    onClick: () => addRiskArea,
                  }
                : undefined
            }
          >
            {editState.isEditing && editState.editingRiskAreaId === 0 && (
              <RiskAreaRow
                riskArea={{ name: "", definition: "" }}
                index={0}
                onEdit={() =>
                  setEditState({ isEditing: true, editingRiskAreaId: 0 })
                }
                onSave={onRiskAreaSave}
                onCancel={() =>
                  setEditState({ isEditing: false, editingRiskAreaId: 0 })
                }
                isEditing
                isDisabled={
                  (editState.isEditing && editState.editingRiskAreaId !== 0) ||
                  isSaving
                }
              />
            )}
            {riskAreas.map((ra, i) => {
              return (
                <RiskAreaRow
                  key={ra.id}
                  riskArea={ra}
                  index={i}
                  onEdit={() =>
                    setEditState({
                      isEditing: true,
                      editingRiskAreaId: ra.id || 0,
                    })
                  }
                  onSave={onRiskAreaSave}
                  onCancel={() =>
                    setEditState({ isEditing: false, editingRiskAreaId: 0 })
                  }
                  isEditing={
                    editState.isEditing && editState.editingRiskAreaId === ra.id
                  }
                  isDisabled={
                    (editState.isEditing &&
                      editState.editingRiskAreaId !== ra.id) ||
                    isSaving
                  }
                />
              );
            })}
          </Table>
        </Flex>
      </div>
    </>
  );
}

export default observer(RiskAreaTable);
