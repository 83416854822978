import dayjs from "dayjs";
import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import { Typography } from "../../Elements";
import { formatDate } from "../../helpers/DateFormatters";
import { getTimeSensitiveGreeting } from "../../helpers/time";

const HomeHeader = () => {
  const { users } = useMainStore();

  return (
    <div className="home-header">
      <Typography
        label={`${getTimeSensitiveGreeting()},`}
        weight="semiBold"
        size="sm"
        color="generalWhite"
      />
      <Typography
        label={users.user?.full_name}
        weight="semiBold"
        size="lgh"
        color="generalWhite"
      />
      <Typography
        className="home-header__date"
        label={formatDate(dayjs().toDate())}
        weight="semiBold"
        size="sm"
        color="generalWhite"
      />
    </div>
  );
};

export default observer(HomeHeader);
