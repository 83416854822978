import "./list-cell.scss";

import React, { ComponentPropsWithoutRef } from "react";

import { Typography } from "@/components/Elements";

import TableCell from "../../TableCell";

type Props = {
  children: JSX.Element[];
  isFirstDataCell?: boolean;
  isLastDataCell?: boolean;
  gridGap?: number;
} & ComponentPropsWithoutRef<"td">;

export default function ListCell({
  children,
  isFirstDataCell,
  isLastDataCell,
  gridGap,
  ...rest
}: Props) {
  return (
    <TableCell
      isFirstDataCell={isFirstDataCell}
      isLastDataCell={isLastDataCell}
      {...rest}
    >
      <ul className="generic-list-cell" style={{ gap: `${gridGap || 3}px` }}>
        {children.map((child, i) => (
          <li key={i}>
            <Typography
              label={child}
              weight="semiBold"
              size="sm"
              className="generic-content-cell"
            />
          </li>
        ))}
      </ul>
    </TableCell>
  );
}
