import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import AddRecordHeader from "@/features/misc/AddRecordHeader";

import Loading from "../../../components/Loading";
import MenuActions from "../../../components/table/menu-actions/MenuActions";
import { customColumns } from "../../../components/table/shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../../../components/table/shared/ModuleRecordVersion";
import ModuleTableColumn from "../../../components/table/shared/ModuleTableColumn";
import ReorderableTable from "../../../components/table/shared/ReorderableTable";
import SectionDragContext from "../../../components/table/shared/SectionDragContext";
import TableMenu from "../../../components/table/shared/tableMenu/TableMenu";
import { useMainStore } from "../../../contexts/Store";
import { useLoading } from "../../../hooks/useLoading";

type Props = {
  recordVersionId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setShowCheckbox?: (...args: any[]) => any;
  showCheckbox?: boolean;
};

function TestSamples({
  recordVersionId,
  showCheckbox,
  setShowCheckbox,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [selectMode, setSelectMode] = useState(false);

  // Variables
  const { moduleWorkspaceID, subModuleTableID } = mainStore.context;
  const visibleFields = mainStore.fields.visibleFields || [];
  const isNoVisibleFields = visibleFields.length === 0;

  // Effects
  useEffect(() => {
    const fetchRecord = async () => {
      const { module_workspace_id, table_id } =
        await mainStore.qa.indexTestSamples(recordVersionId);
      await mainStore.fieldOptions.index(module_workspace_id);
      mainStore.context.setSubModuleTableID(table_id);
      mainStore.context.setSubModuleTableName("samples");
    };

    fetchRecord();
  }, [recordVersionId, isNoVisibleFields]);

  // Hooks
  const loading = useLoading(visibleFields);
  const {
    createColumn,
    renameColumn,
    deleteColumn,
    AddColumnButton,
    // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'st... Remove this comment to see the full error message
  } = customColumns({ subTableID: subModuleTableID });

  // Variables
  const recordVersions = mainStore.recordVersions.list;

  const renderFields = visibleFields.map((field) => (
    <ModuleTableColumn
      key={field.name}
      field={field}
      onDelete={deleteColumn}
      onRename={renameColumn}
    />
  ));

  // Functions
  const resetMenuActions = () => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  function handleClick() {
    mainStore.qa.createTestSample(recordVersionId);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderRow = (recordVersion, idx) => {
    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        isArchivedRow={false}
        // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
        moduleWorkspaceID={moduleWorkspaceID}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
        currentTableName="Default"
        moduleIdentifier="samples"
        inSelectMenuActionMode={selectMode}
        recordVersion={recordVersion}
        isDraggable
        fields={visibleFields}
        order={idx}
      />
    );
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'sourceRecordVersionId' implicitly has a... Remove this comment to see the full error message
  const onDragEnd = async (sourceRecordVersionId, payload) => {
    await mainStore.recordVersions.reorder(sourceRecordVersionId, payload);
  };

  if (loading) {
    return <Loading loadingLayout="table" showTableHeader={false} />;
  }

  return (
    <>
      <AddRecordHeader addRecord={handleClick} recordName="Sample" />
      <div
        className="table-list-wrap company-users-settings-container draggable"
        data-testid="test-samples"
      >
        <SectionDragContext>
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="procedures-list-column-first-blocks table-column-reorder">
                  {visibleFields.length > 0 && (
                    <TableMenu
                      setShowCheckbox={setShowCheckbox}
                      resetMenuActions={resetMenuActions}
                      selectMode={selectMode}
                      setSelectMode={setSelectMode}
                      isSubModule
                    />
                  )}
                </div>
                {showCheckbox && selectMode ? (
                  <div>
                    <MenuActions resetMenuActions={resetMenuActions} />
                  </div>
                ) : (
                  <div className="list-title-table-wrap">
                    {renderFields}
                    <span className="stretch-cell" />
                  </div>
                )}
              </div>
              <AddColumnButton onAdd={createColumn} />
            </ul>
          </div>
          <ReorderableTable
            recordVersions={recordVersions}
            isSorted={false}
            renderer={renderRow}
            onDragEnd={onDragEnd}
            onAddNewRow={handleClick}
            newRowName="Sample"
          />
        </SectionDragContext>
      </div>
    </>
  );
}

TestSamples.defaultProps = {
  setShowCheckbox: () => {},
};

export default observer(TestSamples);
