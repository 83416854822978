import "./partner-details-form.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  generatePath,
  Link,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { useAccounts } from "@/api/queries/accounts";
import DetailView from "@/components/detailView/DetailView";
import { Button } from "@/components/Elements";
import Loading from "@/components/Loading";
import VendorAccountSelect from "@/components/table/vendor-due-diligence/VendorAccountSelect";
import { useMainStore } from "@/contexts/Store";
import { ROUTES } from "@/features/risk-assessment";
import { VendorDetailAccountInfo } from "@/features/vendor-due-diligence/components/VendorDetailAccountInfo";

type Props = {
  isNew?: boolean;
};

function PartnerDetailsForm({ isNew }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { workspaceID } = mainStore.context;
  const { canManageAccounts } = mainStore.userPermissions;

  const { record_version_id } = useParams<{ record_version_id: string }>();
  const recordVersionId = Number(record_version_id);
  const { partners } = mainStore.riskAssessmentPartners;
  const partnerData = partners?.find(
    (partner) => partner.id === recordVersionId,
  );
  const hasCustomFields = mainStore.fields.list.some(
    (field) => field.is_custom_field,
  );

  const newPartnerParams = new URLSearchParams({
    redirectTo: `/workspaces/${workspaceID}/modules/risk-assessment/add-new-partners`,
  }).toString();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const accountId = searchParams.get("account_id");
  const sectionTagId = searchParams.get("section_tag_id");
  const partnerPath = generatePath(ROUTES.PARTNERS_PATH, {
    workspace_id: workspaceID as number,
  });

  // State
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(
    Number(accountId) || partnerData?.record?.account_id || null,
  );

  // Hooks
  const { data: accountData } = useAccounts(Number(workspaceID));
  const accounts = accountData?.data;
  const selectedAccount = accounts?.find(
    (account) => selectedAccountId === account.id,
  );
  const { handleSubmit } = useForm();
  const history = useHistory();

  const renderAccountSelect = (
    <div className="build-vendor-block">
      <div className="row">
        <VendorAccountSelect
          label="Account Name"
          isNew={isNew}
          placeholder="– Select existing account –"
          accounts={accounts}
          selectedAccountId={selectedAccountId}
          setSelectedAccountId={setSelectedAccountId}
        />
        {isNew && canManageAccounts && (
          <div className="determine-risk-rating-container stretch">
            <p>No Existing Account?</p>
            <Link
              to={`/workspaces/${workspaceID}/accounts/new?${newPartnerParams}`}
            >
              <Button size="sm" label="Create New Account" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );

  async function onSubmit() {
    const success = await mainStore.riskAssessmentPartners.createPartner(
      selectedAccountId as number,
      Number(sectionTagId),
    );

    if (success) {
      history.push(partnerPath);
    }
  }

  if (!selectedAccount && !isNew) {
    return <Loading loadingLayout="small-table" showTableHeader={false} />;
  }

  return (
    <div className="partner-form">
      <div
        className={classNames("new-vendor-container", {
          "create-mode": isNew,
        })}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={classNames("forms-container", {
              "tw-pt-6": !isNew,
            })}
          >
            {renderAccountSelect}
            {!!selectedAccount && (
              <VendorDetailAccountInfo
                canManageAccounts={canManageAccounts}
                isNew={!!isNew}
                selectedAccount={selectedAccount}
              />
            )}

            {isNew && <Button size="sm" label="Save Record" />}
          </div>
        </form>
      </div>
      {!isNew && hasCustomFields && (
        <DetailView
          moduleIdentifier="risk_assessment"
          disabledFields={[]}
          ignoredFields={[
            "approvers",
            "partner_name",
            "business_description",
            "principal_contact_and_title",
            "email_address",
            "phone_number",
          ]}
          isLoadingDisabled
          isDataFetchingDisabled
          customTitle="Custom Columns"
        />
      )}
    </div>
  );
}

export default observer(PartnerDetailsForm);
