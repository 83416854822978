import React from "react";
import { FadeLoader } from "react-spinners";

type Props = {
  loading: boolean;
};

function LoginProgressSpinner({ loading }: Props) {
  return (
    <FadeLoader
      width={2}
      height={3}
      color="#ffffff"
      margin={-12}
      cssOverride={{
        transform: "translate(-24px, 13px) scaleX(-1)",
        marginRight: "6px",
      }}
      loading={loading}
    />
  );
}

export default LoginProgressSpinner;
