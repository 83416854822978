import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { generatePath, useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { RecordVersion } from "@/api";
import { Button } from "@/components/Elements";
import ConfirmationDialog from "@/components/table/shared/ConfirmationDialog";
import MoveToSectionPopup from "@/components/table/shared/MoveToSectionPopup";
import { ROUTES } from "@/features/risk-assessment";

import { PartnersAPI } from "../../../api/legacy/risk-assessment/PartnersApi";
import { useMainStore } from "../../../contexts/Store";

type Props = {
  recordVersion: RecordVersion;
};

enum REPORTTYPE {
  PARTNER,
  ENHANCED,
  DOCUMENT,
  ALL,
}

function PartnerContextMenu({ recordVersion }: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const { taskDetail } = mainStore;

  const history = useHistory();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [view, setView] = useState("main");

  const { canDeleteRecords } = mainStore.userPermissions;
  const hasSections = mainStore.sectionTags.list.length > 0;

  const [checkboxCheckedAllExport, setCheckboxCheckedAllExport] =
    useState(true);
  const [checkboxCheckedPartner, setCheckboxCheckedPartner] = useState(true);
  const [checkboxCheckedEnhanced, setCheckboxCheckedEnhanced] = useState(true);
  const [checkboxCheckedDocuments, setCheckboxCheckedDocuments] =
    useState(true);
  const [buttonDisableExport, setButtonDisableExport] = useState(false);

  function noButtonPressed() {
    handlePopUpClose();
  }

  function deleteRowConfirmation() {
    setView("delete-confirmation");
  }

  function reportSelection() {
    setView("select-report");
  }

  function deleteRow() {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
    return PartnersAPI.delete(workspaceID, recordVersion.id);
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsOpenModal(false);
    setView("main");
  }

  function handleCreateTask() {
    handlePopUpClose();
    taskDetail.openFromRecord(recordVersion);
  }

  function generatePartnerPDFReportRA() {
    handlePopUpClose();
    setCheckboxCheckedAllExport(true);
    setCheckboxCheckedPartner(true);
    setCheckboxCheckedEnhanced(true);
    setCheckboxCheckedDocuments(true);
    setButtonDisableExport(false);
    return PartnersAPI.generatePartnerPDFReportRA(
      workspaceID!,
      recordVersion.id,
      checkboxCheckedPartner,
      checkboxCheckedEnhanced,
      checkboxCheckedDocuments,
    );
  }

  useEffect(() => {
    if (
      checkboxCheckedDocuments &&
      checkboxCheckedEnhanced &&
      checkboxCheckedPartner
    ) {
      setCheckboxCheckedAllExport(true);
    } else {
      setCheckboxCheckedAllExport(false);
    }

    if (
      checkboxCheckedAllExport ||
      checkboxCheckedDocuments ||
      checkboxCheckedEnhanced ||
      checkboxCheckedPartner
    ) {
      setButtonDisableExport(false);
    } else {
      setButtonDisableExport(true);
    }
  }, [
    buttonDisableExport,
    checkboxCheckedAllExport,
    checkboxCheckedPartner,
    checkboxCheckedEnhanced,
    checkboxCheckedDocuments,
  ]);

  const checkAllReports = (checkboxChecked: boolean, typeCheck: REPORTTYPE) => {
    if (typeCheck === REPORTTYPE.ALL) {
      setCheckboxCheckedAllExport(checkboxChecked);
      setCheckboxCheckedPartner(checkboxChecked);
      setCheckboxCheckedEnhanced(checkboxChecked);
      setCheckboxCheckedDocuments(checkboxChecked);
    } else if (typeCheck === REPORTTYPE.PARTNER) {
      setCheckboxCheckedPartner(checkboxChecked);
    } else if (typeCheck === REPORTTYPE.ENHANCED) {
      setCheckboxCheckedEnhanced(checkboxChecked);
    } else if (typeCheck === REPORTTYPE.DOCUMENT) {
      setCheckboxCheckedDocuments(checkboxChecked);
    }
  };

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="points-button-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const onMove = () => {
    setView("move");
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'rvID' implicitly has an 'any' type.
  const handleMove = async (rvID, sectionTagID) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
    await PartnersAPI.movePartner(workspaceID, sectionTagID, rvID);
    const { list } = mainStore.recordVersions;
    const partner = list.find((rv) => rv.id === rvID);
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    partner.section_tag_id = sectionTagID;
    mainStore.recordVersions.setList([...list]);
  };

  const renderBaseContent = (
    <div className="table-dropdown-partner partner-dropdown">
      <ul>
        <li
          onClick={() => {
            history.push(
              generatePath(ROUTES.PARTNERS_DETAILS_EDIT_PATH, {
                workspace_id: workspaceID as number,
                record_version_id: recordVersion.id,
              }),
            );
          }}
        >
          View Partner Detail
        </li>
        {hasSections && (
          <li onClick={onMove} data-testid="move-button-trigger">
            Move to
          </li>
        )}
        <li onClick={handleCreateTask}>Create Task</li>

        {canDeleteRecords && (
          <>
            {hasSections && <hr />}
            <li onClick={deleteRowConfirmation}>Delete</li>
          </>
        )}
        {<li onClick={reportSelection}>Export PDF Report</li>}
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Partner"
      content="Are you sure you want to delete this partner? Actions are not reversable."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );

  return (
    <div className="list-points">
      <Popup
        position="bottom right"
        trigger={renderTrigger}
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {view === "main" && renderBaseContent}

        {view === "delete-confirmation" && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}

        {view === "select-report" && (
          <div className="table-dropdown-partner partner-dropdown">
            <ul>
              <li>
                <div className="toggle-pdf-report-purple">
                  Select sections to include in your export
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <div className="toggle-pdf-report">
                  <input
                    type="checkbox"
                    id="checkAll"
                    name="checkAll"
                    checked={checkboxCheckedAllExport}
                    onChange={() =>
                      checkAllReports(!checkboxCheckedAllExport, REPORTTYPE.ALL)
                    }
                    data-testid="toggle-all-workspaces"
                  />
                  <label htmlFor="checkAll">Export Full PDF Report</label>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <div className="partner-report-separator-mid" />
              </li>
            </ul>
            <ul>
              <li>
                <div className="toggle-pdf-report">
                  <input
                    type="checkbox"
                    id="checkPartner"
                    name="checkPartner"
                    checked={checkboxCheckedPartner}
                    onChange={() =>
                      checkAllReports(
                        !checkboxCheckedPartner,
                        REPORTTYPE.PARTNER,
                      )
                    }
                    data-testid="toggle-partner-workspaces"
                  />
                  <label htmlFor="checkPartner">Partner Detail</label>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <div className="toggle-pdf-report">
                  <input
                    type="checkbox"
                    id="checkEnhanced"
                    name="checkEnhanced"
                    checked={checkboxCheckedEnhanced}
                    onChange={() =>
                      checkAllReports(
                        !checkboxCheckedEnhanced,
                        REPORTTYPE.ENHANCED,
                      )
                    }
                    data-testid="toggle-enhanced-workspaces"
                  />
                  <label htmlFor="checkEnhanced">Detailed Questionnaire</label>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <div className="toggle-pdf-report">
                  <input
                    type="checkbox"
                    id="checkDocuments"
                    name="checkDocuments"
                    checked={checkboxCheckedDocuments}
                    onChange={() =>
                      checkAllReports(
                        !checkboxCheckedDocuments,
                        REPORTTYPE.DOCUMENT,
                      )
                    }
                    data-testid="toggle-document-workspaces"
                  />
                  <label htmlFor="checkDocuments">Documents</label>
                </div>
              </li>
            </ul>
            <ul>
              <li>
                <div className="partner-report-separator-mid" />
              </li>
            </ul>
            <ul>
              <li>
                <Button
                  label="Export"
                  onClick={generatePartnerPDFReportRA}
                  disabled={buttonDisableExport}
                  data-testid="questionnaire-export-button"
                />
              </li>
            </ul>
          </div>
        )}

        {view === "move" && (
          <MoveToSectionPopup
            moduleName="Questionnaires"
            recordVersionID={recordVersion.id}
            onMove={handleMove}
            onClose={() => setIsOpenModal(false)}
          />
        )}
      </Popup>
    </div>
  );
}

export default observer(PartnerContextMenu);
