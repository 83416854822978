import "./table-cell.scss";

import classNames from "classnames";
import React, { ComponentPropsWithoutRef, useRef, useState } from "react";

import Portal from "@/components/table/shared/cell-type/Portal";

type LongTextTableCellProps = React.PropsWithChildren<{
  isFirstDataCell?: boolean;
  isLastDataCell?: boolean;
  isLinkCell?: boolean;
  noPadding?: boolean;
  isFocused?: boolean;
  isEditable?: boolean;
  minWidth?: number;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}> &
  ComponentPropsWithoutRef<"td">;

const LongTextTableCell = ({
  isFirstDataCell,
  isLastDataCell,
  isLinkCell,
  noPadding,
  isFocused,
  isEditable,
  minWidth = 200,
  width,
  children,
  startIcon,
  endIcon,
  ...rest
}: LongTextTableCellProps) => {
  const cellRef = useRef<HTMLTableDataCellElement>(null);
  const cellTextRef = useRef<HTMLDivElement>(null);

  const [showPopup, setShowPopup] = useState(false);
  const [coords, setCoords] = useState<
    | ({
        left: number;
        width: number;
      } & ({ top: number } | { bottom: number }))
    | null
  >(null);

  const handleShowPopup = () => {
    if (!cellRef.current || !cellTextRef.current) {
      return;
    }

    const isTextOverflowing =
      cellTextRef.current.scrollHeight > cellTextRef.current.clientHeight;

    if (!isTextOverflowing) {
      return;
    }

    const {
      x,
      y,
      height,
      width: cellWidth,
    } = cellRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const cellTopRelativeToViewport = y;
    const midpointOfViewport = viewportHeight / 2;

    if (cellTopRelativeToViewport >= midpointOfViewport) {
      const bottom = viewportHeight - (cellTopRelativeToViewport + height);

      setCoords({
        bottom,
        left: x,
        width: cellWidth,
      });
    } else {
      setCoords({
        top: y,
        left: x,
        width: cellWidth,
      });
    }

    setShowPopup(true);
  };

  return (
    <td
      ref={cellRef}
      onMouseEnter={handleShowPopup}
      onMouseLeave={() => setShowPopup(false)}
      className={classNames("generic-table-cell", {
        "generic-table-cell--first-data-cell": isFirstDataCell,
        "generic-table-cell--last-data-cell": isLastDataCell,
        "generic-table-cell--link-cell": isLinkCell,
        "generic-table-cell--no-padding": noPadding,
        "generic-table-cell--focused": isFocused,
        "generic-table-cell--editable": isEditable,
      })}
      style={{ minWidth }}
      {...rest}
    >
      <div
        className={classNames("tw-flex tw-items-center", {
          "tw-justify-between": !!endIcon,
        })}
      >
        {startIcon && (
          <div className="tw-mr-2 tw-flex tw-items-center">{startIcon}</div>
        )}
        <div
          className={`tw-line-clamp-4 tw-break-words tw-text-[13px] tw-font-semibold ${
            isFirstDataCell ? "tw-text-primary-500" : "tw-text-primaryDim-300"
          }`}
          data-testid="input-cell"
          ref={cellTextRef}
        >
          {children}
        </div>
        {endIcon && <div>{endIcon}</div>}
      </div>

      {showPopup && coords && (
        <Portal>
          <div className="long-cell-text-popup" style={coords}>
            {children}
          </div>
        </Portal>
      )}
    </td>
  );
};

export default LongTextTableCell;
