import { groupBy } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import { Icon } from "@/components/Elements";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import HeadSelect from "@/components/table/shared/HeadSelect";
import { useMainStore } from "@/contexts/Store";

import KRITabsHeader from "../components/KRITabsHeader";
import KRIThemisLibrarySection from "../components/KRIThemisLibrarySection";
import { KRIThemisLibraryRecord } from "./types";

const KRIThemisLibraryPage = ({ moduleName }: { moduleName: string }) => {
  const mainStore = useMainStore();
  const [search, setSearch] = useState("");

  const { libraryRecords: rows }: { libraryRecords: KRIThemisLibraryRecord[] } =
    mainStore.keyRiskIndicatorsThemisLibrary;

  useEffect(() => {
    mainStore.keyRiskIndicatorsThemisLibrary.index();
    mainStore.keyRiskIndicatorsCompanyLibrary.setCheckedRowIDs([]);
  }, []);

  const filteredRows = rows.filter((item) => {
    const includesSearch =
      item.name?.toLowerCase().includes(search.toLowerCase()) ||
      item.key_risk_indicators_themis_risk_categories[0].name
        .toLowerCase()
        .includes(search.toLowerCase());

    return includesSearch;
  });

  const filteredRowsGroupedBySection = groupBy(
    filteredRows,
    (e) => e.key_risk_indicators_themis_risk_categories[0].name,
  );

  // Array of ids to pass to copyToKRI
  const { checkedRows } = mainStore.keyRiskIndicatorsThemisLibrary;
  const { setCheckedRows } = mainStore.keyRiskIndicatorsThemisLibrary;

  const handleRowCheckboxChange = (id: number, isChecked: boolean) => {
    const newCheckedRows: number[] = isChecked
      ? [...checkedRows, id] // Add id
      : checkedRows.filter((rowId) => rowId !== id); // Remove id

    setCheckedRows(newCheckedRows);
  };

  const handleSectionCheckboxChange = (ids: number[], isChecked: boolean) => {
    let newCheckedRows: number[] = [...checkedRows];

    if (isChecked) {
      // Add all of the checked rows that don't yet exist in checkedRows
      ids.forEach((id) => {
        if (!newCheckedRows.includes(id)) {
          newCheckedRows.push(id);
        }
      });
    } else {
      // Remove items in checkedRows that were just unchecked (values in ids)
      newCheckedRows = checkedRows.filter((rowId) => !ids.includes(rowId));
    }

    setCheckedRows(newCheckedRows);
  };

  const sectionTitles = Object.keys(filteredRowsGroupedBySection);

  const renderSection = (section: string) => (
    <KRIThemisLibrarySection
      key={section}
      handleRowCheckboxChange={handleRowCheckboxChange}
      handleSectionCheckboxChange={handleSectionCheckboxChange}
      sectionTitle={section}
      rows={filteredRowsGroupedBySection[section]}
      checkedRows={checkedRows}
      type="themis"
    />
  );

  const renderSearch = (
    <div className="kri-themis-library-tools">
      <div
        className="controls-search-container kri-library-search-container"
        data-testid="kri-library-search-container"
      >
        <input
          type="text"
          placeholder="Search KRIs"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          data-testid="kri-library-search-input"
        />
        <Icon name="search" color="generalDark" size="de" />
      </div>
    </div>
  );

  return (
    <DashboardContent>
      <DashboardHeader
        title={moduleName}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<HeadSelect />}
      />
      <DashboardContentWrapper disableScroll>
        <KRITabsHeader />
        <div className="kri-library-wrapper">
          {renderSearch}
          <div className="kri-library-sections-wrapper">
            {sectionTitles.map(renderSection)}
          </div>
        </div>
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default observer(KRIThemisLibraryPage);
