import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import DepartmentSelect from "../cell-type/DepartmentSelect";

type Props = {
  disabled?: boolean;
  fieldName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue?: any;
  locked?: boolean;
  isUserSection?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion?: any;
  recordVersionID?: number;
  selectedIDs?: number[];
  onDataChange?: (
    data: unknown,
    serializedValue: unknown,
    value: unknown,
  ) => void;
};

function ViewDepartmentSelect({
  disabled,
  recordVersion,
  fieldName,
  recordVersionID,
  locked,
  initialValue,
  isUserSection = false,
  selectedIDs,
  onDataChange,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const cellError = mainStore.recordVersions.errorsForField(
    recordVersionID || -1,
    fieldName,
  );
  const hasErrors = Boolean(cellError);
  let value: unknown;
  // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
  if (isUserSection && mainStore.users?.selectedUser?.[fieldName]) {
    // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
    value = [mainStore.users.selectedUser[fieldName]?.id];
  } else {
    value = mainStore.avroSchemas.valueForField(fieldName, recordVersion?.data);
  }

  function getSelectedIds() {
    if (value) {
      return value;
    }

    if (initialValue) {
      return [initialValue.id];
    }

    if (selectedIDs) {
      return selectedIDs;
    }

    return [];
  }

  return (
    <DepartmentSelect
      disabled={disabled}
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      fieldName={fieldName}
      recordVersionID={recordVersionID}
      selectedIDs={getSelectedIds() as number[]}
      singleSelection
      width="100%"
      hasErrors={hasErrors}
      errorMessage={cellError?.description}
      locked={locked}
      hasErrorClass="detail-view-has-errors"
      isUserSection={isUserSection}
      onDataChange={onDataChange}
    />
  );
}

export default observer(ViewDepartmentSelect);
