import { HeaderTabs } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getSelectedTab } from "@/components/helpers/Tabs";
import AddNewSectionButton from "@/components/shared/AddNewSectionButton";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useFilters } from "@/hooks/useFilters";
import { useLoading } from "@/hooks/useLoading";
import { useTableData } from "@/hooks/useTableData";

import { useScrollToError } from "../../../hooks/useScrollToError";
import ExportBulk from "../../dashboard/ExportBulk";
import ImportBulk from "../../dashboard/ImportBulk";
import Loading from "../../Loading";
import CreateCTACW from "../shared/CreateCTACW";
import CreateFirstCTA from "../shared/CreateFirstCTA";
import NewSectionTag from "../shared/NewSectionTag";
import SectionDragContext from "../shared/SectionDragContext";
import Table from "../Table";
import MarketingSection from "./MarketingSection";

type Props = {
  defaultScreen?: string;
  isDragAndDrop?: boolean;
};

const TABS = [
  {
    name: "Active",
    key: "",
    value: "/modules/marketing",
    dataTestId: "marketing-tab-Active-trigger",
  },
  {
    name: "Completed",
    key: "completed",
    value: "/modules/marketing/completed",
    dataTestId: "marketing-tab-Completed-trigger",
  },
];

function Marketing({ isDragAndDrop, defaultScreen }: Props) {
  const mainStore = useMainStore();

  const [showAddNewSection, setShowAddNewSection] = useState(false);

  const { data } = mainStore.creatives;
  // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
  const moduleWorkspaceID = data?.module_workspace_id;
  const fields = mainStore.fields.visibleFields;
  const recordVersions = mainStore.recordVersions.list;
  const sectionTags = mainStore.sectionTags.orderedList;
  const { isIW, tableName, workspaceID } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;

  const currentTab =
    TABS.find((t) => t.name === defaultScreen) || getSelectedTab(TABS);
  const isActiveScreen = currentTab?.name === "Active";
  const isCompletedScreen = currentTab?.name === "Completed";
  const isReviewScreen = currentTab?.name === "ReviewProcess";
  const recordVersionsNoSections = recordVersions.filter(
    (recordVersion) => !recordVersion.section_tag_id,
  );

  const loading = useLoading(fields);
  const history = useHistory();
  useTableData();
  useScrollToError();

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields,
  });

  useEffect(() => {
    if (
      mainStore.sectionTags.current &&
      Object.keys(mainStore.sectionTags.current).length !== 0
    ) {
      setShowAddNewSection(false);
    }
  }, [mainStore.sectionTags.current]);

  useEffect(() => {
    mainStore.creatives.setDefaultPages(sectionTags);
  }, [sectionTags]);

  function handleScreenChange(newScreen: string) {
    mainStore.creatives.index({
      workspaceID,
      tab: newScreen,
    });
    history.push(`/workspaces/${workspaceID}${newScreen}`);
  }

  function addNewSection() {
    setShowAddNewSection(!showAddNewSection);
  }

  function renderCTA() {
    return isIW ? (
      <CreateFirstCTA subject="Creative" />
    ) : (
      <CreateCTACW subject="Creative" />
    );
  }

  function getFilteredFields() {
    let items = fields;

    if (isReviewScreen) {
      items = items.filter(
        (field) =>
          !(
            [
              "status",
              "submission_date",
              "name",
              "type",
              "owner",
              "product",
            ].includes(field.name) ||
            field.computed_column_identifier === "record_version"
          ),
      );
    } else if (isActiveScreen) {
      items = items.filter((field) => field.name !== "deny_reason");
    }

    return items;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionId' implicitly has an 'any... Remove this comment to see the full error message
  const onSectionChange = (recordVersionId, sectionId) => {
    mainStore.creatives.updateSection(recordVersionId, sectionId);
  };

  return (
    <Table>
      {loading && <Loading loadingLayout="table" showTableHeader={false} />}

      {!loading && (
        <>
          <div className={classNames("table-header-wrap")}>
            <div
              className={
                "tw-bg-neutral-25tw-py-0 tw-flex tw-h-[40px] tw-w-auto tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primaryDim-100"
              }
            >
              <HeaderTabs
                tabs={TABS}
                selectedTab={getSelectedTab(TABS)?.value || ""}
                onSelectTab={handleScreenChange}
                dataTestId="marketing-main-tabs"
              />
            </div>
            {isReviewScreen && (
              <div className="marketing-header-statuses">
                <div className="marketing-status-letter reviewing">R</div>
                <span>Reviewing</span>
                <div className="marketing-status-letter request_change">C</div>
                <span>Request Change</span>
              </div>
            )}
            <div
              className="import-export-buttons-container"
              data-testid="import-export-container"
            >
              <ExportBulk isExportFilesEnabled />
              {!isCompletedScreen && <ImportBulk topLevelSection />}
              {filtersTrigger}
            </div>
          </div>
          {isActiveScreen && (
            <AddRecordHeader
              addSection={addNewSection}
              addRecord={() =>
                mainStore.creatives.create(Number(workspaceID), null)
              }
              recordName="Creative"
              canAddSection={fields.length > 0}
            />
          )}
          {filtersViewEnabled && (
            <div className="filters-wrap">
              <div className="switch-table-wrap" />
              {filtersContent}
            </div>
          )}
          <div
            className="table-list-wrap marketing-content"
            data-testid="marketing-table"
          >
            <SectionDragContext onSectionChange={onSectionChange}>
              <MarketingSection
                isDropdownOpen
                // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
                tableName={tableName}
                fields={getFilteredFields()}
                recordVersions={recordVersionsNoSections}
                moduleWorkspaceID={moduleWorkspaceID}
                isDragAndDrop={isDragAndDrop}
                currentScreen={currentTab?.name}
                pageID={0}
              />

              {sectionTags.map((sectionTag) => {
                const filteredRecordVersions = recordVersions.filter(
                  (recordVersion) =>
                    recordVersion.section_tag_id === sectionTag.id,
                );
                if (filteredRecordVersions.length === 0 && !isActiveScreen) {
                  return null;
                }

                return (
                  <MarketingSection
                    key={sectionTag.id}
                    isDropdownOpen
                    // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
                    tableName={tableName}
                    fields={getFilteredFields()}
                    recordVersions={filteredRecordVersions}
                    moduleWorkspaceID={moduleWorkspaceID}
                    isDragAndDrop={isDragAndDrop}
                    sectionTag={sectionTag}
                    currentScreen={currentTab?.name}
                    pageID={sectionTag.id}
                  />
                );
              })}

              {showAddNewSection && (
                <NewSectionTag
                  moduleWorkspaceID={moduleWorkspaceID}
                  onCancel={() => setShowAddNewSection(false)}
                />
              )}

              {fields.length > 0 && isActiveScreen && canManageSections && (
                <div className="tw-sticky tw-left-[25px] tw-ml-[25px]">
                  <AddNewSectionButton onClick={addNewSection} />
                </div>
              )}

              {recordVersions.length < 3 && renderCTA()}
            </SectionDragContext>
          </div>
        </>
      )}
    </Table>
  );
}

Marketing.defaultProps = {
  defaultScreen: "Active",
};

export default observer(Marketing);
