import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

/**
 * Used to manage filters and sorting with the new queryParam format, exp. `?filters[status][any]=In Progress,Done&sort_by=status[asc]`
 *
 * @param {FilterFieldData} fieldsData - The data for the filter fields.
 * @returns {Object} result - Relevant filter and sorting data fileds.
 * @property {Object} result.filters - An object containing the active filters, to be used for frontend logic. If there are no active filters, this will be `undefined`.
 * @property {Object} result.sorting - The current sorting configuration, to be used for frontend logic.
 * @property {Object} result.queryParams - An object containing the active filters, to be used for query params in API requests.
 * @property {function} result.addFilter - A function that takes a field name and a filter, and adds the filter to the specified field. It will replace the existing filter if it already exists.
 * @property {function} result.removeFilter - A function that takes a field name and removes the filter from the specified field.
 * @property {function} result.clearAllFilters - A function that removes all filters.
 */
export default function useFilterSort<T>(fieldsData: FilterFieldData<T>) {
  const history = useHistory();
  const { search } = useLocation();

  const { filters, sorting, searchParams, listRequestQueryParams } =
    parseParams(new URLSearchParams(search), fieldsData);

  function addFilter(fieldName: keyof T, filter: Filter) {
    searchParams.set(
      `filters[${String(fieldName)}][${filter.operand}]`,
      stringifyFilterValue(filter),
    );
    history.replace({ search: searchParams.toString() });
  }

  function removeFilter(fieldName: keyof T) {
    const filterOperand = filters[fieldName]?.operand;

    searchParams.delete(`filters[${String(fieldName)}][${filterOperand}]`);
    history.replace({ search: searchParams.toString() });
  }

  function clearAllFilters() {
    (Object.entries(filters) as Array<[keyof T, Filter]>).forEach(
      ([fieldName, filter]) => {
        searchParams.delete(`filters[${String(fieldName)}][${filter.operand}]`);
      },
    );
    history.replace({ search: searchParams.toString() });
  }

  function setSorting(sort: Sorting<T> | undefined) {
    searchParams.forEach((_, key) => {
      if (key.startsWith("sort_by[")) {
        searchParams.delete(key);
      }
    });

    if (sort) {
      searchParams.set(
        `sort_by[${String(sort.columnKey)}]`,
        `${sort.direction}`,
      );
    }

    history.replace({ search: searchParams.toString() });
  }

  return useMemo(
    () => ({
      filters: Object.keys(filters).length > 0 ? filters : undefined,
      addFilter,
      removeFilter,
      clearAllFilters,
      sorting,
      setSorting,
      listRequestQueryParams,
    }),
    [search],
  );
}

function parseFieldFilter(
  operand: string,
  filterParamString: string,
): Filter | null {
  try {
    const castOperand = operand as FilterOperand;
    const filterValues = filterParamString.split(",");

    if ([FilterOperand.ANY, FilterOperand.NOT].includes(castOperand)) {
      return {
        operand: castOperand as FilterOperand.ANY | FilterOperand.NOT,
        value: filterValues,
      };
    }

    if ([FilterOperand.PRESENT, FilterOperand.MISSING].includes(castOperand)) {
      return {
        operand: castOperand as FilterOperand.PRESENT | FilterOperand.MISSING,
      };
    }

    throw new Error(`Invalid filter operand: ${operand}`);
  } catch (error) {
    window.console.info(`Non-parsable filter: ${filterParamString}`, error);
    return null;
  }
}

function stringifyFilterValue(filter: Filter) {
  try {
    if ([FilterOperand.ANY, FilterOperand.NOT].includes(filter.operand)) {
      return (filter.value as string[]).join(",");
    }

    if (
      [FilterOperand.PRESENT, FilterOperand.MISSING].includes(filter.operand)
    ) {
      return "true";
    }

    throw new Error(`Invalid filter operand: ${filter.operand}`);
  } catch (error) {
    window.console.info(`Non-parsable filter: ${filter}`, error);
    return "";
  }
}

function parseSortBy(sortByKey: string, sortByValue: string) {
  const columnKeyMatch = sortByKey.match(/sort_by\[([^\]]*)\]/);
  const columnKey = columnKeyMatch ? columnKeyMatch[1] : "";
  const direction = sortByValue as "asc" | "desc";
  return { columnKey, direction };
}

function parseParams<T>(
  searchParams: URLSearchParams,
  fieldsData: FilterFieldData<T>,
) {
  const filters: Filters<T> = {};
  let sorting: Sorting<T> | undefined;
  searchParams.forEach((value, key) => {
    if (key.startsWith("sort_by[")) {
      const parsedSortBy = parseSortBy(key, value);

      return (sorting = parsedSortBy as Sorting<T>);
    }

    if (!key.match(/filters\[[a-z_]+\]/)) {
      // ignore search params that aren't related to table filters
      return;
    }

    const fieldNameMatch = key.match(/filters\[([^\]]*)\]/);
    const fieldName = fieldNameMatch ? fieldNameMatch[1] : "";

    if (!(fieldName in fieldsData)) {
      // ignore search params that aren't related to table fields
      return;
    }

    const operandMatch = key.match(/filters\[[^\]]*\]\[([^\]]*)\]/);
    const operand = operandMatch ? operandMatch[1] : "";

    const parsedFieldFilter = parseFieldFilter(operand, value);
    if (parsedFieldFilter) {
      filters[fieldName as keyof T] = parsedFieldFilter;
    }
  });

  const listRequestQueryParams = {
    filters: Object.fromEntries(
      (Object.entries(filters) as Array<[keyof T, Filter]>).map(
        ([fieldName, filter]) => [
          fieldName,
          {
            [filter.operand]: [FilterOperand.ANY, FilterOperand.NOT].includes(
              filter.operand,
            )
              ? (filter.value as string[]).join(",")
              : "",
          },
        ],
      ),
    ),
    ...(sorting && { sort_by: { [sorting.columnKey]: sorting.direction } }),
  };

  return { filters, sorting, searchParams, listRequestQueryParams };
}

export enum FilterOperand {
  ANY = "any",
  NOT = "not",
  PRESENT = "present",
  MISSING = "missing",
}

export type Filter =
  | {
      operand: FilterOperand.ANY | FilterOperand.NOT;
      value: string[];
    }
  | {
      operand: FilterOperand.PRESENT | FilterOperand.MISSING;
      value?: never;
    };

export type FilterValueOption = {
  value: string;
  Component: () => JSX.Element;
};

export type FilterData = {
  displayName: string;
  type: "string";
  options?: FilterValueOption[];
};

export type FilterFieldData<T> = Partial<Record<keyof T, FilterData>>;

type Filters<T> = Partial<Record<keyof T, Filter>>;

type Sorting<T> = {
  columnKey: keyof T;
  direction: "asc" | "desc";
};
