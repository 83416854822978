import api from "../api";
import { GoogleAccessTokenResponse } from "./types";

export async function getGoogleAccessToken(): Promise<GoogleAccessTokenResponse> {
  const response = await api.post<GoogleAccessTokenResponse>(
    `/integrations/google/oauth/refresh_auth_token`,
  );

  return response.data;
}
