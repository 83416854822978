import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useCallback, useMemo, useState } from "react";
import Popup from "reactjs-popup";

import { Typography } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import { User } from "@/stores/types/user-types";

import { userColors } from "../constants";
import { Icon } from "../Elements";

type Props = {
  allowedUsers: Array<Partial<User>>;
  stepsIndex: number;
};

function ApprovalsStepsUsersSelect({ allowedUsers, stepsIndex }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { currentApprovalsSteps } = mainStore.manageApprovalsSteps;

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [query, setQuery] = useState("");

  // Hooks
  const filteredUsers = useMemo(
    () =>
      allowedUsers.filter(
        (user) => user.full_name?.toLowerCase().includes(query.toLowerCase()),
      ),
    [allowedUsers, query],
  );

  const addUser = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
    (user, stepsUserIndex) => {
      const updatedValue = [...currentApprovalsSteps];
      const userId = user.id;

      if (updatedValue[stepsUserIndex].users_ids.includes(userId)) {
        updatedValue[stepsUserIndex].users_ids = updatedValue[
          stepsUserIndex
        ].users_ids.filter((id) => id !== userId);
      } else {
        updatedValue[stepsUserIndex].users_ids.push(userId);
      }

      mainStore.manageApprovalsSteps.setCurrentApprovalsSteps(updatedValue);
    },
    [
      currentApprovalsSteps,
      mainStore.manageApprovalsSteps.setCurrentApprovalsSteps,
    ],
  );

  // Functions
  function closePopup() {
    setShowPopup(false);
    setQuery("");
  }

  // Elements
  const renderTrigger = (
    <div
      className={classNames("steps-popup-trigger", { active: showPopup })}
      data-testid="steps-popup-trigger"
    >
      – Add User –
      <Icon name="chevronDown" color="generalDark" size="de" />
    </div>
  );

  function users(index: number) {
    return (
      <div className="email-list-wrap" data-testid="list-users">
        {filteredUsers.map((user) => {
          // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
          const style = { background: userColors[user.icon_color_index] };
          const isChecked = currentApprovalsSteps[index].users_ids.includes(
            // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
            user.id,
          );
          const isUserSelected = currentApprovalsSteps.some(
            (step, stepIndex) =>
              // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
              stepIndex !== index && step.users_ids.includes(user.id),
          );

          return (
            <li
              key={user.id}
              data-testid="email-list-user-li"
              className={classNames("select-option", {
                disabled: isUserSelected,
              })}
              onClick={() => {
                !isUserSelected && addUser(user, index);
              }}
            >
              <div
                className="users select-option-label-wrapper"
                data-testid="user"
              >
                <div className="table-checkbox">
                  <input
                    type="checkbox"
                    id={`option-${user.id}`}
                    checked={isChecked}
                    disabled
                  />
                  <label htmlFor={`option-${user.id}`} />
                </div>
                <div className="users-circle" style={style}>
                  <span>{user.initials}</span>
                </div>
                <div data-testid="user-name" className="user-full-name">
                  {user.full_name}
                </div>
              </div>
            </li>
          );
        })}
      </div>
    );
  }

  return (
    <Popup
      position="bottom right"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={closePopup}
      className="hit-popup"
      arrow={false}
    >
      <div className="select wide approvals-steps-select">
        <div className="select-dropdown">
          <div className="form-input">
            <input
              className="approvals-steps-input"
              placeholder="Search here"
              onChange={(event) => setQuery(event.target.value)}
            />
            <Icon name="search" color="generalDark" size="de" />
          </div>
          {filteredUsers.length > 0 ? (
            <div>
              <Typography
                className="title-all-approvers"
                label="All Approvers"
                size="sm"
                weight="bold"
                color="extrasSlateBlue"
              />
              <ul>{users(stepsIndex)}</ul>
            </div>
          ) : (
            <Typography
              className="no-results"
              label="No results found"
              size="sm"
              weight="semiBold"
            />
          )}
        </div>
      </div>
    </Popup>
  );
}

export default observer(ApprovalsStepsUsersSelect);
