import React from "react";
import { generatePath } from "react-router-dom";

import { MonitoringGroupAssetChild } from "@/api";

import { ReviewButton } from "../components/ReviewButton";
import { StatusTag } from "../components/StatusTag";
import { WEBSITE_MONITORING_ROUTES } from "../pages";

export const MONITORING_GROUP_ASSET_CHILDREN_COLUMNS_TABLE = [
  {
    headerName: "",
    width: 30,
    rowDrag: true,
    resizable: false,
  },
  {
    headerName: "Asset Child URL",
    field: "data",
    cellRenderer: "identifierCellRenderer",
    cellRendererParams: (props: { data: MonitoringGroupAssetChild }) => {
      return {
        onClick: () => {
          const monitoringGroupAssetChildId = props.data.id;

          const link = generatePath(
            `${WEBSITE_MONITORING_ROUTES.base}${WEBSITE_MONITORING_ROUTES.monitoringGroups}${WEBSITE_MONITORING_ROUTES.monitoringGroupAssetChildResults}`,
            {
              monitoringGroupAssetChildId,
            },
          );
          window.location.replace(link);
        },
        ...props,
      };
    },
    editable: false,
    minWidth: 450,
    flex: 2,
  },
  {
    headerName: "Status",
    cellRenderer: (props: { data: MonitoringGroupAssetChild }) => {
      return (
        <div className="tw-pl-2">
          <StatusTag
            status={
              Number(props.data.child_unresolved_results_count) > 0
                ? "Need Review"
                : "Resolved"
            }
          />
        </div>
      );
    },
    editable: false,
    minWidth: 140,
    flex: 3,
  },
  {
    headerName: "Total Findings",
    field: "child_grouped_results_count",
    cellRenderer: "textRenderCell",
    type: "integer",
    editable: false,
    minWidth: 130,
    flex: 2,
  },
  {
    headerName: "Unresolved Findings",
    field: "child_grouped_unresolved_results_count",
    cellRenderer: "textRenderCell",
    type: "integer",
    editable: false,
    minWidth: 180,
    flex: 2,
  },
  {
    headerName: "Latest Scan Date",
    cellRenderer: "datePickerCell",
    field: "monitoring_group_asset_child_updated_at",
    minWidth: 160,
  },
  {
    cellRenderer: (props: { data: MonitoringGroupAssetChild }) => {
      return (
        <div className="tw-flex tw-w-[90px] tw-items-center tw-justify-center">
          <ReviewButton monitoringGroupAssetChildId={Number(props.data?.id)} />
        </div>
      );
    },
    width: 90,
    minWidth: 90,
    flex: 2,
    type: "boolean",
    pinned: "right",
  },
];
