import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { type CreateAccountDocumentMutationRequest } from "@/api";
import {
  createAccountDocument,
  deleteDocument,
  listOfAccountDocuments,
} from "@/api/gen/axios/documentsController";

import { ACCOUNTS_QUERY_KEY } from "./accounts";

export const DOCUMENTS_QUERY_KEY = "documents";

export function useAccountDocuments(accountId: number) {
  return useQuery({
    queryKey: [DOCUMENTS_QUERY_KEY, accountId],
    queryFn: () => listOfAccountDocuments(accountId),
    enabled: !!accountId,
  });
}

export function useCreateDocument({
  accountId,
  workspaceId,
  onSuccess = () => {},
  onError = () => {},
}: {
  accountId: number;
  workspaceId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateAccountDocumentMutationRequest) =>
      createAccountDocument(accountId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENTS_QUERY_KEY, accountId],
      });
      queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_QUERY_KEY, workspaceId, accountId],
      });
      queryClient.invalidateQueries({
        queryKey: ACCOUNTS_QUERY_KEY.detail(Number(accountId)),
      });
      onSuccess();
    },
    onError,
  });
}

export function useDeleteDocument({
  accountId,
  workspaceId,
  onSuccess = () => {},
  onError = () => {},
}: {
  accountId: number;
  workspaceId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (documentId: number) => deleteDocument(documentId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [DOCUMENTS_QUERY_KEY, accountId],
      });
      queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_QUERY_KEY, workspaceId, accountId],
      });
      queryClient.invalidateQueries({
        queryKey: ACCOUNTS_QUERY_KEY.detail(Number(accountId)),
      });
      onSuccess();
    },
    onError,
  });
}
