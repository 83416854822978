import classNames from "classnames";
import React, { MutableRefObject, ReactElement } from "react";
import { Document, Page } from "react-pdf";

type Props = {
  commentsMode?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleFileClick?: (...args: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleMouseDownPosition?: (...args: any[]) => void;
  numPages?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPdfDocumentLoadSuccess?: (...args: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRenderSuccess?: (...args: any[]) => void;
  pdf?: { url: string };
  pdfRef?: MutableRefObject<HTMLDivElement>;
  renderCommentsOverlay?: ReactElement[];
  renderTempCircleOverlay?: ReactElement;
  zoomLevel?: number;
};

function CreativeViewPdf({
  commentsMode,
  pdfRef,
  pdf,
  onPdfDocumentLoadSuccess,
  handleMouseDownPosition,
  handleFileClick,
  numPages,
  onRenderSuccess,
  zoomLevel,
  renderCommentsOverlay,
  renderTempCircleOverlay,
}: Props) {
  return (
    <div
      className={classNames("pdf-wrap", {
        "commments-mode": commentsMode,
      })}
      ref={pdfRef}
      data-testid="cv-pdf-wrap"
    >
      <Document
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        file={pdf.url}
        onLoadSuccess={onPdfDocumentLoadSuccess}
        onMouseDown={(event) =>
          // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          handleMouseDownPosition(event.clientX, event.clientY)
        }
        onMouseUp={handleFileClick}
      >
        {Array.from(new Array(numPages), (_, index) => (
          <Page
            onRenderSuccess={onRenderSuccess}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={zoomLevel}
            width={600}
          />
        ))}
      </Document>
      {commentsMode && renderCommentsOverlay}
      {renderTempCircleOverlay}
    </div>
  );
}

export default CreativeViewPdf;
