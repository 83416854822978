import classNames from "classnames";
import React from "react";

import { Workspace } from "@/stores/types/workspace-types";

import { Typography } from "../../Elements";
import { getWorkspaceLogo } from "../../helpers/getWorkspaceLogo";

type WorkspaceRadioButtonProps = {
  workspace: Workspace;
  onCheck: (id: string) => void;
  isChecked: boolean;
};
export const WorkspaceRadioButton = (props: WorkspaceRadioButtonProps) => {
  const id = `workspace-${props.workspace.id}`;
  const { logo } = getWorkspaceLogo(props.workspace);

  return (
    <div
      key={props.workspace.id}
      className={classNames("workspace-radio-button", {
        "workspace-radio-button--active": props.isChecked,
      })}
    >
      <label htmlFor={id} className="workspace-radio-button__label">
        <img src={logo} alt="" className="workspace-radio-button__logo" />
        <Typography
          label={props.workspace.name}
          size="md"
          weight="semiBold"
          color="generalMidnightDark"
        />
      </label>
      <div className="workspace-radio-button__input-wrapper">
        <input
          className="workspace-radio-button__input"
          type="radio"
          name="workspace-radio-buttons"
          id={id}
          value={props.workspace.id}
          checked={props.isChecked}
          onChange={(e) => props.onCheck(e.target.value)}
        />
      </div>
    </div>
  );
};
