import { AxiosResponse } from "axios";

import api from "../api";
import {
  CustomNotificationRule,
  CustomNotificationRuleCreateUpdate,
  CustomNotificationsDeleteResponse,
  CustomNotificationsListResponse,
} from "./types";

export async function getCustomNotifications(workspaceID: number) {
  const response = await api.get<CustomNotificationsListResponse>(
    `/workspaces/${workspaceID}/custom_notification_rules`,
  );

  return response.data.custom_notification_rules;
}

export async function createCustomNotification(
  workspaceID: number,
  data: Partial<CustomNotificationRule>,
) {
  const response = await api.post<
    Partial<CustomNotificationRule>,
    AxiosResponse<CustomNotificationRuleCreateUpdate>
  >(`/workspaces/${workspaceID}/custom_notification_rules`, data);

  return response.data;
}

export async function updateCustomNotification(
  workspaceID: number,
  id: number,
  data: Partial<CustomNotificationRule>,
) {
  const response = await api.patch<
    Partial<CustomNotificationRule>,
    AxiosResponse<CustomNotificationRuleCreateUpdate>
  >(`/workspaces/${workspaceID}/custom_notification_rules/${id}`, data);

  return response.data;
}

export async function deleteCustomNotification(
  workspaceID: number,
  id: number,
) {
  const response = await api.delete<
    never,
    AxiosResponse<CustomNotificationsDeleteResponse>
  >(`/workspaces/${workspaceID}/custom_notification_rules/${id}`);

  return response.data.success;
}
