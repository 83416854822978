import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import Table from "../Table";
import Templates from "./Templates";
import VendorEmptyQuestionnaires from "./VendorEmptyQuestionnaires";
import VendorQuestionnairesTable from "./VendorQuestionnairesTable";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questionnaires?: any; // TODO: PropTypes.instanceOf(Array)
};

function VendorQuestionnaires({ questionnaires = [] }: Props) {
  // Stores
  const mainStore = useMainStore();

  // Variables
  const { currentVendor } = mainStore.vendors;
  const status = mainStore.avroSchemas.valueForField(
    "status",
    currentVendor?.data,
  );
  const isActive = status !== "disabled";
  const emptyQuestionnaires = questionnaires.length === 0;

  return (
    <Table>
      <div className="view-vendor-tables-container">
        {emptyQuestionnaires && isActive && <VendorEmptyQuestionnaires />}
        {emptyQuestionnaires && !isActive && (
          <div
            className="no-column-wrap vdd-add-first-questionnaire"
            data-testid="create-first-cta"
          >
            <div className="no-column-block">
              <div className="no-column-procedure">
                <div className="no-column-title">
                  <h3>There were no Questionnaires added</h3>
                </div>
                <p style={{ marginBottom: "24px" }}>
                  You don’t have any Questionnaires added for this vendor.
                </p>
                <p>
                  To add Questionnaire here, this vendor needs to be deactivate
                  before any action been taken.
                </p>
              </div>
            </div>
          </div>
        )}
        {!emptyQuestionnaires && (
          <VendorQuestionnairesTable questionnaires={questionnaires} />
        )}
        {isActive && (
          <Templates
            editable={false}
            emptyQuestionnaires={emptyQuestionnaires}
          />
        )}
      </div>
    </Table>
  );
}

export default observer(VendorQuestionnaires);
