import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import Loading from "../../Loading";
import RecordNotFound from "../../shared/RecordNotFound";
import RiskAssessmentBulkView from "./RiskAssessmentBulkView";
import RiskAssessmentNormalView from "./RiskAssessmentNormalView";

const EMPTY_MATRIX_DATA = {
  name: "",
  rating_calculation_type: "auto",
  risk_levels: [
    { title: "Low", color_index: 0 },
    { title: "Moderate", color_index: 2 },
    { title: "High", color_index: 3 },
  ],
  risk_themes: [
    {
      title: "",
      risk_descriptions: [
        { content: "Select Low" },
        { content: "Select Moderate" },
        { content: "Select High" },
      ],
    },
  ],
  risk_assessment_ratings: [
    {
      name: "Low",
      color_index: 0,
      position: 1,
      lower_range: 1,
      higher_range: 50,
    },
    {
      name: "Medium",
      color_index: 1,
      position: 2,
      lower_range: 51,
      higher_range: 75,
    },
    {
      name: "High",
      color_index: 2,
      position: 3,
      lower_range: 76,
      higher_range: 100,
    },
  ],
};

type Props = {
  isNew?: boolean;
  isTemplate?: boolean;
  usingTemplate?: boolean;
};

function BuildRiskAssessment({ isNew, isTemplate, usingTemplate }: Props) {
  const mainStore = useMainStore();

  const [loading, setLoading] = useState(true);
  const [isBulkEdit, setIsBulkEdit] = useState(false);

  const history = useHistory();

  // @ts-expect-error TS(2339) FIXME: Property 'canDeleteRecords' does not exist on type... Remove this comment to see the full error message
  const { canDeleteRecords } = mainStore.context;
  // @ts-expect-error TS(2339) FIXME: Property 'risk_assessment_id' does not exist on ty... Remove this comment to see the full error message
  const { risk_assessment_id, vendor_id: vendorID } = useParams();
  const { currentVendor, currentRiskAssessment: matrix } = mainStore.vendors;
  const status = mainStore.avroSchemas.valueForField(
    "status",
    currentVendor?.data,
  );
  const isActive = !currentVendor || status !== "disabled";

  useEffect(() => {
    if (!isNew || usingTemplate) {
      return;
    }

    mainStore.vendors.setCurrentRiskAssessment(EMPTY_MATRIX_DATA);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!risk_assessment_id || (isNew && !usingTemplate)) {
      return;
    }

    setLoading(true);

    const fetchRiskAssessment = async () => {
      await mainStore.vendors.getRiskAssessmentById(
        risk_assessment_id,
        usingTemplate,
      );

      setLoading(false);
    };

    fetchRiskAssessment();
  }, [risk_assessment_id]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'matrixToSave' implicitly has an 'any' t... Remove this comment to see the full error message
  const handleSaveBulk = async (matrixToSave) => {
    if (isNew) {
      const moduleWorkspaceID = mainStore.moduleWorkspaces.list?.find(
        (mw) => mw.themis_module?.identifier === "vendor_due_diligence",
      )?.id;
      await mainStore.vendors.createRiskAssessment(
        moduleWorkspaceID,
        matrixToSave,
        vendorID,
      );
    } else {
      await mainStore.vendors.batchUpdateRiskAssessment(
        risk_assessment_id,
        matrixToSave,
      );
    }

    mainStore.toast.setInfoText("Risk Assessment saved!");
    history.goBack();
  };

  const handleCancelBulkEdit = () => {
    if (isNew) {
      mainStore.vendors.setCurrentRiskAssessment(EMPTY_MATRIX_DATA);
    }
    setIsBulkEdit(false);
  };

  if (loading) {
    return <Loading loadingLayout="table" showTableHeader />;
  }

  if (isNew ? !matrix?.risk_levels?.length : !matrix?.id) {
    return <RecordNotFound />;
  }

  if (isBulkEdit) {
    return (
      <RiskAssessmentBulkView
        vendorID={vendorID}
        matrix={matrix}
        canDelete={canDeleteRecords || isNew}
        isActive={isActive}
        isNew={isNew}
        isTemplate={isTemplate}
        // @ts-expect-error TS(2322) FIXME: Type '{ workspaceID: number | undefined; vendorID:... Remove this comment to see the full error message
        usingTemplate={usingTemplate}
        onSave={handleSaveBulk}
        onCancel={handleCancelBulkEdit}
      />
    );
  }

  return (
    <RiskAssessmentNormalView
      vendorID={vendorID}
      matrix={matrix}
      isActive={isActive}
      isNew={isNew}
      isTemplate={isTemplate}
      usingTemplate={usingTemplate}
      setIsBulkEdit={setIsBulkEdit}
    />
  );
}

BuildRiskAssessment.defaultProps = {
  isNew: false,
  isTemplate: false,
  usingTemplate: false,
};

export default observer(BuildRiskAssessment);
