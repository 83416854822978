import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { useMainStore } from "@/contexts/Store";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import ListTitleSettings from "../../table/settings/ListTitleSettings";
import AddModules from "./AddModules";
import TableElement from "./TableElement";
import ViewUsers from "./ViewUsers";

export const BUILTIN_THEMIS_MODULES: ModuleIdentifier[] = [
  "policy",
  "procedures",
  "documents",
];

const HEADERS = [
  {
    field_name: "module_name",
    title: "Module Name",
    disabled: true,
    width: "25%",
  },
  {
    field_name: "module_description",
    title: "Module Description",
    width: "35%",
  },
  {
    field_name: "module_users",
    title: "Module Users",
    width: "25%",
  },
  {
    field_name: "module_config",
    title: "",
    width: "15%",
  },
];

function TableContents() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [selectedModuleWorkspaceID, setSelectedModuleWorkspaceID] = useState<
    null | number
  >(null);

  // Variables
  const { moduleInfo, list: moduleWorkspaces } = mainStore.moduleWorkspaces;
  const { active_workspace: activeWorkspace } = mainStore.users.user;

  const websiteMonitoringFeatureEnabled = mainStore.featureFlags.getIsEnabled(
    FEATURE_FLAG_ID.COM_WEBSITE_MONITORING,
  );

  // Hooks
  useEffect(() => {
    const activeWorkspaceID = activeWorkspace?.id;
    if (activeWorkspaceID) {
      mainStore.users.indexForModules({ workspaceID: activeWorkspaceID });
    }
  }, [activeWorkspace]);

  // Functions
  function onCloseUserView() {
    setSelectedModuleWorkspaceID(null);
  }

  // Elements
  const renderHeaders = HEADERS.map((header) => (
    <ListTitleSettings
      key={header.field_name}
      width={header.width}
      title={header.title}
      fieldName={header.field_name}
    />
  ));

  const filteredModules = moduleWorkspaces
    .filter(
      (moduleWorkspace) =>
        moduleWorkspace.added &&
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        moduleInfo[moduleWorkspace.themis_module.identifier],
    )
    .filter((moduleWorkspace) => {
      const { identifier } = moduleWorkspace.themis_module;
      if (identifier === "accounts") {
        return false;
      }
      if (
        identifier === "website_monitoring" &&
        !websiteMonitoringFeatureEnabled
      ) {
        return false;
      }

      return true;
    });

  const renderRows = filteredModules.map((moduleWorkspace) => {
    const data = {
      module_name: {
        name: moduleWorkspace.name,
        icon: moduleWorkspace.themis_module.identifier,
      },
      module_config: {
        built_in: BUILTIN_THEMIS_MODULES.includes(
          moduleWorkspace.themis_module.identifier,
        ),
      },
      themis_module: moduleWorkspace.themis_module,
    };

    return (
      <TableElement
        key={moduleWorkspace.id}
        headers={HEADERS}
        data={data}
        active={selectedModuleWorkspaceID === moduleWorkspace.id}
        onOpenUsersView={() => setSelectedModuleWorkspaceID(moduleWorkspace.id)}
        onCloseUsersView={onCloseUserView}
      />
    );
  });

  return (
    <div className="workspace-dropdown workspace-dropdown-modules">
      <div className="settings-content-wrap modules-content-wrap">
        <div className="list-title-table">
          <ul>
            <div className="list-title-table-wrap">{renderHeaders}</div>
          </ul>
        </div>
        {renderRows}
      </div>
      <AddModules />
      {selectedModuleWorkspaceID && (
        <ViewUsers
          moduleWorkspaceID={selectedModuleWorkspaceID}
          onClose={() => setSelectedModuleWorkspaceID(null)}
        />
      )}
    </div>
  );
}

export default observer(TableContents);
