import { AxiosResponse } from "axios";

import { Record } from "../../";
import api from "../api";

export async function copyToModule(
  attachmentGroupID: number,
  workspaceID: number,
  moduleWorkspaceIDs: number[],
): Promise<Record[]> {
  const response = await api.post<
    {
      copy_attachment_group: {
        workspace_id: number;
        module_workspace_ids: number[];
      };
    },
    AxiosResponse<{ records: Record[] }>
  >(`/attachment_groups/${attachmentGroupID}/copy_to_module`, {
    copy_attachment_group: {
      workspace_id: workspaceID,
      module_workspace_ids: moduleWorkspaceIDs,
    },
  });

  return response.data.records;
}
