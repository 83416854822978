import classNames from "classnames";
import { observer } from "mobx-react";
import React, { ReactNode, useEffect, useRef, useState } from "react";

import { useMainStore } from "../../contexts/Store";
import PortalSlideMenu from "./PortalSlideMenu";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any[] | any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closeSlideMenu?: (...args: any[]) => any;
  open?: boolean;
  singleSlideMenu?: boolean;
  slideMenuID?: string | number;
  trigger?: ReactNode;
};

function SlideMenu({
  children,
  open,
  singleSlideMenu,
  slideMenuID,
  trigger,
  ...props
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Refs
  const refTrigger = useRef(null);

  // State
  const [initialLoad, setInitialLoad] = useState(true);
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const [closeSlideMenu, setCloseSlideMenu] = useState(false);

  // vars
  const { activeSlideMenuID } = mainStore.dynamicTable;
  const slideMenuClasses = classNames("slide-animation", {
    "close-slide-animation": closeSlideMenu,
  });

  // funcs
  function emailSlideClose() {
    setCloseSlideMenu(true);
    if (slideMenuID && activeSlideMenuID === slideMenuID) {
      mainStore.dynamicTable.setActiveSlideMenuID(null);
    }

    setTimeout(() => {
      if (props.closeSlideMenu) {
        props.closeSlideMenu();
      }
      setShowSlideMenu(false);
      setCloseSlideMenu(false);
    }, 300);
  }

  function handleClick() {
    setShowSlideMenu(true);
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | number | undefined' is ... Remove this comment to see the full error message
    mainStore.dynamicTable.setActiveSlideMenuID(slideMenuID);
  }

  // effects
  useEffect(() => {
    if (open) {
      handleClick();
    } else if (!open && singleSlideMenu) {
      emailSlideClose();
    }
  }, [open]);

  useEffect(() => {
    if (initialLoad) {
      return setInitialLoad(false);
    }
    if (!slideMenuID || activeSlideMenuID === slideMenuID) {
      return;
    }

    emailSlideClose();
  }, [activeSlideMenuID]);

  return (
    <>
      {trigger && (
        <div onClick={handleClick} ref={refTrigger} className="trigger-class">
          {trigger}
        </div>
      )}
      {showSlideMenu && (
        <PortalSlideMenu>
          <div data-testid="slide-menu" className={slideMenuClasses}>
            {React.Children.map(children, (child) => {
              if (child !== null) {
                return React.cloneElement(
                  child,
                  typeof child.type !== "string" ? { emailSlideClose } : {},
                );
              }
            })}
          </div>
        </PortalSlideMenu>
      )}
    </>
  );
}

export default observer(SlideMenu);
