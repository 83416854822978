import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  HeaderTabs,
  IconButton,
} from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";
import { PiArchiveBold, PiDotsThreeBold } from "react-icons/pi";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { getSelectedTab } from "@/components/helpers/Tabs";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import { useMainStore } from "@/contexts/Store";
import AuditTrailButton from "@/features/misc/AuditTrailButton";
import ExportButton from "@/features/misc/ExportButton";
import ImportButton from "@/features/misc/ImportButton";

import { MY_TASKS_ROUTES } from ".";
import TasksTable from "../components/old-table/TasksTable";
import StatusFilterButton from "../components/StatusFilterButton";
import {
  allTableColumns,
  assignedToMeTableColumns,
  assignedToOthersTableColumns,
  createdByMeTableColumns,
} from "../config/myTasksTableConfig";

const TASKS_RECORD_TYPE = "tasks";

function MyTasksPage() {
  const history = useHistory();

  const TABS = [
    {
      name: "My Tasks",
      key: "assigned-to-me",
      value: MY_TASKS_ROUTES.assignedToMeView,
    },
    {
      name: "Created by me",
      key: "created-by-me",
      value: MY_TASKS_ROUTES.createdByMeView,
    },
    {
      name: "Assigned to others",
      key: "assigned-to-others",
      value: MY_TASKS_ROUTES.assignedToOthersView,
    },
    {
      name: "All",
      key: "all",
      value: MY_TASKS_ROUTES.allView,
    },
  ];

  const isAssignedToMeView = useRouteMatch(MY_TASKS_ROUTES.assignedToMeView);

  const {
    taskDetail,
    users: { user },
  } = useMainStore();

  return (
    <PageLayout>
      <Header
        title="Tasks"
        HeaderOptions={
          <>
            <AuditTrailButton recordType={TASKS_RECORD_TYPE} />
            <Button
              size="sm"
              onClick={() =>
                taskDetail.open(
                  isAssignedToMeView
                    ? {
                        assignee_id: user.id,
                        collaborator_ids: [user.id!],
                      }
                    : undefined,
                )
              }
            >
              Add New Task
            </Button>
            <ExportButton recordType={TASKS_RECORD_TYPE} />
            <ImportButton recordType={TASKS_RECORD_TYPE} />
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <IconButton Icon={PiDotsThreeBold} color="tertiary" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={() => history.push(MY_TASKS_ROUTES.archived)}
                  className="tw-flex tw-items-center tw-gap-2"
                >
                  <PiArchiveBold />
                  View Archived Tasks
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        }
      />
      <div className="tw-flex tw-h-10 tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primaryDim-100 tw-bg-white tw-px-6">
        <HeaderTabs
          tabs={TABS}
          selectedTab={getSelectedTab(TABS)?.value || ""}
          isLink
        />
        <StatusFilterButton />
      </div>
      <PageContent>
        <Switch>
          <Route path={MY_TASKS_ROUTES.assignedToMeView}>
            <TasksTable tableColumns={assignedToMeTableColumns} />
          </Route>
          <Route path={MY_TASKS_ROUTES.createdByMeView}>
            <TasksTable tableColumns={createdByMeTableColumns} />
          </Route>
          <Route path={MY_TASKS_ROUTES.assignedToOthersView}>
            <TasksTable tableColumns={assignedToOthersTableColumns} />
          </Route>
          <Route path={MY_TASKS_ROUTES.allView}>
            <TasksTable tableColumns={allTableColumns} />
          </Route>
        </Switch>
      </PageContent>
    </PageLayout>
  );
}

export default observer(MyTasksPage);
