import api from "../api";
import { RecordVersionResponse } from "./types";

export async function fetchRecordVersion(recordVersionID: number) {
  const response = await api.get<RecordVersionResponse>(
    `record_versions/${recordVersionID}`,
  );

  return response.data;
}
