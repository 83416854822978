import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { userColors } from "../../../constants";
import { Icon } from "../../../Elements";
import UsersCircle from "../UsersCircle";

const MAX_USERS_VIEW_COUNT = 3;
const AWAITING_VERIFICATION_STATUS = "Awaiting Verification";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items?: any[];
  locked?: boolean;
  pinned?: boolean;
  width?: number;
};

function ActionPlanOwners({ width, items, pinned, locked }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  // Variables
  const userIDs = Array.from(
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    new Set(items.map((item) => item.action_plan_owners).flat()),
  );
  const isEmpty = !userIDs.length;
  const liClassNames = classNames("cell action-plan-owners-cell", {
    pinned,
    "read-only": isCurrentWorkspaceActive,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "table-cell--disabled": isReadOnly,
  });

  // elements
  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  const renderUsersList = (ids) => (
    <div className="users">
      {mainStore.contacts.list
        .concat(mainStore.users.allUsers)
        .filter((user) => ids?.includes(user.id))
        .slice(0, MAX_USERS_VIEW_COUNT)
        .map((user) => (
          <div key={user.id} className="users-circle-block">
            <UsersCircle
              user={user}
              showFullName={false}
              additionalStyles={{
                background: userColors[user.icon_color_index],
              }}
              isAwaitingVerification={
                user.status === AWAITING_VERIFICATION_STATUS ||
                user.awaiting_verification
              }
            />
          </div>
        ))}
      {ids?.length > MAX_USERS_VIEW_COUNT && (
        <div className="users-circle users-count-circle">
          <span>+{ids?.length - MAX_USERS_VIEW_COUNT}</span>
        </div>
      )}
    </div>
  );

  const renderTrigger = (
    <li className={liClassNames} style={{ width }}>
      <div className="cell-content">
        <span className="action-plan-owners-value">
          {isEmpty ? (
            "N/A"
          ) : (
            <>
              {renderUsersList(userIDs)}
              <Icon name="eye" />
            </>
          )}
        </span>
      </div>
    </li>
  );

  const renderPopupContent = (
    <div className="users-dropdown action-plan-owners-popup">
      <h4>Action Plan Owners</h4>
      <hr />
      {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
      {items
        .filter((actionPlan) => actionPlan.action_plan_owners?.length)
        .map((actionPlan) => (
          <div key={actionPlan.global_id} className="action-plan-item">
            <span className="name">{actionPlan.name || "-Untitled-"}</span>
            {renderUsersList(actionPlan.action_plan_owners)}
          </div>
        ))}
    </div>
  );

  return (
    <Popup
      position="bottom right"
      trigger={renderTrigger}
      keepTooltipInside
      on="hover"
      disabled={isEmpty}
    >
      {renderPopupContent}
    </Popup>
  );
}

ActionPlanOwners.defaultProps = {
  width: 200,
  items: [],
};

export default observer(ActionPlanOwners);
