import React, { HTMLProps } from "react";

function EmailInput(
  props: HTMLProps<HTMLInputElement>,
  ref: React.Ref<HTMLInputElement>,
) {
  return (
    <div className="form-input">
      <input type="email" autoComplete="off" ref={ref} {...props} />
    </div>
  );
}

export default React.forwardRef(EmailInput);
