import { isArray } from "lodash";
import React, { useMemo } from "react";

import { useMainStore } from "@/contexts/Store";

import { getWorkspaceLogo } from "../../../../../helpers/getWorkspaceLogo";
import { iconForThemisModuleIdentifier } from "../../../../../helpers/iconForThemisModuleIdentifier";
import Cell from "./Cell";
import Create, { getPayload } from "./Create";
import EditDrawer from "./Edit";
import ReadOnlyView from "./View";

type Props = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFinish: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeWorkspace?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  company?: any;
  disabled?: boolean;
  id?: React.ReactNode;
  isAdmin?: boolean;
  isCW?: boolean;
  isWorkspaceAdmin?: boolean;
  isCurrentUser?: boolean;
  isNewRecord?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modules?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  permissionSets?: any[];
  requireInternalWorkspace?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any[] | any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workspaces?: any[];
  setHasRoleName?: React.Dispatch<React.SetStateAction<boolean>>;
};

function RolesSelect({
  id: userID,
  name,
  value: userRoles,
  permissionSets: allPermissionSets,
  workspaces: allWorkspaces,
  modules: allModules,
  activeWorkspace,
  disabled,
  company,
  isNewRecord,
  isAdmin,
  isCW,
  isWorkspaceAdmin,
  isCurrentUser,
  requireInternalWorkspace,
  onChange,
  onFinish,
  setHasRoleName,
}: Props) {
  const mainStore = useMainStore();

  const permissionSets = useMemo(() => {
    if ((isAdmin && isCW) || isWorkspaceAdmin) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      return allPermissionSets.filter(
        (permissionSet) => permissionSet.permission_type === "workspace_module",
      );
    }

    return allPermissionSets;
  }, [allPermissionSets, isAdmin, isCW, isWorkspaceAdmin]);

  const permissionSetName = useMemo(() => {
    if (isNewRecord || !isArray(userRoles) || userRoles.length === 0) {
      return "";
    }

    const userRole =
      userRoles.find((role) => role.workspace_id === activeWorkspace.id) ||
      userRoles[0];
    return userRole.permission_set.name;
  }, [permissionSets, userRoles, isNewRecord]);

  const workspaces = useMemo(() => {
    const workspacesWithoutIcon = isCW
      ? // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        allWorkspaces.filter((workspace) => workspace.id === activeWorkspace.id)
      : // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        allWorkspaces.filter(
          (workspace) => workspace.company_id === company.id,
        );

    return workspacesWithoutIcon.map((ws) => ({
      ...ws,
      icon: getWorkspaceLogo(ws).logo,
    }));
  }, [allWorkspaces, activeWorkspace, isCW, company]);

  const modules = useMemo(
    () =>
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      allModules.map((module) => ({
        ...module,
        icon: iconForThemisModuleIdentifier(module.id),
      })),
    [allModules],
  );

  const roles = useMemo(() => {
    if (!isArray(userRoles)) {
      return [];
    }

    return userRoles.map((role) => ({
      ...role,
      workspace: workspaces.find(
        (workspace) => workspace.id === role.workspace_id,
      ),
    }));
  }, [userRoles, workspaces]);

  const assignedRoles = useMemo(() => {
    if (
      roles.length === 0 ||
      workspaces.length === 0 ||
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      permissionSets.length === 0
    ) {
      return [];
    }

    const workspaceIDs = workspaces.map((workspace) => workspace.id);

    const filteredRoles = roles.filter(
      (role) =>
        workspaceIDs.includes(role.workspace_id) ||
        role.permission_set.permission_type === "company",
    );

    if (isCW) {
      return filteredRoles.filter(
        (role) =>
          (role.workspace && role.workspace.id === activeWorkspace.id) ||
          role.permission_set.permission_type === "company",
      );
    }

    return filteredRoles;
  }, [roles, activeWorkspace, workspaces, permissionSets, isCW]);

  const editingRole = useMemo(
    () => roles.find((role) => role.workspace_id === activeWorkspace.id),
    [roles, activeWorkspace],
  );

  const showEditDrawer = isCW && isAdmin && !isCurrentUser;

  const handeRefreshUsers = () => {
    if (activeWorkspace?.id) {
      mainStore.users.indexForSettings({
        workspaceID: activeWorkspace.id,
        showAllUsers: true,
      });
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'selection' implicitly has an 'any' type... Remove this comment to see the full error message
  const handleEdit = async (selection) => {
    try {
      const payload = getPayload(selection);

      if (
        (editingRole.permission_set.parent_permission_id ||
          editingRole.permission_set.id) !== payload.permission_set_id
      ) {
        await mainStore.users.unassignRole(userID, editingRole.id);
      }

      await mainStore.users.assignRoles(userID, payload);
      mainStore.toast.setInfoText("User role has been updated.");
      onFinish();
      handeRefreshUsers();
    } catch {
      mainStore.toast.setErrorText("Failed to update user role.");
    }
  };

  if (disabled) {
    return (
      <Cell
        company={company}
        permissionSetName={permissionSetName}
        roles={assignedRoles}
      />
    );
  }

  if (!isNewRecord) {
    return showEditDrawer && editingRole ? (
      <EditDrawer
        permissionSetName={permissionSetName}
        permissionSets={permissionSets}
        modules={modules}
        // @ts-expect-error TS(2322) FIXME: Type '{ permissionSetName: any; permissionSets: an... Remove this comment to see the full error message
        activeWorkspace={activeWorkspace}
        roles={roles}
        editingRole={editingRole}
        onSubmit={handleEdit}
      />
    ) : (
      <ReadOnlyView
        // @ts-expect-error TS(2322) FIXME: Type 'ReactNode' is not assignable to type 'number... Remove this comment to see the full error message
        userID={userID}
        permissionSetName={permissionSetName}
        workspaces={workspaces}
        modules={modules}
        roles={roles}
      />
    );
  }

  return (
    <Create
      permissionSets={permissionSets}
      workspaces={workspaces}
      modules={modules}
      requireInternalWorkspace={requireInternalWorkspace}
      onChange={(newValue) => onChange(name, newValue)}
      setHasRoleName={setHasRoleName}
    />
  );
}

RolesSelect.defaultProps = {
  permissionSets: [],
  workspaces: [],
  modules: [],
  company: {},
  isNewRecord: false,
  isAdmin: false,
  isCW: false,
  isWorkspaceAdmin: false,
  isCurrentUser: false,
  requireInternalWorkspace: false,
};

export default RolesSelect;
