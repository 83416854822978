import classNames from "classnames";
import _ from "lodash";
import React from "react";

import { Typography } from "../../../Elements";

export const MODE = {
  dark: "dark",
  light: "light",
};

const TEXT_COLOR_BY_MODE = {
  [MODE.dark]: "generalWhite",
  [MODE.light]: "generalMidnightDark",
};

type Props = {
  data: number | string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mode?: any; // TODO: PropTypes.oneOf(Object.values(MODE))
};

const DataTile = ({ data, label, mode }: Props) => {
  const className = classNames("data-tile", {
    "data-tile--dark-mode": mode === MODE.dark,
  });
  const color = TEXT_COLOR_BY_MODE[mode];
  return (
    <div className={className}>
      <Typography
        label={_.toString(data || "--")}
        size="lgh"
        // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '"generalW... Remove this comment to see the full error message
        color={color}
        weight="semiBold"
      />
      {/* @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '"generalW... Remove this comment to see the full error message */}
      <Typography label={label} size="sm" color={color} weight="semiBold" />
    </div>
  );
};

DataTile.defaultProps = {
  mode: MODE.light,
};

export default DataTile;
