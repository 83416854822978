import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { useMainStore } from "@/contexts/Store";

import { Button, Typography } from "../../Elements";
import DuplicateWorkspaceLoader from "./DuplicateWorkspaceLoader";
import { WorkspaceRadioButtons } from "./WorkspaceRadioButtons";

type SelectWorkspaceProps = {
  workspaceId: string;
  onWorkspaceChange: (id: string) => void;
  onContinue: () => void;
  onBack: () => void;
};

const SelectWorkspace = (props: SelectWorkspaceProps) => {
  const mainStore = useMainStore();
  const isLoading = mainStore.workspaces.list.length === 0;
  const isEmpty = mainStore.workspaces.collaborativeWorkspaces.length === 0;

  useEffect(() => {
    if (!props.workspaceId && !isEmpty) {
      props.onWorkspaceChange(
        mainStore.workspaces.collaborativeWorkspaces[0].id.toString(),
      );
    }
  }, [props.workspaceId, isEmpty]);

  return (
    <div className="select-workspace">
      <Typography
        label="Select an existing workspace below"
        weight="semiBold"
        color="generalDarkGray"
      />
      <div className="select-workspace__workspaces">
        <Typography
          label="All Workspaces"
          weight="semiBold"
          color="generalDark"
          size="lg"
        />
        <WorkspaceRadioButtons
          workspaces={mainStore.workspaces.collaborativeWorkspaces}
          workspaceId={props.workspaceId}
          onWorkspaceChange={props.onWorkspaceChange}
        />

        {isLoading && <DuplicateWorkspaceLoader />}

        {!isLoading && isEmpty && (
          <div className="select-workspace__empty">
            <Typography
              label="There are no workspaces available"
              size="md"
              color="generalMidnightDark"
              weight="semiBold"
            />
            <Typography
              label="Only existing Collaborative workspaces can be duplicated"
              color="generalDarkGray"
            />
          </div>
        )}
      </div>
      <div className="select-workspace__actions">
        <Button label="Back" theme="tertiary" onClick={props.onBack} />
        <Button
          label="Continue"
          onClick={props.onContinue}
          disabled={!props.workspaceId}
        />
      </div>
    </div>
  );
};

export default observer(SelectWorkspace);
