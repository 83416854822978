import { IconButton } from "@themis/ui";
import React, { useState } from "react";
import { PiXBold } from "react-icons/pi";
import Popup from "reactjs-popup";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onNo?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onYes?: (...args: any[]) => any;
  shouldUpdateStateOnYes?: boolean;
};

function CloseConfirmationDialog({
  onNo,
  onYes,
  shouldUpdateStateOnYes,
}: Props) {
  // State
  const [showPopup, setShowPopup] = useState(false);

  function yesButtonPressed() {
    if (shouldUpdateStateOnYes) {
      setShowPopup(false);
    }
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onYes();
  }

  function noButtonPressed() {
    setShowPopup(false);
    onNo?.();
  }

  return (
    <div>
      <Popup
        position="bottom right"
        trigger={() => <IconButton color="tertiary" size="lg" Icon={PiXBold} />}
        open={showPopup}
        onOpen={() => {
          setShowPopup(true);
        }}
        onClose={() => {
          setShowPopup(false);
        }}
        keepTooltipInside
      >
        <div className="table-dropdown">
          <h4>Cancel Unsaved Info</h4>
          <p>
            Do you want to leave without saving? Confirming will quit without
            making any changes to the fields.
          </p>
          <div>
            <button onClick={yesButtonPressed}>Yes</button>
            <button onClick={noButtonPressed}>No</button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

CloseConfirmationDialog.defaultProps = {
  shouldUpdateStateOnYes: true,
};

export default CloseConfirmationDialog;
