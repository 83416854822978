import { PreviewTable } from "@themis/ui";
import React from "react";
import { useRouteMatch } from "react-router-dom";

import { Contract } from "@/api";

import { CONTRACTS_COLUMNS } from "../../config/contractsTable";

export function AccountContracts({ contracts }: { contracts: Contract[] }) {
  const { url } = useRouteMatch();

  return (
    <PreviewTable
      rows={contracts}
      columns={CONTRACTS_COLUMNS}
      addRow={{
        text: "Add Contract",
        linkTo: (location) => ({
          pathname: `${url}/new`,
          state: { from: location.pathname },
        }),
      }}
    />
  );
}
