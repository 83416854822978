import { useEffect } from "react";

import { CommentTab } from "@/components/table/shared/comments/CommentsSlideMenu/types";
import { useSearchParams } from "@/hooks/useSearchParams";
import { isSelectedCommentPrivate } from "@/stores/helpers/CommentsHelpers";
import { UserComment } from "@/stores/types/comment-types";

export function useSwitchToSelectedCommentTab(privateComments: UserComment[]) {
  const [searchParams, setSearchParams] = useSearchParams<{
    comments_tab?: CommentTab;
    comment_id?: string;
  }>();

  const selectedCommentID = Number(searchParams.comment_id);

  useEffect(() => {
    // Checks if comment id in search params is private.
    // Then switches to correct internal tab if true.
    // (e.g. copy link of a private comment)
    if (isSelectedCommentPrivate(privateComments, selectedCommentID)) {
      setSearchParams({ ...searchParams, comments_tab: "internal" }, true);
    }
  }, [privateComments, selectedCommentID]);
}
