import dayjs from "dayjs";
import React from "react";

import arrowLeftIcon from "../../../images/dashboard/arrow-left-black.svg";
import arrowRightIcon from "../../../images/dashboard/arrow-right-black.svg";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedMonth?: string | any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedMonth?: (...args: any[]) => any;
};

function MonthPicker({ selectedMonth, setSelectedMonth }: Props) {
  const goToLastMonth = () => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setSelectedMonth(dayjs(selectedMonth).subtract(1, "M"));
  };

  const goToNextMonth = () => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setSelectedMonth(dayjs(selectedMonth).add(1, "M"));
  };

  const displayedDate = `${selectedMonth.format(
    "MMMM",
  )}, ${selectedMonth.format("YYYY")}`;

  return (
    <div className="test-schedule-select-date">
      <div
        className="arrow-icon"
        onClick={goToLastMonth}
        data-testid="last-month"
      >
        <img src={arrowLeftIcon} alt="arrow-left-icon" />
      </div>
      <div>{displayedDate}</div>
      <div
        className="arrow-icon"
        onClick={goToNextMonth}
        data-testid="next-month"
      >
        <img src={arrowRightIcon} alt="arrow-right-icon" />
      </div>
    </div>
  );
}

export default MonthPicker;
