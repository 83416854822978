import React from "react";

import { Typography } from "../../Elements";

type Props = {
  count?: number;
  label?: string;
};

const Tile = ({ label, count }: Props) => (
  <div className="reports-tile">
    <Typography
      label={label}
      color="generalMidnightDark"
      size="xs"
      weight="bold"
    />
    <Typography
      label={count || "0"}
      color="generalMidnightDark"
      size="lg"
      weight="bold"
    />
  </div>
);

export default Tile;
