import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MiniTag,
  Select,
  TextArea,
  TextInput,
} from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import type { UpdateRecordVersionRequest } from "@/api/gen/models/UpdateRecordVersionRequest";

import { getRatingColorName } from "../config";

const formSchema = z.object({
  id: z.number(),
  status: z.string().nullable(),
  control_objective: z.string().nullable(),
  control_classification: z.string().nullable(),
  control_placement: z.string().nullable(),
  control_frequency: z.string().nullable(),
  key_control: z.string().nullable(),
  control_category: z.string().nullable(),
  type: z.string().nullable(),
  control_title: z.string().min(1, "Title is required"),
  department: z.string().nullable(),
  description: z.string().min(1, "Description is required"),
  control_type: z.string().nullable(),
  control_effectiveness_rating: z
    .object({
      id: z.number(),
      title: z.string(),
      value: z.number(),
      color: z.string(),
    })
    .nullable(),
  control_owners: z.string().nullable(),
  policies_controls: z.string().nullable(),
  procedures_controls: z.string().nullable(),
  approvers: z.string().nullable(),
  created_at: z.string().nullable(),
  created_by: z.string().nullable(),
  last_updated_at: z.string().nullable(),
  last_updated_by: z.string().nullable(),
  last_closed_at: z.string().nullable(),
  last_closed_by: z.string().nullable(),
  last_reopened_at: z.string().nullable(),
  frameworks: z.string().nullable(),
});

export type CreateControlMappingSchema = z.infer<typeof formSchema>;

function CreateControlMappingForm({
  onSubmit,
  resultsValues,
  moduleWorkspaceDetails,
  defaultTitle,
  defaultDescription,
}: {
  onSubmit: (values: CreateControlMappingSchema) => void;
  resultsValues?: UpdateRecordVersionRequest;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  moduleWorkspaceDetails?: { [key: string]: any };
  defaultTitle?: string;
  defaultDescription?: string;
}) {
  const results = resultsValues?.record_version;
  const form = useForm<CreateControlMappingSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: results?.id || 0,
      status: results?.status || "",
      control_objective: results?.control_objective || "",
      control_classification: results?.control_classification || "",
      control_placement: results?.control_placement || "",
      control_frequency: results?.control_frequency || "",
      key_control: results?.key_control || "",
      control_category: results?.control_category || "",
      type: results?.type || "",
      control_title: results?.control_title || defaultTitle || "",
      department: results?.department || "",
      description: results?.description || defaultDescription || "",
      control_type: results?.control_type || "",
      control_effectiveness_rating: results?.control_effectiveness_rating || "",
      control_owners: results?.control_owners || "",
      policies_controls: results?.policies_controls || "",
      procedures_controls: results?.procedures_controls || "",
      approvers: results?.approvers || "",
      created_at: results?.created_at || "",
      created_by: results?.created_by || "",
      last_updated_at: results?.last_updated_at || "",
      last_updated_by: results?.last_updated_by || "",
      last_closed_at: results?.last_closed_at || "",
      last_closed_by: results?.last_closed_by || "",
      last_reopened_at: results?.last_reopened_at || "",
      frameworks: results?.frameworks || "",
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.handleSubmit(onSubmit)();
  };

  const fieldOptions = moduleWorkspaceDetails?.module_workspace?.field_options;

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit}
        className="tw-flex tw-h-full tw-flex-col tw-gap-3"
      >
        <FormField
          required
          control={form.control}
          name="control_title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Control Title</FormLabel>
              <FormControl>
                <TextInput {...field} value={field?.value || ""} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <TextArea {...field} value={field?.value || ""} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="control_objective"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Control Objective</FormLabel>
              <FormControl>
                <TextInput {...field} value={field?.value || ""} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="control_placement"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Control Placement</FormLabel>
              <FormControl>
                <Select
                  items={
                    fieldOptions?.control_placement?.map(
                      (placement: { [key: string]: string }) => ({
                        label: placement?.display_name,
                        value: placement?.name,
                        Component: () => (
                          <MiniTag>{placement?.display_name}</MiniTag>
                        ),
                      }),
                    ) || []
                  }
                  selected={field.value}
                  onSelect={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="department"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Department</FormLabel>
              <FormControl>
                <Select
                  items={
                    moduleWorkspaceDetails?.departments?.map(
                      (department: { [key: string]: string }) => ({
                        label: department?.title,
                        value: department?.id.toString(),
                        Component: () => (
                          <MiniTag className="tw-bg-neutral-25 tw-text-secondary-300">
                            {department?.title}
                          </MiniTag>
                        ),
                      }),
                    ) || []
                  }
                  selected={field.value}
                  onSelect={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="control_type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Type</FormLabel>
              <FormControl>
                <Select
                  items={
                    fieldOptions?.control_type?.map(
                      (control: { [key: string]: string }) => ({
                        label: control?.display_name,
                        value: control?.name,
                        Component: () => (
                          <MiniTag color={"primary"}>
                            {control?.display_name}
                          </MiniTag>
                        ),
                      }),
                    ) || []
                  }
                  selected={field.value}
                  onSelect={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="control_frequency"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Frequency</FormLabel>
              <FormControl>
                <Select
                  items={
                    fieldOptions?.control_frequency?.map(
                      (control: { [key: string]: string }) => ({
                        label: control?.display_name,
                        value: control?.name,
                        Component: () => (
                          <MiniTag color={"turquoise"}>
                            {control?.display_name}
                          </MiniTag>
                        ),
                      }),
                    ) || []
                  }
                  selected={field.value}
                  onSelect={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="control_effectiveness_rating"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Control Effectiveness Rating</FormLabel>
              <FormControl>
                <Select
                  items={
                    moduleWorkspaceDetails?.column_scoring_matrix_ratings?.map(
                      (rating: { [key: string]: string }) => ({
                        label: rating?.title,
                        value: rating?.id.toString(),
                        Component: () => (
                          <MiniTag color={getRatingColorName(rating.color)}>
                            {rating?.title}
                          </MiniTag>
                        ),
                      }),
                    ) || []
                  }
                  selected={field.value?.id?.toString()}
                  onSelect={(value: string) => {
                    field.onChange(
                      moduleWorkspaceDetails?.column_scoring_matrix_ratings?.find(
                        (rating: { id: number }) =>
                          rating.id.toString() === value,
                      ),
                    );
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button size="lg" disabled={!form} type="submit">
          Publish
        </Button>
      </form>
    </Form>
  );
}
export default observer(CreateControlMappingForm);
