import "./styles.scss";

import classNames from "classnames";
import { noop } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

import { RecordVersion } from "@/api";
import { isPresent } from "@/components/filters/helpers";
import { useMainStore } from "@/contexts/Store";
import { RelatedFindingsLinkTooltip } from "@/features/issue-management/components/RelatedFindingsLinkTooltip";

import warningIcon from "../../../../images/table-image/icon/warning-icon.svg";

export type InputCellProps = {
  fieldName: string;
  hasErrors?: boolean;
  width: number | string;
  columnTitle?: string;
  dataTestId?: string;
  disabled?: boolean;
  hasReadWriteAccess?: boolean;
  errorMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exposeData?: (...args: any[]) => any;
  hasErrorClass?: string;
  initialValue?: number | string;
  isUserEditable?: boolean;
  locked?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange?: (...args: any[]) => any;
  pattern?: string;
  pinned?: boolean;
  performValidations?: ({
    recordVersionID,
    fieldName,
    value,
  }: {
    recordVersionID: number | undefined;
    fieldName: string;
    value: string;
  }) => string | undefined;

  recordVersionID?: number;
  step?: string;
  type?: string;
  hasShouldPersist?: boolean;
  recordVersion?: RecordVersion;
  handleUpdate?: (
    fieldName: string,
    value: string,
    recordVersion: RecordVersion,
  ) => void;
};

function InputCell({
  hasReadWriteAccess,
  columnTitle,
  dataTestId,
  disabled,
  errorMessage,
  exposeData,
  fieldName,
  hasErrorClass,
  hasErrors = false,
  initialValue,
  isUserEditable,
  locked,
  onDataChange,
  pattern,
  pinned,
  performValidations,
  recordVersionID,
  step,
  type,
  width,
  hasShouldPersist,
  recordVersion,
  handleUpdate,
}: InputCellProps) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly =
    !hasReadWriteAccess &&
    (!hasModuleWriteAccess || isCurrentWorkspaceArchived);

  // Refs
  const textInput = useRef(null);

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [value, setValueHelper] = useState("");
  const [cellErrors, setCellErrors] = useState<string | undefined>(
    errorMessage,
  );
  const isLockedOrDisabled = locked || disabled;
  let inputPlaceholder;

  // Effects

  useEffect(() => {
    setCellErrors(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    const initialDisplayValue = isPresent(initialValue) ? initialValue : "";
    setValue(initialDisplayValue);
  }, [initialValue]);

  const inputOnWheelPreventChange: React.WheelEventHandler<HTMLInputElement> = (
    e,
  ) => {
    // Prevent the input value change
    e.currentTarget.blur();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.currentTarget.focus();
    }, 0);
  };
  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  const setValue = (val) => {
    setValueHelper(val);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    exposeData(val);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleMouseDown = (event) => {
    if (event.target.classList.contains("input-cell")) {
      event.preventDefault();
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Blurring input will trigger persistence of data via onBlur
      // @ts-expect-error TS(2339) FIXME: Property 'blur' does not exist on type 'never'.
      textInput.current?.blur();
    }
  };

  const onBlur = () => {
    setIsActive(false);
    persistData();
  };

  const handleClick = () => {
    if (!disabled) {
      setIsActive(true);
      // @ts-expect-error TS(2339) FIXME: Property 'focus' does not exist on type 'never'.
      textInput.current?.focus();
    }
  };

  const persistData = () => {
    const errors = performValidations?.({
      recordVersionID,
      fieldName,
      value,
    });
    setCellErrors(errors);

    if (errors) {
      return;
    }

    const serializeValue = mainStore.avroSchemas.serializeValue(
      fieldName,
      value,
    );

    if (value === initialValue && !hasShouldPersist) {
      return;
    }

    if (typeof onDataChange === "function") {
      onDataChange(fieldName, serializeValue, value);
    } else if (handleUpdate && recordVersion) {
      handleUpdate(fieldName, value, recordVersion);
    } else {
      mainStore.recordVersions.update({
        recordVersionID,
        fieldName,
        value: serializeValue,
      });
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleChange = (event) => {
    {
      const inputValue = event.target.value;

      setValue(inputValue);
    }
  };

  const onPopupOpen = () => {
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  const style = { width };
  const liClassNames = classNames("cell input-cell", {
    active: isActive || showPopup,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [hasErrorClass]: hasErrors || cellErrors,
    "read-only": !isUserEditable && isCurrentWorkspaceActive,
    "table-cell--disabled": disabled,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "pointer-events-none": isLockedOrDisabled || isReadOnly,
    pinned,
  });

  const trigger = (
    <li
      style={style}
      className={liClassNames}
      onClick={() => {
        onPopupClose();
        handleClick();
      }}
      onMouseDown={handleMouseDown}
      onBlur={onBlur}
    >
      <div className="cell-content">
        <input
          ref={textInput}
          type={type}
          disabled={isLockedOrDisabled}
          value={value}
          placeholder={errorMessage}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      </div>
    </li>
  );

  if (cellErrors === undefined) {
    switch (columnTitle) {
      case "Name":
        inputPlaceholder = isLockedOrDisabled ? "N/A" : "Enter a name";
        break;
      default:
        inputPlaceholder = isLockedOrDisabled ? "N/A" : "Type here";
    }
  } else {
    inputPlaceholder = cellErrors;
  }

  if (cellErrors !== undefined) {
    return (
      <Popup
        position="bottom right"
        on="hover"
        trigger={trigger}
        open={showPopup}
        onOpen={onPopupOpen}
        onClose={onPopupClose}
        keepTooltipInside
      >
        <div className="table-dropdown error">
          <ul className="errors">
            <li>
              <img src={warningIcon} alt="warning-icon" />
              {cellErrors}
            </li>
          </ul>
        </div>
      </Popup>
    );
  }

  return (
    <li
      style={style}
      className={liClassNames}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onBlur={onBlur}
    >
      <div className="cell-content">
        {type === "number" && (
          <input
            ref={textInput}
            type={type}
            data-testid={dataTestId}
            disabled={isLockedOrDisabled}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={isLockedOrDisabled ? "" : inputPlaceholder}
            step={step}
            onWheel={inputOnWheelPreventChange}
            pattern={pattern}
          />
        )}

        {type !== "number" && (
          <input
            ref={textInput}
            type={type}
            data-testid={dataTestId}
            disabled={isLockedOrDisabled}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={inputPlaceholder}
          />
        )}
        {recordVersion?.global_id &&
          recordVersion?.record.linked_findings &&
          Boolean(recordVersion.record.linked_findings.length) && (
            <RelatedFindingsLinkTooltip
              linkedFindings={recordVersion.record.linked_findings}
              globalId={recordVersion.global_id}
            />
          )}
      </div>
    </li>
  );
}

InputCell.defaultProps = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  exposeData: (val) => val,
  performValidations: noop,
  dataTestId: "input-cell",
  hasErrorClass: "has-errors",
};

export default InputCell;
