import "./styles.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiChatCircleDotsBold } from "react-icons/pi";

import { UncompletedCommentsCount } from "@/api";
import { Button } from "@/components/Elements";
import { useSwitchToSelectedCommentTab } from "@/components/settings/notificationsCenter/tasks/hooks/useSwitchToSelectedCommentTab";
import SlideMenu from "@/components/slideMenu/SlideMenu";
import { getCommentCount } from "@/components/table/shared/comments/CommentsSlideMenu/helpers";
import { useMainStore } from "@/contexts/Store";
import { useSearchParams } from "@/hooks/useSearchParams";
import {
  CommentTypeProperty,
  TaskableType,
} from "@/stores/types/comment-types";

import CommentsSlideMenuContent, {
  SearchParamsProps,
} from "./CommentsSlideMenuContent";

export type CommentsSlideMenuProps = {
  isLockedRow?: boolean;
  taskableType: TaskableType;
  globalID?: string;
  recordID?: number;
  /**
   * Will render comments button instead of MiniTag comment count trigger
   * (Used in Detail Pages)
   */
  renderCommentsButton?: boolean;
  uncompletedCommentsCount?: UncompletedCommentsCount;
  CustomTrigger?: React.FC;
  SubHeader?: React.FC;
  title?: string;
  hideEmptyState?: boolean;
  isIconButton?: boolean;
};

function CommentsSlideMenu({
  isLockedRow,
  taskableType,
  renderCommentsButton,
  uncompletedCommentsCount,
  globalID,
  recordID,
  CustomTrigger,
  SubHeader,
  title = "Comments",
  hideEmptyState,
  isIconButton,
}: CommentsSlideMenuProps) {
  // Import MobX stores
  const mainStore = useMainStore();

  const [searchParams, setSearchParams] = useSearchParams<SearchParamsProps>();

  const commentCount: number = getCommentCount(
    Boolean(mainStore.context.activeWorkspace?.user_can_view_private_comments),
    uncompletedCommentsCount,
  );

  const { recordID: selectedRecordID, recordType: selectedRecordType } =
    mainStore.comments;

  // State
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const [singleSlideMenu, setSingleSlideMenu] = useState(false);

  useSwitchToSelectedCommentTab(mainStore.comments.privateComments);

  async function indexComments() {
    if (!recordID) {
      return;
    }

    let indexParams: null | CommentTypeProperty = null;

    if (taskableType === "Record") {
      indexParams = { record_id: recordID };
    } else if (taskableType === "BaseChecklist") {
      indexParams = { template_id: recordID };
    } else if (taskableType === "Question") {
      indexParams = { question_id: recordID };
    } else if (taskableType === "QuestionGroup") {
      indexParams = { question_group_id: recordID };
    } else if (taskableType === "QuestionnaireSummary") {
      indexParams = { questionnaire_id: recordID };
    } else {
      mainStore.toast.setErrorText("Something went wrong");
      return;
    }
    await mainStore.comments.index(indexParams);
  }

  function clearSearchParams() {
    setSearchParams(
      {
        ...searchParams,
        template_id: undefined,
        comments_tab: undefined,
        comment_id: undefined,
        question_id: undefined,
        question_group_id: undefined,
        questionnaire_id: undefined,
      },
      true,
    );
  }

  function openSlideMenu() {
    mainStore.comments.setRecordID(recordID || null);
    mainStore.comments.setRecordType(taskableType);
    setShowSlideMenu(true);
    return indexComments();
  }

  function closeSlideMenu() {
    setShowSlideMenu(false);
    clearSearchParams();
    if (recordID === selectedRecordID) {
      mainStore.comments.setRecordID(null);
    }
  }

  useEffect(() => {
    if (selectedRecordType) {
      return;
    }

    if (
      (recordID && selectedRecordID === recordID) ||
      (!!searchParams.comment_id &&
        !searchParams.question_id &&
        /**
         * This is a temporary fix required for questionnaire
         * templates. It will be removed once the search
         * parameters are capable of managing the opening and
         * closing of the comments slide menu, thereby
         * eliminating the need for useEffects.
         */
        (!searchParams.template_id ||
          Number(searchParams.template_id) === recordID)) ||
      (!!searchParams.question_id &&
        Number(searchParams.question_id) === recordID) ||
      (!!searchParams.questionnaire_id &&
        Number(searchParams.questionnaire_id) === recordID)
    ) {
      setShowSlideMenu(true);
      setSingleSlideMenu(true);
    } else {
      setShowSlideMenu(false);
    }
  }, [selectedRecordID, recordID, searchParams]);

  const triggerClasses = classNames({
    active: showSlideMenu,
    "has-tasks": commentCount > 0,
    "tasks-locked-row": isLockedRow,
  });

  const taskTrigger = classNames({
    "task-trigger":
      taskableType === "BaseChecklist" ||
      taskableType === "AttachedDocument" ||
      taskableType === "Question",
  });

  const renderTrigger = () => {
    if (CustomTrigger) {
      return (
        <div onClick={openSlideMenu}>
          <CustomTrigger />
        </div>
      );
    }

    if (renderCommentsButton) {
      return (
        <Button
          size="sm"
          type="button"
          className="comments-slide-menu__comments-button"
          label={`Comments ${commentCount ? `(${commentCount})` : ""}`}
          fontWeight="semiBold"
          onClick={openSlideMenu}
        />
      );
    }

    return (
      <div className={taskTrigger}>
        {isIconButton ? (
          <span
            className={triggerClasses}
            onClick={openSlideMenu}
            data-testid="tasks-trigger"
            data-tooltip-id="tooltip"
            data-tooltip-content="View and add comments"
            data-tooltip-place="bottom"
          >
            <PiChatCircleDotsBold className="tw-h-5 tw-w-5 tw-items-center tw-justify-between" />
          </span>
        ) : (
          <span
            className={triggerClasses}
            onClick={openSlideMenu}
            data-testid="tasks-trigger"
            data-tooltip-id="tooltip"
            data-tooltip-content="View and add comments"
            data-tooltip-place="bottom"
          >
            {commentCount}
          </span>
        )}
      </div>
    );
  };

  return (
    <>
      {renderTrigger()}
      {showSlideMenu && (
        <SlideMenu
          open={showSlideMenu}
          closeSlideMenu={closeSlideMenu}
          singleSlideMenu={singleSlideMenu}
        >
          <CommentsSlideMenuContent
            searchParams={searchParams}
            taskableType={taskableType}
            globalID={globalID}
            recordID={recordID}
            SubHeader={SubHeader}
            title={title}
            hideEmptyState={hideEmptyState}
            closeSlideMenu={closeSlideMenu}
          />
        </SlideMenu>
      )}
    </>
  );
}

export default observer(CommentsSlideMenu);
