import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { Icon } from "../../Elements";

type Props = {
  recordID: number;
  tableID: number;
};

function VersionHistoryExport({ tableID, recordID }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Functions
  function onPopupOpen() {
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
  }

  async function processTableDownload() {
    const spreadSheetData = await mainStore.tables.exportVersionHistory(
      tableID,
      recordID,
    );
    if (spreadSheetData) {
      const link = document.createElement("a");
      const blob = new Blob([new Uint8Array(spreadSheetData)], {
        type: "application/octet-stream",
      });
      link.href = URL.createObjectURL(blob);
      link.download = "version_history.xls";
      link.click();
    }
    setShowPopup(false);
  }

  // elements
  const renderTrigger = (
    <button
      className={classNames("import-button library-export", {
        active: showPopup,
      })}
    >
      <Icon name="export" color="generalWhite" size="de" />
      Export
    </button>
  );

  const renderPopupContent = (
    <div className="table-dropdown export-table-dropdown">
      <h3>Export Version History to Excel</h3>
      <p>
        Click the button below to export the version history to an Excel file.
      </p>
      <button className="export-table-button" onClick={processTableDownload}>
        <Icon name="download" color="generalWhite" size="de" />
        Export to XLS File
      </button>
    </div>
  );

  return (
    <Popup
      position="bottom right"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
      nested
    >
      {renderPopupContent}
    </Popup>
  );
}

export default observer(VersionHistoryExport);
