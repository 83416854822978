import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import { MainStore } from "../Main";

export default class ControlCategoriesStore {
  mainStore: MainStore;

  // Observable objects
  controlCategories = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      controlCategories: observable,
      setControlCategories: action,
    });

    this.mainStore = mainStore;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async index(companyID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/companies/${companyID}/control_categories`,
        headers: this.mainStore.getHeaders(),
      });

      this.setControlCategories(response.data.control_categories);
    } catch (error) {
      window.console.log(
        `"ControlCategories#Index for Company" error ${error}`,
      );
    }
  }

  // Actions

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setControlCategories(value) {
    if (value) {
      this.controlCategories = value;
    } else {
      this.controlCategories = [];
    }
  }

  // Store Helpers

  cleanup() {
    this.setControlCategories([]);
  }
}
