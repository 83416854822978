import { map, take } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { useHistory } from "react-router-dom";

import { nameForThemisModuleIdentifier } from "@/components/helpers/nameForThemisModuleIdentifier";
import { useMainStore } from "@/contexts/Store";
import { UserComment } from "@/stores/types/comment-types";

import { Typography } from "../../Elements";
import ModuleLabel from "../../shared/ModuleLabel";
import { MODE } from "../content/tiles/DataTile";
import LoadingTile from "../content/tiles/LoadingTile";
import { ActionItemSection } from "./ActionItemSection";
import { SectionTitle } from "./SectionTitle";
import TitleWithContextItem from "./TitleWithContextItem";

const NUM_TASKS_TO_SHOW = 3;

const Tasks = () => {
  const history = useHistory();
  const mainStore = useMainStore();
  const [isLoading, setIsLoading] = useState(true);

  const { activeTasks } = mainStore.tasks;
  const currentWorkspaceID = mainStore.context.workspaceID;

  const fetchTasks = async () => {
    setIsLoading(true);
    await mainStore.tasks.myTasks();
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const tasksToShow = take(activeTasks, NUM_TASKS_TO_SHOW);
  const totalTasks = activeTasks?.length;

  async function goToNotifications({ task }: { task: UserComment }) {
    if (task.workspace_id !== currentWorkspaceID) {
      await mainStore.workspaces.switch(task.workspace_id);
    }
    history.push(`/notifications/tasks/${task.id}/edit`);
  }

  return (
    <ActionItemSection>
      <div className="action-item-section__header">
        <SectionTitle>My comments</SectionTitle>
      </div>
      {isLoading && <LoadingTile mode={MODE.dark} />}

      {!isLoading && (
        <>
          <div className="action-item-section__overview">
            <Typography
              label={totalTasks || "--"}
              size="lgh"
              weight="semiBold"
              color="generalWhite"
            />
            <Typography
              label="# of comments"
              size="sm"
              weight="semiBold"
              color="generalWhite"
            />
          </div>
          {map(tasksToShow, (task) => (
            <TitleWithContextItem
              key={task.id}
              globalId={task.global_id}
              title={
                <MentionsInput
                  value={task.content}
                  className="action-item-tasks__mention"
                  disabled
                >
                  <Mention data={[]} trigger="@" />
                </MentionsInput>
              }
              date={task.created_at}
              context={
                <ModuleLabel
                  moduleDisplayName={nameForThemisModuleIdentifier(
                    task.themis_module_identifier,
                  )}
                  moduleIdentifier={task.themis_module_identifier}
                  color="generalWhite"
                />
              }
              onClick={() =>
                goToNotifications({
                  task,
                })
              }
            />
          ))}
        </>
      )}
    </ActionItemSection>
  );
};

export default observer(Tasks);
