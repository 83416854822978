import "./dual-state-select.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import checkBlueIcon from "@/images/table-image/icon/options-check-blue-light.svg";

type Props<T extends FieldValues> = UseControllerProps<T> & {
  positiveText: string;
  negativeText: string;
  hasErrors?: boolean;
  width?: number | string;
  hasErrorClass?: string;
  locked?: boolean;
};

function DualStateSelectField<T extends FieldValues>({
  positiveText,
  negativeText,
  hasErrorClass = "has-errors",
  hasErrors,
  locked,
  width,
  ...controller
}: Props<T>) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const { field } = useController(controller);

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Variables
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  // Functions
  function changeFieldState(fieldState: boolean) {
    field.onChange(fieldState);
    setShowPopup(false);
  }

  // Elements
  const liClassNames = classNames("dual-state-select", {
    active: showPopup,
    [hasErrorClass]: hasErrors,
    illumination: showPopup,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "pointer-events-none": locked || isReadOnly,
    "table-cell--disabled": isReadOnly,
    "gray-background": locked,
    "disabled-li": locked,
  });

  const renderTrigger = (
    <li className={liClassNames} style={{ width }}>
      <div className="cell-content">
        <div
          className={classNames("dual-state-select__option", {
            "dual-state-select__option-positive": field.value,
            "dual-state-select__option-negative": !field.value,
          })}
        >
          {field.value ? positiveText : negativeText}
        </div>
      </div>
    </li>
  );

  const renderContent = (
    <div className={classNames("select")}>
      <div className="select-dropdown">
        {
          <ul className="select-options">
            <li
              className="select-option"
              onClick={() => changeFieldState(false)}
            >
              <div className="select-option-label-wrapper">
                <span className="select-option-label">{negativeText}</span>
              </div>
              {!field.value && (
                <span className="select-option-check-icon">
                  <img src={checkBlueIcon} alt="check-icon" />
                </span>
              )}
            </li>
            <li
              className="select-option"
              onClick={() => changeFieldState(true)}
            >
              <div className="select-option-label-wrapper">
                <span className="select-option-label">{positiveText}</span>
              </div>
              {field.value && (
                <span className="select-option-check-icon">
                  <img src={checkBlueIcon} alt="check-icon" />
                </span>
              )}
            </li>
          </ul>
        }
      </div>
    </div>
  );

  return (
    <Popup
      position="bottom left"
      trigger={renderTrigger}
      open={showPopup}
      keepTooltipInside
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
    >
      {renderContent}
    </Popup>
  );
}

export default observer(DualStateSelectField);
