import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Field, RecordVersion } from "../gen";
import {
  latestRecordVersion,
  recordVersion,
  recordVersionsUpdate,
} from "../gen/axios/recordVersionsController";
import type { LatestRecordVersionPathParams } from "../gen/models/LatestRecordVersion";
import type { RecordVersionPathParams } from "../gen/models/RecordVersion";
import type { RecordVersionsUpdateMutationRequest } from "../gen/models/RecordVersionsUpdate";
import api from "../legacy/api";

export const SHOW_RECORD_VERSIONS_QUERY_KEY = "show_record_versions";
export const LATEST_RECORD_VERSIONS_QUERY_KEY = "latest_record_version";
export const UPDATE_RECORD_VERSIONS_QUERY_KEY = "update_record_version";

export const RECORD_VERSION_QUERY_KEYS = {
  all: ["record_versions"],
  details: () => [...RECORD_VERSION_QUERY_KEYS.all, "detail"],
  detail: (recordVersionId: number) => [
    ...RECORD_VERSION_QUERY_KEYS.details(),
    recordVersionId,
  ],
} as const;

export function useRecordVersion(recordVersionId: number) {
  return useQuery({
    queryKey: RECORD_VERSION_QUERY_KEYS.detail(recordVersionId),
    queryFn: () =>
      api.get<{ record_version: RecordVersion; fields: Field[] }>(
        `/record_versions/${recordVersionId}`,
      ),
    enabled: !!recordVersionId,
  });
}

export function useShowRecordVersions(params: RecordVersionPathParams) {
  return useQuery({
    queryKey: [SHOW_RECORD_VERSIONS_QUERY_KEY, params],
    queryFn: () => recordVersion(params.id),
    enabled: !!params,
  });
}

export function useLatestRecordVersion(params: LatestRecordVersionPathParams) {
  return useQuery({
    queryKey: [LATEST_RECORD_VERSIONS_QUERY_KEY, params],
    queryFn: () => latestRecordVersion(params.id),
    enabled: !!params,
  });
}

export function useUpdateRecordVersion({
  recordVersionId,
  onSuccess = () => {},
  onError = () => {},
}: {
  recordVersionId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: RecordVersionsUpdateMutationRequest) =>
      recordVersionsUpdate(recordVersionId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [UPDATE_RECORD_VERSIONS_QUERY_KEY, recordVersionId],
      });
      onSuccess();
    },
    onError,
  });
}
