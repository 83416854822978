import { observer } from "mobx-react";
import React from "react";

import ActionPlanOwners from "../cell-type/ActionPlanOwners";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  locked?: boolean;
};

function ViewActionPlanOwners({ recordVersion, locked }: Props) {
  return (
    <ActionPlanOwners
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'number'.
      width="100%"
      locked={locked}
      items={recordVersion?.record?.action_plans || []}
    />
  );
}

ViewActionPlanOwners.defaultProps = {
  locked: false,
};

export default observer(ViewActionPlanOwners);
