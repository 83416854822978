import { PopoverContentProps } from "@radix-ui/react-popover";
import { Select, SelectItem } from "@themis/ui";
import React from "react";

export function TimeFrameSelect({
  options,
  selectedValue,
  onSelect,
  alignItemList,
  classNameList,
}: {
  options: SelectItem[];
  selectedValue: string;
  onSelect: (value: string) => void;
  alignItemList?: PopoverContentProps["align"];
  classNameList?: string;
}) {
  return (
    <Select
      className="tw-w-[200px]"
      alignItemList={alignItemList}
      classNameList={classNameList}
      items={options}
      onSelect={onSelect}
      selected={selectedValue}
    />
  );
}
