import classNames from "classnames";
import { isNil } from "lodash";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { Mention, MentionsInput, SuggestionDataItem } from "react-mentions";

import { userColors } from "@/components/constants";
import { Button, Flex } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import { UserComment } from "@/stores/types/comment-types";
import { DisplayUser } from "@/stores/types/user-types";

type Props = {
  replyEditId?: number | null;
  isPrivate: boolean;
  reply: UserComment;
  /**
   * Needed when on Notifications > Tasks Page.
   * Will trigger refetch of user tasks
   */
  workspaceId?: number;
  setReplyEditId(value: number | null): void;
};

function EditCommentReply({
  reply,
  replyEditId,
  workspaceId,
  isPrivate,
  setReplyEditId,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const suggestionUserForComment = useRef<HTMLDivElement>(null);
  const isTask = !isNil(workspaceId);

  // State
  const [inputValue, setInputValue] = useState(reply.content);

  function renderSuggestion(suggestion: SuggestionDataItem) {
    const user = suggestion as DisplayUser;
    const optionClasses = classNames("option", {
      "awaiting-verification": user.awaiting_verification,
    });
    const style = {
      background: userColors[user.icon_color_index],
    };
    return (
      <div className={optionClasses}>
        <span className="users-circle" style={style}>
          {user.initials}
        </span>
        {user.full_name}
        {user.awaiting_verification && (
          <span className="awaiting-verification-status">{user.status}</span>
        )}
      </div>
    );
  }

  async function handleUpdateReply() {
    await mainStore.comments.update(
      reply.id,
      { content: inputValue },
      isTask ? reply.module_workspace_id : undefined,
    );
    if (isTask) {
      await mainStore.tasks.myTasks({ workspace_id: workspaceId });
    }
    setReplyEditId(null);
  }

  function handleCancelUpdateReply() {
    setReplyEditId(null);
  }

  return (
    <>
      <div className="task-comments-description">
        <div className="task-name task-comments-text-single comments-content">
          {replyEditId === reply.id ? (
            <div className="task-comments-text-edit">
              <div
                className="edit-task-description"
                data-testid={`task-comment-content-${reply.id}`}
              >
                <MentionsInput
                  value={inputValue}
                  className="mentions"
                  onChange={(event) => setInputValue(event.target.value)}
                  suggestionsPortalHost={
                    suggestionUserForComment.current
                      ? suggestionUserForComment.current
                      : undefined
                  }
                  allowSuggestionsAboveCursor
                >
                  <Mention
                    trigger="@"
                    data={
                      isPrivate
                        ? mainStore.comments.privateUsers
                        : mainStore.users.activeDisplayUsers
                    }
                    renderSuggestion={renderSuggestion}
                  />
                </MentionsInput>
                <div
                  ref={suggestionUserForComment}
                  className="suggestion-wrap"
                />
              </div>
            </div>
          ) : (
            <MentionsInput disabled value={inputValue} className="mentions">
              <Mention trigger="@" data={[]} />
            </MentionsInput>
          )}
        </div>
      </div>
      {replyEditId === reply.id && (
        <Flex alignCenter columnGap={8}>
          <Button
            data-testid="update-comment-button"
            disabled={!inputValue || inputValue === reply.content}
            label="Update"
            size="sm"
            onClick={handleUpdateReply}
          />
          <Button
            data-testid="cancel-update-comment-button"
            theme="tertiary"
            label="Cancel"
            size="sm"
            onClick={handleCancelUpdateReply}
          />
        </Flex>
      )}
    </>
  );
}

export default observer(EditCommentReply);
