import "./styles.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { Mention, MentionsInput, SuggestionDataItem } from "react-mentions";

import { userColors } from "@/components/constants";
import { Button, Flex } from "@/components/Elements";
import { useScrollToSelectedCommentRef } from "@/components/settings/notificationsCenter/tasks/hooks/useScrollToSelectedCommentRef";
import CommentHeader from "@/components/settings/notificationsCenter/tasks/MutableTaskComment/components/CommentHeader";
import { CommentTab } from "@/components/table/shared/comments/CommentsSlideMenu/types";
import UserMentionsInput from "@/components/table/shared/UserMentionsInput";
import { useMainStore } from "@/contexts/Store";
import { useClickAwayListener } from "@/hooks/useClickAwayListener";
import { useSearchParams } from "@/hooks/useSearchParams";
import { TaskableType } from "@/stores/types/comment-types";
import { DisplayUser } from "@/stores/types/user-types";

import { CommentReply } from "./components/CommentReply";

type Props = {
  commentID: number;
  taskableType: TaskableType;
};

function MutableTaskComment({ commentID, taskableType }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { canAddComments } = mainStore.userPermissions;

  const [searchParams, setSearchParams] = useSearchParams<{
    comments_tab?: CommentTab;
    comment_id?: string;
  }>();

  const isInternalTab = searchParams.comments_tab === "internal";

  const mainComment = (
    isInternalTab
      ? mainStore.comments.privateComments
      : mainStore.comments.comments
  ).find((elem) => elem.id === commentID);
  // Refs
  const suggestionUser = useRef(null);
  const commentWrapper = useRef(null);

  // State
  const [replyInput, setReplyInput] = useState("");
  const [commentChecked, setCommentChecked] = useState(!!mainComment?.resolved);
  const [commentInput, setCommentInput] = useState(mainComment?.content);
  const [editMainCommentMode, setEditMainCommentMode] = useState(false);

  const selectedCommentRef = useScrollToSelectedCommentRef(
    mainStore.comments.privateComments,
  );

  if (!mainComment) {
    return null;
  }

  const handleClickAway = () => {
    setSearchParams({ ...searchParams, comment_id: undefined });
  };

  // Variables
  const selectedCommentID = Number(searchParams.comment_id);
  const mainCommentActive = selectedCommentID === mainComment.id;

  useClickAwayListener(
    commentWrapper,
    mainCommentActive ? handleClickAway : undefined,
  );

  async function handleCreateReply(
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      await mainStore.comments.create({
        original_comment_id: commentID,
        content: replyInput,
      });
      setReplyInput("");
    }
  }

  function handleDeleteComment() {
    mainStore.comments.delete(commentID);
  }

  function handleCommentChecked(event: React.ChangeEvent<HTMLInputElement>) {
    if (!mainComment) {
      return;
    }

    setCommentChecked(!commentChecked);

    mainStore.comments.update(mainComment.id, {
      resolved: event.target.checked,
    });
  }

  async function handleUpdateCommentDescription() {
    if (
      commentInput &&
      commentInput !== "" &&
      commentInput !== mainComment?.content
    ) {
      await mainStore.comments.update(commentID, { content: commentInput });
    }

    setEditMainCommentMode(false);
  }

  function handleCancelEditComment() {
    if (commentInput !== mainComment?.content) {
      setCommentInput(mainComment?.content);
    }

    setEditMainCommentMode(false);
  }

  function renderSuggestion(suggestion: SuggestionDataItem) {
    const user = suggestion as DisplayUser;
    const optionClasses = classNames("option", {
      "awaiting-verification": user.awaiting_verification,
    });
    const style = {
      background: userColors[user.icon_color_index],
    };
    return (
      <div className={optionClasses}>
        <span className="users-circle" style={style}>
          {user.initials}
        </span>
        {user.full_name}
        {user.awaiting_verification && (
          <span className="awaiting-verification-status">{user.status}</span>
        )}
      </div>
    );
  }

  const renderCommentDescription = (
    <div className="comment__description">
      <div
        data-testid={`comment-description-content-${mainComment.id}`}
        className={classNames("task-name", "comments-content", {
          "comments-content--editable": editMainCommentMode,
        })}
      >
        <MentionsInput
          disabled={!editMainCommentMode}
          value={editMainCommentMode ? commentInput : mainComment.content}
          placeholder="Add new comment. Use “@” to assign member"
          className="mentions"
          onChange={(event) => setCommentInput(event.target.value)}
          suggestionsPortalHost={
            suggestionUser?.current ? suggestionUser.current : undefined
          }
          allowSuggestionsAboveCursor
        >
          <Mention
            trigger="@"
            data={
              isInternalTab
                ? mainStore.comments.privateUsers
                : mainStore.users.activeDisplayUsers
            }
            renderSuggestion={renderSuggestion}
          />
        </MentionsInput>
        <div ref={suggestionUser} className="suggestion-wrap" />
      </div>
      {editMainCommentMode && (
        <Flex alignCenter columnGap={8}>
          <Button
            data-testid="update-task-description-button"
            disabled={!commentInput || commentInput === mainComment.content}
            label="Update"
            size="sm"
            onClick={handleUpdateCommentDescription}
          />
          <Button
            data-testid="cancel-update-task-description-button"
            theme="tertiary"
            label="Cancel"
            size="sm"
            onClick={handleCancelEditComment}
          />
        </Flex>
      )}
    </div>
  );

  return (
    <div ref={commentWrapper}>
      <div data-testid="comment">
        <div
          ref={mainCommentActive ? selectedCommentRef : undefined}
          className={classNames("comment__main-comment-container", {
            "comment__main-comment-container--active": mainCommentActive,
          })}
        >
          <CommentHeader
            comment={mainComment}
            commentChecked={commentChecked}
            editCommentMode={editMainCommentMode}
            onDeleteComment={handleDeleteComment}
            onCommentChecked={handleCommentChecked}
            setEditCommentMode={setEditMainCommentMode}
            taskableType={taskableType}
          />
          {commentChecked ? (
            <div
              className="comment--completed comment__description"
              data-testid={`task-completed-${mainComment.id}`}
            >
              <MentionsInput disabled value={commentInput}>
                <Mention trigger="@" data={[]} />
              </MentionsInput>
            </div>
          ) : (
            renderCommentDescription
          )}
        </div>
        {!commentChecked && (
          <>
            <div className="comment__replies-container">
              {mainComment.replies.map((reply) => (
                <CommentReply
                  key={reply.id}
                  reply={reply}
                  selectedCommentRef={selectedCommentRef}
                />
              ))}
            </div>
            {!["QuestionGroup", "Question", "QuestionnaireSummary"].includes(
              taskableType,
            ) &&
              canAddComments && (
                <div className="comment__reply-input-container">
                  <UserMentionsInput
                    editable
                    content={replyInput}
                    onChange={(event) => setReplyInput(event.target.value)}
                    onKeyDown={handleCreateReply}
                    placeholder="Reply"
                    singleLine={false}
                  />
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
}

export default observer(MutableTaskComment);
