import { Task } from "@/api";
import useFilterSort from "@/hooks/useFilterSort";

import { myTasksFilterData } from "../config/myTasksFilterData";

export function useMyTasksFilterSort(
  params?: Omit<Parameters<typeof useFilterSort<Task>>[0], "fieldsData">,
) {
  return useFilterSort<Task>({ fieldsData: myTasksFilterData, ...params });
}
