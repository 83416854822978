import classNames from "classnames";
import React, { useMemo } from "react";

import { COLOR, THEMIS_ICON_SIZES } from "@/config/theme";

import Icon, { IconName } from "../Icon";

export type IconButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
  className?: string;
  color?: keyof typeof COLOR;
  disabled?: boolean;
  icon: IconName;
  size?: string;
  iconSize?: (typeof THEMIS_ICON_SIZES)[number];
  theme?: string;
  transparent?: boolean;
  type?: string;
  buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
};

const IconButton: React.FC<IconButtonProps> = ({
  active = false,
  className,
  color = "generalMidnightDark",
  disabled,
  icon,
  size = "de",
  theme,
  transparent,
  type = "default",
  buttonType = "button",
  iconSize,
  ...rest
}) => {
  const iconColor = useMemo(() => {
    if (disabled) {
      return "generalDarkGray";
    }

    if (active) {
      return "generalWhite";
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (["primary", "secondary"].includes(theme)) {
      return "generalWhite";
    }

    if (theme === "tertiary") {
      return "generalMidnightDark";
    }

    return color;
  }, [disabled, active, color, theme]);

  return (
    <button
      className={classNames(
        "themisIconButton",
        `themisIconButton__${type}-${size}`,
        theme && `themisIconButton__${theme}`,
        !disabled && !active && transparent && "themisIconButton--transparent",
        !disabled && active && "themisIconButton--active",
        className,
        {
          active,
        },
      )}
      disabled={disabled}
      type={buttonType}
      {...rest}
    >
      <Icon name={icon} color={iconColor} size={iconSize} />
    </button>
  );
};

IconButton.displayName = "IconButton";

export default IconButton;
