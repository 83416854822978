import { observer } from "mobx-react";
import React from "react";

import { Review } from "@/api";
import { useMainStore } from "@/contexts/Store";

import warningIcon from "../../../../images/table-image/icon/close-black-icon.svg";
import starIcon from "../../../../images/table-image/icon/star-icon.svg";
import { userColors } from "../../../constants";
import { formatDate, stringToDate } from "../../../helpers/DateFormatters";
import UsersCircle from "../UsersCircle";

type Props = {
  description?: string;
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  descriptionIcon?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleRemoveSponsor?: (...args: any[]) => any;
  isLocked?: boolean;
  showApprovalStatus?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sponsorIDs?: any[];
};

function ViewApprovedUsers({
  recordVersion,
  fieldName,
  isLocked,
  description,
  descriptionIcon,
  showApprovalStatus,
  sponsorIDs,
  handleRemoveSponsor,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const selectedIDs =
    mainStore.avroSchemas.valueForField(fieldName, recordVersion.data) ||
    sponsorIDs ||
    [];
  const reviews: Review[] = recordVersion?.reviews || [];
  const { users } = mainStore.users;
  const filteredUsers = users?.filter((user) => selectedIDs?.includes(user.id));
  const approvalSteps = recordVersion?.approval_config?.approval_steps;

  // @ts-expect-error TS(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
  const onRemove = (userID) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    const idsToSend = selectedIDs.filter((id) => id !== userID);
    handleRemoveSponsor
      ? handleRemoveSponsor(userID)
      : mainStore.recordVersions.update({
          fieldName,
          recordVersionID: recordVersion?.id,
          value: mainStore.avroSchemas.serializeValue(fieldName, idsToSend),
        });
  };

  if (filteredUsers.length === 0 && !approvalSteps) {
    return (
      <div className="table-row empty" data-testid="no-approvers">
        {descriptionIcon && <img src={starIcon} alt="star-icon" />}
        {description || isLocked
          ? "No Approvers added."
          : "No Approvers added yet. Click “Select” to add one here."}
      </div>
    );
  }

  return (
    <>
      {filteredUsers.map((user) => {
        const approvedReview = reviews.find(
          (review) =>
            review.user_id === user.id && review.review_type === "approval",
        );

        return (
          <div key={user.id} className="table-row" data-testid="table-row">
            <UsersCircle
              user={user}
              additionalStyles={{
                background: isLocked
                  ? "#C3C3C8"
                  : userColors[user.icon_color_index],
              }}
              additionalClassNames={approvedReview ? "approved" : ""}
            />
            <div className="policy-approval-status">
              {showApprovalStatus && approvedReview && (
                <>
                  <div className="approval-date">
                    {formatDate(stringToDate(approvedReview.approved_at))}
                  </div>
                  <div className="approved">Approved</div>
                </>
              )}

              {showApprovalStatus && !approvedReview && (
                <div className="pending">Pending</div>
              )}
              {!isLocked && (
                <div className="table-row-actions">
                  <div
                    className="remove"
                    data-testid="remove-approved-user-button"
                    onClick={() => onRemove(user.id)}
                  >
                    <img src={warningIcon} alt="remove" />
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}

ViewApprovedUsers.defaultProps = {
  descriptionIcon: false,
  showApprovalStatus: true,
};

export default observer(ViewApprovedUsers);
