import { AxiosResponse } from "axios";

import api from "../api";
import {
  CreateEventNotificationRuleRequest,
  CreateUpdateEventNotificationRuleResponse,
  EventNotificationRule,
  EventNotificationsDeleteResponse,
  EventNotificationsListResponse,
} from "./types";

export async function getEventNotifications(moduleWorkspaceId: number) {
  const response = await api.get<EventNotificationsListResponse>(
    `/module_workspaces/${moduleWorkspaceId}/event_notification_rules`,
  );

  return response.data;
}

export async function createEventNotification(
  moduleWorkspaceId: number,
  data: CreateEventNotificationRuleRequest,
) {
  const response = await api.post<
    CreateEventNotificationRuleRequest,
    AxiosResponse<CreateUpdateEventNotificationRuleResponse>
  >(`/module_workspaces/${moduleWorkspaceId}/event_notification_rules`, data);

  return response.data;
}

export async function updateEventNotification(
  moduleWorkspaceId: number,
  id: number | string,
  data: Partial<EventNotificationRule>,
) {
  const response = await api.patch<
    Partial<EventNotificationRule>,
    AxiosResponse<CreateUpdateEventNotificationRuleResponse>
  >(
    `/module_workspaces/${moduleWorkspaceId}/event_notification_rules/${id}`,
    data,
  );

  return response.data;
}

export async function deleteEventNotification(
  moduleWorkspaceId: number,
  notificationId: string,
) {
  const response = await api.delete<
    never,
    AxiosResponse<EventNotificationsDeleteResponse>
  >(
    `/module_workspaces/${moduleWorkspaceId}/event_notification_rules/${notificationId}`,
  );

  return response.data.success;
}
