import { map } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Review } from "@/api";
import { nameForThemisModuleIdentifier } from "@/components/helpers/nameForThemisModuleIdentifier";
import { useMainStore } from "@/contexts/Store";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { Typography } from "../../Elements";
import ModuleLabel from "../../shared/ModuleLabel";
import LoadingTile, { MODE } from "../content/tiles/LoadingTile";
import { ActionItemSection } from "./ActionItemSection";
import { SectionLink } from "./SectionLink";
import { SectionTitle } from "./SectionTitle";
import TitleWithContextItem from "./TitleWithContextItem";

const Reviews = () => {
  // Import MobX stores
  const mainStore = useMainStore();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const { requestedReviews: reviews, pendingReviewCount } = mainStore.reviews;
  const currentWorkspaceID = mainStore.context.workspaceID;

  const fetchReviews = async () => {
    setIsLoading(true);
    await mainStore.reviews.fetchRequestedReviews();
    setIsLoading(false);
  };

  async function handleView(review: Review) {
    if (review.module_workspace.workspace_id !== currentWorkspaceID) {
      await mainStore.workspaces.switch(review.module_workspace.workspace_id);
    }
    history.push(review.reviewable.path);
  }

  useEffect(() => {
    fetchReviews();
  }, []);

  return (
    <ActionItemSection>
      <div className="action-item-section__header">
        <SectionTitle>Pending my review</SectionTitle>
        <SectionLink label="View all reviews" to="/notifications/reviews" />
      </div>

      {isLoading && <LoadingTile mode={MODE.dark} />}

      {!isLoading && (
        <>
          <div className="action-item-section__overview">
            <Typography
              label={pendingReviewCount || "--"}
              size="lgh"
              weight="semiBold"
              color="generalWhite"
            />
            <Typography
              label="# of items pending review"
              size="sm"
              weight="semiBold"
              color="generalWhite"
            />
          </div>
          {map(reviews, (review) => {
            const { reviewable } = review;
            return (
              <TitleWithContextItem
                key={review.id}
                globalId={reviewable.identifier}
                title={reviewable.name || "-- Untitled --"}
                context={
                  <ModuleLabel
                    moduleDisplayName={nameForThemisModuleIdentifier(
                      review.module_workspace.themis_module
                        .identifier as ModuleIdentifier,
                    )}
                    moduleIdentifier={
                      review.module_workspace.themis_module.identifier
                    }
                    color="generalWhite"
                  />
                }
                onClick={() => handleView(review)}
                data-testid="tite-with-context-item"
              />
            );
          })}
        </>
      )}
    </ActionItemSection>
  );
};

export default observer(Reviews);
