import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { useMainStore } from "@/contexts/Store";

import { ActionButton } from "./components";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetMenuActions?: (...args: any[]) => any;
};

function MenuActionsUsers({ resetMenuActions }: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const [selectedText, setSelectedText] = useState("");

  const resetSelectedAction = () => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    resetMenuActions();
  };

  const actions = [
    {
      icon: "trash",
      text: "Delete",
      alt: "delete-icon",
      actionVerb: "delete",
      itemName: "contacts",
      defaultColor: "generalError",
      cssClass: "delete-button",
      showButton: true,
      // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
      actionCall: (ids) => mainStore.contacts.bulkDelete(ids, workspaceID),
    },
  ];

  useEffect(() => {
    setSelectedText(
      `${mainStore.dynamicTable.selectedUserIDs.length} Selected`,
    );
  }, [mainStore.dynamicTable.selectedUserIDs]);

  return (
    <div
      data-testid="select-all-menu-actions"
      className="list-title-menu-actions"
    >
      <div data-testid="count-of-selected" className="count-of-selected">
        {selectedText}
      </div>
      <div className="divider" />
      {actions
        .filter((action) => action.showButton)
        .map((action, index) => (
          <ActionButton
            key={index}
            classNames={classNames("action-button", action.cssClass)}
            alt={action.alt}
            icon={action.icon}
            text={action.text}
            reset={resetSelectedAction}
            disabled={mainStore.dynamicTable.selectedUserIDs.length < 1}
            actionCall={action.actionCall}
            actionVerb={action.actionVerb}
            defaultColor={action.defaultColor}
            itemName={action.itemName}
            isForRecords={false}
          />
        ))}
    </div>
  );
}

export default observer(MenuActionsUsers);
