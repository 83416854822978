import { uniq } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";

import { Field } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import { MainStore } from "../Main";

const RECORD_FIELDS = [
  "com.askthemis.types.v1.record_policy",
  "com.askthemis.types.v1.record_procedure",
];

export default class Fields {
  mainStore: MainStore;

  // Observable objects
  list: Array<Field> = [];
  pinnedFieldName = null;
  fieldsByDataType: Record<string, unknown> = {};
  allFields = [];
  optionFields = [];
  allFieldsByDataType = {};
  allGroupableFields = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      list: observable,
      pinnedFieldName: observable,
      allFields: observable,
      allGroupableFields: observable,
      optionFields: observable,

      getAllFieldsByDataType: action,
      setList: action,
      setPinnedFieldName: action,
      setAllGroupableFields: action,

      fieldNames: computed,
      visibleFields: computed,
      fieldTypesRequiringIndex: computed,
    });

    this.mainStore = mainStore;
  }

  // =========================================
  // ================ Actions ================
  // =========================================

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setList(value) {
    if (value) {
      this.list = value;
    } else {
      this.list = [];
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setPinnedFieldName(value) {
    if (value) {
      this.pinnedFieldName = value;
    } else {
      this.pinnedFieldName = null;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAllFields(value) {
    this.allFields = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAllGroupableFields(value) {
    this.allGroupableFields = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setOptionFields(value) {
    this.optionFields = value;
  }
  // =========================================
  // =============== Computeds ===============
  // =========================================

  get fieldNames() {
    return this.list.map((field) => field.name);
  }

  get visibleFields() {
    return this.list.filter((field) => field.position > 0 && !field.is_hidden);
  }

  get fieldTypesRequiringIndex() {
    const relevantFields = this.visibleFields.filter((field) =>
      RECORD_FIELDS.includes(field.data_type),
    );
    const fieldTypes = relevantFields.map((field) => field.data_type);
    return uniq(fieldTypes).sort();
  }

  // =========================================
  // ================== API ==================
  // =========================================

  // GET /api/react/tables/:table_id/fields
  // @ts-expect-error TS(7006) FIXME: Parameter 'tableID' implicitly has an 'any' type.
  async index(tableID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/tables/${tableID}/fields`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorText(
          "An error occurred when attempting to load fields for this table.",
        );
        return null;
      }

      return response.data.fields;
    } catch (error) {
      window.console.log(`"Fields#index" error ${error}`);

      return null;
    }
  }

  // POST /api/react/tables/:table_id/fields
  // @ts-expect-error TS(7006) FIXME: Parameter 'tableID' implicitly has an 'any' type.
  async create(tableID, params) {
    try {
      const data = { field: params };

      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/tables/${tableID}/fields`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorText(
          "An error occurred when attempting to create this column. It's likely a column with that name already exists in this table.",
        );
        return false;
      }

      // Update data if everything went smooth
      this.mainStore.fields.setList(response.data.fields);

      return true;
    } catch (error) {
      window.console.log(`"Fields#create" error ${error}`);

      return false;
    }
  }

  // DELETE /api/react/tables/:table_id/fields/:name
  // @ts-expect-error TS(7006) FIXME: Parameter 'tableID' implicitly has an 'any' type.
  async delete(tableID, name) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/tables/${tableID}/fields/${name}`,
        headers: this.mainStore.getHeaders(),
      });

      // Update data if everything went smooth
      this.mainStore.fields.setList(response.data.fields);
    } catch (error) {
      window.console.log(`"Fields#delete" error ${error}`);
    }
  }

  // PUT /api/react/tables/:table_id/fields/:name/title
  // @ts-expect-error TS(7006) FIXME: Parameter 'tableID' implicitly has an 'any' type.
  async updateDisplayName(tableID, name, displayName) {
    const params = { display_name: displayName };
    const data = { field: params };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/tables/${tableID}/fields/${name}/display_name`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
        this.mainStore.toast.setErrorText(`${response.response.data.errors}`);
        return false;
      }

      // Update data if everything went smooth
      this.mainStore.fields.setList(response.data.fields);

      return true;
    } catch (error) {
      window.console.log(`"Fields#updateDisplayName" error ${error}`);

      return false;
    }
  }

  // PUT /api/react/tables/:table_id/fields/:name/toggle_hidden
  // @ts-expect-error TS(7006) FIXME: Parameter 'tableID' implicitly has an 'any' type.
  async toggleHidden(tableID, name) {
    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/tables/${tableID}/fields/${name}/toggle_hidden`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorText(
          "An error occurred when attempting to update this column.",
        );
        return false;
      }

      // Update data if everything went smooth
      this.mainStore.fields.setList(response.data.fields);

      return true;
    } catch (error) {
      window.console.log(`"Fields#toggleHidden" error ${error}`);

      return false;
    }
  }

  // PUT /api/react/tables/:table_id/fields/:name/toggle_is_required
  // @ts-expect-error TS(7006) FIXME: Parameter 'tableID' implicitly has an 'any' type.
  async toggleIsRequired(tableID, name) {
    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/tables/${tableID}/fields/${name}/toggle_is_required`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorText(
          "An error occurred when attempting to update this column.",
        );
        return false;
      }

      // Update data if everything went smooth
      this.mainStore.fields.setList(response.data.fields);

      return true;
    } catch (error) {
      window.console.log(`"Fields#toggleIsRequired" error ${error}`);

      return false;
    }
  }

  // PUT /api/react/tables/:table_id/fields/:name/width
  // @ts-expect-error TS(7006) FIXME: Parameter 'tableID' implicitly has an 'any' type.
  async updateWidth(tableID, name, width) {
    const params = { width };
    const data = { field: params };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/tables/${tableID}/fields/${name}/width`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorText(
          "An error occurred when attempting to resize this column.",
        );
        return false;
      }

      // Update data if everything went smooth
      this.mainStore.fields.setList(response.data.fields);

      return true;
    } catch (error) {
      window.console.log(`"Fields#updateWidth" error ${error}`);

      return false;
    }
  }

  // PUT /api/react/module_workspaces/:module_workspace_id/fields/:name/toggle_pin
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async togglePinnedField(moduleWorkspaceID, name) {
    if (!moduleWorkspaceID || !name) {
      window.console.warn(
        'Fields#togglePinnedField => "moduleWorkspaceID" and "name" should be present!',
      );
      return;
    }

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/fields/${name}/toggle_pin`,
        headers: this.mainStore.getHeaders(),
      });

      this.setPinnedFieldName(response.data.pinned_field_name);
    } catch (error) {
      window.console.log(`"Fields#togglePinnedField" error ${error}`);
    }
  }

  async getAllFieldsByDataType({
    workspaceIDs,
    identifier,
  }: {
    workspaceIDs: number[];
    identifier: string;
  }) {
    // this returning the full data type name, not the display data type name:
    // there is no way as far as I can tell to easily get the display name from the full name
    // eg: com.askthemis.types.v1.option instead of Option
    const params = { workspace_ids: workspaceIDs, identifier };
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/fields/all_fields`,
        params,
        headers: this.mainStore.getHeaders(),
      });

      if (!response.data.all_fields) {
        return;
      }
      // @ts-expect-error TS(7006) FIXME: Parameter 'acc' implicitly has an 'any' type.
      const groupableFields = response.data.all_fields.reduce((acc, field) => {
        if (
          field.data_type === "com.askthemis.types.v1.option" ||
          field.data_type === "com.askthemis.types.v1.tag_product" ||
          field.data_type === "com.askthemis.types.v1.tag" ||
          field.data_type === "com.askthemis.types.v1.finra_brex_category" ||
          field.data_type ===
            "com.askthemis.types.v1.finra_brex_sub_category" ||
          field.data_type === "com.askthemis.types.v1.finra_problem_code" ||
          field.data_type === "com.askthemis.types.v1.finra_product_code"
        ) {
          acc.push(field);
        }
        return acc;
      }, []);
      // @ts-expect-error TS(7006) FIXME: Parameter 'acc' implicitly has an 'any' type.
      const optionFields = response.data.all_fields.reduce((acc, field) => {
        if (field.data_type === "com.askthemis.types.v1.option") {
          acc.push(field);
        }
        return acc;
      }, []);

      this.setOptionFields(optionFields);
      this.setAllFields(response.data.all_fields);
      this.setAllGroupableFields(groupableFields);
    } catch (error) {
      window.console.log(`"Fields#getAllFieldsByDataType" error ${error}`);
    }
  }

  // PUT /api/react/tables/:table_id/fields/order
  // @ts-expect-error TS(7006) FIXME: Parameter 'tableID' implicitly has an 'any' type.
  async reorder(tableID, fieldsNames) {
    const params = {
      fields_names: fieldsNames,
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/tables/${tableID}/fields/order`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      this.mainStore.fields.setList(response.data.fields);
    } catch (error) {
      window.console.log(`"Fields#reorder" error ${error}`);
    }
  }

  // Helpers

  cleanup() {
    this.setList([]);
    this.setPinnedFieldName(null);
  }
}
