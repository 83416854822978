import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import { RecordVersion } from "@/api";
import { useMainStore } from "@/contexts/Store";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import SlideMenu from "../../slideMenu/SlideMenu";
import ApproveButton from "../shared/ApproveButton";
import PublishFlow from "../shared/PublishFlow";
import Unlock from "../shared/Unlock";
import RequestMoreInformation from "./RequestMoreInformation";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  moduleStore: any;
  recordVersion: RecordVersion;
  moduleIdentifier: ModuleIdentifier;
  reviewedByUserIds: number[];
  status: string;
  showOnlyIcon?: boolean;
};

function ConflictsOfInterestContextMenu({
  moduleStore,
  recordVersion,
  moduleIdentifier,
  reviewedByUserIds,
  status,
  showOnlyIcon = true,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const history = useHistory();
  const location = useLocation();
  const isConflictsOfInterestDetailView = useMemo(() => {
    const conflictsOfInterestDetailPageRe =
      /^\/modules\/conflicts-of-interest\/\d+$/;
    const matches = location.pathname.match(conflictsOfInterestDetailPageRe);

    return Boolean(matches && matches.length > 0);
  }, [location.pathname]);

  // Variables
  const { workspaceID } = mainStore.context;
  const { canDeleteRecords } = mainStore.userPermissions;
  const isPending = !["approved", "denied"].includes(status);
  const approversIds =
    mainStore.avroSchemas.valueForField("approvers", recordVersion?.data) || [];
  const currentUserID = mainStore.users.user.id;
  const isApprover = currentUserID && approversIds.includes(currentUserID);
  const hasReviewed =
    currentUserID && reviewedByUserIds.includes(currentUserID);
  const canReview = isApprover && !hasReviewed;
  const recordVersionID = recordVersion.id;
  const { taskDetail } = mainStore;

  // State
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [showSlideMenu, setShowSlideMenu] = useState(false);

  function noButtonPressed() {
    handlePopUpClose();
  }

  function deleteRowConfirmation() {
    setIsDeleteConfirmationOpen(true);
  }

  function unlockRecordVersion() {
    mainStore.conflicts.unlock(recordVersionID);
  }

  async function deleteRow() {
    // Delete RecordVersion
    const { company } = mainStore.companies;
    if (company && company.id) {
      await mainStore.recordVersions.delete(recordVersionID);
      mainStore.toast.setText("Conflict deleted!");

      if (isConflictsOfInterestDetailView) {
        history.push(
          `/workspaces/${workspaceID}/modules/conflicts-of-interest`,
        );
      }
    }
  }
  function handleCreateTask() {
    handlePopUpClose();
    taskDetail.openFromRecord(recordVersion);
  }

  function denyConflict() {
    mainStore.reviews.createDenial(recordVersionID);
    setIsDropdownOpen(false);
  }

  function onApprove() {
    if (recordVersionID) {
      mainStore.reviews.createApproval(recordVersionID);
    }
  }

  function handlePopUpOpen() {
    setIsDropdownOpen(true);
  }

  function handlePopUpClose() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(false);
  }

  function openSlideMenu() {
    setShowSlideMenu(true);
    handlePopUpClose();
  }

  function closeSlideMenu() {
    setShowSlideMenu(false);
  }

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="points-button-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isDropdownOpen,
      })}
    />
  );

  const renderBaseContent = (
    <div className="table-dropdown policy-dropdown">
      <ul>
        {canReview && (
          <li data-testid="conflict-dropdown-deny" onClick={denyConflict}>
            Deny
          </li>
        )}

        {isPending && (
          <li data-testid="conflict-dropdown-request" onClick={openSlideMenu}>
            Request More Info
          </li>
        )}
        <li onClick={handleCreateTask}>Create Task</li>

        {canDeleteRecords && (
          <>
            <hr />
            <li
              data-testid="conflict-dropdown-delete"
              onClick={deleteRowConfirmation}
            >
              Delete
            </li>
          </>
        )}
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <div>
      <h4>Delete Conflict</h4>
      <p>
        Are you sure you want to delete this conflict? This is not reversible.
      </p>
      <div className="confirmation">
        <button onClick={deleteRow}>Yes</button>
        <button onClick={noButtonPressed}>No</button>
      </div>
    </div>
  );

  return (
    <div
      className={classNames("list-points", {
        "without-background": !showOnlyIcon,
      })}
    >
      {(!isApprover || hasReviewed) && isPending && (
        <PublishFlow
          recordVersionID={recordVersionID}
          moduleIdentifier={moduleIdentifier}
          moduleStore={moduleStore}
          buttonText="Close"
        />
      )}

      {!isPending && (
        <Unlock
          onYes={unlockRecordVersion}
          moduleIdentifier={moduleIdentifier}
          buttonText="Reopen"
        />
      )}

      {canReview && <ApproveButton onClick={onApprove} />}

      <Popup
        position="bottom right"
        trigger={() => renderTrigger}
        open={isDropdownOpen}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {!isDeleteConfirmationOpen && renderBaseContent}
        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
      <SlideMenu open={showSlideMenu} closeSlideMenu={closeSlideMenu}>
        <RequestMoreInformation recordVersionID={recordVersionID} />
      </SlideMenu>
    </div>
  );
}

export default observer(ConflictsOfInterestContextMenu);
