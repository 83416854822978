import React from "react";

import { DashboardComponent } from "@/api";

import { formatPieChartData } from "../../utils";
import { PieChart } from "../charts/PieChart";

export default function WorkflowsWithDueDatesApproaching({
  widgetData,
  moduleWorkspacesNames,
}: {
  widgetData: DashboardComponent["data"];
  moduleWorkspacesNames?: { [key: string]: string };
}) {
  const mutated = formatPieChartData({
    widgetData,
    moduleWorkspacesNames,
  });

  const valueSum = mutated.reduce((acc, curr) => acc + curr.value, 0);

  return (
    <div className="tw-flex tw-h-[260px] tw-min-w-[400px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Workflows with Due Dates Approaching
        </div>
      </div>
      <div>
        <div className="tw-flex tw-h-[228px] tw-w-full tw-justify-evenly">
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
            <div className="tw-text-6xl tw-font-semibold tw-text-neutral-500">
              {valueSum}
            </div>
            <div className="tw-text-sm tw-font-medium tw-text-neutral-300">
              Workflow Due Dates
            </div>
          </div>
          <div className="tw-flex tw-flex-col">
            <PieChart data={mutated} />
            <div className="tw-text-sm tw-font-medium tw-text-neutral-300">
              % Module Workflow
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
