import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import closeIcon2 from "../../../../images/table-image/icon/close-icon2.svg";

const FORMATTED_RECORD_TYPE_MAP = {
  "record:policy": "Policy",
  "record:procedure": "Procedure",
  "record:training": "Training",
  "record:audit": "Audit",
  "record:qa_tests_development": "Monitoring and Testing",
};

const MAX_INITIAL_RECORDS = 5;

type Props = {
  dataType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedIDs: any[];
  singleSelection: boolean;
  fetchOnInit?: boolean;
  fieldName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSelect?: (...args: any[]) => any;
  locked?: boolean;
  pinned?: boolean;
  recordVersionID?: number;
  width?: number;
};

// never use fetchOnInit in dynamic table
function RecordSelect({
  fieldName,
  recordVersionID,
  width,
  dataType,
  selectedIDs,
  singleSelection,
  locked,
  pinned,
  handleSelect,
  fetchOnInit,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [recordQuery, setRecordQuery] = useState("");
  const [ids, setIDs] = useState(selectedIDs);
  const [isExpanded, setIsExpanded] = useState(false);
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const formattedRecordType = FORMATTED_RECORD_TYPE_MAP[dataType] || "Select";

  // effects
  useEffect(() => {
    fetchOnInit && refreshRecords();
  }, []);

  useEffect(() => {
    setIDs(selectedIDs);
  }, [selectedIDs]);

  // funcs
  const refreshRecords = () => {
    if (dataType && workspaceID) {
      mainStore.records.index(dataType, workspaceID);
    }
  };

  const expandList = () => {
    setIsExpanded(true);
  };

  const onOpen = () => {
    setShowPopup(true);
    setRecordQuery("");
    setIsSelectModalOpen(true);
    setIsExpanded(false);
    refreshRecords();
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  const addItemById = (id) => {
    if (singleSelection) {
      setIDs([id]);
      setShowPopup(false);
    } else {
      setIDs([...ids, id]);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  const removeItemById = (id) => {
    const newSelection = ids.filter((element) => element !== id);
    setIDs(newSelection);
  };

  const onClose = () => {
    if (ids !== selectedIDs) {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      handleSelect(ids);

      if (fieldName && recordVersionID) {
        mainStore.recordVersions.update({
          fieldName,
          recordVersionID,
          value: mainStore.avroSchemas.serializeValue(fieldName, ids),
        });
      }
    }

    setShowPopup(false);
    setIsSelectModalOpen(false);
  };

  const triggerStyle = { width };
  const triggerClasses = classNames("linked-records", {
    active: isSelectModalOpen,
    // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
    "no-select": ids === 0,
    "table-cell--disabled": isReadOnly,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "pointer-events-none": locked || isReadOnly,
    pinned,
  });

  // @ts-expect-error TS(7034) FIXME: Variable 'records' implicitly has type 'any[]' in ... Remove this comment to see the full error message
  let records;
  switch (dataType) {
    case "record:policy":
      records = mainStore.records.policies;
      break;
    case "record:procedure":
      records = mainStore.records.procedures;
      break;
    case "record:training":
      records = mainStore.records.trainings;
      break;
    case "record:audit":
      records = mainStore.records.audits;
      break;
    case "record:qa_tests_development":
      records = mainStore.records.qaTestsDevelopments;
      break;
    default:
      records = [];
  }

  // @ts-expect-error TS(7005) FIXME: Variable 'records' implicitly has an 'any[]' type.
  const filteredRecords = records
    .filter(
      (record) =>
        record.meta.name?.toLowerCase().includes(recordQuery.toLowerCase()),
    )
    .sort((a, b) => a.updated_at?.localeCompare(b.updated_at));

  return (
    <Popup
      position="bottom right"
      trigger={
        <li
          className={triggerClasses}
          style={triggerStyle}
          data-testid="record-select-trigger"
        >
          <div className="cell-content">
            {ids?.length === 0 && (
              <div className="no-select">- Select {formattedRecordType} -</div>
            )}
            <div className="selected-parameter-block">
              {/* @ts-expect-error TS(7005) FIXME: Variable 'records' implicitly has an 'any[]' type. */}
              {records
                .filter(
                  (record) =>
                    ids?.map((item) => Number(item))?.includes(record.id),
                )
                .map((record) => (
                  <span key={record.id}>{record.meta.name}</span>
                ))}
            </div>
          </div>
        </li>
      }
      open={showPopup}
      onOpen={onOpen}
      onClose={onClose}
      keepTooltipInside
    >
      <div
        className="users-dropdown linked-records-dropdown"
        data-testid="record-select-dropdown"
      >
        {records?.length > 0 && (
          <div className="users-dropdown-search">
            <input
              type="search"
              data-testid="record-select-search-input"
              // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              placeholder={`Search ${FORMATTED_RECORD_TYPE_MAP[dataType]}`}
              onChange={(event) => {
                setRecordQuery(event.target.value);
              }}
            />
          </div>
        )}
        {ids?.length > 0 && (
          <div className="users-dropdown-block">
            <ul>
              {/* @ts-expect-error TS(7005) FIXME: Variable 'records' implicitly has an 'any[]' type. */}
              {records
                .filter((record) => ids?.includes(record.id))
                .map((record) => (
                  <li key={record.id}>
                    {record.meta.name}
                    <span
                      key={record.id}
                      onClick={() => {
                        removeItemById(record.id);
                      }}
                    >
                      <img src={closeIcon2} alt="closeIcon2" />
                    </span>
                  </li>
                ))}
            </ul>
          </div>
        )}

        <div className="users-dropdown-list">
          {filteredRecords?.length === 0 && (
            <h4 className="no-result">No records found</h4>
          )}
          <ul>
            {filteredRecords
              .slice(0, isExpanded ? Infinity : MAX_INITIAL_RECORDS)
              .filter((record) => !ids?.includes(record.id))
              .map((record) => (
                <li
                  key={record.id}
                  data-testid="record-select-li"
                  onClick={() => {
                    addItemById(record.id);
                  }}
                >
                  {record.meta.name}
                </li>
              ))}
          </ul>

          {!isExpanded && records?.length > MAX_INITIAL_RECORDS && (
            <button
              className="button"
              data-testid="record-select-additional-records-button"
              onClick={expandList}
            >
              {records?.length - MAX_INITIAL_RECORDS}
              {" additional available record"}
              {records?.length - MAX_INITIAL_RECORDS > 1 ? "s" : ""}
            </button>
          )}
        </div>
      </div>
    </Popup>
  );
}

RecordSelect.defaultProps = {
  fetchOnInit: false,
  handleSelect: () => {},
  width: 200,
};

export default observer(RecordSelect);
