import "./questionnaire-review-page.scss";

import { Button } from "@themis/ui";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { PiPencilSimpleLineBold } from "react-icons/pi";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { AnswersAPI } from "@/api/legacy/risk-assessment";
import { QuestionnaireAPI } from "@/api/legacy/risk-assessment/QuestionnaireApi";
import { Flex, Icon, Typography } from "@/components/Elements";
import Loading from "@/components/Loading";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import CommentsSlideMenu from "@/components/table/shared/comments/CommentsSlideMenu";
import { useMainStore } from "@/contexts/Store";
import Table from "@/features/risk-assessment/components/Table/GenericTable/Table";
import TableCellSpacer from "@/features/risk-assessment/components/Table/GenericTable/TableCellSpacer";
import TableHeaderCell from "@/features/risk-assessment/components/Table/GenericTable/TableHeaderCell";
import {
  REVIEWER,
  RISK_ASSESSMENT_STATUS_DISPLAY,
} from "@/features/risk-assessment/config";
import {
  useAssessmentData,
  useQuestionnaireData,
} from "@/features/risk-assessment/hooks";
import {
  BaseQuestion,
  QuestionnaireRead,
  QuestionnaireStatus,
  QuestionRead,
  QuestionType,
  ReviewerType,
} from "@/features/risk-assessment/types/questionnaire";
import {
  AnswerRead,
  TextAnswer,
} from "@/features/risk-assessment/types/questionnaire-form";
import {
  getQuestionsRemainingForGroups,
  mapFormGroupsAndQuestionsToFormGroups,
} from "@/stores/helpers/RiskAssessmentHelpers";

import { ROUTES } from "..";
import { ModuleContentWrapper } from "../../components/ModuleContentWrapper/ModuleContentWrapper";
import ModuleHeader from "../../components/ModuleHeader/ModuleHeader";
import { DisplayFollowUpQuestionsSidebarProvider } from "../../components/Questionnaires/providers/DisplayFollowUpQuestionsSidebarProvider";
import { FollowUpSidebarProvider } from "../../components/Questionnaires/providers/FollowUpSidebarProvider";
import QuestionnaireFormQuestion from "../../components/Questionnaires/QuestionnaireForm/QuestionnaireFormQuestion/QuestionnaireFormQuestion";
import QuestionnaireGroups from "../../components/Questionnaires/QuestionnaireGroups/QuestionnaireGroups";
import QuestionnaireProgress from "../../components/Questionnaires/QuestionnaireProgress/QuestionnaireProgress";
import AttachmentAnswerReview from "../../components/Questionnaires/QuestionnaireReview/AttachmentAnswerReview";
import FinalizeButton from "../../components/Questionnaires/QuestionnaireReview/FinalizeButton";
import RadioAnswerReview from "../../components/Questionnaires/QuestionnaireReview/RadioAnswerReview";
import ReviewQuestionnaireUserAssignment from "../../components/Questionnaires/QuestionnaireReview/ReviewQuestionnaireUserAssignment";
import TextAnswerReview from "../../components/Questionnaires/QuestionnaireReview/TextAnswerReview";
import QuestionsRemaining from "../../components/Questionnaires/QuestionsRemaining/QuestionsRemaining";
import SummaryContent from "../../components/Summary/SummaryContent";
import UserAssignment from "../../components/UserAssignment";
import { getPartnerName } from "../../utils/partners";
import {
  patchQuestionsWithOrder,
  removeFollowupQuestions,
  removeHiddenQuestions,
} from "../../utils/review-helpers";

type QuestionnaireReviewPageProps = {
  redirectPathAfterCompletion?: string;
  isPartner?: boolean;
};

function QuestionnaireReviewPage(props: QuestionnaireReviewPageProps) {
  const history = useHistory();
  const mainStore = useMainStore();
  const activeWorkspaceId: number | undefined =
    mainStore.context.activeWorkspace?.id;
  const { workspaceID, isAdmin } = mainStore.context!;
  const { id: userID } = mainStore.users.user;

  const isInternalUser = !!mainStore.workspaces.list.find(
    (ws) => ws.is_internal,
  );
  const { isPartner, redirectPathAfterCompletion } = props;
  const {
    questionnaire,
    riskMethodology,
    riskAreas,
    setQuestionnaire,
    fetchQuestionnaireData,
  } = useQuestionnaireData(activeWorkspaceId);
  const { assessment, setAssessment } = useAssessmentData(activeWorkspaceId);

  const { workspace_id, questionnaireId } = useParams<{
    questionnaireId: string;
    workspace_id: string;
  }>();

  useEffect(() => {
    async function setInReview() {
      if (questionnaire && questionnaire?.status === "ready_for_review") {
        await QuestionnaireAPI.setInReview(Number(questionnaireId));
      }
    }
    setInReview();
  }, [questionnaire]);

  useEffect(() => {
    if (!workspaceID) {
      return;
    }
    mainStore.users.indexForModules({ workspaceID });
  }, [workspaceID]);

  const questionsByGroup = patchQuestionsWithOrder(
    removeFollowupQuestions(
      removeHiddenQuestions(questionnaire?.questions || []),
    ),
  );

  const status = questionnaire?.status as QuestionnaireStatus;
  const canChangeAssignees = isAdmin || !questionnaire?.is_internal;
  const isPreReview =
    status &&
    (
      [
        QuestionnaireStatus.NOT_STARTED,
        QuestionnaireStatus.INCOMPLETE,
      ] as string[]
    ).includes(status);
  const isInReview = status
    ? (
        [
          QuestionnaireStatus.IN_REVIEW,
          QuestionnaireStatus.READY_FOR_REVIEW,
        ] as string[]
      ).includes(status)
    : false;
  const isFinalized = QuestionnaireStatus.FINALIZED === status;

  const partnerName =
    questionnaire &&
    getPartnerName(
      mainStore.riskAssessmentPartners.partners,
      questionnaire.record_version_id.toString(),
    );

  const canReview =
    isAdmin ||
    questionnaire?.reviewer_ids?.length === 0 ||
    (userID && questionnaire?.reviewer_ids?.includes(userID));

  const reviewComplete = () => {
    const questionsToReview = questionnaire?.questions.filter(
      (q) => !!q.assignments.find((a) => a.user_id === userID),
    );

    const hasApprovedAll = questionsToReview?.every((q) =>
      Boolean(
        q.answer?.approved_by?.some((approval) => approval.user_id === userID),
      ),
    );
    return hasApprovedAll;
  };

  const replaceAnswer = (answer: AnswerRead, question: QuestionRead) => {
    const newQuestionnaire = structuredClone(questionnaire);

    const relevantQuestion = newQuestionnaire?.questions.find(
      (clonedQuestion) => clonedQuestion.id === question?.id,
    );
    if (relevantQuestion) {
      relevantQuestion.answer = answer;
    }
    setQuestionnaire(newQuestionnaire);
  };

  const updateQuestionnaireInAssessment = (
    updatedQuestionnaire: QuestionnaireRead,
  ) => {
    setQuestionnaire(updatedQuestionnaire);
    if (assessment) {
      const newAssessment = { ...assessment };
      if (newAssessment.questionnaires) {
        const index = newAssessment.questionnaires.findIndex(
          (q) => q.id === updatedQuestionnaire.id,
        );
        if (index === -1) {
          return;
        }
        newAssessment.questionnaires[index] = updatedQuestionnaire;
        setAssessment(newAssessment);
      }
    }
  };

  const mappedFormGroups = useMemo(() => {
    return mapFormGroupsAndQuestionsToFormGroups(
      questionnaire?.questions || [],
      questionnaire?.question_groups || [],
    );
  }, [questionnaire]);

  const [activeGroupId, setActiveGroupId] = useState(
    mappedFormGroups.length && mappedFormGroups[0].id,
  );
  const [isUserAssignmentSaving, setIsUserAssignmentSaving] = useState(false);
  const activeGroup =
    mappedFormGroups.find((group) => group.id === activeGroupId) ||
    mappedFormGroups[0];

  if (
    !questionnaire ||
    !riskMethodology ||
    !activeWorkspaceId ||
    (isPartner && !isInternalUser)
  ) {
    return <Loading loadingLayout="small-table" />;
  }

  const retrieveSelectedUsers = (groupID: number) =>
    (
      questionnaire?.question_groups
        .find(({ id }) => id === groupID)
        ?.assignments?.filter((assignment) => !assignment._destroy) || []
    ).map((assignment) => assignment.user_id);

  const setAssignments = async (groupID: number, ids: number[]) => {
    if (!questionnaire) {
      return;
    }
    const groupIndex = questionnaire.question_groups.findIndex(
      (subGroup) => subGroup.id === groupID,
    );
    const group = questionnaire.question_groups?.[groupIndex];
    if (!group) {
      return;
    }
    const assignmentUserIDs = group.assignments.map(
      (subGroup) => subGroup.user_id,
    );
    ids.forEach((id) => {
      if (!assignmentUserIDs.includes(id)) {
        group.assignments.push({
          user_id: id,
          assignment_type: REVIEWER,
        });
      }
    });
    group.assignments.forEach((assignment) => {
      if (!ids.includes(assignment.user_id)) {
        assignment._destroy = true;
      }
      if (ids.includes(assignment.user_id) && assignment._destroy) {
        assignment._destroy = false;
      }
    });
    try {
      setIsUserAssignmentSaving(true);
      const newQuestionnaire = await QuestionnaireAPI.updateGroupAssignments(
        questionnaire.id,
        group.id,
        group.assignments,
      );
      setQuestionnaire(newQuestionnaire);
      if (assessment) {
        const newAssessment = { ...assessment };
        const index = newAssessment.questionnaires.findIndex(
          (subQuestionnaire) => subQuestionnaire.id === newQuestionnaire.id,
        );
        if (index > -1) {
          newAssessment.questionnaires[index] = newQuestionnaire;
          setAssessment(newAssessment);
        }
      }
    } catch {
      mainStore.toast.setErrorText("Something went wrong");
    } finally {
      setIsUserAssignmentSaving(false);
    }
  };

  const handleSummaryQuestionGroup = async (
    questionGroupId: number,
    summary: string,
  ) => {
    QuestionnaireAPI.updateSummaryQuestionGroup(
      questionnaire.id,
      questionGroupId,
      summary,
    );
  };

  const handleSummaryOverallQuestionnaire = async (
    questionnaireOverallId: number,
    summary: string,
  ) => {
    QuestionnaireAPI.updateSummaryOverallQuestionnaire(
      questionnaireOverallId,
      summary,
    );
  };

  async function onChangeQuestionAssignees(
    question: BaseQuestion & { id: number },
    userIDs: number[],
  ) {
    try {
      setIsUserAssignmentSaving(true);

      const deletedAssignments = question.assignments
        .filter((assignment) => !userIDs.includes(assignment.user_id))
        .map((assignment) => ({
          ...assignment,
          _destroy: true,
        }));
      const newAssignments = userIDs
        .filter(
          (user_id) =>
            !question.assignments.some(
              (assignment) => assignment.user_id === user_id,
            ),
        )
        .map((user_id) => ({
          assignment_type: REVIEWER as ReviewerType,
          user_id,
        }));
      const allAssignments = [...deletedAssignments, ...newAssignments];

      const updatedQuestionnaire =
        await QuestionnaireAPI.updateQuestionAssignments(
          questionnaire!.id,
          question.id,
          allAssignments,
        );

      setQuestionnaire(updatedQuestionnaire);

      if (assessment) {
        setAssessment({
          ...assessment,
          questionnaires: assessment.questionnaires.map((q) =>
            q.id === updatedQuestionnaire.id ? updatedQuestionnaire : q,
          ),
        });
      }
    } catch (err) {
      mainStore.toast.setErrorText("Something went wrong");
    } finally {
      setIsUserAssignmentSaving(false);
    }
  }

  async function onApproveAll() {
    const questionsToReview = questionnaire?.questions.filter(
      (q) => !!q.assignments.find((a) => a.user_id === userID),
    );
    if (questionsToReview) {
      await Promise.all(
        questionsToReview
          .map((q) => {
            const approved = q.answer?.approved_by?.some(
              (approval) => approval.user_id === userID,
            );
            if (!approved) {
              return AnswersAPI.approve(Number(workspaceID), q.answer!.id!);
            }
            return false;
          })
          .filter((p) => p),
      );
      fetchQuestionnaireData();
    }
  }

  async function onChangeApproval(
    questionID: number,
    answerID: number,
    isApproved: boolean,
  ) {
    try {
      const answer = isApproved
        ? await AnswersAPI.approve(Number(workspaceID), answerID)
        : await AnswersAPI.unapprove(Number(workspaceID), answerID);

      if (questionnaire) {
        setQuestionnaire({
          ...questionnaire,
          questions: questionnaire!.questions.map((question) =>
            question.id === questionID
              ? {
                  ...question,
                  answer,
                }
              : question,
          ),
        });
      }

      if (assessment) {
        setAssessment({
          ...assessment,
          questionnaires: assessment.questionnaires.map((q) =>
            q.id === questionnaire!.id
              ? {
                  ...q,
                  questions: q.questions.map((question) =>
                    question.id === questionID
                      ? {
                          ...question,
                          answer,
                        }
                      : question,
                  ),
                }
              : q,
          ),
        });
      }
      mainStore.toast.setInfoText(
        `Answer ${isApproved ? "" : "un"}approved!`,
        true,
      );
    } catch (err) {
      mainStore.toast.setErrorText("Something went wrong");
    }
  }

  const onUnlock = async () => {
    document.querySelectorAll(".editAnswerButton").forEach((button) => {
      if (button instanceof HTMLElement) {
        button.click();
      }
    });
  };

  return (
    <FollowUpSidebarProvider>
      <DisplayFollowUpQuestionsSidebarProvider>
        <DashboardContent>
          <DashboardHeader
            title={`${partnerName} ${questionnaire?.name || "Questionnaire"} (${
              RISK_ASSESSMENT_STATUS_DISPLAY[status || "not_started"].text
            })`.trim()}
            onBackClick={() =>
              history.push(
                redirectPathAfterCompletion
                  ? generatePath(redirectPathAfterCompletion, {
                      assessmentId: assessment?.id,
                      workspace_id,
                    })
                  : generatePath(ROUTES.PARTNERS_QUESTIONNAIRES_PATH, {
                      record_version_id: questionnaire?.record_version_id,
                      workspace_id,
                    }),
              )
            }
          />
          <DashboardContentWrapper>
            <ModuleHeader>
              <QuestionnaireProgress
                questionnaireStatus={
                  isPreReview
                    ? QuestionnaireStatus.INCOMPLETE
                    : status || "not_started"
                }
              />

              {isInReview && questionnaire && (
                <div className="dashboard-group">
                  <Button
                    disabled={reviewComplete() || !canReview}
                    onClick={onApproveAll}
                  >
                    Approve All
                  </Button>
                  <Button
                    LeftIcon={PiPencilSimpleLineBold}
                    color="tertiary"
                    onClick={onUnlock}
                  >
                    Unlock
                  </Button>
                  <FinalizeButton
                    questionnaireId={questionnaire.id}
                    assessmentId={assessment?.id}
                    updateQuestionnaire={updateQuestionnaireInAssessment}
                    redirectPathAfterCompletion={
                      props.redirectPathAfterCompletion
                    }
                    disabled={!canReview || !reviewComplete()}
                  />
                </div>
              )}
            </ModuleHeader>
            <ModuleContentWrapper>
              {(isInReview || isFinalized) && (
                <Flex column rowGap={32}>
                  {questionsByGroup.map(([groupId, groupedQuestions]) => {
                    const questionGroup = questionnaire?.question_groups.find(
                      ({ id }) => id === Number(groupId),
                    );
                    return (
                      <Flex
                        column
                        key={groupId}
                        rowGap={8}
                        className="tw-pb-[25px]"
                      >
                        <div className="questionnaire-review-title-section">
                          <Typography
                            color="generalMidnightDark"
                            size="lg"
                            label={questionGroup?.text}
                          />

                          <CommentsSlideMenu
                            CustomTrigger={() => (
                              <div className="question-group-comment-icon">
                                <Icon name="commentLinear" />
                              </div>
                            )}
                            SubHeader={() => (
                              <div className="section-commentary-subheader">
                                {questionGroup?.text}
                              </div>
                            )}
                            renderCommentsButton
                            recordID={Number(groupId)}
                            taskableType="QuestionGroup"
                            title="Summary"
                            hideEmptyState
                            isIconButton
                          />
                          {!isFinalized && (
                            <div className="questionnaire-review-user-selection">
                              <UserAssignment
                                assignedUsers={mainStore.users.allUsers.filter(
                                  (user) =>
                                    retrieveSelectedUsers(
                                      Number(groupId),
                                    ).includes(user.id),
                                )}
                                disabled={!canChangeAssignees}
                              >
                                <ReviewQuestionnaireUserAssignment
                                  selectedIDs={retrieveSelectedUsers(
                                    Number(groupId),
                                  )}
                                  setSelectedIDs={(ids: number[]) =>
                                    setAssignments(Number(groupId), ids)
                                  }
                                  isSaving={isUserAssignmentSaving}
                                />
                              </UserAssignment>
                            </div>
                          )}
                        </div>
                        <Table
                          header={
                            <>
                              <TableCellSpacer />
                              <TableHeaderCell
                                title="Status"
                                firstDataHeader
                                leftIcon={
                                  <Icon
                                    color="extrasBlueGrayDarker"
                                    name="listBullet"
                                  />
                                }
                              />
                              <TableHeaderCell
                                title="Reviewers"
                                leftIcon={
                                  <Icon
                                    color="extrasBlueGrayDarker"
                                    name="user"
                                  />
                                }
                              />
                              <TableHeaderCell
                                title="Response"
                                leftIcon={
                                  <Icon
                                    color="extrasBlueGrayDarker"
                                    name="listBullet"
                                  />
                                }
                              />
                              <TableHeaderCell
                                title="Question"
                                leftIcon={
                                  <Icon
                                    color="extrasBlueGrayDarker"
                                    name="book1"
                                  />
                                }
                              />

                              <TableHeaderCell
                                title="Response Rating"
                                leftIcon={
                                  <Icon
                                    color="extrasBlueGrayDarker"
                                    name="number"
                                  />
                                }
                              />
                              <TableHeaderCell
                                title="Explanation"
                                leftIcon={
                                  <Icon
                                    color="extrasBlueGrayDarker"
                                    name="commentLinear"
                                  />
                                }
                              />
                              <TableHeaderCell
                                title="Final Rating"
                                leftIcon={
                                  <Icon
                                    color="extrasBlueGrayDarker"
                                    name="number"
                                  />
                                }
                              />
                              <TableHeaderCell
                                title="Change Rationale"
                                leftIcon={
                                  <Icon
                                    color="extrasBlueGrayDarker"
                                    name="commentLinear"
                                  />
                                }
                              />
                              <TableHeaderCell
                                title="Risk Area"
                                leftIcon={
                                  <Icon
                                    color="extrasBlueGrayDarker"
                                    name="listBullet"
                                  />
                                }
                              />
                              <TableHeaderCell
                                title="Inherent / Control"
                                leftIcon={
                                  <Icon
                                    color="extrasBlueGrayDarker"
                                    name="listBullet"
                                  />
                                }
                                lastDataHeader
                              />
                              {isInReview && <TableCellSpacer />}
                            </>
                          }
                        >
                          {groupedQuestions.map((q, index) => {
                            const answerReviewProps = {
                              rowIndex: index,
                              question: q,
                              groupAssigneeIDs: retrieveSelectedUsers(
                                Number(groupId),
                              ),
                              riskMethodology,
                              riskAreas,
                              updateAnswer: replaceAnswer,
                              isEditable: isInReview,
                              order: q.order,
                              onChangeAssignees: onChangeQuestionAssignees,
                              onChangeApproval,
                              isInternalQuestionnaire:
                                !!questionnaire?.is_internal,
                            };

                            if (q.input_type === QuestionType.TEXT) {
                              (
                                answerReviewProps.question.answer as TextAnswer
                              ).comment =
                                answerReviewProps.question.answer?.comment ||
                                "";
                            }

                            if (
                              [
                                QuestionType.RADIO,
                                QuestionType.YES_NO,
                              ].includes(q.input_type)
                            ) {
                              return (
                                <RadioAnswerReview
                                  key={q.id}
                                  {...answerReviewProps}
                                />
                              );
                            } else if (
                              QuestionType.DOCUMENTS === q.input_type
                            ) {
                              return (
                                <AttachmentAnswerReview
                                  key={q.id}
                                  {...answerReviewProps}
                                />
                              );
                            } else if (QuestionType.TEXT === q.input_type) {
                              return (
                                <TextAnswerReview
                                  key={q.id}
                                  {...answerReviewProps}
                                />
                              );
                            }

                            return (
                              <div key={q.id}>
                                Question type {q.input_type} not supported in
                                review
                              </div>
                            );
                          })}
                        </Table>
                        <SummaryContent
                          summaryType="questionGroup"
                          summary={questionGroup?.summary || ""}
                          questionGroup={questionGroup}
                          onChangeSummary={(
                            questionGroupId: number,
                            summary: string,
                          ) =>
                            handleSummaryQuestionGroup(questionGroupId, summary)
                          }
                        />
                      </Flex>
                    );
                  })}
                  <Typography
                    color="generalMidnightDark"
                    size="lg"
                    label="Overall Questionnaire Summary"
                  />
                  <SummaryContent
                    summaryType="questionnaire"
                    summary={questionnaire?.summary || ""}
                    questionnaire={questionnaire}
                    onChangeSummary={(
                      questionnaireOverallId: number,
                      summary: string,
                    ) =>
                      handleSummaryOverallQuestionnaire(
                        questionnaireOverallId,
                        summary,
                      )
                    }
                  />
                </Flex>
              )}
              {isPreReview && (
                <div className="questionnaire-review-page-preview">
                  <Typography
                    className="questionnaire-review-page-preview__heading-text"
                    label="The questionnaire has not been submitted for review yet. This is a read only version of the questionnaire form."
                  />
                  <div className="questionnaire-review-page-preview__groups-and-questions">
                    <div className="questionnaire-review-page-preview__groups">
                      <QuestionnaireGroups
                        groups={mappedFormGroups}
                        activeGroupId={activeGroupId}
                        onActiveGroupChanged={(id) => setActiveGroupId(id)}
                        GroupsHeader={
                          <Typography
                            label="Sections"
                            color="generalMidnightDark"
                            weight="semiBold"
                          />
                        }
                      />
                      <QuestionsRemaining
                        count={getQuestionsRemainingForGroups(mappedFormGroups)}
                      />
                    </div>
                    <div className="questionnaire-review-page-preview__questions-wrapper">
                      <div className="questionnaire-review-title-section questionnaire-review-title-section__normal_alignment">
                        <Typography
                          label={activeGroup.name}
                          className="questionnaire-review-page-preview__group-name"
                          color="generalMidnightDark"
                          weight="semiBold"
                        />
                        <div className="questionnaire-review-user-selection">
                          <UserAssignment
                            assignedUsers={mainStore.users.allUsers.filter(
                              (user) =>
                                retrieveSelectedUsers(
                                  Number(activeGroup.id),
                                ).includes(user.id),
                            )}
                            disabled={!canChangeAssignees}
                          >
                            <ReviewQuestionnaireUserAssignment
                              selectedIDs={retrieveSelectedUsers(
                                Number(activeGroup.id),
                              )}
                              setSelectedIDs={(ids: number[]) =>
                                setAssignments(Number(activeGroup.id), ids)
                              }
                              isSaving={isUserAssignmentSaving}
                            />
                          </UserAssignment>
                        </div>
                      </div>
                      <div className="questionnaire-review-page-preview__questions">
                        {activeGroup.questions.map((question, i) => (
                          <QuestionnaireFormQuestion
                            key={question.id}
                            order={String(i + 1)}
                            question={question}
                            disableAnswering
                            onChangeAssignees={(user_ids) =>
                              onChangeQuestionAssignees(question, user_ids)
                            }
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </ModuleContentWrapper>
          </DashboardContentWrapper>
        </DashboardContent>
      </DisplayFollowUpQuestionsSidebarProvider>
    </FollowUpSidebarProvider>
  );
}

export default observer(QuestionnaireReviewPage);
