import "./styles.scss";

import React, { useCallback, useState } from "react";
import Popup from "reactjs-popup";

import { IconButton } from "@/components/Elements";
import { IconButtonProps } from "@/components/Elements/IconButton";
import { IconMenuContext } from "@/components/IconMenu/context";

export function IconMenu(props: {
  nested?: boolean;
  triggerTestId?: string;
  containerTestId?: string;
  icon: IconButtonProps["icon"];
  children?: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const handleSetOpen = useCallback(
    (nextOpen: boolean) => setOpen(nextOpen),
    [],
  );

  return (
    <IconMenuContext.Provider value={{ open, setOpen: handleSetOpen }}>
      <Popup
        nested={props.nested}
        keepTooltipInside
        position="bottom right"
        trigger={
          <div data-testid={props.triggerTestId}>
            <IconButton icon={props.icon} transparent active={open} />
          </div>
        }
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <div
          role="menu"
          className="dropdown__container"
          data-testid={props.containerTestId}
        >
          {props.children}
        </div>
      </Popup>
    </IconMenuContext.Provider>
  );
}
