import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import type { ImportContactsMutationRequest } from "@/api";
import { importContacts } from "@/api/gen/axios/importContactsController";
import { RailsErrors } from "@/utils/errors";

export const IMPORT_CONTACTS_QUERY_KEY = "contacts";

export function useImportContacts({
  workspaceId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  onSuccess?: () => void;
  onError?: (error: Error | AxiosError<{ errors: RailsErrors }>) => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ImportContactsMutationRequest) =>
      importContacts(workspaceId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [IMPORT_CONTACTS_QUERY_KEY],
      });
      onSuccess();
    },
    onError,
  });
}
