import axios from "axios";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { QuestionnaireAPI } from "@/api/legacy/risk-assessment/QuestionnaireApi";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import { useMainStore } from "@/contexts/Store";
import {
  type FlattedAnswer,
  type QuestionGroupRead,
  type QuestionnaireFormRead,
  type QuestionRead,
  RESPONDER,
  ROUTES,
  type QuestionnaireForm as TQuestionnaireForm,
} from "@/features/risk-assessment";

import { ModuleContentWrapper } from "../../components/ModuleContentWrapper/ModuleContentWrapper";
import QuestionnaireFormBody from "../../components/Questionnaires/QuestionnaireForm/QuestionnaireFormBody";

type Props = {
  onlyInternalUsers?: boolean;
  completionPath?: string;
  hideResponderChange?: boolean;
  showResponderList?: boolean;
  showRespondentFilter?: boolean;
};

function PartnerQuestionnaireReview({
  onlyInternalUsers,
  completionPath = ROUTES.DASHBOARD_PATH,
  hideResponderChange = false,
  showResponderList = false,
  showRespondentFilter = false,
}: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  const wsOnlyUserIDs = mainStore.users.allUsers
    .filter((user) => user.is_internal_user === Boolean(onlyInternalUsers))
    .map((user) => user.id);
  const { questionnaireId } = useParams<{
    questionnaireId: string;
  }>();

  let questionnaireCompletionRedirectPath = generatePath(completionPath, {
    workspace_id: Number(workspaceID),
  });
  if (questionnaireId) {
    questionnaireCompletionRedirectPath = onlyInternalUsers
      ? generatePath(ROUTES.QUESTIONNAIRE_REVIEW_PATH, {
          questionnaireId,
          workspace_id: Number(workspaceID),
        })
      : generatePath(ROUTES.DASHBOARD_PATH, {
          workspace_id: Number(workspaceID),
        });
  }

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<QuestionnaireFormRead | undefined>();
  const [questionnaireForm, setQuestionnaireForm] =
    useState<TQuestionnaireForm | null>(null);

  const history = useHistory();

  const loadQuestionnaireForm = () => {
    setIsLoading(true);
    QuestionnaireAPI.get(Number(questionnaireId))
      .then((questionnaire) => {
        setForm({
          company_logo_url: "",
          password_protected: false,
          download_slug: "",
          ...questionnaire,
        });
        setQuestionnaireForm({
          company_logo_url: "",
          password_protected: false,
          download_slug: "",
          ...questionnaire,
          external_id: questionnaireId,
          groups: [],
        });
      })
      .catch((err) => {
        if (axios.isAxiosError(err)) {
          if (err?.response?.status === 401) {
            return;
          }

          if (err?.response?.status === 404) {
            return mainStore.toast.setErrorText(
              "The questionnaire either doesn't exist or the link has expired.",
            );
          }
        }

        mainStore.toast.setErrorText(
          "There was an issue loading the questionnaire. Please refresh to try again.",
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setResponders = async (
    questionID: number,
    assigneeIDs: number[],
    isQuestion: boolean = true,
  ) => {
    if (!form) {
      return;
    }

    let assignable: QuestionGroupRead | QuestionRead | undefined;

    if (isQuestion) {
      assignable = form.questions.find((q) => q.id === questionID);
    } else {
      assignable = form.question_groups.find((q) => q.id === questionID);
    }

    if (!assignable) {
      return;
    }

    const allResponders = [...(assignable.responders || [])].map(
      (assignment) => ({ ...assignment, _destroy: true }),
    );

    const responderMap = new Map(
      allResponders.map((responder) => [responder.id, responder]),
    );

    assigneeIDs.forEach((id) => {
      const assignment = responderMap.get(id);
      if (assignment) {
        assignment._destroy = false;
      } else {
        allResponders.push({
          user_id: id,
          assignment_type: RESPONDER,
          _destroy: false,
        });
      }
    });

    try {
      const newQuestionnaire = await QuestionnaireAPI.updateQuestionResponders(
        Number(questionnaireId),
        questionID,
        allResponders,
        isQuestion,
      );
      setForm(newQuestionnaire);
    } catch {
      mainStore.toast.setErrorText("Something went wrong");
    }
  };

  const saveAnswerViaAPI = async (
    subQuestionnaireID: string | number,
    answers: FlattedAnswer[],
    isCompleted: boolean = false,
  ): Promise<QuestionnaireFormRead> => {
    try {
      const submittedQuestionnaire = await QuestionnaireAPI.saveAnswers(
        parseInt(subQuestionnaireID.toString()),
        answers,
        isCompleted,
      );
      return {
        ...submittedQuestionnaire,
        password_protected: false,
        company_logo_url: "",
        download_slug: "",
      };
    } catch {
      mainStore.toast.setErrorText("Something went wrong while saving");
      return form as QuestionnaireFormRead;
    }
  };

  const handleChangeResponders = (
    questionID: number,
    assigneeIDs: number[],
    isQuestion: boolean = true,
  ) => {
    if (hideResponderChange) {
      return undefined;
    }

    return setResponders(questionID, assigneeIDs, isQuestion);
  };

  useEffect(() => {
    loadQuestionnaireForm();
  }, []);

  useEffect(() => {
    if (!workspaceID) {
      return;
    }
    mainStore.users.indexForModuleIfNoUsersPresent(workspaceID);
  }, [workspaceID]);

  return (
    <DashboardContent>
      <DashboardHeader
        title="Questionnaire Review"
        onBackClick={() => history.goBack()}
      />
      <DashboardContentWrapper>
        <ModuleContentWrapper>
          {!isLoading && form && questionnaireForm && (
            <QuestionnaireFormBody
              questionnaireForm={form}
              externalId={questionnaireId}
              disabled={questionnaireForm.completed_at !== null}
              afterSubmit={async (questionnaire: TQuestionnaireForm) => {
                questionnaire.completed &&
                  history.push(questionnaireCompletionRedirectPath);
              }}
              onChangeResponders={handleChangeResponders}
              cwOnlyUserIDs={wsOnlyUserIDs}
              saveAnswerViaAPI={saveAnswerViaAPI}
              hideResponderChange={hideResponderChange}
              showResponderList={showResponderList}
              showRespondentFilter={showRespondentFilter}
            />
          )}
        </ModuleContentWrapper>
      </DashboardContentWrapper>
    </DashboardContent>
  );
}

export default observer(PartnerQuestionnaireReview);
