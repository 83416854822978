import { IconButton } from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";
import { PiArchiveBold, PiCaretRightBold } from "react-icons/pi";

import { Task } from "@/api";
import { useUpdateTask } from "@/api/queries/tasks";
import { useMainStore } from "@/contexts/Store";

function TaskActionsCell({ task }: { task: Task }) {
  const {
    taskDetail,
    context: { companyID },
  } = useMainStore();
  const { mutate: updateTask } = useUpdateTask({
    companyId: Number(companyID),
  });

  return (
    <div className="tw-flex tw-gap-1">
      {task.archived_at && (
        <IconButton
          color="tertiary"
          Icon={PiArchiveBold}
          onClick={() =>
            updateTask({ id: Number(task.id), task: { archived_at: null } })
          }
          data-tooltip-id="tooltip"
          data-tooltip-place="top"
          data-tooltip-content="Unarchive task"
        />
      )}
      <IconButton
        Icon={PiCaretRightBold}
        onClick={() => taskDetail.open(task)}
        data-tooltip-id="tooltip"
        data-tooltip-place="top"
        data-tooltip-content="Open task detail"
      />
    </div>
  );
}

export default observer(TaskActionsCell);
