import { observer } from "mobx-react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import Spinner from "../../table/shared/Spinner";

type ScreenType = "active";

type Props = {
  libraryType: string;
  handleScreenChange: (screen: ScreenType, fromDialog?: boolean) => void;
};

function ControlLibraryApplyButton({ libraryType, handleScreenChange }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [isSaving, setIsSaving] = useState(false);

  // Variables
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const sectionTagId = searchParams.get("sectionTagId");
  const { libraryRecords } = mainStore.controlMappings;
  const disabled = !libraryRecords.some((item) => item.checked) || isSaving;

  // Functions
  async function handleClick() {
    setIsSaving(true);

    const ids = libraryRecords
      .filter((item) => item.checked)
      .map((item) => item.id);

    mainStore.pageLoading.startLoading();
    await mainStore.controlMappings.createBulk(ids, sectionTagId, libraryType);
    mainStore.pageLoading.endLoading();

    mainStore.toast.setText(
      "Control Mapping Records Being Created. This may take a few minutes.",
    );
    handleScreenChange("active");
  }

  return (
    <button
      className="table-button header-action"
      disabled={disabled}
      onClick={handleClick}
      data-testid="add-to-controls-button"
    >
      Add to Controls
      {isSaving && <Spinner />}
    </button>
  );
}

export default observer(ControlLibraryApplyButton);
