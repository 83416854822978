import classNames from "classnames";
import dayjs from "dayjs";
import React from "react";

import { Contract } from "@/api";

function VendorDetailAllContractsContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div
      className="build-vendor-block contract-detail-block"
      data-testid="vdd-contracts-section"
    >
      <div className="contracts-header">
        <div className="title">All Contracts</div>
      </div>
      {children}
    </div>
  );
}

export function VendorDetailAllContracts({
  contracts,
  canManageAccounts,
}: {
  contracts?: Contract[];
  canManageAccounts: boolean;
}) {
  if (!canManageAccounts) {
    return (
      <VendorDetailAllContractsContainer>
        <article className="tw-box-border tw-flex tw-h-9 tw-flex-col tw-items-center tw-justify-center tw-gap-6 tw-self-stretch tw-rounded-lg tw-bg-neutral-50 tw-px-3 tw-py-2.5 tw-text-xs tw-text-neutral-300">
          You do not have access to view contracts
        </article>
      </VendorDetailAllContractsContainer>
    );
  }

  if (!contracts?.length) {
    return (
      <VendorDetailAllContractsContainer>
        <div className="empty-contracts" data-testid="vdd-contracts-empty">
          No Contracts added yet. Contracts added to Account will show up here.
        </div>
      </VendorDetailAllContractsContainer>
    );
  }

  return (
    <VendorDetailAllContractsContainer>
      <div className="contracts-container">
        {contracts.map((contract, index) => (
          <div
            key={`contract-${index}`}
            className="contract-item"
            data-testid="vdd-contracts-item"
          >
            <p className="date">
              {dayjs(contract.start_date).format("MM/DD/YYYY")}
            </p>
            <p className="date">
              - {dayjs(contract.end_date).format("MM/DD/YYYY")}
            </p>
            <div
              className={classNames("terms", {
                "with-value": contract.terms !== "0",
              })}
            >
              <p>Termination Notice:</p>
              <p>{contract.terms === "0" ? "-" : contract.terms}</p>
            </div>
          </div>
        ))}
      </div>
    </VendorDetailAllContractsContainer>
  );
}
