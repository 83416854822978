import { RecordVersion } from "@/api/gen/models/RecordVersion";
import { INHERENT_MATRIX_TITLE } from "@/components/table/risk-register/Constants";
import { RiskRegisterScoringMatrix } from "@/stores/types/risk-register-types";

export function getMatchingRecordsForMatrixIndex(
  matrix: RiskRegisterScoringMatrix,
  recordVersions: RecordVersion[],
  rowIndex: number,
  colIndex: number,
): RecordVersion[] {
  return recordVersions.filter((rv) => {
    if (rv.status !== "completed") {
      return false;
    }

    const rowValue =
      matrix.name === INHERENT_MATRIX_TITLE
        ? rv.data.impact?.options[0]
        : // @ts-expect-error ...
          rv.inherent_risk_rating_cell?.value;
    const columnValue =
      matrix.name === INHERENT_MATRIX_TITLE
        ? rv.data.likelihood?.options[0]
        : rv.data.control_rating?.options[0];
    return (
      rowValue === matrix.scoring_matrix_rows.length - rowIndex &&
      columnValue === colIndex + 1
    );
  });
}
