import React from "react";

import { Typography } from "../Elements";

type Props = {
  title: string;
};

const PageHeader = ({ title }: Props) => (
  <div className="page-header">
    <Typography
      color="generalMidnightDark"
      type="h1"
      label={title}
      size="lg"
      weight="semiBold"
    />
  </div>
);

export default PageHeader;
