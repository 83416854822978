import classNames from "classnames";
import React, { useState } from "react";

import advertisingMarketingImage from "../../images/setting-workspace/advertising-marketing-image.svg";
import issueManagementImage from "../../images/setting-workspace/issue-management-image.svg";
import policiesProceduresImage from "../../images/setting-workspace/policies-procedures-image.svg";
import closeIcon from "../../images/table-image/icon/close-black-icon.svg";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emailSlideClose?: (...args: any[]) => any;
};

function SharedModules({ emailSlideClose }: Props) {
  // State
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [disablingSharedModules, setDisablingSharedModules] = useState(false);

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function switchChange(event) {
    if (!event.target.checked) {
      setDisablingSharedModules(true);
      setButtonDisabled(false);
    } else {
      setDisablingSharedModules(false);
      setButtonDisabled(true);
    }
  }

  const buttonClasses = classNames({ active: !buttonDisabled });

  return (
    <div className="table-dropdown table-email-dropdown">
      <div className="email-dropdown-head">
        <h3>Shared Modules</h3>
        <div onClick={emailSlideClose}>
          <img src={closeIcon} alt="close-icon" />{" "}
        </div>
      </div>
      <hr />
      <div className="shared-modules-wrap">
        <div className="internal-modules-block">
          <img src={policiesProceduresImage} alt="policies-procedures-image" />
          <h4>Policies & Procedures</h4>
          <div className="toggle-checkbox">
            <label className="switch">
              <input type="checkbox" defaultChecked />
              <span className="slider" />
            </label>
          </div>
        </div>
        <div className="internal-modules-block">
          <img
            src={advertisingMarketingImage}
            alt="advertising-marketing-image"
          />
          <h4>Advertising and Marketing</h4>
          <div className="toggle-checkbox">
            <label className="switch">
              <input type="checkbox" defaultChecked />
              <span className="slider" />
            </label>
          </div>
        </div>
        <div className="internal-modules-block">
          <img src={issueManagementImage} alt="issue-management-image" />
          <h4>Issue Management</h4>
          <div className="toggle-checkbox">
            <label className="switch">
              <input type="checkbox" defaultChecked onChange={switchChange} />
              <span className="slider" />
            </label>
          </div>
        </div>
      </div>
      <hr />
      {disablingSharedModules && (
        <div className="disabling-shared-modules">
          <h4>Disabling Shared Modules</h4>
          <p>
            Turning off a module will result in losing access to the data in
            this collaborative workspace. Other companies and workspaces will
            keep access to this data.
          </p>
        </div>
      )}
      <button className={buttonClasses} disabled={buttonDisabled}>
        Confirm
      </button>
    </div>
  );
}

export default SharedModules;
