import {
  Attachment,
  AttachmentFileType,
  attachmentFileType,
  AttachmentGroup,
} from "@/api";

export function getFilenames(
  attachmentGroups?: Array<AttachmentGroup>,
  showFinalized = false,
): Array<string> {
  const filenames = attachmentGroups?.map((attachmentGroup) => {
    const latestAttachment = getLatestAttachment(attachmentGroup);

    if (showFinalized && latestAttachment && latestAttachment.processed) {
      return latestAttachment.processed.filename;
    }

    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    return latestAttachment.original.filename;
  });

  return filenames || [];
}

export function hasFigmaAttachment(
  attachmentGroups?: Array<AttachmentGroup>,
): boolean {
  const attachments = getAllAttachments(attachmentGroups);
  return attachments.some(
    (attachment) => attachment.file_type === attachmentFileType.figma,
  );
}

export function getFigmaAttachment(
  attachmentGroups?: Array<AttachmentGroup>,
): Attachment | null {
  const attachments = getAllAttachments(attachmentGroups);
  const figmaAttachment = attachments.find(
    (attachment) => attachment.file_type === attachmentFileType.figma,
  );
  if (figmaAttachment) {
    return figmaAttachment;
  }
  return null;
}

export function getLatestAttachment(
  attachmentGroup?: AttachmentGroup,
): Attachment | undefined {
  if (!attachmentGroup) {
    return undefined;
  }
  const attachments = getAllAttachments([attachmentGroup]);
  const maxVersion = maxAttachmentVersion(attachments);
  return attachments.find((attachment) => attachment.version === maxVersion);
}

export function getFirstAttachment(
  attachmentGroup?: AttachmentGroup,
): Attachment | undefined {
  if (!attachmentGroup) {
    return undefined;
  }
  const attachments = getAllAttachments([attachmentGroup]);
  return attachments.find((attachment) => attachment.version === 1);
}

export function maxAttachmentVersion(attachments?: Array<Attachment>) {
  if (!attachments) {
    return -1;
  }

  const maxVersion = Math.max(
    ...attachments.map((attachment) => attachment.version),
  );
  if (!maxVersion || isNaN(maxVersion)) {
    return -1;
  }

  return maxVersion;
}

export function getAllAttachments(
  attachmentGroups?: AttachmentGroup[],
): Attachment[] {
  return (
    attachmentGroups
      ?.map((attachmentGroup) => attachmentGroup.attachments)
      ?.flat(1) || []
  );
}

type ExternalMetaData = {
  file_id: string;
  driver_id: string;
};

export function buildUploadPayload(
  fieldName: string,
  fileType: AttachmentFileType,
  signedID?: string,
  url?: string,
  deleteExistingAttachments?: boolean,
  externalMetaData?: ExternalMetaData,
) {
  type Params = {
    [key: string]: string | number | boolean | ExternalMetaData | Array<object>;
  };

  const attachmentParams: Params = {
    file_type: fileType,
  };

  switch (fileType) {
    case attachmentFileType.direct_upload:
      if (!signedID) {
        return null;
      }
      attachmentParams.original = signedID;
      break;
    case attachmentFileType.share_point:
      if (!externalMetaData || !url) {
        return null;
      }

      attachmentParams.external_meta_data = externalMetaData;
      attachmentParams.url = url;
      break;
    case attachmentFileType.google_drive:
    case attachmentFileType.figma:
    case attachmentFileType.url_upload:
      if (!url) {
        return null;
      }
      attachmentParams.url = url;
      break;

    default:
      window.console.error(
        `Unknown fileType (${fileType}) in #buildUploadPayload`,
      );
      return null;
  }

  const attachmentGroupParams: Params = {
    attachments_attributes: [attachmentParams],
    field_name: fieldName,
  };

  if (deleteExistingAttachments) {
    attachmentGroupParams.delete_existing_attachments = true;
  }

  return attachmentGroupParams;
}
