import "./risk-methodology-builder.scss";

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { Flex, Icon, Toggle, Typography } from "@/components/Elements";

import DynamicSizeInput from "../../../../../components/shared/DynamicSizeInput";
import {
  ResidualRiskMatrix,
  ResidualRisk as ResidualRiskProps,
  RiskRating as RiskRatingProps,
} from "../../../types/risk-methodology";
import { adjustRiskRatings } from "./helpers";
import ResidualRisks from "./ResidualRisks";
import RiskRating from "./RiskRating";
import SectionRange from "./SectionRange";

type RiskMethodologyBuilderProps = {
  name: string;
  onNameChange: (name: string) => void;
  overallRiskRatings: Array<RiskRatingProps>;
  updateOverallRiskRatings: (riskRatings: Array<RiskRatingProps>) => void;
  inherentRiskRatings: Array<RiskRatingProps>;
  updateInherentRiskRatings: (riskRatings: Array<RiskRatingProps>) => void;
  controlRiskRatings: Array<RiskRatingProps>;
  updateControlRiskRatings: (riskRatings: Array<RiskRatingProps>) => void;
  residualRisks: Array<ResidualRiskProps>;
  updateResidualRisks: (residualRisks: Array<ResidualRiskProps>) => void;
  residualRiskMatrices: Array<ResidualRiskMatrix>;
  updateResidualRiskMatrices: (
    residualRiskMatrices: Array<ResidualRiskMatrix>,
  ) => void;
  highRange: number;
  nameError: string;
  lowRange: number;
  setHighRange: (highRange: number) => void;
  setSaveable: (canSave: boolean) => void;
  isScoringEnabled: boolean;
  onScoringEnabledChange: (isScoringEnabled: boolean) => void;
  isWeightingEnabled: boolean;
  onChangeWeightingEnabled: (isWeightingEnabled: boolean) => void;
};

function RiskMethodologyBuilder(props: RiskMethodologyBuilderProps) {
  const [overallsSaveable, setOverallsSavealbe] = useState(true);
  const [controlsSaveable, setControlsSaveable] = useState(true);
  const [inherentsSaveable, setInherentsSaveable] = useState(true);
  const [residualRisksSaveable, setResidualRisksSaveable] = useState(true);

  const adjustRatings = (ratings: Array<RiskRatingProps>, type: string) => {
    const newRatings = adjustRiskRatings(
      ratings,
      props.lowRange,
      props.highRange,
    );
    if (type === "inherent") {
      props.updateInherentRiskRatings(newRatings);
    } else if (type === "control") {
      props.updateControlRiskRatings(newRatings);
    } else if (type === "overall") {
      props.updateOverallRiskRatings(newRatings);
    }
  };

  useEffect(() => {
    props.setSaveable(
      overallsSaveable &&
        controlsSaveable &&
        inherentsSaveable &&
        residualRisksSaveable,
    );
  }, [
    overallsSaveable,
    residualRisksSaveable,
    controlsSaveable,
    inherentsSaveable,
  ]);

  useEffect(() => {
    adjustRatings(props.overallRiskRatings, "overall");
    adjustRatings(props.inherentRiskRatings, "inherent");
    adjustRatings(props.controlRiskRatings, "control");
  }, [props.highRange]);

  return (
    <div
      className="risk-methodology-builder"
      data-testid="risk-methodology-builder"
    >
      <Flex justifyCenter>
        <DynamicSizeInput
          value={props.name}
          onChange={props.onNameChange}
          placeholder="Enter Scoring Template Name"
          defaultWidth={400}
          dataTestID="risk-methodology-builder-input"
          className={props.nameError ? "ra-risk-rating-error" : ""}
        />
      </Flex>
      <SectionRange
        dataTestID="section-range"
        lowRange={props.lowRange}
        highRange={props.highRange}
        setHighRange={props.setHighRange}
      />
      <Flex
        className="risk-methodology-builder__scoring-toggle"
        justifySpaceBetween
      >
        <Flex columnGap={5} alignCenter>
          <Typography
            label="Enable Question Weighting"
            color="generalMidnightDark"
            size="lg"
            weight="semiBold"
          />
          <Flex
            alignCenter
            data-tooltip-id="tooltip"
            data-tooltip-content="If enabled, questions associated with this risk methodology will have an adjustable weighting that affects the questions impact on assessment scoring."
            data-tooltip-place="bottom"
          >
            <Icon name="help" color="generalDarkGray" />
          </Flex>
        </Flex>
        <Toggle
          onLabel="Yes"
          offLabel="No"
          onChange={props.onChangeWeightingEnabled}
          active={props.isWeightingEnabled}
        />
      </Flex>
      <Flex
        className="risk-methodology-builder__scoring-toggle"
        justifySpaceBetween
      >
        <Typography
          label="Enable Inherent/Control Scoring"
          color="generalMidnightDark"
          size="lg"
          weight="semiBold"
        />
        <Toggle
          onLabel="Yes"
          offLabel="No"
          onChange={props.onScoringEnabledChange}
          active={props.isScoringEnabled}
        />
      </Flex>
      <RiskRating
        description={`Define your Overall Scale below, Range is set within values of ${props.lowRange}-${props.highRange}`}
        groupTitle="Overall Risk Rating"
        dataTestID="overall-risk-ratings"
        addNewText="Add New Overall"
        riskRatings={props.overallRiskRatings}
        updateRiskRatings={(ratings) => {
          adjustRatings(ratings, "overall");
        }}
        globalHigherRange={props.highRange}
        setSaveable={setOverallsSavealbe}
      />
      {props.isScoringEnabled && (
        <>
          <RiskRating
            description={`Define your Inherent Risk Scale below, Range is set within values of ${props.lowRange}-${props.highRange}`}
            groupTitle="Inherent Risk Rating"
            dataTestID="inherent-risk-ratings"
            addNewText="Add New Inherent"
            riskRatings={props.inherentRiskRatings}
            updateRiskRatings={(ratings) => {
              adjustRatings(ratings, "inherent");
            }}
            globalHigherRange={props.highRange}
            setSaveable={setInherentsSaveable}
          />
          <RiskRating
            description={`Define your Control Risk Scale below, Range is set within values of ${props.lowRange}-${props.highRange}`}
            groupTitle="Control Risk Rating"
            dataTestID="control-risk-ratings"
            addNewText="Add New Control"
            riskRatings={props.controlRiskRatings}
            updateRiskRatings={(ratings) => {
              adjustRatings(ratings, "control");
            }}
            globalHigherRange={props.highRange}
            setSaveable={setControlsSaveable}
          />
          <ResidualRisks
            groupTitle="Residual Risks"
            addNewText="Add New Residual Risk"
            residualRisks={props.residualRisks}
            updateResidualRisks={(r) => props.updateResidualRisks(r)}
            controlRiskRatings={props.controlRiskRatings}
            inherentRiskRatings={props.inherentRiskRatings}
            residualRiskMatrices={props.residualRiskMatrices}
            updateResidualRiskMatrices={props.updateResidualRiskMatrices}
            setSaveable={setResidualRisksSaveable}
          />
        </>
      )}
    </div>
  );
}

export default observer(RiskMethodologyBuilder);
