import React from "react";
import { Bar, BarChart, Text, XAxis, YAxis } from "recharts";

export type StackedHorizontalBarChartProps = {
  data: { name: string | number; [key: string]: string | number }[];
  colors?: string[];
  keys?: string[];
};
export const StackedHorizontalBarChart = ({
  data,
  colors = ["#BCBCCF", "#313181", "#EB2E4E"],
  keys = ["value1", "value2", "value3"],
}: StackedHorizontalBarChartProps) => {
  const stackedBars = keys.map((key: string, index: number) =>
    index === 0 ? (
      <Bar
        key={`bar-${key}`}
        dataKey={key}
        stackId="a"
        fill={colors[index]}
        barSize={12}
      />
    ) : (
      <Bar
        key={`bar-${key}`}
        dataKey={key}
        stackId="a"
        fill={colors[index]}
        barSize={12}
      />
    ),
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomAxisTick = (props: any) => {
    const { x, y, payload } = props;
    const total = data.find((d) => d.name === payload.value)?.total;
    return (
      <g>
        <Text
          x={x - 135}
          y={y}
          verticalAnchor="middle"
          fill="#353549"
          fontFamily="Poppins"
          fontSize={12}
          fontWeight={500}
        >
          {payload.value}
        </Text>
        <Text
          x={x - 4}
          y={y}
          textAnchor="end"
          verticalAnchor="middle"
          fill="#353549"
          fontFamily="Poppins"
          fontSize={12}
          fontWeight={600}
        >
          {total}
        </Text>
      </g>
    );
  };
  return (
    <BarChart
      layout="vertical"
      width={400}
      height={216}
      data={data}
      margin={{
        left: 85,
      }}
    >
      <XAxis type="number" tickCount={0} />
      <YAxis
        dataKey="name"
        type="category"
        interval={0}
        tickLine={false}
        tick={CustomAxisTick}
        axisLine={{
          stroke: "#E4E4EB",
          strokeWidth: 1,
        }}
      />
      {stackedBars.reverse()}
    </BarChart>
  );
};
