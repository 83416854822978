import axios from "axios";
import { action, makeObservable, observable } from "mobx";

import { RecordVersion } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import { MainStore } from "../Main";
import { IndexParams, ModuleStore } from "../types/module-store-types";

export default class ChangeManagementsStore implements ModuleStore {
  mainStore: MainStore;

  // Observable objects
  data = {};
  currentSectionTags = {};
  defaultQuestions = [];
  checklists = [];

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      currentSectionTags: observable,
      defaultQuestions: observable,
      checklists: observable,

      setData: action,
      setCurrentSectionTags: action,
      setDefaultQuestions: action,
      setChecklists: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/workspaces/:workspace_id/change_management
  async index({ workspaceID, sortParams, tableFilters = [] }: IndexParams) {
    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        table_filters: tableFilters,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
      };

      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/workspaces/${workspaceID}/change_management`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return;
      }

      this.setData(response.data);

      this.mainStore.fields.setList(response.data.fields);
    } catch (error) {
      window.console.log(`"CM#Index for Company" error ${error}`);
    }
  }

  // POST /api/react/workspaces/:workspace_id/change_management
  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async create(workspaceID, sectionTagID) {
    const data = { section_tag_id: sectionTagID };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/workspaces/${workspaceID}/change_management`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"CM#create" error ${error}`);
    }
  }

  // DELETE /api/react/change_management/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/change_management/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(axios);
      } else {
        this.mainStore.toast.setInfoText(
          "Change Management Record Has Been Deleted",
        );
      }
    } catch (error) {
      window.console.log(`"CM#delete" error ${error}`);
    }
  }

  // GET /api/react/module_workspaces/:module_workspace_id/change_management/checklists/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async fetchChecklist(moduleWorkspaceID, checklistID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/change_management/checklists/${checklistID}`,
        headers: this.mainStore.getHeaders(),
      });

      return response.data.checklist;
    } catch (error) {
      window.console.log(`"CM#fetchChecklist" error ${error}`);
    }
  }

  // PUT /api/react/module_workspaces/:module_workspace_id/change_management/checklist
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async updateChecklist(moduleWorkspaceID, checklistID, params) {
    const data = {
      id: checklistID,
      checklist: params,
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/change_management/checklist`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      return response.data.checklist;
    } catch (error) {
      window.console.log(`"CM#updateChecklist" error ${error}`);
    }
  }

  // GET /api/react/module_workspaces/:module_workspace_id/change_management/available_checklists
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async fetchAvailableChecklists(moduleWorkspaceID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/change_management/available_checklists`,
        headers: this.mainStore.getHeaders(),
      });

      this.setChecklists(response.data.checklists);
    } catch (error) {
      window.console.log(`"CM#fetchAvailableChecklists" error ${error}`);
    }
  }

  // POST /api/react/module_workspaces/:module_workspace_id/change_management/checklist
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async createChecklist(moduleWorkspaceID, params) {
    const data = { checklist: params };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/change_management/checklist`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      return response.data.new_product_checklist;
    } catch (error) {
      window.console.log(`"CM#createChecklist" error ${error}`);
    }
  }

  // POST /api/react/module_workspaces/:module_workspace_id/change_management/assign_checklist
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async assignChecklist(moduleWorkspaceID, checklistID, recordID) {
    const data = { checklist_id: checklistID, record_id: recordID };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/change_management/assign_checklist`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      return response.data.new_product_checklist;
    } catch (error) {
      window.console.log(`"CM#assignChecklist" error ${error}`);
    }
  }

  // DELETE /api/react/module_workspaces/:module_workspace_id/change_management/checklist
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async deleteChecklist(moduleWorkspaceID, checklistID) {
    const data = { id: checklistID };

    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/change_management/checklist`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"CM#deleteChecklist" error ${error}`);
    }
  }

  // GET /api/react/module_workspaces/:module_workspace_id/change_management/questions
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async fetchQuestionsWithCategory(moduleWorkspaceID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/change_management/questions`,
        headers: this.mainStore.getHeaders(),
      });

      this.setDefaultQuestions(response.data.new_product_questions);
    } catch (error) {
      window.console.log(`"CM#fetchQuestionsWithCategory" error ${error}`);
    }
  }

  // POST /api/react/module_workspaces/:module_workspace_id/change_management/questions
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async createQuestion(moduleWorkspaceID, params) {
    const data = { new_product_question: params };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/change_management/questions`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setDefaultQuestions([
        ...this.defaultQuestions,
        response.data.new_product_question,
      ]);
    } catch (error) {
      window.console.log(`"CM#createQuestion" error ${error}`);
    }
  }

  // PUT /api/react/module_workspaces/:module_workspace_id/change_management/checklist_question
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async updateChecklistQuestion(moduleWorkspaceID, id, params) {
    const data = {
      id,
      checklist_question: params,
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/change_management/checklist_question`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      if (axios.isAxiosError(response)) {
        this.mainStore.toast.setErrorFromResponse(response);
      }

      return response.data.checklist_question;
    } catch (error) {
      window.console.log(`"CM#updateChecklistQuestion" error ${error}`);
    }
  }

  // POST /api/react/change_management/:id/finalize
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async finalize(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/change_management/${recordVersionID}/finalize`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
        return;
      }
    } catch (error) {
      window.console.log(`"CM#finalize" error ${error}`);
    }
  }

  // POST /api/react/change_management/:id/unlock
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unlock(recordVersionID) {
    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/change_management/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });
      return data;
    } catch (error) {
      window.console.log(`"CM#unlock" error ${error}`);
    }
  }

  async updateSection(recordVersionID: RecordVersion, sectionTagID: number) {
    const data = {
      section_tag_id: sectionTagID,
      identifier: "new_product_approval",
    };

    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/change_management/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"CM#update-section" error ${error}`);
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value) {
    this.data = value;
    // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
    this.mainStore.recordVersions.setList(this.data.record_versions);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrentSectionTags(value) {
    this.currentSectionTags = value || {};
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setDefaultQuestions(value) {
    if (value) {
      this.defaultQuestions = value;
    } else {
      this.defaultQuestions = [];
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setChecklists(value) {
    if (value) {
      this.checklists = value;
    } else {
      this.checklists = [];
    }
  }

  // Store Helpers

  cleanup() {
    this.setData({});
    this.setCurrentSectionTags({});
    this.setDefaultQuestions([]);
    this.setChecklists([]);
  }

  abort() {
    this.controller?.abort();
  }
}
