import classNames from "classnames";
import React, { useRef } from "react";

import { useSize } from "../../../../hooks/useSize";

const BREAKPOINT_2_COLUMNS = 730;
const BREAKPOINT_1_COLUMN = 490;

// @ts-expect-error TS(7006) FIXME: Parameter 'width' implicitly has an 'any' type.
const columnClassFromWidth = (width) => {
  if (width < BREAKPOINT_1_COLUMN) {
    return "tile-set--single";
  } else if (width < BREAKPOINT_2_COLUMNS) {
    return "tile-set--double";
  }
  return "tile-set--triple";
};

type TileSetProps = {
  children?: React.ReactNode;
};

const TileSet = ({ children }: TileSetProps) => {
  const ref = useRef(null);
  const [width] = useSize(ref);

  const classes = classNames("tile-set", columnClassFromWidth(width));

  return (
    <div ref={ref} className={classes}>
      {children}
    </div>
  );
};

export default TileSet;
