import classNames from "classnames";
import React from "react";

import { formatTimeAfterDate } from "../../../helpers/DateFormatters";

type Props = {
  width: number | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  locked: boolean;
};

function DateTimeCell({ value, locked, width }: Props) {
  const dateAndTimeString = formatTimeAfterDate(value) || null;

  const cellClasses = classNames("set-date-button", {
    "disabled pointer-events-none": !locked,
    "locked-cell": locked,
  });

  const dateClasses = classNames("date-placeholder", {
    "placeholder-locked": locked,
  });

  const dateCell = (
    <li className={cellClasses} style={{ width }}>
      <div className="cell-content">
        <span className={dateClasses}>{!value && "N/A"}</span>
        {dateAndTimeString}
      </div>
    </li>
  );

  return dateCell;
}

DateTimeCell.defaultProps = {
  width: "100%",
};

export default DateTimeCell;
