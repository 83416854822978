import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import { attachmentFileType } from "@/api";
import { getLatestAttachment } from "@/components/helpers/AttachmentGroupsHelper";
import { useMainStore } from "@/contexts/Store";
import googleDriveIcon from "@/images/table-image/icon/logo_drive.webp";
import { IntegrationAttachmentTypes } from "@/stores/types/attachment-types";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { Icon } from "../../../Elements";
import { getModuleUrlFromLocation } from "../../../helpers/getModuleUrlFromLocation";
import ConfirmationDialog from "../ConfirmationDialog";
import UploadDate from "../UploadDate";
import ViewFileUploader from "./ViewFileUploader";

type Props = {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  description?: string;
  descriptionIcon?: boolean;
  hasErrorClass?: string;
  isMultiple?: boolean;
  isRecordLocked?: boolean;
  moduleIdentifier?: ModuleIdentifier;
};

function ViewDocuments({
  recordVersion,
  fieldName,
  hasErrorClass,
  isMultiple,
  description,
  descriptionIcon,
  isRecordLocked,
  moduleIdentifier,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const location = useLocation();

  // State
  const [showPopup, setShowPopup] = useState(-1);
  const [viewPopup, setViewPopup] = useState("menu");

  useEffect(() => {
    if (!showPopup) {
      setViewPopup("menu");
    }
  }, [showPopup]);

  const { isCurrentWorkspaceArchived } = mainStore.workspaces;

  const attachmentGroups =
    recordVersion?.attachment_groups?.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'attachmentGroup' implicitly has an 'any... Remove this comment to see the full error message
      (attachmentGroup) => attachmentGroup.field_name === fieldName,
    ) || [];

  // @ts-expect-error TS(7006) FIXME: Parameter 'attachmentGroup' implicitly has an 'any... Remove this comment to see the full error message
  const urls = attachmentGroups?.map((attachmentGroup) => attachmentGroup.url);
  const meta = null;

  const status =
    mainStore.avroSchemas.firstValueForField("status", recordVersion?.data) ||
    mainStore.avroSchemas.firstValueForField(
      "customer_support_status",
      recordVersion?.data,
    );
  const closedOptions = ["closed", "complete", "submitted"];
  const isClosed = isRecordLocked || closedOptions.includes(status);
  const isDisabled =
    isCurrentWorkspaceArchived ||
    recordVersion?.table_name === "Finalized" ||
    Boolean(isClosed);

  const cellError = mainStore.recordVersions.errorsForField(
    recordVersion?.id,
    fieldName,
  );
  const hasErrors = Boolean(cellError);
  const modulePath = getModuleUrlFromLocation(location.pathname);

  // @ts-expect-error TS(7006) FIXME: Parameter 'attachmentID' implicitly has an 'any' t... Remove this comment to see the full error message
  const onDownload = (attachmentID, file_type = "", id = -1) => {
    if (file_type === "AttachedDocument") {
      mainStore.attachedDocuments.fetchFileUrl(id);
      return;
    }

    mainStore.files.fetchFile({
      recordVersionID: recordVersion?.id,
      fieldName,
      attachmentID,
      downloadMode: true,
    });
  };

  const resync = async (
    fileType: IntegrationAttachmentTypes,
    attachmentID: number,
  ) => {
    if (fieldName) {
      setViewPopup("file-changes");
      await mainStore.files.resync(
        recordVersion?.id,
        fieldName,
        fileType,
        attachmentID,
      );
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'attachmentID' implicitly has an 'any' t... Remove this comment to see the full error message
  const onDelete = (attachmentID) => {
    if (recordVersion?.id) {
      mainStore.files.deleteSingleFile(
        recordVersion?.id,
        fieldName,
        attachmentID,
      );
    }

    setShowPopup(-1);
  };

  if (attachmentGroups.length === 0) {
    return (
      <div
        className={classNames("table-row", "empty", {
          // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
          [hasErrorClass]: hasErrors,
        })}
        data-testid="no-documents"
      >
        {descriptionIcon && (
          <Icon
            name="starSolid"
            className="star-icon"
            color="generalDark"
            size="de"
          />
        )}
        {description ? description : ViewDocuments.defaultProps.description}
      </div>
    );
  }

  return (
    <>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'attachmentGroup' implicitly has an 'any... Remove this comment to see the full error message */}
      {attachmentGroups.map((attachmentGroup, index) => {
        const latestAttachment = getLatestAttachment(attachmentGroup);

        return (
          <div key={attachmentGroup.id} className="table-row document">
            {attachmentGroup.file_type !== "AttachedDocument" ? (
              <NavLink
                to={`/workspaces/${workspaceID}/modules/${modulePath}/attachment_view/${recordVersion.id}/${attachmentGroup.id}`}
                className="link-creative"
              >
                {latestAttachment?.file_type === "figma" && (
                  <Icon name="figma" spaceRight />
                )}
                {latestAttachment?.file_type === "share_point" && (
                  <Icon name="sharePoint" spaceRight />
                )}
                {/* @ts-expect-error TS(2531) FIXME: Object is possibly 'null'. */}
                {latestAttachment.original.filename}
              </NavLink>
            ) : (
              // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
              latestAttachment.original.filename
            )}
            <div className="right-content">
              {/* @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
              <UploadDate date={latestAttachment?.upload_date} />
              <div className="table-row-actions">
                {attachmentGroup.file_type === "google_drive" && (
                  <Popup
                    trigger={() => (
                      <a
                        className="google-drive-link"
                        // @ts-expect-error TS(2339) FIXME: Property 'url' does not exist on type 'never'.
                        href={urls[0] || meta?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div>
                          <img src={googleDriveIcon} alt="view" />
                        </div>
                      </a>
                    )}
                    on="hover"
                  >
                    <div className="table-dropdown policy-document-tooltip">
                      View Google Doc
                    </div>
                  </Popup>
                )}

                {attachmentGroup.file_type !== "AttachedDocument" && (
                  <Popup
                    trigger={() => (
                      <div data-testid="view-attachment">
                        <NavLink
                          to={`/workspaces/${workspaceID}/modules/${modulePath}/attachment_view/${recordVersion.id}/${attachmentGroup.id}`}
                          className="link-creative"
                          data-testid="link"
                        >
                          <div>
                            <Icon name="eye" color="generalDark" size="de" />
                          </div>
                        </NavLink>
                      </div>
                    )}
                    on="hover"
                    position="top center"
                  >
                    <div className="table-dropdown policy-document-tooltip">
                      View Attachment
                    </div>
                  </Popup>
                )}

                {!isDisabled &&
                  attachmentGroup.file_type !== "AttachedDocument" && (
                    <Popup
                      trigger={() => (
                        <div>
                          <ViewFileUploader
                            recordVersion={recordVersion}
                            fieldName={fieldName}
                            // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                            replacementId={latestAttachment.id}
                            icon={
                              <Icon name="sync" color="generalDark" size="de" />
                            }
                            isMultiple={isMultiple}
                            moduleIdentifier={moduleIdentifier}
                          />
                        </div>
                      )}
                      on="hover"
                      position="top center"
                    >
                      <div className="table-dropdown policy-document-tooltip">
                        Replace Attachment
                      </div>
                    </Popup>
                  )}

                <Popup
                  trigger={() => (
                    <div
                      data-testid="download-attachment"
                      onClick={() =>
                        onDownload(
                          latestAttachment?.id,
                          attachmentGroup.file_type,
                          attachmentGroup.id,
                        )
                      }
                    >
                      <Icon name="download" color="generalDark" size="de" />
                    </div>
                  )}
                  on="hover"
                  position="top center"
                >
                  <div className="table-dropdown policy-document-tooltip">
                    Download Attachment
                  </div>
                </Popup>

                {!isDisabled &&
                  attachmentGroup.file_type !== "AttachedDocument" && (
                    <Popup
                      trigger={() => (
                        <div
                          className="more"
                          data-testid="attachment-more-actions"
                        >
                          <Icon
                            name="moreHorizontal"
                            color="generalDark"
                            size="de"
                          />
                        </div>
                      )}
                      open={showPopup === index}
                      onOpen={() => setShowPopup(index)}
                      onClose={() => setShowPopup(-1)}
                      keepTooltipInside
                    >
                      {viewPopup === "menu" && (
                        <ul className="table-dropdown policy-document-menu">
                          <li
                            data-testid="delete-attachment"
                            onClick={() => setViewPopup("delete")}
                          >
                            <Icon
                              name="trash"
                              className="delete-icon"
                              color="generalDark"
                              size="de"
                            />
                            Delete
                          </li>
                          {attachmentGroup.file_type === "google_drive" && (
                            <li
                              onClick={() =>
                                resync(
                                  attachmentFileType.google_drive,
                                  Number(
                                    getLatestAttachment(attachmentGroup)?.id,
                                  ),
                                )
                              }
                              data-testid="google-resync-button"
                            >
                              <Icon
                                className="delete-icon"
                                name="sync"
                                color="generalDark"
                                size="de"
                              />
                              <span>Resync from Google Drive</span>
                            </li>
                          )}
                        </ul>
                      )}
                      {viewPopup === "delete" && (
                        <ConfirmationDialog
                          containerClass="table-dropdown"
                          heading="Delete Attachment"
                          content="Are you sure you want to delete this attachment?"
                          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                          handleConfirm={() => onDelete(latestAttachment.id)}
                          // @ts-expect-error TS(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
                          handleReject={() => setShowPopup(false)}
                        />
                      )}
                    </Popup>
                  )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

ViewDocuments.defaultProps = {
  hasErrorClass: "has-errors",
  isMultiple: false,
  description:
    "No Documents added yet. Click “Upload Document” to add one here.",
  descriptionIcon: false,
};

export default observer(ViewDocuments);
