import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  CreateAccountMutationRequest,
  ListAccountsQueryParams,
  ShowAccountQueryParams,
} from "@/api";

import {
  createAccount,
  deleteAccount,
  listAccounts,
  listAccountsWithLimitedResponse,
  showAccount,
  updateAccount,
} from "../gen/axios/accountsController";
import { ModifyAccountRequest } from "../gen/models/ModifyAccountRequest";

export const ACCOUNTS_QUERY_KEY = {
  all: ["accounts"] as const,
  list: ({
    workspaceId,
    queryParams,
  }: {
    workspaceId: number;
    queryParams?: ListAccountsQueryParams | null;
  }) => [...ACCOUNTS_QUERY_KEY.all, "list", workspaceId, queryParams] as const,
  listLimited: (moduleWorkspaceId: number) =>
    [...ACCOUNTS_QUERY_KEY.all, "listLimited", moduleWorkspaceId] as const,
  details: () => [...ACCOUNTS_QUERY_KEY.all, "details"] as const,
  detail: (accountId: number, queryParams: ShowAccountQueryParams = {}) =>
    [...ACCOUNTS_QUERY_KEY.details(), accountId, queryParams] as const,
};

export function useAccounts(
  workspaceId: number,
  queryParams?: ListAccountsQueryParams | null,
) {
  return useQuery({
    queryKey: ACCOUNTS_QUERY_KEY.list({
      workspaceId,
      queryParams,
    }),
    queryFn: () => listAccounts(workspaceId, queryParams || undefined),
    enabled: !!workspaceId && queryParams !== null,
  });
}

export function useAccountsList(moduleWorkspaceId: number) {
  return useQuery({
    queryKey: ACCOUNTS_QUERY_KEY.listLimited(moduleWorkspaceId),
    queryFn: () => listAccountsWithLimitedResponse(moduleWorkspaceId),
    enabled: !!moduleWorkspaceId,
  });
}

export function useAccount({
  workspaceId,
  accountId,
  queryParams = {},
}: {
  workspaceId: number;
  accountId: number;
  queryParams?: ShowAccountQueryParams;
}) {
  return useQuery({
    queryKey: ACCOUNTS_QUERY_KEY.detail(accountId, queryParams),
    queryFn: () => showAccount(workspaceId, accountId, queryParams),
    enabled: !!workspaceId && !!accountId,
  });
}

export function useCreateAccount({
  workspaceId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (account: CreateAccountMutationRequest) =>
      createAccount(workspaceId, account),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ACCOUNTS_QUERY_KEY.all,
      });
      onSuccess();
    },
    onError,
  });
}

export function useUpdateAccount({
  workspaceId,
  accountId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  accountId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (account: ModifyAccountRequest) =>
      updateAccount(workspaceId, accountId, { account }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ACCOUNTS_QUERY_KEY.detail(accountId),
      });
      onSuccess();
    },
    onError,
  });
}

export function useDeleteAccount({
  workspaceId,
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => deleteAccount(workspaceId, id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ACCOUNTS_QUERY_KEY.all,
      });
      onSuccess();
    },
    onError,
  });
}
