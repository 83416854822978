import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { useMainStore } from "../../../contexts/Store";
import attachmentBlackIcon from "../../../images/table-image/icon/attachment-black.svg";
import calendarBlackIcon from "../../../images/table-image/icon/calendar-black-icon.svg";
import closeIcon from "../../../images/table-image/icon/close-black-icon.svg";
import contactBlackIcon from "../../../images/table-image/icon/contact-black.svg";
import listBulletBlackIcon from "../../../images/table-image/icon/list-bullet-black.svg";
import textBlackIcon from "../../../images/table-image/icon/text-black-icon.svg";

const MAPPING_FIELDS_WITH_ICONS = [
  { label: "Description", icon: textBlackIcon },
  { label: "Source", icon: listBulletBlackIcon },
  { label: "Priority", icon: listBulletBlackIcon },
  { label: "Date Identified", icon: calendarBlackIcon },
  { label: "Owner", icon: contactBlackIcon },
  { label: "Action Plan", icon: textBlackIcon },
  { label: "Supporting Evidence", icon: attachmentBlackIcon },
];

type Props = {
  parentRecordVersionID: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emailSlideClose?: (...args: any[]) => any;
};

function FindingsSendToIssuesBulk({
  parentRecordVersionID,
  emailSlideClose,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [isSubmitting, setIsSubmitting] = useState(false);

  // vars
  const { workspaceID } = mainStore.context;
  const selectedRecordVersionIDs =
    mainStore.dynamicTable.selectedRecordVersionIDs.slice();
  const selectedRecordVersions = mainStore.recordVersions.list.filter(
    (recordVersion) =>
      selectedRecordVersionIDs.includes(recordVersion.id) &&
      mainStore.avroSchemas.firstValueForField(
        "status",
        recordVersion?.data,
      ) !== "sent",
  );
  const isSubmitDisabled = selectedRecordVersions.length === 0 || isSubmitting;

  // funcs
  async function onSend() {
    setIsSubmitting(true);
    await mainStore.qa.sendFindingsToIM(
      parentRecordVersionID,
      selectedRecordVersions.map((item) => item.id),
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
      workspaceID,
    );
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    emailSlideClose();
  }

  // renders
  const renderHeader = (
    <div className="email-dropdown-head">
      <h3>Send Issues</h3>
      <div onClick={emailSlideClose}>
        <img src={closeIcon} alt="close-icon" />{" "}
      </div>
    </div>
  );

  const renderSelectedRecordVersions = selectedRecordVersions.map(
    (recordVersion) => (
      <div
        key={recordVersion.id}
        className="table-email-element with-global-id"
      >
        <span className="global-id">{recordVersion.global_id}</span>
        <span>{recordVersion.meta.name}</span>
      </div>
    ),
  );

  const renderPlaceholder = (
    <>
      <hr />
      <div className="initial-text-paragraph">
        <span>Select Issues you want to send:</span>
        <span className="subheading">Check the enabled records’ checkbox</span>
      </div>
      <hr />
    </>
  );

  const renderContent = (
    <div className="table-email-select">
      <div className="table-email-block">{renderSelectedRecordVersions}</div>
    </div>
  );

  const renderFieldsHelper = (
    <div className="sidebar-field-helper">
      <span>
        Here are all the columns that would sent to Issue Management Module:
      </span>
      <div className="fields-list">
        {MAPPING_FIELDS_WITH_ICONS.map((item) => (
          <div key={item.label} className="fields-list-item">
            <img src={item.icon} alt="column-icon" />
            <p>{item.label}</p>
          </div>
        ))}
      </div>
      <hr />
    </div>
  );

  const renderSubmitBtn = (
    <button
      className={classNames("sidebar-submit", { active: !isSubmitDisabled })}
      disabled={isSubmitDisabled}
      onClick={onSend}
    >
      Send
    </button>
  );

  return (
    <div className="table-dropdown table-email-dropdown">
      {renderHeader}
      {selectedRecordVersions.length === 0 && renderPlaceholder}
      {selectedRecordVersions.length > 0 && renderContent}
      {renderFieldsHelper}
      <div className="email-dropdown-wrap">{renderSubmitBtn}</div>
      <hr />
    </div>
  );
}

export default observer(FindingsSendToIssuesBulk);
