import "./SharePointFilePicker.scss";

import { PublicClientApplication } from "@azure/msal-browser";
import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import Popup from "reactjs-popup";

import { attachmentFileType } from "@/api";
import { IntegrationsAPI } from "@/api/legacy/integrations";
import { buildUploadPayload } from "@/components/helpers/AttachmentGroupsHelper";
import {
  establishMessaging,
  filePickerOptions,
  generateRandomHex,
  getToken,
  msalConfig,
} from "@/components/table/shared/cell-type/file-select/SharePointFilePicker/helpers";
import { SPItem } from "@/components/table/shared/cell-type/file-select/SharePointFilePicker/types";
import { useMainStore } from "@/contexts/Store";
import { useLoadableData } from "@/hooks/useLoadableData";

function SharePointFilePicker({
  recordVersionID,
  fieldName,
  onPickerClose,
  onPickerUploading,
}: {
  recordVersionID: number;
  fieldName: string;
  onPickerUploading: () => void;
  onPickerClose: () => void;
}) {
  const mainStore = useMainStore();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { data: sharePointTenantDetails } = useLoadableData({
    request: () => IntegrationsAPI.sharePointTenantDetails(),
    onError: () =>
      "There was an issue loading the share point integration. Please refresh to try again.",
  });

  async function handlePickFile(item: SPItem) {
    const payload = buildUploadPayload(
      fieldName,
      attachmentFileType.share_point,
      undefined,
      item.webUrl,
      undefined,
      { file_id: item.id, driver_id: item.parentReference.driveId },
    );

    onPickerUploading();

    if (payload) {
      await mainStore.attachmentGroups.create({
        recordVersionID,
        payload,
      });
    }
  }

  async function loadFilePicker() {
    if (!iframeRef.current || !sharePointTenantDetails) {
      return;
    }

    const frame = iframeRef.current;
    const sharePointWindow = frame.contentWindow;

    if (!sharePointWindow) {
      window.console.error(`Failed to get sharepoint window`);

      return;
    }

    const channelID = generateRandomHex(8);

    const queryString = new URLSearchParams({
      filePicker: JSON.stringify(filePickerOptions(channelID)),
      locale: "en-us",
    });

    const { tenant_id: tenantID, tenant_name: tenantName } =
      sharePointTenantDetails;

    const baseURL = `https://${tenantName}-my.sharepoint.com`;
    const url = `${baseURL}/_layouts/15/FilePicker.aspx?${queryString}`;
    const form = sharePointWindow.document.createElement("form");

    if (!form) {
      window.console.error(`Failed to get form`);

      return;
    }

    form.setAttribute("action", url);
    form.setAttribute("method", "POST");

    const tokenInput = window.document.createElement("input");

    const msalInstance = new PublicClientApplication(msalConfig(tenantID));

    tokenInput.setAttribute("type", "hidden");
    tokenInput.setAttribute("name", "access_token");
    tokenInput.setAttribute("value", await getToken(baseURL, msalInstance));

    form.appendChild(tokenInput);

    sharePointWindow.document.body.appendChild(form);

    form.submit();

    establishMessaging({
      sharePointWindow,
      msalInstance,
      channelID,
      onPickFile: handlePickFile,
      onPickerClose,
    });
  }

  useEffect(() => {
    loadFilePicker();
  }, [iframeRef.current, sharePointTenantDetails]);

  return (
    <Popup
      modal
      className="share-point-file-picker-modal"
      closeOnDocumentClick={false}
      open
    >
      <iframe
        className="share-point-file-picker-modal__iframe"
        ref={iframeRef}
        title="browserFrame"
        id="sharepoint-iframe"
        width="100%"
        height="100%"
      />
    </Popup>
  );
}

export default observer(SharePointFilePicker);
