import classNames from "classnames";
import dayjs from "dayjs";
import React, { useState } from "react";
import Calendar from "react-calendar";
import Popup from "reactjs-popup";

import {
  formatDate,
  formatDateForCellValue,
} from "@/components/helpers/DateFormatters";
import arrowLeft from "@/images/table-image/icon/arrow-left.svg";
import arrowRight from "@/images/table-image/icon/arrow-right.svg";
import calendarIcon from "@/images/table-image/icon/calendar-black-icon.svg";

type Props = {
  disabled?: boolean;
  handleUpdateDate?: (formattedDate: string) => void;
  initialValue?: Date;
  title?: string;
  label?: string;
  open?: boolean;
  onlyMonthYearPicker?: boolean;
  placeholder?: string;
  maxDate?: Date;
  minDate?: Date;
  error?: boolean;
};

function RegularDateSelect({
  initialValue = dayjs().toDate(),
  title,
  label,
  handleUpdateDate,
  disabled,
  open,
  onlyMonthYearPicker = false,
  placeholder,
  maxDate,
  minDate,
  error,
}: Props) {
  const dateFormatOptions = onlyMonthYearPicker
    ? {
        month: "short",
        year: "numeric",
      }
    : undefined;

  // State
  const [date, setDate] = useState(initialValue || new Date());
  const [activeStartDate, setActiveStartDate] = useState<Date | null>(null);
  const [dateString, setDateString] = useState(() =>
    placeholder ? "" : formatDate(date, dateFormatOptions),
  );

  function onCalendarChange(newDate: Date) {
    // Set date
    setDate(newDate);
    setDateString(() => formatDate(newDate, dateFormatOptions));

    // Persist changes
    persistDate(newDate);

    // Close popup
    onClose();
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newDateString = event.target.value;

    // Set dateString without any conditions, since it defines the value in input
    setDateString(newDateString);

    // Try to parse a date
    if (!newDateString) {
      return;
    }

    const dateParts = newDateString.split("/");
    const month = Number(dateParts[0]);
    const day = Number(dateParts[1]);
    const year = Number(dateParts[2]);

    if (year >= 2000 && month >= 1 && month <= 12 && day >= 1 && day <= 31) {
      const newDate = new Date(year, month - 1, day);

      if (!isNaN(Number(newDate))) {
        setDate(newDate);
        setActiveStartDate(
          new Date(newDate.getFullYear(), newDate.getMonth(), 1),
        );
      }
    }
  }

  function onActiveStartDateChange(value: { activeStartDate: Date }) {
    setActiveStartDate(value.activeStartDate);
  }

  function setToday() {
    const today = new Date();
    setDate(today);
    setActiveStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
    setDateString(() => formatDate(today));
    onCalendarChange(today);
  }

  function onOpen() {
    setShowPopup(true);
  }

  function onClose() {
    setShowPopup(false);
  }

  function persistDate(newDate: Date) {
    if (!newDate) {
      return;
    }

    const formattedDate = formatDateForCellValue(newDate);
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    handleUpdateDate?.(formattedDate);
  }

  const modalTrigger = (
    <div
      data-testid="regular-date-select-trigger"
      className={classNames("regular-date-select-trigger", {
        active: initialValue,
        "filled-input": !disabled && initialValue,
      })}
    >
      {placeholder && !dateString ? (
        <span className="date-select-placeholder">{placeholder}</span>
      ) : (
        <span>{dateString || title}</span>
      )}
      <img src={calendarIcon} alt="calendar-icon" />
    </div>
  );

  const [showPopup, setShowPopup] = useState(open);

  return (
    <>
      {disabled && modalTrigger}
      {!disabled && (
        <div
          className={classNames("date-select-container", {
            "error-active": error,
          })}
        >
          {label && <h5>{label}</h5>}
          <Popup
            trigger={modalTrigger}
            position="bottom left"
            open={showPopup}
            onOpen={onOpen}
            onClose={onClose}
            keepTooltipInside
          >
            <div
              className="select-small-wrap calendar-wrap"
              data-testid="date-cell-popup"
            >
              <input
                className="input-date"
                // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                value={dateString}
                onChange={onInputChange}
                data-testid="regular-date-select-input"
                label="Testing"
              />
              <Calendar
                // @ts-expect-error TS(2322) FIXME: Type 'Date | null' is not assignable to type 'Date... Remove this comment to see the full error message
                activeStartDate={activeStartDate}
                onActiveStartDateChange={onActiveStartDateChange}
                formatShortWeekday={(locale, weekday) =>
                  ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][weekday.getDay()]
                }
                value={date}
                onChange={onCalendarChange}
                prevLabel={<img src={arrowLeft} alt="image" />}
                nextLabel={<img src={arrowRight} alt="image" />}
                maxDetail={onlyMonthYearPicker ? "year" : undefined}
                maxDate={maxDate}
                minDate={minDate}
              />
              <button
                className="table-button set-today"
                onClick={setToday}
                data-testid="set-today-button"
              >
                Set Today
              </button>
            </div>
          </Popup>
        </div>
      )}
    </>
  );
}

export default RegularDateSelect;
