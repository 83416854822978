import "./TimeRange.scss";

import dayjs from "dayjs";
import React from "react";

import { Icon, Typography } from "@/components/Elements";
import RegularDateSelect from "@/components/table/shared/RegularDateSelect";

type TimeRangeProps = {
  refreshMonths: (startDate?: Date, endDate?: Date) => void;
  startDate: Date | undefined;
  setStartDate: (date: Date) => void;
  endDate: Date | undefined;
  setEndDate: (date: Date) => void;
};

function TimeRange({
  refreshMonths,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: TimeRangeProps) {
  const handleUpdateStartDate = (date: Date) => {
    setStartDate(date);
    refreshMonths(date, endDate);
  };

  const handleUpdateEndDate = (date: Date) => {
    setEndDate(date);
    refreshMonths(startDate, date);
  };

  if (!startDate || !endDate) {
    return null;
  }

  return (
    <div className="time-range-container">
      <div className="grid-container">
        <div className="start-icon-and-label icon-and-label">
          <Icon
            name="play"
            size="de"
            color="extrasBlueGrayDarker"
            className="kri-start-icon"
          />
          <Typography
            label="Start Month"
            size="sm"
            weight="semiBold"
            color="extrasBlueGrayDarker"
            className="time-range-label-start"
          />
        </div>
        <div className="start-select">
          <RegularDateSelect
            handleUpdateDate={(v: string) =>
              handleUpdateStartDate(dayjs(v).toDate())
            }
            onlyMonthYearPicker
            initialValue={startDate}
            error={startDate > endDate}
          />
        </div>
        <label className="time-range-dash">-</label>
        <div className="end-icon-and-label icon-and-label">
          <Icon
            name="pause"
            size="de"
            color="extrasBlueGrayDarker"
            className="kri-end-icon"
          />
          <Typography
            label="End Month"
            size="sm"
            weight="semiBold"
            color="extrasBlueGrayDarker"
            className="time-range-label-end"
          />
        </div>
        <div className="end-select">
          <RegularDateSelect
            handleUpdateDate={(v: string) =>
              handleUpdateEndDate(dayjs(v).toDate())
            }
            onlyMonthYearPicker
            initialValue={endDate}
            error={startDate > endDate}
          />
        </div>

        {startDate > endDate && (
          <div className="error-message">
            <Typography
              label="Start date cannot be after end date"
              size="sm"
              color="generalError"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TimeRange;
