import { CommentTab } from "@/components/table/shared/comments/CommentsSlideMenu/types";
import { UserComment } from "@/stores/types/comment-types";

function isNotReplyComment(comment: UserComment) {
  return !comment.original_comment_id;
}

export function isNotResolvedComment(comment: UserComment) {
  return !comment.resolved;
}

export function getTopLevelComments(
  commentsTab: CommentTab | undefined,
  commentsData: { comments: UserComment[]; privateComments: UserComment[] },
) {
  switch (commentsTab) {
    case "internal":
      return commentsData.privateComments.filter(isNotReplyComment);
    case "collaborative":
    default:
      return commentsData.comments.filter(isNotReplyComment);
  }
}

export function getCommentModuleWorkspaceID(
  paramsModuleWorkspaceID: number | undefined,
  contextStoreModuleWorkspaceID: number | null,
) {
  const id = paramsModuleWorkspaceID || contextStoreModuleWorkspaceID;

  if (!id) {
    throw new Error("ModuleWorkspaceID is null");
  }

  return id;
}

export function isSelectedCommentPrivate(
  privateComments: UserComment[],
  selectedCommentID?: number,
) {
  if (!selectedCommentID) {
    return false;
  }

  return !!privateComments.find(
    ({ id, replies }) =>
      id === selectedCommentID ||
      replies.find(({ id: replyID }) => replyID === selectedCommentID),
  );
}
