import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import Spinner from "../../table/shared/Spinner";

type Props = {
  isExistingItem: boolean;
};

function RiskRegisterLibraryItemSaveButton({ isExistingItem }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [isSaving, setIsSaving] = useState(false);

  // Variables
  const { moduleWorkspaceID } = mainStore.context;
  const history = useHistory();
  const { libraryItem } = mainStore.riskRegisters;
  const isRiskTypeIdInvalid = !libraryItem?.risk_type?.id;
  const isMainCategoryIdInvalid = !libraryItem?.main_category?.id;
  const areRiskEventsInvalid = !libraryItem?.risk_events;
  const isDescriptionInvalid = !libraryItem?.description;

  const areFrameworksInvalid = !(
    // @ts-expect-error TS(2339) FIXME: Property 'frameworks' does not exist on type 'Libr... Remove this comment to see the full error message
    (libraryItem?.frameworks?.length > 0 || libraryItem?.tags?.id?.length > 0)
  );

  const disabled =
    isSaving ||
    isRiskTypeIdInvalid ||
    isMainCategoryIdInvalid ||
    areRiskEventsInvalid ||
    isDescriptionInvalid ||
    areFrameworksInvalid;

  // Functions
  async function handleClick() {
    setIsSaving(true);

    const data = {
      library_type: "user_generated",
      risk_type_id: libraryItem.risk_type?.id,
      main_category_id: libraryItem.main_category?.id,
      risk_events: libraryItem.risk_events,
      description: libraryItem.description,
      framework:
        // @ts-expect-error TS(2339) FIXME: Property 'frameworks' does not exist on type 'Libr... Remove this comment to see the full error message
        libraryItem?.tags?.id || libraryItem?.frameworks?.map((f) => f.id),
      reference: libraryItem.reference,
    };

    if (isExistingItem) {
      await mainStore.riskRegisters.updateLibraryItem(
        moduleWorkspaceID,
        libraryItem.id,
        data,
      );
    } else {
      await mainStore.riskRegisters.createLibraryItem(moduleWorkspaceID, data);
    }
    mainStore.toast.setInfoText("Done!");

    history.goBack();
  }

  return (
    <button
      className="table-button header-action"
      disabled={disabled}
      onClick={handleClick}
    >
      Save Library Item
      {isSaving && <Spinner />}
    </button>
  );
}

RiskRegisterLibraryItemSaveButton.defaultProps = {
  isExistingItem: false,
};

export default observer(RiskRegisterLibraryItemSaveButton);
