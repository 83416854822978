import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import { MainStore } from "../Main";

export default class ChecklistsStore {
  mainStore: MainStore;

  // Observable objects
  templateChecklists = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      templateChecklists: observable,

      setTemplateChecklists: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/checklists
  // @ts-expect-error TS(7006) FIXME: Parameter 'checklistID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async index(checklistID) {
    const params = {
      id: checklistID,
    };

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/checklists`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      return response.data.checklist;
    } catch (error) {
      window.console.log(`"Checklists#index" error ${error}`);
    }
  }

  // PATCH/PUT /api/react/checklists/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'checklistID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async update(checklistID, params) {
    const data = {
      id: checklistID,
      checklist: params,
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/checklists/${checklistID}`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      return response.data.checklist;
    } catch (error) {
      window.console.log(`"CM#update" error ${error}`);
    }
  }

  // GET /api/react/module_workspaces/:module_workspace_id/fetch_checklists
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async fetchChecklists(moduleWorkspaceID, fieldName) {
    const params = {
      field_name: fieldName,
    };

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/fetch_checklists`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.setTemplateChecklists(response.data.checklists);
    } catch (error) {
      window.console.log(`"checklists#fetch_checklists" error ${error}`);
    }
  }

  // GET /api/react/module_workspaces/:module_workspace_id/checklist
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async create(moduleWorkspaceID, params, fieldName) {
    const data = {
      checklist: params,
      field_name: fieldName,
    };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/checklist`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      return response.data.checklist;
    } catch (error) {
      window.console.log(`"CM#create" error ${error}`);
    }
  }

  // GET /api/react/checklists/:id/edit
  // @ts-expect-error TS(7006) FIXME: Parameter 'checklistID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async edit(checklistID, recordID) {
    const params = { checklist_id: checklistID, record_id: recordID };

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/checklists/${checklistID}/edit`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      return response.data.checklist;
    } catch (error) {
      window.console.log(`"CM#assignChecklist" error ${error}`);
    }
  }

  // DELETE /api/react/checklists/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'checklistID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async delete(checklistID) {
    const params = { id: checklistID };

    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/checklists/${checklistID}`,
        headers: this.mainStore.getHeaders(),
        params,
      });
    } catch (error) {
      window.console.log(`"CM#deleteChecklist" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setTemplateChecklists(value) {
    if (value) {
      this.templateChecklists = value;
    } else {
      this.templateChecklists = [];
    }
  }

  // Store Helpers
  cleanup() {
    this.setTemplateChecklists([]);
  }
}
