import classNames from "classnames";
import { isEqual } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import arrowLeft from "../../images/table-image/icon/arrow-left-blue.svg";
import { ActionButtonPopup } from "../table/menu-actions/components/ActionButtonPopup";
import Spinner from "../table/shared/Spinner";

function CompanyUsersHeader() {
  const mainStore = useMainStore();

  const [userID, setUserID] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showSavePopup, setShowSavePopup] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { selectedUser } = mainStore.users;
  const { isIW } = mainStore.context;

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const locationParts = location.pathname.split("/");
    const id = locationParts[locationParts.length - 1];

    if (id) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setUserID(id);
    }
  }, [location.pathname]);

  const handleGoBack = () => {
    const search = new URLSearchParams(location.search);
    const isInternalUserDetailView = search.get("in") === "1";

    if (isInternalUserDetailView && isIW) {
      history.push("/settings/users-internal");
    } else {
      history.push("/settings/users");
    }
  };

  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  const onClickTrigger = useCallback(() => {
    const user = mainStore.users?.users?.find(
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      (elem) => elem.id === selectedUser.id,
    );
    const hasNotChanged = isEqual(toJS(user), toJS(selectedUser));

    if (hasNotChanged) {
      handleGoBack();
      setShowSavePopup(false);
    } else {
      setShowSavePopup(true);
    }
  });

  const handleCancel = () => {
    setShowSavePopup(false);
    handleGoBack();
  };

  const saveUser = async () => {
    setIsSaving(true);

    if (!mainStore.users.selectedUser?.full_name?.trim().length) {
      mainStore.toast.setErrorText("Required fields must be filled in!");
      setShowPopup(false);
      setIsSaving(false);
      return;
    }

    if (userID) {
      const { company } = mainStore.companies;

      await mainStore.users.updateWithParams(company.id, userID, {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        full_name: selectedUser.full_name,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        department_id: selectedUser.department?.id,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        roles: selectedUser.roles,
      });

      mainStore.toast.setInfoText("User was successfully updated.");
    }

    setIsSaving(false);
    handleGoBack();
  };

  const saveButton = (
    <button
      onClick={saveUser}
      className={classNames("users-btn", { active: showPopup })}
    >
      Save User
    </button>
  );

  return (
    <div className="users-header">
      <div className="archive-head-link go-back">
        <ActionButtonPopup
          showPopup={showSavePopup}
          trigger={
            <div className="archive-head-link" data-testid="vendor-back-button">
              {isSaving ? (
                <Spinner />
              ) : (
                <a>
                  <img
                    onClick={onClickTrigger}
                    data-testid="vendor-back-button-img"
                    src={arrowLeft}
                    alt="arrow-left-icon"
                  />
                </a>
              )}
            </div>
          }
          title="Leaving without save"
          text="Your new user will not be saved if you wish to leave now. Are you sure you want to proceed?"
          onCancel={() => setShowSavePopup(false)}
          onConfirm={handleCancel}
          width="272px"
        />

        <div className="exit-config">User Detail View</div>
      </div>

      <div className="action-buttons">
        <Popup
          position="bottom left"
          trigger={() => saveButton}
          open={showPopup}
          keepTooltipInside
          onOpen={() => setShowPopup(true)}
          onClose={() => setShowPopup(false)}
        >
          <div className="table-dropdown">
            <h4>Save User</h4>
            <p>Are you sure you want to update this user?</p>
            <div>
              <button className="yes-btn" onClick={saveUser}>
                Yes
              </button>
              <button className="no-btn" onClick={() => setShowPopup(false)}>
                No
              </button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
}

export default observer(CompanyUsersHeader);
