import { MiniTag, Select, TextArea, useToast } from "@themis/ui";
import { debounce } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import {
  useControlsModules,
  useSaveControlsModules,
} from "@/api/queries/controlsModules";
import { RESIDUAL_MATRIX_TITLE } from "@/components/table/risk-register/Constants";
import { useMainStore } from "@/contexts/Store";

import {
  ModuleControlsForm,
  ModuleControlsSchema,
} from "../components/ModuleControls/ModuleControlsForm";
import { ModuleControlsGeneralInfo } from "../components/ModuleControls/ModuleControlsGeneralInfo";
import { ModuleControlsHeader } from "../components/ModuleControls/ModuleControlsHeader";
import { getRatingColorName } from "../config";
import { ControlMappingsSidebarProvider } from "../providers/ControlMappingsSidebarProvider";

export function ModuleControlsPage() {
  const { record_version_id: recordVersionID } = useParams<{
    record_version_id: string;
  }>();
  const mainStore = useMainStore();

  const { data, loading } = mainStore.riskRegisters;
  const { workspace_id: workspaceID } = useParams<{ workspace_id: string }>();
  const controlsModules = useControlsModules(Number(recordVersionID));
  const isLocked =
    controlsModules?.data?.data?.record_version?.is_locked_record;

  const matrix = controlsModules?.data?.data?.scoring_matrices?.find(
    (item) => item.name === RESIDUAL_MATRIX_TITLE,
  );
  const rangeRatings = data?.scoring_matrix_range_ratings || [];

  const controlRatings = [
    { title: "No Control" },
    ...(matrix?.scoring_matrix_columns || []),
  ];

  const toast = useToast();

  useEffect(() => {
    if (!loading) {
      mainStore.riskRegisters.index({ workspaceID: Number(workspaceID) });
    }
  }, [workspaceID, data]);

  const { mutateAsync: updateResult } = useSaveControlsModules({
    recordVersionId: Number(recordVersionID),
    onSuccess: () => {
      toast({ content: "Control saved successfully!", variant: "success" });
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not update control.",
        variant: "error",
      });
    },
  });

  const handleSubmitForm = (values: Partial<ModuleControlsSchema>) => {
    const requestBody = {
      controls_data: {
        description_value: values.description_value,
        rating_ids: values.rating_value,
        records_ids: values.records_ids,
        module_ratings: values.module_ratings,
      },
    };

    updateResult(requestBody);
  };
  const debouncedSubmitModuleDescription = useMemo(
    () =>
      debounce(
        (value) =>
          handleSubmitForm({
            description_value: value,
          }),
        2000,
      ),
    [],
  );

  return (
    <ControlMappingsSidebarProvider>
      {controlsModules && (
        <>
          <ModuleControlsHeader
            workspaceID={Number(workspaceID)}
            recordVersionID={Number(recordVersionID)}
          />
          <div className="tw-flex tw-h-full tw-min-w-96 tw-flex-col tw-bg-white tw-px-32 tw-pt-3 ">
            <ModuleControlsGeneralInfo
              controlsModules={controlsModules?.data?.data}
            />
            <div className="tw-mt-4 tw-flex tw-gap-6">
              <div className="tw-flex tw-w-full tw-flex-col tw-gap-1">
                <div className="tw-text-xs tw-font-semibold tw-text-neutral-300">
                  Control Rating
                </div>
                <Select
                  locked={isLocked}
                  size="lg"
                  selected={controlsModules?.data?.data?.rating?.value || "0"}
                  onSelect={(value: string) =>
                    handleSubmitForm({ rating_value: value })
                  }
                  items={controlRatings.map(
                    (
                      rating: {
                        title: string;
                        column_scoring_matrix_ratings: { color: string };
                      },
                      idx: number,
                    ) => ({
                      label: rating?.title,
                      value: idx.toString(),
                      Component: () => (
                        <MiniTag
                          color={
                            idx === 0
                              ? "primary"
                              : getRatingColorName(
                                  rating.column_scoring_matrix_ratings?.color,
                                )
                          }
                          size="sm"
                          className="tw-mr-2"
                        >
                          {rating?.title}
                        </MiniTag>
                      ),
                    }),
                  )}
                />
              </div>
              <div className="tw-flex tw-w-full tw-flex-col tw-gap-1">
                <div className="tw-text-xs tw-font-semibold tw-text-neutral-300">
                  Notes
                </div>
                <TextArea
                  placeholder="Type here"
                  className="tw-h-[40px]"
                  locked={isLocked}
                  rows={1}
                  defaultValue={
                    controlsModules?.data?.data?.description?.value || ""
                  }
                  onChange={(e) => {
                    debouncedSubmitModuleDescription(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="tw-mt-10">
              {controlsModules?.data?.data && (
                <ModuleControlsForm
                  recordVersionID={Number(recordVersionID)}
                  isLocked={isLocked}
                  controlsModules={controlsModules?.data?.data}
                  controlRatings={controlRatings}
                  rangeRatings={rangeRatings}
                  defaultValues={{
                    rating_value:
                      controlsModules?.data?.data?.rating?.value?.toString() ||
                      "0",
                    description_value:
                      controlsModules?.data?.data?.description?.value || "",
                  }}
                />
              )}
            </div>
          </div>
        </>
      )}
    </ControlMappingsSidebarProvider>
  );
}
