import "./selectable-question-types.scss";

import React from "react";

import { IconName } from "../../../../../../components/Elements/Icon";
import { SELECTABLE_QUESTION_TYPES } from "../../../../config";
import { Question, QuestionType } from "../../../../types/questionnaire";
import SelectableQuestionType from "./selectable-question/SelectableQuestion";

export type SelectableQuestion = {
  type: QuestionType;
  icon: IconName;
  name: string;
  questionDefaults: () => Question;
  canAddNewOption: boolean;
  optionIcon?: IconName;
};

type SelectableQuesitonTypesProps = {
  onAddQuestion: (question: Question) => void;
};

function SelectableQuestionTypes(props: SelectableQuesitonTypesProps) {
  return (
    <div className="selectable-question-types">
      {SELECTABLE_QUESTION_TYPES.map((questionType) => (
        <SelectableQuestionType
          key={questionType.name}
          question={questionType}
          onAddQuestion={props.onAddQuestion}
        />
      ))}
    </div>
  );
}

export default SelectableQuestionTypes;
