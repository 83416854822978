import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

import Portal from "@/components/table/shared/cell-type/Portal";
import { useMainStore } from "@/contexts/Store";

type Props<T extends FieldValues> = UseControllerProps<T> & {
  hasErrors?: boolean;
  width: number | string;
  minWidth?: number | string;
  disabled?: boolean;
  hasErrorClass?: string;
  locked?: boolean;
  pinned?: boolean;
  readOnly?: boolean;
  hasDetailView?: boolean;
  twoRows?: boolean;
};

function LongTextField<T extends FieldValues>({
  disabled,
  hasErrorClass = "has-errors",
  hasErrors = false,
  locked,
  pinned,
  readOnly,
  width,
  minWidth,
  hasDetailView,
  twoRows,
  ...controller
}: Props<T>) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  // Hooks
  const { field, fieldState } = useController(controller);

  // State
  const [isActive, setIsActive] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [coords, setCoords] = useState<{
    left: number;
    top: number;
    width: number;
  } | null>(null);

  // Hooks
  const textArea = useRef<HTMLTextAreaElement>(null);
  const cellRef = useRef<HTMLLIElement>(null);
  const cellTextRef = useRef<HTMLDivElement>(null);

  // Effects
  useEffect(() => {
    if (isActive && textArea.current) {
      textArea.current.focus();
      textArea.current.selectionStart = textArea.current.selectionEnd =
        textArea.current.value.length;
    }
  }, [isActive]);

  useEffect(() => {
    if (!showPopup) {
      setCoords(null);
    }
  }, [showPopup]);

  // Functions
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Escape") {
      onBlur();
    }
  };

  const onBlur = () => {
    setIsActive(false);
  };

  const handleClick = () => {
    if (!disabled) {
      setIsActive(true);
      setShowPopup(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    field.onChange(event.target.value);
  };

  const handleShowPopup = () => {
    if (!cellRef.current || !cellTextRef.current) {
      return;
    }

    const isTextOverflowing =
      cellTextRef.current.scrollHeight > cellTextRef.current.clientHeight;

    if (!isTextOverflowing) {
      return;
    }

    const { x, y, width: cellWidth } = cellRef.current.getBoundingClientRect();

    setCoords({ left: x, top: y, width: cellWidth });
    setShowPopup(true);
  };

  // Elements
  const liClassNames = classNames("cell long-text-cell", {
    active: isActive,
    [hasErrorClass]: hasErrors || !!fieldState.error,
    "read-only": readOnly,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "table-cell--disabled": isReadOnly,
    "pointer-events-none": locked || isReadOnly || readOnly,
    "gray-background": locked,
    "active-outline": isActive,
    pinned,
  });

  const inputPlaceholder = locked ? "N/A" : "Type here";

  if ((locked || !isActive) && !hasDetailView) {
    return (
      <li
        style={{ width, minWidth }}
        className={liClassNames}
        data-testid="long-input-cell-inactive"
        ref={cellRef}
        onMouseEnter={handleShowPopup}
        onMouseLeave={() => setShowPopup(false)}
        onClick={handleClick}
      >
        <div className="cell-content">
          <div
            className={classNames({
              "long-cell-text": true,
              placeholder: !field.value,
            })}
            data-testid="input-cell"
            ref={cellTextRef}
          >
            {field.value}
          </div>
        </div>
        {showPopup && coords && (
          <Portal>
            <div className="long-cell-text-popup" style={coords}>
              {field.value}
            </div>
          </Portal>
        )}
      </li>
    );
  }

  return (
    <li
      style={{ width, minWidth }}
      className={liClassNames}
      data-testid="long-input-cell-active"
      onBlur={onBlur}
    >
      <div className="cell-content">
        <textarea
          ref={textArea}
          readOnly={readOnly || locked}
          className={classNames("long-cell-textarea", {
            "long-cell-textarea-locked": locked,
            "table-cell--disabled": isReadOnly,
            "two-rows": twoRows,
          })}
          data-testid="input-cell"
          disabled={disabled}
          value={field.value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={inputPlaceholder}
        />
      </div>
    </li>
  );
}

export default LongTextField;
