import { useState } from "react";

import { SortDirection } from "../types";

export const useSortable = () => {
  const [sortByFieldName, setSortByFieldName] = useState<string | undefined>(
    undefined,
  );

  const [sortDirection, setSortDirection] = useState<SortDirection>(undefined);

  const sortParams =
    sortByFieldName && sortDirection
      ? { sort_field_name: sortByFieldName, sort_direction: sortDirection }
      : undefined;

  function setSortBy(fieldName: string) {
    if (sortByFieldName !== fieldName) {
      setSortByFieldName(fieldName);
      setSortDirection("ASC");
      return;
    }

    function newSortDirection(direction: SortDirection): SortDirection {
      switch (direction) {
        case "ASC":
          return "DESC";
        case "DESC":
          return undefined;
        default:
          return "ASC";
      }
    }

    setSortDirection(newSortDirection(sortDirection));
  }

  return {
    sortByFieldName,
    sortDirection,
    sortParams,
    setSortBy,
  };
};
