import React from "react";

import { Typography } from "@/components/Elements";
import Checkbox from "@/components/table/shared/Checkbox";
import Switch from "@/components/table/shared/Switch";

import { ModuleSummaryInfo } from "../../../stores/types/workspace-types";
import { iconForThemisModuleIdentifier } from "../../helpers/iconForThemisModuleIdentifier";

const allowedModulesToCopy = ["policy", "procedures", "documents"];

type ModuleSelectionItem = {
  module: ModuleSummaryInfo;
  handleSwitch: (identifier: string) => void;
  handleRecordsIncluded: (identifier: string) => void;
  isEnabled: boolean;
  areRecordsIncluded: boolean;
  defaultModules: string[];
};

const ModuleSelectionItem = (props: ModuleSelectionItem) => (
  <div className="module-summary-item">
    <div className="module-summary-item__title">
      <div className="module-summary-title">
        <img
          className="module-summary-icon"
          width="20px"
          src={iconForThemisModuleIdentifier(props.module.identifier)}
          alt=""
        />
        <Typography
          label={props.module.name}
          weight="semiBold"
          size="sm"
          color="generalMidnightDark"
        />
      </div>
      <div>
        {props.defaultModules.includes(props.module.identifier) ? (
          <span>Built In Module</span>
        ) : (
          <Switch
            active
            checked={props.isEnabled}
            onChange={() => props.handleSwitch(props.module.identifier)}
          />
        )}
      </div>
    </div>
    {allowedModulesToCopy.includes(props.module.identifier) &&
      props.isEnabled && (
        <div className="module-summary-copy-desc module-summary-copy-desc__flex">
          <Checkbox
            checked={props.areRecordsIncluded}
            data-testid="filters-options-select-item-checkbox"
            onChange={() =>
              props.handleRecordsIncluded(props.module.identifier)
            }
          />
          <span className="seperate-line-desc">
            {props.module.identifier === "documents"
              ? "Copy over folders and related record names"
              : "Copy over record names"}
          </span>
        </div>
      )}
  </div>
);

export default ModuleSelectionItem;
