import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { useIsFeatureEnabled } from "@/hooks/useIsFeatureEnabled";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import { CompanyOutlook } from "./CompanyOutlook";
import { CompanyRetro } from "./CompanyRetro";
import { MyWork } from "./MyWork";
import OldHome from "./OldHome";

const HOME_ROUTES = {
  base: "/home",
  myWork: "/my-work",
  companyRetro: "/company-retro",
} as const;

export const HOME_PATH =
  `/workspaces/:workspace_id${HOME_ROUTES.base}` as const;

export const MY_WORK_PATH = `${HOME_PATH}${HOME_ROUTES.myWork}` as const;

export const COMPANY_RETRO_PATH =
  `${HOME_PATH}${HOME_ROUTES.companyRetro}` as const;

export function HomeRoutes() {
  const { path } = useRouteMatch();
  const isOutlookEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_NEW_DASHBOARD_COMPANY_OUTLOOK_WIDGETS,
  );

  const isRetroEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_NEW_DASHBOARD_RETROSPECTIVE_WIDGETS,
  );

  if (isOutlookEnabled === undefined && isRetroEnabled === undefined) {
    return null;
  }

  if (!isOutlookEnabled && !isRetroEnabled) {
    return <OldHome />;
  }

  return (
    <Switch>
      {isOutlookEnabled && (
        <Route path={`${path}${HOME_ROUTES.myWork}`}>
          <MyWork />
        </Route>
      )}
      {isRetroEnabled && (
        <Route path={`${path}${HOME_ROUTES.companyRetro}`}>
          <CompanyRetro />
        </Route>
      )}
      <Route exact path={path}>
        <CompanyOutlook />
      </Route>
    </Switch>
  );
}
