import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { PermissionSet } from "@/api";
import { useMainStore } from "@/contexts/Store";

import { Button, MiniTag } from "../../Elements";
import Table from "../../table/Table";
import CompanySettingsNavigation from "../CompanySettingsNavigation";
import PermissionItem from "./PermissionItem";

function PermissionList() {
  // Import MobX stores
  const mainStore = useMainStore();

  const companyID = mainStore.context.companyID!;

  // Hooks
  useEffect(() => {
    mainStore.permissions.index(companyID);
  }, [companyID]);

  // Variables
  const history = useHistory();
  const { permissionSets } = mainStore.permissions;

  const permissionGroups = useMemo(() => {
    const admin = {
      name: "Admin Roles",
      description:
        "Full Access to all Themis workspaces and the ability to define user roles and permissions.",
      permissionSets: [] as PermissionSet[],
    };
    const system = {
      name: "System Roles",
      description:
        "Limited access to certain functionality and modules in Themis.",
      permissionSets: [] as PermissionSet[],
    };
    const custom = {
      name: "Custom Roles",
      badge: "Beta",
      permissionSets: [] as PermissionSet[],
    };

    permissionSets.forEach((permissionSet) => {
      if (!permissionSet.read_only) {
        custom.permissionSets.push(permissionSet);
        return;
      }

      if (permissionSet.permission_type === "company") {
        admin.permissionSets.push(permissionSet);
        return;
      }

      if (permissionSet.permission_type === "workspace_module") {
        system.permissionSets.push(permissionSet);
      }
    });

    return [admin, system, custom];
  }, [permissionSets]);

  // Functions
  const handleDelete = async (permissionSetID: string) => {
    try {
      await mainStore.permissions.deletePermissionSet(permissionSetID);
      mainStore.toast.setText("Role has been deleted!");
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      mainStore.toast.setErrorText(error || "Failed to delete role!");
    }
  };

  const handleEdit = (permissionSetID: string, clone: boolean = false) => {
    history.push(
      `/settings/roles/${permissionSetID}${clone ? "?clone=true" : ""}`,
    );
  };

  const handleCreate = () => {
    history.push("/settings/roles/new");
  };

  return (
    <Table>
      <div className="settings-wrap">
        <CompanySettingsNavigation />

        <div className="permission-sections">
          {permissionGroups.map(
            (group) =>
              group.permissionSets.length > 0 && (
                <div key={group.name} className="permission-section">
                  <div className="permission-section-name">
                    {group.name}
                    {/* @ts-expect-error TS(2339) FIXME: Property 'badge' does not exist on type '{ name: s... Remove this comment to see the full error message */}
                    {group.badge && (
                      <MiniTag
                        theme="black"
                        // @ts-expect-error TS(2339) FIXME: Property 'badge' does not exist on type '{ name: s... Remove this comment to see the full error message
                        label={group.badge}
                        className="permission-section-name-badge"
                      />
                    )}
                  </div>

                  {/* @ts-expect-error TS(2339) FIXME: Property 'description' does not exist on type '{ n... Remove this comment to see the full error message */}
                  {group.description && (
                    <div className="permission-section-description">
                      {/* @ts-expect-error TS(2339) FIXME: Property 'description' does not exist on type '{ n... Remove this comment to see the full error message */}
                      {group.description}
                    </div>
                  )}

                  <div className="permission-section-content">
                    {group.permissionSets.map((permissionSet) => (
                      <PermissionItem
                        key={permissionSet.id}
                        permissionSet={permissionSet}
                        onEditPermission={handleEdit}
                        onDeletePermission={handleDelete}
                      />
                    ))}
                  </div>
                </div>
              ),
          )}

          <Button
            icon="plus"
            label={
              <>
                Add Custom Role
                <MiniTag
                  className="permission-sections-create-badge"
                  label="Beta"
                />
              </>
            }
            data-testid="permission-create-button"
            onClick={handleCreate}
          />
        </div>
      </div>
    </Table>
  );
}

export default observer(PermissionList);
