import { keyBy } from "lodash";

import {
  ModuleSectionInfo,
  ModuleSectionNode,
  ModuleSummaryInfo,
  ModuleWorkspace,
} from "../types/workspace-types";

export function buildWorkspaceSummaryInformation(
  moduleWorkspaces: Array<Partial<ModuleWorkspace>>,
  moduleSectionInfos: Array<ModuleSectionInfo>,
) {
  const enabledModules = moduleWorkspaces.filter((module) => module.added);
  const summaries = enabledModules.map((module) =>
    buildModuleSummaryInformation(
      module,
      moduleSectionInfos?.find(
        (moduleSection) => moduleSection.module_id === module.id,
      ),
    ),
  );

  return summaries;
}

function buildModuleSummaryInformation(
  module: Partial<ModuleWorkspace>,
  sectionInfo?: ModuleSectionInfo,
): ModuleSummaryInfo {
  return {
    // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    id: module.id,
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    name: module.name,
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    identifier: module.themis_module.identifier,
    sections: sectionInfo ? buildSectionTree(sectionInfo) : [],
  };
}

function buildSectionTree(
  sectionInfo: ModuleSectionInfo,
): Array<ModuleSectionNode> {
  const rootSections: Array<ModuleSectionNode> = [];

  const sections = sectionInfo.section_tags.map((section) => ({
    ...section,
    id: section.id,
    title: section.title,
    sections: [],
  }));
  const lookup = keyBy(sections, "id");

  sections.forEach((section) => {
    if (!section.parent_section_tag_id) {
      rootSections.push(section);
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ id: number; title: string; sec... Remove this comment to see the full error message
      lookup[section.parent_section_tag_id].sections.push(section);
    }
    section.parent_section_tag_id = undefined;
  });

  return rootSections;
}
