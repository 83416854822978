import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import { Button } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
};

function SubExportComplaintButton({ recordVersion }: Props) {
  const mainStore = useMainStore();

  enum REPORTTYPE {
    DETAILS,
    COMMENTS_SUMMARY,
    ALL,
  }

  const [checkboxCheckedAllExport, setCheckboxCheckedAllExport] =
    useState(true);
  const [checkboxCheckedDetails, setCheckboxCheckedDetails] = useState(true);
  const [checkboxCheckedCommentsSummary, setCheckboxCheckedCommentsSummary] =
    useState(true);
  const [buttonDisableExport, setButtonDisableExport] = useState(false);
  // State
  const [isOpenModal, setIsOpenModal] = useState(true);

  const recordVersionID = recordVersion;

  function generatePDFReport() {
    handlePopUpClose();
    setCheckboxCheckedAllExport(true);
    setCheckboxCheckedDetails(true);
    setCheckboxCheckedCommentsSummary(true);
    setButtonDisableExport(false);
    return mainStore.complaints.export_pdf(
      recordVersionID,
      checkboxCheckedDetails,
      checkboxCheckedCommentsSummary,
    );
  }

  useEffect(() => {
    if (checkboxCheckedCommentsSummary && checkboxCheckedDetails) {
      setCheckboxCheckedAllExport(true);
    } else {
      setCheckboxCheckedAllExport(false);
    }

    if (
      checkboxCheckedAllExport ||
      checkboxCheckedCommentsSummary ||
      checkboxCheckedDetails
    ) {
      setButtonDisableExport(false);
    } else {
      setButtonDisableExport(true);
    }
  }, [
    buttonDisableExport,
    checkboxCheckedAllExport,
    checkboxCheckedDetails,
    checkboxCheckedCommentsSummary,
  ]);

  const checkAllReports = (checkboxChecked: boolean, typeCheck: REPORTTYPE) => {
    if (typeCheck === REPORTTYPE.ALL) {
      setCheckboxCheckedAllExport(checkboxChecked);
      setCheckboxCheckedDetails(checkboxChecked);
      setCheckboxCheckedCommentsSummary(checkboxChecked);
    } else if (typeCheck === REPORTTYPE.DETAILS) {
      setCheckboxCheckedDetails(checkboxChecked);
    } else if (typeCheck === REPORTTYPE.COMMENTS_SUMMARY) {
      setCheckboxCheckedCommentsSummary(checkboxChecked);
    }
  };

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsOpenModal(false);
  }

  return (
    <div
      className={classNames("list-points", {
        "without-background": false,
      })}
      data-testid="complaints-context-menu"
    >
      <Popup
        position="bottom left"
        trigger={<div />}
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
        nested
      >
        <div className="table-dropdown-partner partner-dropdown">
          <ul>
            <li>
              <div className="toggle-pdf-report-purple">
                Select sections to include in your export
              </div>
            </li>
          </ul>
          <ul>
            <li>
              <div className="toggle-pdf-report">
                <input
                  type="checkbox"
                  id="checkAll"
                  name="checkAll"
                  checked={checkboxCheckedAllExport}
                  onChange={() =>
                    checkAllReports(!checkboxCheckedAllExport, REPORTTYPE.ALL)
                  }
                  data-testid="toggle-all-workspaces"
                />
                <label htmlFor="checkAll">Export Full PDF Report</label>
              </div>
            </li>
          </ul>
          <ul>
            <hr />
            <li>
              <div className="toggle-pdf-report">
                <input
                  type="checkbox"
                  id="checkPartner"
                  name="checkPartner"
                  checked={checkboxCheckedDetails}
                  onChange={() =>
                    checkAllReports(!checkboxCheckedDetails, REPORTTYPE.DETAILS)
                  }
                  data-testid="toggle-partner-workspaces"
                />
                <label htmlFor="checkPartner">Complaint Details</label>
              </div>
            </li>
          </ul>
          <ul>
            <li>
              <div className="toggle-pdf-report">
                <input
                  type="checkbox"
                  id="checkCommentsSummary"
                  name="checkCommentsSummary"
                  checked={checkboxCheckedCommentsSummary}
                  onChange={() =>
                    checkAllReports(
                      !checkboxCheckedCommentsSummary,
                      REPORTTYPE.COMMENTS_SUMMARY,
                    )
                  }
                  data-testid="toggle-document-workspaces"
                />
                <label htmlFor="checkCommentsSummary">Comment Summary</label>
              </div>
            </li>
          </ul>
          <ul>
            <hr />
            <li>
              <Button
                label="Export"
                onClick={generatePDFReport}
                disabled={buttonDisableExport}
                data-testid="questionnaire-export-button"
              />
            </li>
          </ul>
        </div>
      </Popup>
    </div>
  );
}

export default observer(SubExportComplaintButton);
