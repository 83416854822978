import React, { ReactNode, useState } from "react";
import Popup from "reactjs-popup";

import { Button, Flex, Typography } from "@/components/Elements";
import ModalWrapper from "@/components/shared/ModalWrapper";

type Props = {
  onConfirm: () => Promise<void> | void;
  headerText?: string;
  text: string;
  trigger: ReactNode;
  disabled?: boolean;
};

export function ConfirmationPopup({
  onConfirm,
  headerText,
  text,
  trigger,
  disabled = false,
}: Props) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  async function handleConfirm() {
    await onConfirm();
    setIsPopupOpen(false);
  }

  return (
    <Popup
      disabled={disabled}
      position={["bottom left", "bottom right"]}
      trigger={<div>{trigger}</div>} // div wrap prevents trigger position issues
      open={isPopupOpen}
      onOpen={() => setIsPopupOpen(true)}
      onClose={() => setIsPopupOpen(false)}
      keepTooltipInside
    >
      <ModalWrapper>
        <Flex column rowGap={10}>
          {headerText && (
            <Typography label={headerText} color="brandingDimViolet" />
          )}
          <Typography label={text} size="sm" color="generalMidnightDark" />
          <Flex columnGap={8}>
            <Button label="Delete" onClick={handleConfirm} theme="primary" />
            <Button
              label="Cancel"
              onClick={() => setIsPopupOpen(false)}
              theme="tertiary"
            />
          </Flex>
        </Flex>
      </ModalWrapper>
    </Popup>
  );
}
