import { action, makeObservable, observable } from "mobx";

import {
  CreateTagMutationRequest,
  Tag,
  tagsService,
  UpdateTagMutationRequest,
} from "@/api";

import { MainStore } from "../Main";

export default class TagsStore {
  mainStore: MainStore;

  // Observable objects
  tags: Tag[] = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      tags: observable,
      setTags: action,
    });

    this.mainStore = mainStore;
  }

  async index(companyID: number) {
    const response = await tagsService.listTags(companyID);
    this.setTags(response);
  }

  async create(companyID: number, title: string) {
    const data: CreateTagMutationRequest = {
      tag: { title, tag_type: "framework" },
    };

    await tagsService.createTag(companyID, data);
    await this.index(companyID);
  }

  async update(companyID: number, tagID: number, title: string) {
    const data: UpdateTagMutationRequest = {
      tag: { title, tag_type: "framework" },
    };

    await tagsService.updateTag(tagID, data);
    await this.index(companyID);
  }

  async delete(companyID: number, tagID: number) {
    await tagsService.deleteTag(tagID);
    await this.index(companyID);
  }

  // Actions
  setTags(value: Tag[]) {
    if (value) {
      this.tags = value;
    } else {
      this.tags = [];
    }
  }

  // Store Helpers
  cleanup() {
    this.setTags([]);
  }
}
