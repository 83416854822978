import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import DefaultIcon from "../../images/setting-workspace/icon/Default Icon - V1.svg";
import Marketing from "../../images/setting-workspace/icon/Icon-AdvertisingAndMarketing.svg";
import Audit from "../../images/setting-workspace/icon/Icon-Audit.svg";
import ChangeManagement from "../../images/setting-workspace/icon/Icon-ChangeManagement.svg";
import Complaints from "../../images/setting-workspace/icon/Icon-Complaints.svg";
import ConflictOfInterest from "../../images/setting-workspace/icon/Icon-ConflictOfInterest.svg";
import ControlMapping from "../../images/setting-workspace/icon/Icon-ControlMapping.svg";
import CustomerSupport from "../../images/setting-workspace/icon/Icon-CustomerSupport.svg";
import Documents from "../../images/setting-workspace/icon/Icon-Documents.svg";
import Finra from "../../images/setting-workspace/icon/Icon-Finra.svg";
import IssueManagement from "../../images/setting-workspace/icon/Icon-IssueManagement.svg";
import KRI from "../../images/setting-workspace/icon/Icon-KRI.svg";
import Policy from "../../images/setting-workspace/icon/Icon-Policy.svg";
import Procedures from "../../images/setting-workspace/icon/Icon-Procedures.svg";
import QATesting from "../../images/setting-workspace/icon/Icon-QATesting.svg";
import RiskAssessment from "../../images/setting-workspace/icon/Icon-RiskAssessment.svg";
import RiskRegister from "../../images/setting-workspace/icon/Icon-RiskRegister.svg";
import Training from "../../images/setting-workspace/icon/Icon-Training.svg";
import VDD from "../../images/setting-workspace/icon/Icon-VendorDueDiligence.svg";
import WebsiteMonitoring from "../../images/setting-workspace/icon/Icon-WebsiteMonitoringModule.svg";
import Zendesk from "../../images/setting-workspace/icon/Icon-Zendesk.svg";

type ModuleIdentifierAndIcon = {
  [key in ModuleIdentifier | "default"]: string;
};

const iconMap: ModuleIdentifierAndIcon = {
  audits: Audit,
  complaints: Complaints,
  conflicts_of_interest: ConflictOfInterest,
  control_mapping: ControlMapping,
  customer_support: CustomerSupport,
  default: DefaultIcon,
  documents: Documents,
  finra: Finra,
  issue_management: IssueManagement,
  marketing: Marketing,
  new_product_approval: ChangeManagement,
  policy: Policy,
  procedures: Procedures,
  qa_tests_development: QATesting,
  risk_assessment: RiskAssessment,
  risk_register: RiskRegister,
  training: Training,
  vendor_due_diligence: VDD,
  zendesk: Zendesk,
  key_risk_indicators: KRI,
  action_plans: DefaultIcon,
  linked_documents: DefaultIcon,
  qa_findings: DefaultIcon,
  qa_procedures: DefaultIcon,
  samples: DefaultIcon,
  questionnaires: DefaultIcon,
  values: DefaultIcon,
  accounts: DefaultIcon,
  website_monitoring: WebsiteMonitoring,
};

export default iconMap;
