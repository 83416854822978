import axios from "axios";
import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { parseFeatureFlagResponse } from "../helpers/FeatureFlagHelpers";
import { MainStore } from "../Main";
import {
  FEATURE_FLAG_ID,
  FeatureFlagActorMap,
  FeatureFlagActors,
  FeatureFlagResponse,
} from "../types/feature-flag-types";

export default class FeatureFlagsStore {
  mainStore: MainStore;

  data: FeatureFlagActorMap | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,

      setData: action,
      cleanup: action,
    });

    this.mainStore = mainStore;
  }

  async fetchFeatureFlags() {
    try {
      const response = await legacyApi.get<FeatureFlagResponse>(
        "/features/api/features",
        { headers: this.mainStore.getHeaders() },
      );

      if (axios.isAxiosError(response)) {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throw (response.response.data as any).errors;
      }

      this.setData(parseFeatureFlagResponse(response.data));
    } catch (error) {
      window.console.log(`"FeatureFlags#fetchFeatureFlags" error ${error}`);
    }
  }

  getIsEnabled(featureFlagId: FEATURE_FLAG_ID) {
    if (!featureFlagId) {
      return false;
    }

    const featureFlagData = this.data?.[featureFlagId];

    if (typeof featureFlagData === "boolean") {
      return featureFlagData;
    }

    if (featureFlagData === undefined) {
      return false;
    }

    const featureFlagActors = featureFlagData as FeatureFlagActors;
    const { workspaceIds } = featureFlagActors;
    const { companyIds } = featureFlagActors;
    const { userIds } = featureFlagActors;

    return [
      Array.isArray(workspaceIds) &&
        workspaceIds.includes(Number(this.mainStore.context.workspaceID)),
      Array.isArray(companyIds) &&
        companyIds.includes(Number(this.mainStore.context.companyID)),
      Array.isArray(userIds) &&
        userIds.includes(Number(this.mainStore.users.user.id)),
    ].some(Boolean);
  }

  setData(value: FeatureFlagActorMap) {
    this.data = value;
  }

  cleanup() {
    this.data = {};
  }
}
