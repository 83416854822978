import React from "react";
import Popup from "reactjs-popup";

type Props = {
  height?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCancel?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onOpen?: (...args: any[]) => any;
  showPopup?: boolean;
  text?: React.ReactNode;
  title?: React.ReactNode;
  trigger?: React.ReactNode;
  width?: string;
  disabled?: boolean;
};

export const ActionButtonPopup = ({
  showPopup,
  trigger,
  title,
  text,
  onConfirm,
  onCancel,
  onClose,
  onOpen,
  width,
  height,
  disabled,
}: Props) => {
  const popupContentStyle = {
    backgroundColor: "white",
    boxShadow: "0 8px 24px rgba(33, 32, 95, 0.2)",
    borderRadius: "8px",
    height: height || "124px",
    width: width || "272px",
    padding: "12px 16px",
    display: "flex",
    flexDirection: "column",
    fontSize: "12px",
  };

  const titleStyle = {
    color: "#696FA3",
    fontWeight: "700",
    marginBottom: "8px",
  };

  const buttonStyle = {
    alignItems: "center",
    padding: "8px 12px",
    borderRadius: "6px",
    cursor: "pointer",
  };

  return (
    <Popup
      position="bottom left"
      className="action-menu"
      open={showPopup}
      // @ts-expect-error TS(2322) FIXME: Type 'ReactNode' is not assignable to type 'Elemen... Remove this comment to see the full error message
      trigger={trigger}
      keepTooltipInside
      // @ts-expect-error TS(2322) FIXME: Type '{ backgroundColor: string; boxShadow: string... Remove this comment to see the full error message
      contentStyle={popupContentStyle}
      arrow
      onClose={onClose}
      onOpen={onOpen}
      disabled={disabled}
    >
      <div data-testid="action-btn-popup">
        <div data-testid="action-btn-popup-title" style={titleStyle}>
          {title}
        </div>
        <div data-testid="action-btn-popup-text">{text}</div>

        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "12px" }}
        >
          <div
            data-testid="action-btn-popup-confirm"
            style={{
              ...buttonStyle,
              background: "#6D71F9",
              color: "white",
              marginRight: "12px",
            }}
            onClick={onConfirm}
          >
            Yes
          </div>
          <div
            data-testid="action-btn-popup-cancel"
            style={{ ...buttonStyle, background: "#E3E3FD", color: "black" }}
            onClick={onCancel}
          >
            No
          </div>
        </div>
      </div>
    </Popup>
  );
};
