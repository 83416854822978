import React from "react";

import { Workspace } from "@/stores/types/workspace-types";

import { getWorkspaceLogo } from "../../helpers/getWorkspaceLogo";
import WorkspaceNavigationPopup from "./WorkspaceNavigationPopup";

type WorkspaceNavigationElementsProps = {
  isInternal?: boolean;
  workspaces: Workspace[];
  switchWorkspace(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    workspaceID: number,
  ): void;
};

export function WorkspaceNavigationElements({
  isInternal,
  workspaces: unArchivedWorkspaces,
  switchWorkspace,
}: WorkspaceNavigationElementsProps) {
  return unArchivedWorkspaces
    .filter((workspace) => workspace.is_internal === !!isInternal)
    .map((workspace) => {
      const { logo } = getWorkspaceLogo(workspace);

      return (
        <WorkspaceNavigationPopup
          key={workspace.id}
          archived={workspace.is_archived}
          logo={logo}
          showInbound={workspace.is_collaborative_only}
          workspaceID={workspace.id}
          workspaceName={workspace.name}
          switchWorkspace={switchWorkspace}
        />
      );
    });
}
