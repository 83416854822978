import { action, computed, makeObservable, observable } from "mobx";

import { CommentsAPI } from "@/api/legacy/comments";
import { UserTasksQueryParams } from "@/api/legacy/comments/types";
import { MainStore } from "@/stores/Main";
import { UserComment } from "@/stores/types/comment-types";

export default class TasksStore {
  mainStore: MainStore;

  allTasks: UserComment[] | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      allTasks: observable,
      setAllTasks: action,
      activeTasks: computed,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/tasks/my
  async myTasks(params?: UserTasksQueryParams) {
    try {
      const response = await CommentsAPI.userTasks(
        params?.workspace_id ? params : {},
      );

      this.setAllTasks(response.comments);
    } catch (error) {
      window.console.error(`"Tasks#myTasks" error ${error}`);
    }
  }

  setAllTasks(value: UserComment[] | null) {
    this.allTasks = value;
  }

  // computations
  get activeTasks() {
    return this.allTasks?.filter((task) => !task.resolved);
  }

  // Store Helpers

  cleanup() {
    this.setAllTasks(null);
  }
}
