import { observer } from "mobx-react";
import React, { ChangeEvent } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";

import { useMainStore } from "@/contexts/Store";

import downloadIcon from "../../images/table-image/icon/download-white.svg";
import uploadIcon from "../../images/table-image/icon/upload-icon.svg";

type Props = {
  parentRecordVersionID?: number;
  sectionId?: number | null;
  setShowPopup: (show: boolean) => void;
  subModuleDownload?: boolean;
};

function ImportRecords({
  setShowPopup,
  sectionId,
  subModuleDownload,
  parentRecordVersionID,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { themisModuleIdentifier } = mainStore.context;
  const { moduleWorkspaceID } = mainStore.context;
  const { companyID } = mainStore.context;
  const tableID = subModuleDownload
    ? mainStore.context.subModuleTableID
    : mainStore.context.tableID;
  const tableName = subModuleDownload
    ? mainStore.context.subModuleTableName
    : mainStore.context.tableName;
  const { user } = mainStore.users;

  async function downloadTemplate() {
    if (!tableID) {
      return;
    }

    const spreadSheetData = await mainStore.tables.generateTemplate(tableID);
    if (!spreadSheetData) {
      return;
    }

    const link = document.createElement("a");
    const blob = new Blob([new Uint8Array(spreadSheetData)], {
      type: "application/octet-stream",
    });
    link.href = URL.createObjectURL(blob);
    link.download = !tableName
      ? `${themisModuleIdentifier}_table.xlsx`
      : `${themisModuleIdentifier}_${tableName.toLowerCase()}_table.xlsx`;
    link.click();
  }

  async function processCSV(event: ChangeEvent<HTMLInputElement>) {
    setShowPopup(false);
    const { files } = event.target;
    if (!files || files.length === 0 || !moduleWorkspaceID) {
      return;
    }
    await mainStore.tables.importRecords(
      files[0],
      tableID,
      sectionId || null,
      user.id || null,
      parentRecordVersionID,
    );

    // Re-index for newly created departments, products & options
    mainStore.departments.index(companyID);
    mainStore.products.index(companyID);
    mainStore.fieldOptions.index(moduleWorkspaceID);
  }

  const downloadTemplateButton = (
    <div>
      <button onClick={downloadTemplate}>
        <img src={downloadIcon} alt="download-icon" />
        Download XLSX Template
      </button>
    </div>
  );

  const directUploadProvider = (
    <DirectUploadProvider
      onSuccess={() => {}}
      render={() => (
        <div className="import-csv-file-select">
          <button>
            <img src={uploadIcon} alt="upload-icon" />
            Import CSV/XLSX File
          </button>
          <input
            data-testid="file-upload-input"
            type="file"
            onChange={(event) => processCSV(event)}
            className="cursor-pointer"
          />
        </div>
      )}
    />
  );

  return (
    <div className="table-dropdown import-dropdown">
      <div className="import-csv-section">
        <div className="import-csv-section-title">
          <div className="users-circle">1</div>
          <h3>Download XLSX File</h3>
        </div>
        {downloadTemplateButton}
      </div>
      <div className="import-csv-section">
        <div className="import-csv-section-title">
          <div className="users-circle">2</div>
          <h3>Add / Edit info in XLSX template</h3>
        </div>
        <div className="import-csv-blurb">
          The first line in your CSV/XLSX file must contain the column headers
          as shown in the template. Any custom columns need to be manually added
          to the template. Only user editable cells can be imported.
        </div>
      </div>
      <div className="import-csv-section">
        <div className="import-csv-section-title">
          <div className="users-circle">3</div>
          <h3>Upload CSV/XLSX File</h3>
        </div>
        <div className="cursor-pointer">{directUploadProvider}</div>
      </div>
    </div>
  );
}

ImportRecords.defaultProps = {
  parentRecordVersionID: -1,
  subModuleDownload: false,
};

export default observer(ImportRecords);
