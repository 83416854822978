import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@themis/ui";
import classNames from "classnames";
import { kebabCase } from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";
import {
  PiArchiveBold,
  PiClockBold,
  PiGearSixBold,
  PiInfoBold,
} from "react-icons/pi";
import { generatePath, Link, matchPath, useLocation } from "react-router-dom";

import { REPORT_ENABLED_MODULES } from "@/components/reports/constants";
import { useMainStore } from "@/contexts/Store";

import { moduleInfo } from "./constants/module_info";

const MENU_ITEM_CLASSES =
  "tw-pl-4 tw-flex tw-items-center tw-text-sm tw-min-h-8 tw-font-medium tw-tracking-lesswide tw-leading-5";

function HeadSelect() {
  const mainStore = useMainStore();
  const { pathname } = useLocation();

  const {
    themisModuleIdentifier: moduleIdentifier,
    workspaceID,
    moduleWorkspaceID,
  } = mainStore.context;
  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const selectedModuleInfo = moduleInfo.find(
    (module) => module.identifier === moduleIdentifier,
  );

  const lastPartURL = pathname.split("/").pop();
  const moduleWorkspace = mainStore.moduleWorkspaces.list.find(
    (mw) => mw.id === moduleWorkspaceID,
  );
  const subModule = moduleWorkspace?.attached_module_workspaces.find(
    (mw) => mw.themis_module.identifier === lastPartURL?.replace(/-/g, "_"),
  );
  const subModulePath = subModule?.themis_module?.identifier.replace(/_/g, "-");
  const isActivityPage = !!matchPath(
    location.pathname,
    "/workspaces/:workspace_id/modules/:module_identifier/audit_trail",
  );
  const isNotificationPage = !!matchPath(
    location.pathname,
    "/workspaces/:workspace_id/modules/:module_identifier/notifications",
  );

  const [isMoreButtonOpen, setIsMoreButtonOpen] = useState(false);
  const [isOpenModuleInfoModal, setIsOpenModuleInfoModal] = useState(false);

  const showMoreButton =
    moduleIdentifier &&
    (mainStore.userPermissions.canSeeNotifications ||
      moduleIdentifier !== "risk_assessment");

  return (
    <div className="tw-flex">
      {selectedModuleInfo && (
        <TooltipProvider>
          <Tooltip delayDuration={700}>
            <TooltipTrigger>
              <Popover
                onOpenChange={(isOpen) => setIsOpenModuleInfoModal(isOpen)}
              >
                <PopoverTrigger role="dialog" aria-label="Info" asChild>
                  <div
                    className={classNames(
                      "tw-flex tw-h-8 tw-w-8  tw-items-center tw-justify-center tw-rounded-md ",
                      {
                        "tw-rounded-md tw-bg-black": isOpenModuleInfoModal,
                        "hover:tw-bg-primary-25": !isOpenModuleInfoModal,
                      },
                    )}
                  >
                    <PiInfoBold
                      size="16px"
                      fill={isOpenModuleInfoModal ? "#fff" : "#353549"}
                    />
                  </div>
                </PopoverTrigger>
                <PopoverContent
                  className="tw-w-64 tw-rounded-lg tw-bg-black tw-p-4"
                  side="left"
                >
                  <div className="tw-text-left tw-text-sm tw-font-medium tw-leading-4 tw-tracking-lesswide tw-text-white">
                    <h2>{selectedModuleInfo.name}</h2>
                    <p className="tw-mt-2">{selectedModuleInfo.description}</p>
                    <ol className="tw-mt-5 tw-pl-4">
                      {selectedModuleInfo.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ol>
                    {selectedModuleInfo.link && (
                      <div className="tw-mt-5">
                        <p className="tw-text-xs tw-font-semibold tw-leading-5 tw-tracking-tight">
                          Want to learn more?{" "}
                          <a
                            className="tw-text-white tw-underline"
                            href={selectedModuleInfo.link}
                          >
                            Visit our module documentation
                          </a>
                        </p>
                      </div>
                    )}
                  </div>
                </PopoverContent>
              </Popover>
            </TooltipTrigger>
            <TooltipContent align="end" side="top" disableArrow>
              <p>Module Information</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
      {moduleIdentifier &&
        [
          "control_mapping",
          "documents",
          "policy",
          "procedures",
          "training",
        ].includes(moduleIdentifier) && (
          <TooltipProvider>
            <Tooltip delayDuration={700}>
              <TooltipTrigger>
                <Link
                  to={`/workspaces/${workspaceID}/modules/${kebabCase(
                    moduleIdentifier,
                  )}/archived`}
                  aria-label="View Archived"
                >
                  <div
                    className={
                      "tw-flex tw-h-8 tw-w-8 tw-cursor-pointer  tw-items-center tw-justify-center tw-rounded-md hover:tw-bg-primary-25"
                    }
                  >
                    <PiArchiveBold size="16px" fill="353549" />
                  </div>
                </Link>
              </TooltipTrigger>
              <TooltipContent align="end" side="top" disableArrow>
                <p>View Archived</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}

      {moduleIdentifier && moduleIdentifier !== "risk_assessment" && (
        <TooltipProvider>
          <Tooltip delayDuration={700}>
            <TooltipTrigger
              className={classNames({
                "tw-cursor-not-allowed": isActivityPage,
                "tw-cursor-pointer": !isActivityPage,
              })}
            >
              <Link
                to={`/workspaces/${workspaceID}/modules/${kebabCase(
                  moduleIdentifier,
                )}/audit_trail`}
                aria-label="View Activity"
                className={classNames({
                  "tw-pointer-events-none": isActivityPage,
                })}
              >
                <div
                  className={classNames(
                    "tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-md hover:tw-bg-primary-25",
                  )}
                >
                  <PiClockBold size="16px" fill="#353549" />
                </div>
              </Link>
            </TooltipTrigger>
            <TooltipContent align="end" side="top" disableArrow>
              <p>View Activity</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
      {showMoreButton && (
        <div>
          <TooltipProvider>
            <Tooltip delayDuration={700}>
              <TooltipTrigger>
                <DropdownMenu
                  onOpenChange={(isOpen) => setIsMoreButtonOpen(isOpen)}
                >
                  <DropdownMenuTrigger
                    data-testid="header-popup-more"
                    aria-label="More"
                  >
                    <div
                      className={classNames(
                        "tw-flex tw-h-8 tw-w-8  tw-items-center tw-justify-center tw-rounded-md ",
                        {
                          "tw-rounded-md tw-bg-neutral-500": isMoreButtonOpen,
                          "hover:tw-bg-primary-25": !isMoreButtonOpen,
                        },
                      )}
                    >
                      <PiGearSixBold
                        size="16px"
                        fill={isMoreButtonOpen ? "#fff" : "#353549"}
                      />
                    </div>
                  </DropdownMenuTrigger>

                  <DropdownMenuContent
                    aria-label="More Menu Context"
                    align="end"
                    side="bottom"
                    className="tw-w-60"
                  >
                    {mainStore.userPermissions.canSeeNotifications && (
                      <Link
                        to={generatePath(
                          "/workspaces/:workspace_id/modules/:moduleIdentifier/:subModule?/notifications",
                          {
                            workspace_id: workspaceID as number,
                            moduleIdentifier: kebabCase(moduleIdentifier),
                            subModule: subModulePath,
                          },
                        )}
                        className={classNames({
                          "tw-pointer-events-none": isNotificationPage,
                        })}
                      >
                        <DropdownMenuItem
                          disabled={isNotificationPage}
                          className={MENU_ITEM_CLASSES}
                        >
                          Module Notifications
                        </DropdownMenuItem>
                      </Link>
                    )}
                    {["policy", "marketing"].includes(moduleIdentifier) &&
                      isCurrentWorkspaceActive &&
                      hasModuleWriteAccess && (
                        <Link
                          to={`/workspaces/${workspaceID}/modules/${moduleIdentifier}/manage-approvals-steps`}
                        >
                          <DropdownMenuItem className={MENU_ITEM_CLASSES}>
                            Manage Approvals
                          </DropdownMenuItem>
                        </Link>
                      )}

                    {REPORT_ENABLED_MODULES.includes(moduleIdentifier) && (
                      <Link to={`/workspace-reports/${moduleIdentifier}`}>
                        <DropdownMenuItem className={MENU_ITEM_CLASSES}>
                          Reports
                        </DropdownMenuItem>
                      </Link>
                    )}
                  </DropdownMenuContent>
                </DropdownMenu>
              </TooltipTrigger>
              <TooltipContent align="end" side="top" disableArrow>
                <p>More Options</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}
    </div>
  );
}

export default observer(HeadSelect);
