import React from "react";

import { Task } from "@/api";
import { useMainStore } from "@/contexts/Store";

export default function NameCell({ task }: { task: Task }) {
  const { taskDetail } = useMainStore();

  return (
    <div
      className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-flex tw-cursor-pointer tw-items-center tw-px-2.5 tw-py-1 hover:tw-underline"
      onClick={() => taskDetail.open(task)}
    >
      <span className="tw-truncate">{task.name}</span>
    </div>
  );
}
