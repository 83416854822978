import classNames from "classnames";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { Icon, MiniTag, Typography } from "../../Elements";
import ModalWrapper from "../../shared/ModalWrapper";
import ComplianceTemplatePreview from "./ComplianceTemplatePreview";

type ComplianceTemplateProps = {
  attachmentName: string;
  description: string;
  title: string;
  className?: string;
  previewableFileName?: string;
};

const ComplianceTemplate = ({
  title,
  description,
  attachmentName,
  className,
  previewableFileName,
}: ComplianceTemplateProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const templateClasses = classNames("complaince-template", className);
  const attachmentClasses = classNames("compliance-template__attachment", {
    "compliance-template__attachment--active": isOpen,
  });
  return (
    <div className={templateClasses}>
      <div className="compliance-template__title-wrapper">
        <Typography
          weight="bold"
          color="generalMidnightDark"
          label={title}
          type="h2"
        />
        {previewableFileName && <MiniTag label="Viewable Now" color="purple" />}
      </div>
      <Typography
        className="compliance-template__description"
        weight="semiBold"
        color="generalDarkGray"
        size="sm"
        label={description}
      />
      {previewableFileName && (
        <Popup
          modal
          trigger={
            <div className="compliance-template__attachment">
              <Typography
                weight="semiBold"
                color="brandingHighlightTurquoise"
                size="sm"
                label={attachmentName}
              />
              <Icon name="eye" />
            </div>
          }
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          open={isOpen}
          position="bottom right"
        >
          <ComplianceTemplatePreview
            fileName={previewableFileName}
            onClose={() => setIsOpen(false)}
          />
        </Popup>
      )}
      {!previewableFileName && (
        <Popup
          trigger={
            <div className={attachmentClasses}>
              <Typography
                weight="semiBold"
                color="brandingHighlightTurquoise"
                size="sm"
                label={attachmentName}
              />
              <Icon name="info" />
            </div>
          }
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          position="bottom right"
        >
          <ModalWrapper>
            <Typography
              weight="semiBold"
              color="generalDarkGray"
              size="sm"
              label="Please contact us via"
            />
            <a
              href="mailto:hello@themis.com"
              target="_blank"
              rel="noreferrer noopener"
              className="compliance-template__email"
            >
              <Typography
                weight="semiBold"
                color="brandingHighlightTurquoise"
                size="lg"
                label="hello@themis.com"
              />
            </a>
            <Typography
              weight="semiBold"
              color="generalDarkGray"
              size="sm"
              label="to purchase a subscription to access this document or a free trial of internal workspace modules. "
            />
          </ModalWrapper>
        </Popup>
      )}
    </div>
  );
};

export default ComplianceTemplate;
