import { kebabCase } from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { MAX_CONTROL_COUNT } from "@/components/detailView/constants";
import { Button, Icon, MiniTag } from "@/components/Elements";
import PermissionPopup from "@/components/shared/permission-popup/PermissionPopup";
import { useMainStore } from "@/contexts/Store";

type Props = {
  moduleIdentifier: string;
  recordVersionID: number;
  isLocked: boolean;
};

function ViewOperationalControlSummary({
  moduleIdentifier,
  recordVersionID,
  isLocked,
}: Props) {
  // Import Mobx stores
  const mainStore = useMainStore();

  // Variables
  const listRecordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  );
  const { workspaceID } = mainStore.context;
  const recordVersion = listRecordVersion || mainStore.recordVersions.current;
  const controlMappingRecordVersions =
    // @ts-expect-error TS(2339) FIXME: Property 'owned_control' does not exist on type 'R... Remove this comment to see the full error message
    recordVersion?.record?.owned_control?.control_mapping_record_versions;
  const mwList = mainStore.moduleWorkspaces.list;
  const operationalControlsURL = () => {
    if (moduleIdentifier === "risk_register") {
      return `/workspaces/${workspaceID}/modules/${kebabCase(
        moduleIdentifier,
      )}/${recordVersionID}/controls`;
    }
    return `/workspaces/${workspaceID}/modules/${kebabCase(
      moduleIdentifier,
    )}/${recordVersionID}/operational-controls`;
  };

  // Hooks
  const history = useHistory();

  // State
  const [visibleControlRecordCount, setVisibleControlRecordCount] =
    useState(MAX_CONTROL_COUNT);

  // Functions
  function handleShowMore() {
    setVisibleControlRecordCount(visibleControlRecordCount + MAX_CONTROL_COUNT);
  }

  return (
    <section className="section-approval">
      <div className="section-header">
        <h3>Operational Controls Summary</h3>
        <Link to={operationalControlsURL}>
          <Button
            theme="tertiary"
            size="sm"
            label="Select Operational Controls"
          />
        </Link>
      </div>
      <div className="section-content">
        {!controlMappingRecordVersions?.length && (
          <div className="table-row empty">
            {isLocked
              ? "No Operational Controls."
              : "No Operational Controls added yet. Click “Operational Controls” on top / “Select Operational Controls” button to start with."}
          </div>
        )}

        {controlMappingRecordVersions
          ?.slice(0, visibleControlRecordCount)
          // @ts-expect-error TS(7006) FIXME: Parameter 'record' implicitly has an 'any' type.
          ?.map((record) => {
            const moduleURL = `/workspaces/${workspaceID}/modules/control-mapping/${record.id}`;
            const moduleAccess = mwList.find(
              (mw) =>
                mw.added && mw.themis_module.identifier === "control_mapping",
            );

            return (
              <div key={record.id} className="table-row">
                <div className="dv-rr-controls-row">
                  <PermissionPopup
                    trigger={
                      <div className="dv-rr-controls-row__trigger-wrap">
                        <div
                          className="dv-rr-controls-row__trigger"
                          onClick={() =>
                            !!moduleAccess && history.push(moduleURL)
                          }
                        >
                          <MiniTag label={record.global_id} theme="navy" />
                          <div className="dv-rr-controls-row__link">
                            {record.meta?.name}
                          </div>
                          <Icon
                            name="chevronRight"
                            className="dv-rr-controls-row__arrow"
                          />
                        </div>
                      </div>
                    }
                    disabled={!!moduleAccess}
                  />
                </div>
              </div>
            );
          })}

        {controlMappingRecordVersions?.length > visibleControlRecordCount && (
          <div className="table-row">
            <div
              className="dv-rr-controls-row cursor-pointer"
              onClick={handleShowMore}
            >
              {`+ ${
                controlMappingRecordVersions.length - visibleControlRecordCount
              } more operational controls`}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default observer(ViewOperationalControlSummary);
