import { observer } from "mobx-react";
import React from "react";

import { RecordVersion, Review } from "@/api";
import { useMainStore } from "@/contexts/Store";

import CreativeViewReviewers from "./CreativeViewReviewers";

type Props = {
  recordVersion: RecordVersion;
};

function CreativeViewPendingReviewers({ recordVersion }: Props) {
  const mainStore = useMainStore();

  const reviews: Review[] = recordVersion?.reviews || [];
  const { allUsers } = mainStore.users;

  const approvedUsers = allUsers.filter(
    (user) => recordVersion?.data?.approvers?.ids.includes(user.id),
  );

  const reviewUserIds = reviews
    .filter((review) => review.review_type === "approval")
    .map((review) => review.user_id);

  const reviewers = approvedUsers.filter(
    (user) => !reviewUserIds.includes(user.id),
  );

  const pendingReviewers = reviewers?.length < 1 ? approvedUsers : reviewers;

  return (
    pendingReviewers?.length > 0 && (
      <div className="tw-flex tw-items-center tw-justify-center ">
        <CreativeViewReviewers
          reviewers={pendingReviewers}
          text="Pending Review From"
        />
      </div>
    )
  );
}

export default observer(CreativeViewPendingReviewers);
