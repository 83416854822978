import {
  Button,
  Label,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Select,
  TextInput,
} from "@themis/ui";
import classNames from "classnames";
import React, { useState } from "react";
import { ControllerRenderProps, FieldError } from "react-hook-form";

import { ContractInfoSchema } from "./ContractInfoForm";

const CUSTOM_TERMS = "custom";

const TERMS_OPTIONS = [
  { value: "0", label: "On termination date" },
  { value: "30", label: "30" },
  { value: "60", label: "60" },
  { value: "90", label: "90" },
  { value: CUSTOM_TERMS, label: "Custom Value" },
];

export function ContractTermsSelect({
  field,
  fieldError,
}: {
  field: ControllerRenderProps<ContractInfoSchema, "terms">;
  fieldError?: FieldError;
}) {
  const [customValue, setCustomValue] = useState("");
  const [customInputPopupOpen, setCustomInputPopupOpen] = useState(false);

  function handleClose() {
    setCustomInputPopupOpen(false);
  }

  return (
    <>
      <Select
        className={classNames({
          "tw-bg-warning-50 tw-text-warning-300": fieldError,
        })}
        items={TERMS_OPTIONS}
        selected={field.value}
        onSelect={(value) => {
          if (value === CUSTOM_TERMS) {
            setCustomInputPopupOpen(true);

            return;
          }

          field.onChange(value);
        }}
        renderSelected={() =>
          Number(field.value)
            ? `${field.value} days before termination`
            : "On termination date"
        }
      />
      <Popover open={customInputPopupOpen}>
        <PopoverAnchor />
        <PopoverContent onEscapeKeyDown={handleClose} align="start">
          <div className="tw-box-border tw-flex tw-w-72 tw-flex-col tw-gap-1 tw-px-4 tw-py-3">
            <h4 className="tw-text-primaryDim-400">Custom Value</h4>
            <Label>Please enter an integer value</Label>
            <TextInput
              onChange={(e) => {
                // Allow only digits
                setCustomValue(e.target.value.replace(/[^\d]/g, ""));
              }}
              value={customValue}
            />
          </div>
          <div className="tw-h-px tw-bg-neutral-100" />
          <div className="tw-flex tw-items-center tw-gap-2 tw-px-4 tw-py-3">
            <Button
              onClick={() => {
                field.onChange(customValue);
                handleClose();
              }}
            >
              Confirm
            </Button>
            <Button color="tertiary" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
}
