import classNames from "classnames";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import draggableIcon from "../../../../images/table-image/icon/draggable-icon.svg";
import trashIcon from "../../../../images/table-image/icon/trash-icon.svg";

type Props = {
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleMoveItem?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleRemove?: (...args: any[]) => any;
};

function ChecklistItem({
  item,
  index,
  disabled,
  handleMoveItem,
  handleChange,
  handleRemove,
}: Props) {
  // D&D
  const ref = useRef();

  const [{ handlerId }, drop] = useDrop({
    accept: "item",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(listItem, monitor) {
      if (!ref.current) {
        return;
      }

      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      const dragIndex = listItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      // @ts-expect-error TS(2339) FIXME: Property 'getBoundingClientRect' does not exist on... Remove this comment to see the full error message
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      handleMoveItem(dragIndex, hoverIndex);
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      listItem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "item",
    canDrag: !disabled,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const draggingClasses = classNames("checklist-item", {
    dragging: isDragging,
  });

  drag(drop(ref));

  // For auto-sizing question height
  // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
  const textAreaElement = document.getElementById(index);
  if (textAreaElement) {
    textAreaElement.setAttribute("style", "height:0px;");
    textAreaElement.setAttribute(
      "style",
      `height:${textAreaElement.scrollHeight - 22}px;overflow-y:hidden;`,
    );
  }

  return (
    <div
      // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
      ref={ref}
      data-testid="checklist-item"
      data-handler-id={handlerId}
      className={draggingClasses}
    >
      <img className="draggable-icon" src={draggableIcon} alt="draggable" />
      <div>
        <textarea
          // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'string'.
          id={index}
          className="checklist-item-input checklist-item-input-custom-column"
          data-testid="checklist-item-input"
          disabled={item.category || disabled}
          value={item.name}
          onChange={handleChange}
        />
        {!disabled && (
          <img
            className="trash-icon"
            data-testid="checklist-item-remove"
            src={trashIcon}
            alt="trash"
            onClick={handleRemove}
          />
        )}
      </div>
    </div>
  );
}

ChecklistItem.defaultProps = {
  disabled: false,
  handleMoveItem: () => {},
  handleChange: () => {},
  handleRemove: () => {},
};

export default ChecklistItem;
