import { cn, Tabs, TabsContent, TabsList, TabsTrigger } from "@themis/ui";
import React, { useMemo, useState } from "react";

import { DashboardComponent } from "@/api";

import { BarChart } from "../charts/BarChart";

export default function LibraryRecords({
  widgetData,
  widgetCloseData,
  widgetCompleteData,
  widgetAvgData,
}: {
  widgetData: DashboardComponent["data"];
  widgetCloseData: DashboardComponent["data"];
  widgetCompleteData: DashboardComponent["data"];
  widgetAvgData: DashboardComponent["data"];
}) {
  const [tabName, setTabName] = useState("policies");

  function exportData(data: { [key: string]: number }) {
    return {
      count:
        Object.keys(data)
          .map((key) => data[key], 0)
          .reduce((a, b) => a + b) || 0,
      data: Object.keys(data).map((key) => ({
        name: key
          .replace(/_/g, " ")
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" "),
        value: data[key],
        color: "#8F92F9",
      })),
    };
  }
  const mutated = useMemo(() => {
    if (!widgetData) {
      return {
        policies: {
          count: 0,
          data: [],
        },
        procedures: {
          count: 0,
          data: [],
        },
        documents: {
          count: 0,
          data: [],
        },
      };
    }
    return {
      policies: exportData(widgetData.policy),
      procedures: exportData(widgetData.procedures),
      documents: exportData(widgetData.documents),
    };
  }, [widgetData]);

  const tabContent = (
    closeRecords: string,
    completionRecords: string,
    avgRecords: string,
    mutatedData: { name: string; value: number; color: string }[],
  ) => {
    const isEmptyState =
      closeRecords === "0 / 0" &&
      completionRecords === "0%" &&
      avgRecords === "0 days";
    return (
      <div className="box-border tw-flex tw-h-[204px] tw-w-full tw-flex-row tw-items-start tw-justify-start">
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-start tw-gap-4 tw-px-32 tw-py-8">
          <div className="tw-w-min-[250px] tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-4">
            <div
              className={cn(
                "tw-w-[90px] tw-text-center tw-text-xl tw-font-semibold",
                {
                  "tw-text-neutral-500": !isEmptyState,
                  "tw-text-neutral-200": isEmptyState,
                },
              )}
            >
              {closeRecords}
            </div>
            <div className="tw-w-[150px] tw-text-left tw-text-xs tw-font-medium tw-text-neutral-300">
              Close Records
            </div>
          </div>
          <div className="tw-w-min-[200px] tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-4">
            <div
              className={cn(
                "tw-w-[90px] tw-text-center tw-text-xl tw-font-semibold",
                {
                  "tw-text-neutral-500": !isEmptyState,
                  "tw-text-neutral-200": isEmptyState,
                },
              )}
            >
              {completionRecords}
            </div>
            <div className="tw-w-[150px] tw-text-left tw-text-xs tw-font-medium tw-text-neutral-300">
              Completion
            </div>
          </div>
          <div className="tw-w-min-[200px] tw-flex tw-flex-row tw-items-center tw-justify-start tw-gap-4">
            <div
              className={cn(
                "tw-w-[90px] tw-text-center tw-text-xl tw-font-semibold",
                {
                  "tw-text-neutral-500": !isEmptyState,
                  "tw-text-neutral-200": isEmptyState,
                },
              )}
            >
              {avgRecords}
            </div>
            <div className="tw-w-[150px] tw-text-left tw-text-xs tw-font-medium tw-text-neutral-300">
              Avg. time to close
            </div>
          </div>
        </div>
        <div className="tw-flex tw-flex-1 tw-flex-col tw-justify-start tw-gap-8 tw-self-stretch tw-text-center">
          <BarChart data={mutatedData} />
        </div>
      </div>
    );
  };

  return (
    <div className="tw-flex tw-h-[260px] tw-min-w-[400px] tw-flex-grow tw-basis-0 tw-flex-col tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Library Records
        </div>
      </div>
      <div>
        <Tabs
          value={tabName}
          onValueChange={setTabName}
          className="tw-border-0"
        >
          <TabsList className="tw-h-[34px] tw-gap-4 tw-rounded-none tw-border-0 tw-border-b-2 tw-border-solid tw-border-primaryDim-100 tw-pb-0">
            <TabsTrigger
              value="policies"
              className="tw-rounded-none tw-p-0 tw-text-xs tw-font-medium tw-text-neutral-300 hover:tw-bg-primaryDim-25  data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
            >
              {mutated.policies.count} Policies
            </TabsTrigger>
            <TabsTrigger
              value="procedures"
              className="tw-rounded-none tw-p-0 tw-text-xs tw-font-medium tw-text-neutral-300 hover:tw-bg-primaryDim-25  data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
            >
              {mutated.procedures.count} Procedures
            </TabsTrigger>
            <TabsTrigger
              value="documents"
              className="tw-rounded-none tw-p-0 tw-text-xs tw-font-medium tw-text-neutral-300 hover:tw-bg-primaryDim-25  data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
            >
              {mutated.documents.count} Documents
            </TabsTrigger>
          </TabsList>
          <TabsContent value="policies">
            {tabContent(
              widgetCloseData?.policy?.value,
              widgetCompleteData?.policy?.value,
              widgetAvgData?.policy?.value,
              mutated.policies.data,
            )}
          </TabsContent>
          <TabsContent value="procedures">
            {tabContent(
              widgetCloseData?.procedures?.value,
              widgetCompleteData?.procedures?.value,
              widgetAvgData?.procedures?.value,
              mutated.procedures.data,
            )}
          </TabsContent>
          <TabsContent value="documents">
            {tabContent(
              widgetCloseData?.documents?.value,
              widgetCompleteData?.documents?.value,
              widgetAvgData?.documents?.value,
              mutated.documents.data,
            )}
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
