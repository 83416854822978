import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import MainCategoriesSelect from "../cell-type/MainCategoriesSelect";

type Props = {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  recordVersionID: number;
  fieldName?: string;
  locked?: boolean;
};

function ViewMainCategoriesSelect({
  disabled,
  recordVersion,
  fieldName,
  recordVersionID,
  locked,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const selectedOptions = mainStore.avroSchemas.valueForField(
    fieldName,
    recordVersion.data,
  );
  const cellError = mainStore.recordVersions.errorsForField(
    recordVersion.id,
    fieldName,
  );
  const hasErrors = Boolean(cellError);

  return (
    <MainCategoriesSelect
      disabled={disabled}
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      fieldName={fieldName}
      recordVersionID={recordVersionID}
      selectedOptions={selectedOptions}
      width="100%"
      hasErrors={hasErrors}
      errorMessage={cellError?.description}
      locked={locked}
      l1ID={mainStore.avroSchemas.firstValueForField(
        "l1_risk_taxonomy",
        recordVersion?.data,
      )}
      hasErrorClass="detail-view-has-errors"
    />
  );
}

export default observer(ViewMainCategoriesSelect);
