import React from "react";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";

import { MiniTag, Toggle } from "@/components/Elements";

export function NotificationDetailsHeader<T extends FieldValues>({
  headerName = "Notification Details",
  ...props
}: { headerName?: string } & UseControllerProps<T>) {
  return (
    <div className="notification-details--header tw-flex tw-content-center tw-justify-between">
      <div className="tw-text-lg tw-font-semibold tw-text-neutral-500">
        {" "}
        {headerName}{" "}
      </div>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field: { value, onChange } }) => (
          <div className="tw-flex tw-gap-2">
            <MiniTag label="Disabled" theme={value ? "gray" : "red"} />
            <Toggle size="sm" active={value} onChange={onChange} />
            <MiniTag label="Enabled" theme={value ? "green" : "gray"} />
          </div>
        )}
      />
    </div>
  );
}
