import { observer } from "mobx-react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import trashIcon from "../../../images/table-image/icon/delete-icon.svg";
import ConfirmationDialog from "../shared/ConfirmationDialog";

type Props = {
  recordID: number;
};

function IMRelatedRisksActions({ recordID }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);

  // vars
  const { canDeleteRecords } = mainStore.userPermissions;
  const { selectedIDs: selectedRecorsIDs } =
    mainStore.issueManagement.relatedRisksData;
  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id: recordVersionID } = useParams();

  // Functions
  function handleDeleteConfirm() {
    const newSelectedRecordsIDs = selectedRecorsIDs.filter(
      (item) => item !== recordID,
    );
    handleSave(newSelectedRecordsIDs);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  function handleSave(ids) {
    mainStore.issueManagement.setRelatedRisksData(ids);
    mainStore.issueManagement.saveRelatedRisks(recordVersionID);
  }

  return (
    <div
      className="list-points rr-library-table-row-actions"
      data-testid="im-related-risks-actions-container"
    >
      <Popup
        position="bottom right"
        open={isOpenModal}
        trigger={() => (
          <button
            type="button"
            data-testid="im-related-risks-actions-delete-trigger"
            disabled={!canDeleteRecords}
          >
            <img src={trashIcon} alt="trash-icon" />
          </button>
        )}
        disabled={!canDeleteRecords}
        onOpen={() => setIsOpenModal(true)}
        onClose={() => setIsOpenModal(false)}
      >
        <div
          className="table-dropdown success-dropdown"
          data-testid="im-related-risks-actions-delete-popup"
        >
          <ConfirmationDialog
            heading="Delete Risk Item"
            content="Are you sure you want to delete this risk item? Actions are not reversible."
            handleConfirm={handleDeleteConfirm}
            handleReject={() => setIsOpenModal(false)}
          />
        </div>
      </Popup>
    </div>
  );
}

export default observer(IMRelatedRisksActions);
