import { Popover, PopoverAnchor, PopoverContent } from "@themis/ui";
import React from "react";

type TooltipProps = {
  value: string;
  defaultOpen?: boolean;
  children?: React.ReactNode;
};

const CustomTooltip = ({ value, defaultOpen, children }: TooltipProps) => {
  const [isOpen, setIsOpen] = React.useState(!children || !!defaultOpen);

  return (
    <Popover open={isOpen}>
      {children ? (
        <PopoverAnchor>
          <div
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
          >
            {children}
          </div>
        </PopoverAnchor>
      ) : (
        <PopoverAnchor />
      )}
      <PopoverContent align="center" side="bottom">
        <div className="tw-max-w-[200px] tw-overflow-hidden tw-rounded-md tw-bg-neutral-500 tw-px-4 tw-py-2 tw-font-sans tw-text-xs tw-text-neutral-25 tw-shadow-dropdown">
          {value}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default CustomTooltip;
