import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import { Icon } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import { useModuleDetection } from "@/hooks/useModuleDetection";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";

import { EMPTY_OPTIONS } from "./constants";
import FiltersAddNew from "./FiltersAddNew";
import FiltersAppliedItem from "./FiltersAppliedItem";
import FiltersDateApplied from "./FiltersDateApplied";
import { Filter, FilterOption } from "./types";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (...args: any[]) => any;
};

function FiltersContent({ fields }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const location = useLocation();
  const {
    setTableFiltersParam,
    clearTableFilters,
    isFieldGroupPresent,
    isFilterOptionPresent,
    getTableFiltersParam,
  } = useUpdateFilter();

  // vars
  const filters = getTableFiltersParam();
  const filtersAreEmpty = filters.length === 0;
  const identifier = useModuleDetection(location);
  const isSearch = location.pathname.includes("search");

  // funcs
  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
  function findRelatedField(fieldName) {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    return fields.find((item) => item.name === fieldName);
  }

  function handleToggleFilterOption(
    filterOption: FilterOption,
    fieldName: string,
  ) {
    const field = findRelatedField(fieldName);
    const filterGroupExist = isFieldGroupPresent(field);
    // filter group does not exist -> create new group
    if (!filterGroupExist) {
      return setTableFiltersParam([
        ...filters,
        {
          name: fieldName,
          condition: mainStore.filters.getConditionText(field.data_type, true),
          options: [filterOption],
        },
      ]);
    }

    const filterOptionExists = isFilterOptionPresent(filterOption, fieldName);
    // filter exists
    if (filterOptionExists) {
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      const existingFilter = filters.find((item) => item.name === fieldName);
      const hasDisputeAmountFinra =
        fieldName === "dispute_amount" && identifier === "finra";
      // it was last filter option in group -> remove group
      if (existingFilter?.options?.length === 1) {
        return handleRemoveFilter(filterOption, fieldName);
      }

      // it was not last filter in group -> remove filter option, but keep the group
      return setTableFiltersParam(
        filters.map((item: Filter) =>
          item.name === fieldName
            ? {
                ...item,
                options: item.options.filter((option: FilterOption) =>
                  hasDisputeAmountFinra
                    ? option.label !== filterOption.label
                    : option.value !== filterOption.value,
                ),
              }
            : item,
        ),
      );
    }
    // filter does not exist -> add/replace filter in this group
    setTableFiltersParam(
      filters.map((item: Filter) =>
        item.name === fieldName
          ? {
              ...item,
              options: [...item.options, filterOption],
            }
          : item,
      ),
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'filter' implicitly has an 'any' type.
  function handleRemoveFilter(filter, fieldName) {
    setTableFiltersParam(
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      filters.filter((item) => item.name !== fieldName),
      false,
      true,
      true,
    );
  }

  function addEmptyCondition(condition: string, fieldName: string) {
    const updatedFilters = [
      ...filters,
      {
        name: fieldName,
        condition,
        options: [{ name: fieldName }],
      },
    ];

    setTableFiltersParam(updatedFilters, false, true);
  }

  function handleChangeCondition(
    filter: Filter,
    condition: string,
    fieldName = "",
  ) {
    const isEmptyCondition = EMPTY_OPTIONS.includes(condition);

    if (!filter && isEmptyCondition) {
      return addEmptyCondition(condition, fieldName);
    }

    let updatedFilter = { ...filter, condition };

    if (!isEmptyCondition && EMPTY_OPTIONS.includes(filter.condition)) {
      updatedFilter = {
        ...filter,
        condition,
        options: [],
      };
    } else if (isEmptyCondition) {
      updatedFilter = {
        ...filter,
        condition,
        options: [{ name: filter.name, condition }],
      };
    }

    setTableFiltersParam(
      filters.map(
        // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
        (item) => (item.name === filter.name ? updatedFilter : item),
      ),
    );
  }

  const renderClearAll = (
    <button
      type="button"
      className="filters-clear-all"
      onClick={clearTableFilters}
      disabled={filtersAreEmpty}
      data-testid="filters-content-clear-all"
    >
      <Icon name="trash" color="generalDark" size="de" />
      Clear All
    </button>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'filterItem' implicitly has an 'any' typ... Remove this comment to see the full error message
  const isDateFieldName = (filterItem) => {
    const field = mainStore.fields.list.find((f) => f.name === filterItem.name);
    return field?.data_type?.includes("date");
  };
  // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
  const dateFiltersToDisplay = (option) =>
    option.condition !== "is within" ||
    (option.condition === "is within" && option.label !== "Today");

  // renders
  const renderGetStarted = (
    <p
      className="filters-get-started"
      data-testid="filters-content-get-started"
    >
      Click Add Filter to get started
    </p>
  );

  const filtersContent = (
    <div className="filters-content" data-testid="filters-content">
      <div className="filters-content-left">
        {/* Filters for date are shown with the FiltersDateApplied component */}
        {filters
          // @ts-expect-error TS(7006) FIXME: Parameter 'f' implicitly has an 'any' type.
          .filter((f) => !isDateFieldName(f))
          // @ts-expect-error TS(7006) FIXME: Parameter 'filter' implicitly has an 'any' type.
          .map((filter) => {
            const field = findRelatedField(filter.name);
            return field ? (
              <FiltersAppliedItem
                key={filter.name}
                field={field}
                filter={filter}
                onChangeCondition={handleChangeCondition}
                onToggleFilterOption={handleToggleFilterOption}
                onRemoveFilter={handleRemoveFilter}
              />
            ) : null;
          })}
        {filters
          .filter((f: Filter) => isDateFieldName(f))
          .map(
            (filter: Filter) =>
              filter.options
                ?.filter((option: FilterOption) => dateFiltersToDisplay(option))
                .map((filterOption, index) => (
                  <FiltersDateApplied
                    key={`${filter.name}-${index}`}
                    filterOption={filterOption}
                    fieldName={filter.name as string}
                  />
                )),
          )}
        <FiltersAddNew
          fields={fields}
          onAddFilter={handleToggleFilterOption}
          handleChangeCondition={handleChangeCondition}
        />
        {filtersAreEmpty && renderGetStarted}
      </div>
      <div className="filters-content-right">{renderClearAll}</div>
    </div>
  );

  return !isSearch ? filtersContent : null;
}

FiltersContent.defaultProps = {
  fields: [],
  onClose: () => {},
};

export default observer(FiltersContent);
