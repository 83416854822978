import classNames from "classnames";
import React from "react";

import closeIcon from "@/images/table-image/icon/close-black-icon.svg";

type Props = {
  children?: React.ReactNode;
  closeOutsideClick?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (...args: any[]) => any;
  title?: React.ReactNode;
};

function Drawer({ title, children, closeOutsideClick, onClose }: Props) {
  return (
    <>
      <div className="drawer" data-testid="drawer">
        <div className="drawer-header">
          {title}
          <div
            className="drawer-close-button"
            data-testid="drawer-close-button"
            onClick={onClose}
          >
            <img src={closeIcon} />
          </div>
        </div>

        <div className="drawer-content">{children}</div>
      </div>
      <div
        className={classNames("drawer-backdrop", {
          clickable: closeOutsideClick,
        })}
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onClick={() => closeOutsideClick && onClose()}
      />
    </>
  );
}

Drawer.defaultProps = {
  closeOutsideClick: true,
};

export default Drawer;
