import { Draggable } from "@akojic27/react-beautiful-dnd";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";

import { RiskAssessmentRiskRating } from "@/stores/types/vendor-types";

import draggableIcon from "../../../../images/table-image/icon/draggable-icon.svg";
import { IconButton } from "../../../Elements";
import { RISK_RATINGS_COLORS } from "../constants";

type Props = {
  rating?: RiskAssessmentRiskRating;
  index?: number;
  ratingsLength?: number;
  handleUpdateRating?: (index: number, data: object) => void;
  handleRemoveRating?: (index: number, id?: number) => void;
};

const RiskAssessmentMethodologyRow = ({
  rating,
  index,
  ratingsLength,
  handleUpdateRating,
  handleRemoveRating,
}: Props) => {
  // vars
  // @ts-expect-error TS(7006) FIXME: Parameter 'localDisabled' implicitly has an 'any' ... Remove this comment to see the full error message
  const classes = (localDisabled) =>
    classNames("risk-methodology-builder__content__risk-rating-value", {
      "risk-methodology-builder__content__risk-rating-value__disabled":
        localDisabled,
    });
  // @ts-expect-error TS(7006) FIXME: Parameter 'selected' implicitly has an 'any' type.
  const colorClasses = (selected) =>
    classNames("risk-methodology-builder__content__risk-rating-color", {
      "risk-methodology-builder__content__risk-rating-color__selected":
        selected,
    });

  // funcs
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function setName(e) {
    const { value: name } = e.target;

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleUpdateRating(index, { name });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'colorIdx' implicitly has an 'any' type.
  function setSelectedColor(colorIdx) {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleUpdateRating(index, { color_index: colorIdx });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function setLowerRange(e) {
    const { value } = e.target;

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleUpdateRating(index, { lower_range: Number(value) });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function setHigherRange(e) {
    const { value } = e.target;

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleUpdateRating(index, { higher_range: Number(value) });
  }

  return (
    <Draggable
      draggableId={`risk-rating-${index}`}
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      index={index}
    >
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div className="input-group-row">
            <hr />
          </div>
          <div className="input-group-row">
            <div className="drag-handler" {...provided.dragHandleProps}>
              <img
                className="draggable-icon"
                src={draggableIcon}
                alt="draggable"
              />
            </div>
            <div className="risk-methodology-builder__content__risk-rating-input-area">
              <span>
                <textarea
                  className="risk-methodology-builder__content__risk-rating-name"
                  onChange={setName}
                  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                  value={rating.name}
                />
                <span className="risk-area-colors-wrapper">
                  <span>
                    {RISK_RATINGS_COLORS.map((colorSet, colorIdx) => (
                      <div
                        className={colorClasses(
                          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                          rating.color_index === colorIdx,
                        )}
                        style={{ backgroundColor: colorSet.background }}
                        key={colorSet.background}
                        onClick={() => setSelectedColor(colorIdx)}
                      />
                    ))}
                  </span>
                </span>
              </span>
              <span className="risk-methodology-builder__content__risk-rating-value-area">
                <span className="risk-rating-range-label">Range, %</span>
                <input
                  type="number"
                  min="1"
                  max="100"
                  className={classes(index === 0)}
                  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                  value={index === 0 ? 1 : rating.lower_range}
                  onChange={setLowerRange}
                  disabled={index === 0}
                />
                <span className="risk-rating-range-label risk-rating-range-label__dash">
                  {" "}
                  -{" "}
                </span>
                <input
                  type="number"
                  min="1"
                  max="100"
                  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                  className={classes(index === ratingsLength - 1)}
                  value={
                    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                    index === ratingsLength - 1 ? 100 : rating.higher_range
                  }
                  onChange={setHigherRange}
                  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                  disabled={index === ratingsLength - 1}
                />
                <IconButton
                  // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                  onClick={() => handleRemoveRating(index, rating.id)}
                  icon="trash"
                />
              </span>
            </div>
          </div>
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          {index === ratingsLength - 1 && (
            <div className="input-group-row">
              <hr />
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default observer(RiskAssessmentMethodologyRow);
