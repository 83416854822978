import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { Icon } from "../../Elements";
import WorkspaceItem from "./WorkspaceItem";

const ManageWorkspaces = () => {
  const mainStore = useMainStore();

  const { selectedWorkspaceIDs, selectAllWorkspaces } = mainStore.reports;
  const workspaces = mainStore.workspaces.list;
  const { isAdmin } = mainStore.context;

  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (selectAllWorkspaces) {
      const allWorkspaceIds = workspaces.map((workspace) => workspace.id);
      mainStore.reports.setSelectedWorkspaceIDs(allWorkspaceIds);
    }
  }, [selectAllWorkspaces, workspaces]);

  const toggleSelectAllWorkspace = useCallback(() => {
    mainStore.reports.setSelectAllWorkspaces(!selectAllWorkspaces);
  }, [selectAllWorkspaces]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleSearchWorkspace = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    mainStore.reports.updateRefetchRecordsCount();
  }, []);

  const handleWorkspaceSelection = useCallback(
    (workspaceId: number) => {
      let updatedWorkspaceIds: Array<string | number>;

      if (selectedWorkspaceIDs.includes(workspaceId)) {
        if (selectedWorkspaceIDs.length === 1) {
          return mainStore.toast.setInfoText(
            "At least one workspace must be selected.",
          );
        }
        updatedWorkspaceIds = selectedWorkspaceIDs.filter(
          (wsID) => wsID !== workspaceId,
        );
      } else {
        updatedWorkspaceIds = [...selectedWorkspaceIDs, workspaceId];
      }

      mainStore.reports.setSelectedWorkspaceIDs(updatedWorkspaceIds);
    },
    [workspaces, selectedWorkspaceIDs],
  );

  const isChecked = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    (id) => selectedWorkspaceIDs.includes(id),
    [selectedWorkspaceIDs],
  );

  const filteredWorkspaces = useMemo(
    () =>
      workspaces.filter((workspace) =>
        workspace.name.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    [workspaces, searchQuery],
  );

  return (
    <Popup
      trigger={() => (
        <div
          className={classNames("workspace-select-trigger", {
            highlighted: open,
          })}
          data-testid="select-workspaces-trigger"
        >
          <Icon
            name={open ? "settingsWhite" : "settings"}
            className="trigger-icon"
          />
          <span>Manage</span>
        </div>
      )}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      position="bottom right"
    >
      <div className="table-dropdown workspace-select-dropdown">
        {isAdmin && (
          <div className="all-workspaces-selected">
            All Workspaces
            <div className="toggle-workspace">
              <label className="switch">
                <input
                  checked={selectAllWorkspaces}
                  type="checkbox"
                  data-testid="toggle-all-workspaces"
                  onChange={toggleSelectAllWorkspace}
                />
                <span className="slider" />
              </label>
            </div>
          </div>
        )}
        <div>
          <div>
            <input
              className="search-workspaces"
              type="search"
              data-testid="users-select-search-input"
              placeholder="- Search here -"
              value={searchQuery}
              onChange={handleSearchWorkspace}
            />
          </div>
          <div className="workspace-list-container">
            <div className="workspace-list">
              {filteredWorkspaces.map((workspace) => (
                <WorkspaceItem
                  showCheckbox
                  key={workspace.id}
                  workspace={workspace}
                  checked={isChecked(workspace.id)}
                  onChange={handleWorkspaceSelection}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default observer(ManageWorkspaces);
