import React, { SVGProps } from "react";

const Play = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2875 10.7339L6.78 2.47079C6.55227 2.33133 6.29144 2.25521 6.02445 2.25026C5.75747 2.24532 5.49399 2.31173 5.26125 2.44266C5.03073 2.57154 4.8387 2.75951 4.7049 2.98722C4.57112 3.21494 4.50039 3.47418 4.5 3.73829V20.2626C4.50174 20.6589 4.66073 21.0381 4.94199 21.3171C5.22327 21.5961 5.60384 21.752 6 21.7505C6.27652 21.7503 6.54767 21.6741 6.78375 21.5301L20.2875 13.267C20.5045 13.1347 20.684 12.9488 20.8085 12.7272C20.933 12.5055 20.9984 12.2556 20.9984 12.0014C20.9984 11.7472 20.933 11.4973 20.8085 11.2756C20.684 11.054 20.5045 10.8681 20.2875 10.7358V10.7339ZM6 20.2449V3.75047L19.4841 12.0005L6 20.2449Z"
      fill="#353549"
    />
  </svg>
);

Play.displayName = "PlayIcon";

export default Play;
