import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { Icon } from "../../Elements";
import { INHERENT_MATRIX_TITLE } from "../risk-register/Constants";

type Props = {
  fieldName: string;
  hasErrors: boolean;
  isUserEditable: boolean;
  recordVersionID: number;
  width: number | string;
  errorMessage?: string;
  hasErrorClass?: string;
  locked?: boolean;
  pinned?: boolean;
  value?: number;
};

function RiskRegisterInherentValuesCell({
  fieldName,
  recordVersionID,
  width,
  isUserEditable,
  hasErrors,
  errorMessage,
  value,
  locked,
  hasErrorClass,
  pinned,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [newValue, setNewValue] = useState(null);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

  // Variables
  const { data } = mainStore.riskRegisters;
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;
  const matrix = data.scoring_matrices?.find(
    (item) => item.name === INHERENT_MATRIX_TITLE,
  );
  const options =
    fieldName === "impact"
      ? matrix?.scoring_matrix_rows.slice().reverse()
      : matrix?.scoring_matrix_columns;
  const selectedOption = value
    ? options?.find((_, index) => index === value - 1)
    : null;
  const style = { width };
  const liClassNames = classNames("cell column-options-cell", {
    active: isSelectModalOpen,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [hasErrorClass]: hasErrors,
    "read-only": !isUserEditable && isCurrentWorkspaceActive,
    illumination: selectedOption,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "table-cell--disabled": isReadOnly,
    "pointer-events-none": locked || isReadOnly,
    pinned,
  });

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
  function addItem(index) {
    setNewValue(index);
    setShowPopup(false);
  }

  function onClose() {
    if (!newValue || newValue === value) {
      return;
    }

    mainStore.recordVersions.update({
      fieldName,
      recordVersionID,
      value: mainStore.avroSchemas.serializeValue(fieldName, [newValue]),
    });
  }

  function handlePopUpOpen() {
    setShowPopup(true);
    setIsSelectModalOpen(true);
  }

  function handlePopUpClose() {
    onClose();
    setShowPopup(false);
    setIsSelectModalOpen(false);
  }

  // elements
  const titlePlaceholder = hasErrors ? "" : `- Select ${fieldName} -`;

  const optionsPlaceholder = (
    <p
      className="options-placeholder nodata"
      data-testid="column-options-empty"
    >
      {titlePlaceholder}
    </p>
  );

  const renderTrigger = (
    <li
      className={liClassNames}
      style={style}
      data-testid="column-options-trigger"
    >
      <div className="cell-content">
        <div
          className="options column-options-container"
          data-testid="column-options-container"
        >
          {!selectedOption && optionsPlaceholder}

          {hasErrors && <div>{errorMessage}</div>}

          {!hasErrors && selectedOption && (
            <div
              className="options column-options-item"
              data-testid="column-options-item"
            >
              <span
                className="value value-type rr-inherent-value-cell-item"
                data-testid="column-options-value"
              >
                {selectedOption.title}
              </span>
            </div>
          )}
        </div>
      </div>
    </li>
  );

  const renderContent = (
    <div
      className="options-dropdown"
      data-testid="column-options-popup-content"
    >
      <div
        className="options-dropdown-list"
        data-testid="column-options-dropdown-list"
      >
        <ul>
          <div>
            {options?.length === 0 && (
              <h4 className="no-result">No result found</h4>
            )}

            {options?.map((option, index) => {
              const optionClasses = classNames({
                colored: true,
                active: selectedOption?.id === option.id,
              });

              return (
                <li
                  key={option.id}
                  className={optionClasses}
                  data-testid="column-options-dropdown-item"
                  onClick={() => addItem(index + 1)}
                >
                  <span className="item rr-inherent-value-cell-items-container">
                    <span className="rr-inherent-value-cell-item">
                      {option.title}
                    </span>
                  </span>
                  <span className="check">
                    <Icon name="check" color="generalDarkGray" size="de" />
                  </span>
                </li>
              );
            })}
          </div>
        </ul>
      </div>
    </div>
  );

  if (!isUserEditable) {
    return renderTrigger;
  }

  return (
    <Popup
      position="bottom left"
      trigger={() => renderTrigger}
      open={showPopup}
      onOpen={handlePopUpOpen}
      onClose={handlePopUpClose}
      keepTooltipInside
    >
      {renderContent}
    </Popup>
  );
}

RiskRegisterInherentValuesCell.defaultProps = {
  hasErrorClass: "has-errors",
};

export default observer(RiskRegisterInherentValuesCell);
