import dayjs from "dayjs";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { formatDate } from "../../../components/helpers/DateFormatters";
import { useMainStore } from "../../../contexts/Store";
import MonthPicker from "./MonthPicker";
import TestInfo from "./TestInfo";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersions?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sectionTags?: any[];
};

function TestsSchedule({ recordVersions, sectionTags }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const currentMonth = dayjs();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const monthlyTestsSchedule = recordVersions.filter((recordVersion) => {
    const date = mainStore.avroSchemas.valueForField(
      "start_date",
      recordVersion.data,
    );
    return (
      date &&
      dayjs(date).format("MMMM, YYYY") === selectedMonth.format("MMMM, YYYY")
    );
  });

  return (
    <div className="test-schedule-container" data-testid="tests-schedule">
      <div className="test-schedule-title">Tests Schedule for the Month</div>
      <MonthPicker
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
      />

      <div className="test-list-container">
        {monthlyTestsSchedule.length === 0 && (
          <div className="no-tests">
            Your Test Schedule is empty. Once you have set your test dates, they
            will be displayed here.
          </div>
        )}

        {monthlyTestsSchedule.length > 0 &&
          monthlyTestsSchedule.map((recordVersion) => {
            const date = mainStore.avroSchemas.valueForField(
              "start_date",
              recordVersion.data,
            );

            return (
              <TestInfo
                key={recordVersion.id}
                testName={recordVersion.meta.name}
                // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
                testDate={formatDate(date)}
                sectionTitle={
                  sectionTags?.find(
                    (section) => section.id === recordVersion.section_tag_id,
                  )?.title
                }
              />
            );
          })}
      </div>
    </div>
  );
}

export default observer(TestsSchedule);
