import classNames from "classnames";
import { observer } from "mobx-react";
import Pagination from "rc-pagination";
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'rc-p... Remove this comment to see the full error message
import EN_US from "rc-pagination/lib/locale/en_US";
import React, { useState } from "react";
import { PiCaretUpBold } from "react-icons/pi";
import { useHistory, useLocation } from "react-router-dom";

import { RecordVersion } from "@/api";
import SectionTag from "@/components/table/shared/SectionTag";
import { useMainStore } from "@/contexts/Store";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";
import { IndexParams } from "@/stores/types/module-store-types";
import { Section } from "@/stores/types/section-tags";

import { useSortingAndPagination } from "../../../hooks/useSortingAndPagination";
import { Icon } from "../../Elements";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import MenuActions from "../menu-actions/MenuActions";
import { customColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import ReorderableTable from "../shared/ReorderableTable";
import SectionPopupSettings from "../shared/SectionPopupSettings";
import Sharing from "../shared/Sharing";
import TableMenu from "../shared/tableMenu/TableMenu";

type Props = {
  currentScreen?: string;
  isDropdownOpenProp?: boolean;
  moduleWorkspaceID?: number;
  pageID?: number;
  recordVersions?: RecordVersion[];
  sectionTag?: Section;
};

function ComplaintsSection({
  sectionTag,
  isDropdownOpenProp,
  moduleWorkspaceID,
  currentScreen,
  recordVersions,
  pageID,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(isDropdownOpenProp);

  // Variables
  const { visibleFields } = mainStore.fields;
  const { tableName, isAdmin, activeWorkspace, workspaceID } =
    mainStore.context;
  const { canManageSections } = mainStore.userPermissions;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const page = mainStore.complaints.pages[pageID];
  const count =
    // @ts-expect-error TS(2339) FIXME: Property 'counts' does not exist on type '{}'.
    mainStore.complaints.data.counts &&
    // @ts-expect-error TS(2339) FIXME: Property 'counts' does not exist on type '{}'.
    mainStore.complaints.data.counts[pageID];
  const sectionTableID = `section-table-${sectionTag?.id || 0}`;
  const { getTableFiltersParam } = useUpdateFilter();
  const filters = getTableFiltersParam();

  const arrowLeftImage = (
    <Icon name="chevronLeft" color="generalMidnightDark" />
  );
  const arrowRightImage = (
    <Icon name="chevronRight" color="generalMidnightDark" />
  );

  // Hooks
  const location = useLocation();
  const history = useHistory();

  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    customColumns({
      sectionTableID,
    });

  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
  } = useSortingAndPagination({
    store: mainStore.complaints,
    page,
    sectionTagId: sectionTag?.id,
  });

  const renderRow = (recordVersion: RecordVersion, idx: number) => {
    const status = mainStore.avroSchemas.firstValueForField(
      "status",
      recordVersion?.data,
    );
    const isClosed = status === "closed";

    // @ts-expect-error TS(7006) FIXME: Parameter 'columnTitle' implicitly has an 'any' ty... Remove this comment to see the full error message
    const minDateFunction = (columnTitle) => {
      if (columnTitle === "Complaint End Date") {
        return mainStore.avroSchemas.valueForField(
          "complaint_start_date",
          recordVersion?.data,
        );
      }
    };
    // @ts-expect-error TS(7006) FIXME: Parameter 'columnTitle' implicitly has an 'any' ty... Remove this comment to see the full error message
    const maxDateFunction = (columnTitle) => {
      if (columnTitle === "Complaint Start Date") {
        return mainStore.avroSchemas.valueForField(
          "complaint_end_date",
          recordVersion?.data,
        );
      }
    };

    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        isLockedRow={isClosed}
        recordVersion={recordVersion}
        fields={visibleFields}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
        currentTableName={recordVersion.table_name}
        moduleIdentifier="complaints"
        isArchivedRow={isClosed}
        isComplaintClosed={isClosed}
        maxDateFunction={maxDateFunction}
        minDateFunction={minDateFunction}
        inSelectMenuActionMode={selectMode}
        isDraggable
        order={idx}
        isSorted={!!sortByFieldName}
      />
    );
  };

  const onPageChange = async (newPage: number) => {
    const params: IndexParams = {};
    params.workspaceID = activeWorkspace?.id;
    if (pageID !== undefined) {
      params.pages = { [pageID]: newPage };
    }
    params.tableName = tableName || undefined;
    params.fromUseSortingAndPagination = true;
    params.tab = currentScreen;
    if (sortByFieldName) {
      params.sortParams = {
        field_name: sortByFieldName,
        direction: sortByDirection || "ASC",
      };
    }

    if (filters) {
      params.tableFilters = filters;
    }

    if (sectionTag?.id) {
      params.sectionTagId = sectionTag.id;
    }

    await mainStore.complaints.index(params);
  };

  async function createNewComplaints() {
    if (showCheckbox && selectMode) {
      return;
    }

    if (tableName) {
      const recordVersion = await mainStore.complaints.create(
        tableName,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
        sectionTag?.id,
      );
      if (
        location.pathname === `/workspaces/${workspaceID}/modules/complaints` &&
        recordVersion
      ) {
        history.push(
          `/workspaces/${workspaceID}/modules/complaints/${recordVersion.id}`,
        );
      }
    }
  }

  const deleteSectionTag = () => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    mainStore.sectionTags.delete(moduleWorkspaceID, sectionTag.id);
    mainStore.toast.setText("Section has been deleted!");
  };

  const dropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const renameClick = () => {
    setIsEditMode(true);
  };

  function resetMenuActions() {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  }

  const renderFields = visibleFields.map((field) => {
    return (
      <ModuleTableColumn
        key={field.name}
        field={field}
        // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
        setSortByDirection={setSortByDirection}
        setSortByFieldName={setSortByFieldName}
        onDelete={deleteColumn}
        onRename={renameColumn}
      />
    );
  });

  return (
    <>
      {sectionTag && (
        <div className="tw-ml-[18px] tw-flex" data-testid="dropdown-section">
          <div
            className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-p-[7px] hover:tw-rounded-md hover:tw-bg-neutral-100"
            onClick={dropdownClick}
          >
            <PiCaretUpBold
              className={classNames(
                "tw-h-[18px] tw-w-[18px] tw-origin-center tw-text-neutral-500",
                {
                  "tw-rotate-180": !isDropdownOpen,
                  "tw-rotate-0 ": isDropdownOpen,
                },
              )}
            />
          </div>
          {sectionTag?.title && (
            <SectionTag
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              sectionTagID={sectionTag?.id}
              sectionTagTitle={sectionTag?.title}
            />
          )}

          {sectionTag?.editable && !isEditMode && (
            <>
              {isAdmin && (
                <Sharing
                  moduleWorkspaceID={moduleWorkspaceID}
                  objectID={sectionTag.id}
                  selectedDepartmentIDs={sectionTag.department_ids}
                  selectedUserIDs={sectionTag.user_ids}
                  tableName={tableName!}
                  allowSharing
                />
              )}

              {canManageSections && (
                <SectionPopupSettings
                  onRename={renameClick}
                  onDelete={deleteSectionTag}
                />
              )}
            </>
          )}
        </div>
      )}
      {isDropdownOpen && (
        <div
          className="dropdown-table draggable"
          data-testid="complaints-dropdown-table"
          id={sectionTableID}
        >
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="title-first-block table-column-reorder">
                  {visibleFields.length > 0 && (
                    <TableMenu
                      setShowCheckbox={setShowCheckbox}
                      resetMenuActions={resetMenuActions}
                      selectMode={selectMode}
                      setSelectMode={setSelectMode}
                    />
                  )}
                </div>
                {showCheckbox && selectMode ? (
                  <div>
                    <MenuActions resetMenuActions={resetMenuActions} />
                  </div>
                ) : (
                  <div className="list-title-table-wrap">
                    {renderFields}
                    <span className="stretch-cell" />
                  </div>
                )}
              </div>
              {!(showCheckbox && selectMode) && (
                <AddColumnButton onAdd={createColumn} />
              )}
            </ul>
          </div>
          <ReorderableTable
            recordVersions={recordVersions}
            isSorted={!!sortByFieldName}
            renderer={renderRow}
            sectionTag={sectionTag}
            onAddNewRow={
              currentScreen !== "Completed" ? createNewComplaints : undefined
            }
            newRowName={getRecordName("complaints", moduleWorkspaces, true)}
          />
          <Pagination
            onChange={onPageChange}
            current={page || 1}
            total={count}
            prevIcon={arrowLeftImage}
            nextIcon={arrowRightImage}
            showPrevNextJumpers
            locale={EN_US}
          />
        </div>
      )}
    </>
  );
}

export default observer(ComplaintsSection);
