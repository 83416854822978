import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import downloadIcon from "../../images/table-image/icon/download-white.svg";

type Props = {
  isAttestation?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onActiveStateChange?: (...args: any[]) => any;
  parentRecordVersionID?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setShowPopup?: (...args: any[]) => any;
  specificStatuses?: string[];
  subModuleDownload?: boolean;
  customTableExportFunction?: () => void;
};

function ExportTable({
  onActiveStateChange,
  setShowPopup,
  parentRecordVersionID = -1,
  specificStatuses,
  subModuleDownload,
  customTableExportFunction,
  ...props
}: Props) {
  // store
  const mainStore = useMainStore();

  // vars
  const moduleIdentifier = mainStore.context.themisModuleIdentifier;
  const { selectedRecordVersionIDs } = mainStore.dynamicTable;
  const tableName = subModuleDownload
    ? mainStore.context.subModuleTableName
    : mainStore.context.tableName;
  const location = useLocation();
  const isTrainingActiveAttestations =
    location.pathname.endsWith("/attestations") &&
    moduleIdentifier === "training";
  let tableID = subModuleDownload
    ? mainStore.context.subModuleTableID
    : mainStore.context.tableID;
  const supportedSingleTableModules: ModuleIdentifier[] = [
    "issue_management",
    "marketing",
    "documents",
    "complaints",
    "training",
    "finra",
    "customer_support",
    "new_product_approval",
    "audits",
    "conflicts_of_interest",
    "control_mapping",
    "risk_register",
    "qa_tests_development",
    "vendor_due_diligence",
    "zendesk",
    "key_risk_indicators",
  ];
  const supportedModules: ModuleIdentifier[] = [
    "policy",
    "procedures",
    ...supportedSingleTableModules,
  ];

  // effects
  useEffect(() => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onActiveStateChange(true);

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    return () => onActiveStateChange(false);
  }, []);

  // funcs
  function processTableDownload() {
    if (customTableExportFunction) {
      customTableExportFunction();
      return;
    }

    if (
      // @ts-expect-error TS(2345) FIXME: Argument of type '"vendor_due_diligence" | "audits... Remove this comment to see the full error message
      !supportedModules.includes(moduleIdentifier) ||
      // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      (moduleIdentifier === "policy" && tableName === "drafts")
    ) {
      mainStore.toast.setErrorText(
        "Export table is currently not available for this page!",
      );
      return;
    }
    // "Attestations" is not a real table, use finalized table to get record data
    const isAttestationsTable =
      tableName === "PoliciesAttestation" ||
      tableName === "ProceduresAttestation";
    const isAttestations = isAttestationsTable || props.isAttestation;
    const selectedIDs = isTrainingActiveAttestations
      ? selectedRecordVersionIDs
      : undefined;

    if (isAttestationsTable) {
      const finalizedTableName =
        moduleIdentifier === "policy" ? "Finalized" : "ProceduresFinalized";
      // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
      tableID = mainStore.tables.list.find(
        (table) => table.title === finalizedTableName,
      )?.id;
    }

    if (!tableID) {
      return;
    }

    mainStore.tables.export(
      tableID,
      isAttestations,
      parentRecordVersionID,
      selectedIDs,
      specificStatuses,
    );
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setShowPopup(false);
  }

  function onReject() {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setShowPopup(false);
  }

  // elements
  const betaMsg = (
    <p style={{ color: "#F95B46" }}>
      This feature is currently in beta and only available on certain tables.
    </p>
  );

  const baseContent = (
    <>
      <p>Click the button below to export the table to an Excel file.</p>
      {betaMsg}
      <button className="export-table-button" onClick={processTableDownload}>
        <img src={downloadIcon} alt="upload-icon" />
        Export to XLSX File
      </button>
    </>
  );

  const multiSelectContent = (
    <>
      <p>Please select the Attestations you want to export.</p>
      {betaMsg}
      <div className="export-actions">
        <button
          className="export-table-button"
          disabled={selectedRecordVersionIDs.length === 0}
          onClick={processTableDownload}
        >
          Confirm
        </button>
        <button className="import-button" onClick={onReject}>
          Reject
        </button>
      </div>
    </>
  );

  return (
    <div className="table-dropdown export-table-dropdown">
      <h3>Export Table to Excel</h3>
      {isTrainingActiveAttestations ? multiSelectContent : baseContent}
    </div>
  );
}

ExportTable.defaultProps = {
  isAttestation: false,
  onActiveStateChange: () => {},
  subModuleDownload: false,
};

export default observer(ExportTable);
