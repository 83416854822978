import { UserRole } from "@/stores/types/user-types";

export function getCanAddRemoveWorkspaces(
  isWorkspaceInternal: boolean,
  companyID: number,
  userRoles?: UserRole[],
) {
  return (
    !isWorkspaceInternal &&
    userRoles?.some(
      (role) =>
        role.permission_set.company_id === companyID &&
        (role.permission_set.data?.company?.actions?.add_remove_workspaces
          ?.state.edit ||
          role.permission_set.data?.company?.all?.state.edit),
    )
  );
}
