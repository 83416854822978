import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { Link, useParams } from "react-router-dom";

import { userColors } from "@/components/constants";
import { formatDate, stringToDate } from "@/components/helpers/DateFormatters";
import CommentsSlideMenu from "@/components/table/shared/comments/CommentsSlideMenu";
import ModuleTableColumn from "@/components/table/shared/ModuleTableColumn";
import UsersCircle from "@/components/table/shared/UsersCircle";
import { VendorQuestionnaireTableAction } from "@/components/table/vendor-due-diligence/VendorQuestionnairesTable/components/VendorQuestionnaireTableAction";
import { getStatus } from "@/components/table/vendor-due-diligence/VendorQuestionnairesTable/helpers";
import { useMainStore } from "@/contexts/Store";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questionnaires?: any; // TODO: Create Type Definition
};

function VendorQuestionnairesTable({ questionnaires = [] }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const { vendor_id: vendorID } = useParams<{ vendor_id?: string }>();

  // Variables
  const { currentVendor } = mainStore.vendors;
  const { user, users } = mainStore.users;
  const { list: workspacesList } = mainStore.workspaces;
  const { workspaceID } = mainStore.context;
  const isActive =
    !vendorID ||
    mainStore.avroSchemas.valueForField("status", currentVendor?.data) !==
      "disabled";
  const isWorkspaceInternal = user.active_workspace?.is_internal;
  const fields = [
    {
      id: 1,
      width: 310,
      data_type: "com.askthemis.types.v1.text",
      display_name: "Name",
      name: "name",
      is_user_editable: false,
    },
    {
      id: 2,
      width: 200,
      data_type: "com.askthemis.types.v1.integer",
      display_name: "Number of Questions",
      name: "number_of_questions",
      is_user_editable: false,
    },
    {
      id: 4,
      width: 200,
      data_type: "com.askthemis.types.v1.text",
      display_name: "Status",
      name: "status",
      is_user_editable: false,
    },
  ];

  if (isWorkspaceInternal) {
    fields.push({
      id: 5,
      width: 200,
      data_type: "com.askthemis.types.v1.tag_user",
      display_name: "Approver",
      name: "approver",
      is_user_editable: false,
    });
  } else {
    fields.push({
      id: 5,
      width: 200,
      data_type: "com.askthemis.types.v1.tag_user",
      display_name: "Completed User",
      name: "completed_user",
      is_user_editable: false,
    });
  }

  const sortedFields = fields.sort((a, b) => a.id - b.id);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
  function getCellValue(fieldName, questionnaire) {
    if (fieldName === "name") {
      return questionnaire.name;
    }
    if (fieldName === "number_of_questions") {
      return questionnaire.checklist_questions.length;
    }
    if (fieldName === "status") {
      const status = getStatus(questionnaire);
      let date = null;
      if (status === "Complete" && questionnaire.completed_at) {
        date = (
          <span className="completed-at-text">
            {" "}
            {formatDate(stringToDate(questionnaire.completed_at))}{" "}
          </span>
        );
      }
      return (
        <>
          <span
            className={classNames(
              `vdd-questionnaire-status ${status.toLowerCase()}`,
            )}
          >
            {status}
          </span>{" "}
          {date}
        </>
      );
    }
    if (fieldName === "shared_to") {
      if (!questionnaire.shared) {
        return "N/A";
      }
      const ids = questionnaire.shared_checklists.map(
        // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
        (item) => item.workspace_id,
      );
      return workspacesList
        .filter((item) => ids.includes(item.id))
        .map((item) => item.name)
        .join(", ");
    }
    if (fieldName === "approver") {
      if (!questionnaire.approver?.id) {
        return "N/A";
      }
      const approver = users.find(
        (item) => item.id === questionnaire.approver.id,
      );
      if (!approver) {
        return;
      }
      return (
        <UsersCircle
          user={approver}
          additionalStyles={{
            background: userColors[approver?.icon_color_index],
          }}
          additionalText={
            questionnaire.approved_at &&
            formatDate(stringToDate(questionnaire.approved_at))
          }
        />
      );
    }
    if (fieldName === "completed_user") {
      if (!questionnaire.completed_user?.id) {
        return "N/A";
      }
      const completedUser = users.find(
        (item) => item.id === questionnaire.completed_user.id,
      );
      if (!completedUser) {
        return;
      }
      return (
        <UsersCircle
          user={completedUser}
          additionalStyles={{
            background: userColors[completedUser?.icon_color_index],
          }}
        />
      );
    }
  }

  // elements
  const renderColumns = sortedFields.map((field) => (
    <ModuleTableColumn key={field.name} field={field} />
  ));

  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const renderCell = (field, questionnaire) => {
    const value = getCellValue(field.name, questionnaire);
    const role = `vdd-questionnaires-cell-${field.name}`;

    return (
      <li data-testid={role} key={field.id} style={{ width: field.width }}>
        <div className="cell-content">{value}</div>
      </li>
    );
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'questionnaire' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderRows = questionnaires.map((questionnaire) => (
    <div key={questionnaire.id} className="list-table">
      <ul>
        <div className="list-table-wrap">
          <div className="list-table-block">
            <CommentsSlideMenu
              globalID={questionnaire.global_id}
              recordID={questionnaire.id}
              uncompletedCommentsCount={
                questionnaire.uncompleted_comments_count
              }
              isLockedRow
              taskableType="BaseChecklist"
            />
            {sortedFields.map((field) => renderCell(field, questionnaire))}
            <span className="stretch-cell" />
          </div>
          <div
            className="list-points vdd-details"
            data-testid="vdd-questionnaires-button"
          >
            <VendorQuestionnaireTableAction
              isActive={isActive}
              questionnaire={questionnaire}
            />
          </div>
        </div>
      </ul>
    </div>
  ));

  return (
    <div
      className="vdd-templates-wrapper"
      data-testid="vdd-questionnaires-content"
    >
      <div className="table-list-wrap">
        <h4 className="questionnaires-title questionnaires-header-title">
          Questionnaires Created
        </h4>
        <div>
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="list-title-table-wrap questionnaires-header-title">
                  {renderColumns}
                  <span className="stretch-cell" />
                </div>
                <div className="list-plus">
                  <a href="#" />
                </div>
              </div>
            </ul>
          </div>

          {vendorID && (
            <div
              className="create-new-record questionnaires-header-title"
              data-testid="vdd-questionnaires-add-new"
            >
              <Link
                to={`/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendorID}/questionnaires/create`}
              >
                <button type="button">Add New Questionnaire +</button>
              </Link>
            </div>
          )}

          {renderRows}
        </div>
      </div>
    </div>
  );
}

export default observer(VendorQuestionnairesTable);
