import classNames from "classnames";
import { observer } from "mobx-react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { PiCaretUpBold } from "react-icons/pi";

import { Field, RecordVersion, TableName } from "@/api";
import SectionTag from "@/components/table/shared/SectionTag";
import { useMainStore } from "@/contexts/Store";
import { Section } from "@/stores/types/section-tags";

import { useSortingAndPagination } from "../../../hooks/useSortingAndPagination";
import { isLockedRecord } from "../../../stores/helpers/RecordVersionHelper";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import MenuActions from "../menu-actions/MenuActions";
import { customColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import ReorderableTable from "../shared/ReorderableTable";
import SectionPopupSettings from "../shared/SectionPopupSettings";
import TableMenu from "../shared/tableMenu/TableMenu";

type Props = {
  archived?: boolean;
  fields?: Field[];
  isDropdownOpen?: boolean;
  moduleWorkspaceID?: number;
  recordVersions?: RecordVersion[];
  sectionTag?: Section;

  setShowCheckbox?: Dispatch<SetStateAction<boolean>>;
  showCheckbox?: boolean;
  tableName?: TableName;
  workspaceID?: number | null;
};

function TrainingSection({
  archived,
  fields,
  moduleWorkspaceID,
  recordVersions,
  sectionTag,
  tableName,
  workspaceID,
  ...props
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  // States
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(props.isDropdownOpen);
  const [selectMode, setSelectMode] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(props.showCheckbox);
  const sectionTableID = `section-table-${sectionTag?.id || 0}`;

  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    customColumns({
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ store: Trainings; tableName: "... Remove this comment to see the full error message
      store: mainStore.trainings,
      tableName,
      moduleWorkspaceID,
      sectionTableID,
    });

  // Hooks
  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
  } = useSortingAndPagination({
    store: mainStore.trainings,
    sectionTagId: sectionTag?.id,
  });

  useEffect(() => {
    setShowCheckbox(props.showCheckbox);
  }, [props.showCheckbox]);

  // Variables
  const { canManageSections } = mainStore.userPermissions;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;

  // Functions
  const dropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const addNewTraining = async () => {
    if (selectMode && showCheckbox) {
      return;
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
    await mainStore.trainings.create(workspaceID, sectionTag?.id);
  };

  const deleteSectionTag = () => {
    mainStore.sectionTags.delete(moduleWorkspaceID, sectionTag?.id);
    mainStore.toast.setText("Section has been deleted!");
  };

  const renameClick = () => {
    setIsEditMode(true);
  };

  const resetMenuActions = () => {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderRow = (recordVersion, idx) => {
    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        fields={fields}
        recordVersion={recordVersion}
        moduleWorkspaceID={moduleWorkspaceID}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
        currentTableName={tableName}
        archived={archived}
        moduleIdentifier="training"
        inSelectMenuActionMode={selectMode}
        isDraggable={!archived}
        isSorted={!!sortByFieldName}
        order={idx}
        showCheckboxFromSlideMenu={props.showCheckbox}
        // @ts-expect-error TS(2322) FIXME: Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
        isLockedRow={isLockedRecord(recordVersion, "training")}
      />
    );
  };

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const renderColumns = fields.map((field) => {
    return (
      <ModuleTableColumn
        key={field.name}
        field={field}
        // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
        setSortByDirection={setSortByDirection}
        setSortByFieldName={setSortByFieldName}
        onDelete={deleteColumn}
        onRename={renameColumn}
      />
    );
  });

  return (
    <>
      {sectionTag && (
        <div className="tw-ml-[18px] tw-flex" data-testid="dropdown-section">
          <div
            className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-p-[7px] hover:tw-rounded-md hover:tw-bg-neutral-100"
            onClick={dropdownClick}
          >
            <PiCaretUpBold
              className={classNames(
                "tw-h-[18px] tw-w-[18px] tw-origin-center  tw-text-neutral-500",
                {
                  "tw-rotate-180": !isDropdownOpen,
                  "tw-rotate-0 ": isDropdownOpen,
                },
              )}
            />
          </div>
          {sectionTag?.title && (
            <SectionTag
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              sectionTagID={sectionTag?.id}
              sectionTagTitle={sectionTag?.title}
            />
          )}

          {!archived &&
            sectionTag?.editable &&
            !isEditMode &&
            canManageSections && (
              <SectionPopupSettings
                onRename={renameClick}
                onDelete={deleteSectionTag}
              />
            )}
        </div>
      )}

      {isDropdownOpen && (
        <div
          className="dropdown-table draggable training-section-container"
          id={sectionTableID}
          data-testid="procedures-dropdown-table"
        >
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                {!archived && (
                  <div className="procedures-list-column-first-blocks table-column-reorder">
                    {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
                    {fields.length > 0 && (
                      <TableMenu
                        setShowCheckbox={setShowCheckbox}
                        resetMenuActions={resetMenuActions}
                        selectMode={selectMode}
                        setSelectMode={setSelectMode}
                      />
                    )}
                  </div>
                )}
                {showCheckbox && selectMode ? (
                  <div>
                    <MenuActions resetMenuActions={resetMenuActions} />
                  </div>
                ) : (
                  <div className="list-title-table-wrap">
                    {showCheckbox && <div className="checkbox-title-table" />}
                    {renderColumns}
                    <span className="stretch-cell" />
                  </div>
                )}
              </div>
              {!archived ? (
                <AddColumnButton onAdd={createColumn} />
              ) : (
                <div className="title-first-block finalized-block training" />
              )}
            </ul>
          </div>

          <ReorderableTable
            recordVersions={recordVersions}
            renderer={renderRow}
            sectionTag={sectionTag}
            isSorted={!!sortByFieldName}
            onAddNewRow={!archived ? addNewTraining : undefined}
            newRowName={getRecordName("training", moduleWorkspaces, true)}
          />
        </div>
      )}
    </>
  );
}

TrainingSection.defaultProps = {
  setShowCheckbox: () => {},
  showCheckbox: false,
};

export default observer(TrainingSection);
