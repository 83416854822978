import { IconButton, TextInput } from "@themis/ui";
import { observer } from "mobx-react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { PiCheckBold } from "react-icons/pi";

import { useMainStore } from "@/contexts/Store";

type Props = {
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  sectionTagID: number | null;
  sectionTagTitle: string;
  dataTestID?: string;
};

function SectionTag({
  isEditMode,
  setIsEditMode,
  sectionTagID,
  sectionTagTitle,
  dataTestID,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [title, setTitle] = useState(sectionTagTitle);
  const [hasSubmitError, setHasSubmitError] = useState(false);

  // Variables
  const { moduleWorkspaceID } = mainStore.context;

  async function renameSectionTag() {
    if (title === sectionTagTitle) {
      setIsEditMode(false);
    } else {
      try {
        await mainStore.sectionTags.update(moduleWorkspaceID, sectionTagID, {
          title,
        });
        setIsEditMode(false);
      } catch (error) {
        setHasSubmitError(true);
      }
    }
  }

  async function editSectionTag(event: React.KeyboardEvent<HTMLInputElement>) {
    const isEnterKey = event.key === "Enter";
    const isEscapeKey = event.key === "Escape";

    if (isEnterKey) {
      await renameSectionTag();
    } else if (isEscapeKey) {
      setTitle(sectionTagTitle);
      setIsEditMode(false);
    }
  }

  function onTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setHasSubmitError(false);
    setTitle(event.target.value);
  }

  return isEditMode ? (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2">
      <TextInput
        data-testid="section-name-input"
        value={title}
        onKeyDown={editSectionTag}
        onChange={onTitleChange}
        className="tw-w-[304px]"
        size="md"
        autoFocus
      />
      {hasSubmitError && <p className="error">Please try a different name</p>}
      <IconButton
        data-testid="rename-section-button"
        color="secondary"
        Icon={PiCheckBold}
        onClick={renameSectionTag}
        size="md"
      />
    </div>
  ) : (
    <div
      data-testid={dataTestID}
      className="tw-mx-2 tw-flex tw-items-center tw-text-base tw-font-semibold tw-text-neutral-500"
    >
      {sectionTagTitle}
    </div>
  );
}

export default observer(SectionTag);
