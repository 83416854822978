import { observer } from "mobx-react";
import React from "react";

type Props = {
  name: string;
};
// import calendarIcon from '../../../images/table-image/icon/calendar-black-icon.svg'

function RiskRegisterReportMatrixHeader({ name }: Props) {
  return (
    <div className="report-dashboard-header">
      <div className="report-dashboard-header-block">
        <div className="report-dashboard-header-left">{name}</div>
        {/* <div className='report-dashboard-header-right'>
          <img src={ calendarIcon } />
          { period }
        </div> */}
      </div>
    </div>
  );
}

// RiskRegisterReportMatrixHeader.defaultProps = {
//   period: 'This Month',
// }

export default observer(RiskRegisterReportMatrixHeader);
