import classNames from "classnames";
import React, { useState } from "react";

import { FOLDER_COLORS } from "../colors";

type Props = {
  defaultColor: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (...args: any[]) => any;
};

const FolderChangeColorForm = ({ defaultColor, onSubmit }: Props) => {
  const [selectedColor, setSelectedColor] = useState(defaultColor);

  const isSubmitDisabled = defaultColor === selectedColor;

  const handleSubmit = () => onSubmit({ color_name: selectedColor });

  return (
    <div
      className="table-dropdown folder-form"
      data-testid="folder-change-color-form"
    >
      <div className="form-row">
        <div className="form-label">Select Folder Color</div>
        <div className="form-element">
          <ul
            className="folder-color-selector"
            data-testid="folder-color-selector"
          >
            {FOLDER_COLORS.map((color) => (
              <li
                key={color}
                data-testid="folder-color-selector-option"
                className={classNames(color, {
                  active: selectedColor === color,
                })}
                onClick={() => setSelectedColor(color)}
              />
            ))}
          </ul>
        </div>
      </div>

      <div className="form-row">
        <button
          className="form-submit-button"
          data-testid="form-submit-button"
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default FolderChangeColorForm;
