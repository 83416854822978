import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { useMainStore } from "@/contexts/Store";

import DocumentsContextMenu from "./DocumentsContextMenu";

function DocumentsActionBar() {
  // Import MobX stores
  const mainStore = useMainStore();
  const path = location.pathname;
  const rvId = Number(path.substring(path.lastIndexOf("/") + 1));
  if (!rvId) {
    return;
  }

  const recordVersionList = mainStore.recordVersions.list.find(
    (rv) => rv.id === rvId,
  );
  const recordVersionFetch = mainStore.recordVersions.current;
  const recordVersion = recordVersionList || recordVersionFetch;

  useEffect(() => {
    const fetchData = async () => {
      if (recordVersionList) {
        return;
      }
      await mainStore.recordVersions.fetch(rvId);
    };

    fetchData();
  }, [rvId]);

  if (!recordVersion) {
    return null;
  }
  return <DocumentsContextMenu recordVersion={recordVersion} showOnlyApprove />;
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type '({ re... Remove this comment to see the full error message
DocumentsContextMenu.propTypes = {
  recordVersion: PropTypes.object.isRequired,
};

export default observer(DocumentsActionBar);
