import { Button } from "@themis/ui";
import React from "react";
import { useHistory } from "react-router-dom";

type Props = {
  url: string;
  disabled?: boolean;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [rest: string]: any;
};

function ViewLink({ url, disabled, label, ...rest }: Props) {
  const history = useHistory();
  return (
    <Button
      color="tertiary"
      size="md"
      disabled={disabled}
      onClick={() => history.push(url)}
      className="tw-w-[86px] tw-max-w-[86px]"
      {...rest}
    >
      {label}
    </Button>
  );
}

ViewLink.defaultProps = {
  disabled: false,
  label: "View",
  additionalClassName: null,
};

export default ViewLink;
