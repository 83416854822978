import React, { useMemo } from "react";

import { DashboardComponent } from "@/api";
import { nameForThemisModuleIdentifier } from "@/components/helpers/nameForThemisModuleIdentifier";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { StackedHorizontalBarChart } from "../charts/StackedHorizontalBarChart";

export default function WorkDistributionByModules({
  widgetData,
  moduleWorkspacesNames,
}: {
  widgetData: DashboardComponent["data"];
  moduleWorkspacesNames?: { [key: string]: string };
}) {
  const mutated = useMemo(() => {
    if (!widgetData) {
      return {
        keys: [],
        data: [],
      };
    }
    const mutatedData: {
      name: string;
      [key: string]: string | number;
    }[] = [];
    const modules = Object.keys(widgetData);
    modules.forEach((module) => {
      const updatedName = moduleWorkspacesNames
        ? moduleWorkspacesNames[String(module)] ||
          nameForThemisModuleIdentifier(module as ModuleIdentifier)
        : nameForThemisModuleIdentifier(module as ModuleIdentifier);
      mutatedData.push({
        name: updatedName.slice(0, 12) + (updatedName.length > 12 ? "..." : ""),
        total:
          widgetData[module].to_do +
          widgetData[module].in_progress +
          widgetData[module].overdue,
        ...widgetData[module],
      });
    });

    return {
      keys: ["overdue", "in_progress", "to_do"],
      data: mutatedData.sort((a, b) => a.name.localeCompare(b.name)),
    };
  }, [widgetData]);

  return (
    <div className="tw-flex tw-h-[260px] tw-min-w-[400px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Work Distribution by Module
        </div>
      </div>
      <div className="tw-flex tw-justify-center tw-gap-3">
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-neutral-200" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            To Do
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-primaryDim-600" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            In Review
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-gap-[6px]">
          <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-warning-300" />
          <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
            Overdue
          </div>
        </div>
      </div>
      <div>
        <div className="tw-h-full tw-w-full">
          <StackedHorizontalBarChart
            data={mutated.data}
            keys={mutated.keys}
            colors={["#EB2E4E", "#313181", "#BCBCCF"]}
          />
        </div>
      </div>
    </div>
  );
}
