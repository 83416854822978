import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import { getApproversForCurrentStep } from "../shared/helpers/approvers";
import Unlock from "../shared/Unlock";
import ViewLink from "../shared/ViewLink";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersionID: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleUnlock?: (...args: any[]) => any;
};

function RenderMarketingButton({ recordVersionID, handleUnlock }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { canUnpublishOrUnlockRecords, hasModuleWriteAccess } =
    mainStore.userPermissions;
  const { workspaceID } = mainStore.context;

  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  );
  const status = recordVersion?.status;
  const currentUserID = mainStore.users.user.id;
  const isOwner =
    mainStore.avroSchemas.firstValueForField("owner", recordVersion?.data) ===
    currentUserID;

  const currentStepApproversIDs = getApproversForCurrentStep(recordVersion);

  const isApprover =
    (
      mainStore.avroSchemas.valueForField("approvers", recordVersion?.data) ||
      []
    ).includes(currentUserID) ||
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    currentStepApproversIDs.includes(currentUserID);
  const attachmentGroups = recordVersion?.attachment_groups.filter(
    (attachmentGroup) => attachmentGroup.field_name === "creative",
  );

  // Tabs 1-2 -> Review
  const viewButtonText =
    status &&
    isApprover &&
    hasModuleWriteAccess &&
    ["request_change", "reviewing"].includes(status)
      ? "Review"
      : "View";

  // Tab 3 -> Unlock
  if (
    (canUnpublishOrUnlockRecords ||
      (isApprover && hasModuleWriteAccess) ||
      isOwner) &&
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    ["approved", "rejected"].includes(status)
  ) {
    return (
      <Unlock
        // @ts-expect-error TS(2322) FIXME: Type '((...args: any[]) => any) | undefined' is no... Remove this comment to see the full error message
        onYes={handleUnlock}
        tableName="Default"
        moduleIdentifier="marketing"
        buttonText="Unlock"
      />
    );
  }

  return (
    <ViewLink
      url={`/workspaces/${workspaceID}/modules/marketing/attachment_view/${recordVersionID}/${attachmentGroups?.[0]?.id}`}
      disabled={!attachmentGroups?.length}
      label={viewButtonText}
    />
  );
}

export default observer(RenderMarketingButton);
