import { observer } from "mobx-react";
import React, { useState } from "react";

import { useMainStore } from "@/contexts/Store";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { recordTypeForThemisModuleIdentifier } from "../../helpers/nameForThemisModuleIdentifier";

interface FileUploadChangesProps {
  moduleIdentifier: ModuleIdentifier;
  handleNext: (updatedName?: string, updatedDescription?: string) => void;
  recordVersionID?: number;
  skipUpdate?: boolean;
}

export const FileUploadChanges = ({
  moduleIdentifier,
  handleNext,
  recordVersionID,
  skipUpdate = false,
}: FileUploadChangesProps) => {
  const mainStore = useMainStore();

  const [documentDescription, setDocumentDescription] = useState("");

  const displayedModuleName =
    recordTypeForThemisModuleIdentifier(moduleIdentifier).singular;

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const changeDocumentDescription = (event) => {
    setDocumentDescription(event.target.value);
  };

  const updateChangesColumn = () => {
    const changesFieldName = `${displayedModuleName.toLowerCase()}_changes`;
    if (recordVersionID) {
      mainStore.recordVersions.update({
        fieldName: changesFieldName,
        recordVersionID,
        value: mainStore.avroSchemas.serializeValue(
          changesFieldName,
          documentDescription,
        ),
      });
    }
  };

  const onClick = () => {
    if (skipUpdate) {
      const changesFieldName = `${displayedModuleName.toLowerCase()}_changes`;
      handleNext(changesFieldName, documentDescription);
    } else {
      updateChangesColumn();
      handleNext();
    }
  };

  return (
    <div className="table-dropdown file-changes">
      <h4>{displayedModuleName} Changes?</h4>
      <p>
        Describe any significant changes to the{" "}
        {displayedModuleName.toLowerCase()} document. If there are no
        significant changes you can skip for a default message.
      </p>
      <textarea
        data-testid="replace-file-changes-input"
        placeholder="No Material Changes"
        tabIndex={1}
        value={documentDescription}
        onChange={changeDocumentDescription}
      />
      <div className="confirmation">
        <button data-testid="replace-file-changes-input-next" onClick={onClick}>
          Next
        </button>
        <button
          className="skip"
          data-testid="replace-file-changes-input-skip"
          onClick={() => handleNext()}
        >
          Skip
        </button>
      </div>
    </div>
  );
};

export default observer(FileUploadChanges);
