import axios from "axios";

import { attachmentFileType } from "@/api";
import mainStore from "@/stores/Main";
import { IntegrationAttachmentTypes } from "@/stores/types/attachment-types";

export function downloadFile(blob: Blob | MediaSource, filename: string) {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

export async function fetchFile(urlOrID: string) {
  try {
    return await axios({
      method: "GET",
      url: urlOrID,
      responseType: "blob",
    });
  } catch (error) {
    mainStore.toast.setErrorText("Error downloading file");
    window.console.log(`"Error downloading file:" error ${error}`);
    throw error;
  }
}

export function getIntegrationName(
  integrationFileType: IntegrationAttachmentTypes,
) {
  switch (integrationFileType) {
    case attachmentFileType.figma:
      return "Figma";
    case attachmentFileType.google_drive:
      return "Google";
    case attachmentFileType.share_point:
      return "Microsoft";
    default:
  }
}
