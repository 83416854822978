import PropTypes from "prop-types";
import React from "react";

// @ts-expect-error TS(7031) FIXME: Binding element 'fill' implicitly has an 'any' typ... Remove this comment to see the full error message
const Info = ({ fill, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM12 10C11.1716 10 10.5 9.32843 10.5 8.5C10.5 7.67157 11.1716 7 12 7C12.8284 7 13.5 7.67157 13.5 8.5C13.5 9.32843 12.8284 10 12 10ZM12 11C11.4477 11 11 11.4477 11 12V16.5C11 17.0523 11.4477 17.5 12 17.5C12.5523 17.5 13 17.0523 13 16.5V12C13 11.4477 12.5523 11 12 11Z"
      fill={fill}
    />
  </svg>
);

Info.displayName = "InfoIcon";

export default Info;

// Validate fill prop
Info.propTypes = {
  fill: PropTypes.string,
};

Info.defaultProps = {
  fill: "#353549",
};
