import "./subpage-tabs.scss";

import classNames from "classnames";
import { ParsedQs } from "qs";
import React, { ReactNode } from "react";
import Popup from "reactjs-popup";

import { Icon, Typography } from "@/components/Elements";
import ModalWrapper from "@/components/shared/ModalWrapper";
import SubpageTab from "@/components/subpage-tabs/subpage-tab/SubpageTab";
import { useSearchParams } from "@/hooks/useSearchParams";

type SubPageTabOption<T> = {
  count?: number;
  label: ReactNode;
  /**
   * Search Param value in URL to determine if active
   */
  activeValue: T[keyof T];
};

function SubpageTabs<T extends ParsedQs>(props: {
  className?: string;
  /**
   * Search Param property name in URL to determine if active
   */
  searchParamName: keyof T;
  options: SubPageTabOption<T>[];
  infoText?: React.CSSProperties["content"];
}) {
  const [searchParams, setSearchParams] = useSearchParams<T>();

  return (
    <div className={classNames("subpage-tabs", props.className)}>
      {props.options.map(({ activeValue, ...option }, index) => {
        // Default to first tab if no search param
        // (happens when you first mount the tabs)
        const active = searchParams[props.searchParamName]
          ? activeValue === searchParams[props.searchParamName]
          : index === 0;

        return (
          <SubpageTab
            key={typeof option.label === "string" ? option.label : index}
            active={active}
            onClick={() => {
              if (active) {
                return;
              }
              setSearchParams(
                {
                  ...searchParams,
                  [props.searchParamName]: activeValue,
                },
                true,
              );
            }}
            {...option}
          />
        );
      })}
      {props.infoText && (
        <Popup
          keepTooltipInside
          on="hover"
          trigger={
            <div>
              <Icon
                className="subpage-tabs__icon"
                name="info"
                color="extrasBlueGrayDarker"
              />
            </div>
          }
        >
          <ModalWrapper className="subpage-tabs__tooltip">
            <Typography
              label={props.infoText}
              weight="semiBold"
              color="generalWhite"
              size="xs"
            />
          </ModalWrapper>
        </Popup>
      )}
    </div>
  );
}

export default SubpageTabs;
