import { DataWithIdAndCustomFields } from "@themis/ui";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSearchParams } from "./useSearchParams";

/**
 * Used to manage filters and sorting with the new queryParam format, exp. `?filters[status][any]=In Progress,Done&sort_by=status[asc]`
 */
export default function useFilterSort<T extends DataWithIdAndCustomFields>({
  fieldsData,
  initialFilters,
  initialSorting,
}: {
  fieldsData: FilterFieldData<T>;
  initialFilters?: Partial<Record<keyof T, Filter>>;
  initialSorting?: Sorting<T>;
}) {
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { filters, sorting, listRequestQueryParams } = parseParams(
    new URLSearchParams(search),
    fieldsData,
  );

  useEffect(() => {
    if (initialFilters && isEmpty(searchParams.filters)) {
      applyFilter({ filtersToApply: initialFilters, sort: initialSorting });
    }
  }, []);

  /**
   * Applies filters, removes filters, and adds sort to the search parameters in the URL.
   *
   * @param {Object} params - The parameters for applying filters and sorting.
   * @param {Partial<Record<keyof T, Filter>>} [params.filtersToApply={}] - The filters to apply, where each key is a field name and the value is a filter object.
   * @param {(keyof T)[]} [params.filtersToRemove] - The filters to remove, where each item is a field name.
   * @param {Sorting<T> | null} [params.sort] - The sorting configuration, where the columnKey is the field to sort by and direction is the sort direction. If null, sorting will be cleared.
   */
  function applyFilter({
    filtersToApply = {},
    filtersToRemove,
    sort,
  }: {
    filtersToApply?: Partial<Record<keyof T, Filter>>;
    filtersToRemove?: (keyof T)[];
    sort?: Sorting<T> | null;
  }) {
    // Apply the filters provided in filtersToApply
    const appliedFilters = Object.keys(filtersToApply).reduce(
      (acc, fieldName) => {
        const filter = filtersToApply[fieldName as keyof T];
        if (!filter) {
          return acc;
        }

        return {
          ...acc,
          filters: {
            ...acc.filters,
            [fieldName]: {
              [filter.operand]: stringifyFilterValue(filter),
            },
          },
        };
      },
      { filters: {} },
    );

    // Remove the filters specified in filtersToRemove
    const removedFilters = filtersToRemove?.reduce(
      (acc, fieldName) => {
        if (!filters[fieldName]) {
          return acc;
        }

        const filterOperand = filters[fieldName]?.operand;

        if (!filterOperand) {
          return acc;
        }

        return {
          ...acc,
          filters: {
            ...acc.filters,
            [fieldName]: {
              [filterOperand]: undefined,
            },
          },
        };
      },
      { filters: {} },
    );

    // Handle sorting parameters
    const sortedParams = (() => {
      // Clear existing sort parameters
      const clearedSortParams = Object.keys(searchParams).reduce(
        (acc, key) => (key === "sort_by" ? { ...acc, [key]: undefined } : acc),
        {},
      );
      // Apply new sort parameters if provided
      if (sort || sort === null) {
        return {
          ...clearedSortParams,
          ...(sort ? { sort_by: { [sort.columnKey]: sort.direction } } : {}),
        };
      }

      return {};
    })();

    // Combine all new search parameters
    const newSearchParamObject = {
      filters: {
        ...appliedFilters.filters,
        ...removedFilters?.filters,
      },
      ...sortedParams,
    };

    // Update the search parameters in the URL
    setSearchParams(
      {
        ...searchParams,
        ...newSearchParamObject,
        filters: {
          ...(typeof searchParams.filters === "object" && searchParams.filters),
          ...newSearchParamObject.filters,
        },
      },
      true,
    );
  }

  function clearAllFilters() {
    const clearedFilters = Object.keys(filters).reduce(
      (acc, fieldName) => {
        const filter = filters[fieldName as keyof T];
        if (!filter) {
          return acc;
        }
        const filterOperand = filter.operand;
        return {
          ...acc,
          filters: {
            ...acc.filters,
            [fieldName]: {
              [filterOperand]: undefined,
            },
          },
        };
      },
      { filters: {} },
    );

    setSearchParams({ ...searchParams, ...clearedFilters }, true);
  }

  return {
    filters: Object.keys(filters).length > 0 ? filters : undefined,
    applyFilter,
    clearAllFilters,
    sorting,
    listRequestQueryParams,
  };
}

function parseFieldFilter(
  operand: string,
  filterParamString: string,
): Filter | null {
  try {
    const castOperand = operand as FilterOperand;
    const filterValues = filterParamString.split(",");

    if ([FilterOperand.ANY, FilterOperand.NOT].includes(castOperand)) {
      return {
        operand: castOperand as FilterOperand.ANY | FilterOperand.NOT,
        value: filterValues,
      };
    }

    if ([FilterOperand.PRESENT, FilterOperand.MISSING].includes(castOperand)) {
      return {
        operand: castOperand as FilterOperand.PRESENT | FilterOperand.MISSING,
      };
    }

    throw new Error(`Invalid filter operand: ${operand}`);
  } catch (error) {
    window.console.info(`Non-parsable fitler: ${filterParamString}`, error);
    return null;
  }
}

function stringifyFilterValue(filter: Filter) {
  try {
    if ([FilterOperand.ANY, FilterOperand.NOT].includes(filter.operand)) {
      return (filter.value as string[]).join(",");
    }

    if (
      [FilterOperand.PRESENT, FilterOperand.MISSING].includes(filter.operand)
    ) {
      return "true";
    }

    throw new Error(`Invalid filter operand: ${filter.operand}`);
  } catch (error) {
    window.console.info(`Non-parsable filter: ${filter}`, error);
    return "";
  }
}

function parseSortBy(sortByKey: string, sortByValue: string) {
  const columnKeyMatch = sortByKey.match(/sort_by\[([^\]]*)\]/);
  const columnKey = columnKeyMatch ? columnKeyMatch[1] : "";
  const direction = sortByValue as "asc" | "desc";
  return { columnKey, direction };
}

function parseParams<T extends DataWithIdAndCustomFields>(
  searchParams: URLSearchParams,
  fieldsData: FilterFieldData<T>,
) {
  const filters: Filters<T> = {};
  let sorting: Sorting<T> | undefined;
  searchParams.forEach((value, key) => {
    if (key.startsWith("sort_by[")) {
      const parsedSortBy = parseSortBy(key, value);

      return (sorting = parsedSortBy as Sorting<T>);
    }

    if (!key.match(/filters\[[a-z_]+\]/)) {
      // ignore search params that aren't related to table filters
      return;
    }

    const fieldNameMatch = key.match(/filters\[([^\]]*)\]/);
    const fieldName = fieldNameMatch ? fieldNameMatch[1] : "";

    if (!(fieldName in fieldsData)) {
      // ignore search params that aren't related to table fields
      return;
    }

    const operandMatch = key.match(/filters\[[^\]]*\]\[([^\]]*)\]/);
    const operand = operandMatch ? operandMatch[1] : "";

    const parsedFieldFilter = parseFieldFilter(operand, value);
    if (parsedFieldFilter) {
      filters[fieldName as keyof T] = parsedFieldFilter;
    }
  });

  const listRequestQueryParams = {
    filters: Object.fromEntries(
      (Object.entries(filters) as Array<[keyof T, Filter]>).map(
        ([fieldName, filter]) => [
          fieldName,
          {
            [filter.operand]: [FilterOperand.ANY, FilterOperand.NOT].includes(
              filter.operand,
            )
              ? (filter.value as string[]).join(",")
              : "",
          },
        ],
      ),
    ),
    ...(sorting && { sort_by: { [sorting.columnKey]: sorting.direction } }),
  };

  return { filters, sorting, searchParams, listRequestQueryParams };
}

export enum FilterOperand {
  ANY = "any",
  NOT = "not",
  PRESENT = "present",
  MISSING = "missing",
}

export type Filter =
  | {
      operand: FilterOperand.ANY | FilterOperand.NOT;
      value: string[];
    }
  | {
      operand: FilterOperand.PRESENT | FilterOperand.MISSING;
      value?: never;
    };

export type FilterValueOption = {
  value: string;
  key: string;
  Component: () => JSX.Element;
};

export type FilterData = {
  displayName: string;
  type: "string";
  options?: FilterValueOption[];
};

export type FilterFieldData<T> = Partial<Record<keyof T, FilterData>>;

type Filters<T> = Partial<Record<keyof T, Filter>>;

type Sorting<T extends DataWithIdAndCustomFields> = {
  columnKey: keyof T | keyof T["custom_fields"];
  direction: "asc" | "desc";
};
