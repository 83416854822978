import React from "react";

type Props = {
  section: string;
  title: string;
  value: number;
  color?: string;
};

const DEFAULT_FONT_COLOR = "#6161C3";
const DEFAULT_BACKGROUND_COLOR = "#E3E4FD";

function RiskRegisterScoreChip({ section, title, value, color }: Props) {
  const backgroundColor = color || DEFAULT_BACKGROUND_COLOR;
  const fontColor =
    backgroundColor === DEFAULT_BACKGROUND_COLOR ? DEFAULT_FONT_COLOR : "#fff";

  return (
    <span
      key={`rr-score-chip-${title}-${value}`}
      className="rr-score-chip"
      style={{ backgroundColor, color: fontColor }}
    >
      {`${section}: `}{" "}
      {`${title ? `${title} - ` : ""}${title ? value : `${`${value}%`}`}`}
    </span>
  );
}

export default RiskRegisterScoreChip;
