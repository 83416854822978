import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { useMainStore } from "@/contexts/Store";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitPressed: (...args: any[]) => any;
};

function SubmitComplaint({ submitPressed }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { user } = mainStore.users;
  const { current: finraContact } = mainStore.finraContacts;

  // State
  const [fullName, setFullName] = useState(
    // @ts-expect-error TS(2339) FIXME: Property 'full_name' does not exist on type '{}'.
    finraContact.full_name || user.full_name,
  );
  // @ts-expect-error TS(2339) FIXME: Property 'phone' does not exist on type '{}'.
  const [phone, setPhone] = useState(finraContact.phone || user.phone);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    // @ts-expect-error TS(2339) FIXME: Property 'full_name' does not exist on type '{}'.
    setFullName(finraContact.full_name || user.full_name);
    // @ts-expect-error TS(2339) FIXME: Property 'full_name' does not exist on type '{}'.
  }, [finraContact.full_name, user.full_name]);

  useEffect(() => {
    // @ts-expect-error TS(2339) FIXME: Property 'phone' does not exist on type '{}'.
    setPhone(finraContact.phone || user.phone);
    // @ts-expect-error TS(2339) FIXME: Property 'phone' does not exist on type '{}'.
  }, [finraContact.phone, user.phone]);

  useEffect(() => {
    const [firstName, lastName] = (fullName || "").split(" ");

    // Full name should be present
    // First name and last name should be present (as a part of full name)
    // Last name should have length < 30
    // Phone number should be present
    // Phone number should have length >= 7
    // Phone number should have length < 20
    // Phone number should contain only digits
    if (
      fullName?.length > 0 &&
      firstName?.length > 0 &&
      lastName?.length > 0 &&
      lastName?.length < 30 &&
      phone?.length >= 7 &&
      phone?.length < 20 &&
      /^\d+$/.test(phone)
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [phone, fullName]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleInputChange(event) {
    event.preventDefault();

    const { name, value } = event.target;

    if (name === "phone") {
      setPhone(value);
    }

    if (name === "fullName") {
      setFullName(value);
    }
  }

  function onSubmit() {
    submitPressed(fullName, phone);
  }

  const buttonClasses = classNames({ active: !buttonDisabled });
  const fullNameClasses = classNames({
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    "full-name-initials": user.full_name.length > 0,
    active: fullName && fullName.length > 0,
  });
  const phoneClasses = classNames({ active: phone && phone.length > 0 });

  return (
    <div className="table-dropdown complaints-submit-popup">
      <h4>Collect Contact Info</h4>
      <p>
        Please fill in the following contact info if they are missing /
        not-updated.
      </p>
      <p>A user with a full first & last name is required.</p>
      <div className="complaints-submit-form">
        <label className="full-name">
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          {user.initials.length > 0 && <span>{user.initials}</span>}

          <input
            type="text"
            autoComplete="off"
            name="fullName"
            className={fullNameClasses}
            value={fullName}
            onChange={handleInputChange}
            placeholder="Full name"
          />
        </label>

        <input type="text" name="email" disabled value={user.email} />

        <input
          type="phone"
          autoComplete="off"
          name="phone"
          className={phoneClasses}
          value={phone}
          onChange={handleInputChange}
          placeholder="Contact number"
        />

        <button
          className={buttonClasses}
          onClick={onSubmit}
          disabled={buttonDisabled}
        >
          Confirm
        </button>
      </div>
    </div>
  );
}

export default SubmitComplaint;
