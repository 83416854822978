import classNames from "classnames";
import React from "react";

type Props = {
  width: number | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contracts?: any; // TODO: PropTypes.instanceOf(Array)
  locked?: boolean;
};

export default function Contracts({ width, contracts, locked }: Props) {
  // Variables
  const style = { width };

  return (
    <li
      style={style}
      data-testid="contracts-cell"
      className={classNames("contracts-cell", {
        "locked-cell pointer-events-none": locked,
      })}
    >
      <div className="cell-content">{`${contracts.length} Contract(s)`}</div>
    </li>
  );
}

Contracts.defaultProps = {
  width: 200,
  contracts: [],
};
