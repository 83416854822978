import { MiniTag, MiniTagProps } from "@themis/ui";
import { camelCase, startCase } from "lodash";
import React from "react";

const STATUS_COLOR_MAP: Record<string, MiniTagProps["color"]> = {
  New: "grey",
  In_Progress: "fuchsia",
  Complete: "blue",
  Compliant: "green",
  Non_Compliant: "red",
} as const;

export function ModuleAssessmentStatusTag({
  status,
  isDefaultValue = true,
}: {
  status?: string;
  isDefaultValue?: boolean;
}) {
  if (!status && !isDefaultValue) {
    return;
  }

  const defaultStatus = !status ? "New" : status;

  const manipulated_status = startCase(camelCase(defaultStatus));

  if (manipulated_status === "") {
    return;
  }

  return (
    <MiniTag
      size="lg"
      color={STATUS_COLOR_MAP[manipulated_status.replace(/ /g, "_")]}
    >
      {manipulated_status}
    </MiniTag>
  );
}
