import React from "react";
import { Route, Switch } from "react-router-dom";

import AddNewSectionButton from "@/components/shared/AddNewSectionButton";

import CreateCTA from "../../../components/table/policy/CreateCTA";
import Policies from "../../../components/table/policy/Policies";
import CWCreateFirstCTA from "../../../components/table/shared/create-first-cta/CWCreateFirstCTA";
import NewSectionTag from "../../../components/table/shared/NewSectionTag";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addNewSection?: (...args: any[]) => any;
  canManageSections?: boolean;
  isInternal?: boolean;
  moduleWorkspaceID?: number;
  showAddNewSection?: boolean;
  showCTA?: boolean;
  showCheckbox?: boolean;
  onCancelAddNewSection?: () => void;
};

function PoliciesDraft({
  addNewSection,
  showCTA,
  isInternal,
  showCheckbox,
  showAddNewSection,
  moduleWorkspaceID,
  canManageSections,
  onCancelAddNewSection,
}: Props) {
  return (
    <div className="policies-table-section" data-testid="policies-draft">
      <Switch>
        <Route exact path="/workspaces/:workspace_id/modules/policy/drafts">
          <div
            className="table-list-wrap no-margin table-list-wrap-wider policy-table"
            data-testid="policy-table"
          >
            <Policies showCheckbox={showCheckbox} />

            {showAddNewSection && (
              <NewSectionTag
                moduleWorkspaceID={moduleWorkspaceID}
                onCancel={onCancelAddNewSection}
              />
            )}

            {canManageSections && (
              <div className="tw-sticky tw-left-[25px] tw-ml-[25px]">
                <AddNewSectionButton onClick={addNewSection} />
              </div>
            )}

            {showCTA && isInternal && <CreateCTA />}

            {showCTA && !isInternal && (
              <CWCreateFirstCTA
                title="Add / Share Policies"
                sentences={[
                  'Create Policies for the collaborative workspace by clicking "Add New Policy" on the top to start.',
                  "Shared policies are visible here.",
                ]}
              />
            )}
          </div>
        </Route>
      </Switch>
    </div>
  );
}

export default PoliciesDraft;
