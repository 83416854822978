import {
  ENABLED,
  NotificationRuleData,
  RECURRING_INTERVAL_SCALE,
  RELATIVE_TO,
  SCALE,
  TRIGGER,
} from "@/stores/types/notification-rule-types";

import { initialCreateValues } from "./constants";
import {
  DateNotificationFormValues,
  NOTIFICATION_FREQUENCY,
  TimePeriod,
} from "./types";

/**
 * Serializes form data into a notification rule POST/PUT request body
 * @param formData notification form values
 * @returns notification rule POST/PUT request body
 */
export const serializeFormData = (
  formData: DateNotificationFormValues,
  record_type: string = "record_version",
): NotificationRuleData => {
  const isScheduled = formData.frequency === "one_time";
  const { triggerColumn } = formData;
  const isSameDayOf = formData.condition !== RELATIVE_TO.ON;

  const getScheduled: () => NotificationRuleData["data"]["scheduled"] = () => ({
    trigger_column: { options: [triggerColumn] },
    relative: { options: [formData.condition] },
    // @ts-expect-error TS(2322) FIXME: Type 'number[] | undefined' is not assignable to t... Remove this comment to see the full error message
    interval: isSameDayOf ? formData.timePeriod.numberOfOneTimeDays : undefined,
    // @ts-expect-error TS(2322) FIXME: Type '{ options: SCALE.DAILY[]; } | undefined' is ... Remove this comment to see the full error message
    scale: isSameDayOf ? { options: [SCALE.DAILY] } : undefined,
  });

  // @ts-expect-error TS(2322) FIXME: Type '() => { day: { options: DAYS_OF_WEEK[]; }; t... Remove this comment to see the full error message
  const getRecurring: () => NotificationRuleData["data"]["recurring"] = () => {
    const scaleByFrequency = {
      days: SCALE.DAILY,
      weeks: SCALE.WEEKLY,
      months: SCALE.MONTHLY,
    };

    const distanceValues = {
      days: formData.timePeriod.numberOfDailyDays,
      weeks: formData.timePeriod.numberOfWeeks,
      months: formData.timePeriod.numberOfMonths,
    };

    const recurringIntervalScaleValues = {
      days: RECURRING_INTERVAL_SCALE.DAY,
      weeks: RECURRING_INTERVAL_SCALE.WEEK,
      months: RECURRING_INTERVAL_SCALE.MONTH,
    };

    return {
      day: { options: formData.timePeriod.recurringWeekdays },
      trigger_column: { options: [triggerColumn] },
      relative: { options: [formData.condition] },
      scale: {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        options: [scaleByFrequency[formData.frequency]],
      },
      interval: {
        ignore_weekends:
          formData.frequency === "months"
            ? false
            : formData.timePeriod.excludeWeekends,
        ...(isSameDayOf
          ? {
              distance: {
                // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                value: distanceValues[formData.frequency],
              },
              scale: {
                // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                options: [recurringIntervalScaleValues[formData.frequency]],
              },
            }
          : undefined),
      },
    };
  };

  return {
    record_type,
    trigger:
      formData.frequency === "one_time" ? TRIGGER.ONE_TIME : TRIGGER.RECURRING,
    data: {
      enabled: {
        options: [formData.enabled ? ENABLED.TRUE : ENABLED.FALSE],
      },
      filters: formData.criteria
        // Remove criteria with no `fieldValues`
        .filter((criteria) => !!criteria.fieldValues.length)
        .map((singleCriteria) => ({
          field_name: { options: [singleCriteria.fieldName] },
          field_value: { options: singleCriteria.fieldValues },
        })),
      notification_type: { options: [formData.type] },
      recipients: {
        columns: { field_names: formData.recipients.columns },
        departments: { ids: formData.recipients.department_ids },
        users: { ids: formData.recipients.user_ids },
      },
      ...(isScheduled
        ? {
            scheduled: getScheduled(),
          }
        : {
            recurring: getRecurring(),
          }),
    },
  };
};

/**
 * Parses a notification rule from API into notification form values
 * @param notificationRule notification rule response from API
 * @returns notification form values
 */
export const parseNotificationRule = (
  notificationRule: NotificationRuleData,
): DateNotificationFormValues => {
  if (!notificationRule) {
    // @ts-expect-error TS(2322) FIXME: Type 'undefined' is not assignable to type 'DateNo... Remove this comment to see the full error message
    return;
  }

  const getFrequency = () => {
    if (notificationRule.trigger === TRIGGER.ONE_TIME) {
      return NOTIFICATION_FREQUENCY.ONE_TIME;
    }

    if (
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      notificationRule.data.recurring.interval.scale.options[0] ===
      RECURRING_INTERVAL_SCALE.DAY
    ) {
      return NOTIFICATION_FREQUENCY.DAILY;
    } else if (
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      notificationRule.data.recurring.interval.scale.options[0] ===
      RECURRING_INTERVAL_SCALE.WEEK
    ) {
      return NOTIFICATION_FREQUENCY.WEEKLY;
    } else if (
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      notificationRule.data.recurring.interval.scale.options[0] ===
      RECURRING_INTERVAL_SCALE.MONTH
    ) {
      return NOTIFICATION_FREQUENCY.MONTHLY;
    }
  };

  const frequency = getFrequency();
  const isOneTime = frequency === "one_time";

  // @ts-expect-error TS(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
  const getTimePeriod = (): TimePeriod => {
    if (frequency === "one_time") {
      return {
        ...initialCreateValues.timePeriod,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        numberOfOneTimeDays: notificationRule.data.scheduled.interval || [],
      };
    }

    if (frequency === "days") {
      return {
        ...initialCreateValues.timePeriod,
        numberOfDailyDays:
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          notificationRule.data.recurring.interval.distance.value,
        excludeWeekends:
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          notificationRule.data.recurring.interval.ignore_weekends,
      };
    }

    if (frequency === "weeks") {
      return {
        ...initialCreateValues.timePeriod,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        numberOfWeeks: notificationRule.data.recurring.interval.distance.value,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        recurringWeekdays: notificationRule.data.recurring.day.options,
      };
    }

    if (frequency === "months") {
      return {
        ...initialCreateValues.timePeriod,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        numberOfMonths: notificationRule.data.recurring.interval.distance.value,
      };
    }
  };

  return {
    // @ts-expect-error TS(2322) FIXME: Type 'NOTIFICATION_FREQUENCY | undefined' is not a... Remove this comment to see the full error message
    frequency,
    type: notificationRule.data.notification_type.options[0],
    enabled: notificationRule.data.enabled.options[0] === ENABLED.TRUE,
    timePeriod: getTimePeriod(),
    condition: isOneTime
      ? // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        notificationRule.data.scheduled.relative.options[0]
      : // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        notificationRule.data.recurring.relative.options[0],
    triggerColumn: isOneTime
      ? // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        notificationRule.data.scheduled.trigger_column.options[0]
      : // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        notificationRule.data.recurring.trigger_column.options[0],
    criteria: notificationRule.data.filters.map((filter) => ({
      fieldName: filter.field_name.options[0],
      fieldValues: filter.field_value.options,
    })),
    recipients: {
      columns: notificationRule.data.recipients.columns.field_names,
      user_ids: notificationRule.data.recipients.users.ids,
      department_ids: notificationRule.data.recipients.departments.ids,
    },
  };
};
