import { MiniTag } from "@themis/ui";
import { CustomCellRendererProps } from "ag-grid-react";
import React from "react";
import { generatePath } from "react-router-dom";

import { TRAINING_DETAILS_PATH } from "@/features/training/pages";

import { ThemisRecordActionsCell } from "../components/ThemisRecordActionsCell";

const recordType = "trainings";
export const TRAINING_TABLE_COLUMNS = (workspaceId: number) => [
  {
    headerName: "Name",
    field: "name",
    editable: false,
    minWidth: 250,
    cellRenderer: "identifierCellRenderer",
    cellRendererParams: (props: CustomCellRendererProps) => {
      const trainingId = props.data.id;
      return {
        to: generatePath(TRAINING_DETAILS_PATH, {
          workspace_id: workspaceId,
          trainingId,
        }),
        ...props,
      };
    },
  },
  {
    // TODO: PLACEHOLDER (NEED GENERIC STATUS COMPONENT)
    headerName: "Status",
    field: "status",
    minWidth: 200,
    cellRenderer: (props: CustomCellRendererProps) => {
      return <MiniTag>{props.value}</MiniTag>;
    },
  },
  {
    // TODO: PLACEHOLDER (NEED GENERIC FIELD_OPTION COMPONENT)
    headerName: "Type",
    field: "training_type",
    minWidth: 200,
    cellRenderer: (props: CustomCellRendererProps) => {
      return <MiniTag>{props.value}</MiniTag>;
    },
  },
  {
    headerName: "Notes",
    field: "notes",
    minWidth: 200,
    cellRenderer: "textRenderCell",
  },
  // TODO: Remaining fields

  {
    headerName: "",
    minWidth: 40,
    width: 40,
    sortable: false,
    resizable: false,
    pinned: "right" as const,
    cellStyle: () => {
      return { justifyContent: "center" };
    },
    cellRenderer: (props: CustomCellRendererProps) => {
      return (
        <ThemisRecordActionsCell
          recordType={recordType}
          recordId={props.data.id}
        />
      );
    },
  },
];
