import {
  Avatar,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@themis/ui";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { User } from "@/api";

const DEFAULT_USER_COUNT = 3;

type Props = {
  reviewers: Partial<User>[];
  text: string;
};

function CreativeViewReviewers({ reviewers, text }: Props) {
  const [open, setOpen] = useState(false);

  const popup = (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger
        asChild
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <Avatar
          size="sm"
          variant="basic"
          color="gray"
          className="tw-absolute tw-cursor-pointer tw-text-neutral-500"
          style={{ left: `${24 * DEFAULT_USER_COUNT}px` }}
        >
          +{reviewers.length - DEFAULT_USER_COUNT}
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="center"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <DropdownMenuItem className="tw-flex tw-items-center tw-gap-2">
          Reviewers
        </DropdownMenuItem>
        {reviewers.slice(DEFAULT_USER_COUNT, reviewers.length).map((user) => (
          <DropdownMenuItem
            className="tw-flex tw-items-center tw-gap-2"
            key={user.id}
          >
            <Avatar colorIndex={user.icon_color_index} size="sm">
              {user.initials}
            </Avatar>
            <div className="tw-text-xs tw-font-normal tw-text-neutral-500">
              {user.full_name}
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );

  function renderReviewers() {
    return (
      <span className="tw-flex tw-h-5 tw-items-center tw-space-x-3 tw-bg-neutral-25 tw-px-3 tw-py-1.5 tw-text-xs tw-font-normal">
        {text && (
          <div className="tw-pr-2 tw-text-xs tw-font-normal tw-text-neutral-300">
            {text}
          </div>
        )}
        <div
          className="tw-relative tw-h-5"
          style={{
            width:
              reviewers.length > DEFAULT_USER_COUNT
                ? "92px"
                : `${23 * reviewers.length}px`,
          }}
          data-testid="view-module-users"
        >
          {reviewers.slice(0, DEFAULT_USER_COUNT).map((user, index) => (
            <Avatar
              key={`view-module-users-${user.id}`}
              colorIndex={user.icon_color_index}
              size="sm"
              className="tw-absolute tw-cursor-default"
              style={{ left: `${24 * index}px` }}
            >
              {user.initials}
            </Avatar>
          ))}
          {reviewers.length > DEFAULT_USER_COUNT && popup}
        </div>
      </span>
    );
  }

  return renderReviewers();
}

export default observer(CreativeViewReviewers);
