import { observer } from "mobx-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { useFilters } from "@/hooks/useFilters";
import { useTableData } from "@/hooks/useTableData";
import { pathMatchesPattern } from "@/utils/routing";

import { LOADING_DELAY } from "../../constants";
import Loading from "../../Loading";
import Table from "../Table";
import { urlToScreen } from "./constants";
import RiskAssessmentTemplates from "./RiskAssessmentTemplates";
import Templates from "./Templates";
import {
  activeVDDUrl,
  inactiveVDDUrl,
  RATemplatesVDDUrl,
  templatesVDDUrl,
} from "./urlPaths";
import VendorsHeader from "./VendorsHeader";
import VendorsScreen from "./VendorsScreen";

function VendorDueDiligence() {
  // Import MobX stores
  const mainStore = useMainStore();

  const location = useLocation();
  useTableData();

  // State
  const [loading, setLoading] = useState(true);

  // Variables
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const { sharedChecklists } = mainStore.vendors;
  const { list: fields } = mainStore.fields;
  const canCreateNewVendors = hasModuleWriteAccess;

  const activeScreen = useMemo(() => {
    const matchingUrlPattern = Object.keys(urlToScreen).find((pattern) =>
      pathMatchesPattern(location.pathname, pattern),
    );

    if (!matchingUrlPattern) {
      return undefined;
    }

    const screenName = urlToScreen[matchingUrlPattern];
    if (screenName) {
      return screenName;
    }

    return undefined;
  }, [urlToScreen, location.pathname]);

  // refs
  const timer = useRef();

  // Hooks
  const history = useHistory();

  const filtersData = useFilters({
    fields: fields.filter(
      (item) =>
        ![
          "business_name",
          "type",
          "logo",
          "status",
          "grouping",
          "pii_notes",
          "other_info",
          "company_website",
          "company_contact",
          "other_documents",
          "vendor_contact",
          "vendor_contact_email_address",
          "summary_of_activity_outsourced",
          "deactivate_date",
        ].includes(item.name),
    ),
  });

  useEffect(() => {
    if (activeScreen !== "ActiveVendors") {
      return;
    }

    const { pathname } = window.location;
    history.replace({ pathname });
  }, [activeScreen]);

  useEffect(() => {
    timer.current && clearTimeout(timer.current);

    // @ts-expect-error TS(2322) FIXME: Type 'Timeout' is not assignable to type 'undefine... Remove this comment to see the full error message
    timer.current = setTimeout(() => {
      const loaded = fields.length > 0 || sharedChecklists !== null;
      setLoading(!loaded);
    }, LOADING_DELAY);
  }, [fields, sharedChecklists]);

  useEffect(() => {
    mainStore.vendors.setCurrentVendor({});
  }, []);

  if (loading) {
    return <Loading loadingLayout="grid" showTableHeader />;
  }

  return (
    <Table>
      <div className="vdd-table" data-testid="vdd-table">
        <VendorsHeader
          activeScreen={activeScreen}
          filtersData={filtersData}
          disableCreationForLimit={!canCreateNewVendors}
        />
        <Switch>
          <Route exact path={activeVDDUrl}>
            <VendorsScreen
              canCreateNewVendors={canCreateNewVendors}
              isActiveScreen
            />
          </Route>
          <Route exact path={templatesVDDUrl}>
            <Templates editable={hasModuleWriteAccess} />
          </Route>
          <Route exact path={RATemplatesVDDUrl}>
            <RiskAssessmentTemplates editable={hasModuleWriteAccess} />
          </Route>
          <Route exact path={inactiveVDDUrl}>
            <VendorsScreen
              isActiveScreen={false}
              canCreateNewVendors={canCreateNewVendors}
            />
          </Route>
        </Switch>
      </div>
    </Table>
  );
}

VendorDueDiligence.defaultProps = {
  defaultScreen: "ActiveVendors",
};

export default observer(VendorDueDiligence);
