import React from "react";

type Props = {
  title: string;
  sentences?: string[];
};

function CWCreateFirstCTA({ title, sentences }: Props) {
  return (
    <div className="no-column-wrap" data-testid="create-first-cta">
      <div className="no-column-block no-column-block-borderless-cw">
        <div className="no-column-procedure">
          <div className="no-column-title title-color">{title}</div>
          <div className="sentences">
            {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
            {sentences.map((sentence, index) => (
              <div className="sentence" key={`cw-sentence-${index}`}>
                {sentence}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

CWCreateFirstCTA.defaultProps = {
  sentences: [],
};

export default CWCreateFirstCTA;
