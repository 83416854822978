import { get, isEqual, keys } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";

import { TableName } from "@/api";

import { getPermissionMap } from "../helpers/PermissionsHelpers";
import { MainStore } from "../Main";
import { ModuleIdentifier } from "../types/module-workspaces-types";
import { Workspace } from "../types/workspace-types";

const USER_ACTIONS = [
  "add_remove_sections",
  "add_comments",
  "archive_unarchive",
  "delete_records",
  "publish_lock_records",
];

const SUPPORT_USER_ACTIONS = [
  "add_comments",
  "archive_unarchive",
  "delete_records",
  "publish_lock_records",
];

const checkPermissionsAndTargets = (
  // @ts-expect-error TS(7006) FIXME: Parameter 'permissionSet' implicitly has an 'any' ... Remove this comment to see the full error message
  permissionSet,
  // @ts-expect-error TS(7006) FIXME: Parameter 'requiredPermissions' implicitly has an ... Remove this comment to see the full error message
  requiredPermissions,
  // @ts-expect-error TS(7006) FIXME: Parameter 'requiredModules' implicitly has an 'any... Remove this comment to see the full error message
  requiredModules,
) => {
  const permissionSetActions = get(permissionSet, "data.module.actions");
  const fullPermissions = keys(permissionSetActions)
    .filter((actionName) =>
      get(permissionSetActions, `${actionName}.state.edit`),
    )
    .sort();
  const readOnlyPermissions = keys(permissionSetActions)
    .filter(
      (actionName) =>
        !get(permissionSetActions, `${actionName}.state.edit`) &&
        get(permissionSetActions, `${actionName}.state.view`),
    )
    .sort();
  const requiredPermissionsSorted = requiredPermissions.sort();

  if (
    !isEqual(requiredPermissionsSorted, fullPermissions) ||
    readOnlyPermissions.length > 0
  ) {
    return false;
  }

  const targets = get(permissionSet, "data.module.targets");
  const fullModules = keys(targets)
    .filter((moduleName) => get(targets, `${moduleName}.state.edit`))
    .sort();
  const readOnlyModules = keys(targets)
    .filter(
      (moduleName) =>
        !get(targets, `${moduleName}.state.edit`) &&
        !get(targets, `${moduleName}.state.view`),
    )
    .sort();

  if (
    !isEqual(requiredModules.sort(), fullModules) ||
    readOnlyModules.length > 0
  ) {
    return false;
  }

  return true;
};

export default class ContextStore {
  mainStore;

  // Observables (ordered by hierarchy)
  companyID: number | null = null;
  activeWorkspace: Workspace | null = null;
  workspaceID: number | null = null;
  /**
   * themis module identifier e.g. `'policy'`
   */
  themisModuleIdentifier: ModuleIdentifier | null = null;
  /**
   * ID of the module user is currently on e.g. for policy `id === 7`
   */
  moduleWorkspaceID: number | null = null;
  tableName: TableName | null = null;
  tableID: number | null = null;
  isModuleActive = null;
  subModuleTableID: number | null = null;
  subModuleTableName = null;
  highlightedRecordId = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      companyID: observable,
      activeWorkspace: observable,
      workspaceID: observable,
      themisModuleIdentifier: observable,
      moduleWorkspaceID: observable,
      tableName: observable,
      tableID: observable,
      isModuleActive: observable,
      subModuleTableID: observable,
      subModuleTableName: observable,
      highlightedRecordId: observable,

      setCompanyID: action,
      setActiveWorkspace: action,
      setThemisModuleIdentifier: action,
      setModuleWorkspaceID: action,
      setTableName: action,
      setTableID: action,
      setIsModuleActive: action,
      setSubModuleTableName: action,
      setSubModuleTableID: action,
      setHighlightedRecordId: action,

      isCompanySuperAdmin: computed,
      isCompanyAdmin: computed,
      isWorkspaceAdmin: computed,
      isAdmin: computed,
      isUser: computed,
      isSupport: computed,
      isCustomerSupport: computed,
      isFinraSupport: computed,
      isIW: computed,
      isCW: computed,
      viewPermissions: computed,
      editPermissions: computed,
    });

    this.mainStore = mainStore;
  }

  // Actions (ordered by hierarchy)

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCompanyID(value) {
    if (value) {
      this.companyID = value;
    } else {
      this.companyID = null;
    }
  }

  setActiveWorkspace(value: Workspace | null) {
    this.activeWorkspace = value;
    if (value) {
      this.workspaceID = value?.id;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setThemisModuleIdentifier(value) {
    if (value) {
      this.themisModuleIdentifier = value;
      this.setIsModuleActive(true);
    } else {
      this.setIsModuleActive(false);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setModuleWorkspaceID(value) {
    this.moduleWorkspaceID = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setTableName(value) {
    this.tableName = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setTableID(value) {
    // Reset RecordVersions if they belong to a different Table
    const { list } = this.mainStore.recordVersions;
    if (list.length > 0 && list[0].table_id !== value) {
      this.mainStore.recordVersions.resetList();
    }

    this.tableID = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setIsModuleActive(value) {
    this.isModuleActive = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setSubModuleTableID(value) {
    this.subModuleTableID = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setHighlightedRecordId(value) {
    this.highlightedRecordId = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setSubModuleTableName(value) {
    this.subModuleTableName = value;
  }

  // Computed
  get isCompanySuperAdmin() {
    const { user } = this.mainStore.users;
    const hasRole = user.roles?.find(
      ({ permission_set }) =>
        permission_set.name === "Super Admin" &&
        permission_set.permission_type === "company" &&
        permission_set.company_id === this.companyID &&
        get(permission_set, "data.company.all.state.edit"),
    );
    return Boolean(hasRole);
  }

  get isCompanyAdmin() {
    const { user } = this.mainStore.users;
    const hasRole = user.roles?.find(
      ({ permission_set }) =>
        permission_set.name === "Company Admin" &&
        permission_set.permission_type === "company" &&
        permission_set.company_id === this.companyID &&
        get(permission_set, "data.company.all.state.edit"),
    );
    return Boolean(hasRole);
  }

  get isWorkspaceAdmin() {
    const { user } = this.mainStore.users;
    const hasRole = user.roles?.find(
      ({ workspace_id, permission_set }) =>
        permission_set.permission_type === "workspace_module" &&
        permission_set.company_id === this.companyID &&
        workspace_id === this.workspaceID &&
        get(permission_set, "data.workspace.all.state.edit"),
    );
    return Boolean(hasRole);
  }

  get isAdmin() {
    return (
      this.isCompanySuperAdmin || this.isCompanyAdmin || this.isWorkspaceAdmin
    );
  }

  get isUser() {
    const { user } = this.mainStore.users;
    const { modules } = this.mainStore.permissions;

    const hasRole = user.roles?.find(({ workspace_id, permission_set }) => {
      if (
        permission_set.permission_type !== "workspace_module" ||
        permission_set.company_id !== this.companyID ||
        workspace_id !== this.workspaceID
      ) {
        return false;
      }

      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
      const moduleNames = modules.map((module) => module.id);
      return checkPermissionsAndTargets(
        permission_set,
        USER_ACTIONS,
        moduleNames,
      );
    });
    return Boolean(hasRole);
  }

  // id just make the call for isSupport right here
  get isSupport() {
    const { user } = this.mainStore.users;
    const hasRole = user.roles?.find(({ workspace_id, permission_set }) => {
      if (
        permission_set.permission_type !== "workspace_module" ||
        permission_set.company_id !== this.companyID ||
        workspace_id !== this.workspaceID
      ) {
        return false;
      }

      const moduleNames = ["complaints", "customer_support", "finra"];

      return checkPermissionsAndTargets(
        permission_set,
        SUPPORT_USER_ACTIONS,
        moduleNames,
      );
    });
    return Boolean(hasRole);
  }

  get isCustomerSupport() {
    const { user } = this.mainStore.users;
    const hasRole = user.roles?.find(({ workspace_id, permission_set }) => {
      if (
        permission_set.permission_type !== "workspace_module" ||
        permission_set.company_id !== this.companyID ||
        workspace_id !== this.workspaceID
      ) {
        return false;
      }

      return checkPermissionsAndTargets(permission_set, SUPPORT_USER_ACTIONS, [
        "customer_support",
      ]);
    });
    return Boolean(hasRole);
  }

  get isFinraSupport() {
    const { user } = this.mainStore.users;
    const hasRole = user.roles?.find(({ workspace_id, permission_set }) => {
      if (
        permission_set.permission_type !== "workspace_module" ||
        permission_set.company_id !== this.companyID ||
        workspace_id !== this.workspaceID
      ) {
        return false;
      }

      return checkPermissionsAndTargets(permission_set, SUPPORT_USER_ACTIONS, [
        "finra",
      ]);
    });
    return Boolean(hasRole);
  }

  get isIW() {
    return this.activeWorkspace?.is_internal === true;
  }

  get isCW() {
    return this.activeWorkspace?.is_internal === false;
  }

  get viewPermissions() {
    const { user } = this.mainStore.users;
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Partial<User>' is not assignable... Remove this comment to see the full error message
    return getPermissionMap(user, this.workspaceID, this.companyID, "view");
  }

  get editPermissions() {
    const { user } = this.mainStore.users;
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Partial<User>' is not assignable... Remove this comment to see the full error message
    return getPermissionMap(user, this.workspaceID, this.companyID, "edit");
  }

  // Store Helpers

  cleanup() {
    this.activeWorkspace = null;
    this.workspaceID = null;
    this.themisModuleIdentifier = null;
    this.moduleWorkspaceID = null;
    this.tableName = null;
    this.tableID = null;
    this.subModuleTableID = null;
    this.highlightedRecordId = null;
  }
}
