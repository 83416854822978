import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

type Props = {
  vendorID: string;
};

function VendorEmptyRiskAssessments({ vendorID }: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  return (
    <div
      className="no-column-wrap vdd-add-first-questionnaire"
      data-testid="create-first-cta"
    >
      <div className="no-column-block">
        <div className="no-column-procedure">
          <div className="no-column-title">
            <h3>Add your Risk Assessment</h3>
          </div>
          <p style={{ marginBottom: "24px" }}>
            Click “Add New Risk Assessment” below to create the first
            questionnaire under this vendor. If you want to start with a
            template, find a suitable template and click “Use” to start building
            it.
          </p>
          <Link
            to={`/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendorID}/build_risk_assessment/create`}
            className="table-button questionnaire"
          >
            Add New Risk Assessment
          </Link>
        </div>
      </div>
    </div>
  );
}

export default observer(VendorEmptyRiskAssessments);
