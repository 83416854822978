import { observer } from "mobx-react";
import React from "react";

import RiskRegisterScoringValueCell from "../../risk-register/RiskRegisterScoringValueCell";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  fieldName: string;
};

function ViewRiskRegisterScoringValueCell({ recordVersion, fieldName }: Props) {
  return (
    <RiskRegisterScoringValueCell
      fieldName={fieldName}
      matrixCell={
        fieldName === "inherent_risk_rating"
          ? recordVersion.inherent_risk_rating_cell
          : recordVersion.residual_risk_rating_cell
      }
      width="100%"
    />
  );
}

ViewRiskRegisterScoringValueCell.defaultProps = {
  isEditable: true,
};

export default observer(ViewRiskRegisterScoringValueCell);
