import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import { MainStore } from "../Main";

export default class AttestationListsStore {
  mainStore: MainStore;

  // Observable objects
  current = {};

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      current: observable,
      setCurrent: action,
    });

    this.mainStore = mainStore;
  }

  // POST /api/react/module_workspaces/:module_workspace_id/attestation_lists
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async create(moduleWorkspaceID, params) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/attestation_lists`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.setCurrent(null);
        return;
      }

      this.setCurrent(response.data.attestation_list);
      this.mainStore.dynamicTable.clearSelectedRecordVersionIDs();

      return response.data.attestation_list;
    } catch (error) {
      window.console.log(`"AttestationLists#create" error ${error}`);
    }
  }

  // Actions

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrent(value) {
    if (value) {
      this.current = value;
    } else {
      this.current = {};
    }
  }

  // Store Helpers

  cleanup() {
    this.setCurrent({});
  }
}
