import React, { useState } from "react";
import Popup from "reactjs-popup";

import * as ApiKeysAPI from "@/api/legacy/api-keys";
import { APIKey } from "@/api/legacy/api-keys/types";
import { Button, Flex, IconButton, Typography } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

type APIKeyInformationProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  id?: number;
  isEdit?: boolean;
  refetch?: VoidFunction;
  title?: string;
};

function APIKeyInformation({
  showModal,
  setShowModal,
  id,
  isEdit,
  refetch,
  title,
}: APIKeyInformationProps) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [value, setValue] = useState(title || "");
  const [apiKeyData, setApiKeyData] = useState<Partial<APIKey>>({});

  // Functions
  async function handleCreate() {
    try {
      const data = await ApiKeysAPI.createApiKey(value);
      setApiKeyData(data);
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      refetch();

      mainStore.toast.setInfoText("API key was successfully created.");
    } catch (error) {
      mainStore.toast.setErrorText("Key name must be unique!");
      window.console.log(error);
    }
  }

  async function handleUpdate() {
    try {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      const data = await ApiKeysAPI.updateApiKey(id, value);
      setApiKeyData(data);
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      refetch();

      mainStore.toast.setInfoText("API key was successfully updated.");
      setShowModal(false);
    } catch (error) {
      mainStore.toast.setErrorText("Key name must be unique!");
      window.console.log(error);
    }
  }

  async function copyToClipboard() {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    await navigator.clipboard.writeText(apiKeyData.api_key.token);
    mainStore.toast.setInfoText("API key copied to clipboard!");
  }

  function handleModalClose() {
    setShowModal(false);
    setApiKeyData({});
    setValue(title || "");
  }

  return (
    <Popup
      modal
      open={showModal}
      onOpen={() => setShowModal(true)}
      onClose={handleModalClose}
      className="information-modal"
      closeOnDocumentClick={false}
    >
      <Flex className="api-key-information" column rowGap={40}>
        <IconButton
          icon="close"
          className="api-key-information__close"
          onClick={() => setShowModal(false)}
          transparent
          size="de"
        />
        {!isEdit && (
          <Flex column rowGap={4}>
            <Typography
              label="Your New API Key"
              size="md"
              color="generalMidnightDark"
            />
            {Object.keys(apiKeyData).length > 0 && (
              <Typography
                label={
                  <>
                    Your new API key has been created. Set{" "}
                    <span className="text-highlighting">
                      Authorization: Bearer YOUR_TOKEN
                    </span>{" "}
                    HTTP header for all your requests. You will not be able to
                    view this token again once you close this window, so be sure
                    to record it securely.
                  </>
                }
                size="sm"
                color="extrasBlueGrayDarker"
                className="api-key-information__text"
              />
            )}
          </Flex>
        )}
        <Flex
          column
          rowGap={12}
          alignCenter
          className="api-key-information__form"
        >
          {!isEdit && !!Object.keys(apiKeyData).length && (
            <input
              disabled
              // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
              defaultValue={apiKeyData.api_key.token}
              className="api-key-information__form-input api-key-information__form-key"
            />
          )}
          <Flex column className="api-key-information__block-name" rowGap={4}>
            <Typography
              label="Name"
              size="xs"
              weight="semiBold"
              color="extrasBlueGrayDarker"
            />
            <input
              type="text"
              value={value}
              placeholder="– Enter Key Name –"
              onChange={(event) => setValue(event.target.value)}
              disabled={Boolean(apiKeyData?.api_key?.token)}
              className="api-key-information__form-input api-key-information__form-name"
            />
          </Flex>
        </Flex>
        <Flex alignCenter justifyCenter columnGap={8}>
          {isEdit ? (
            <Button
              label="Save"
              size="de"
              type="button"
              onClick={handleUpdate}
              theme="tertiary"
              disabled={value.length === 0 || title === value}
            />
          ) : (
            <>
              {Object.keys(apiKeyData).length ? (
                <Button
                  icon="copy"
                  label="Copy to clipboard"
                  size="de"
                  type="button"
                  onClick={copyToClipboard}
                />
              ) : (
                <Button
                  label="Create"
                  size="de"
                  type="button"
                  onClick={handleCreate}
                  disabled={!value.length}
                />
              )}
              <Button
                label="Close"
                size="de"
                type="button"
                onClick={() => setShowModal(false)}
                theme="tertiary"
              />
            </>
          )}
        </Flex>
      </Flex>
    </Popup>
  );
}

export default APIKeyInformation;
