import classNames from "classnames";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import arrowDownIcon from "../../../images/table-image/icon/arrow-down-black.svg";
import { Typography } from "../../Elements";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedOptions: any[];
  color?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange?: (...args: any[]) => any;
  isDisabled?: boolean;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any[];
  testId?: string;
  tooltip?: string;
  value?: string;
};

function MultipleSelectField({
  selectedOptions,
  color,
  isDisabled,
  testId,
  handleChange,
  options,
  value,
  tooltip,
  label,
}: Props) {
  // State
  const [open, setOpen] = useState(false);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
  const onChange = (option) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleChange(option);
  };

  return (
    <div>
      <Typography
        size="sm"
        color="generalDarkGray"
        data-tooltip-top={tooltip}
        label={label}
        weight="semiBold"
      />
      <Popup
        position="bottom right"
        trigger={
          <button
            disabled={isDisabled}
            className={classNames("reports-module-dropdown qa-select", {
              disabled: isDisabled,
              opened: open,
            })}
            data-testid={testId}
          >
            <div className="module-item">
              {selectedOptions.length > 0 ? (
                selectedOptions.map((item) => (
                  <Typography
                    key={item.name}
                    data-testid="selected-item"
                    className="module-item-value"
                    size="sm"
                    // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '"generalW... Remove this comment to see the full error message
                    color={color || "generalMidnightDark"}
                    label={item.display_name}
                    weight="semiBold"
                  />
                ))
              ) : (
                <Typography
                  data-testid="selected-item"
                  size="sm"
                  // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '"generalW... Remove this comment to see the full error message
                  color={color || "generalMidnightDark"}
                  label={isDisabled ? "Please select column" : value}
                  weight="semiBold"
                />
              )}
            </div>
            <img src={arrowDownIcon} alt="open" />
          </button>
        }
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        keepTooltipInside
      >
        <ul className="table-dropdown reports-dropdown-popup">
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          {options.map((option, index) => (
            <li
              key={index}
              data-testid={option.name}
              className={classNames("flex", {
                active: value === option.display_name,
              })}
              onClick={() => onChange(option)}
            >
              <div className="table-checkbox">
                <input
                  type="checkbox"
                  id={`option-${index}`}
                  onClick={(event) => event.stopPropagation()}
                  checked={selectedOptions
                    .map((item) => item.display_name)
                    .includes(option.display_name)}
                  readOnly
                />
                <label htmlFor={`option-${index}`} />
              </div>
              {option.display_name}
            </li>
          ))}
        </ul>
      </Popup>
    </div>
  );
}

MultipleSelectField.defaultProps = {
  options: [],
};

export default MultipleSelectField;
