import { MiniTag, MiniTagProps } from "@themis/ui";
import React from "react";

const FINDING_ACTIVE_COLOR_MAP: Record<string, MiniTagProps["color"]> = {
  Open: "grey",
  Sent: "yellow",
  Closed: "primary",
} as const;

const FINDING_ACTIVE_LABEL_MAP: Record<string, string> = {
  open: "Open",
  sent: "Sent",
  closed: "Closed",
} as const;

export function FindingActiveTag({ status = "open" }: { status?: string }) {
  return (
    <MiniTag color={FINDING_ACTIVE_COLOR_MAP[status]}>
      {FINDING_ACTIVE_LABEL_MAP[status]}
    </MiniTag>
  );
}
