import _, { snakeCase } from "lodash";
import React from "react";

import { Typography } from "@/components/Elements";
import { Filter } from "@/components/filters/types";
import { useMainStore } from "@/contexts/Store";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";

type Props = {
  data: {
    count: number;
    label: string;
    color: string;
  }[];
  filtersViewEnabled: boolean;
  setFiltersViewEnabled: (arg: boolean) => void;
};

const TileLegendStatusPieChart = ({
  data,
  filtersViewEnabled,
  setFiltersViewEnabled,
}: Props) => {
  const mainStore = useMainStore();

  const nonEmptyValues = _.filter(data, (entry) => entry.count > 0);
  const { setTableFiltersParam, getTableFiltersParam } = useUpdateFilter();
  const filters = getTableFiltersParam();

  function handleLegendItemClick(label: string) {
    if (!filtersViewEnabled) {
      setFiltersViewEnabled(true);
    }

    addFilter(label);
  }

  function addFilter(label: string) {
    const currentFiltersWithoutStatus = filters.filter(
      (filter: Filter) => filter.name !== "status",
    );

    return setTableFiltersParam([
      ...currentFiltersWithoutStatus,
      {
        name: "status",
        condition: mainStore.filters.getConditionText(
          "com.askthemis.types.v1.option",
          true,
        ),
        options: [{ label, value: snakeCase(label) }],
      },
    ]);
  }

  return (
    <div className="donut-tile-legend">
      {_.map(nonEmptyValues, (entry) => (
        <div className="donut-tile-legend__item" key={entry.label}>
          <div
            className="donut-tile-legend__item__marker"
            style={{ backgroundColor: entry.color }}
          />
          <div
            className="clickable-title"
            onClick={() => handleLegendItemClick(entry.label)}
          >
            <Typography
              label={entry.label}
              size="xs"
              weight="medium"
              color="extrasBlueGrayDarker"
              title={entry.label}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TileLegendStatusPieChart;
