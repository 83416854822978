import axios from "axios";
import { action, makeObservable, observable } from "mobx";

import { PartnersAPI } from "@/api/legacy/risk-assessment/PartnersApi";

import type { RAPartner } from "../../features/risk-assessment";
import { MainStore } from "../Main";
import { IndexParams, ModuleStore } from "../types/module-store-types";

export default class RiskAssessmentPartners implements ModuleStore {
  mainStore: MainStore;

  // Observable objects
  partners: Array<RAPartner> = [];

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      partners: observable,
      setPartners: action,
    });

    this.mainStore = mainStore;
  }

  // API Calls
  async index({ workspaceID, sortParams }: IndexParams) {
    if (!workspaceID) {
      window.console.error("workspaceID is missing");
      return;
    }

    this.controller = new AbortController();
    try {
      const data = await PartnersAPI.list(
        workspaceID,
        this.controller.signal,
        sortParams,
      );
      this.setPartners(data.record_versions);
      this.mainStore.fields.setList(data.fields);
    } catch (error) {
      this.setPartners([]);
      window.console.log(`"RiskAssessmentPartners#index" error ${error}`);
    }
  }

  async createPartner(accountId: number, sectionTagId?: number) {
    try {
      await PartnersAPI.create(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
        this.mainStore.context.workspaceID,
        accountId,
        sectionTagId,
      );

      return true;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 422) {
        this.mainStore.toast.setErrorText(`${error.response.data.errors.base}`);
      }
      window.console.log(
        `"RiskAssessmentPartners#createPartner" error ${error}`,
      );

      return false;
    }
  }

  // actions
  setPartners(partners: Array<RAPartner>) {
    this.partners = partners;
    this.mainStore.recordVersions.setList(partners);
  }

  // Store Helpers
  cleanup() {
    this.setPartners([]);
  }

  abort() {
    this.controller?.abort();
  }
}
