import { AxiosResponse } from "axios";

import {
  CreateRiskMethodologyRequest,
  GetRiskMethodologiesResponse,
  GetRiskMethodologyResponse,
  RiskMethodology,
  UpdateRiskMethodologyRequest,
} from "@/features/risk-assessment";

import api from "../api";

export const RiskMethodologyAPI = {
  // GET /api/react/workspaces/:workspace_id/risk_methodologies
  async getAll(
    workspaceId: number,
    signal: AbortSignal,
  ): Promise<RiskMethodology[]> {
    const response = await api.get<GetRiskMethodologiesResponse>(
      `/workspaces/${workspaceId}/risk_methodologies`,
      {
        signal,
      },
    );

    return response.data.risk_methodologies;
  },
  // POST /api/react/risk_methodologies
  async create(
    workspaceId: number,
    request: CreateRiskMethodologyRequest,
  ): Promise<RiskMethodology> {
    const response = await api.post<
      CreateRiskMethodologyRequest,
      AxiosResponse<{ risk_methodology: RiskMethodology }>
    >(`/workspaces/${workspaceId}/risk_methodologies`, request);

    return response.data.risk_methodology;
  },
  // PUT /api/react/risk_methodologies/:id
  async update(
    workspaceId: number,
    id: number,
    request: UpdateRiskMethodologyRequest,
  ): Promise<RiskMethodology> {
    const response = await api.put<
      UpdateRiskMethodologyRequest,
      AxiosResponse<{ risk_methodology: RiskMethodology }>
    >(`/workspaces/${workspaceId}/risk_methodologies/${id}`, request);

    return response.data.risk_methodology;
  },
  // GET /api/react/risk_methodologies/:id
  async get(
    workspaceId: number,
    riskMethodologyId: number,
  ): Promise<GetRiskMethodologyResponse> {
    const response = await api.get<GetRiskMethodologyResponse>(
      `/workspaces/${workspaceId}/risk_methodologies/${riskMethodologyId}`,
    );
    return response.data;
  },

  delete(
    workspaceId: number,
    riskMethodologyId: number,
  ): Promise<{ data: { success: boolean } }> {
    return api.delete(
      `/workspaces/${workspaceId}/risk_methodologies/${riskMethodologyId}`,
    );
  },
};
