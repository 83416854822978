/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-date-fns";
import classNames from "classnames";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { Typography } from "../../Elements";
import ExportDashboard from "../common/MetricsPage/dashboards/components/ExportDashboard";
import { DateFilter } from "../dateFilter";
import { legendHeightPlugin } from "./chartHelpers";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

type HorizontalBarChartProps = {
  groupColumnDefault?: string;
  chartData: any;
  chartOptions: any;
  groupedByField?: string;
  index: number;
  showDatepicker?: boolean;
  barRef?: any;
  onClick?: any;
  chartTitle: string;
};

const RecordsByMonthHorizontalStackedBar = ({
  chartData,
  chartOptions,
  index = 0,
  showDatepicker = false,
  barRef,
  onClick,
  chartTitle,
}: HorizontalBarChartProps) => {
  const mainStore = useMainStore();

  const [openDatepicker, setOpenDatepicker] = useState(false);

  const {
    handleDisplayRange,
    validRange,
    fromValue,
    toValue,
    dayPickerTrigger,
    renderDayPicker,
    // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
  } = DateFilter({
    fromParam: dayjs().subtract(6, "M").format("YYYY-MM-DD"),
    toParam: dayjs().add(6, "M").format("YYYY-MM-DD"),
  });

  function handleDatepickerClose() {
    setOpenDatepicker(false);
    handleDisplayRange();
    if (validRange) {
      mainStore.reports.setFromValue(fromValue);
      mainStore.reports.setToValue(toValue);
    }
  }

  const headerClass = classNames("report-dashboard-header no-top-padding");

  return (
    <div>
      <div className={headerClass}>
        <div className="report-dashboard-header-block graph-title-container title-container">
          <div className="graph-title">{chartTitle}</div>
          <div className="report-dashboard-header-right">
            {showDatepicker && (
              <Popup
                position="bottom right"
                trigger={dayPickerTrigger}
                open={openDatepicker}
                onOpen={() => setOpenDatepicker(true)}
                onClose={() => handleDatepickerClose()}
                keepTooltipInside
              >
                {renderDayPicker}
              </Popup>
            )}
            <ExportDashboard
              fileDownloadName={chartTitle}
              chartId={chartTitle}
              forTables={false}
            />
          </div>
        </div>
      </div>
      <div className="report-bar-chart-container" id={chartTitle}>
        {isEmpty(chartData?.labels) ? (
          <div className="no-chart-data">
            <div>
              <Typography
                size="sm"
                color="generalMidnightDark"
                label="No Data Yet"
                weight="semiBold"
              />
            </div>
          </div>
        ) : (
          <Bar
            ref={barRef}
            data={chartData}
            plugins={[legendHeightPlugin]}
            options={chartOptions}
            id={`stackedHorizontalBarChart-${index}`}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
};

export default observer(RecordsByMonthHorizontalStackedBar);
