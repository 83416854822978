import classNames from "classnames";
import { observer } from "mobx-react";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";

import PasswordInput from "@/components/form/PasswordInput";

import { validatePassword } from "../utils/validatePassword";

function PasswordFields(props: {
  onPasswordChange: Dispatch<SetStateAction<string>>;
  onValidityChange: Dispatch<SetStateAction<boolean>>;
}) {
  const [password, setPasswordValue] = useState("");
  const [confirmation, setConfirmation] = useState("");

  const validation = useMemo(
    () => validatePassword(password, confirmation),
    [password, confirmation],
  );

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(event.target.value);
    props.onPasswordChange(event.target.value);
  };

  const handleConfirmationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmation(event.target.value);
  };

  useEffect(() => {
    props.onValidityChange(validation.isValid);
  }, [validation.isValid]);

  return (
    <>
      <PasswordInput
        placeholder="Create Password"
        name="password"
        onChange={handlePasswordChange}
        autoComplete="new-password"
      />
      <span className="password-requirements-header">
        Password must include:
      </span>
      <ul className="password-requirements">
        {validation.contentRules.map((rule, index) => (
          <li
            className={classNames("password-requirement", {
              "password-error": password && !rule.isValid,
            })}
            key={index}
          >
            - {rule.label}
          </li>
        ))}
      </ul>
      <PasswordInput
        name="password_confirmation"
        placeholder="Confirm Password"
        onChange={handleConfirmationChange}
        autoComplete="new-password"
      />
      {!validation.doesMatch && confirmation && (
        <p className="error-input">Passwords do not match</p>
      )}
    </>
  );
}

export default observer(PasswordFields);
