import "./styles.scss";

import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import { Button, Flex, Typography } from "@/components/Elements";
import ThemisLogo from "@/images/logo.svg";

type LoadingWrapperProps = {
  loadingError: ReactNode;
};

export default function LoadingError({ loadingError }: LoadingWrapperProps) {
  return (
    <Flex
      fullWidth
      fullHeight
      justifyCenter
      alignCenter
      column
      className="loading-error"
    >
      <Link to="/home" className="loading-error__home-link">
        <img src={ThemisLogo} alt="themis-logo" />
      </Link>
      <Typography
        label={loadingError}
        color="generalMidnightDark"
        size="lg"
        weight="semiBold"
      />
      <Button onClick={() => window.location.reload()} label="Reload Page" />
    </Flex>
  );
}
