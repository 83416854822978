import {
  Button,
  Checkbox,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from "@themis/ui";
import { observer } from "mobx-react";
import React, {
  cloneElement,
  Dispatch,
  ReactElement,
  SetStateAction,
  useState,
} from "react";
import { PiDownloadSimpleBold } from "react-icons/pi";

import { CompanyModuleRecordsReportPathParamsModuleIdentifier } from "@/api";
import { useCompanyModuleRecordsReport } from "@/api/queries/exports";
import { useMainStore } from "@/contexts/Store";

type ExportWorkspacePopupProps = {
  trigger: JSX.Element;
  downloadFooter?: React.ReactNode;
  onExport?: (allWorkspaces: boolean) => void;
  onOpenChange?: (isOpen: boolean) => void;
  title?: string;
  subtitle?: string;
  successMessage?: string;
  moduleIdentifier: CompanyModuleRecordsReportPathParamsModuleIdentifier;
};

function DownloadSpreadsheetMenu({
  setIsExportMenu,
  downloadFooter,
  setOpen,
}: {
  setIsExportMenu: Dispatch<SetStateAction<boolean>>;
  downloadFooter?: React.ReactNode;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="tw-w-[280px]">
      <div
        className="tw-w-full tw-cursor-pointer tw-px-4 tw-py-2.5 tw-text-sm tw-font-medium tw-text-primary-300 hover:tw-bg-primary-25"
        onClick={() => setIsExportMenu(true)}
      >
        Download spreadsheet
      </div>
      {downloadFooter && (
        <div className="tw-mt-2 tw-border-0 tw-border-t tw-border-solid tw-border-t-neutral-100">
          {cloneElement(downloadFooter as ReactElement, {
            onClose: () => setOpen(false),
          })}
        </div>
      )}
    </div>
  );
}

function ExportTitleSection({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-1 tw-px-4">
      <div className="tw-text-sm tw-font-semibold tw-text-primaryDim-300">
        {title}
      </div>
      <div className="tw-text-xs tw-text-neutral-300">{subtitle}</div>
    </div>
  );
}

function ExportOptionsSection({
  allWorkspacesChecked,
  setAllWorkspacesChecked,
}: {
  allWorkspacesChecked: boolean;
  setAllWorkspacesChecked: Dispatch<SetStateAction<boolean>>;
}) {
  const mainStore = useMainStore();
  const { isCompanyAdmin, isCompanySuperAdmin } = mainStore.context;

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-cursor-not-allowed tw-items-center tw-gap-2 tw-px-4 tw-py-2.5 tw-text-neutral-300">
        <Checkbox
          color="primary"
          size="md"
          checked
          disabled
          aria-label="This Workspace"
        />
        <div className="tw-select-none tw-text-sm tw-text-inherit ">
          This workspace
        </div>
      </div>
      {(isCompanySuperAdmin || isCompanyAdmin) && (
        <div
          className="tw-group tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-px-4 tw-py-2.5 tw-text-neutral-500 hover:tw-bg-primary-25 hover:tw-text-primaryDim-300"
          onClick={() => {
            setAllWorkspacesChecked((prev: boolean) => !prev);
          }}
        >
          <Checkbox
            color="secondary"
            size="md"
            checked={allWorkspacesChecked}
            className="tw-pointer-events-none"
            aria-label="All Workspaces"
          />
          <div className="tw-select-none tw-text-sm tw-text-inherit">
            All workspaces
          </div>
        </div>
      )}
    </div>
  );
}

function ExportSubmitSection({
  moduleIdentifier,
  successMessage,
  setOpen,
  allWorkspacesChecked,
  onExport,
}: {
  moduleIdentifier: CompanyModuleRecordsReportPathParamsModuleIdentifier;
  successMessage: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  allWorkspacesChecked: boolean;
  onExport?: (allWorkspaces: boolean) => void;
}) {
  const mainStore = useMainStore();
  const { companyID, tableID } = mainStore.context;
  const toast = useToast();

  const { mutateAsync: companyModuleRecordsReport } =
    useCompanyModuleRecordsReport({
      companyId: Number(companyID),
      moduleIdentifier,
      onSuccess: () => {
        toast({
          content: successMessage,
          variant: "success",
        });
      },
      onError: () => {
        toast({
          content: "Failed to export document!",
          variant: "error",
        });
      },
    });

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-border-0 tw-border-t tw-border-solid tw-border-t-neutral-100 tw-px-4 tw-pb-1 tw-pt-3">
      <div className="tw-text-xs tw-text-warning-300">
        This feature is currently in beta and only available on certain tables.
      </div>
      <Button
        aria-label="Export Workspace Data"
        LeftIcon={PiDownloadSimpleBold}
        onClick={() => {
          setOpen(false);
          if (allWorkspacesChecked) {
            companyModuleRecordsReport();
          } else {
            mainStore.tables.export(Number(tableID));
          }

          if (onExport) {
            onExport(allWorkspacesChecked);
          }
        }}
      >
        Export
      </Button>
    </div>
  );
}

function ExportWorkspacePopup({
  trigger,
  downloadFooter,
  onExport,
  onOpenChange,
  title = "Export Table to Excel",
  subtitle = "Please select the options below before exporting.",
  successMessage = "Report are preparing to be exported",
  moduleIdentifier,
}: ExportWorkspacePopupProps) {
  const [open, setOpen] = useState(false);
  const [isExportMenu, setIsExportMenu] = useState(false);
  const [allWorkspacesChecked, setAllWorkspacesChecked] = useState(false);

  const handleOnOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
    setIsExportMenu(false);
    setAllWorkspacesChecked(false);
    onOpenChange && onOpenChange(isOpen);
  };

  return (
    <Popover open={open} onOpenChange={handleOnOpenChange}>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <PopoverContent
        align="end"
        className="tw-overflow-hidden tw-py-2"
        style={{ maxWidth: isExportMenu ? 260 : 280 }}
      >
        {isExportMenu ? (
          <div className="tw-flex tw-flex-col tw-gap-2">
            <ExportTitleSection title={title} subtitle={subtitle} />
            <ExportOptionsSection
              allWorkspacesChecked={allWorkspacesChecked}
              setAllWorkspacesChecked={setAllWorkspacesChecked}
            />
            <ExportSubmitSection
              moduleIdentifier={moduleIdentifier}
              successMessage={successMessage}
              setOpen={setOpen}
              allWorkspacesChecked={allWorkspacesChecked}
              onExport={onExport}
            />
          </div>
        ) : (
          <DownloadSpreadsheetMenu
            setIsExportMenu={setIsExportMenu}
            downloadFooter={downloadFooter}
            setOpen={setOpen}
          />
        )}
      </PopoverContent>
    </Popover>
  );
}

export default observer(ExportWorkspacePopup);
