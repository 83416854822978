import "./module-tabs.scss";

import React from "react";

type ModuleTabsProps = {
  children: JSX.Element[];
};

function ModuleTabs(props: ModuleTabsProps) {
  return (
    <div className="module-tabs">
      <ul>
        {props.children.map((child, i) => (
          <li key={i}>{child}</li>
        ))}
      </ul>
    </div>
  );
}

export default ModuleTabs;
