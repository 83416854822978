import "./user-assignment.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { userColors } from "@/components/constants";
import { Icon, Typography } from "@/components/Elements";
import UsersCircle from "@/components/table/shared/UsersCircle";
import { User } from "@/stores/types/user-types";

type UserAssignmentProps = {
  assignedUsers: User[];
  assigneeWord?: string;
  children: React.ReactNode;
  CustomTrigger?: React.FC;
  tooltip?: string | boolean;
  disabled?: boolean;
};

function UserAssignment({
  assignedUsers,
  assigneeWord = "Reviewers",
  children,
  CustomTrigger,
  tooltip,
  disabled,
}: UserAssignmentProps) {
  const [showPopup, setShowPopup] = useState(false);

  const onOpen = () => !disabled && setShowPopup(true);
  const onClose = () => setShowPopup(false);

  const trigger = CustomTrigger ? (
    React.cloneElement(<CustomTrigger />, {
      showPopup,
      onOpen,
      assignedUsers,
      assigneeWord,
    })
  ) : (
    <span className="user-assignment-flex">
      {assignedUsers && (
        <div className="user-assignment-flex">
          {assignedUsers.map((user) => (
            <UsersCircle
              key={user.id}
              user={user}
              showFullName={false}
              additionalStyles={{
                backgroundColor: userColors[user.icon_color_index!],
              }}
            />
          ))}
        </div>
      )}
      <span
        onClick={onOpen}
        className={classNames("user-assignment-wrapper", {
          ["user-assignment-wrapper__active"]: showPopup,
        })}
      >
        <Typography
          label={assigneeWord}
          size="sm"
          color={showPopup ? "generalWhite" : "generalMidnightDark"}
        />
        <Icon
          data-tooltip-id="tooltip"
          data-tooltip-place="top"
          data-tooltip-content={tooltip}
          name="user"
          color={showPopup ? "generalWhite" : "generalBlack"}
        />
      </span>
    </span>
  );

  return (
    <Popup
      position="bottom center"
      trigger={<div>{trigger}</div>}
      open={showPopup}
      onOpen={onOpen}
      onClose={onClose}
      disabled={disabled}
      keepTooltipInside
    >
      {children}
    </Popup>
  );
}

export default observer(UserAssignment);
