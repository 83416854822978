import { PreviewTable } from "@themis/ui";
import React from "react";
import { PiChartBarBold, PiGlobeBold, PiTextTBold } from "react-icons/pi";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import { useColumns } from "@/api/queries/columns";
import { useRecordFindings } from "@/api/queries/findings";
import { ErrorContainer } from "@/components/ErrorContainer";
import Loading from "@/components/Loading";
import { FINDING_ROUTES } from "@/features/findings/pages";
import mainStore from "@/stores/Main";
import { fieldConfigToColumnsMap } from "@/utils/tableUtils";

import { FINDINGS_TABLE_COMPONENT_OVERRIDES } from "../config/findingsTable";
import { FindingActions } from "./FindingActions";
import { FindingIdLinkCell } from "./FindingsTable/FindingIdLinkCell";

export type SendFindingSearchParams = {
  send_finding?: string;
};

export function FindingsList({ recordId }: { recordId: number | undefined }) {
  const { url } = useRouteMatch();
  const companyId = mainStore.context.companyID!;
  const { workspace_id } = useParams<{ workspace_id: string }>();

  const {
    data: findingColumns,
    isPending: isColumnsPending,
    isError: isColumnsError,
  } = useColumns({
    companyId,
    recordClass: "findings",
  });

  const {
    data: findings,
    isPending: isFindingsPending,
    isError: isFindingsError,
  } = useRecordFindings(Number(workspace_id), Number(recordId));

  if (isFindingsPending || isColumnsPending) {
    return <Loading loadingLayout="table" />;
  }

  if (isFindingsError || isColumnsError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/modules/qa-tests", {
            workspace_id,
          }),
        }}
      >
        Could not load findings.
      </ErrorContainer>
    );
  }

  return (
    <PreviewTable
      rows={findings?.data}
      columns={[
        {
          title: "ID",
          type: "id",
          width: 160,
          Component: (finding) => <FindingIdLinkCell findingId={finding.id} />,
          Icon: PiGlobeBold,
        },
        ...fieldConfigToColumnsMap(findingColumns.data),
      ]}
      columnIconOverrides={{
        status: PiChartBarBold,
        risk_level: PiChartBarBold,
        action_plan_title: PiTextTBold,
      }}
      componentOverrides={FINDINGS_TABLE_COMPONENT_OVERRIDES}
      rowActions={(finding) => <FindingActions finding={finding} />}
      addRow={{
        text: "Add Finding",
        linkTo: generatePath(`${url}${FINDING_ROUTES.details}`, {
          findingId: "new",
        }),
      }}
    />
  );
}
