import { Recipients } from "@/features/notifications/pages/single/types";
import {
  DAYS_OF_WEEK,
  NOTIFICATION_TYPE,
  RELATIVE_TO,
} from "@/stores/types/notification-rule-types";

export enum NOTIFICATION_FREQUENCY {
  DAILY = "days",
  WEEKLY = "weeks",
  MONTHLY = "months",
  ONE_TIME = "one_time",
}

export type DateNotificationFormValues = {
  enabled: boolean;
  frequency: NOTIFICATION_FREQUENCY;
  type: NOTIFICATION_TYPE;
  timePeriod: TimePeriod;
  condition: RELATIVE_TO;
  triggerColumn: string;
  criteria: Criteria[];
  recipients: Recipients;
};

export type TimePeriod = {
  numberOfDailyDays?: number;
  numberOfOneTimeDays: number[];
  numberOfWeeks?: number;
  numberOfMonths?: number;
  excludeWeekends: boolean;
  recurringWeekdays: DAYS_OF_WEEK[];
};

export type Criteria = {
  fieldName: string;
  fieldValues: string[];
};
