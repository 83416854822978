import "./styles.scss";

import classNames from "classnames";
import React from "react";
import { Control, useFieldArray, useWatch } from "react-hook-form";

import { ZendeskConfigResponseData } from "@/api/legacy/zendesk-config/types";
import { Flex, Icon, Typography } from "@/components/Elements";
import { SelectInputField } from "@/components/form/react-hook-form/SelectInputField";
import Checkbox from "@/components/table/shared/Checkbox";

import { getThemisColumnOptions, getZendeskFieldName } from "../../helpers";
import { ZendeskConfigFormValues } from "../../types";

type MappedFieldsProps = {
  control: Control<ZendeskConfigFormValues>;
  zendeskConfigData: ZendeskConfigResponseData;
};

export const MappedFields = ({
  control,
  zendeskConfigData,
}: MappedFieldsProps) => {
  const { fields, update } = useFieldArray({
    control,
    name: "mapped_fields",
    rules: {
      /**
       * At least 1 mapping required that is checked and with themis column
       * selected
       */
      validate: (mappedFields) => {
        if (
          !mappedFields.some(
            (mappedField) => mappedField.checked && mappedField.internal_name,
          )
        ) {
          return "At least 1 mapped zendesk table field required.";
        }
      },
    },
  });

  const currentlyMappedThemisColumns = useWatch({
    control,
    name: "mapped_fields",
  });

  const handleCheck = (checked: boolean, index: number) => {
    update(index, { ...currentlyMappedThemisColumns[index], checked });
  };

  return (
    <div className="zendesk-table-fields">
      <Flex alignCenter>
        <Typography
          label="Zendesk Fields"
          size="sm"
          color="extrasBlueGrayDarker"
        />
      </Flex>
      <Flex alignCenter>
        <Typography
          label="Themis Columns"
          size="sm"
          color="extrasBlueGrayDarker"
        />
      </Flex>
      {fields.map((mappedField, index) => (
        <React.Fragment key={mappedField.id}>
          <Flex
            justifySpaceBetween
            alignCenter
            className={classNames(
              "zendesk-table-fields__row",
              "zendesk-table-fields__zendesk-field-row",
            )}
          >
            <Flex columnGap={8} alignCenter>
              <Checkbox
                small
                data-testid={`mapping checked ${mappedField.zendesk_id}`}
                checked={mappedField.checked}
                onChange={(event) => {
                  handleCheck(event.target.checked, index);
                }}
              />
              <Typography
                label={getZendeskFieldName(
                  mappedField.zendesk_id,
                  zendeskConfigData,
                )}
                color="generalMidnightDark"
                size="sm"
              />
            </Flex>
            <Icon name="chevronRight" />
          </Flex>
          <Flex alignCenter className="zendesk-table-fields__row">
            <SelectInputField
              fullWidth
              tooltipError
              className="zendesk-table-fields__select-themis-column"
              name={`mapped_fields.${index}.internal_name`}
              control={control}
              rules={{
                validate: (value) => {
                  if (mappedField.checked && !value) {
                    return "Themis Column missing";
                  }

                  return true;
                },
              }}
              placeholder="- Select -"
              disabledOptions={currentlyMappedThemisColumns.map(
                ({ internal_name }) => internal_name,
              )}
              options={getThemisColumnOptions(
                mappedField.zendesk_id,
                zendeskConfigData,
              )}
            />
          </Flex>
        </React.Fragment>
      ))}
    </div>
  );
};
