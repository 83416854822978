import React from "react";

import { Icon } from "@/components/Elements";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import { getRatingForChoice } from "@/features/risk-assessment/utils/scoring-helpers";

import { RadioAnswer } from "../../../types/questionnaire-form";
import MiniTag from "../../MiniTag/MiniTag";
import LongTextTableCell from "../../Table/GenericTable/LongTextTableCell";
import AnswerReview, { AnswerReviewProps } from "./AnswerReview";

export default function RadioAnswerReview(
  props: Omit<AnswerReviewProps, "AnswerCell" | "RiskRatingCell">,
) {
  const radioAnswer = props.question.answer as RadioAnswer;
  const answers =
    radioAnswer?.question_choice_ids.map((choiceId) => {
      return props.question.question_choices.find(
        (choice) => choice.id === choiceId,
      );
    }) || [];
  const [firstAnswer] = answers;
  const rating = getRatingForChoice(
    props.riskMethodology,
    props.question,
    firstAnswer,
  );

  const NotAnsweredContent = (
    <ContentCell
      content={
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content="This question hasn't been answered yet"
          data-tooltip-place="bottom"
        >
          N/A
        </div>
      }
    />
  );

  if (!firstAnswer) {
    return (
      <AnswerReview
        {...props}
        AnswerCell={NotAnsweredContent}
        RiskRatingCell={NotAnsweredContent}
      />
    );
  }

  return (
    <AnswerReview
      {...props}
      showRedFlag={firstAnswer.red_flag}
      AnswerCell={
        firstAnswer && (
          <LongTextTableCell
            startIcon={
              <Icon color="extrasBlueGrayDarker" name="radioChecked" />
            }
          >
            {firstAnswer.text}
          </LongTextTableCell>
        )
      }
      RiskRatingCell={
        <LongTextTableCell
          startIcon={
            rating &&
            firstAnswer?.base_score && (
              <MiniTag label={rating.text} color={rating.color} />
            )
          }
        >
          {firstAnswer?.base_score || (Boolean(firstAnswer) && "N/A")}
        </LongTextTableCell>
      }
    />
  );
}
