import React from "react";

interface ScoreTagProps {
  label: string;
  color: string;
}

function ScoreTag(props: ScoreTagProps) {
  return (
    <div
      className={`tw-rounded-3xl tw-px-3 tw-py-1 tw-text-sm tw-font-semibold tw-text-neutral-25`}
      style={{
        background: props.color,
      }}
    >
      {props.label}
    </div>
  );
}

export default ScoreTag;
