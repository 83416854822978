var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Slot } from "@radix-ui/react-slot";
import { cva, cx } from "cva";
import * as React from "react";
import { cn } from "../../lib/utils";
import { buttonBaseVariants } from "./ButtonBase";
var buttonVariants = cva({
    variants: {
        size: {
            sm: "tw-px-2 tw-h-7 tw-text-xs tw-gap-1",
            md: "tw-px-3 tw-h-8 tw-gap-1.5",
            lg: "tw-px-4 tw-h-9 tw-gap-2",
        },
    },
    defaultVariants: {
        size: "md",
    },
});
var Button = React.forwardRef(function (_a, ref) {
    var className = _a.className, children = _a.children, _b = _a.color, color = _b === void 0 ? "primary" : _b, _c = _a.size, size = _c === void 0 ? "md" : _c, _d = _a.asChild, asChild = _d === void 0 ? false : _d, LeftIcon = _a.LeftIcon, RightIcon = _a.RightIcon, _e = _a.type, type = _e === void 0 ? "button" : _e, props = __rest(_a, ["className", "children", "color", "size", "asChild", "LeftIcon", "RightIcon", "type"]);
    var Comp = asChild ? Slot : "button";
    return (React.createElement(Comp, __assign({ className: cn(cx(buttonBaseVariants({ color: color }), buttonVariants({ size: size, className: className }))), ref: ref, type: type }, props),
        LeftIcon && React.createElement(LeftIcon, { className: "tw-h-4 tw-w-4" }),
        children,
        RightIcon && React.createElement(RightIcon, { className: "tw-h-4 tw-w-4" })));
});
Button.displayName = "Button";
export { Button, buttonVariants };
