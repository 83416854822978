import { Avatar, PreviewTableColumn } from "@themis/ui";
import React from "react";
import { PiAddressBookBold, PiTextTBold } from "react-icons/pi";

import { Contact } from "@/api";

import { ContactsActionsCell } from "../components/ContactsTable/ContactsActionCell";

export const PREVIEW_CONTACTS_COLUMNS: PreviewTableColumn<Contact>[] = [
  {
    title: "Name",
    type: "locked",
    width: 400,
    Icon: PiTextTBold,
    Component: (contact) => (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
        <Avatar colorIndex={contact.id}>{contact.initials}</Avatar>
        {contact.full_name}
      </div>
    ),
  },
  {
    key: "phone",
    title: "Phone",
    type: "locked",
    width: 300,
    Icon: PiAddressBookBold,
  },
  {
    key: "email",
    title: "Email",
    type: "locked",
    width: 300,
    Icon: PiAddressBookBold,
  },
] as const;

export const CONTACTS_COLUMNS: PreviewTableColumn<Contact>[] = [
  {
    key: "first_name",
    title: "First Name",
    type: "locked",
    width: 200,
    Icon: PiTextTBold,
  },
  {
    key: "middle_name",
    title: "Middle Name",
    type: "locked",
    width: 200,
    Icon: PiTextTBold,
  },
  {
    key: "last_name",
    title: "Last Name",
    type: "locked",
    width: 200,
    Icon: PiTextTBold,
  },
  {
    key: "phone",
    title: "Phone",
    type: "locked",
    width: 200,
    Icon: PiAddressBookBold,
  },
  {
    key: "email",
    title: "Email",
    type: "locked",
    width: 200,
    Icon: PiAddressBookBold,
  },
  {
    type: "actions",
    Component: (contact) => (
      <ContactsActionsCell
        contactId={contact.id}
        fullName={contact.full_name}
      />
    ),
  },
] as const;
