import classNames from "classnames";
import { format, isBefore, parseISO } from "date-fns";
import React from "react";

export function DueDateCell({ dueDate }: { dueDate: string | null }) {
  const date = parseISO(dueDate || "");
  const isOverdue = dueDate && isBefore(date, new Date());

  return (
    <div
      className={classNames(
        "tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-flex tw-items-center tw-px-2.5 tw-font-semibold",
        {
          "tw-bg-red-100 tw-text-red-600": isOverdue,
          "tw-text-neutral-200": !dueDate,
        },
      )}
    >
      {dueDate ? format(date, "MM/dd/yyyy") : "N/A"}
    </div>
  );
}
