import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { PiCaretDownBold } from "react-icons/pi";
import Popup from "reactjs-popup";

import { Icon } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import { Section } from "@/stores/types/section-tags";

import ConfirmationDialog from "../shared/ConfirmationDialog";

const RISK_REGISTER = "risk-register";

interface AllRiskPopupProps {
  activeScreen: string;
  onClick: (screen: string) => void;
  showConfirmation: boolean;
  onConfirmYes: () => void;
  onConfirmNo: (screen: string) => void;
  moduleWorkspaceID: number;
  initialSection: Section | null;
}

const AllRiskPopup = ({
  activeScreen,
  showConfirmation,
  onConfirmYes,
  onConfirmNo,
  moduleWorkspaceID,
  initialSection,
  onClick,
}: AllRiskPopupProps) => {
  const mainStore = useMainStore();

  const inputRef = useRef<HTMLInputElement>(null);

  const [popUpOpen, setPopupOpen] = useState(false);
  const [currentSelection, setCurrentSelection] = useState("All Risks");
  const [riskRegisterSection, setRiskRegisterSection] = useState("");
  const [inEditMode, setInEditMode] = useState(false);
  const [editingSection, setEditingSection] = useState<Section | null>(null);
  const unUpdatableSections = ["General", "All Risks"];

  const currentSection = mainStore.riskRegisters.currentSection as Section;
  const { canManageSections } = mainStore.userPermissions;
  const riskTitles = [
    "All Risks",
    ...mainStore.sectionTags.orderedList.map((tag) => tag.title),
  ];
  useEffect(() => {
    if (initialSection || currentSection) {
      setCurrentSelection(initialSection?.title || currentSection?.title);
    } else {
      setCurrentSelection("All Risks");
    }
  }, [initialSection]);

  useEffect(() => {
    if (!riskTitles.includes(currentSelection)) {
      setCurrentSelection("All Risks");
    }
  }, [currentSelection, riskTitles]);

  const handleRiskSelection = (riskEventName: string) => {
    setCurrentSelection(riskEventName);
    setPopupOpen(false);

    if (riskEventName === "All Risks") {
      mainStore.riskRegisters.setCurrentSection(null);
    } else {
      const sectionTag = mainStore.sectionTags.orderedList.find(
        (tag) => tag.title === riskEventName,
      );
      mainStore.riskRegisters.setCurrentSection(sectionTag);
    }
  };

  const handleNewRiskRegisterChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setRiskRegisterSection(event.target.value);
  };

  const handleAddNewRiskRegisterSection = async () => {
    await mainStore.sectionTags.create(moduleWorkspaceID, {
      title: riskRegisterSection,
    });
    setRiskRegisterSection("");
    handleRiskSelection(riskRegisterSection);
    setPopupOpen(false);
  };

  const handleUpdateRiskRegisterSection = async () => {
    if (!editingSection) {
      return;
    }
    await mainStore.sectionTags.update(moduleWorkspaceID, editingSection.id, {
      ...editingSection,
      title: riskRegisterSection,
    });
    setRiskRegisterSection("");
    handleRiskSelection(riskRegisterSection);
    setPopupOpen(false);
    setInEditMode(false);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      if (inEditMode && editingSection) {
        handleUpdateRiskRegisterSection();
      } else {
        handleAddNewRiskRegisterSection();
      }
    }
  };

  const handleCancelEdit = () => {
    setInEditMode(false);
    setRiskRegisterSection("");
  };

  const onClose = () => {
    if (inEditMode) {
      handleCancelEdit();
    }
    setPopupOpen(false);
  };

  const handleEditRiskRegisterSection = (
    event: MouseEvent<SVGSVGElement>,
    sectionName: string,
  ) => {
    event.stopPropagation();
    setInEditMode(true);

    const sectionTag = mainStore.sectionTags.orderedList.find(
      (tag) => tag.title === sectionName,
    );
    if (sectionTag) {
      setEditingSection(sectionTag);
      setRiskRegisterSection(sectionName);
      inputRef.current && inputRef.current.focus();
    }
  };

  const handleOpenPopup = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (activeScreen === RISK_REGISTER) {
      setPopupOpen(!popUpOpen);
    } else {
      onClick(RISK_REGISTER);
    }
  };

  const addRiskInput = () => {
    if (canManageSections) {
      return (
        <div className="add-new-risk-register-container">
          <input
            className="add-new-risk-register"
            type="text"
            ref={inputRef}
            data-testid="add-new-risk-register-input"
            placeholder="Add Risk Register"
            value={riskRegisterSection}
            onChange={handleNewRiskRegisterChange}
            onKeyDown={handleKeyDown}
          />
          {inEditMode ? (
            <Icon
              name="check"
              className="check"
              onClick={handleUpdateRiskRegisterSection}
            />
          ) : (
            <Icon name="plus" onClick={handleAddNewRiskRegisterSection} />
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <Popup
      trigger={
        <IconButton
          Icon={() => (
            <PiCaretDownBold className="tw-h-4 tw-w-4 tw-fill-primary-300" />
          )}
          color="transparent"
          size="sm"
          onClick={handleOpenPopup}
          data-testid="risk-register-tab-AllRisk-trigger"
          className="tw-ml-2"
        />
      }
      open={showConfirmation || popUpOpen}
      onClose={onClose}
      position="bottom center"
      arrow={false}
    >
      {showConfirmation && (
        <div
          className="table-dropdown success-dropdown"
          data-testid="rr-tab-switch-confirmation-popup"
        >
          <ConfirmationDialog
            heading="Save Now?"
            content="Do you want to save changes?"
            handleConfirm={onConfirmYes}
            handleReject={() => onConfirmNo("AllRisk")}
          />
        </div>
      )}
      {!showConfirmation && (
        <div
          className="table-dropdown all-risk-dropdown"
          data-testid="all-risk-dropdown"
        >
          <div className="">
            {addRiskInput()}
            <div
              className="risk-list-container"
              data-testid="risk-list-container"
            >
              <div className="risk-list">
                {riskTitles.map((risk) => {
                  const selected = risk === currentSelection;
                  return (
                    <div
                      key={risk}
                      className={classNames("risk-name-container", {
                        selected,
                      })}
                      onClick={() => handleRiskSelection(risk)}
                      data-testid="risk-name-container"
                    >
                      <div className="risk-name">
                        <li key={risk}>{risk}</li>
                      </div>
                      {selected && (
                        <Icon name="check" className="check" fill="#27AAE1" />
                      )}
                      {!selected &&
                        !inEditMode &&
                        canManageSections &&
                        !unUpdatableSections.includes(risk) && (
                          <Icon
                            name="edit"
                            className="check"
                            onClick={(event) =>
                              handleEditRiskRegisterSection(event, risk)
                            }
                          />
                        )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default observer(AllRiskPopup);
