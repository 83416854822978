import { useEffect, useRef, useState } from "react";

import { Field } from "@/api";

import { LOADING_DELAY } from "../components/constants";

export function useLoading(fields: Field[]) {
  // State
  const [loading, setLoading] = useState(true);
  // refs
  const timer = useRef();

  useEffect(() => {
    timer.current && clearTimeout(timer.current);

    // @ts-expect-error TS(2322) FIXME: Type 'Timeout' is not assignable to type 'undefine... Remove this comment to see the full error message
    timer.current = setTimeout(() => {
      setLoading(fields?.length === 0);
    }, LOADING_DELAY);
  }, [fields]);

  return loading;
}
