import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AssessmentAPI, RiskAreaApi } from "@/api/legacy/risk-assessment";
import { useMainStore } from "@/contexts/Store";
import { AssessmentDetail } from "@/features/risk-assessment/types/assessment";
import { RiskArea } from "@/features/risk-assessment/types/risk-area";

function useAssessmentData(activeWorkspaceId: number | undefined) {
  const mainStore = useMainStore();
  const { assessmentId } = useParams<{ assessmentId: string }>();

  const internalWorspaceId: number | undefined = mainStore.workspaces.list.find(
    (ws) => ws.is_internal,
  )?.id;
  const [assessment, setAssessment] = useState<AssessmentDetail | undefined>();
  const [riskAreas, setRiskAreas] = useState<RiskArea[]>([]);

  const loadData = async (workspaceId: number, recordId: string) => {
    try {
      const [assessmentData, riskAreasData] = await Promise.all([
        AssessmentAPI.get(workspaceId, recordId),
        RiskAreaApi.getAll(String(internalWorspaceId)),
      ]);
      setAssessment(assessmentData);
      setRiskAreas(riskAreasData);
    } catch (err) {
      mainStore.toast.setErrorFromResponse(err);
    }
  };

  const fetchAssessmentData = () => {
    if (assessmentId && activeWorkspaceId) {
      loadData(activeWorkspaceId, assessmentId);
    }
  };

  useEffect(() => {
    if (assessmentId && activeWorkspaceId) {
      loadData(activeWorkspaceId, assessmentId);
    }
  }, [assessmentId, activeWorkspaceId]);

  return {
    assessment,
    riskAreas,
    assessmentId,
    setAssessment,
    setRiskAreas,
    fetchAssessmentData,
  };
}

export default useAssessmentData;
