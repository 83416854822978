import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "../../../contexts/Store";
import closeIcon from "../../../images/table-image/icon/close-black-icon.svg";
import { userColors } from "../../constants";
import { MiniTag } from "../../Elements";
import {
  formatDate,
  formatDateToTheSecond,
  stringToDate,
  stringToDateAndTime,
} from "../../helpers/DateFormatters";

type Props = {
  emailSlideClose?: () => void;
  recordVersionName?: string;
};

function AttestationsDetails({ emailSlideClose, recordVersionName }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // vars
  const { statuses, lastSentDate } = mainStore.attestations;

  // elements
  const renderMainContent = (
    <div
      className="table-dropdown-status"
      data-testid="attestation-status-dropdown"
    >
      <h4>{formatDate(stringToDate(lastSentDate))}</h4>
      <ul>
        {statuses.map(({ id, attester, status: attestationStatus, date }) => {
          let statusClass = "attested";
          let statusTitle = "Attested";

          switch (attestationStatus) {
            case "attested":
              statusClass = "attested";
              statusTitle = "Attested";
              break;
            case "send_failure":
              statusClass = "sent-failed";
              statusTitle = "Send Failure";
              break;
            case "resent":
              statusClass = "resent";
              statusTitle = "Resent";
              break;
            default:
              statusClass = "not-attested";
              statusTitle = "Not Attested";
          }

          // @ts-expect-error TS(2339) FIXME: Property 'icon_color_index' does not exist on type... Remove this comment to see the full error message
          const color = userColors[attester.icon_color_index];
          // @ts-expect-error TS(2339) FIXME: Property 'is_contact' does not exist on type 'neve... Remove this comment to see the full error message
          const style = attester.is_contact
            ? { borderColor: color }
            : { background: color };

          return (
            <li key={id}>
              <div className="status-users">
                <span
                  className={classNames("users-circle", {
                    // @ts-expect-error TS(2339) FIXME: Property 'is_contact' does not exist on type 'neve... Remove this comment to see the full error message
                    "users-circle-contact": attester.is_contact,
                  })}
                  style={style}
                >
                  {/* @ts-expect-error TS(2339) FIXME: Property 'initials' does not exist on type 'never'... Remove this comment to see the full error message */}
                  {attester.initials}
                </span>
                <div>
                  {/* @ts-expect-error TS(2339) FIXME: Property 'greeting' does not exist on type 'never'... Remove this comment to see the full error message */}
                  <p>{attester.greeting}</p>
                  <p>{formatDateToTheSecond(stringToDateAndTime(date))}</p>
                </div>
              </div>
              <div className="status-wrap">
                <div className={statusClass}>
                  <span>{statusTitle}</span>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <div
      className="table-dropdown table-email-dropdown"
      data-testid="edit-attestation-list-dropdown"
    >
      <div className="email-dropdown-head">
        <h3>View Attestation status</h3>
        <div onClick={emailSlideClose}>
          <img src={closeIcon} alt="close-icon" />{" "}
        </div>
      </div>
      <div className="table-email-select">
        <div className="table-email-block">
          <div className="table-email-element attest-selected-item">
            <MiniTag label={recordVersionName || "-Untitled-"} theme="navy" />
          </div>
        </div>
      </div>
      <hr />
      <ul className="users-menu-attestation edit-attestation-list">
        {renderMainContent}
        <hr />
      </ul>
    </div>
  );
}

export default observer(AttestationsDetails);
