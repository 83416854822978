import React from "react";

import { Icon } from "../../Elements";

type Props = {
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
};

const ActionItem = ({ children, onClick }: Props) => {
  const content = <div className="action-item__content">{children}</div>;

  if (onClick) {
    return (
      <button onClick={onClick} className="action-item">
        {content}
        <Icon
          name="chevronRight"
          color="generalWhite"
          data-testid="action-item-icon"
        />
      </button>
    );
  }
  return <div className="action-item">{content}</div>;
};

export default ActionItem;
