import { Button } from "@themis/ui";
import React, { useMemo, useState } from "react";
import Popup from "reactjs-popup";

import { TableName } from "@/api";
import { Icon } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onYes: (...args: any[]) => any;
  actualModelName?: string;
  buttonText?: string;
  canEveryoneUnlock?: boolean;
  canEveryoneUnlockForInternalWorkspace?: boolean;
  disabled?: boolean;
  moduleIdentifier?: ModuleIdentifier;
  tableName?: TableName;
};

function Unlock({
  onYes,
  tableName,
  moduleIdentifier,
  buttonText,
  disabled,
  canEveryoneUnlock,
  actualModelName,
  canEveryoneUnlockForInternalWorkspace,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);

  const { isCurrentWorkspaceArchived } = mainStore.workspaces;
  const { activeWorkspace } = mainStore.context;
  const { canUnpublishOrUnlockRecords } = mainStore.userPermissions;

  const isDisabled =
    disabled || isCurrentWorkspaceArchived || !canUnpublishOrUnlockRecords;

  const modelName = useMemo(() => {
    if (tableName === "Finalized") {
      return "Policy";
    }

    if (tableName === "ProceduresFinalized") {
      return "Procedure";
    }

    if (moduleIdentifier === "conflicts_of_interest") {
      return "Conflict";
    }

    if (moduleIdentifier === "marketing") {
      return "Creative";
    }

    if (moduleIdentifier === "documents") {
      return "Document";
    }

    if (moduleIdentifier === "issue_management") {
      return "Issue";
    }

    if (moduleIdentifier === "audits") {
      return "Audit";
    }

    return "Record";
  }, [tableName, moduleIdentifier]);

  let specialMessage;
  if (moduleIdentifier === "marketing") {
    specialMessage =
      "Once confirmed, creative will be moved to “Active” tab on top left.";
  }
  const canTrigger =
    canEveryoneUnlock ||
    (canEveryoneUnlockForInternalWorkspace && activeWorkspace?.is_internal) ||
    canUnpublishOrUnlockRecords;

  function onConfirm() {
    setShowPopup(false);
    onYes();
  }

  return (
    <>
      {canTrigger && (
        <Popup
          position="bottom right"
          open={showPopup}
          onOpen={() => setShowPopup(true)}
          onClose={() => setShowPopup(false)}
          disabled={disabled}
          trigger={
            <div data-testid="unlock-button-trigger">
              <Button
                color="transparent"
                size="md"
                data-testid="unlock-button"
                disabled={isDisabled}
                className="tw-w-[86px] tw-max-w-[86px]"
              >
                {buttonText}
              </Button>
            </div>
          }
          keepTooltipInside
        >
          <div className="table-dropdown" data-testid="unapprove-record-popup">
            <h4>{`${buttonText} ${actualModelName || modelName}`}</h4>
            {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
            <p>{`Are you sure you wish to ${buttonText.toLowerCase()} this ${
              actualModelName || modelName
            }? ${specialMessage || ""}`}</p>
            <div className="confirmation">
              <button onClick={onConfirm} data-testid="confirm-button">
                Yes
              </button>
              <button onClick={() => setShowPopup(false)}>No</button>
            </div>
          </div>
        </Popup>
      )}
      {!canTrigger && (
        <Popup
          // @ts-expect-error TS(2322) FIXME: Type '"left"' is not assignable to type 'PopupPosi... Remove this comment to see the full error message
          position="left"
          trigger={() => (
            <Button
              color="transparent"
              size="md"
              data-testid="unlock-button-disabled"
              disabled
              className="tw-w-[86px] tw-max-w-[86px]"
            >
              {buttonText}
            </Button>
          )}
          open={showPopup}
          onOpen={() => setShowPopup(true)}
          onClose={() => setShowPopup(false)}
          keepTooltipInside
          className="hit-popup"
          on="hover"
          arrow={false}
          offsetX={18}
        >
          <div className="hint-wrap" data-testid="only-admin-hint">
            <Icon name="lightbulb" color="generalWhite" />
            {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
            Only admins can {buttonText.toLowerCase()} this record.
          </div>
        </Popup>
      )}
    </>
  );
}

Unlock.defaultProps = {
  tableName: "default",
  buttonText: "Unlock",
  disabled: false,
  showIcon: true,
  canEveryoneUnlock: false,
  canEveryoneUnlockForInternalWorkspace: true,
};

export default Unlock;
