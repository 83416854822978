import { Table } from "@themis/ui";
import React from "react";
import { useParams, useRouteMatch } from "react-router-dom";

import { Account } from "@/api";

import AddNewAccount from "../../components/AccountsTable/AddNewAccount";
import { ACCOUNT_TABLE_COLUMNS } from "../../config/accountsTable";

export function ChildAccounts({
  child_accounts,
}: {
  child_accounts: Account[];
}) {
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const { url } = useRouteMatch();

  return (
    <div>
      <Table
        columns={ACCOUNT_TABLE_COLUMNS(Number(workspace_id))}
        rows={child_accounts}
        width="100%"
      />
      <AddNewAccount recordName="Account" to={`${url}/new`} />
    </div>
  );
}
