import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import classNames from "classnames";
import { camelCase, startCase } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import Popup from "reactjs-popup";

import { RecordsByFieldOptionQueryParams } from "@/api";
import { recordsByFieldOption } from "@/api/gen/axios/reportsController";
import { useMainStore } from "@/contexts/Store";
import { titleForColor } from "@/stores/helpers/TitleForColorSchemeHelpers";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import arrowDownIcon from "../../../images/table-image/icon/arrow-down-black.svg";
import calendarIcon from "../../../images/table-image/icon/calendar-black-icon.svg";
import { pieChartColors } from "../../constants";
import { Typography } from "../../Elements";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import { naColor } from "../common/MetricsPage/constants";
import ExportDashboard from "../common/MetricsPage/dashboards/components/ExportDashboard";
import { legendHeightPlugin } from "./chartHelpers";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

type Props = {
  columnDefault?: string;
  identifier?: string;
  tableName?: string;
};

const PieChart = ({ identifier, columnDefault, tableName }: Props) => {
  const mainStore = useMainStore();
  const activeWorkspaceID = mainStore.context.activeWorkspace?.id;
  const mwList = mainStore.moduleWorkspaces.list;
  const groupableFields = mainStore.fields.allGroupableFields;
  const { colorSchemes } = mainStore.reports;

  // State
  const [columnName, setColumnName] = useState(columnDefault);
  const [range] = useState("All Time");
  const [showColumnSelection, setShowColumnSelection] = useState(false);
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  const recordTypePlural = getRecordName(identifier, mwList, false);

  useEffect(() => {
    getRecords();
  }, [columnName, activeWorkspaceID, mainStore.reports.refetchRecordsCount]);

  async function getRecords() {
    if (!activeWorkspaceID || !columnName) {
      return;
    }

    if (
      mainStore.featureFlags.getIsEnabled(
        FEATURE_FLAG_ID.GENG_TRAINING_MODULE_REVAMP,
      ) &&
      identifier === "training"
    ) {
      const params: RecordsByFieldOptionQueryParams = {
        workspace_ids: mainStore.reports.selectedWorkspaceIDs as number[],
        field_name: columnName,
      };
      const response = await recordsByFieldOption(
        mainStore.context.companyID!,
        "trainings",
        params,
      );
      mainStore.reports.setRecordsByFieldOption(response.data);
    } else {
      mainStore.reports.getReportsByFieldOption(
        columnName,
        identifier,
        tableName,
      );
    }
  }

  const data = mainStore.reports.recordsByFieldOption;

  const colors = Object.keys(data).map((title, index) =>
    !title
      ? naColor
      : colorSchemes?.find(
          (colorScheme) =>
            titleForColor(colorScheme.title) === titleForColor(title),
        )?.text_color || pieChartColors[index],
  );

  const insertData = () => ({
    data: {
      datasets: [
        {
          data: Object.values(data),
          backgroundColor: colors,
        },
      ],
      labels: Object.keys(data).map((v) =>
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        v ? `${startCase(camelCase(v))}: ${data[v]}` : "N/A",
      ),
    },
  });

  // @ts-expect-error TS(7006) FIXME: Parameter 'column' implicitly has an 'any' type.
  const handleColumnChange = (column) => {
    setColumnName(column);
    setShowColumnSelection(false);
  };

  return (
    <div className="report-dashboard-contents">
      <div className="report-dashboard-header-multiple-row">
        <div className="row graph-title-container">
          <div className="graph-title">{recordTypePlural} by month</div>
          <div className="report-dashboard-header-right">
            <div className="date-picker all-time">
              <img src={calendarIcon} />
              {range}
            </div>
            <ExportDashboard
              chartId="reports-pie-chart"
              fileDownloadName={`${recordTypePlural}_by_month_${columnName}`}
              forTables={false}
            />
          </div>
        </div>
        <div className="selects-container">
          <div className="single-title-and-select">
            <Typography
              size="sm"
              color="generalDarkGray"
              label="Show by"
              weight="semiBold"
            />
            <Popup
              position="bottom right"
              trigger={
                <button
                  className={classNames("reports-module-dropdown", {
                    opened: showColumnSelection,
                  })}
                  data-testid="reports-module-dropdown"
                >
                  <div className="module-item">
                    <span>{startCase(camelCase(columnName))}</span>
                  </div>
                  <img src={arrowDownIcon} alt="open" />
                </button>
              }
              open={showColumnSelection}
              onOpen={() => setShowColumnSelection(true)}
              onClose={() => setShowColumnSelection(false)}
              keepTooltipInside
            >
              <ul className="table-dropdown reports-dropdown-popup">
                {groupableFields?.map((column) => (
                  <li
                    key={column}
                    data-testid={column}
                    className={classNames({ active: columnName === column })}
                    // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
                    onClick={() => handleColumnChange(column.name)}
                  >
                    {" "}
                    {/* @ts-expect-error TS(2339) FIXME: Property 'display_name' does not exist on type 'ne... Remove this comment to see the full error message */}
                    {startCase(camelCase(column.display_name))}
                  </li>
                ))}
              </ul>
            </Popup>
          </div>
        </div>
      </div>
      <div className="report-dashboard-graph single">
        <div>
          <Pie
            id="reports-pie-chart"
            data={insertData().data}
            // @ts-expect-error TS(2339) FIXME: Property 'options' does not exist on type '{ data:... Remove this comment to see the full error message
            options={insertData().options}
            plugins={[legendHeightPlugin]}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(PieChart);
