import { ChangeEvent, useEffect } from "react";

/**
 *
 * @param initial set desired initial height here AND in css as height:
 */
export function useAddTextareaHeightBehavior(
  textareaRef: React.MutableRefObject<HTMLTextAreaElement> | null,
  initial = 0,
) {
  const textarea = textareaRef?.current;

  useEffect(() => {
    if (textareaRef?.current) {
      const { current } = textareaRef;

      current.style.overflowY = "hidden";
      current.style.height = `${current.scrollHeight}px`;
    }
  }, [textarea]);

  function handleInput(event: ChangeEvent<HTMLTextAreaElement>) {
    const { scrollHeight } = event.target;

    event.target.style.height = `${
      scrollHeight > initial ? scrollHeight : initial
    }px`;
  }

  return handleInput;
}
