import api from "@/api/legacy/api";

export const AccountsApi = {
  async downloadTemplate(workspaceID: number) {
    const response = await api.get(
      `/workspaces/${workspaceID}/accounts/export_template`,
    );
    const blob = new Blob([new Uint8Array(response.data)], {
      type: "application/octet-stream",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "accounts_template.xlsx";
    link.click();
  },

  async importAccounts(workspaceID: number, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const result = (await api.post(
      `/workspaces/${workspaceID}/accounts/import`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      // @ts-expect-error TS(7008) FIXME: Member 'accounts' implicitly has an 'any' typ... Remove this comment to see the full error message
    )) as { data: { accounts } };
    return result.data.accounts;
  },
};
