import { observer } from "mobx-react";
import React from "react";

import { ActionItems } from "@/components/home/action-items/ActionItems";
import HomeContent from "@/components/home/content/HomeContent";
import AnnouncementHeader from "@/components/home/header/AnnouncementHeader";
import HomeHeader from "@/components/home/header/HomeHeader";
import { PageContent } from "@/components/Layout/PageContent";
import { useMainStore } from "@/contexts/Store";

function OldHome() {
  const { announcements } = useMainStore();

  return (
    <div className="dashboard-content">
      <div className="tw-flex tw-h-full tw-bg-neutral-25 tw-pb-10">
        <div className="home-page__main-panel" data-testid="home-header">
          {announcements.announcement.forHeader ? (
            <AnnouncementHeader />
          ) : (
            <HomeHeader />
          )}
          <PageContent>
            <HomeContent />
          </PageContent>
        </div>
        <ActionItems />
      </div>
    </div>
  );
}

export default observer(OldHome);
