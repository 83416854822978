import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";
import UniversalHeader from "@/features/misc/UniversalHeader";
import TaskDetail from "@/features/tasks/components/TaskDetail";

import DashboardMenu from "./dashboard/sidebar/DashboardMenu";
import WorkspaceNavigation from "./dashboard/sidebar/WorkspaceNavigation";
import OverviewMenu from "./overviewMenu/OverviewMenu";

type MainContentProps = {
  children: React.ReactNode;
};

function MainContent({ children }: MainContentProps) {
  const mainStore = useMainStore();
  const showTaskDetailMenu = mainStore.taskDetail.isOpen;

  return (
    <div className="dashboard-wrap">
      <div className="workspace-navigation">
        <WorkspaceNavigation />
      </div>
      <div className="tw-flex tw-max-w-[calc(100%_-_64px)] tw-flex-grow tw-flex-col">
        <UniversalHeader />
        <div className="tw-flex tw-max-h-[calc(100%_-_2.5rem)] tw-flex-grow tw-items-stretch">
          <DashboardMenu />
          <div className="dashboard-wrap__main tw-h-full tw-flex-grow tw-overflow-hidden">
            {children}
          </div>
          <OverviewMenu />
          {showTaskDetailMenu && <TaskDetail />}
        </div>
      </div>
    </div>
  );
}

export default observer(MainContent);
