import { Button, IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import SubExportComplaintButton from "@/components/dashboard/SubExportComplaintButton";
import { useMainStore } from "@/contexts/Store";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import SlideMenu from "../../slideMenu/SlideMenu";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import SendRecordVersion from "../shared/SendRecordVersion";
import ShareToCW from "../shared/ShareToCW";
import Unlock from "../shared/Unlock";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  showOnlyIcon?: boolean;
};

function ComplaintsContextMenu({ recordVersion, showOnlyIcon = true }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const [sendSlide, setSendSlide] = useState(false);
  const [view, setView] = useState("main");

  const history = useHistory();
  const location = useLocation();
  const isComplaintsDetailView = useMemo(() => {
    const complaintsDetailPageRe =
      /^\/workspaces\/\d+\/modules\/complaints\/\d+$/;
    const matches = location.pathname.match(complaintsDetailPageRe);

    return Boolean(matches && matches.length > 0);
  }, [location.pathname]);

  // Variables
  const recordVersionID = recordVersion.id;
  const { isIW, workspaceID } = mainStore.context;
  const { canDeleteRecords, canPublishOrLockRecords } =
    mainStore.userPermissions;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName("complaints", moduleWorkspaces, true);
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const { taskDetail } = mainStore;

  // Variables - send to cw
  const handleOpenSendSlideMenu = () => {
    setIsOpenModal(false);
    setShowSlideMenu(true);
    setSendSlide(true);
  };

  const handleCloseSlideMenu = () => {
    setShowSlideMenu(false);
    setSendSlide(false);
  };

  // Variables - Status of the Complaint
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );
  const isClosed = status === "closed";

  // Functions
  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function deleteRowConfirmation() {
    if (isClosed) {
      handlePopUpClose();
    } else {
      setIsDropdownOpen(false);
      setIsDeleteConfirmationOpen(true);
    }
  }

  async function deleteRow() {
    // Delete RecordVersion
    const { company } = mainStore.companies;
    if (company && company.id) {
      await mainStore.complaints.delete(recordVersionID);
      mainStore.toast.setText("Complaint was successfully deleted!");

      if (isComplaintsDetailView) {
        history.push(`/workspaces/${workspaceID}/modules/complaints`);
      }
    }
  }

  function reportSelection() {
    setIsDropdownOpen(false);
    setView("select-report");
  }

  const renderSectionSelectionPDF = (
    <SubExportComplaintButton recordVersion={recordVersionID} />
  );

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsOpenModal(false);
    setIsDropdownOpen(true);
    setIsDeleteConfirmationOpen(false);
    setView("main");
  }

  function reopenComplaint() {
    mainStore.complaints.reopen(recordVersionID);
    handlePopUpClose();
  }

  // Verify / Submit
  function submitPressed() {
    mainStore.complaints.finalize(recordVersionID);
  }

  function handleCreateTask() {
    handlePopUpClose();
    taskDetail.openFromRecord(recordVersion);
  }

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="complaints-context-menu-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="complaints-context-menu-dropdown"
    >
      <ul>
        {!isComplaintsDetailView && (
          <li
            onClick={() =>
              history.push(
                `/workspaces/${workspaceID}/modules/complaints/${recordVersionID}`,
              )
            }
            data-testid="view-detail"
          >
            View {recordName} Detail
          </li>
        )}

        {isIW && hasModuleWriteAccess && (
          <ShareToCW
            recordName={recordName}
            sendTrigger={handleOpenSendSlideMenu}
          />
        )}
        <li onClick={handleCreateTask}>Create Task</li>

        {canDeleteRecords && (
          <>
            <hr />
            <li
              onClick={deleteRowConfirmation}
              className={classNames({ disabled: isClosed })}
              data-testid="complaints-context-menu-dropdown-delete"
            >
              Delete
            </li>
          </>
        )}

        {<li onClick={reportSelection}>Export PDF Report</li>}
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Complaints"
      content="Are you sure you want to delete this complaints? Actions are not reversable."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );

  let renderButton = (
    <Button
      color="secondary"
      size="md"
      data-testid="verify-button"
      onClick={submitPressed}
      disabled={!canPublishOrLockRecords}
      className="tw-w-[86px] tw-max-w-[86px]"
    >
      Verify
    </Button>
  );

  if (isClosed) {
    renderButton = (
      <Unlock
        onYes={reopenComplaint}
        tableName="Default"
        moduleIdentifier="complaints"
        buttonText="Reopen"
        canEveryoneUnlockForInternalWorkspace={false}
      />
    );
  }

  return (
    <div
      className={classNames("list-points", {
        "without-background": !showOnlyIcon,
      })}
      data-testid="complaints-context-menu"
    >
      {renderButton}

      <Popup
        position="bottom right"
        trigger={renderTrigger}
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
        nested
      >
        {isDropdownOpen && renderBaseContent}

        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}

        {view === "select-report" && renderSectionSelectionPDF}
      </Popup>
      <SlideMenu open={showSlideMenu} closeSlideMenu={handleCloseSlideMenu}>
        {sendSlide && (
          <SendRecordVersion
            recordVersionID={recordVersionID}
            recordName={recordName}
          />
        )}
      </SlideMenu>
    </div>
  );
}

export default observer(ComplaintsContextMenu);
