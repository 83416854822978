import { noop } from "lodash";
import React, { Dispatch, SetStateAction } from "react";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import HeadSelect from "@/components/table/shared/HeadSelect";
import Table from "@/components/table/Table";
import { UseFiltersReturn } from "@/hooks/useFilters";

import PoliciesFinalized from "../components/PoliciesFinalized";
import PoliciesTabHeader from "../components/PoliciesTabHeader";

interface PoliciesFinalizedPageProps {
  tableName: string;
  setShowCheckbox: Dispatch<SetStateAction<boolean>>;
  buttonDisabled: boolean;
  moduleWorkspaceID: number;
  showCTA: boolean;
  isInternal: boolean;
  showCheckbox: boolean;
  recordVersionsLength: number;
  filtersData?: UseFiltersReturn | Record<string, never>;
  recordNamePlural: string;
  hasModuleWriteAccess?: boolean;
}

const PoliciesFinalizedPage = ({
  tableName,
  setShowCheckbox,
  buttonDisabled,
  moduleWorkspaceID,
  showCTA,
  isInternal,
  showCheckbox,
  recordVersionsLength,
  filtersData,
  recordNamePlural,
  hasModuleWriteAccess,
}: PoliciesFinalizedPageProps) => (
  <DashboardContent>
    <DashboardHeader
      title={`Finalized ${recordNamePlural}`}
      LeftActionBar={<ViewModuleUsers />}
      RightActionBar={<HeadSelect />}
    />
    <DashboardContentWrapper>
      <Table>
        <PoliciesTabHeader
          tableName={tableName}
          setShowCheckbox={setShowCheckbox}
          buttonDisabled={buttonDisabled}
          moduleWorkspaceID={moduleWorkspaceID}
          filtersData={filtersData}
          hasModuleWriteAccess={hasModuleWriteAccess}
          openImportSlideOut={noop}
        />
        <div className="policies-page-content">
          <PoliciesFinalized
            recordVersionsLength={recordVersionsLength}
            showCTA={showCTA}
            isInternal={isInternal}
            showCheckbox={showCheckbox}
            recordNamePlural={recordNamePlural}
          />
        </div>
      </Table>
    </DashboardContentWrapper>
  </DashboardContent>
);

export default PoliciesFinalizedPage;
