import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import IconButton from "../../../Elements/IconButton";
import ConfirmationDialog from "../../shared/ConfirmationDialog";

type Props = {
  canDelete?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matrix?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...args: any[]) => any;
};

function RiskAssessmentThemes({ matrix, canDelete, onChange }: Props) {
  const [deletePopupOpened, setDeletePopupOpened] = useState(null);

  const {
    risk_themes: riskThemes,
    rating_calculation_type: ratingCalculationType,
  } = matrix;
  const isAutoCalc = ratingCalculationType === "auto";

  useEffect(() => {
    const textarea = document.getElementsByTagName("textarea");
    for (let i = 0; i < textarea.length; i++) {
      textarea[i].setAttribute("style", `height:${textarea[i].scrollHeight}px`);
      textarea[i].addEventListener("input", OnInput, false);
    }

    // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
    function OnInput(event) {
      event.target.style.height = "auto";
      event.target.style.height = `${event.target.scrollHeight}px`;
    }
  }, []);

  // @ts-expect-error TS(7031) FIXME: Binding element 'mode' implicitly has an 'any' typ... Remove this comment to see the full error message
  function handleLocalMatrixUpdate({ mode, index, data = {} }) {
    let newRiskThemes = riskThemes;

    if (mode === "update") {
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      newRiskThemes = riskThemes.map((item, i) =>
        i === index ? { ...item, ...data } : item,
      );
    } else if (mode === "delete") {
      // @ts-expect-error TS(7006) FIXME: Parameter '_' implicitly has an 'any' type.
      newRiskThemes = riskThemes.filter((_, i) => i !== index);
    }

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onChange({
      ...matrix,
      risk_themes: newRiskThemes,
    });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
  function handleTitleChange(evt, index) {
    const data = { title: evt.target.value };
    handleLocalMatrixUpdate({ mode: "update", index, data });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
  function handleWeightingChange(evt, index) {
    let { value } = evt.target;
    if (value && value < 0) {
      value = 0;
    }
    if (value > 100) {
      value = 100;
    }

    const data = { weighting: value };
    handleLocalMatrixUpdate({ mode: "update", index, data });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
  function handleDescriptionChange(evt, themeIdx, descriptionIdx) {
    const newRiskThemes = [...riskThemes];
    newRiskThemes[themeIdx].risk_descriptions[descriptionIdx].content =
      evt.target.value;
  }

  function handleDeleteSubmit() {
    handleLocalMatrixUpdate({ mode: "delete", index: deletePopupOpened });
    setDeletePopupOpened(null);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'theme' implicitly has an 'any' type.
  const renderThemeItem = (theme, themeIdx) => {
    const isDeleting = deletePopupOpened === themeIdx;

    const renderPopupTrigger = (
      <div>
        <IconButton icon="trash" theme="tertiary" size="sm" />
      </div>
    );

    const renderDeleteConfirmation = (
      <div className="vdd-risk-assessment__popup">
        <div className="table-dropdown success-dropdown">
          <ConfirmationDialog
            heading="Delete Theme"
            content="Are you sure you want to delete this Theme? This action is not reversible."
            handleConfirm={handleDeleteSubmit}
            // @ts-expect-error TS(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
            handleReject={() => setDeletePopupOpened(false)}
          />
        </div>
      </div>
    );

    const renderHeading = (
      <div className="vdd-risk-assessment__risk-theme-header">
        <input
          type="text"
          data-testid="vdd-risk-assessment__risk-theme-input"
          autoFocus
          value={theme.title}
          placeholder="eg. Supplier Viability"
          onChange={(evt) => handleTitleChange(evt, themeIdx)}
        />

        {isAutoCalc && (
          <input
            type="number"
            className="weighting"
            value={theme.weighting}
            placeholder="Weighting"
            min="0"
            max="100"
            onChange={(evt) => handleWeightingChange(evt, themeIdx)}
          />
        )}

        {canDelete && (
          <Popup
            position="bottom left"
            trigger={renderPopupTrigger}
            open={isDeleting}
            onOpen={() => setDeletePopupOpened(themeIdx)}
            onClose={() => setDeletePopupOpened(null)}
            keepTooltipInside
          >
            {renderDeleteConfirmation}
          </Popup>
        )}
      </div>
    );

    // @ts-expect-error TS(7006) FIXME: Parameter 'description' implicitly has an 'any' ty... Remove this comment to see the full error message
    const renderDescriptionItem = (description, descriptionIdx) => {
      const { content } = description;

      return (
        <div
          key={descriptionIdx}
          className="vdd-risk-assessment__risk-theme-description"
          data-testid="vdd-risk-assessment__risk-theme-description"
        >
          <textarea
            rows={1}
            onChange={(evt) =>
              handleDescriptionChange(evt, themeIdx, descriptionIdx)
            }
            data-testid="vdd-risk-assessment__risk-theme-description-textarea-active"
            defaultValue={content}
          />
        </div>
      );
    };

    const renderDescriptions = (
      <div className="vdd-risk-assessment__risk-theme-descriptions">
        {theme.risk_descriptions.map(renderDescriptionItem)}
      </div>
    );

    return (
      <div
        key={themeIdx}
        className="vdd-risk-assessment__risk-theme"
        data-testid="vdd-risk-assessment__risk-theme"
      >
        {renderHeading}
        {renderDescriptions}
      </div>
    );
  };

  return (
    <section
      className="vdd-risk-assessment__risk-themes"
      data-testid="vdd-risk-assessment__risk-themes"
    >
      {riskThemes.map(renderThemeItem)}
    </section>
  );
}

RiskAssessmentThemes.defaultProps = {
  canDelete: false,
};

export default RiskAssessmentThemes;
