import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { UpdateNotificationRequest } from "../gen";
import {
  dismissAllNotifications,
  listNotifications,
  readAllNotifications,
  updateNotification,
} from "../gen/axios/notificationsController";

export const NOTIFICATIONS_QUERY_KEY = "notifications";

export function useNotifications() {
  return useInfiniteQuery({
    queryKey: [NOTIFICATIONS_QUERY_KEY],
    queryFn: (params) => {
      return listNotifications({ page: params.pageParam });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.meta.next_page) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });
}

export function useDismissAllNotifications() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dismissed_at: string) =>
      dismissAllNotifications({ dismissed_at }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTIFICATIONS_QUERY_KEY],
      });
    },
  });
}

export function useReadAllNotifications() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (read_at: string) => readAllNotifications({ read_at }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTIFICATIONS_QUERY_KEY],
      });
    },
  });
}

export function useUpdateNotification(id: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: UpdateNotificationRequest) =>
      updateNotification(id, request),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTIFICATIONS_QUERY_KEY],
      });
    },
  });
}
