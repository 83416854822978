import { zodResolver } from "@hookform/resolvers/zod";
import {
  Avatar,
  Button,
  ConfirmationPopup,
  DatePicker,
  FilePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectProps,
  TextArea,
  TextInput,
  useToast,
} from "@themis/ui";
import { format, parseISO } from "date-fns";
import React, {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { ControllerRenderProps, useForm } from "react-hook-form";
import { PiLockSimpleBold } from "react-icons/pi";
import { z } from "zod";

import { CompanyUser, Relatable, Task, UpdateTaskRequest } from "@/api";
import { useCompanyUsers } from "@/api/queries/users";
import { getWorkspaceLogo } from "@/components/helpers/getWorkspaceLogo";
import mainStore from "@/stores/Main";
import { Workspace } from "@/stores/types/workspace-types";

import { TASK_STATUSES } from "../config/status";
import {
  TASK_VISIBILITIES,
  TASK_VISIBILITY_HELPER_TEXT,
  TaskVisibility,
} from "../config/visibility";
import { AssociatedRecordRow } from "./AssociatedRecordRow";
import { DirectUploadProviderWrapper } from "./DirectUploadProviderWrapper";
import MultiUserSelect from "./MultiuserSelect";
import RecordSelect from "./Select/RecordSelect";

const formSchema = z
  .object({
    name: z.string().min(1, "Task name is required"),
    assignee_id: z.number().nullable().optional(),
    description: z.string().optional(),
    due_date: z.string().nullable().optional(),
    status: z.enum(["not_started", "in_progress", "done"]),
    workspace_id: z.number().nullable().optional(),
    visibility: z.enum(["private", "public", "internal"]),
  })
  .refine(
    (data) =>
      !(
        data.visibility === TaskVisibility.Public.value &&
        mainStore.workspaces.list.find(
          (workspace) => workspace.id === data.workspace_id,
        )?.is_internal
      ),
    {
      message: "Public tasks cannot be associated with an internal workspace",
      path: ["visibility"],
    },
  );

export type TaskDetailFormSchema = z.infer<typeof formSchema>;

type TaskDetailFormProps = {
  workspaces: Workspace[];
  defaultValues: Partial<Task>;
  onSubmit: (
    formValues: TaskDetailFormSchema,
    additionalFields: {
      taskables?: Relatable[];
      attachmentIds: string[];
      collaboratorIds?: number[];
    },
  ) => void;
  onClickArchive: MouseEventHandler<HTMLButtonElement>;
  onFieldChange: (updatedValue: Partial<UpdateTaskRequest["task"]>) => void;
  formDidChange: boolean;
  setFormDidChange: Dispatch<SetStateAction<boolean>>;
  onRecordSelect: (record: Relatable) => void;
  onRecordDelete: (id: number) => void;
  onAttachmentAdd: (signedId: string) => void;
  onAttachmentDelete: (attachmentId: number) => void;
  onAttachmentUpdate: (attachmentId: number, newSignedId: string) => void;
};

export default function TaskDetailForm({
  workspaces,
  defaultValues,
  onSubmit,
  onClickArchive,
  onFieldChange,
  formDidChange,
  setFormDidChange,
  onRecordSelect,
  onRecordDelete,
  onAttachmentAdd,
  onAttachmentDelete,
  onAttachmentUpdate,
}: TaskDetailFormProps) {
  const isNew = !defaultValues?.id;
  const isArchived = Boolean(defaultValues?.archived_at);
  const companyId = mainStore.companies.company?.id;
  const { activeWorkspace } = mainStore.context;
  const internalWorkspace = workspaces.find((ws) => ws.is_internal);
  const isInternalUser = Boolean(workspaces.find((ws) => ws.is_internal));
  const toast = useToast();

  const [confirmationState, setConfirmationState] = useState<{
    value: number | string | null;
    field:
      | ControllerRenderProps<TaskDetailFormSchema, "workspace_id">
      | ControllerRenderProps<TaskDetailFormSchema, "visibility">
      | null;
    open: boolean;
  }>({ value: null, field: null, open: false });

  const [newSelectedCollaboratorIds, setNewSelectedCollaboratorIds] = useState<
    number[]
  >([]);

  const [newSelectedTaskables, setNewSelectedTaskables] = useState<Relatable[]>(
    () => {
      if (!defaultValues?.taskables) {
        return [];
      }
      return defaultValues.taskables
        .map((taskable) => taskable.target)
        ?.filter(Boolean) as Relatable[];
    },
  );

  const [newSelectedAttachments, setNewSelectedAttachments] = useState<
    { signedId: string; file: File }[]
  >([]);

  const formattedDefaultValues = {
    name: defaultValues?.name || "",
    assignee_id: defaultValues?.assignee_id || null,
    description: defaultValues?.description || "",
    due_date: defaultValues?.due_date ? defaultValues?.due_date : null,
    status: defaultValues?.status || "not_started",
    workspace_id: defaultValues?.workspace_id || activeWorkspace?.id,
    visibility: (() => {
      if (defaultValues?.visibility) {
        return defaultValues.visibility;
      }
      if (!isInternalUser) {
        return TaskVisibility.Public.value;
      }
      if (activeWorkspace?.is_internal) {
        return TaskVisibility.Internal.value;
      }
      return TaskVisibility.Public.value;
    })(),
  };
  const form = useForm<TaskDetailFormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: formattedDefaultValues,
    mode: "onBlur",
  });
  const watchedValues = form.watch();

  const {
    data: { data: selectableUsers } = {},
    isPending: isSelectableUsersPending,
  } = useCompanyUsers(Number(companyId), {
    workspace_id:
      watchedValues.visibility === TaskVisibility.Internal.value // Internal visibility supersedes workspace_id when determining selectable users
        ? internalWorkspace?.id
        : watchedValues.workspace_id ?? undefined,
    view: "active",
  });

  const usersForSelect: SelectProps["items"] = (selectableUsers ?? [])
    .filter((user) => {
      return (
        user.status === "Active" ||
        user.is_active ||
        user.id === defaultValues?.assignee_id
      );
    })
    .map((user: CompanyUser) => {
      return {
        label: user.full_name as string,
        value: user.id.toString(),
        Component: ({ label }) => (
          <span className="tw-flex tw-space-x-2">
            <Avatar
              size="sm"
              colorIndex={user.icon_color_index}
              variant={!user.is_active ? "pending" : undefined}
            >
              {user.initials}
            </Avatar>
            <span>{label}</span>
          </span>
        ),
      };
    });

  const workspaceItems: SelectProps["items"] = workspaces.map((workspace) => ({
    label: workspace.name,
    value: String(workspace.id),
    Component: () => (
      <div className="tw-flex tw-items-center tw-gap-2">
        <img
          className="tw-w-[20px] tw-rounded"
          src={getWorkspaceLogo(workspace).logo}
        />
        <span className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
          {workspace.name}
        </span>
      </div>
    ),
  }));

  useEffect(() => {
    if (Object.keys(form.formState.dirtyFields).length > 0) {
      setFormDidChange(true);
    }
  }, [Object.keys(form.formState.dirtyFields).length]);

  useEffect(() => {
    form.reset(formattedDefaultValues);
  }, [defaultValues?.last_updated_at]);

  useEffect(() => {
    if (isNew && !isSelectableUsersPending && selectableUsers) {
      removeUsers();
    }
  }, [selectableUsers]);

  // Handles submit onclick of Create Task button, which only appears if the task is new
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!isNew) {
      return;
    }

    form.handleSubmit((formData) =>
      onSubmit(formData, {
        taskables: newSelectedTaskables,
        attachmentIds: newSelectedAttachments.map(
          (attachment) => attachment.signedId,
        ),
        collaboratorIds: newSelectedCollaboratorIds,
      }),
    )();
  }

  // If a new task's workspace is changed, remove assignees and collaborators who are not in that workspace
  // If a new task's visiblity is changed to internal, remove assignees and collaborators who are not in the internal workspace
  function removeUsers() {
    const selectableUserIds = selectableUsers?.map((user) => user.id) ?? [];
    const assigneeId = form.getValues("assignee_id");

    // Remove assignee if they are not in the new workspace
    if (assigneeId && !selectableUserIds.includes(assigneeId)) {
      form.setValue("assignee_id", null);
    }

    // Remove collaborators if they are not in the new workspace
    if (newSelectedCollaboratorIds.length) {
      const workspaceOnlyCollaboratorIds = newSelectedCollaboratorIds.filter(
        (id) => selectableUserIds.includes(id),
      );
      if (
        workspaceOnlyCollaboratorIds.length < newSelectedCollaboratorIds.length
      ) {
        setNewSelectedCollaboratorIds(workspaceOnlyCollaboratorIds);
      }
    }
  }

  function handleSubmitOnBlur(updatedValue: Partial<TaskDetailFormSchema>) {
    if (isNew || !formDidChange) {
      return;
    }

    form.handleSubmit(() => onFieldChange(updatedValue))();
  }

  async function handleSubmitOnChange(
    updatedValue: Partial<TaskDetailFormSchema>,
  ) {
    if (isNew) {
      return;
    }
    form.handleSubmit(() => onFieldChange(updatedValue))();
  }

  function handleSelect(
    value: string | number,
    field:
      | ControllerRenderProps<TaskDetailFormSchema, "workspace_id">
      | ControllerRenderProps<TaskDetailFormSchema, "visibility">,
    showConfirmation: boolean,
  ) {
    if (showConfirmation) {
      setConfirmationState({ value, field, open: true });
    } else {
      field.onChange(value);
      handleSubmitOnChange({ [field.name]: value });
    }
  }

  function handleConfirmChange() {
    if (confirmationState.field) {
      confirmationState.field.onChange(confirmationState.value);
      handleSubmitOnChange({
        [confirmationState.field.name]: confirmationState.value,
      });
      setConfirmationState({ value: null, field: null, open: false });
    }
  }

  function handleAssigneeSelect(
    value: number,
    field: ControllerRenderProps<TaskDetailFormSchema, "assignee_id">,
  ) {
    if (field.value === value) {
      field.onChange(null);
      handleSubmitOnChange({ assignee_id: null });
    } else {
      field.onChange(value);
      handleSubmitOnChange({ assignee_id: value });
    }
  }

  function handleRecordSelect(record: Relatable) {
    if (isNew) {
      setNewSelectedTaskables((prevTaskables) => [...prevTaskables, record]);
    } else {
      onRecordSelect(record);
    }
  }

  function handleCollaboratorSelect(collaboratorIds: number[]) {
    if (isNew) {
      setNewSelectedCollaboratorIds(collaboratorIds);
    } else {
      onFieldChange({ collaborator_ids: collaboratorIds });
    }
  }

  function handleDeleteNewTaskable(id: number) {
    setNewSelectedTaskables((prevTaskables) =>
      prevTaskables.filter((taskable) => taskable.id !== id),
    );
  }

  return (
    <Form {...form}>
      <ConfirmationPopup
        title="Users may be removed from task"
        content={`Assignees and collaborators not in the ${
          confirmationState.field?.name === "visibility"
            ? "Internal"
            : workspaces.find((ws) => ws.id === confirmationState.value)?.name
        } workspace will be removed. Do you want to proceed?`}
        open={confirmationState.open}
        onCancel={() =>
          setConfirmationState({
            value: null,
            field: null,
            open: false,
          })
        }
        onConfirm={handleConfirmChange}
        align="center"
        anchor
      />
      <form onSubmit={handleSubmit} className="tw-p tw-space-y-4">
        <FormField
          required
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Task Name</FormLabel>
              <FormControl>
                <TextInput
                  {...field}
                  onBlur={() => handleSubmitOnBlur({ name: field.value })}
                  readOnly={isArchived}
                  autoComplete="off"
                  autoFocus={isNew}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="tw-flex tw-flex-col tw-space-y-1">
          <div className="tw-flex tw-flex-row tw-space-x-2">
            <FormField
              control={form.control}
              name="visibility"
              render={({ field }) => (
                <FormItem className="tw-basis-1/2">
                  <FormLabel>Visibility</FormLabel>
                  <FormControl>
                    <Select
                      readOnly={isArchived || !isInternalUser}
                      items={TASK_VISIBILITIES}
                      selected={field.value}
                      onSelect={(value) => {
                        handleSelect(
                          value,
                          field,
                          value === TaskVisibility.Internal.value,
                        );
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="workspace_id"
              render={({ field }) => (
                <FormItem className="tw-basis-1/2">
                  <FormLabel>Workspace</FormLabel>
                  <FormControl>
                    <Select
                      searchable
                      readOnly={isArchived}
                      items={workspaceItems}
                      selected={field.value ? String(field.value) : null}
                      onSelect={(value) => {
                        handleSelect(Number(value), field, true);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-space-x-1 tw-text-xs tw-font-medium">
            <PiLockSimpleBold />
            <span>{TASK_VISIBILITY_HELPER_TEXT[watchedValues.visibility]}</span>
          </div>
        </div>
        <FormField
          control={form.control}
          name="assignee_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Assigned to</FormLabel>
              <FormControl>
                <Select
                  readOnly={isArchived}
                  searchable
                  items={usersForSelect}
                  selected={field.value ? String(field.value) : null}
                  onSelect={(value: string) =>
                    handleAssigneeSelect(Number(value), field)
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div>
          <label className="tw-font-sans tw-text-xs tw-font-semibold tw-leading-none tw-tracking-wide tw-text-neutral-300">
            Collaborators
          </label>
          <MultiUserSelect
            users={selectableUsers || []}
            selectedUserIds={
              isNew
                ? newSelectedCollaboratorIds
                : defaultValues?.collaborator_ids || []
            }
            onSelect={(collaborator_ids) =>
              handleCollaboratorSelect(collaborator_ids)
            }
          />
        </div>
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <TextArea
                  {...field}
                  onBlur={() =>
                    handleSubmitOnBlur({ description: field.value })
                  }
                  readOnly={isArchived}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="due_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Due Date</FormLabel>
              <FormControl>
                <DatePicker
                  readOnly={isArchived}
                  calendarProps={{
                    mode: "single",
                    selected: field.value ? parseISO(field.value) : undefined,
                    onSelect: (value) => {
                      if (value) {
                        const formattedDate = format(value, "yyyy-MM-dd");
                        field.onChange(formattedDate);
                        handleSubmitOnChange({ due_date: formattedDate });
                      }
                    },
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="status"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Status</FormLabel>
              <FormControl>
                <Select
                  readOnly={isArchived}
                  items={TASK_STATUSES}
                  selected={field.value}
                  onSelect={(value: string) => {
                    field.onChange(value);
                    handleSubmitOnChange({
                      status: value as "not_started" | "in_progress" | "done",
                    });
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="tw-grid tw-min-w-0 tw-grid-cols-1 tw-gap-2">
          <FormLabel>Attachments</FormLabel>
          {isNew
            ? newSelectedAttachments.map((selectedAttachment) => (
                <DirectUploadProviderWrapper
                  key={selectedAttachment.signedId}
                  onSuccess={(attachment) => {
                    setNewSelectedAttachments((prev) =>
                      prev.map((a) =>
                        a.signedId === selectedAttachment.signedId
                          ? attachment
                          : a,
                      ),
                    );
                  }}
                  onError={() => {
                    toast({
                      content:
                        "Error uploading file. Please try again or use a different file type.",
                      variant: "error",
                    });
                  }}
                  render={({
                    handleUpload,
                    ready,
                    uploads,
                    isOpen,
                    setIsOpen,
                  }) => {
                    return (
                      <FilePicker
                        file={selectedAttachment.file}
                        isLoading={["uploading", "waiting"].includes(
                          uploads[0]?.state,
                        )}
                        percentage={Math.round(uploads[0]?.progress) || 0}
                        readOnly={!ready}
                        onSelectFile={(selectedFile) => {
                          handleUpload([selectedFile]);
                        }}
                        onRemoveFile={() =>
                          setNewSelectedAttachments((prev) =>
                            prev.filter(
                              (a) => a.signedId !== selectedAttachment.signedId,
                            ),
                          )
                        }
                        alignPopover="end"
                        isOpen={isOpen}
                        onOpenChange={setIsOpen}
                      />
                    );
                  }}
                />
              ))
            : defaultValues?.attachments?.map((defaultAttachment) => (
                <DirectUploadProviderWrapper
                  key={defaultAttachment?.id}
                  onSuccess={(attachment) => {
                    onAttachmentUpdate(
                      Number(defaultAttachment?.id),
                      attachment.signedId,
                    );
                  }}
                  onError={() => {
                    toast({
                      content:
                        "Error uploading file. Please try again or use a different file type.",
                      variant: "error",
                    });
                  }}
                  render={({
                    handleUpload,
                    ready,
                    uploads,
                    isOpen,
                    setIsOpen,
                  }) => {
                    return (
                      <FilePicker
                        file={{
                          name: String(defaultAttachment?.file?.file_name),
                          url: String(defaultAttachment?.file?.file_url),
                          type: String(defaultAttachment?.file?.content_type),
                        }}
                        isLoading={["uploading", "waiting"].includes(
                          uploads[0]?.state,
                        )}
                        percentage={Math.round(uploads[0]?.progress) || 0}
                        readOnly={isArchived || !ready}
                        onSelectFile={(selectedFile) => {
                          handleUpload([selectedFile]);
                        }}
                        onRemoveFile={
                          isArchived
                            ? undefined
                            : () =>
                                onAttachmentDelete(
                                  Number(defaultAttachment?.id),
                                )
                        }
                        isOpen={isOpen}
                        onOpenChange={setIsOpen}
                      />
                    );
                  }}
                />
              ))}
          <div className="tw-flex tw-w-full tw-justify-center">
            <DirectUploadProviderWrapper
              onSuccess={(attachment) => {
                isNew
                  ? setNewSelectedAttachments((prev) => [...prev, attachment])
                  : onAttachmentAdd(attachment.signedId);
              }}
              onError={() => {
                toast({
                  content:
                    "Error uploading file. Please try again or use a different file type.",
                  variant: "error",
                });
              }}
              render={({ handleUpload, ready, uploads, isOpen, setIsOpen }) => {
                return (
                  <FilePicker
                    isLoading={["uploading", "waiting"].includes(
                      uploads[0]?.state,
                    )}
                    percentage={Math.round(uploads[0]?.progress) || 0}
                    readOnly={!ready}
                    onSelectFile={(selectedFile) => {
                      handleUpload([selectedFile]);
                    }}
                    trigger={
                      <Button size="sm" color="grey" disabled={isArchived}>
                        Add Attachment
                      </Button>
                    }
                    alignPopover="end"
                    isOpen={isOpen}
                    onOpenChange={setIsOpen}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="tw-grid tw-gap-2">
          <div className="tw-flex tw-items-center tw-justify-between">
            <h3 className="tw-text-base tw-font-semibold tw-text-neutral-500">
              Associated Records
            </h3>
            <RecordSelect
              ignoredRecordIds={
                isNew
                  ? newSelectedTaskables.map((taskable) => taskable.id)
                  : defaultValues?.taskables?.map(
                      (taskable) => taskable.target?.id || 0,
                    )
              }
              isDisabled={isArchived}
              onRecordSelect={handleRecordSelect}
            />
          </div>
          <div>
            {isNew
              ? newSelectedTaskables.map((taskable) => (
                  <AssociatedRecordRow
                    key={taskable.id}
                    relatedRecord={taskable}
                    onClickDelete={handleDeleteNewTaskable}
                  />
                ))
              : defaultValues?.taskables?.map((relatedTask) => (
                  <AssociatedRecordRow
                    taskable={relatedTask}
                    key={relatedTask.id}
                    relatedRecord={relatedTask.target}
                    onClickDelete={isArchived ? undefined : onRecordDelete}
                  />
                ))}
          </div>
        </div>
        {isNew ? (
          <Button className="tw-mt-6" type="submit">
            Create Task
          </Button>
        ) : (
          <div className="tw-flex tw-justify-center ">
            {isArchived ? (
              <Button
                color="transparent"
                type="button"
                onClick={onClickArchive}
              >
                Unarchive Task
              </Button>
            ) : (
              <Button
                color="transparent"
                type="button"
                className="tw-text-[#EB2E4E]"
                onClick={onClickArchive}
              >
                Archive Task
              </Button>
            )}
          </div>
        )}
      </form>
    </Form>
  );
}
