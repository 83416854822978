import { noop } from "lodash";
import React, { Dispatch, SetStateAction } from "react";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import HeadSelect from "@/components/table/shared/HeadSelect";
import Table from "@/components/table/Table";

import PoliciesAttestation from "../components/PoliciesAttestation";
import PoliciesTabHeader from "../components/PoliciesTabHeader";

interface PoliciesAttestationPageProps {
  tableName: string;
  setShowCheckbox: Dispatch<SetStateAction<boolean>>;
  buttonDisabled: boolean;
  moduleWorkspaceID: number;
  showCTA: boolean;
  isInternal: boolean;
  showCheckbox: boolean;
  recordName: string;
  recordNamePlural: string;
}

const PoliciesAttestationPage = ({
  tableName,
  setShowCheckbox,
  buttonDisabled,
  moduleWorkspaceID,
  showCTA,
  isInternal,
  showCheckbox,
  recordName,
  recordNamePlural,
}: PoliciesAttestationPageProps) => (
  <DashboardContent>
    <DashboardHeader
      title={`${recordName} Attestation Status`}
      LeftActionBar={<ViewModuleUsers />}
      RightActionBar={<HeadSelect />}
    />
    <DashboardContentWrapper>
      <Table>
        <PoliciesTabHeader
          tableName={tableName}
          setShowCheckbox={setShowCheckbox}
          buttonDisabled={buttonDisabled}
          moduleWorkspaceID={moduleWorkspaceID}
          openImportSlideOut={noop}
        />
        <div className="policies-page-content">
          <PoliciesAttestation
            showCTA={showCTA}
            isInternal={isInternal}
            showCheckbox={showCheckbox}
            recordName={recordName}
            recordNamePlural={recordNamePlural}
          />
        </div>
      </Table>
    </DashboardContentWrapper>
  </DashboardContent>
);

export default PoliciesAttestationPage;
