import { DependencyList, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

/**
 * Will prevent navigation away from page if 1 or more conditions are true
 * @param conditionsThatPreventNavigation
 * @param deps - any react state dependencies that should retrigger calculations of first param
 * @returns unblock callback, must be called prior to overriding navigation
 */
export function usePreventUnsavedChanges(
  conditionsThatPreventNavigation: boolean[],
  deps: DependencyList,
) {
  const history = useHistory();
  const isSafeToNavigate = useRef<boolean>();

  useEffect(() => {
    const shouldPreventNavigation =
      conditionsThatPreventNavigation.includes(true);

    // @ts-expect-error TS(2345) FIXME: Argument of type '() => boolean' is not assignable... Remove this comment to see the full error message
    const unblock = history.block(() => {
      if (isSafeToNavigate.current) {
        return true;
      }

      if (shouldPreventNavigation) {
        return window.confirm(
          "Unsaved changes will be lost. Are you sure you want to proceed?",
        );
      }

      return true;
    });

    const unloadEventHandler = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      if (!isSafeToNavigate.current || shouldPreventNavigation) {
        e.returnValue = "";
        return "";
      }
    };
    addEventListener("beforeunload", unloadEventHandler, { capture: true });

    return () => {
      unblock();
      removeEventListener("beforeunload", unloadEventHandler, {
        capture: true,
      });
    };
  }, deps);

  return () => (isSafeToNavigate.current = true);
}
