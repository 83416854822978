import React from "react";

type Props = {
  children?: React.ReactNode;
};

const ActionItemSection = ({ children }: Props) => (
  <div className="action-item-section">{children}</div>
);

export { ActionItemSection };
