import React from "react";
import { Link } from "react-router-dom";

import TextButton from "../../Elements/TextButton";

type Props = {
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
  to?: string;
};

const SectionLink = ({ label, onClick, to }: Props) => {
  // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  const textButton = <TextButton label={label} onClick={onClick} />;
  return (
    <div className="section-link">
      {to ? <Link to={to}>{textButton}</Link> : textButton}
    </div>
  );
};

export { SectionLink };
