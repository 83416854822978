import { Field } from "@/api";

import { ModuleFieldOptions, OptionsList } from "./field-types";

export enum ENABLED {
  TRUE = "enabled",
  FALSE = "disabled",
}

export enum DAYS_OF_WEEK {
  SUNDAY = "sunday",
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
}

export enum TRIGGER {
  ONE_TIME = "schedule",
  RECURRING = "recurring",
}

export enum SCALE {
  DAILY = "days",
  WEEKLY = "weeks",
  MONTHLY = "months",
}

export enum NOTIFICATION_TYPE {
  REMINDER = "reminder",
  OVERDUE = "overdue",
  AUTO_UNLOCK = "auto_unlock",
}

export enum RELATIVE_TO {
  BEFORE = "before",
  AFTER = "after",
  ON = "on",
}

export enum RECURRING_INTERVAL_SCALE {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export type NotificationRuleData = {
  data: {
    enabled: OptionsList<ENABLED>;
    recurring?: {
      day: OptionsList<DAYS_OF_WEEK>;
      scale: OptionsList<SCALE>;
      trigger_column: OptionsList<string>;
      relative: OptionsList<RELATIVE_TO>;
      interval: {
        distance: {
          value: number;
        };
        ignore_weekends: boolean;
        scale: OptionsList<RECURRING_INTERVAL_SCALE>;
      };
    };
    notification_type: OptionsList<NOTIFICATION_TYPE>;
    scheduled?: {
      interval: number[];
      scale: OptionsList<SCALE>;
      relative: OptionsList<RELATIVE_TO>;
      trigger_column: OptionsList<string>;
    };
    filters: {
      field_name: OptionsList<string>;
      field_value: OptionsList<string>;
    }[];

    recipients: {
      columns: { field_names: string[] };
      departments: { ids: number[] };
      users: { ids: number[] };
    };
  };
  global_id?: string;
  id?: number;
  module_workspace_id?: number;
  trigger: TRIGGER;
  record_type?: string;
  appended_processes?: string[];
};

export type NotificationsRulesStoreData = {
  notification_rules?: NotificationRuleData[];
  field_options?: ModuleFieldOptions;
  fields?: Field[];
};
