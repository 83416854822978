import "./WorkspaceRow.scss";

import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { ConfirmPopup } from "@/components/confirm-popup";
import { Flex, Typography } from "@/components/Elements";
import { getWorkspaceLogo } from "@/components/helpers/getWorkspaceLogo";
import { Divider, IconMenu, IconMenuItem } from "@/components/IconMenu";
import DirectUpload from "@/components/table/shared/DirectUpload";
import { useMainStore } from "@/contexts/Store";
import { Workspace } from "@/stores/types/workspace-types";

import { getCanAddRemoveWorkspaces } from "./helpers";

type Props = {
  workspace: Workspace;
};

function WorkspaceRow({ workspace }: Props) {
  const mainStore = useMainStore();
  const history = useHistory();

  const {
    is_archived: archived,
    id: workspaceID,
    name: workspaceName,
    is_collaborative_only: inbound,
    company,
    company_id: companyID,
    is_internal: isInternal,
  } = workspace;

  const archiveUpdateLabel = archived ? "Unarchive" : "Archive";

  const canAddRemoveWorkspaces = getCanAddRemoveWorkspaces(
    isInternal,
    companyID,
    mainStore.users.user.roles,
  );

  const [deleting, setDeleting] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);
  const [archivedUpdating, setArchivedUpdating] = useState(false);
  const [name, setName] = useState(workspaceName);
  const [showChangeIconPopup, setShowChangeIconPopup] = useState(false);
  const [upload, setUpload] = useState("");

  function renameClick() {
    setIsRenaming(true);
  }

  function handleEditName(event: React.KeyboardEvent<HTMLInputElement>) {
    const hitEnter = event.key === "Enter";
    const hitEscape = event.key === "Escape";

    if (hitEnter) {
      setIsRenaming(false);
    }

    if (hitEnter && workspaceID && name) {
      mainStore.workspaces.update(workspaceID, { name });
    }

    if (hitEscape) {
      setIsRenaming(false);
      setName(workspaceName);
    }
  }

  function toastMessage(action: "deleted" | "archived" | "unarchived") {
    return `"${workspaceName}" Workspace ${action}.`;
  }

  function handleDeleteWorkspace() {
    mainStore.workspaces.delete(workspaceID);

    setDeleting(false);

    mainStore.toast.setText(toastMessage("deleted"));
  }

  function handleAttachment(signedIds: string[]) {
    const workspaceParams = { logo: signedIds[0] };

    mainStore.workspaces.update(workspaceID, workspaceParams);

    setShowChangeIconPopup(false);
    setUpload("");
  }

  async function handleUpdateArchived() {
    const isArchived = archived;

    isArchived
      ? await mainStore.workspaces.unarchive(workspaceID)
      : await mainStore.workspaces.archive(workspaceID);

    setArchivedUpdating(false);

    mainStore.toast.setText(
      toastMessage(isArchived ? "unarchived" : "archived"),
    );
  }

  async function switchWorkspace() {
    await mainStore.workspaces.switch(workspaceID);
    history.push(`/workspaces/${workspaceID}/home`);
  }

  return (
    <>
      <Flex columnGap={8} alignCenter>
        <img
          src={getWorkspaceLogo(workspace).logo}
          alt="logo"
          className="workspace-row__workspace-logo"
        />
        {isRenaming ? (
          <input
            type="text"
            className="new-section-tag"
            data-testid="new-section-tag-input"
            value={name}
            autoFocus
            onKeyDown={handleEditName}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        ) : (
          <Typography
            className="workspace-row__name workspace-row--no-wrap"
            title={workspaceName}
            color="generalDark"
            label={workspaceName}
            size="md"
            weight="semiBold"
          />
        )}
        {!inbound && (
          <>
            <IconMenu nested icon="moreHorizontal">
              {canAddRemoveWorkspaces && archived && (
                <IconMenuItem
                  label="View Workspace"
                  onClick={switchWorkspace}
                />
              )}
              <Popup
                position="right center"
                trigger={
                  <div>
                    <IconMenuItem
                      nested
                      label="Change Workspace Icon"
                      onClick={() => setShowChangeIconPopup(true)}
                    />
                  </div>
                }
                open={showChangeIconPopup}
                onOpen={() => setShowChangeIconPopup(true)}
                onClose={() => setShowChangeIconPopup(false)}
                keepTooltipInside
              >
                <DirectUpload
                  supportedFileTypes={["jpg", "jpeg", "png"]}
                  attachmentUpload={handleAttachment}
                  setUpload={setUpload}
                  upload={upload}
                  multiple={false}
                />
              </Popup>
              <IconMenuItem label="Rename" onClick={renameClick} />
              {canAddRemoveWorkspaces && (
                <>
                  <Divider />
                  <IconMenuItem
                    label="Delete"
                    onClick={() => setDeleting(true)}
                  />
                  <IconMenuItem
                    label={archiveUpdateLabel}
                    onClick={() => setArchivedUpdating(true)}
                  />
                </>
              )}
            </IconMenu>
            <ConfirmPopup
              showPopup={deleting}
              setShowPopup={setDeleting}
              title="Delete Workspace"
              content="Actions are non-reversible. Are you sure you want to proceed?"
              onConfirmClick={handleDeleteWorkspace}
            />
            <ConfirmPopup
              showPopup={archivedUpdating}
              setShowPopup={setArchivedUpdating}
              title={`${archiveUpdateLabel} Workspace`}
              content="Are you sure you want to proceed?"
              onConfirmClick={handleUpdateArchived}
            />
          </>
        )}
      </Flex>
      <Typography
        label={inbound ? "Invited by " : "Created by "}
        color="generalMidnightDark"
        size="xs"
        weight="semiBold"
      />
      <Flex alignCenter columnGap={8} className={"workspace-row__company"}>
        {company.logo && (
          <img
            src={getWorkspaceLogo(workspace).logo}
            alt="logo"
            className="workspace-row__company-logo"
          />
        )}
        <Typography
          title={company.name}
          className="workspace-row__name workspace-row--no-wrap"
          label={company.name}
          color="extrasDim300"
          weight="semiBold"
          size="xs"
        />
      </Flex>
    </>
  );
}

export default observer(WorkspaceRow);
