import {
  ResidualRisk,
  ResidualRiskMatrix as ResidualRiskMatrixItem,
  RiskRating,
} from "../../../types/risk-methodology";

export function adjustRiskRatings(
  ratings: RiskRating[],
  lowRange: number,
  highRange: number,
): RiskRating[] {
  const newRatings = [...ratings];
  if (ratings.length > 0) {
    const maxRange = highRange;
    let index = newRatings.length - 1;
    newRatings[index].higher_range = maxRange;
    while (index > 0) {
      newRatings[index].lower_range = Number(
        parseFloat(newRatings[index - 1].higher_range.toString()),
      );
      if (
        index !== newRatings.length - 1 &&
        newRatings[index].higher_range === maxRange
      ) {
        newRatings[index].higher_range = Number(
          parseFloat(newRatings[index].lower_range.toString()),
        );
      }
      index--;
    }
    newRatings[0].lower_range = lowRange;
    index = newRatings.length - 1;
    if (newRatings[index].lower_range >= newRatings[index].higher_range) {
      newRatings[index].lower_range = newRatings[index].higher_range - 1;
    }
  }

  return newRatings;
}

export function makeMatrix(
  controlRiskRatings: Array<RiskRating>,
  inherentRiskRatings: Array<RiskRating>,
  residualRisks: Array<ResidualRisk>,
  residualRiskMatrices: Array<ResidualRiskMatrixItem>,
): Array<Array<ResidualRisk>> {
  const ans = [];
  const filteredControlRiskRatings = controlRiskRatings.filter(
    (rr) => !rr._destroy,
  );
  const filteredInherentRiskRatings = inherentRiskRatings.filter(
    (rr) => !rr._destroy,
  );
  const filteredResidualRisks = residualRisks.filter((rr) => !rr._destroy);

  for (const inherentRiskRating of filteredInherentRiskRatings) {
    const row = [];
    for (const controlRiskRating of filteredControlRiskRatings) {
      const residualRiskMatrix = residualRiskMatrices.find(
        (item) =>
          item.control_risk_rating?.text === controlRiskRating?.text &&
          item.inherent_risk_rating?.text === inherentRiskRating?.text,
      );
      // eslint-disable-next-line no-nested-ternary
      const selectedResidualRisk = residualRiskMatrix
        ? filteredResidualRisks
            .map((r) => r.id)
            .includes(residualRiskMatrix.residual_risk.id)
          ? residualRiskMatrix.residual_risk
          : filteredResidualRisks[0]
        : filteredResidualRisks[0];
      row.push(selectedResidualRisk);
    }
    ans.push(row);
  }
  return ans;
}
