import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";
import { API_URL } from "@/components/constants";
import { KRIThemisLibraryRecord } from "@/features/key-risk-indicators/pages/types";
import { MainStore } from "@/stores/Main";

import { ModuleStore } from "../types/module-store-types";

export default class KeyRiskIndicatorsThemisLibraryRecordsStore
  implements ModuleStore
{
  mainStore: MainStore;

  checkedRows: number[] = [];
  libraryRecords: KRIThemisLibraryRecord[] = [];
  selectedSectionTagID: number | "unassigned" | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      libraryRecords: observable,
      selectedSectionTagID: observable,
      setLibraryRecords: action,
      setSelectedSectionTagID: action,
      checkedRows: observable,
      setCheckedRows: action,
    });

    this.setCheckedRows = this.setCheckedRows.bind(this);
    this.mainStore = mainStore;
  }

  // GET /api/react/key_risk_indicators_themis_library_records
  async index() {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/key_risk_indicators_themis_library_records`,
        headers: this.mainStore.getHeaders(),
      });

      this.setLibraryRecords(response.data);
    } catch (error) {
      this.mainStore.toast.setErrorFromResponse(error);
      window.console.log(`"key_risk_indicators#fetchLibrary" error ${error}`);
    }
  }

  async copyToKri({ moduleWorkspaceID }: { moduleWorkspaceID: number }) {
    try {
      const sectionTagID =
        this.selectedSectionTagID === "unassigned"
          ? null
          : this.selectedSectionTagID;
      const params = {
        section_tag_id: sectionTagID,
        module_workspace_id: moduleWorkspaceID,
        ids: this.checkedRows,
      };
      await legacyApi({
        method: "PUT",
        params,
        url: `${API_URL}/key_risk_indicators_themis_library_records/copy_to_kri`,
        headers: this.mainStore.getHeaders(),
      });
      this.mainStore.toast.setText("KRIs copied successfully");
    } catch (error) {
      this.mainStore.toast.setErrorFromResponse(error);

      window.console.log(`"key_risk_indicators#copyToKri" error ${error}`);
    }
  }

  async copyToCompanyKriLibrary({
    companyID,
    ids,
  }: {
    companyID: number;
    ids: number[];
  }) {
    try {
      const params = {
        ids,
        company_id: companyID,
      };
      await legacyApi({
        method: "PUT",
        params,
        url: `${API_URL}/key_risk_indicators_themis_library_records/copy_to_company_library`,
        headers: this.mainStore.getHeaders(),
      });
      this.mainStore.toast.setText("KRIs copied successfully");
    } catch (error) {
      window.console.log(
        `"key_risk_indicators#copyToCompanyKriLibrary" error ${error}`,
      );
      this.mainStore.toast.setErrorFromResponse(error);
    }
  }

  setLibraryRecords(value: KRIThemisLibraryRecord[]) {
    this.libraryRecords = value;
  }

  setCheckedRows(value: number[]) {
    this.checkedRows = value;
  }
  setSelectedSectionTagID(value: number | "unassigned" | null) {
    this.selectedSectionTagID = value;
  }
}
