import {
  NOTIFICATION_FREQUENCY,
  TimePeriod,
} from "@/features/notifications/pages/single/date//types";
import {
  ENABLED,
  NOTIFICATION_TYPE,
  NotificationRuleData,
  RELATIVE_TO,
  SCALE,
  TRIGGER,
} from "@/stores/types/notification-rule-types";

import { initialCreateValues, UNLOCK_NOTIFICATION_RULE } from "./constants";
import { UnlockTabNotificationFormValues } from "./types";

/**
 * Serializes form data into a notification rule POST/PUT request body
 * @param formData notification form values
 * @returns notification rule POST/PUT request body
 */
export const serializeFormData = (
  formData: UnlockTabNotificationFormValues,
  record_type: string = "record_version",
): NotificationRuleData => {
  const { triggerColumn } = formData;

  const getScheduled: () => NotificationRuleData["data"]["scheduled"] = () => ({
    trigger_column: { options: [triggerColumn] },
    relative: { options: [RELATIVE_TO.BEFORE] },
    interval: formData.timePeriod.numberOfOneTimeDays,
    scale: { options: [SCALE.DAILY] },
  });

  return {
    record_type,
    trigger: TRIGGER.ONE_TIME,
    data: {
      enabled: {
        options: [formData.enabled ? ENABLED.TRUE : ENABLED.FALSE],
      },
      filters: formData.criteria
        .filter((criteria) => !!criteria.fieldValues.length)
        .map((singleCriteria) => ({
          field_name: { options: [singleCriteria.fieldName] },
          field_value: { options: singleCriteria.fieldValues },
        })),

      notification_type: { options: [NOTIFICATION_TYPE.AUTO_UNLOCK] },
      recipients: {
        columns: { field_names: formData.recipients.columns },
        departments: { ids: formData.recipients.department_ids },
        users: { ids: formData.recipients.user_ids },
      },
      scheduled: getScheduled(),
    },
    appended_processes: formData.canUnlockTest
      ? [UNLOCK_NOTIFICATION_RULE]
      : [],
  };
};

/**
 * Parses a notification rule from API into notification form values
 * @param notificationRule notification rule response from API
 * @returns notification form values
 */
export const parseNotificationRule = (
  notificationRule: NotificationRuleData,
): UnlockTabNotificationFormValues => {
  const getTimePeriod = (): TimePeriod => {
    return {
      ...initialCreateValues.timePeriod,
      numberOfOneTimeDays: notificationRule.data.scheduled?.interval || [],
    };
  };

  return {
    canUnlockTest: (notificationRule.appended_processes || []).includes(
      UNLOCK_NOTIFICATION_RULE,
    ),
    frequency: NOTIFICATION_FREQUENCY.ONE_TIME,
    type: notificationRule.data.notification_type.options[0],
    enabled: notificationRule.data.enabled.options[0] === ENABLED.TRUE,
    timePeriod: getTimePeriod(),
    condition: notificationRule.data.scheduled?.relative?.options?.[0],
    triggerColumn: String(
      notificationRule.data.scheduled?.trigger_column?.options?.[0],
    ),
    criteria: notificationRule.data.filters.map((filter) => ({
      fieldName: filter.field_name.options[0],
      fieldValues: filter.field_value.options,
    })),
    recipients: {
      columns: notificationRule.data.recipients.columns.field_names,
      user_ids: notificationRule.data.recipients.users.ids,
      department_ids: notificationRule.data.recipients.departments.ids,
    },
  };
};
