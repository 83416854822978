import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";

import { useMainStore } from "@/contexts/Store";

import cardBlackIcon from "../../../images/table-image/icon/card-view-black.svg";
import cardBlueLightIcon from "../../../images/table-image/icon/card-view-blue-light.svg";
import listBlueIcon from "../../../images/table-image/icon/list-bullet-active.svg";
import listBlackIcon from "../../../images/table-image/icon/list-bullet-black.svg";
import checkIcon from "../../../images/table-image/icon/options-check-blue-light.svg";

const DASHBOARD_ID = "company-wide";

type Props = {
  moduleName?: string;
  omitTopLevelSection?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMove?: (...args: any[]) => any;
  recordVersionID?: number;
};

function MoveToSectionPopup({
  moduleName,
  recordVersionID,
  onMove,
  onClose,
  omitTopLevelSection,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const sections = mainStore.sectionTags.orderedList;
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  );
  const currentSectionID = recordVersion?.section_tag_id;

  // States
  const [selectedSectionID, setSelectedSectionID] = useState(null);

  const filteredSections = useMemo(() => {
    if (!currentSectionID) {
      return sections;
    }

    if (omitTopLevelSection) {
      return sections.filter((section) => section.id !== currentSectionID);
    }

    return [
      { id: DASHBOARD_ID, title: `General ${moduleName}` },
      ...sections.filter((section) => section.id !== currentSectionID),
    ];
  }, [sections, currentSectionID]);

  const handleMove = async () => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onClose();

    const name = recordVersion?.meta?.name;
    try {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      await onMove(
        recordVersionID,
        selectedSectionID === DASHBOARD_ID ? null : selectedSectionID,
      );
      mainStore.toast.setInfoText(name ? `Moved "${name}"` : "Moved!");
    } catch {
      mainStore.toast.setErrorText("Failed to move!");
    }
  };

  return (
    <div className="mts-popup">
      <div className="mts-popup-heading">
        Select a section to move this record
      </div>

      <div className="mts-popup-sections">
        {filteredSections.map((section) => {
          const isActive = section.id === selectedSectionID;
          const activeIcon =
            section.id !== DASHBOARD_ID ? listBlueIcon : cardBlueLightIcon;
          const inactiveIcon =
            section.id !== DASHBOARD_ID ? listBlackIcon : cardBlackIcon;
          const icon = isActive ? activeIcon : inactiveIcon;

          return (
            <div
              key={section.id}
              className={classNames("mts-popup-section", {
                "mts-popup-section--active": isActive,
              })}
              data-testid="mts-popup-section"
              // @ts-expect-error TS(2345) FIXME: Argument of type 'string | number | null' is not a... Remove this comment to see the full error message
              onClick={() => setSelectedSectionID(section.id)}
            >
              <img src={icon} />
              <span>{section.title}</span>
              {isActive && <img src={checkIcon} />}
            </div>
          );
        })}
      </div>

      <div className="mts-popup-action-wrapper">
        <button
          className="mts-popup-action"
          data-testid="mts-popup-action-button"
          disabled={!selectedSectionID}
          onClick={handleMove}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

MoveToSectionPopup.defaultProps = {
  omitTopLevelSection: false,
};

export default observer(MoveToSectionPopup);
