import "./module-content-wrapper.scss";

import classnames from "classnames";
import React, { ReactNode } from "react";

type ModuleContentWrapperProps = {
  children: ReactNode;
  className?: string;
};

export const ModuleContentWrapper = (props: ModuleContentWrapperProps) => (
  <div
    className={classnames("module-content-wrapper", {
      // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
      [props.className]: Boolean(props.className),
    })}
  >
    {props.children}
  </div>
);
