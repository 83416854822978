import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";
import { API_URL } from "@/components/constants";
import { Filter } from "@/components/filters/types";
import { MainStore } from "@/stores/Main";
import { SummaryData } from "@/stores/types/key-risk-indicators-types";
import { Data } from "@/stores/types/record-types";

import { IndexParams, ModuleStore } from "../types/module-store-types";

export default class KeyRiskIndicatorsStore implements ModuleStore {
  mainStore: MainStore;

  data: Data | null = null;
  summaries: SummaryData = null;
  controller: AbortController | null = null;
  editMode = true;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      summaries: observable,
      editMode: observable,
      setData: action,
      setSummaries: action,
      setEditMode: action,
      cleanup: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/modules/key_risk_indicators
  async index({ sortParams, tableFilters = [] }: IndexParams) {
    if (this.controller) {
      this.controller.abort();
    }

    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        table_filters: tableFilters,
        ...(sortParams && {
          sort_field_name: sortParams.field_name,
          sort_direction: sortParams.direction,
        }),
      };

      const result = await legacyApi.get<Data>(
        `${API_URL}/modules/key_risk_indicators`,
        {
          headers: this.mainStore.getHeaders(),
          signal: controller.signal,
          params,
        },
      );

      this.mainStore.fields.setList(result.data.fields);
      this.setData(result.data);
    } catch (error) {
      window.console.log(
        `"key_risk_indicators#Index for Workspace" error ${error}`,
      );
    }
  }

  async create({ sectionTagID }: { sectionTagID: number | null | undefined }) {
    const params = {
      section_tag_id: sectionTagID,
      identifier: "key_risk_indicators",
      aggregation: { options: ["average"] },
    };
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/modules/key_risk_indicators`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      // @ts-expect-error axios error
      if (result.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(
          // @ts-expect-error TS(2339) FIXME:
          result.response,
        );
        return;
      }
    } catch (error) {
      window.console.log(`"key_risk_indicators#create" error ${error}`);
    }
  }

  // GET /api/react/modules/key_risk_indicators/summaries/:interval
  async fetchKRISummaries(interval: "month" | "quarter") {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/modules/key_risk_indicators/summaries/${interval}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME:
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME:
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      this.setSummaries(response.data);
      return response.data;
    } catch (error) {
      window.console.log(
        `"key_risk_indicators#fetchKRISummary" error ${error}`,
      );
    }
  }

  // GET /api/react/modules/key_risk_indicators/:parent_record_version_id/values
  async fetchKRIValues({
    sortFieldName = null,
    sortDirection = null,
    tableFilters = [],
    parentRecordVersionID,
  }: {
    sortFieldName?: string | null;
    sortDirection?: "ASC" | "DESC" | null;
    tableFilters?: Filter[];
    parentRecordVersionID: number;
  }) {
    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        table_filters: tableFilters,
        ...(sortFieldName && {
          sort_field_name: sortFieldName,
          sort_direction: sortDirection || "ASC",
        }),
      };

      const result = await legacyApi.get<Data>(
        `${API_URL}/modules/key_risk_indicators/${parentRecordVersionID}/values`,
        {
          headers: this.mainStore.getHeaders(),
          signal: controller.signal,
          params,
        },
      );

      // @ts-expect-error axios error
      if (result.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(
          // @ts-expect-error TS(2339) FIXME:
          result.response,
        );
        return;
      }

      this.mainStore.fields.setList(result.data.fields);
      this.mainStore.recordVersions.setList(result.data.record_versions, true);

      return result.data;
    } catch (error) {
      window.console.log(
        `"key_risk_indicators#fetchKRIValues for Workspace" error ${error}`,
      );
    }
  }

  // POST /api/react/modules/key_risk_indicators/:parent_record_version_id/values
  async createKRIValue(
    parentRecordVersionID: number,
    data?: { date: string; value: number },
    meta?: object,
  ) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/modules/key_risk_indicators/${parentRecordVersionID}/values`,
        headers: this.mainStore.getHeaders(),
        data: { identifier: "values", meta, data },
      });

      // @ts-expect-error TS(2339) FIXME:
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME:
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }
    } catch (error) {
      window.console.log(`"key_risk_indicators#createKRIValue" error ${error}`);
    }
  }

  // DELETE /api/react/modules/values/:id
  async deleteKRIValue(recordVersionID: number) {
    try {
      const result = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/modules/values/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error axios error
      if (result.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(
          // @ts-expect-error TS(2339) FIXME:
          result.response,
        );
        return;
      }
    } catch (error) {
      window.console.log(`"KeyRiskIndicators#deleteKRIValue" error ${error}`);
    }
  }

  // DELETE /api/react/modules/key_risk_indicators/:id
  async delete(recordVersionID: number) {
    try {
      const result = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/modules/key_risk_indicators/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error axios error
      if (result.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(
          // @ts-expect-error TS(2339) FIXME:
          result.response,
        );
        return;
      }
    } catch (error) {
      window.console.log(`"KeyRiskIndicators#delete" error ${error}`);
    }
  }

  // PUT /api/react/modules/key_risk_indicators/:id/update_section
  async updateSection(recordVersionID: number, sectionTagId: number) {
    const data = {
      section_tag_id: sectionTagId,
      identifier: "key_risk_indicators",
    };
    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/modules/key_risk_indicators/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"KeyRiskIndicators#update" error ${error}`);
    }
  }

  // Actions
  setData(value: Data | null) {
    this.data = value;
    this.mainStore.recordVersions.setList(this.data?.record_versions);
  }

  setSummaries(value: SummaryData) {
    this.summaries = value;
  }

  setEditMode(value?: boolean) {
    this.editMode = value ?? !this.editMode;
  }

  cleanup() {
    this.setData(null);
  }

  abort() {
    this.controller?.abort();
  }
}
