import classNames from "classnames";
import { isEqual } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import { Review } from "@/api/gen/models/Review";
import { Icon } from "@/components/Elements";
import SteppedApprovals from "@/components/table/shared/cell-type/users-select/SteppedApprovals";
import { useMainStore } from "@/contexts/Store";

import closeIcon2 from "../../../../../images/table-image/icon/close-icon2.svg";
import placeholderPlusViolet from "../../../../../images/table-image/icon/plus-placeholder-violet.svg";
import placeholderPlus from "../../../../../images/table-image/icon/plus-placeholder.svg";
import placeholderUserViolet from "../../../../../images/table-image/icon/user-placeholder-violet.svg";
import placeholderUser from "../../../../../images/table-image/icon/user-placeholder.svg";
import warningIcon from "../../../../../images/table-image/icon/warning-icon.svg";
import { userColors } from "../../../../constants";
import {
  formatDate,
  formatDateToTheSecond,
  stringToDate,
  stringToDateAndTime,
} from "../../../../helpers/DateFormatters";
import { validateEmail } from "../../../../helpers/EmailValidator";
import Switch from "../../Switch";
import UsersCircle from "../../UsersCircle";

const MAX_INITIAL_USERS = 5;
const AWAITING_APPROVAL_STATUS = "Awaiting Approval";
const AWAITING_VERIFICATION_STATUS = "Awaiting Verification";

type Props = {
  columnTitle: string;
  fieldName: string;
  hasErrors: boolean;
  recordVersionID: number;
  reviews: Review[];
  selectedIDs: number[];
  singleSelection: boolean;
  tableName: string;
  width: number | string;
  customTrigger?: React.ReactNode;
  errorMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleAfterSave?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleRemoveSponsor?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSelectSponsor?: (...args: any[]) => any;
  hasErrorClass?: string;
  isCreativeViewApproversList?: boolean;
  isUserEditable?: boolean;
  locked?: boolean;
  moduleWorkspaceID?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange?: (...args: any[]) => any;
  openOnHover?: boolean;
  pinned?: boolean;
  withContacts?: boolean;
  withUsers?: boolean;
  hasReadWriteAccess?: boolean;
};

function UsersSelect({
  columnTitle,
  customTrigger,
  errorMessage,
  fieldName,
  handleAfterSave,
  handleRemoveSponsor,
  handleSelectSponsor,
  hasErrorClass,
  hasErrors,
  isCreativeViewApproversList,
  isUserEditable,
  locked,
  moduleWorkspaceID,
  onDataChange,
  openOnHover,
  pinned,
  recordVersionID,
  reviews,
  selectedIDs,
  singleSelection,
  tableName,
  width,
  withContacts,
  withUsers,
  hasReadWriteAccess,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly =
    !hasReadWriteAccess &&
    (!hasModuleWriteAccess || isCurrentWorkspaceArchived);
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  );
  const { workspaceID } = mainStore.context;
  const approvalSteps = recordVersion?.approval_config?.approval_steps;
  const approvalStatus = recordVersion?.approval_status;
  const approvalStepsUsersIDs: number[] | undefined = approvalSteps
    ?.map((item) => item.users_ids)
    .flat();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupClick, setShowPopupClick] = useState(false);
  const [ids, setIDs] = useState<number[]>(selectedIDs);
  const [query, setQuery] = useState("");
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [mode, setMode] = useState("select");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState(withContacts ? "contact" : "user");
  const [emailError, setEmailError] = useState(null);

  // Variables
  const location = useLocation();
  const isApproversField = fieldName === "approvers";
  const moduleWorkspace = mainStore.moduleWorkspaces.list?.find(
    (mw) => mw.id === moduleWorkspaceID,
  );
  const disabled = !isUserEditable;
  const moduleConfiguration = moduleWorkspace?.module_configuration;
  const defaultApproversIDs = moduleConfiguration?.default_approvers.map(
    (defaultApprover) => defaultApprover.user_id,
  );
  const shouldRenderCreate =
    !locked && (showPopupClick || singleSelection) && mode === "create";
  const shouldRenderSelect =
    !locked && (showPopupClick || singleSelection) && mode === "select";
  const shouldRenderSteppedApprovals =
    !!approvalSteps &&
    !shouldRenderCreate &&
    !shouldRenderSelect &&
    isApproversField;
  const shouldRenderStatus =
    !shouldRenderCreate && !shouldRenderSelect && !shouldRenderSteppedApprovals;
  const allApprovals = "All approvals are required";
  const anyApproval = "Any 1 approval is required";
  const numberOfApprovers = (
    approvalStepsUsersIDs && isApproversField ? approvalStepsUsersIDs : ids
  )?.length;

  // Hooks
  useEffect(() => {
    setIDs(selectedIDs);
  }, [selectedIDs]);

  useEffect(() => {
    if (!workspaceID) {
      return;
    }
    mainStore.users.indexForModuleIfNoUsersPresent(workspaceID);
  }, [workspaceID]);

  const approversConfigurationTitle = useMemo(() => {
    switch (moduleConfiguration?.approvers_configuration) {
      case "all":
        return allApprovals;
      case "at_least_one":
        return anyApproval;
      default:
        return "";
    }
  }, [moduleConfiguration]);

  const pendingReviewerState = (hasApprovalUsers: boolean) => {
    const isApproved =
      reviews.filter((review) => review.review_type === "approval").length > 0;
    const isClosed =
      isApproved &&
      approversConfigurationTitle === anyApproval &&
      !approvalSteps;

    return isClosed || (approvalSteps && hasApprovalUsers)
      ? "Closed"
      : "Pending";
  };

  const refreshUsers = () => {
    const activeWorkspaceID = mainStore.users.user.active_workspace?.id;

    if (activeWorkspaceID) {
      mainStore.users.indexForModules({ workspaceID: activeWorkspaceID });
    }
  };

  const resetCreateForm = () => {
    setEmail("");
  };

  const expandList = () => {
    setIsExpanded(true);
  };

  const goToCreateMode = () => {
    setMode("create");
    resetCreateForm();
  };

  const usersList = () => {
    const contacts = mainStore.contacts.list;
    let users = mainStore.users.allUsers;

    if (!withUsers) {
      users = [];
    }

    let listApprovers;
    if (!defaultApproversIDs) {
      listApprovers = users;
    } else {
      const defaultApprovers = users.filter((user) =>
        defaultApproversIDs.includes(user.id),
      );
      if (defaultApprovers.length === 0 || !isApproversField) {
        listApprovers = users;
      } else if (isApproversField) {
        listApprovers = defaultApprovers;
      } else {
        listApprovers = defaultApprovers;
      }
    }

    if (withContacts) {
      return contacts.concat(listApprovers);
    }
    return listApprovers;
  };

  const usersListForCell = () => {
    const contacts = mainStore.contacts.list;
    const users = mainStore.users.allUsers;
    if (withContacts) {
      return contacts.concat(users);
    }

    return users;
  };

  const createUser = async () => {
    if (!validateEmail(email)) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '"Email address is invalid"' is n... Remove this comment to see the full error message
      setEmailError("Email address is invalid");
      return;
    }

    setEmailError(null);

    const activeWorkspaceID = mainStore.users.user.active_workspace?.id;

    const user =
      userType === "user"
        ? await mainStore.users.createWithParams(
            activeWorkspaceID,
            {
              email,
              full_name: name,
              // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
              is_contact: userType === "contact",
            },
            true,
            false,
          )
        : await mainStore.contacts.create({
            email,
            full_name: name,
          });

    if (user) {
      refreshUsers();
      resetCreateForm();
      setMode("select");
      setQuery("");
      addItemById(user);
    }
    onClose();
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
  const inviteUser = async (user) => {
    const { company } = mainStore.companies;
    await mainStore.users.updateWithParams(company.id, user.id, {
      status: AWAITING_VERIFICATION_STATUS,
    });
    refreshUsers();
  };

  const filteredUsers = usersList()
    .filter((u) => !u.is_inactive)
    .filter(
      (searchItems) =>
        (withContacts || !searchItems.is_contact) &&
        searchItems.full_name?.toLowerCase()?.includes(query.toLowerCase()),
    )
    .sort((a, b) => a.full_name?.localeCompare(b.full_name));

  const listUsersElements = () => {
    let sortedUsers = [];

    if (isApproversField && approvalStepsUsersIDs) {
      sortedUsers = approvalStepsUsersIDs.map((id) =>
        usersListForCell().find((user) => user.id === id),
      );
    } else {
      sortedUsers = ids?.map((id) =>
        usersListForCell().find((user) => user.id === id),
      );
    }

    if (!sortedUsers?.length) {
      return;
    }

    return (
      <div className="users-circle-block">
        {sortedUsers
          .slice(0, 3)
          .filter((u) => !!u)
          .map((user) => {
            let className = "";
            const userState = mainStore.users.users.find(
              (u) => u.id === user?.id,
            );
            const isAwaitingVerification =
              userState?.status === AWAITING_VERIFICATION_STATUS ||
              user.awaiting_verification;

            reviews.forEach((review) => {
              if (
                review.user_id === user.id &&
                review.review_type === "approval" &&
                isApproversField
              ) {
                className += "approved";
              } else if (
                review.user_id === user.id &&
                review.review_type === "denial" &&
                isApproversField
              ) {
                className += "denied";
              }
            });

            return (
              <UsersCircle
                key={user.id}
                user={user}
                additionalStyles={{
                  background: userColors[user.icon_color_index],
                }}
                showFullName={false}
                additionalClassNames={className}
                isAwaitingVerification={isAwaitingVerification}
              />
            );
          })}
      </div>
    );
  };

  const approvalStatuses = (
    userIDs: number[],
    hasApprovalUsers: boolean = false,
  ): React.ReactNode[] | null =>
    userIDs?.map((userID) => {
      const user = usersList().find((elem) => elem.id === userID);
      const approval = reviews.find(
        (review) =>
          review.user_id === userID && review.review_type === "approval",
      );
      const denial = reviews.find(
        (review) =>
          review.user_id === userID && review.review_type === "denial",
      );

      if (!user) {
        return null;
      }

      return (
        <div
          className={classNames("users-circle-block", {
            "awaiting-verification": user.awaiting_verification,
            inactive: user.is_inactive,
          })}
          key={userID}
        >
          <div key={user.id} className="approval-wrap">
            <div className="approval-users">
              <div
                key={user.id}
                className="users-circle"
                style={{ background: userColors[user.icon_color_index] }}
              >
                <span>{user.initials}</span>
              </div>
              <div className="approval-name" title={user.full_name}>
                {user.full_name}
                {approval && isApproversField && (
                  <div className="approval-date">
                    {isCreativeViewApproversList
                      ? formatDateToTheSecond(
                          stringToDateAndTime(approval?.approved_at),
                        )
                      : formatDate(stringToDate(approval?.approved_at))}
                  </div>
                )}
              </div>
            </div>
            {isApproversField && (
              <div className="approval-block">
                {approval && <div className="approved">Approved</div>}
                {denial && <div className="denied">Denied</div>}
                {!approval && !denial && (
                  <div className="pending">
                    {pendingReviewerState(hasApprovalUsers)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      );
    });

  // @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
  const addItemById = (user) => {
    if (singleSelection) {
      setIDs([user.id]);
      setShowPopup(false);
    } else if (handleSelectSponsor) {
      handleSelectSponsor(user);
    } else {
      setIDs([...ids, user.id]);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  const removeItemById = (id) => {
    if (handleRemoveSponsor) {
      handleRemoveSponsor(id);
    } else {
      const newSelectedIDs = ids.filter((selectedID) => selectedID !== id);
      setIDs(newSelectedIDs);
    }
  };

  const popupClick = () => {
    isUserEditable && setShowPopupClick(true);
  };

  const onOpen = () => {
    setShowPopup(true);
    setQuery("");
    setIsSelectModalOpen(true);
    setIsExpanded(false);

    if (
      ((!singleSelection && !approvalStatuses(ids)?.length) ||
        !!handleSelectSponsor) &&
      (!approvalSteps || !isApproversField)
    ) {
      popupClick();
    }
  };

  const onClose = async () => {
    setShowPopup(false);
    setShowPopupClick(false);
    setIsSelectModalOpen(false);
    setMode("select");

    if (!isEqual(ids, selectedIDs)) {
      if (typeof onDataChange === "function") {
        onDataChange(fieldName, ids);
      } else {
        await mainStore.recordVersions.update({
          recordVersionID,
          fieldName,
          value: mainStore.avroSchemas.serializeValue(fieldName, ids),
        });
      }

      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      handleAfterSave();
    }
  };

  const addRemoveSteppedApprovals = async () => {
    await onClose();

    if (approvalSteps) {
      await mainStore.manageApprovalsSteps.removeSteppedApprovals(
        recordVersionID,
      );
    } else {
      await mainStore.manageApprovalsSteps.addSteppedApprovals(recordVersionID);
    }
  };

  const triggerStyle = { width };
  const triggerClasses = classNames("users-select", {
    active: isSelectModalOpen,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [hasErrorClass]: hasErrors,
    illumination: isSelectModalOpen,
    "users-multiple-select": !singleSelection,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "read-only": disabled && !locked && isCurrentWorkspaceActive,
    "table-cell--disabled": isReadOnly,
    "pointer-events-none":
      (locked && reviews.length === 0) || disabled || isReadOnly,
    pinned,
  });

  const usersToSelect = singleSelection
    ? filteredUsers
    : filteredUsers.filter((user) => !ids?.includes(user.id));

  const usersCircleStyle = classNames("users-circle users-count-circle", {
    "active-approves": usersList().length === reviews.length,
  });

  const renderSelect = () => {
    if (mainStore.context.isSupport && tableName === "Customer Support") {
      return null;
    }

    return (
      <div className="users-dropdown" data-testid="users-select-dropdown">
        {!disabled &&
          isCurrentWorkspaceActive &&
          (!approvalSteps || !isApproversField || singleSelection) && (
            <div className="users-dropdown-search users-dropdown-search__update">
              <input
                type="text"
                data-testid="users-select-search-input"
                disabled={disabled || isReadOnly || false}
                placeholder={`Search / Add New ${columnTitle}`}
                onChange={(event) => {
                  setName(event.target.value);
                  setQuery(event.target.value);
                }}
              />
              <Icon name="search" className="search-icon" />
            </div>
          )}
        {!singleSelection &&
          ids.length > 0 &&
          (!approvalSteps || !isApproversField) && (
            <div className="users-dropdown-block">
              <ul>
                {ids?.map((id) => {
                  const userToAdd = usersList().find((user) => user.id === id);

                  return (
                    <li
                      key={userToAdd.id}
                      id={userToAdd.id}
                      className={classNames({
                        "awaiting-verification":
                          userToAdd.awaiting_verification,
                      })}
                    >
                      <span className="user-full-name">
                        {userToAdd.full_name}
                      </span>
                      <span
                        onClick={() => {
                          removeItemById(userToAdd.id);
                        }}
                      >
                        <img src={closeIcon2} alt="close-icon" />
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        {!singleSelection &&
          ids.length === 0 &&
          isApproversField &&
          (location.pathname.startsWith(
            `/workspaces/${workspaceID}/modules/policy`,
          ) ||
            location.pathname.startsWith(
              `/workspaces/${workspaceID}/modules/marketing`,
            )) && (
            <ul className="stepped-approvals">
              <li
                className={classNames("stepped-approvals__element", {
                  "stepped-approvals__active": !!approvalSteps,
                })}
                onClick={addRemoveSteppedApprovals}
                data-testid="stepped-approvals"
              >
                Stepped Approvals
                {!!approvalSteps && (
                  <Icon name="check" className="check" fill="#27AAE1" />
                )}
              </li>
              {!approvalSteps && <hr className="select-divider" />}
            </ul>
          )}
        {!disabled &&
          isCurrentWorkspaceActive &&
          (!approvalSteps || !isApproversField || singleSelection) && (
            <div
              className="users-dropdown-list"
              data-testid="users-select-dropdown-list"
            >
              <ul>
                <div>
                  {filteredUsers.length > 0 ? (
                    <h4>Select {columnTitle}</h4>
                  ) : (
                    <h4 className="no-result">
                      No results found
                      <button
                        className="button"
                        data-testid="users-select-add-new-button"
                        onClick={goToCreateMode}
                      >
                        Add New {columnTitle}
                      </button>
                    </h4>
                  )}
                  {usersToSelect
                    .slice(0, isExpanded ? Infinity : MAX_INITIAL_USERS)
                    .map((user) => {
                      const userState = mainStore.users.users.find(
                        (u) => u.id === user.id,
                      );

                      const isAwaitingVerification =
                        userState?.status === AWAITING_VERIFICATION_STATUS ||
                        user.awaiting_verification;
                      const isAwaitingApproval =
                        userState?.status === AWAITING_APPROVAL_STATUS ||
                        user.status === AWAITING_APPROVAL_STATUS;

                      const optionClasses = classNames({
                        active: singleSelection && ids?.includes(user.id),
                        "active-search": query.length > 0,
                        "awaiting-verification": isAwaitingVerification,
                      });
                      const style = {
                        background: userColors[user.icon_color_index],
                        borderColor: user.is_contact
                          ? userColors[user.icon_color_index]
                          : "inherit",
                      };
                      const usersClass = classNames("users-circle", {
                        "users-circle-contact": user.is_contact,
                      });

                      return (
                        <li
                          key={user.id}
                          id={user.id}
                          className={optionClasses}
                          data-testid="users-select-dropdown-li"
                          onClick={() => {
                            addItemById(user);
                          }}
                        >
                          <span className={usersClass} style={style}>
                            {user.initials}
                          </span>
                          <span className="user-full-name">
                            {user.full_name}
                          </span>
                          {isAwaitingVerification && (
                            <span className="awaiting-verification-status">
                              {userState?.status || user.status}
                            </span>
                          )}
                          {isAwaitingApproval && !user.is_contact && (
                            <button
                              className="invite-button"
                              onClick={(event) => {
                                event.stopPropagation();
                                inviteUser(user);
                              }}
                            >
                              Invite
                            </button>
                          )}
                        </li>
                      );
                    })}
                  {!isExpanded && usersToSelect.length > MAX_INITIAL_USERS && (
                    <button className="button" onClick={expandList}>
                      {usersToSelect.length - MAX_INITIAL_USERS}
                      {" additional available "}
                      {columnTitle}
                      {usersToSelect.length - MAX_INITIAL_USERS > 1 &&
                      columnTitle?.slice(-1) !== "s"
                        ? "s"
                        : ""}
                    </button>
                  )}
                </div>
              </ul>
            </div>
          )}
      </div>
    );
  };

  const renderStatus = () => (
    <div
      className="users-dropdown users-dropdown-approval"
      data-testid="approval-statuses"
    >
      {isCreativeViewApproversList ? (
        <h4>Approval Status</h4>
      ) : (
        <h4>{columnTitle}</h4>
      )}
      {isApproversField && (
        <h4 className="configuration-state">{approversConfigurationTitle}</h4>
      )}
      {approvalStatuses(ids)}
      {errorMessage && (
        <div className="error">
          <ul className="errors">
            <li>
              <img src={warningIcon} alt="warning-icon" />
              {errorMessage}
            </li>
          </ul>
        </div>
      )}
    </div>
  );

  const renderCreate = () => {
    const inputClasses = classNames("users-create-input", {
      "users-create-input-error": emailError,
    });
    return (
      <div
        className="users-dropdown users-dropdown-wrap"
        data-testid="create-users"
      >
        <div className="users-create-section">
          Add {columnTitle}
          <input
            className="users-create-input"
            type="text"
            value={name}
            placeholder="Name"
            onChange={(event) => setName(event.target.value)}
          />
          <input
            className={inputClasses}
            data-testid="users-select-email-input"
            type="email"
            value={email}
            placeholder="Email"
            onChange={(event) => setEmail(event.target.value)}
          />
          <p className="users-create-input-error-message">{emailError}</p>
        </div>

        {withContacts && (
          <div className="users-create-section">
            {columnTitle} Type
            <div
              className="users-create-switch"
              data-testid="user-contact-switch-container"
            >
              {/* @ts-expect-error TS(2322) FIXME: Type 'false | "active"' is not assignable to type ... Remove this comment to see the full error message */}
              <span className={userType === "contact" && "active"}>
                Contact
              </span>
              <Switch
                active
                indeterminate={false}
                checked={userType === "user"}
                onChange={(event) =>
                  setUserType(event.target.checked ? "user" : "contact")
                }
              />
              {/* @ts-expect-error TS(2322) FIXME: Type 'false | "active"' is not assignable to type ... Remove this comment to see the full error message */}
              <span className={userType === "user" && "active"}>User</span>
            </div>
            <ul className="users-create-info">
              <li>
                &quot;Contacts&quot; are references to people who are not Themis
                users.
              </li>
              <li>
                &quot;Users&quot; are people who are invited to Themis and have
                the ability to interact in the platform.
              </li>
            </ul>
          </div>
        )}

        <button
          className="users-create-confirm"
          data-testid="users-select-create-confirm-button"
          type="button"
          disabled={userType === "user" ? !email : !email && !name}
          onClick={createUser}
        >
          Confirm
        </button>
      </div>
    );
  };

  const placeholderClasses = classNames(
    "users-placeholder nodata users-circle-placeholder",
    {
      "placeholder-disabled": disabled || isReadOnly,
    },
  );

  const usersPlaceholder = hasErrors ? (
    <p className="options-placeholder nodata" />
  ) : (
    <p className={placeholderClasses}>
      <span>
        <img
          src={disabled || isReadOnly ? placeholderUserViolet : placeholderUser}
          alt="placeholder-user"
        />
      </span>
      {disabled || isReadOnly ? "N/A" : "- Add user -"}
    </p>
  );

  const usersMultipleSelectPlaceholder = (
    <p className={placeholderClasses}>
      {[...Array(3)].map((_, id) => (
        <span key={id}>
          <img
            src={
              disabled || isReadOnly ? placeholderUserViolet : placeholderUser
            }
            alt="placeholder-user"
          />
        </span>
      ))}
      <span>
        <img
          src={disabled || isReadOnly ? placeholderPlusViolet : placeholderPlus}
          alt="placeholder-user"
        />
      </span>
    </p>
  );

  return (
    <Popup
      position="bottom right"
      // @ts-expect-error TS(2322) FIXME: Type 'string | number | true | Iterable<ReactNode>... Remove this comment to see the full error message
      trigger={
        customTrigger ||
        (singleSelection ? (
          <li
            className={triggerClasses}
            data-testid="users-select-single-select"
            style={triggerStyle}
            onClick={popupClick}
          >
            <div className="cell-content">
              {mainStore.users.allUsers.slice(0, 1).map((elem) => (
                <div
                  className="users"
                  data-testid="users-select-div"
                  key={elem.id}
                >
                  {ids.length === 0 && usersPlaceholder}
                  {hasErrors ? (
                    <div>{errorMessage}</div>
                  ) : (
                    usersList()
                      .filter((user) => selectedIDs?.includes(user.id))
                      .slice(0, 1)
                      .map((user) => {
                        const style = {
                          background: userColors[user.icon_color_index],
                        };
                        const userState = mainStore.users.users.find(
                          (u) => u.id === user.id,
                        );
                        const isAwaitingVerification =
                          userState?.status === AWAITING_VERIFICATION_STATUS ||
                          user.awaiting_verification;

                        return (
                          <UsersCircle
                            key={user.id}
                            user={user}
                            additionalStyles={style}
                            isAwaitingVerification={isAwaitingVerification}
                          />
                        );
                      })
                  )}
                </div>
              ))}
            </div>
          </li>
        ) : (
          <li
            className={triggerClasses}
            data-testid="users-select-multi-select"
            style={triggerStyle}
            onClick={popupClick}
          >
            <div
              className="cell-content"
              data-testid={`users-select-${columnTitle?.toLowerCase()}`}
            >
              {numberOfApprovers === 0
                ? usersMultipleSelectPlaceholder
                : listUsersElements()}

              {numberOfApprovers > 3 && (
                <div className={usersCircleStyle}>
                  <span>{numberOfApprovers - 3}</span>
                </div>
              )}
            </div>
          </li>
        ))
      }
      open={showPopup}
      on={
        singleSelection || !openOnHover || !!handleSelectSponsor
          ? "click"
          : "hover"
      }
      onOpen={onOpen}
      onClose={onClose}
      keepTooltipInside
    >
      <div className="render-users-list">
        {shouldRenderSelect && renderSelect()}
        {shouldRenderCreate && renderCreate()}
        {shouldRenderStatus && renderStatus()}
        {shouldRenderSteppedApprovals && (
          <SteppedApprovals
            approvalStatuses={approvalStatuses}
            approvalSteps={approvalSteps}
            reviews={reviews}
            approvalStatus={approvalStatus || undefined}
          />
        )}
      </div>
    </Popup>
  );
}

UsersSelect.defaultProps = {
  handleAfterSave: () => {},
  hasErrorClass: "has-errors",
  hasErrors: false,
  openOnHover: true,
  reviews: [],
  withContacts: false,
  withUsers: true,
};

export default observer(UsersSelect);
