import { Select, useToast } from "@themis/ui";
import React from "react";

import { useMonitoringGroupChildResultsUpdate } from "@/api/queries/websiteMonitoring";

import { ENTRY_STATUS_TYPES } from "../config/monitoringGroupResultStatus";

export function SelectMarkAllStatus({
  monitoringGroupAssetId,
  monitoringGroupAssetChildId,
  resultCreatedAt,
  complianceHashGroupUid,
}: {
  monitoringGroupAssetId: number;
  monitoringGroupAssetChildId: number;
  resultCreatedAt: string;
  complianceHashGroupUid?: string;
}) {
  const toast = useToast();

  const { mutateAsync: updateResult } = useMonitoringGroupChildResultsUpdate({
    monitoringGroupAssetChildId: Number(monitoringGroupAssetChildId),
    monitoringGroupAssetId,
    onSuccess: () => {
      toast({ content: "Result saved successfully!", variant: "success" });
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not update result.",
        variant: "error",
      });
    },
  });

  async function handleSubmitForm(value: string) {
    const requestBody = {
      data: {
        monitoring_group_asset_child_result_created_at: resultCreatedAt,
        status: value,
        compliance_hash_group_uid: complianceHashGroupUid,
      },
    };
    await updateResult(requestBody);
  }

  return (
    <Select
      items={ENTRY_STATUS_TYPES}
      selected={""}
      onSelect={(value) => {
        handleSubmitForm(value);
      }}
    />
  );
}
