import { Line } from "rc-progress";
import React from "react";

type Props = {
  progress: number;
};

function CompletionPercentage({ progress }: Props) {
  let strokeColor;

  const progressPercentage = Math.min(Math.max(Math.round(progress), 0), 100);

  if (progressPercentage < 50) {
    strokeColor = "#E63956";
  } else if (progressPercentage < 100) {
    strokeColor = "#FF9F4D";
  } else {
    strokeColor = "#6D71F9";
  }

  return (
    <div className="line-progress-bar">
      <span>{progressPercentage}%</span>
      <Line
        percent={progressPercentage}
        strokeWidth={3}
        strokeLinecap="round"
        strokeColor={strokeColor}
        trailColor="#FFFFFF"
      />
    </div>
  );
}

export default CompletionPercentage;
