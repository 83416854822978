import classNames from "classnames";
import { get, keys } from "lodash";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import deleteIcon from "../../../../images/table-image/icon/close-icon2.svg";
import deleteIconActive from "../../../../images/table-image/icon/close-icon3.svg";
import { Icon } from "../../../Elements";
import { getWorkspaceLogo } from "../../../helpers/getWorkspaceLogo";
import ViewRolesDetailPopup from "./ViewRolesDetailPopup";

// @ts-expect-error TS(7006) FIXME: Parameter 'modules' implicitly has an 'any' type.
export const getSelectedModules = (modules, role) => {
  const moduleTargets = get(role, "permission_set.data.module.targets");
  const moduleIdentifiers = keys(moduleTargets).filter((key) =>
    get(moduleTargets, `${key}.state.edit`),
  );
  // @ts-expect-error TS(7006) FIXME: Parameter 'module' implicitly has an 'any' type.
  return modules.filter((module) => moduleIdentifiers.includes(module.id));
};

type ViewRolesListRowProps = {
  isDeleteDisabled?: boolean;
  isDisabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modules?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEdit?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  role?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workspaces?: any[];
};

const ViewRolesListRow = ({
  workspaces,
  modules,
  role,
  isDisabled,
  isDeleteDisabled,
  onEdit,
  onDelete,
}: ViewRolesListRowProps) => {
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const { permission_type: permissionType, name } = role.permission_set;

  const handleDelete = () => {
    setShowDeletePopup(false);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onDelete();
  };

  const selectedModules = getSelectedModules(modules, role);

  const deletePopup = !isDeleteDisabled && (
    <Popup
      open={showDeletePopup}
      trigger={
        <button className={classNames({ active: showDeletePopup })}>
          <img src={showDeletePopup ? deleteIconActive : deleteIcon} />
        </button>
      }
      position="bottom right"
      onOpen={() => setShowDeletePopup(true)}
      onClose={() => setShowDeletePopup(false)}
    >
      <div className="user-role-delete-popup">
        <div className="user-role-delete-popup-caption">Remove Role</div>

        <div className="user-role-delete-popup-description">
          Are you sure you want to remove this role? Confirming will remove user
          from accessing this workspace and related modules.
        </div>

        <div className="user-role-delete-popup-actions">
          <button onClick={handleDelete}>Yes</button>
          <button onClick={() => setShowDeletePopup(false)}>No</button>
        </div>
      </div>
    </Popup>
  );

  if (permissionType === "company") {
    return (
      <div className="user-roles-list-item">
        <div className="user-roles-list-item-type">
          {role.permission_set.name}
        </div>

        <ViewRolesDetailPopup
          caption="Workspaces Available"
          trigger={
            <div className="user-roles-list-item-all-workspaces">
              All Workspaces Available
            </div>
          }
          options={workspaces}
        />

        <ViewRolesDetailPopup
          caption="Modules Available"
          trigger={
            <div className="user-roles-list-item-modules">
              All Modules Available
            </div>
          }
          options={modules}
        />
        <div className="user-roles-list-item-actions">
          {!isDisabled && deletePopup}
        </div>
      </div>
    );
  }

  return (
    <div key={role.id} className="user-roles-list-item">
      <div className="user-roles-list-item-type">
        {role.permission_set.name}
      </div>

      <div className="user-roles-list-item-workspace">
        {role.workspace && (
          <>
            <img src={getWorkspaceLogo(role.workspace).logo} />
            <div className="user-roles-list-item-workspace-name">
              {role.workspace.name}
            </div>
            <div
              className={classNames("user-roles-list-item-workspace-tag", {
                internal: role.workspace.is_internal,
                collaborative: !role.workspace.is_internal,
              })}
            >
              {role.workspace.is_internal ? "Internal" : "Collaborative"}
            </div>
          </>
        )}
      </div>
      <ViewRolesDetailPopup
        caption="Modules Available"
        trigger={
          <div className="user-roles-list-item-modules">
            {name === "Workspace Admin" ||
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            selectedModules.length === modules.length
              ? "All Modules Available"
              : // @ts-expect-error TS(7006) FIXME: Parameter 'module' implicitly has an 'any' type.
                selectedModules.map((module) => module.name).join(", ")}
          </div>
        }
        options={name === "Workspace Admin" ? modules : selectedModules}
      />
      {!isDisabled && (
        <div className="user-roles-list-item-actions">
          <button onClick={onEdit}>
            <Icon name="edit" />
          </button>
          {deletePopup}
        </div>
      )}
    </div>
  );
};

ViewRolesListRow.defaultProps = {
  role: {},
  workspaces: [],
  modules: [],
  isDisabled: false,
  isDeleteDisabled: false,
  onEdit: () => {},
  onDelete: () => {},
};

export default ViewRolesListRow;
