import { observer } from "mobx-react";
import React from "react";

import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import FileUploader from "../cell-type/FileUploader";

type Props = {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleAddFile?: (...args: any[]) => any;
  icon?: React.ReactNode;
  isMultiple?: boolean;
  label?: string;
  moduleIdentifier?: ModuleIdentifier;
  replacementId?: number;
  triggerAction?: React.ReactNode;
  hasReadWriteAccess?: boolean;
};

function ViewFileUploader({
  recordVersion,
  fieldName,
  isMultiple,
  icon,
  label,
  replacementId,
  triggerAction,
  handleAddFile,
  moduleIdentifier,
  hasReadWriteAccess,
}: Props) {
  // Variables
  return (
    <FileUploader
      fieldName={fieldName}
      recordVersion={recordVersion}
      icon={icon}
      label={label}
      isMultiple={isMultiple}
      replacedAttachmentId={replacementId}
      triggerAction={triggerAction}
      handleAddFile={handleAddFile}
      moduleIdentifier={moduleIdentifier}
      hasReadWriteAccess={hasReadWriteAccess}
    />
  );
}

ViewFileUploader.defaultProps = {
  replacementId: null,
  hasReadWriteAccess: false,
};

export default observer(ViewFileUploader);
