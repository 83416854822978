import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import ApprovalNew from "../cell-type/ApprovalNew";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  recordVersionID: number;
};

function ViewApprovalNew({ recordVersion, recordVersionID }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const cellError = mainStore.recordVersions.errorsForField(
    recordVersionID,
    "validator",
  );
  const hasErrors = Boolean(cellError);
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const errorMessage = hasErrors ? cellError.description : undefined;

  // Rendering
  return (
    <div className="column-approval">
      <ApprovalNew
        recordVersionID={recordVersionID}
        reviews={recordVersion.reviews}
        enableApprove
        viewIssueDetail
        hasErrors={hasErrors}
        errorMessage={errorMessage}
        width="100%"
      />
    </div>
  );
}

export default observer(ViewApprovalNew);
