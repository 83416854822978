import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { getBasePathFromModuleIdentifier } from "@/components/helpers/getPathFromModuleIdentifier";
import { useMainStore } from "@/contexts/Store";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import {
  useLinkToCreate,
  useLinkToEdit,
} from "../../../../hooks/useChecklistNavigatePath";
import { Icon } from "../../../Elements";
import CompleteChecklistSidebar from "../../change-management/Checklist/CompleteChecklistSidebar";
import SavedChecklistsSidebar from "../../change-management/Checklist/SavedChecklistsSidebar";
import ConfirmationDialog from "../ConfirmationDialog";

type Props = {
  recordID: number;
  width?: number | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  disabled?: boolean;
  fieldName?: string;
  fromDetailView?: boolean;
  locked?: boolean;
  moduleIdentifier?: ModuleIdentifier;
  pinned?: boolean;
  hasErrors?: boolean;
  hasErrorClass?: string;
};

function Checklist({
  recordID,
  defaultValue,
  width,
  disabled,
  locked,
  pinned,
  fieldName,
  moduleIdentifier,
  fromDetailView,
  hasErrors = false,
  hasErrorClass,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [value, setValue] = useState(defaultValue);
  const [isPopUpOpened, setIsPopUpOpened] = useState(null);
  const [useSavedChecklistSidebarOpened, setUseSavedChecklistSidebarOpened] =
    useState(false);
  const [completeChecklistSidebarOpened, setCompleteChecklistSidebarOpened] =
    useState(false);
  const [showInfo, setShowInfo] = useState(true);
  const [showReplacePopup, setShowReplacePopup] = useState(false);

  // Hooks
  const history = useHistory();

  // Variables
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;
  const { moduleWorkspaceID, workspaceID } = mainStore.context;
  const { themisModuleIdentifier } = mainStore.context;
  const style = { width };

  const answeredQuestionsLength = value?.checklist_questions.filter(
    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    (item) => item?.answer,
  ).length;
  const allQuestionsLength = value?.checklist_questions.length;

  // useMemo for find path
  const isCustomField = useMemo(() => {
    if (
      ["new_product_approval", "vendor_due_diligence"].includes(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        moduleIdentifier,
      )
    ) {
      if (fieldName === "checklist") {
        return false;
      }
      return true;
    }
    return true;
  }, [moduleIdentifier, fieldName]);

  // @ts-expect-error TS(2345) FIXME: Argument of type '"audits" | "new_product_approval... Remove this comment to see the full error message
  const modulePath = getBasePathFromModuleIdentifier(themisModuleIdentifier);

  const linkToEdit = useLinkToEdit(
    workspaceID!,
    modulePath,
    value?.id,
    isCustomField,
  );
  const linkToCreate = useLinkToCreate(
    workspaceID!,
    modulePath,
    recordID,
    isCustomField,
    fieldName!,
  );

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleUseSavedChecklist(e) {
    e.preventDefault();

    setUseSavedChecklistSidebarOpened(true);
    setIsPopUpOpened(null);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleCompleteChecklist(e) {
    e.preventDefault();

    setCompleteChecklistSidebarOpened((prevState) => !prevState);
    setIsPopUpOpened(null);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleReplaceChecklist(e) {
    e.preventDefault();

    if (!fromDetailView) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '"replace-confirmation"' is not a... Remove this comment to see the full error message
      setIsPopUpOpened("replace-confirmation");
    } else {
      setShowReplacePopup(false);
      setShowInfo(false);
    }
  }

  function handleReject() {
    if (!fromDetailView) {
      setIsPopUpOpened(null);
    } else {
      setShowReplacePopup(false);
      setShowInfo(false);
    }
  }

  async function handleReplaceChecklistConfirm() {
    if (isCustomField) {
      await mainStore.checklists.delete(value.id);
    } else {
      await mainStore.changeManagements.deleteChecklist(
        moduleWorkspaceID,
        value.id,
      );
    }
    setValue(null);
    setIsPopUpOpened(null);
    setUseSavedChecklistSidebarOpened(true);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function handleSwitchClick(id, e) {
    const { checked } = e.target;
    const answer = checked ? "Yes" : "No";
    const data = { answer };

    handleUpdateChecklistQuestion(id, data);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function handleNAClick(id, e) {
    const { checked } = e.target;
    const answer = checked ? "N/A" : null;
    const data = { answer };

    handleUpdateChecklistQuestion(id, data);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'newValue' implicitly has an 'any' type.
  function handleOnBlur(newValue, id) {
    const data = { comment: newValue };

    handleUpdateChecklistQuestion(id, data);
  }

  function editChecklistFromDetailView() {
    history.push(linkToEdit);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function replaceChecklistFromDetailView(e) {
    e.preventDefault();

    setShowInfo(false);
    setShowReplacePopup(true);
  }

  const renderReplaceConfirmation = (
    <div className="table-dropdown success-dropdown">
      <ConfirmationDialog
        containerClass="change-management-confirm"
        heading="Replacing Existing Checklist"
        content="Replacing an existing checklist will delete existing checklist data.  Do you wish to do continue?"
        handleConfirm={handleReplaceChecklistConfirm}
        handleReject={handleReject}
      />
    </div>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  async function handleUpdateChecklistQuestion(id, data) {
    let updatedChecklistQuestion: unknown;
    if (isCustomField) {
      updatedChecklistQuestion = await mainStore.checklistQuestions.update(
        id,
        data,
      );
    } else {
      updatedChecklistQuestion =
        await mainStore.changeManagements.updateChecklistQuestion(
          moduleWorkspaceID,
          id,
          data,
        );
    }

    if (updatedChecklistQuestion) {
      setValue({
        ...value,
        // @ts-expect-error TS(7006) FIXME: Parameter 'cq' implicitly has an 'any' type.
        checklist_questions: value.checklist_questions.map((cq) =>
          cq.id === id ? updatedChecklistQuestion : cq,
        ),
      });
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  async function handleAddSavedChecklist(id) {
    let checklist;
    if (isCustomField) {
      checklist = await mainStore.checklists.edit(id, recordID);
    } else {
      checklist = await mainStore.changeManagements.assignChecklist(
        moduleWorkspaceID,
        id,
        recordID,
      );
    }
    setValue(checklist);
    setUseSavedChecklistSidebarOpened(false);

    if (fromDetailView) {
      setShowInfo(true);
      setShowReplacePopup(false);
    }
  }

  function onClose() {
    setShowInfo(true);
    setShowReplacePopup(false);
  }

  const noValueLabel = locked ? "N/A" : "– Add Checklist –";

  const renderTrigger = (
    <li
      className={classNames("change-management-checklist-cell", {
        disabled,
        nodata: !value,
        "locked-cell": locked && isCurrentWorkspaceActive,
        "pointer-events-none": disabled,
        "table-cell--disabled": isReadOnly,
        pinned,
        "detail-view-checklist": fromDetailView,
        // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [hasErrorClass]: hasErrors,
      })}
      style={style}
      data-testid="view-checklist-cell"
    >
      <div
        className="cell-content tw-justify-between"
        data-testid="view-checklist-cell-content"
      >
        {value
          ? `${answeredQuestionsLength}/${allQuestionsLength} Completed`
          : noValueLabel}
        {(disabled || locked) && value && (
          <div className={classNames("table-row-actions")}>
            <div data-testid="eye">
              <Icon
                name={isReadOnly || locked ? "eye" : "edit"}
                color="generalDark"
                size="de"
                className="tw-pointer-events-auto"
                onClick={() =>
                  setCompleteChecklistSidebarOpened((prevState) => !prevState)
                }
              />
            </div>
          </div>
        )}
        {fromDetailView && !(disabled || locked) && value && (
          <div
            className={classNames("table-row-actions", {
              "hide-image": locked,
            })}
          >
            <Popup
              trigger={() => (
                <div data-testid="eye" onClick={handleCompleteChecklist}>
                  <Icon name="eye" color="generalDark" size="de" />
                </div>
              )}
              on="hover"
              position="top center"
            >
              <div
                className="table-dropdown policy-document-tooltip"
                data-testid="eye-hover"
              >
                Review / Complete Checklist
              </div>
            </Popup>

            <Popup
              trigger={() => (
                <div data-testid="edit" onClick={editChecklistFromDetailView}>
                  <Icon name="edit" color="generalDark" size="de" />
                </div>
              )}
              on="hover"
              position="top center"
            >
              <div
                className="table-dropdown policy-document-tooltip"
                data-testid="edit-hover"
              >
                Edit Checklist
              </div>
            </Popup>

            <Popup
              trigger={() => (
                <div
                  data-testid="sync"
                  onClick={replaceChecklistFromDetailView}
                >
                  <Icon name="sync" color="generalDark" size="de" />
                </div>
              )}
              on={showInfo ? "hover" : "click"}
              open={showReplacePopup}
              position={showInfo ? "top center" : "bottom center"}
              onOpen={() => setShowReplacePopup(true)}
              onClose={onClose}
            >
              {!showInfo ? (
                renderReplaceConfirmation
              ) : (
                <div
                  className="table-dropdown policy-document-tooltip"
                  data-testid="sync-hover"
                >
                  Replace Checklist
                </div>
              )}
            </Popup>
          </div>
        )}
      </div>
    </li>
  );

  const renderActionsPopupContent = (
    <div
      className={classNames("change-management-checklist-popup", {
        "detail-view-checklist": fromDetailView,
      })}
      style={style}
      data-testid="checklist-item-popup"
    >
      {value && !fromDetailView ? (
        <>
          <a href="#" onClick={handleCompleteChecklist}>
            Review / Complete Checklist
          </a>
          <Link to={linkToEdit}>Edit Checklist</Link>
          <a href="#" onClick={handleReplaceChecklist}>
            Replace Checklist
          </a>
        </>
      ) : (
        <>
          <Link to={linkToCreate} data-testid="create-checklist-item">
            Create Checklist
          </Link>
          <a href="#" onClick={handleUseSavedChecklist}>
            Use Saved Checklist
          </a>
        </>
      )}
    </div>
  );

  return (
    <>
      {disabled || locked ? (
        renderTrigger
      ) : (
        <Popup
          position={fromDetailView ? "bottom left" : "bottom center"}
          trigger={() => renderTrigger}
          open={!!isPopUpOpened}
          // @ts-expect-error TS(2345) FIXME: Argument of type '"actions"' is not assignable to ... Remove this comment to see the full error message
          onOpen={() => setIsPopUpOpened("actions")}
          onClose={() => setIsPopUpOpened(null)}
        >
          {isPopUpOpened === "actions" &&
            (!fromDetailView || !value) &&
            renderActionsPopupContent}
          {isPopUpOpened === "replace-confirmation" &&
            renderReplaceConfirmation}
        </Popup>
      )}

      {useSavedChecklistSidebarOpened && (
        <SavedChecklistsSidebar
          recordID={recordID}
          handleClose={() => setUseSavedChecklistSidebarOpened(false)}
          handleSelect={handleAddSavedChecklist}
          fieldName={fieldName}
          isCustomField={isCustomField}
          modulePath={modulePath}
        />
      )}
      {completeChecklistSidebarOpened && (
        <CompleteChecklistSidebar
          handleClose={() => setCompleteChecklistSidebarOpened(false)}
          checklist={value}
          disabled={disabled || locked}
          handleSwitchClick={handleSwitchClick}
          handleNAClick={handleNAClick}
          handleOnBlur={handleOnBlur}
          isCustomField={isCustomField}
          modulePath={modulePath}
        />
      )}
    </>
  );
}

Checklist.defaultProps = {
  locked: false,
  fromDetailView: false,
  hasErrorClass: "has-errors",
};

export default observer(Checklist);
