import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  useToast,
} from "@themis/ui";
import React from "react";
import { PiDotsThreeBold } from "react-icons/pi";
import { generatePath, Link, useParams } from "react-router-dom";

import { Project } from "@/api";
import { useCloneRecord } from "@/api/queries/clones";
import { useUpdateProject } from "@/api/queries/projects";

import { PROJECT_DETAILS_PATH } from "../../pages/ProjectRoutes";

function ProjectRowContextMenu({ values }: { values: Project }) {
  const toast = useToast();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const project_id = values.id.toString();

  const { mutateAsync: updateProject } = useUpdateProject({
    projectId: Number(project_id),
  });

  const { mutateAsync: cloneRecord } = useCloneRecord({
    recordId: Number(project_id),
    recordType: "projects",
  });

  function handleArchive() {
    try {
      updateProject({ project: { archived_at: new Date().toISOString() } });
      toast({
        content: "Project archived successfully!",
        variant: "success",
      });
    } catch {
      toast({
        content: "Could not archive project.",
        variant: "error",
      });
    }
  }

  async function handleDuplicate() {
    try {
      await cloneRecord({});
      toast({
        content: "Project duplicated successfully!",
        variant: "success",
      });
    } catch {
      toast({
        content: "Could not duplicate task.",
        variant: "error",
      });
    }
  }

  return (
    <DropdownMenu onOpenChange={() => {}}>
      <DropdownMenuTrigger asChild>
        <IconButton Icon={PiDotsThreeBold} color="tertiary" />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="tw-divide-x-0 tw-divide-y tw-divide-solid tw-divide-neutral-100"
      >
        <div className="tw-w-56">
          <Link
            className="tw-text-neutral-500"
            to={(location) => ({
              pathname: generatePath(PROJECT_DETAILS_PATH, {
                workspace_id,
                project_id,
              }),
              state: { from: location.pathname },
            })}
          >
            <DropdownMenuItem>Edit Details</DropdownMenuItem>
          </Link>
          <DropdownMenuItem onClick={handleDuplicate}>
            Duplicate
          </DropdownMenuItem>
        </div>
        <div>
          <DropdownMenuItem onClick={handleArchive}>Archive</DropdownMenuItem>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default ProjectRowContextMenu;
