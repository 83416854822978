import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import iconMap from "../../components/helpers/moduleIcons";

export const iconForThemisModuleIdentifier = (
  themisModuleIdentifier: ModuleIdentifier,
) => {
  const iconUrl = iconMap[themisModuleIdentifier];
  if (iconUrl) {
    return iconUrl;
  }

  return iconMap.default;
};
