import { AxiosResponse } from "axios";

import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import api from "../api";
import {
  ZendeskConfigCreateRequestData,
  ZendeskConfigResponseData,
  ZendeskSaveMappingParams,
  ZendeskSaveMappingRequestData,
} from "./types";

export async function createConfiguration(
  body: ZendeskConfigCreateRequestData,
) {
  const response = await api.post<
    ZendeskConfigCreateRequestData,
    AxiosResponse<ZendeskConfigResponseData>
  >("/integrations/zendesk/configurations", body);

  return response.data;
}

export async function saveConfiguration(params: ZendeskSaveMappingParams) {
  const { id, ...requestData } = params;

  const response = await api.put<ZendeskSaveMappingRequestData>(
    `/integrations/zendesk/configurations/${id}`,
    requestData,
  );

  return response.data;
}
export async function getConfiguration(id: string) {
  const response = await api.get<ZendeskConfigResponseData>(
    `/integrations/zendesk/configurations/${id}`,
  );

  return response.data;
}

export async function connect(params: {
  workspace_id: number;
  subdomain: string;
  module_identifier: ModuleIdentifier;
  user_app_host: string;
}) {
  const response = await api.get<{ url: string }>(
    "/integrations/zendesk/oauth",
    { params },
  );

  return response.data;
}
