import "./index.scss";

import React, { ComponentProps } from "react";

type Props = ComponentProps<"input">;

function RadioButton({
  value = "",
  name,
  id,
  checked = false,
  onChange = () => {},
  disabled = false,
}: Props) {
  return (
    <div className="radio-button__wrapper">
      <input
        className="radio-button__input"
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}

export default RadioButton;
