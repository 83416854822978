import classNames from "classnames";
import { observer } from "mobx-react";
import React, { ReactNode } from "react";
import { CircularProgressbar } from "react-circular-progressbar";

import iconMap from "@/components/helpers/moduleIcons";
import { useMainStore } from "@/contexts/Store";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import DefaultIcon from "../../../images/setting-workspace/icon/Default Icon - V1.svg";
import { nameFromModuleWorkspace } from "../../helpers/nameForThemisModuleIdentifier";

type Props = {
  isCreateWorkspaceButtonEnabled?: boolean;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onWorkspaceSubmit?: (...args: any[]) => any;
  selectedModules?: ModuleIdentifier[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setWorkspaceStep?: (...args: any[]) => any;

  workspaceIconLogo?: ReactNode;
};

const ReviewWorkspaceInfo = ({
  workspaceIconLogo,
  name,
  selectedModules,
  setWorkspaceStep,
  isCreateWorkspaceButtonEnabled,
  onWorkspaceSubmit,
}: Props) => {
  const mainStore = useMainStore();
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const createWorkspaceButtonActive = classNames({
    active: isCreateWorkspaceButtonEnabled,
  });

  return (
    <>
      <div className="setting-workspace-progressbar">
        <div className="progressbar">
          <CircularProgressbar value={100} strokeWidth={15} />
        </div>
        <p>Review workspace info</p>
      </div>
      <div className="workspace-name">
        <p>Workspace Name</p>
        <div className="workspace-name-block">
          {workspaceIconLogo}
          <h4>{name}</h4>
        </div>
      </div>
      <div className="workspace-internal-modules">
        <h5>Selected Internal Modules</h5>
        <ul>
          {selectedModules?.map((selectedModule) => {
            return (
              <li key={selectedModule}>
                <img src={iconMap[selectedModule]} alt={DefaultIcon} />
                {nameFromModuleWorkspace(selectedModule, moduleWorkspaces)}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="workspace-buttons">
        {/* @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
        <button className="back" onClick={() => setWorkspaceStep(4)}>
          Back
        </button>
        <button
          className={createWorkspaceButtonActive}
          disabled={!isCreateWorkspaceButtonEnabled}
          onClick={onWorkspaceSubmit}
        >
          Confirm Workspace
        </button>
      </div>
    </>
  );
};

ReviewWorkspaceInfo.defaultProps = {
  isCreateWorkspaceButtonEnabled: true,
};

export default observer(ReviewWorkspaceInfo);
