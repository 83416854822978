import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "../contexts/Store";
import { useModuleDetection } from "./useModuleDetection";

export default function usePermissionIndexer() {
  const mainStore = useMainStore();
  const { accessToken } = mainStore.webSessions;
  const { workspaceID, companyID, themisModuleIdentifier } = mainStore.context;
  const location = useLocation();

  // re-index company permissions on company change
  useEffect(() => {
    if (accessToken && companyID) {
      mainStore.userPermissions.index("company", companyID, null, null);
    }
  }, [accessToken, companyID]);

  // re-index workspace permissions on workspace change
  useEffect(() => {
    if (accessToken && companyID && workspaceID) {
      mainStore.userPermissions.index(
        "workspace",
        companyID,
        workspaceID,
        null,
      );
    }
  }, [accessToken, workspaceID, companyID]);

  // re-index module-specific permissions on workspace/module change
  useEffect(() => {
    const currentModule = useModuleDetection(location);

    if (accessToken && companyID && workspaceID && currentModule) {
      mainStore.userPermissions.index(
        "module",
        companyID,
        workspaceID,
        currentModule,
      );
    }
  }, [
    accessToken,
    workspaceID,
    companyID,
    themisModuleIdentifier,
    location.pathname,
  ]);
}
