import "./questionnaire-save-button.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { Button } from "@/components/Elements";
import Switch from "@/components/table/shared/Switch";

type QuestionnaireSaveButtonProps = {
  onSave: () => void;
  isDisabled: boolean;
  buttonLabel: string;
  isPublic: boolean;
  setIsPublic: (val: boolean) => void;
};

function QuestionnaireSaveButton({
  onSave,
  isDisabled,
  buttonLabel,
  isPublic,
  setIsPublic,
}: QuestionnaireSaveButtonProps) {
  const [showPopup, setShowPopup] = useState(false);

  const handlePopUpOpen = () => setShowPopup(true);
  const handlePopUpClose = () => setShowPopup(false);

  const renderTrigger = (
    <div>
      <Button
        label={buttonLabel}
        onClick={handlePopUpOpen}
        size="sm"
        type="button"
        disabled={isDisabled}
      />
    </div>
  );

  return (
    <Popup
      position="bottom left"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={handlePopUpOpen}
      onClose={handlePopUpClose}
      keepTooltipInside
    >
      <div className="table-dropdown success-dropdown back-btn-confirm-popup">
        <div className="confirmation-dialog" data-testid="confirmation-dialog">
          <h4>Set Questionnaire Visibility</h4>
          <p className="questionnaire-save-button-text">
            Toggle the visibility below whether this questionnaire would be
            accessible via Internal users ONLY OR it could be shared publicly.
          </p>

          <p className="questionnaire-save-button-text">
            *You cannot change the visibility once being set.
          </p>

          <div className="questionnare-save-button-divider" />

          <div className="public-internal-selection">
            <div
              className={classNames({
                "active-text": !isPublic,
                "passive-text": isPublic,
              })}
            >
              Completing internally
            </div>
            <Switch
              active
              checked={isPublic}
              onChange={() => setIsPublic(!isPublic)}
            />
            <div
              className={classNames({
                "active-text": isPublic,
                "passive-text": !isPublic,
              })}
            >
              Sharing externally
            </div>
          </div>

          <div className="questionnare-save-button-divider" />

          <div className="confirmation">
            <Button
              label="Confirm"
              onClick={() => onSave()}
              size="sm"
              type="button"
            />
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default observer(QuestionnaireSaveButton);
