import React from "react";

import lightbulbIcon from "../../../images/table-image/icon/lightbulb-icon.svg";

type Props = {
  description?: string;
  title?: string;
};

function ArchivedEmpty({ description, title }: Props) {
  return (
    <div className="archived-empty">
      <div className="empty-title">
        <img src={lightbulbIcon} alt="warning-icon" />
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
    </div>
  );
}

export default ArchivedEmpty;
