var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Slot } from "@radix-ui/react-slot";
import uniqueId from "lodash/uniqueId";
import * as React from "react";
import { Controller, FormProvider, useFormContext, } from "react-hook-form";
import { cn } from "../../lib/utils";
import { Label } from "../Label/Label";
var Form = FormProvider;
var FormFieldContext = React.createContext({});
var FormField = function (_a) {
    var props = __rest(_a, []);
    return (React.createElement(FormFieldContext.Provider, { value: { name: props.name, required: props.required } },
        React.createElement(Controller, __assign({}, props))));
};
var useFormField = function () {
    var fieldContext = React.useContext(FormFieldContext);
    var itemContext = React.useContext(FormItemContext);
    var _a = useFormContext(), getFieldState = _a.getFieldState, formState = _a.formState;
    var fieldState = getFieldState(fieldContext.name, formState);
    if (!fieldContext) {
        throw new Error("useFormField should be used within <FormField>");
    }
    var id = itemContext.id;
    return __assign({ id: id, name: fieldContext.name, required: fieldContext.required, formItemId: "".concat(id, "-form-item"), formDescriptionId: "".concat(id, "-form-item-description"), formMessageId: "".concat(id, "-form-item-message") }, fieldState);
};
var FormItemContext = React.createContext({});
var FormItem = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    var id = uniqueId();
    return (React.createElement(FormItemContext.Provider, { value: { id: id } },
        React.createElement("div", __assign({ ref: ref, className: cn("tw-space-y-1", className) }, props))));
});
FormItem.displayName = "FormItem";
var FormLabel = React.forwardRef(function (_a, ref) {
    var className = _a.className, children = _a.children, props = __rest(_a, ["className", "children"]);
    var _b = useFormField(), formItemId = _b.formItemId, required = _b.required;
    return (React.createElement(Label, __assign({ ref: ref, className: cn("tw-font-sans", className), htmlFor: formItemId }, props),
        children,
        " ",
        required && React.createElement("span", { className: "tw-text-warning-300" }, "*")));
});
FormLabel.displayName = "FormLabel";
var FormControl = React.forwardRef(function (_a, ref) {
    var placeholder = _a.placeholder, props = __rest(_a, ["placeholder"]);
    var _b = useFormField(), error = _b.error, formItemId = _b.formItemId, formDescriptionId = _b.formDescriptionId, formMessageId = _b.formMessageId;
    return (React.createElement(Slot, __assign({ ref: ref, id: formItemId, className: cn({
            " tw-bg-warning-50 tw-text-warning-300 placeholder:tw-text-warning-300": error,
        }), "aria-describedby": !error
            ? "".concat(formDescriptionId)
            : "".concat(formDescriptionId, " ").concat(formMessageId), "aria-invalid": !!error }, props)));
});
FormControl.displayName = "FormControl";
var FormMessage = React.forwardRef(function (_a, ref) {
    var className = _a.className, children = _a.children, props = __rest(_a, ["className", "children"]);
    var _b = useFormField(), error = _b.error, formMessageId = _b.formMessageId;
    var body = error ? String(error === null || error === void 0 ? void 0 : error.message) : children;
    if (!body) {
        return null;
    }
    return (React.createElement("p", __assign({ ref: ref, id: formMessageId, className: cn("tw-font-sans tw-text-xs tw-font-medium tw-text-warning-300", className) }, props), body));
});
FormMessage.displayName = "FormMessage";
export { useFormField, Form, FormItem, FormLabel, FormControl, FormMessage, FormField, };
