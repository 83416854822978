import { cn } from "@themis/ui";
import React from "react";

import { DashboardComponent } from "@/api";

export default function RecordCompletionRate({
  widgetData,
}: {
  widgetData: DashboardComponent["data"];
}) {
  const val = widgetData?.value;
  const percentageNum = val?.includes("%") ? val.slice(0, -1) : val;
  const operator = val?.includes("%") ? "%" : "";
  const isEmptyState = percentageNum === "0" || percentageNum === "0.00";

  return (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Record Completion Rate
        </div>
      </div>
      <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-2">
        <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center">
            <div className="tw-flex tw-items-baseline tw-text-6xl tw-font-semibold tw-text-neutral-500">
              <div
                className={cn("tw-relative tw-text-7xl", {
                  "tw-text-neutral-500": !isEmptyState,
                  "tw-text-neutral-200": isEmptyState,
                })}
              >
                {percentageNum}
              </div>
              <div className="tw-relative tw-text-3xl tw-tracking-wide tw-text-neutral-300">
                {operator}
              </div>
            </div>
            <div className="tw-items-center tw-pt-4 tw-text-center tw-text-sm tw-font-medium tw-text-neutral-300">
              <p>Record closed with due dates</p>
              <p className="tw-m-0">in the time range</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
