import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import PolicySection from "../../../components/table/policy/PolicySection";
import { getRepositionPayloadFromIndices } from "../../helpers/reordering";
import SectionDragContext from "../shared/SectionDragContext";

type Props = {
  showCheckbox?: boolean;
};

function Policies({ showCheckbox }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const { data } = mainStore.policies;
  const moduleWorkspaceID = data?.module_workspace_id;
  const recordVersions = mainStore.recordVersions.list;
  const { tableName } = mainStore.context;
  const isFinalized = tableName === "Finalized";
  const isDrafts = tableName === "Drafts";
  const onSectionChange = async (
    recordVersionId: number,
    sectionId: number,
  ) => {
    await mainStore.policies.updateSection(recordVersionId, sectionId);
  };

  const getVersionsForStatusAndSection = (sectionId: number) =>
    recordVersions.filter((recordVersion) => {
      if (recordVersion.section_tag_id !== sectionId) {
        return false;
      }
      const statusValue = mainStore.avroSchemas.valueForField(
        "status",
        recordVersion.data,
      )?.[0];
      const completed = statusValue === "completed";
      return (
        (tableName === "Completed" && completed) || tableName !== "Completed"
      );
    });

  const onRecordMoved = async (
    recordVersionId: number,
    sectionId: number,
    sourceIndex: number,
    destinationIndex: number,
  ) => {
    const relevantRecordVersions = getVersionsForStatusAndSection(sectionId);
    await mainStore.recordVersions.reorder(
      recordVersionId,
      getRepositionPayloadFromIndices(
        relevantRecordVersions,
        sourceIndex,
        destinationIndex,
      ),
    );
  };

  return (
    ((recordVersions.length > 0 && isFinalized) || isDrafts) && (
      <SectionDragContext
        onSectionChange={onSectionChange}
        onRecordMoved={onRecordMoved}
      >
        {/* top-level */}
        <PolicySection
          isDropdownOpen
          tableName={tableName}
          recordVersions={recordVersions.filter(
            (recordVersion) => !recordVersion.section_tag_id,
          )}
          moduleWorkspaceID={moduleWorkspaceID}
          showCheckbox={showCheckbox}
        />

        {mainStore.sectionTags.orderedList.map((sectionTag) => {
          const filteredRecordVersions = recordVersions.filter(
            (recordVersion) => recordVersion.section_tag_id === sectionTag.id,
          );
          return (
            <PolicySection
              key={sectionTag.id}
              isDropdownOpen
              tableName={tableName}
              recordVersions={filteredRecordVersions}
              moduleWorkspaceID={moduleWorkspaceID}
              showCheckbox={showCheckbox}
              sectionTag={sectionTag}
            />
          );
        })}
      </SectionDragContext>
    )
  );
}

export default observer(Policies);
