import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import { Button } from "../../../Elements";
import ImportMatrix from "../ImportMatrix";
import ResetRiskAssessment from "../ResetRiskAssessment";
import RiskAssessmentHeading from "../RiskAssessmentHeading";
import RiskAssessmentRiskLevels from "./RiskAssessmentRiskLevels";
import RiskAssessmentThemes from "./RiskAssessmentThemes";

type Props = {
  isActive?: boolean;
  isNew?: boolean;
  isTemplate?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matrix?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIsBulkEdit?: (...args: any[]) => any;
  usingTemplate?: boolean;
  vendorID?: string;
};

const RiskAssessmentNormalView = ({
  isActive,
  isNew,
  isTemplate,
  matrix,
  setIsBulkEdit,
  usingTemplate,
  vendorID,
}: Props) => {
  const mainStore = useMainStore();
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  return (
    <div className="vdd-risk-assessment" data-testid="vdd-risk-assessment">
      <RiskAssessmentHeading
        vendorID={vendorID}
        matrix={matrix}
        isActive={isActive}
        isNew={isNew}
        isTemplate={isTemplate}
        actions={
          <>
            <Button
              disabled={!hasModuleWriteAccess}
              icon="edit"
              theme="tertiary"
              label="Edit"
              size="sm"
              // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              onClick={() => setIsBulkEdit(true)}
            />
            {!isNew && isActive && (
              <ImportMatrix riskAssessmentID={matrix?.id} />
            )}
            {isActive && <ResetRiskAssessment matrix={matrix} isNew={isNew} />}
          </>
        }
      />
      <div
        className="vdd-risk-assessment__content"
        data-testid="vdd-risk-assessment__content"
      >
        <RiskAssessmentRiskLevels
          matrix={matrix}
          isNew={isNew}
          isTemplate={isTemplate || usingTemplate}
        />
        <RiskAssessmentThemes
          matrix={matrix}
          isNew={isNew}
          isTemplate={isTemplate || usingTemplate}
        />
      </div>
    </div>
  );
};

export default observer(RiskAssessmentNormalView);
