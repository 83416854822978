import classNames from "classnames";
import { sortBy } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";

import { Icon } from "../Elements";
import { EMPTY_OPTIONS } from "./constants";
import {
  FiltersIntegerConditions,
  FiltersIntegerRangeValues,
  FiltersIntegerValue,
} from "./FiltersIntegerDropdowns";
import { ConditionInteger, Step } from "./types";

type FiltersIntegerProps = {
  fieldName: string;
  onAddFilter?: () => void;
  clearField: () => void;
  toggleShowFilter?: (value?: boolean) => void;
  selectedStep?: Step;
};

const FiltersInteger = ({
  fieldName,
  clearField,
  onAddFilter,
  toggleShowFilter,
}: FiltersIntegerProps) => {
  const mainStore = useMainStore();

  const { getTableFiltersParam, setTableFiltersParam } = useUpdateFilter();

  const [open, setOpen] = useState(true);
  const [condition, setCondition] = useState<ConditionInteger>("equal");
  const [currentStep, setCurrentStep] = useState("condition");
  const [value, setValue] = useState("");
  const [valueFrom, setValueFrom] = useState("");
  const [valueTo, setValueTo] = useState("");
  const [disabled, setDisabled] = useState(true);

  const isEmptyCondition = EMPTY_OPTIONS.includes(condition);
  const isBeetwenSelected = condition === "is beetwen";
  const showIntegerRange = condition && !isEmptyCondition && !isBeetwenSelected;

  // useEffect
  useEffect(() => {
    if (!open) {
      toggleShowFilter?.(true);
    }

    if (open || !condition) {
      return;
    }

    if (isEmptyCondition) {
      integerRangeSelected();
    }
  }, [open]);

  useEffect(() => {
    setValueTo(valueTo);
  }, [valueTo]);

  useEffect(() => {
    setValueFrom(valueFrom);
  }, [valueFrom]);

  const field = mainStore.fields.list.find(
    (fieldData) => fieldData.display_name === fieldName,
  );

  const integerRangeSelected = () => {
    let filter;
    if (isEmptyCondition) {
      filter = {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        name: field.name,
        condition,
      };
    } else {
      filter = {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        name: field.name,
        condition,
        value: value || null,
      };
    }

    handleAddIntegerFilterOption(filter);
    onAddFilter?.();
  };

  const betweenIntegerRangeSelected = () => {
    if (!checkIfNumbers(valueFrom) || !checkIfNumbers(valueTo)) {
      mainStore.toast.setErrorText("Please only enter integer values.");
      return;
    }

    if (Number(valueFrom) > Number(valueTo)) {
      mainStore.toast.setErrorText(
        "The first number must be less than the second",
      );
      return;
    }

    const sharedFilterValues = {
      condition,
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      name: field.name,
    };

    const filter = {
      ...sharedFilterValues,
      value: valueFrom || null,
    };

    const otherFilter = {
      ...sharedFilterValues,
      value: valueTo || null,
    };

    // @ts-expect-error TS(2345) FIXME: Argument of type '{ value: string | null; conditio... Remove this comment to see the full error message
    handleAddIntegerFilterOption(filter, otherFilter);
    onAddFilter?.();
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'number' implicitly has an 'any' type.
  function skipDisable(number) {
    if (checkIfNumbers(number)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      if (event.target.value.length > 0 && checkIfNumbers(event.target.value)) {
        integerRangeSelected();
      } else {
        mainStore.toast.setErrorText("Please only enter integer values.");
      }
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'number' implicitly has an 'any' type.
  function checkIfNumbers(number) {
    if (Number(number) || number === "0") {
      return true;
    }
    return false;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'filter' implicitly has an 'any' type.
  const handleAddIntegerFilterOption = (filter, otherFilter = undefined) => {
    const filterField = getTableFiltersParam().find(
      // @ts-expect-error TS(7006) FIXME: Parameter 'filterItem' implicitly has an 'any' typ... Remove this comment to see the full error message
      (filterItem) => filterItem.name === filter.name,
    );

    if (!filterField) {
      return setTableFiltersParam([
        ...getTableFiltersParam(),
        {
          name: filter.name,
          condition: filter.condition,
          options: otherFilter
            ? sortBy([filter, otherFilter], (filterItem) => filterItem?.value)
            : [filter],
        },
      ]);
    }

    const filterFieldOptionIsPresent = filterField.options.find(
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      (item) =>
        item.data_type === filter.data_type &&
        item.condition === filter.condition,
    );

    if (!filterFieldOptionIsPresent) {
      setTableFiltersParam(
        // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
        getTableFiltersParam().map((item) =>
          item.name === filter.name
            ? {
                ...item,
                options: otherFilter ? [filter, otherFilter] : [filter],
              }
            : item,
        ),
      );
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  const handleSelectCondition = (item) => {
    setCondition(item);

    if (EMPTY_OPTIONS.includes(item)) {
      setOpen(false);
    } else if (item === "is between") {
      setCurrentStep("betweenIntegerRange");
    } else {
      setCurrentStep("integerRange");
    }
  };

  const handleCloseFilter = () => {
    setCondition("equal");
    setCurrentStep("condition");

    clearField();
    setOpen(false);

    toggleShowFilter?.(true);

    setTableFiltersParam(
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      getTableFiltersParam().filter((item) => item.name !== field.name),
    );
  };

  const goBackToSelectCondition = () => {
    setCondition("equal");
    setCurrentStep("condition");
  };

  const onOpen = (clickedStep: Step) => {
    setCurrentStep(clickedStep);
    setOpen(true);
  };

  return (
    <Popup
      trigger={
        <div className="date-filter-container">
          <div
            className="date-filter no-pointer"
            onClick={(e) => e.stopPropagation()}
          >
            {fieldName || "..."}
          </div>
          <div className="date-filter" onClick={() => onOpen("condition")}>
            {condition || "..."}
          </div>

          {showIntegerRange && <div className="date-filter">{"..."}</div>}

          <div onClick={handleCloseFilter} className="close date-filter">
            <Icon name="close" color="generalDark" size="de" />
          </div>
        </div>
      }
      position="bottom left"
      open={open}
      onClose={() => setOpen(false)}
    >
      <div
        className={classNames("table-dropdown success-dropdown filters-popup", {
          "date-container": currentStep === "exactDate",
        })}
      >
        {currentStep === "condition" && (
          <FiltersIntegerConditions
            showBackButton
            handleCloseFilter={handleCloseFilter}
            handleSelectCondition={handleSelectCondition}
            condition={condition}
          />
        )}
        {currentStep === "integerRange" && (
          <FiltersIntegerValue
            showBackButton
            onlyContent
            goBackToSelectCondition={goBackToSelectCondition}
            handleKeyDown={handleKeyDown}
            setValue={setValue}
            value={value}
          />
        )}
        {currentStep === "betweenIntegerRange" && (
          <FiltersIntegerRangeValues
            showBackButton
            goBackToSelectCondition={goBackToSelectCondition}
            setValueFrom={setValueFrom}
            valueFrom={valueFrom}
            valueTo={valueTo}
            setValueTo={setValueTo}
            skipDisable={skipDisable}
            betweenIntegerRangeSelected={betweenIntegerRangeSelected}
            onlyContent
            disabled={disabled}
          />
        )}
      </div>
    </Popup>
  );
};

export default observer(FiltersInteger);
