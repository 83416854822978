import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { isLockedRecord } from "../../../../stores/helpers/RecordVersionHelper";
import Checklist from "../checklist/Checklist";

type Props = {
  fieldName?: string;
  moduleIdentifier?: ModuleIdentifier;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion?: any;
};

function ViewChecklist({ recordVersion, fieldName, moduleIdentifier }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const currentChecklist = recordVersion?.record?.base_checklists?.find(
    // @ts-expect-error TS(7006) FIXME: Parameter 'checklist' implicitly has an 'any' type... Remove this comment to see the full error message
    (checklist) => checklist.field_name === fieldName,
  );
  const isLocked = isLockedRecord(recordVersion, moduleIdentifier);

  const cellError = mainStore.recordVersions.errorsForField(
    recordVersion?.id,
    fieldName,
  );
  const hasErrors = Boolean(cellError);

  const errorMessage =
    hasErrors && cellError ? cellError.description : undefined;

  return (
    <div className="block-image-content checklist">
      <Checklist
        recordID={recordVersion?.record?.id}
        defaultValue={currentChecklist}
        // @ts-expect-error TS(2322) FIXME: Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
        locked={isLocked}
        fieldName={fieldName}
        moduleIdentifier={moduleIdentifier}
        fromDetailView
        hasErrors={hasErrors}
        errorMessage={errorMessage}
      />
    </div>
  );
}

export default observer(ViewChecklist);
