import * as React from "react";

import { Typography } from "@/components/Elements";

type PopupMenuItemProps = {
  label?: string;
  onClick?: () => void;
  divider?: boolean;
};

export default function PopupMenuItem(props: PopupMenuItemProps) {
  if (props.divider) {
    return <div className="popup-menu-section-divider" />;
  }
  return (
    <button className="popup-menu-section-item" onClick={props.onClick}>
      <Typography className="label" label={props.label} />
    </button>
  );
}
