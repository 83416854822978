import {
  IconButton,
  LinkButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import classNames from "classnames";
import pluralize from "pluralize";
import React from "react";
import { PiInfoBold, PiPlusBold } from "react-icons/pi";
import { generatePath, useRouteMatch } from "react-router-dom";

import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import AuditTrailButton from "@/features/misc/AuditTrailButton";
import ExportButton from "@/features/misc/ExportButton";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";
import ImportButton from "@/features/misc/ImportButton";
import mainStore from "@/stores/Main";

import { TrainingList } from "../components/TrainingList";
import { TRAINING_ROUTES } from "../pages";

function TrainingContainer({ children }: { children: React.ReactNode }) {
  return (
    <div
      className={classNames(
        "tw-flex tw-min-w-96 tw-flex-col tw-gap-4 tw-overflow-auto",
      )}
    >
      {children}
    </div>
  );
}

function ActionsContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-end tw-gap-1 tw-border-b tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-generals-underline tw-bg-white tw-px-6 tw-py-2">
      {children}
    </div>
  );
}

function TitleContainer({ children }: { children: React.ReactNode }) {
  return <div className="tw-flex tw-items-center tw-gap-1">{children}</div>;
}

export function Training() {
  const { url } = useRouteMatch();
  const moduleDisplayName = "Training";
  const moduleWorkspaceID = mainStore.moduleWorkspaces.list?.find(
    (mw) => mw.themis_module?.identifier === "training",
  )?.id;

  return (
    <PageLayout>
      <Header
        title={
          <TitleContainer>
            {moduleDisplayName}
            <Tooltip>
              <TooltipTrigger asChild>
                <IconButton Icon={PiInfoBold} color="transparent" size="sm" />
              </TooltipTrigger>
              <TooltipContent className="tw-box-border tw-inline-flex tw-w-72 tw-flex-col tw-items-start tw-gap-1 tw-px-6 tw-py-4">
                <h3 className="tw-text-base tw-font-semibold">
                  {pluralize(moduleDisplayName)}
                </h3>
                <p className="tw-font-medium">
                  This is where you can manage your{" "}
                  {moduleDisplayName.toLowerCase()}.
                </p>
              </TooltipContent>
            </Tooltip>
          </TitleContainer>
        }
        HeaderOptions={
          <>
            <FavoriteButton
              passedModuleWorkspaceID={moduleWorkspaceID || undefined}
            />
            <AuditTrailButton recordType="trainings" />
            <ExportButton recordType="trainings" />
            <ImportButton recordType="trainings" />
          </>
        }
      />
      <ActionsContainer>
        <LinkButton
          LeftIcon={PiPlusBold}
          to={generatePath(`${url}${TRAINING_ROUTES.details}`, {
            trainingId: "new",
          })}
        >
          {`Add ${moduleDisplayName}`}
        </LinkButton>
      </ActionsContainer>
      <PageContent>
        <TrainingContainer>
          <TrainingList />
        </TrainingContainer>
      </PageContent>
    </PageLayout>
  );
}
