import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";

import { useCompanyUsers } from "@/api/queries/users";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";

import { useSortingAndPagination } from "../../hooks/useSortingAndPagination";
import ListSettings from "../table/settings/ListSettings";
import ListTitleSettings from "../table/settings/ListTitleSettings";
import Table from "../table/Table";
import CompanySettingsNavigation from "./CompanySettingsNavigation";

export const HEADERS = [
  {
    field_name: "email",
    title: "Email Address",
    disabled: true,
    sortable: true,
    data_type: "text",
  },
  {
    field_name: "full_name",
    title: "Full Name",
    sortable: true,
    data_type: "text",
  },
  {
    field_name: "roles",
    title: "Roles and Associations",
    sortable: true,
    data_type: "tag:roles",
  },
  {
    field_name: "status",
    title: "Status",
    disabled: true,
    sortable: true,
    data_type: "option",
  },
  {
    field_name: "department",
    title: "Department",
    sortable: true,
    data_type: "tag:department",
  },
];

function CompanyInternalUserList() {
  const mainStore = useMainStore();

  const companyID = mainStore.context.companyID!;

  const [newRecord, setNewRecord] = useState(null);
  const { setSortBy, sortByFieldName, sortByDirection } =
    useSortingAndPagination({});

  const { activeWorkspace, workspaceID } = mainStore.context;
  const users = useCompanyUsers(companyID, {
    workspace_id: Number(workspaceID),
  })?.data?.data;

  useEffect(() => {
    mainStore.permissions.index(companyID);
    mainStore.permissions.getPermissionGroups();
  }, [companyID]);

  useEffect(() => {
    const activeWorkspaceID = activeWorkspace?.id;
    if (activeWorkspaceID) {
      mainStore.users.indexForSettings({
        workspaceID: activeWorkspaceID,
      });
    }
  }, [activeWorkspace]);

  useEffect(() => {
    const activeWorkspaceID = activeWorkspace?.id;
    if (activeWorkspaceID) {
      mainStore.users.indexForSettings({
        workspaceID: activeWorkspaceID,
        sortBy: sortByFieldName,
        sortByDirection,
      });
    }
  }, [sortByFieldName, sortByDirection]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const addNewUser = (event) => {
    event && event.preventDefault();

    if (newRecord) {
      mainStore.toast.setErrorText(
        "You have an unsaved user below, please select the checkmark button to save the new user.",
      );
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ external: boolean; }' is not a... Remove this comment to see the full error message
      setNewRecord({ external: !activeWorkspace.is_internal });
    }
  };

  const removeNewUser = () => {
    setNewRecord(null);
  };

  // elements
  const renderHeaders = HEADERS.map((header) => (
    <ListTitleSettings
      key={header.field_name}
      title={header.title}
      fieldName={header.field_name}
      sortDirection={
        header.sortable && sortByDirection === header.field_name
          ? sortByDirection
          : undefined
      }
      onClick={() => setSortBy(header.field_name)}
    />
  ));

  const renderRows = users?.map((user) => {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    const external = !activeWorkspace.is_internal;
    const data = {
      ...user,
      roles: user.applied_permission_sets,
      external,
      module_admin: "Policy, Issue Management",
    };

    return (
      <ListSettings key={user.id} headers={HEADERS} data={data} model="User" />
    );
  });

  return (
    <Table>
      <div className="settings-wrap company-users-settings-container">
        <CompanySettingsNavigation />
        <AddRecordHeader recordName="User" addRecord={() => addNewUser(null)} />
        <div className="settings-content-wrap">
          <div className="settings-explainer">
            Who are users? <br />
            Users have login credential to Themis. They can view modules and
            workspaces based on their roles and module specific permissions.
          </div>
          <div className="list-title-table">
            <ul>
              <div className="list-title-table-wrap">
                {renderHeaders}
                <span className="stretch-cell" />
              </div>
              <div className="list-title-table-block" />
            </ul>
          </div>
          {newRecord && (
            <ListSettings
              headers={HEADERS}
              data={newRecord}
              model="User"
              isNewRecord
              removeNewEntry={removeNewUser}
            />
          )}
          {renderRows}
          <div
            className="tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
            onClick={addNewUser}
            data-testid="new-user-button"
          >
            <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
            <div className="tw-neutral-300 tw-text-sm">Add User...</div>
          </div>
        </div>
      </div>
    </Table>
  );
}

export default observer(CompanyInternalUserList);
