import { HeaderTabs } from "@themis/ui";
import React from "react";
import { generatePath, useLocation, useParams } from "react-router-dom";

import { useIsFeatureEnabled } from "@/hooks/useIsFeatureEnabled";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import { COMPANY_RETRO_PATH, HOME_PATH, MY_WORK_PATH } from "../pages";

export function HomeTabs() {
  const { pathname } = useLocation();

  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const isOutlookEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_NEW_DASHBOARD_COMPANY_OUTLOOK_WIDGETS,
  );

  const isRetroEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_NEW_DASHBOARD_RETROSPECTIVE_WIDGETS,
  );

  const tabs = [];

  if (isOutlookEnabled) {
    tabs.push({
      value: generatePath(HOME_PATH, {
        workspace_id,
      }),
      name: "Company Outlook",
      key: "company-outlook",
    });
  }

  tabs.push({
    value: generatePath(MY_WORK_PATH, {
      workspace_id,
    }),
    name: "My Work",
    key: "my-work",
  });

  if (isRetroEnabled) {
    tabs.push({
      value: generatePath(COMPANY_RETRO_PATH, {
        workspace_id,
      }),
      name: "Company Retro",
      key: "company-retro",
    });
  }

  return (
    <HeaderTabs
      tabs={tabs}
      selectedTab={pathname}
      tabsListClassName="tw-bg-neutral-25 tw-px-3"
      isLink
    />
  );
}
