import { HeaderTabs } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import { getSelectedTab } from "@/components/helpers/Tabs";
import { useMainStore } from "@/contexts/Store";
import { UseFiltersReturn } from "@/hooks/useFilters";

import ExportBulk from "../../../components/dashboard/ExportBulk";
import ImportBulk from "../../../components/dashboard/ImportBulk";
import calendarIcon from "../../../images/table-image/icon/calendar-black-icon.svg";
import calendarActiveIcon from "../../../images/table-image/icon/calendar-white-icon.svg";

type Props = {
  filtersData?: UseFiltersReturn | Record<string, never>;
  hideSchedule?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setHideSchedule?: (...args: any[]) => any;
};

function QAHeader({ setHideSchedule, hideSchedule, filtersData = {} }: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const location = useLocation();
  const isInTests =
    location.pathname === `/workspaces/${workspaceID}/modules/qa-tests`;

  const toggleScheduleView = () => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setHideSchedule(!hideSchedule);
  };

  const TABS = [
    {
      name: "Tests",
      key: "qa-tests",
      value: `/workspaces/${workspaceID}/modules/qa-tests`,
    },
    {
      name: "Completed",
      key: "completed",
      value: `/workspaces/${workspaceID}/modules/qa-tests/completed`,
    },
  ];

  return (
    <>
      <div
        className={classNames("table-header-wrap no-scroll")}
        data-testid="qa-header"
      >
        <HeaderTabs
          tabs={TABS}
          selectedTab={getSelectedTab(TABS)?.value || ""}
          isLink
        />
        <div className="import-export-buttons-container">
          <ExportBulk />
          <ImportBulk />
          {isInTests && (
            <button
              onClick={toggleScheduleView}
              className="import-button"
              data-testid="schedule-button"
              style={{
                backgroundColor: hideSchedule ? "#E3E3FD" : "#353549",
                color: hideSchedule ? "#353549" : "white",
              }}
            >
              <img
                src={hideSchedule ? calendarIcon : calendarActiveIcon}
                alt="export-icon"
              />
              Schedule
            </button>
          )}
          {filtersData.filtersTrigger}
        </div>
      </div>
      {filtersData.filtersViewEnabled && (
        <div className="filters-wrap">
          <div className="switch-table-wrap" />
          {filtersData.filtersContent}
        </div>
      )}
    </>
  );
}

export default observer(QAHeader);
