import { HeaderTabs } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getSelectedTab } from "@/components/helpers/Tabs";
import { useMainStore } from "@/contexts/Store";
import { UseFiltersReturn } from "@/hooks/useFilters";

import ExportBulk from "../../dashboard/ExportBulk";

const TABS = [
  {
    name: "Active Vendors",
    key: "active",
    value: "/modules/vendor-due-diligence/active",
    dataTestId: "vdd-tab-Active_Vendors-trigger",
  },
  {
    name: "Questionnaires",
    key: "templates",
    value: "/modules/vendor-due-diligence/templates",
    dataTestId: "vdd-tab-Templates-trigger",
  },
  {
    name: "Risk Assessment Templates",
    key: "risk-assessment-templates",
    value: "/modules/vendor-due-diligence/risk-assessment-templates",
    dataTestId: "vdd-tab-Risk_Assessment_Templates-trigger",
  },
  {
    name: "Inactive Vendors",
    key: "inactive-vendors",
    value: "/modules/vendor-due-diligence/inactive-vendors",
    dataTestId: "vdd-tab-Inactive_Vendors-trigger",
  },
];

type VendorsHeaderProps = {
  activeScreen?: string;
  filtersData?: UseFiltersReturn | Record<string, never>;
  disableCreationForLimit: boolean;
};

function VendorsHeader({
  activeScreen,
  filtersData = {},
  disableCreationForLimit,
}: VendorsHeaderProps) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const history = useHistory();
  const { pathname } = useLocation();

  const handleAddNewVendor = () => {
    history.push(`/workspaces/${workspaceID}/modules/add-new-vendor`);
  };

  return (
    <>
      <div
        className={classNames("table-header-wrap", {
          "header-with-filters": filtersData.filtersViewEnabled,
        })}
      >
        <div
          className={
            "tw-flex tw-h-[40px] tw-w-auto tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primaryDim-100 tw-bg-neutral-25 tw-py-0"
          }
        >
          <HeaderTabs
            tabs={TABS}
            selectedTab={getSelectedTab(TABS, false, pathname)?.value || ""}
            isLink
            dataTestId="vdd-main-tabs"
          />
        </div>
        <div className="add-new-block">
          {activeScreen === "ActiveVendors" && <ExportBulk />}
          {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
          {["ActiveVendors", "InactiveVendors"].includes(activeScreen) && (
            <div className="vendor-heading-actions">
              <button
                type="button"
                className="table-button add-new-vendor-button"
                onClick={handleAddNewVendor}
                data-testid="vdd-add-new"
                disabled={disableCreationForLimit}
              >
                Add New Vendor
              </button>
              {activeScreen === "ActiveVendors" && filtersData.filtersTrigger}
            </div>
          )}
        </div>
      </div>
      {filtersData.filtersViewEnabled && (
        <div className="filters-wrap">
          <div className="switch-table-wrap" />
          {filtersData.filtersContent}
        </div>
      )}
    </>
  );
}

export default observer(VendorsHeader);
