import { FilePicker, PreviewTableColumn } from "@themis/ui";
import { format, parseISO } from "date-fns";
import React from "react";
import {
  PiBookOpenBold,
  PiCalendarBlankBold,
  PiChartBarBold,
  PiListBulletsBold,
  PiPaperclipBold,
  PiTextAlignLeftBold,
  PiTextTBold,
} from "react-icons/pi";

import { Contract } from "@/api";

import { ActiveTag } from "../components/ActiveTag";
import { ContractRenewalTag } from "../components/Contracts/ContractRenewalTag";
import { ContractsActionsCell } from "../components/ContractsTable/ContractsActionsCell";
import { isContractActive } from "../utils";

export const CONTRACTS_COLUMNS: PreviewTableColumn<Contract>[] = [
  {
    title: "Status",
    type: "locked",
    width: 200,
    Icon: PiChartBarBold,
    Component: (contract) => (
      <ActiveTag isActive={isContractActive(contract.end_date)} />
    ),
  },
  {
    title: "Renewal Option",
    type: "locked",
    width: 200,
    Icon: PiListBulletsBold,
    Component: (contract) => <ContractRenewalTag renewal={contract.renewal} />,
  },
  {
    isTruncated: true,
    title: "Contract",
    type: "locked",
    width: 350,
    Icon: PiPaperclipBold,
    Component: (contract) => (
      <FilePicker
        file={{
          name: contract.file?.file_name || "",
          type: contract.file?.content_type || "",
          url: contract.file?.file_url || "",
        }}
        readOnly
        placeholder="- Add Contract -"
        backwardCompatible
        locked
      />
    ),
  },
  {
    title: "Contract Start Date",
    type: "locked",
    width: 200,
    Icon: PiCalendarBlankBold,
    Component: (contract) =>
      format(parseISO(contract.start_date), "MM/dd/yyyy"),
  },
  {
    title: "Contract Review Date",
    type: "locked",
    width: 200,
    Icon: PiCalendarBlankBold,
    Component: (contract) =>
      format(parseISO(contract.review_date), "MM/dd/yyyy"),
  },
  {
    title: "Contract End Date",
    type: "locked",
    width: 200,
    Icon: PiCalendarBlankBold,
    Component: (contract) => format(parseISO(contract.end_date), "MM/dd/yyyy"),
  },
  {
    title: "Termination Date",
    type: "locked",
    width: 240,
    Icon: PiCalendarBlankBold,
    Component: (contract) =>
      contract.terms === "0"
        ? "On termination date"
        : `${contract.terms} days before termination date`,
  },
  {
    key: "notes",
    title: "Termination Details",
    type: "locked",
    width: 240,
    Icon: PiTextAlignLeftBold,
  },
  {
    type: "actions",
    Component: (contract) => <ContractsActionsCell contract={contract} />,
  },
] as const;

export const PREVIEW_CONTRACTS_COLUMNS: PreviewTableColumn<Contract>[] = [
  {
    title: "Name",
    type: "locked",
    width: 276,
    Icon: PiTextTBold,
    Component: (contract) => contract.file?.file_name,
  },
  {
    title: "Start Date",
    type: "locked",
    width: 200,
    Icon: PiBookOpenBold,
    Component: ({ start_date }) =>
      start_date ? format(parseISO(start_date), "MM/dd/yyyy") : null,
  },
  {
    title: "End Date",
    type: "locked",
    width: 200,
    Icon: PiBookOpenBold,
    Component: ({ end_date }) =>
      end_date ? format(parseISO(end_date), "MM/dd/yyyy") : null,
  },
] as const;
