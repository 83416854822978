import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import {
  ActiveStorageFileUpload,
  DirectUploadProvider,
  DirectUploadProviderProps,
  RenderProps,
} from "react-activestorage-provider";

import { useMainStore } from "@/contexts/Store";

import fileAddGrayIcon from "../../../images/table-image/icon/file-add-gray-icon.svg";
import { Icon } from "../../Elements";

type Props = {
  onSuccess: (signedIds: string[]) => void;
  multiple?: boolean;
};

type UploadedFile = {
  id: string;
  name: string;
  xhr: XMLHttpRequest;
  signed_id?: string;
  canceled?: boolean;
  completed?: boolean;
};

function InteractiveDirectUpload({ onSuccess, multiple }: Props) {
  // Import MobX store
  const mainStore = useMainStore();

  // state
  const [files, setFiles] = useState<UploadedFile[]>([]);

  // vars
  const { themisModuleIdentifier } = mainStore.context;
  const isCreative = themisModuleIdentifier === "marketing";
  const failedFiles = files.filter((item) => item.canceled);
  const notFailedFiles = files.filter((item) => !item.canceled);
  const failedFilesPresent = failedFiles.length > 0;

  // effects
  useEffect(() => {
    if (
      failedFiles.length === 0 ||
      notFailedFiles.some((item) => !item.completed)
    ) {
      return;
    }
    handleConfirm();
  }, [notFailedFiles]);

  // funcs
  function onBeforeBlobRequest({
    id,
    file,
    xhr,
  }: Parameters<
    Required<DirectUploadProviderProps>["onBeforeBlobRequest"]
  >[0]) {
    window.console.log("Before Blob Request Started", id);
    setFiles((prevFiles) => [...prevFiles, { name: file.name, id, xhr }]);

    xhr.onreadystatechange = function () {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      }

      setFiles((prevFiles) =>
        prevFiles.map((item) =>
          item.id === id
            ? {
                ...item,
                signed_id: xhr.response.signed_id,
              }
            : item,
        ),
      );
      xhr.onreadystatechange = null;
      window.console.log("Before Blob Request Ended", id);
    };
  }

  function onBeforeStorageRequest({
    id,
    xhr,
  }: Parameters<
    Required<DirectUploadProviderProps>["onBeforeStorageRequest"]
  >[0]) {
    window.console.log("Before Storage Request Started", id);

    setFiles((prevFiles) =>
      prevFiles.map((item) => (item.id === id ? { ...item, xhr } : item)),
    );

    xhr.onreadystatechange = function () {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      }

      setFiles((prevFiles) =>
        prevFiles.map((item) =>
          item.id === id
            ? {
                ...item,
                completed: true,
              }
            : item,
        ),
      );
      xhr.onreadystatechange = null;

      window.console.log("Before Storage Request Ended", id);
    };
  }

  function getFileProgress(
    file: UploadedFile,
    uploads: ActiveStorageFileUpload[],
  ) {
    const uploadObject = uploads.find((item) => item.id === file.id);
    const state = uploadObject?.state || "finished";

    if (uploadObject?.state === "uploading") {
      return Math.floor(uploadObject.progress);
    }

    if (state === "finished") {
      return 100;
    }

    return 0;
  }

  function cancelUpload(file: UploadedFile) {
    file.xhr.abort();
    setFiles((prevFiles) =>
      prevFiles.map((item) =>
        item.id === file.id ? { ...item, canceled: true } : item,
      ),
    );
  }

  function handleConfirm() {
    onSuccess(
      notFailedFiles.flatMap((item) =>
        item.signed_id ? [item.signed_id] : [],
      ),
    );
  }

  // elements
  const renderFileItem = (
    file: UploadedFile,
    index: number,
    uploads: ActiveStorageFileUpload[],
  ) => {
    const { canceled, name } = file;
    const progress = getFileProgress(file, uploads);
    const isUploadFinished = progress === 100;
    const progressPercentage = `${progress}%`;

    const renderInfo = (
      <div className="item-info">
        <div className="identifier">
          <Icon name="addFile" color="generalDark" size="de" />
          <span>{name}</span>
        </div>
        <div className="progress">
          <div style={{ width: progressPercentage }} />
        </div>
      </div>
    );

    const renderStatus = () => {
      if (isUploadFinished) {
        return "Done!";
      }

      if (canceled) {
        return "Canceled";
      }

      return progressPercentage;
    };

    const renderAction = () => {
      if (isUploadFinished || canceled) {
        return (
          <div className="action success">
            <Icon name="check" color="generalWhite" size="de" />
          </div>
        );
      }

      return (
        <div className="action" onClick={() => cancelUpload(file)}>
          <Icon name="close" color="generalDark" size="de" />
        </div>
      );
    };

    return (
      <div key={index} className="interactive-direct-upload-process-item">
        {renderInfo}
        <div className={classNames("status", { canceled })}>
          {renderStatus()}
        </div>
        {renderAction()}
      </div>
    );
  };

  const renderUploadProcess = (uploads: ActiveStorageFileUpload[]) => (
    <div className="interactive-direct-upload-process-container">
      <div className="heading">
        <h5>Files Uploading</h5>
      </div>
      {failedFiles.map((file, index) => renderFileItem(file, index, uploads))}
      {failedFilesPresent && <hr />}
      {notFailedFiles.map((file, index) =>
        renderFileItem(file, index, uploads),
      )}
    </div>
  );

  const renderPlaceholder = (handleUpload: RenderProps["handleUpload"]) => (
    <div>
      <img src={fileAddGrayIcon} alt="file-add" />
      <p>
        Drag and drop files here
        <br />- or -
      </p>
      <button>Select {isCreative ? "creatives" : "files"}</button>
      <input
        type="file"
        onChange={(event) => handleUpload(event.currentTarget.files || [])}
        multiple={multiple}
        data-testid="direct-upload-input"
      />
    </div>
  );

  const renderMainContent = ({ handleUpload, uploads }: RenderProps) => (
    <div data-testid="direct-upload-wrapper">
      <div className="drag-drop-wrap">
        <div
          className={classNames("drag-drop-block", {
            "interactive-process": files.length,
          })}
        >
          {files.length
            ? renderUploadProcess(uploads)
            : renderPlaceholder(handleUpload)}
        </div>
      </div>
    </div>
  );

  return (
    <DirectUploadProvider
      onSuccess={onSuccess}
      onError={(e) => {
        mainStore.toast.setInfoText("Error uploading files");
        window.console.error("Error uploading files", e);
      }}
      multiple={multiple}
      render={renderMainContent}
      onBeforeBlobRequest={onBeforeBlobRequest}
      onBeforeStorageRequest={onBeforeStorageRequest}
    />
  );
}

InteractiveDirectUpload.defaultProps = {
  multiple: false,
};

export default observer(InteractiveDirectUpload);
