import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { useAddTextareaHeightBehavior } from "../../detailView/helpers";

type ScoringDefinitionsCellProps = {
  onChange: (value: string) => void;
  width: number | string;
  initialValue?: string;
  isUserEditable?: boolean;
};

function ScoringDefinitionsCell({
  initialValue,
  isUserEditable,
  width,
  onChange,
}: ScoringDefinitionsCellProps) {
  const textInput = useRef<HTMLTextAreaElement | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState("");
  // @ts-expect-error TS(2345) FIXME: Argument of type 'MutableRefObject<HTMLTextAreaEle... Remove this comment to see the full error message
  const onInput = useAddTextareaHeightBehavior(textInput);

  // Effects
  useEffect(() => {
    setValue(initialValue || "");
  }, [initialValue]);

  const style = { width };
  const liClassNames = classNames("cell input-cell", {
    active: isActive,
    "read-only pointer-events-none": !isUserEditable,
  });

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      textInput.current?.blur();
    }
  };

  const handleClick = () => {
    textInput.current?.focus();
  };

  const onFocus = () => {
    setIsActive(true);
  };

  const onBlur = () => {
    setIsActive(false);
    persistData();
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const persistData = () => {
    if (value !== initialValue) {
      onChange(value);
    }
  };
  return (
    <li style={style} className={liClassNames} onClick={handleClick}>
      <div className="cell-content">
        <textarea
          ref={textInput}
          placeholder="Type Here"
          value={value}
          onChange={handleChange}
          onInput={onInput}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete="off"
          spellCheck={false}
          rows={1}
        />
      </div>
    </li>
  );
}

export default ScoringDefinitionsCell;
