import { observer } from "mobx-react";
import React from "react";

import AttestationsDetails from "./AttestationsDetails";
import EditAttestationsList from "./EditAttestationsList";
import ShareAttestations from "./ShareAttestations";

type Props = {
  emailSlideClose?: () => void;
  recordVersionID: number;
  menu?: string;
  recordVersionName?: string;
};

function AttestationsMenu({ menu = "share", ...props }: Props) {
  if (menu === "share") {
    return <ShareAttestations {...props} />;
  }
  if (menu === "details") {
    return <AttestationsDetails {...props} />;
  }
  return <EditAttestationsList {...props} />;
}

export default observer(AttestationsMenu);
