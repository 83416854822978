import "./styles.scss";

import React, { ReactNode } from "react";
import { Link, useParams } from "react-router-dom";

import { Button, Flex, Typography } from "@/components/Elements";
import { ModuleNotificationParams } from "@/features/notifications/types/types";

type MessageGroup =
  | {
      title: string;
      description?: string;
      addButtonText: string;
      addButtonLink: string;
      addButtonClick?: never;
      initialStateDescription: string;
      children?: ReactNode;
    }
  | {
      title: string;
      description?: string;
      addButtonText: string;
      addButtonLink?: never;
      addButtonClick(): void;
      initialStateDescription: string;
      children?: ReactNode;
    };

export function MessageGroup(props: MessageGroup) {
  const params = useParams<ModuleNotificationParams>();

  return (
    <Flex column rowGap={8} className="message-section">
      <Flex justifySpaceBetween alignCenter>
        <Flex column rowGap={2}>
          <Typography
            label={props.title}
            color="generalMidnightDark"
            weight="semiBold"
            size="lg"
          />
          <Typography label={props.description} color="extrasBlueGrayDarker" />
        </Flex>
        {props.addButtonLink ? (
          <Link
            to={{
              pathname: props.addButtonLink,
              state: {
                fromAllTab: !params.tab,
              },
            }}
          >
            <Button label={props.addButtonText} />
          </Link>
        ) : (
          <Button label={props.addButtonText} onClick={props.addButtonClick} />
        )}
      </Flex>
      {props.children || (
        <Flex
          column
          justifyCenter
          alignCenter
          className="initial-state-description"
        >
          <Typography
            size="sm"
            weight="semiBold"
            color="extrasBlueGrayDarker"
            label={props.initialStateDescription}
          />
        </Flex>
      )}
    </Flex>
  );
}
