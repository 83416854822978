import { PreviewTableColumn } from "@themis/ui";
import React from "react";

import { Task } from "@/api";

import AssociatedRecordCell from "../components/old-table/AssociatedRecordCell";
import { DueDateCell } from "../components/old-table/DueDateCell";
import NameCell from "../components/old-table/NameCell";
import TaskActionsCell from "../components/old-table/TaskActionsCell";
import TaskCheckboxCell from "../components/old-table/TaskCheckboxCell";
import UserCell from "../components/old-table/UserCell";
import UsersCell from "../components/old-table/UsersCell";
import WorkspaceCell from "../components/old-table/WorkspaceCell";
import { TaskStatus } from "./status";

function NotAnyCell() {
  return <span className="tw-font-semibold tw-text-neutral-200">N/A</span>;
}

const myTasksTableColumns: Record<
  | keyof Pick<
      Task,
      | "name"
      | "assignee_id"
      | "due_date"
      | "status"
      | "taskables"
      | "collaborator_ids"
      | "workspace_id"
    >
  | "checkbox"
  | "actions",
  PreviewTableColumn<Task>
> = {
  checkbox: {
    title: "",
    type: "readOnly",
    width: 44,
    Component: (task) => <TaskCheckboxCell task={task} />,
  },
  name: {
    key: "name",
    title: "Name",
    type: "readOnly",
    width: 300,
    Component: (task) => <NameCell task={task} />,
  },
  assignee_id: {
    key: "assignee_id",
    title: "Assigned to",
    type: "readOnly",
    width: 150,
    Component: (task) =>
      task.assignee_id ? (
        <UserCell userId={task.assignee_id} />
      ) : (
        <NotAnyCell />
      ),
  },
  collaborator_ids: {
    title: "Collaborators",
    type: "readOnly",
    width: 150,
    Component: (task) =>
      task.collaborator_ids.length ? (
        <UsersCell userIds={task.collaborator_ids} />
      ) : (
        <NotAnyCell />
      ),
  },
  due_date: {
    key: "due_date",
    title: "Due Date",
    type: "readOnly",
    width: 150,
    Component: (task) => <DueDateCell dueDate={task.due_date} />,
  },
  status: {
    key: "status",
    title: "Status",
    type: "readOnly",
    width: 150,
    Component: (task) => TaskStatus[task.status].Component(),
  },
  workspace_id: {
    key: "workspace_id",
    title: "Workspace",
    type: "readOnly",
    width: 200,
    Component: (task) =>
      task.workspace_id ? (
        <WorkspaceCell workspaceId={task.workspace_id} />
      ) : (
        <NotAnyCell />
      ),
  },
  taskables: {
    title: "Associated Records",
    type: "readOnly",
    width: 225,
    Component: (task) => <AssociatedRecordCell task={task} />,
  },
  actions: {
    type: "actions",
    Component: (task) => <TaskActionsCell task={task} />,
  },
} as const;

export const assignedToMeTableColumns: PreviewTableColumn<Task>[] = [
  myTasksTableColumns.checkbox,
  myTasksTableColumns.name,
  myTasksTableColumns.assignee_id,
  myTasksTableColumns.collaborator_ids,
  myTasksTableColumns.due_date,
  myTasksTableColumns.status,
  myTasksTableColumns.workspace_id,
  myTasksTableColumns.taskables,
  myTasksTableColumns.actions,
];

export const assignedToOthersTableColumns: PreviewTableColumn<Task>[] = [
  myTasksTableColumns.checkbox,
  myTasksTableColumns.name,
  myTasksTableColumns.assignee_id,
  myTasksTableColumns.collaborator_ids,
  myTasksTableColumns.due_date,
  myTasksTableColumns.status,
  myTasksTableColumns.workspace_id,
  myTasksTableColumns.taskables,
  myTasksTableColumns.actions,
];

export const createdByMeTableColumns: PreviewTableColumn<Task>[] = [
  myTasksTableColumns.checkbox,
  myTasksTableColumns.name,
  myTasksTableColumns.assignee_id,
  myTasksTableColumns.collaborator_ids,
  myTasksTableColumns.due_date,
  myTasksTableColumns.status,
  myTasksTableColumns.workspace_id,
  myTasksTableColumns.taskables,
  myTasksTableColumns.actions,
];

export const allTableColumns: PreviewTableColumn<Task>[] = [
  myTasksTableColumns.checkbox,
  myTasksTableColumns.name,
  myTasksTableColumns.assignee_id,
  myTasksTableColumns.collaborator_ids,
  myTasksTableColumns.due_date,
  myTasksTableColumns.status,
  myTasksTableColumns.workspace_id,
  myTasksTableColumns.taskables,
  myTasksTableColumns.actions,
];

export const archivedTableColumns: PreviewTableColumn<Task>[] = [
  myTasksTableColumns.name,
  myTasksTableColumns.assignee_id,
  myTasksTableColumns.collaborator_ids,
  myTasksTableColumns.due_date,
  myTasksTableColumns.status,
  myTasksTableColumns.workspace_id,
  myTasksTableColumns.taskables,
  myTasksTableColumns.actions,
];

export const projectTasksTableColumns: PreviewTableColumn<Task>[] = [
  myTasksTableColumns.checkbox,
  myTasksTableColumns.name,
  myTasksTableColumns.assignee_id,
  myTasksTableColumns.collaborator_ids,
  myTasksTableColumns.due_date,
  myTasksTableColumns.status,
  myTasksTableColumns.taskables,
  myTasksTableColumns.actions,
];
