import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import UsersSelect from "../cell-type/users-select/UsersSelect";

type Props = {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  recordVersionID: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleRemoveSponsor?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSelectSponsor?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialSelectedIDs?: any[];
  locked?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sponsorIDs?: any[];
  selectedIDs?: number[];
  withContacts?: boolean;
  hasReadWriteAccess?: boolean;
  onDataChange?: (...args: unknown[]) => void;
};

function ViewUsersSelect({
  recordVersion,
  fieldName,
  recordVersionID,
  withContacts,
  locked,
  initialSelectedIDs,
  sponsorIDs,
  selectedIDs: selectedUserIDs,
  handleSelectSponsor,
  handleRemoveSponsor,
  hasReadWriteAccess,
  onDataChange,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const field = mainStore.fields.list.find(
    (subField) => subField.name === fieldName,
  );
  const value = mainStore.avroSchemas.valueForField(
    fieldName,
    recordVersion?.data,
  );
  const cellError = mainStore.recordVersions.errorsForField(
    recordVersion?.id,
    fieldName,
  );
  const hasErrors = Boolean(cellError);

  const columnTitle =
    mainStore.avroSchemas.displayNameForField(fieldName) || "";
  const isMultiselect =
    mainStore.avroSchemas.isMultiselectField(fieldName) ||
    !!handleSelectSponsor;
  const isUserEditable = field?.is_user_editable || !!handleSelectSponsor;

  // UGLY: Override for com.askthemis.types.v1.tag_verifier
  let selectedIDs = [];
  if (selectedUserIDs) {
    selectedIDs = selectedUserIDs;
  } else if (Array.isArray(value)) {
    selectedIDs = value;
  } else if (typeof value === "object" && value !== null) {
    const verifierID = value.verifier_id;
    if (verifierID) {
      selectedIDs = [verifierID];
    }
  }

  return (
    <UsersSelect
      fieldName={fieldName}
      columnTitle={columnTitle}
      hasErrors={hasErrors}
      errorMessage={cellError?.description}
      isUserEditable={isUserEditable}
      recordVersionID={recordVersionID}
      selectedIDs={sponsorIDs || selectedIDs || initialSelectedIDs}
      singleSelection={!isMultiselect}
      tableName={recordVersion?.table_name || ""}
      width="100%"
      reviews={recordVersion?.reviews}
      withContacts={withContacts}
      hasErrorClass="detail-view-has-errors"
      locked={locked}
      handleSelectSponsor={handleSelectSponsor}
      handleRemoveSponsor={handleRemoveSponsor}
      hasReadWriteAccess={hasReadWriteAccess}
      onDataChange={onDataChange}
    />
  );
}

ViewUsersSelect.defaultProps = {
  initialSelectedIDs: [],
  hasReadWriteAccess: false,
};

export default observer(ViewUsersSelect);
