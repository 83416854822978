import "./selectable-question-type.scss";

import classNames from "classnames";
import React from "react";
import { useDrag } from "react-dnd";

import { Icon, Typography } from "../../../../../../../components/Elements";
import { Question } from "../../../../../types/questionnaire";
import { SelectableQuestion } from "../SelectableQuestionTypes";

type SelectableQuestionTypeProps = {
  question: SelectableQuestion;
  onAddQuestion: (question: Question) => void;
};

function SelectableQuestionType(props: SelectableQuestionTypeProps) {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "question",
      item: props.question.name,
      end: (item, monitor) => {
        if (item && monitor.getDropResult()) {
          props.onAddQuestion(props.question.questionDefaults());
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props.onAddQuestion],
  );

  return (
    <div
      ref={drag}
      key={props.question.type}
      className={classNames(
        "selectable-question-type",
        isDragging && "is-dragging",
      )}
    >
      <Icon name={props.question.icon} color="brandingDimViolet" />
      <Typography
        label={props.question.name}
        color="brandingDimViolet"
        weight="semiBold"
      />
      <Icon
        name="reorder"
        color="brandingDimViolet"
        className="selectable-question-type__grab"
      />
    </div>
  );
}

export default SelectableQuestionType;
