import classNames from "classnames";
import React, { useEffect, useState } from "react";

import AssigningDrawer from "./AssigningDrawer";

export const getPayload = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedPermissionSet' implicitly... Remove this comment to see the full error message
  selectedPermissionSet,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedWorkspaces' implicitly ha... Remove this comment to see the full error message
  selectedWorkspaces,
  // @ts-expect-error TS(7031) FIXME: Binding element 'selectedModules' implicitly has a... Remove this comment to see the full error message
  selectedModules,
}) => {
  const payload = {
    permission_set_id:
      selectedPermissionSet.parent_permission_id || selectedPermissionSet.id,
  };

  if (selectedPermissionSet.permission_type === "workspace_module") {
    // @ts-expect-error TS(2339) FIXME: Property 'workspace_ids' does not exist on type '{... Remove this comment to see the full error message
    payload.workspace_ids = selectedWorkspaces.map((workspace) => workspace.id);
  }

  if (
    selectedPermissionSet.permission_type === "workspace_module" &&
    !selectedPermissionSet.locked_modules
  ) {
    // @ts-expect-error TS(2339) FIXME: Property 'module_identifiers' does not exist on ty... Remove this comment to see the full error message
    payload.module_identifiers = selectedModules.map((module) => module.id);
  }

  return payload;
};

type RolesCreateProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modules?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  permissionSets?: any[];
  requireInternalWorkspace?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workspaces?: any[];
  setHasRoleName?: React.Dispatch<React.SetStateAction<boolean>>;
};

function RolesCreate({
  permissionSets,
  workspaces,
  modules,
  requireInternalWorkspace,
  onChange,
  setHasRoleName,
}: RolesCreateProps) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [roleName, setRoleName] = useState("");

  // Effects
  useEffect(() => {
    if (roleName) {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setHasRoleName(!!roleName);
    }
  }, [roleName]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'selection' implicitly has an 'any' type... Remove this comment to see the full error message
  const handleSubmit = (selection) => {
    const payload = getPayload(selection);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onChange(payload);
    setShowDrawer(false);
  };

  return (
    <>
      <div
        className={classNames("roles-placeholder", {
          active: showDrawer,
        })}
        data-testid="roles-placeholder"
        onClick={() => setShowDrawer(true)}
      >
        {roleName || "- Select -"}
      </div>

      {showDrawer && (
        <AssigningDrawer
          workspaces={workspaces}
          modules={modules}
          permissionSets={permissionSets}
          requireInternalWorkspace={requireInternalWorkspace}
          onRoleNameChange={setRoleName}
          onClose={() => setShowDrawer(false)}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
}

RolesCreate.defaultProps = {
  workspaces: [],
  modules: [],
  permissionSets: [],
  requireInternalWorkspace: false,
  onChange: () => {},
};

export default RolesCreate;
