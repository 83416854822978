import "./questionnaire-form-guard.scss";

import axios from "axios";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { QuestionnaireFormApi } from "@/api/legacy/risk-assessment/QuestionnaireFormApi";
import { Button, Typography } from "@/components/Elements";
import PageErroredReload from "@/components/errors/PageErroredReload";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";

import { QuestionnaireFormRead } from "../../../types/questionnaire-form";
import QuestionnaireForm from "./QuestionnaireForm";

function QuestionnaireFormGuard() {
  const mainStore = useMainStore();
  const { questionnaireExternalId } = useParams<{
    questionnaireExternalId: string;
  }>();
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState("");
  const [questionnaireForm, setQuestionnaireForm] =
    useState<QuestionnaireFormRead | null>(null);

  useEffect(() => {
    if (!questionnaireExternalId) {
      return;
    }

    loadQuestionnaireForm();
  }, [questionnaireExternalId]);

  const loadQuestionnaireForm = () => {
    setIsLoading(true);

    QuestionnaireFormApi.get(questionnaireExternalId, password)
      .then((questionnaire) => {
        setQuestionnaireForm(questionnaire);
      })
      .catch((err) => {
        if (axios.isAxiosError(err)) {
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          if (err.response.status === 401) {
            if (password) {
              mainStore.toast.setErrorText("Incorrect password");
            }
            return;
          }

          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          if (err.response.status === 404) {
            return setLoadingError(
              "The questionnaire either doesn't exist or the link has expired.",
            );
          }
        }

        setLoadingError(
          "There was an issue loading the questionnaire. Please refresh to try again.",
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (questionnaireForm) {
    return (
      <QuestionnaireForm
        questionnaireForm={questionnaireForm}
        externalId={questionnaireExternalId}
      />
    );
  }

  if (loadingError) {
    return <PageErroredReload loadingError={loadingError} />;
  }

  return (
    <div className="questionnaire-form-guard">
      <div className="questionnaire-form-guard__content">
        {isLoading ? (
          <Loading loadingLayout="small-table" />
        ) : (
          <>
            <Typography
              label="This form is password protected. Please enter your password to proceed. If you did not recieve a password, you can reach out to your contact who shared this form link."
              color="generalMidnightDark"
              textAlignCenter
            />
            <div className="questionnaire-form-guard__authentication">
              <div>
                <Typography label="Password" color="generalMidnightDark" />
                <span className="form-input">
                  <input
                    type="text"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) =>
                      e.key === "Enter" && password && loadQuestionnaireForm()
                    }
                  />
                </span>
              </div>
              <Button
                label="Submit"
                onClick={loadQuestionnaireForm}
                disabled={!password}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default observer(QuestionnaireFormGuard);
