import React from "react";
import {
  Bar,
  ComposedChart,
  LabelList,
  Line,
  ResponsiveContainer,
  XAxis,
} from "recharts";

const labelWidth = 32;
const labelHeight = 24;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomAxisTick = (props: any) => {
  const { x, y, payload, isMultiXLines } = props;
  const name: string = payload?.value?.toString() || "";
  const words = isMultiXLines ? name.split("-") : [name];

  return (
    <text
      x={x}
      y={y + 8}
      width={124}
      textAnchor="middle"
      fill="#353549"
      fontFamily="Poppins"
      fontSize={10}
      fontWeight={500}
    >
      {words.map((line: string, index: number) => (
        <tspan x={x} dy={index * 14} key={index}>
          {line}
        </tspan>
      ))}
    </text>
  );
};

export type StackedBarChartProps = {
  wireKey?: "none" | string;
  data: {
    name: string | number;
    total: number;
    [key: string]: string | number;
  }[];
  keys: string[];
  colors: { key: string; color: string }[];
  isMultiXLines?: boolean;
};

export const StackedBarChart = ({
  wireKey = "none",
  data,
  keys,
  colors,
  isMultiXLines,
}: StackedBarChartProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const FloatingValueLabel = (props: any) => {
    const { x, y, width, name } = props;

    const barValue = data.find((d) => d.name === name)?.total;
    if (!barValue) {
      return null;
    }
    return (
      <g>
        <rect
          x={x - 4}
          y={y - labelWidth}
          rx={4}
          ry={4}
          width={labelWidth}
          height={labelHeight}
          fill="#F6F6F9"
        />
        <text
          x={x + width / 2}
          y={y - labelHeight / 2 - 4}
          fill="#353549"
          textAnchor="middle"
          fontFamily="Poppins"
          fontWeight={600}
          fontSize={12}
        >
          {barValue}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={data}
        margin={{
          top: 40,
        }}
        // barSize={24}
      >
        <XAxis
          interval={0}
          dataKey="name"
          tickLine={false}
          tick={<CustomAxisTick isMultiXLines={isMultiXLines} />}
          axisLine={{
            stroke: "#E4E4EB",
            strokeWidth: 1,
          }}
        />
        {wireKey !== "none" && (
          <Line
            type="linear"
            dataKey={wireKey}
            stroke="#313181"
            strokeWidth={2}
            dot={false}
          />
        )}
        {keys
          .map((key: string, index: number) =>
            index === 0 ? (
              <Bar
                key={`bar-${key}`}
                dataKey={key}
                stackId="a"
                barSize={24}
                fill={colors.find((c) => c.key === key)?.color}
              >
                <LabelList dataKey="total" content={FloatingValueLabel} />
              </Bar>
            ) : (
              <Bar
                key={`bar-${key}`}
                dataKey={key}
                stackId="a"
                barSize={24}
                fill={colors.find((c) => c.key === key)?.color}
              />
            ),
          )
          .reverse()}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
