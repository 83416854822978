import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { ConfirmPopup } from "@/components/confirm-popup";
import { Button, Flex, Typography } from "@/components/Elements";
import {
  formatTimeAfterLongDate,
  stringToDateAndTimeSSSZ,
} from "@/components/helpers/DateFormatters";
import { Divider, IconMenu, IconMenuItem } from "@/components/IconMenu";
import UserMentionsInput from "@/components/table/shared/UserMentionsInput";
import { UsersCircle } from "@/components/users-circle";
import { useMainStore } from "@/contexts/Store";
import { UserComment } from "@/stores/types/comment-types";

export type CreativeViewCommentResponseProps = {
  active?: boolean;
  key?: number;
  reply: UserComment;
};

function CreativeViewCommentResponse({
  reply,
  key,
  active,
}: CreativeViewCommentResponseProps) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { taskDetail } = mainStore;

  // State
  const [editInput, setEditInput] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [showDeleteCommentPopup, setShowDeleteCommentPopup] = useState(false);

  // Refs
  const selectedReplyRef = useRef<HTMLDivElement>(null);

  // Effects
  useEffect(() => {
    if (selectedReplyRef.current) {
      selectedReplyRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedReplyRef.current]);

  // Vars
  const formattedCreateDate = formatTimeAfterLongDate(
    stringToDateAndTimeSSSZ(reply.created_at),
    { removeAt: true, monthFormat: "short" },
  );

  function handleOpenEditMode() {
    setEditInput(reply.content);
    setEditMode(true);
  }

  async function handleEditComment(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    event.stopPropagation();
    await mainStore.comments.update(reply.id, {
      content: editInput,
    });
    setEditMode(false);
    setEditInput("");
  }

  function handleDeleteComment() {
    mainStore.comments.delete(reply.id);
  }

  function handleShowDeleteCommentPopup() {
    setShowDeleteCommentPopup(true);
  }

  function handleCopyLink() {
    mainStore.comments.copyCommentLink(reply);
  }

  const renderResponseEditActions = (
    <div className="comment-edit-actions" data-testid="comment-edit-actions">
      <Button
        data-testid="comment-update-confirm"
        onClick={handleEditComment}
        label="Update"
        size="sm"
      />
      <Button
        data-testid="comment-update-cancel"
        onClick={(event) => {
          event.stopPropagation();
          setEditMode(false);
        }}
        label="Cancel"
        theme="tertiary"
        size="sm"
      />
    </div>
  );

  return (
    <div
      className={classNames("comment-reply", { active })}
      key={key}
      data-testid="comment-reply"
      ref={active ? selectedReplyRef : undefined}
    >
      <div className="comments-info" data-testid="comment-reply-info">
        <Flex alignCenter columnGap={8}>
          <UsersCircle
            small
            initials={reply.author.initials}
            iconColorIndex={reply.author.icon_color_index}
          />
          <Flex column>
            <Typography
              label={reply.author.full_name}
              weight="semiBold"
              color="generalMidnightDark"
              size="sm"
            />
            <Typography
              label={formattedCreateDate}
              weight="semiBold"
              color="extrasBlueGrayDarker"
              size="xs"
            />
          </Flex>
        </Flex>
        <div className="comment-actions" data-testid="comment-actions">
          <IconMenu
            triggerTestId="comment-points-trigger"
            containerTestId="cv-comment-response-popup"
            icon="moreHorizontal"
          >
            {reply.user_can_update_content && (
              <>
                <IconMenuItem label="Edit" onClick={handleOpenEditMode} />
                <Divider />
              </>
            )}
            <IconMenuItem label="Copy Link" onClick={handleCopyLink} />
            <IconMenuItem
              label="Create Task"
              onClick={() => taskDetail.openFromComment(reply)}
            />
            {reply.user_can_destroy && (
              <IconMenuItem
                label="Delete"
                onClick={handleShowDeleteCommentPopup}
              />
            )}
          </IconMenu>
          <ConfirmPopup
            title="Delete Comment"
            content="Are you sure you want to remove this comment?"
            showPopup={showDeleteCommentPopup}
            setShowPopup={setShowDeleteCommentPopup}
            onConfirmClick={handleDeleteComment}
          />
        </div>
      </div>
      <UserMentionsInput
        singleLine={false}
        editable={editMode}
        content={editMode ? editInput : reply.content}
        onClick={(event) => event.stopPropagation()}
        onChange={(e) => setEditInput(e.target.value)}
        style={{ borderColor: "#6D71F9" }}
        dataTestID="comment-sidebar-input-edit"
      />
      {editMode && renderResponseEditActions}
    </div>
  );
}

export default CreativeViewCommentResponse;
