import { Project } from "@/api";
import useFilterSort from "@/hooks/useFilterSort";

import { projectsFilterData } from "../config/projectsFilterData";

export function useProjectsFilterSort(
  params?: Omit<Parameters<typeof useFilterSort<Project>>[0], "fieldsData">,
) {
  return useFilterSort<Project>({ fieldsData: projectsFilterData, ...params });
}
