import React, { PropsWithChildren, useState } from "react";

import { Assignment } from "@/features/risk-assessment/types";

import { FollowUpPage } from "../pages/FollowUpPage";

const CreateFollowUpSidebarContext = React.createContext<{
  openSidebar: (
    questionID: number,
    questionText?: string,
    questionOrder?: string,
    questionReviewers?: Assignment[],
  ) => void;
  closeSidebar: () => void;
}>(undefined!);

function FollowUpSidebarProvider(props: PropsWithChildren) {
  const [questionId, setQuestionId] = useState<number | undefined>();
  const [questionTextEntry, setQuestionTextEntry] = useState<string>("");
  const [questionOrderEntry, setQuestionOrderEntry] = useState<string>("");
  const [questionReviewersEntry, setQuestionReviewersEntry] = useState<
    Assignment[]
  >([]);

  const value = {
    openSidebar: (
      questionID: number,
      questionText?: string,
      questionOrder?: string,
      questionReviewers?: Assignment[],
    ) => {
      setQuestionId(questionID);
      setQuestionTextEntry(questionText || "");
      setQuestionOrderEntry(questionOrder || "");
      setQuestionReviewersEntry(questionReviewers || []);
    },
    closeSidebar: () => {
      setQuestionId(undefined);
      setQuestionTextEntry("");
      setQuestionOrderEntry("");
      setQuestionReviewersEntry([]);
    },
  };

  return (
    <CreateFollowUpSidebarContext.Provider value={value}>
      {props.children}
      {questionId && (
        <FollowUpPage
          questionID={questionId}
          questionText={questionTextEntry}
          questionReviewers={questionReviewersEntry}
          questionOrder={questionOrderEntry}
        />
      )}
    </CreateFollowUpSidebarContext.Provider>
  );
}
const useFollowUpSidebar = () => React.useContext(CreateFollowUpSidebarContext);

export { FollowUpSidebarProvider, useFollowUpSidebar };
