import classNames from "classnames";
import React from "react";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any[] | any;
  dataTestId?: string;
  extraClassNames?: string;
};

function Table({ children, extraClassNames, dataTestId }: Props) {
  return (
    <div
      className={classNames("table-wrap", extraClassNames)}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
}

export default Table;
