import { Avatar } from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";

import { useCompanyUsers } from "@/api/queries/users";
import { useMainStore } from "@/contexts/Store";

function UserCell({ userId }: { userId: number }) {
  const {
    context: { companyID },
  } = useMainStore();
  const { data } = useCompanyUsers(Number(companyID), {
    view: "all",
  });

  const user = data?.data?.find((u) => u.id === userId) || {
    id: 0,
    icon_color_index: 0,
    full_name: "Not Found",
    initials: "NF",
    is_active: false,
  };

  return (
    <div className="tw-flex tw-items-center tw-gap-1.5 tw-overflow-hidden tw-font-semibold">
      <Avatar
        size="md"
        colorIndex={user.icon_color_index}
        variant={user.is_active ? undefined : "pending"}
      >
        {user.initials}
      </Avatar>
      <p className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-font-semibold">
        {user.full_name}
      </p>
    </div>
  );
}

export default observer(UserCell);
