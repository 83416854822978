import React from "react";

import { MiniTag } from "@/components/Elements";

import { QuestionnaireStatus } from "../../../types/questionnaire";
import ProgressBreadcrumbs from "./progress-breadcrumbs/ProgressBreadcrumbs";

type Props = {
  questionnaireStatus: QuestionnaireStatus;
};

function QuestionnaireProgress(props: Props) {
  return (
    <ProgressBreadcrumbs>
      <MiniTag
        label="Create"
        theme={
          props.questionnaireStatus === QuestionnaireStatus.NOT_STARTED
            ? "cherry"
            : "gray"
        }
        fontSize="de"
      />
      <MiniTag
        label="In Progress"
        theme={
          props.questionnaireStatus === QuestionnaireStatus.INCOMPLETE
            ? "cherry"
            : "gray"
        }
        fontSize="de"
      />
      <MiniTag
        label="In Review"
        theme={
          props.questionnaireStatus === QuestionnaireStatus.IN_REVIEW ||
          props.questionnaireStatus === QuestionnaireStatus.READY_FOR_REVIEW
            ? "cherry"
            : "gray"
        }
        fontSize="de"
      />
      <MiniTag
        label="Complete"
        theme={
          props.questionnaireStatus === QuestionnaireStatus.FINALIZED
            ? "cherry"
            : "gray"
        }
        fontSize="de"
      />
    </ProgressBreadcrumbs>
  );
}

export default QuestionnaireProgress;
