import _ from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";

import iconMap from "@/components/helpers/moduleIcons";
import { BUILTIN_THEMIS_MODULES } from "@/components/settings/Modules/TableContents";
import { useMainStore } from "@/contexts/Store";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import DefaultIcon from "../../../images/setting-workspace/icon/Default Icon - V1.svg";
import { nameFromModuleWorkspace } from "../../helpers/nameForThemisModuleIdentifier";

type Props = {
  selectedModules?: ModuleIdentifier[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedModules?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setWorkspaceStep?: (...args: any[]) => any;
};

const SelectCollaborativeWorkspaceModules = ({
  selectedModules,
  setSelectedModules,
  setWorkspaceStep,
}: Props) => {
  const mainStore = useMainStore();
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;

  const moduleIdentifierOptions: ModuleIdentifier[] = [
    "policy",
    "procedures",
    "documents",
    "marketing",
    "audits",
    "vendor_due_diligence",
    "complaints",
    "issue_management",
    "qa_tests_development",
    "new_product_approval",
    "risk_register",
    "training",
    "key_risk_indicators",
  ];

  function onModulesChange(event: React.ChangeEvent<HTMLInputElement>) {
    const themisModuleIdentifier = event.target.name;

    if (event.target.checked) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      if (!selectedModules.includes(themisModuleIdentifier)) {
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        setSelectedModules([...selectedModules, event.target.name]);
      }
    } else {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const modulesExcludingSelected = selectedModules.filter(
        (selectedModule) => selectedModule !== themisModuleIdentifier,
      );
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setSelectedModules(modulesExcludingSelected);
    }
  }

  const renderModuleRows = moduleIdentifierOptions.map((moduleIdentifier) => {
    return (
      <div
        className="internal-modules-block"
        key={moduleIdentifier}
        data-testid={`${_.kebabCase(moduleIdentifier)}-block`}
      >
        <img
          className="w-48"
          src={iconMap[moduleIdentifier]}
          alt={DefaultIcon}
        />
        <h4>{nameFromModuleWorkspace(moduleIdentifier, moduleWorkspaces)}</h4>
        {BUILTIN_THEMIS_MODULES.includes(moduleIdentifier) ? (
          <h5 className="built-label">Built In Module</h5>
        ) : (
          <div className="toggle-checkbox">
            <label className="switch">
              <input
                type="checkbox"
                name={moduleIdentifier}
                onChange={onModulesChange}
                data-testid={`${_.kebabCase(moduleIdentifier)}-checkbox`}
              />
              <span className="slider" />
            </label>
          </div>
        )}
      </div>
    );
  });

  return (
    <>
      <div className="setting-workspace-progressbar">
        <div className="progressbar">
          <CircularProgressbar value={50} strokeWidth={15} />
        </div>
        <p>Select Internal Modules</p>
      </div>
      <p className="setup-workspace-text">
        Add any of the modules below to your collaborative
        <br /> workspace
      </p>
      <div className="internal-modules-wrap">{renderModuleRows}</div>

      <div className="workspace-buttons">
        <button
          className="back"
          // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          onClick={() => setWorkspaceStep(2)}
          data-testid="back-button"
        >
          Back
        </button>
        <button
          className="active"
          // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          onClick={() => setWorkspaceStep(4)}
          data-testid="next-button"
        >
          Continue
        </button>
      </div>
    </>
  );
};

export default observer(SelectCollaborativeWorkspaceModules);
