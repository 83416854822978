import classNames from "classnames";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import Archive from "@/assets/svgs/archive";
import { useMainStore } from "@/contexts/Store";
import replyIcon from "@/images/table-image/icon/reply.svg";

type Props = {
  archived: boolean;
  logo?: string;
  showInbound: boolean;
  workspaceID: number;
  workspaceName: string;
  switchWorkspace(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    workspaceID: number,
  ): void;
};

function WorkspaceNavigationPopup({
  archived,
  logo,
  showInbound,
  workspaceID,
  workspaceName,
  switchWorkspace,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);

  const { active_workspace: activeWorkspace } = mainStore.users.user;

  const switchWorkspacePopup = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    switchWorkspace(event, workspaceID);
    setShowPopup(false);
  };

  const disabledWorkspacePopup = workspaceID === activeWorkspace?.id;

  const renderTrigger = (
    <div
      onClick={switchWorkspacePopup}
      className={classNames("workspace-navigation-element", {
        active: disabledWorkspacePopup,
        "tooltip-active": showPopup,
      })}
      data-testid="workspace-nav-icon"
    >
      {showInbound && (
        <div className="workspace-navigation-indicator">
          <img className="inbound" src={replyIcon} alt="external-icon" />
        </div>
      )}
      {archived && (
        <div className="workspace-navigation-indicator workspace-navigation-indicator--archived">
          <Archive />
        </div>
      )}
      <img
        className="w-55"
        src={logo}
        data-testid={`iw-cw-icon-${workspaceID}`}
        alt="image-icon"
      />
    </div>
  );

  return (
    <Popup
      position="right center"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
      keepTooltipInside
      disabled={disabledWorkspacePopup}
      arrow={false}
      offsetX={12}
      on="hover"
    >
      <div className="workspace-navigation-tooltip">{workspaceName}</div>
    </Popup>
  );
}

export default WorkspaceNavigationPopup;
