import classNames from "classnames";
import React from "react";

import { TypographyProps } from "./typings";

const Typography: React.FC<TypographyProps> = ({
  className = "",
  color,
  label,
  children,
  size = "sm",
  type = "div",
  weight = "medium",
  textAlignCenter = false,
  ...rest
}) => {
  const typoClassName = classNames(
    "themisTyphography",
    `themisTyphography__${size}`,
    `themisTyphography__${weight}`,
    color && `themisColor__${color}`,
    textAlignCenter && "themisTypeography--text-align-center",
    className,
  );

  if (!label && !children) {
    return null;
  }

  return React.createElement(
    type || "div",
    { className: typoClassName, ...rest },
    label || children,
  );
};

Typography.displayName = "Typography";

export default Typography;
