import { HeaderTabs } from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

type Props = {
  moduleIdentifier: string;
};

function ManageApprovalsStepsNavigation({ moduleIdentifier }: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  const TABS = [
    {
      name: "Stepped Approval",
      key: "manage-stepped-approval",
      value: `/workspaces/${workspaceID}/modules/${moduleIdentifier}/manage-approvals-steps`,
    },
  ];

  return (
    <div
      className="tw-flex tw-h-11 tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-neutral-100 tw-px-4"
      data-testid="stepped-approval-navigation"
    >
      <HeaderTabs tabs={TABS} selectedTab={TABS[0].value} isLink />
    </div>
  );
}

export default observer(ManageApprovalsStepsNavigation);
