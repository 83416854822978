import {
  AnswerRead,
  AnswerReview,
  AnswerReviewRequest,
} from "@/features/risk-assessment";

import api from "../api";

export const AnswersAPI = {
  // PUT /api/react/workspaces/:workspace_id/answers/:id
  async review(
    workspaceId: number,
    answerId: number,
    review: AnswerReview,
  ): Promise<AnswerRead> {
    const response = await api.put<
      AnswerReviewRequest,
      { data: { answer: AnswerRead } }
    >(`/workspaces/${workspaceId}/answers/${answerId}`, {
      answer: {
        answer_review_attributes: review,
      },
    });

    return response.data.answer;
  },

  async approve(workspaceId: number, answerId: number): Promise<AnswerRead> {
    const response = await api.post<
      AnswerReviewRequest,
      { data: { answer: AnswerRead } }
    >(`/workspaces/${workspaceId}/answers/${answerId}/approve`);

    return response.data.answer;
  },

  async approveAll(
    workspaceId: number,
    questionnaireId: number,
  ): Promise<boolean> {
    const response = await api.post<null, { success: boolean }>(
      `/workspaces/${workspaceId}/risk_assessment/answers/approve_all`,
      {
        questionnaire_id: questionnaireId,
      },
    );

    return response.success;
  },

  async unapprove(workspaceId: number, answerId: number): Promise<AnswerRead> {
    const response = await api.delete<
      AnswerReviewRequest,
      { data: { answer: AnswerRead } }
    >(`/workspaces/${workspaceId}/answers/${answerId}/unapprove`);

    return response.data.answer;
  },
};
