import axios from "axios";
import dayjs from "dayjs";
import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import { MainStore } from "../Main";
import { IndexParams, ModuleStore } from "../types/module-store-types";
import { RiskAssessment, Vendor } from "../types/vendor-types";

export default class Vendors implements ModuleStore {
  mainStore: MainStore;

  // Observable objects
  data = {};
  currentVendor: Partial<Vendor> = {};
  currentRiskAssessment: RiskAssessment = {};
  templates = [];
  defaultQuestions = [];
  sharedChecklists = null;

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      templates: observable,
      defaultQuestions: observable,
      currentVendor: observable,
      currentRiskAssessment: observable,
      sharedChecklists: observable,

      setData: action,
      setTemplates: action,
      setCurrentVendor: action,
      setCurrentRiskAssessment: action,
      setDefaultQuestions: action,
      setSharedChecklists: action,
      setRecordVersions: action,
      setRiskAssessmentTemplates: action,
      setDataTemplates: action,
    });

    this.mainStore = mainStore;
  }

  async index({ workspaceID, sortParams, tableFilters = [] }: IndexParams) {
    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        table_filters: tableFilters,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
      };

      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/workspaces/${workspaceID}/vendors`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return;
      }

      this.setData(response.data);
      this.mainStore.fields.setList(response.data.fields);
    } catch (error) {
      window.console.log(`"VDD#Index for Company" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'vendorID' implicitly has an 'any' type.
  async getVendorById(vendorID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/vendors/${vendorID}`,
        headers: this.mainStore.getHeaders(),
      });

      this.setCurrentVendor(response.data.record_version);
    } catch (error) {
      window.console.log(`"VDD#getVendorById for Vendor" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async create(workspaceID, params, setCells = false) {
    const data = { vendor: params };
    if (setCells) {
      // @ts-expect-error TS(2339) FIXME: Property 'set_cells' does not exist on type '{ ven... Remove this comment to see the full error message
      data.set_cells = true;
    }

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/workspaces/${workspaceID}/vendors`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      if (axios.isAxiosError(response)) {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        return response.response.data;
      }

      this.setRecordVersions([
        response.data.record_version,
        // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
        ...this.data.record_versions,
      ]);
      await this.mainStore.vendors.index({ workspaceID });
      return response.data;
    } catch (error) {
      window.console.log(`"VDD#create" error ${error}`);
      return error;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'vendorID' implicitly has an 'any' type.
  async update(vendorID, params, specificTitle = null) {
    const data = {
      vendor: params,
      specific_title: specificTitle,
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/vendors/${vendorID}`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      const { record_version: recordVersion } = response.data;

      this.setCurrentVendor(recordVersion);
      this.setRecordVersions(
        // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
        this.data.record_versions?.map(
          // @ts-expect-error TS(7006) FIXME: Parameter 'rv' implicitly has an 'any' type.
          (rv) => (rv.id === recordVersion.id ? recordVersion : rv),
        ),
      );

      return recordVersion;
    } catch (error) {
      window.console.log(`"VDD#update" error ${error}`);
      return null;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'vendorID' implicitly has an 'any' type.
  async delete(vendorID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/vendors/${vendorID}`,
        headers: this.mainStore.getHeaders(),
      });
      this.setRecordVersions(
        // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
        this.data.record_versions?.filter((item) => item.id !== vendorID),
        false,
      );
    } catch (error) {
      window.console.log(`"VDD#delete" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'vendorID' implicitly has an 'any' type.
  async updateVendorStatus(vendorID, status, deactivateDate = null) {
    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/vendors/${vendorID}/update_status`,
        headers: this.mainStore.getHeaders(),
        data: { status, deactivate_date: deactivateDate },
      });
    } catch (error) {
      window.console.log(`"VDD#updateVendorStatus" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'vendorID' implicitly has an 'any' type.
  async generateAndOpenPDFReport(vendorID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/vendors/${vendorID}/pdf_report`,
        headers: this.mainStore.getHeaders(),
        responseType: "blob",
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        const text = await response.response.data.text();
        const errors = JSON.parse(text).errors?.join(",");
        this.mainStore.toast.setErrorText(errors);
        return;
      }

      const link = document.createElement("a");
      link.href = URL.createObjectURL(response.data);
      link.target = "_blank";
      link.click();
    } catch (error) {
      window.console.log(
        `"VDD#generateAndOpenPDFReport for Vendor" error ${error}`,
      );
    }
  }

  // checklists
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async fetchChecklist(moduleWorkspaceID, templateID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/templates/${templateID}`,
        headers: this.mainStore.getHeaders(),
      });

      return response.data.template;
    } catch (error) {
      window.console.log(`"VDD#fetchChecklist" error ${error}`);
    }
  }

  async copyTemplate(
    moduleWorkspaceID: number,
    templateID: number,
    copiedWSIDs: number[],
  ) {
    await legacyApi({
      method: "POST",
      url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/templates/${templateID}/copy`,
      headers: this.mainStore.getHeaders(),
      data: {
        vendor_checklist: {
          copied_workspace_ids: copiedWSIDs,
        },
      },
    });
  }

  // AWS rule AllowLargeRequests was required to support lists longer than 300 items
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async updateChecklist(moduleWorkspaceID, templateID, params) {
    const data = {
      id: templateID,
      template: params,
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/template`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      return response.data.template;
    } catch (error) {
      window.console.log(`"VDD#updateChecklist" error ${error}`);
    }
  }

  // AWS rule AllowLargeRequests was required to support lists longer than 300 items
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async createChecklist(moduleWorkspaceID, params) {
    const data = { template: params };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/template`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setDataTemplates([
        response.data.vendor_template,
        // @ts-expect-error TS(2339) FIXME: Property 'templates' does not exist on type '{}'.
        ...this.data.templates,
      ]);
      return response.data.vendor_template;
    } catch (error) {
      window.console.log(`"VDD#createChecklist" error ${error}`);
    }
  }

  async deleteChecklist(
    // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
    moduleWorkspaceID,
    // @ts-expect-error TS(7006) FIXME: Parameter 'checklistID' implicitly has an 'any' ty... Remove this comment to see the full error message
    checklistID,
    isQuestionnaire = false,
  ) {
    const data = { id: checklistID };

    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/template`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      if (isQuestionnaire) {
        return this.setCurrentVendor({
          ...this.currentVendor,
          record: {
            ...this.currentVendor.record,
            vendor_checklists:
              // @ts-expect-error TS(2339) FIXME: Property 'record' does not exist on type 'object |... Remove this comment to see the full error message
              this.currentVendor.record.vendor_checklists.filter(
                // @ts-expect-error TS(7006) FIXME: Parameter 'checklist' implicitly has an 'any' type... Remove this comment to see the full error message
                (checklist) => checklist.id !== checklistID,
              ),
          },
        });
      }

      this.setDataTemplates(
        // @ts-expect-error TS(2339) FIXME: Property 'templates' does not exist on type '{}'.
        this.data.templates.filter((template) => template.id !== checklistID),
      );
    } catch (error) {
      window.console.log(`"VDD#deleteChecklist" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async fetchQuestionsWithCategory(moduleWorkspaceID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/questions`,
        headers: this.mainStore.getHeaders(),
      });

      this.setDefaultQuestions(response.data.vendor_questions);
    } catch (error) {
      window.console.log(`"VDD#fetchQuestionsWithCategory" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async createQuestion(moduleWorkspaceID, params) {
    const data = { vendor_question: params };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/questions`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setDefaultQuestions([
        ...this.defaultQuestions,
        response.data.vendor_question,
      ]);
    } catch (error) {
      window.console.log(`"VDD#createQuestion" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async approveChecklist(moduleWorkspaceID, checklistID) {
    const data = { id: checklistID };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/approve_questionnaire`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setCurrentVendor({
        ...this.currentVendor,
        record: {
          ...this.currentVendor.record,
          // @ts-expect-error TS(2339) FIXME: Property 'record' does not exist on type 'object |... Remove this comment to see the full error message
          vendor_checklists: this.currentVendor.record?.vendor_checklists?.map(
            // @ts-expect-error TS(7006) FIXME: Parameter 'checklist' implicitly has an 'any' type... Remove this comment to see the full error message
            (checklist) =>
              checklist.id !== checklistID
                ? checklist
                : {
                    ...checklist,
                    approver: this.mainStore.users.user,
                    approved_at: dayjs(
                      new Date().toISOString().slice(0, 10),
                      "YYYY-MM-DD",
                    ).toDate(),
                  },
          ),
        },
      });
    } catch (error) {
      window.console.log(`"VDD#approveChecklist" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async unapproveChecklist(moduleWorkspaceID, checklistID) {
    const data = { id: checklistID };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/unapprove_questionnaire`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setCurrentVendor({
        ...this.currentVendor,
        record: {
          ...this.currentVendor.record,
          // @ts-expect-error TS(2339) FIXME: Property 'record' does not exist on type 'object |... Remove this comment to see the full error message
          vendor_checklists: this.currentVendor.record?.vendor_checklists?.map(
            // @ts-expect-error TS(7006) FIXME: Parameter 'checklist' implicitly has an 'any' type... Remove this comment to see the full error message
            (checklist) =>
              checklist.id !== checklistID
                ? checklist
                : { ...checklist, approver: {} },
          ),
        },
      });
    } catch (error) {
      window.console.log(`"VDD#unapproveChecklist" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async completeChecklist(moduleWorkspaceID, checklistID) {
    const data = { id: checklistID };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/complete_questionnaire`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setCurrentVendor({
        ...this.currentVendor,
        record: {
          ...this.currentVendor.record,
          // @ts-expect-error TS(2339) FIXME: Property 'record' does not exist on type 'object |... Remove this comment to see the full error message
          vendor_checklists: this.currentVendor.record?.vendor_checklists?.map(
            // @ts-expect-error TS(7006) FIXME: Parameter 'checklist' implicitly has an 'any' type... Remove this comment to see the full error message
            (checklist) =>
              checklist.id !== checklistID
                ? checklist
                : { ...checklist, completed_user: this.mainStore.users.user },
          ),
        },
      });
    } catch (error) {
      window.console.log(`"VDD#completeChecklist" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async reopenChecklist(moduleWorkspaceID, checklistID) {
    const data = { id: checklistID };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/reopen_questionnaire`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setCurrentVendor({
        ...this.currentVendor,
        record: {
          ...this.currentVendor.record,
          // @ts-expect-error TS(2339) FIXME: Property 'record' does not exist on type 'object |... Remove this comment to see the full error message
          vendor_checklists: this.currentVendor.record.vendor_checklists.map(
            // @ts-expect-error TS(7006) FIXME: Parameter 'checklist' implicitly has an 'any' type... Remove this comment to see the full error message
            (checklist) =>
              checklist.id !== checklistID
                ? checklist
                : { ...checklist, completed_user: {} },
          ),
        },
      });
    } catch (error) {
      window.console.log(`"VDD#reopenChecklist" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async shareChecklist(moduleWorkspaceID, checklistID, workspaceIDs) {
    const data = {
      id: checklistID,
      workspace_ids: workspaceIDs,
    };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/share_questionnaire`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setCurrentVendor({
        ...this.currentVendor,
        record: {
          ...this.currentVendor.record,
          // @ts-expect-error TS(2339) FIXME: Property 'record' does not exist on type 'object |... Remove this comment to see the full error message
          vendor_checklists: this.currentVendor.record.vendor_checklists.map(
            // @ts-expect-error TS(7006) FIXME: Parameter 'checklist' implicitly has an 'any' type... Remove this comment to see the full error message
            (checklist) =>
              checklist.id !== checklistID
                ? checklist
                : {
                    ...checklist,
                    shared: true,
                    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
                    shared_checklists: workspaceIDs.map((id) => ({
                      workspace_id: id,
                    })),
                  },
          ),
        },
      });
    } catch (error) {
      window.console.log(`"VDD#shareChecklist" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async updateChecklistQuestion(moduleWorkspaceID, id, params) {
    const data = {
      id,
      checklist_question: params,
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/checklist_question`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      return response.data.checklist_question;
    } catch (error) {
      window.console.log(`"VDD#updateChecklistQuestion" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async fetchSharedChecklists(workspaceID) {
    try {
      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/workspaces/${workspaceID}/vendors/shared_checklists`,
        headers: this.mainStore.getHeaders(),
      });

      this.setSharedChecklists(result.data?.shared_checklists);
    } catch (error) {
      window.console.log(`"VDD#fetchSharedChecklists" error ${error}`);
    }
  }

  async fetchChecklistQuestionAttachment({
    // @ts-expect-error TS(7031) FIXME: Binding element 'checklistQuestionID' implicitly h... Remove this comment to see the full error message
    checklistQuestionID,
    // @ts-expect-error TS(7031) FIXME: Binding element 'moduleWorkspaceID' implicitly has... Remove this comment to see the full error message
    moduleWorkspaceID,
    // @ts-expect-error TS(7031) FIXME: Binding element 'filename' implicitly has an 'any'... Remove this comment to see the full error message
    filename,
    downloadMode = false,
  }) {
    const data = { id: checklistQuestionID };

    try {
      let response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/checklist_question/fetch_attachment`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      const { url, content_type, file_name } = response.data;

      if (!downloadMode) {
        this.mainStore.files.setContentType(content_type);
        this.mainStore.files.setFileName(file_name);
        return this.mainStore.files.setURL(url);
      }

      response = await legacyApi({
        method: "GET",
        url,
        responseType: "blob",
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(response.data);
      link.download = filename;
      link.click();
    } catch (error) {
      window.console.log(
        `"Vendors#fetchChecklistQuestionAttachment" error ${error}`,
      );
    }
  }

  // risk assessments
  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentID' implicitly has an 'an... Remove this comment to see the full error message
  async getRiskAssessmentById(riskAssessmentID, usingTemplate) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentID}`,
        headers: this.mainStore.getHeaders(),
      });

      const { risk_assessment: matrix } = response.data;
      if (usingTemplate) {
        matrix.name = "";
        matrix.id = null;
        // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
        matrix.risk_levels = matrix.risk_levels.map((item) => ({
          ...item,
          id: null,
        }));
        // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
        matrix.risk_themes = matrix.risk_themes.map((item) => ({
          ...item,
          id: null,
          // @ts-expect-error TS(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
          risk_descriptions: item.risk_descriptions.map((i) => ({
            ...i,
            id: null,
          })),
        }));
      }

      this.setCurrentRiskAssessment(matrix);
    } catch (error) {
      window.console.log(
        `"VDD#getRiskAssessmentById for Company" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async createRiskAssessment(moduleWorkspaceID, params, vendorID = null) {
    const data = {
      risk_assessment: params,
      vendor_id: vendorID,
    };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/risk_assessments/create`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      const { risk_assessment: riskAssessment } = response.data;

      this.setCurrentRiskAssessment(riskAssessment);
      if (!vendorID) {
        this.setRiskAssessmentTemplates([
          riskAssessment,
          // @ts-expect-error TS(2339) FIXME: Property 'risk_assessment_templates' does not exis... Remove this comment to see the full error message
          ...this.data.risk_assessment_templates,
        ]);
      }
    } catch (error) {
      window.console.log(
        `"VDD#createRiskAssessment for Company" error ${error}`,
      );
      this.mainStore.toast.setErrorText("Failed to create risk assessment");
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentID' implicitly has an 'an... Remove this comment to see the full error message
  async updateRiskAssessment(riskAssessmentID, params) {
    const data = { risk_assessment: params };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentID}`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setCurrentRiskAssessment(response.data.risk_assessment);
    } catch (error) {
      window.console.log(
        `"VDD#updateRiskAssessment for Company" error ${error}`,
      );
      this.mainStore.toast.setErrorText("Failed to update risk assessment");
    }
  }

  async batchUpdateRiskAssessment(
    // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentID' implicitly has an 'an... Remove this comment to see the full error message
    riskAssessmentID,
    // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
    params,
    updateCurrent = true,
  ) {
    const data = {
      risk_assessment: {
        name: params.name,
        risk_levels_attributes: params.risk_levels,
        // @ts-expect-error TS(7006) FIXME: Parameter 'risk_theme' implicitly has an 'any' typ... Remove this comment to see the full error message
        risk_themes_attributes: params.risk_themes.map((risk_theme) => ({
          id: risk_theme.id,
          comment: risk_theme.comment,
          title: risk_theme.title,
          weighting: risk_theme.weighting ? Number(risk_theme.weighting) : null,
          risk_descriptions_attributes: [...risk_theme.risk_descriptions],
        })),
        risk_assessment_ratings_attributes: params.risk_assessment_ratings,
      },
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentID}/batch_risk_assessment`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      if (updateCurrent) {
        this.setCurrentRiskAssessment(response.data.risk_assessment);
      }
    } catch (error) {
      window.console.log(
        `"VDD#updateRiskAssessment for Company" error ${error}`,
      );
      this.mainStore.toast.setErrorText("Failed to update risk assessment");
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentID' implicitly has an 'an... Remove this comment to see the full error message
  async deleteRiskAssessment(riskAssessmentID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentID}`,
        headers: this.mainStore.getHeaders(),
      });

      this.setCurrentVendor({
        ...this.currentVendor,
        record: {
          ...this.currentVendor?.record,
          risk_assessments:
            // @ts-expect-error TS(2339) FIXME: Property 'record' does not exist on type 'object |... Remove this comment to see the full error message
            this.currentVendor?.record?.risk_assessments?.filter(
              // @ts-expect-error TS(7006) FIXME: Parameter 'ra' implicitly has an 'any' type.
              (ra) => ra.id !== riskAssessmentID,
            ),
        },
      });

      this.setRiskAssessmentTemplates(
        // @ts-expect-error TS(2339) FIXME: Property 'risk_assessment_templates' does not exis... Remove this comment to see the full error message
        this.data.risk_assessment_templates?.filter(
          // @ts-expect-error TS(7006) FIXME: Parameter 'template' implicitly has an 'any' type.
          (template) => template.id !== riskAssessmentID,
        ),
      );
    } catch (error) {
      window.console.log(
        `"VDD#deleteRiskAssessment for Company" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentID' implicitly has an 'an... Remove this comment to see the full error message
  async resetRiskAssessment(riskAssessmentID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentID}/reset`,
        headers: this.mainStore.getHeaders(),
      });

      this.setCurrentRiskAssessment(response.data.risk_assessment);
    } catch (error) {
      window.console.log(
        `"VDD#resetRiskAssessment for Company" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentID' implicitly has an 'an... Remove this comment to see the full error message
  async createRiskLevel(riskAssessmentID, params) {
    const data = { risk_level: params };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentID}/risk_level`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setCurrentRiskAssessment(response.data.risk_assessment);
    } catch (error) {
      window.console.log(`"VDD#createRiskLevel for Company" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentID' implicitly has an 'an... Remove this comment to see the full error message
  async updateRiskLevel(riskAssessmentID, riskLevelID, params) {
    const data = {
      id: riskLevelID,
      risk_level: params,
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentID}/risk_level`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setCurrentRiskAssessment(response.data.risk_assessment);
    } catch (error) {
      window.console.log(`"VDD#updateRiskLevel for Company" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentID' implicitly has an 'an... Remove this comment to see the full error message
  async deleteRiskLevel(riskAssessmentID, riskLevelID) {
    const data = { id: riskLevelID };

    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentID}/risk_level`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setCurrentRiskAssessment(response.data.risk_assessment);
    } catch (error) {
      window.console.log(`"VDD#deleteRiskLevel for Company" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentID' implicitly has an 'an... Remove this comment to see the full error message
  async createRiskTheme(riskAssessmentID, params) {
    const data = { risk_theme: params };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentID}/risk_theme`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setCurrentRiskAssessment(response.data.risk_assessment);
    } catch (error) {
      window.console.log(`"VDD#createRiskTheme for Company" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentID' implicitly has an 'an... Remove this comment to see the full error message
  async updateRiskTheme(riskAssessmentID, riskThemeID, params) {
    const data = {
      id: riskThemeID,
      risk_theme: params,
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentID}/risk_theme`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setCurrentRiskAssessment(response.data.risk_assessment);
    } catch (error) {
      window.console.log(`"VDD#updateRiskTheme for Company" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentID' implicitly has an 'an... Remove this comment to see the full error message
  async deleteRiskTheme(riskAssessmentID, riskThemeID) {
    const data = { id: riskThemeID };

    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentID}/risk_theme`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setCurrentRiskAssessment(response.data.risk_assessment);
    } catch (error) {
      window.console.log(`"VDD#deleteRiskTheme for Company" error ${error}`);
    }
  }

  async importMatrix(file: File, riskAssessmentID: number) {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentID}/import`,
        headers: {
          ...this.mainStore.getHeaders(),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return this.mainStore.toast.setInfoText(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          response.response.status === 400
            ? // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
              response.response.data.errors
            : "An unexpected error occurred!",
        );
      }

      this.setCurrentRiskAssessment(response.data.risk_assessment);
    } catch (error) {
      window.console.log(`"VDD#importCsvFile" error ${error}`);
    }
  }

  // GET /api/react/module_workspaces/:module_workspaces_id/vendors/templates/:id/export
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async exportQuestionnaire(moduleWorkspaceID, templateId) {
    try {
      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/vendors/templates/${templateId}/export`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.toast.setInfoText(
          "An error occurred during export. Please try again or contact Themis support if the problem persists.",
        );
        return;
      }

      this.mainStore.toast.setText("Processing table download...");

      return result.data;
    } catch (error) {
      window.console.log(`"Tables#exportQuestionnaire" error ${error}`);
    }
  }

  // GET /api/react/vendors/risk_assessments/:risk_assessment_id/export
  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentId' implicitly has an 'an... Remove this comment to see the full error message
  async exportRiskAssessment(riskAssessmentId) {
    try {
      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentId}/export`,
        headers: this.mainStore.getHeaders(),
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.toast.setInfoText(
          "An error occurred during export. Please try again or contact Themis support if the problem persists.",
        );
        return false;
      }

      this.mainStore.toast.setText("Processing table download...");
      return result.data;
    } catch (error) {
      window.console.log(`"Tables#exportRiskAssessment" error ${error}`);
      return false;
    }
  }

  // GET /api/react/vendors/risk_assessments/:risk_assessment_id/export_data
  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentId' implicitly has an 'an... Remove this comment to see the full error message
  async exportRiskAssessmentData(riskAssessmentId) {
    try {
      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/vendors/risk_assessments/${riskAssessmentId}/export_data`,
        headers: this.mainStore.getHeaders(),
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.toast.setInfoText(
          "An error occurred during export. Please try again or contact Themis support if the problem persists.",
        );
        return false;
      }

      this.mainStore.toast.setText("Processing table download...");
      return result.data;
    } catch (error) {
      window.console.log(`"Tables#exportRiskAssessmentData" error ${error}`);
      return false;
    }
  }

  async copyRiskAssessment(riskAssessmentId: number, copiedWSIDs: number[]) {
    await legacyApi({
      method: "POST",
      url: `${API_URL}/vendors/risk_assessments/${riskAssessmentId}/copy`,
      headers: this.mainStore.getHeaders(),
      data: {
        risk_assessment: {
          copied_workspace_ids: copiedWSIDs,
        },
      },
    });
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value) {
    this.data = value;
    // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
    this.mainStore.recordVersions.setList(this.data.record_versions);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersions' implicitly has an 'any'... Remove this comment to see the full error message
  setRecordVersions(recordVersions, updateRecordVersionStore = true) {
    this.data = {
      ...this.data,
      record_versions: recordVersions,
    };
    if (updateRecordVersionStore) {
      this.mainStore.recordVersions.setList(recordVersions);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'templates' implicitly has an 'any' type... Remove this comment to see the full error message
  setDataTemplates(templates) {
    this.data = {
      ...this.data,
      templates,
    };
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentTemplates' implicitly has... Remove this comment to see the full error message
  setRiskAssessmentTemplates(riskAssessmentTemplates) {
    this.data = {
      ...this.data,
      risk_assessment_templates: riskAssessmentTemplates,
    };
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setTemplates(value) {
    this.templates = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrentVendor(value) {
    this.currentVendor = value || {};
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrentRiskAssessment(value) {
    this.currentRiskAssessment = value || {};
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setDefaultQuestions(value) {
    this.defaultQuestions = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setSharedChecklists(value) {
    this.sharedChecklists = value || [];
  }

  updateVendorChecklistIfPresent(
    // @ts-expect-error TS(7006) FIXME: Parameter 'vendorChecklistID' implicitly has an 'a... Remove this comment to see the full error message
    vendorChecklistID,
    // @ts-expect-error TS(7006) FIXME: Parameter 'uncompletedCommentsCount' implicitly ha... Remove this comment to see the full error message
    uncompletedCommentsCount,
  ) {
    if (!vendorChecklistID) {
      return;
    }

    this.setCurrentVendor({
      ...this.currentVendor,
      record: {
        ...this.currentVendor.record,
        // @ts-expect-error TS(2339) FIXME: Property 'record' does not exist on type 'object |... Remove this comment to see the full error message
        vendor_checklists: this.currentVendor?.record?.vendor_checklists?.map(
          // @ts-expect-error TS(7006) FIXME: Parameter 'vendorChecklist' implicitly has an 'any... Remove this comment to see the full error message
          (vendorChecklist) => {
            if (vendorChecklist.id !== vendorChecklistID) {
              return vendorChecklist;
            }

            return {
              ...vendorChecklist,
              uncompleted_comments_count: uncompletedCommentsCount || {
                private: 0,
                public: 0,
              },
            };
          },
        ),
      },
    });
  }

  // Store Helpers

  cleanup() {
    this.setData({});
    this.setTemplates([]);
    this.setCurrentVendor({});
    this.setCurrentRiskAssessment({});
    this.setDefaultQuestions([]);
    this.setSharedChecklists([]);
  }

  abort() {
    this.controller?.abort();
  }
}
