import {
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import React from "react";
import { PiLinkBreakBold } from "react-icons/pi";

type Props = {
  disabled?: boolean;
  onClick: () => void;
};

function UnlinkButtonWithTooltip({ disabled = false, onClick }: Props) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <IconButton
          disabled={disabled}
          Icon={PiLinkBreakBold}
          color="transparent"
          size="md"
          onClick={onClick}
          aria-label="Unlink"
        />
      </TooltipTrigger>
      <TooltipContent align="center" side="bottom">
        Unlink
      </TooltipContent>
    </Tooltip>
  );
}

export default UnlinkButtonWithTooltip;
