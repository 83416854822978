import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";

import EditCommentReply from "@/components/settings/notificationsCenter/tasks/EditCommentReply";
import { ReplyHeader } from "@/components/settings/notificationsCenter/tasks/ReplyHeader";
import { CommentTab } from "@/components/table/shared/comments/CommentsSlideMenu/types";
import { useMainStore } from "@/contexts/Store";
import { useClickAwayListener } from "@/hooks/useClickAwayListener";
import { useSearchParams } from "@/hooks/useSearchParams";
import { UserComment } from "@/stores/types/comment-types";

export function CommentReply({
  reply,
  selectedCommentRef,
}: {
  reply: UserComment;
  selectedCommentRef: React.RefObject<HTMLDivElement>;
}) {
  const mainStore = useMainStore();
  const replyWrapper = useRef(null);
  const [replyEditId, setReplyEditId] = useState<number | null>(null);

  const [searchParams, setSearchParams] = useSearchParams<{
    comments_tab?: CommentTab;
    comment_id?: string;
  }>();

  const isInternalTab = searchParams.comments_tab === "internal";
  const selectedCommentID = Number(searchParams.comment_id);
  const replyActive = reply.id === selectedCommentID;

  async function handleDeleteReply(id: number) {
    await mainStore.comments.delete(id);
  }

  function handleEditCommentClick(id: number) {
    setReplyEditId(id);
  }

  function handleClickAway() {
    setSearchParams({ ...searchParams, comment_id: undefined });
  }

  useClickAwayListener(replyWrapper, replyActive ? handleClickAway : undefined);

  return (
    <div ref={replyWrapper} key={reply.id}>
      <div
        ref={replyActive ? selectedCommentRef : undefined}
        className={classNames("comment-reply-container", {
          ["comment-reply-container--active"]: replyActive,
        })}
      >
        <ReplyHeader
          comment={reply}
          onConfirmDelete={handleDeleteReply}
          onEditCommentClick={handleEditCommentClick}
        />
        <EditCommentReply
          key={[replyEditId, reply.content].join()}
          isPrivate={isInternalTab}
          reply={reply}
          replyEditId={replyEditId}
          setReplyEditId={setReplyEditId}
        />
      </div>
    </div>
  );
}

export default observer(CommentReply);
