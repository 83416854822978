import React, { useEffect } from "react";

import AttachmentOverlay from "@/components/shared/attachment-overlay/AttachmentOverlay";
import { useMainStore } from "@/contexts/Store";

type ComplianceTemplatePreviewProps = {
  onClose: VoidFunction;
  fileName: string;
};

export default function ComplianceTemplatePreview({
  fileName,
  onClose,
}: ComplianceTemplatePreviewProps) {
  const mainStore = useMainStore();
  // Disables right click in effort to prevent users from downloading the PDF
  useEffect(() => {
    function handleContextMenu(e: MouseEvent) {
      e.preventDefault();
    }

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <AttachmentOverlay
      fileUrl={`/api/react/compliance_templates/${fileName}`}
      requestHeaders={mainStore.getHeaders()}
      preventDownload
      onClose={onClose}
    />
  );
}
