import "./module-header.scss";

import React, { ReactNode } from "react";

type ModuleHeaderProps = {
  children: ReactNode;
};

export default function ModuleHeader(props: ModuleHeaderProps) {
  return <div className="module-header">{props.children}</div>;
}
