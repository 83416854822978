import React, { SVGProps } from "react";

const FlagSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4.66797C8.36819 4.66797 8.66667 4.96645 8.66667 5.33464H17.3333C17.5644 5.33464 17.779 5.45427 17.9004 5.65081C18.0219 5.84736 18.0329 6.09279 17.9296 6.29944L16.7454 8.66797L17.9296 11.0365C18.0329 11.2432 18.0219 11.4886 17.9004 11.6851C17.779 11.8817 17.5644 12.0013 17.3333 12.0013H8.66667V18.0013H9.33333C9.70152 18.0013 10 18.2998 10 18.668C10 19.0362 9.70152 19.3346 9.33333 19.3346H6.66667C6.29848 19.3346 6 19.0362 6 18.668C6 18.2998 6.29848 18.0013 6.66667 18.0013H7.33333V5.33464C7.33333 4.96645 7.63181 4.66797 8 4.66797Z"
      fill="#EB2E4E"
    />
  </svg>
);

FlagSolid.displayName = "FlagSolidIcon";

export default FlagSolid;
