import "./create-scoring-group-menu.scss";

import classNames from "classnames";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { PartnersAPI } from "@/api/legacy/risk-assessment";
import { Button, Icon } from "@/components/Elements";
import TextInput from "@/components/form/TextInput";
import { useMainStore } from "@/contexts/Store";
import { RiskMethodology } from "@/features/risk-assessment/types";

interface CreateScoringGroupMenuProps {
  riskMethodologies?: RiskMethodology[];
  onClose: (refetch?: boolean) => void;
}

function CreateScoringGroupMenu(props: CreateScoringGroupMenuProps) {
  const mainStore = useMainStore();
  const { workspace_id, record_version_id } = useParams<{
    workspace_id: string;
    record_version_id: string;
  }>();
  const [groupName, setGroupName] = useState("");
  const [selectedRiskMethod, setSelectedRiskMethod] = useState<number>();

  const handleMethodSelectChange = (selectedId: number) => {
    setSelectedRiskMethod(selectedId);
  };

  const submit = async () => {
    try {
      await PartnersAPI.createAssessment(
        workspace_id,
        record_version_id,
        groupName,
        selectedRiskMethod!,
      );
    } catch (err) {
      mainStore.toast.setErrorFromResponse(err);
    }
    props.onClose(true);
  };
  return (
    <div className="create-scoring-group-menu">
      <div className="section title">
        <p>Add New Group</p>
        <Icon name="close" size="lg" onClick={() => props.onClose()} />
      </div>
      <div className="section group-name-input">
        <label htmlFor="groupNameInput">Group Name</label>
        <TextInput
          placeholder="- Type here -"
          name="groupNameInput"
          id="groupNameInput"
          inputRef={null}
          onChange={(e) => setGroupName(e.target.value)}
        />
      </div>
      <div className="section item-select">
        {props.riskMethodologies?.length === 0 ? (
          <p className="emptystate">
            You do not have any questionnaires under this Risk Methodology
          </p>
        ) : (
          <>
            <p className="title">
              Select the Risk Methodology of the questionnaires you want to
              score
            </p>
            {props.riskMethodologies?.map((rm) => (
              <label
                key={`risk-method-radio-select-${rm.id}`}
                className={classNames("radio", {
                  "option-selected": rm.id === selectedRiskMethod,
                })}
              >
                <input
                  name="radio"
                  type="radio"
                  onChange={() => handleMethodSelectChange(rm.id!)}
                />
                <span>{rm.name}</span>
              </label>
            ))}
          </>
        )}
      </div>
      <div className="section">
        <Button
          label="Confirm"
          disabled={!selectedRiskMethod || !groupName}
          onClick={submit}
        />
      </div>
    </div>
  );
}

export default CreateScoringGroupMenu;
