import React from "react";
import Popup from "reactjs-popup";

import { Icon } from "@/components/Elements";

type Props = {
  isOpened?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

function ModalWrapper({ isOpened, onClose, children }: Props) {
  return (
    <Popup
      open={isOpened}
      modal
      closeOnDocumentClick={false}
      overlayStyle={{ background: "rgba( 175, 175, 217, 0.7)" }}
    >
      <div className="modal-container">
        <Icon
          name="close"
          color="generalDark"
          size="de"
          className="close"
          onClick={onClose}
        />
        <div className="modal-content">{children}</div>
      </div>
    </Popup>
  );
}

export default ModalWrapper;
