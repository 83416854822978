import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import { Button } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

const VDD_MODULE_WORKSPACE_IDENTIFIER = "vendor_due_diligence";

type Props = {
  path: string;
};

function RiskAssessmentSaveButton({ path }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const history = useHistory();
  const { isCurrentWorkspaceArchived } = mainStore.workspaces;
  const { currentRiskAssessment: matrix } = mainStore.vendors;
  const isTemplate = path.includes("/templates/");
  const isUsing = path.includes("/use");

  // Functions
  async function handleClick() {
    if (!matrix.name) {
      return mainStore.toast.setErrorText("Name is required");
    }
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (!matrix.risk_levels.length) {
      return mainStore.toast.setErrorText("Please add at least 1 risk level");
    }
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (matrix.risk_themes.some((item) => !item.title)) {
      return mainStore.toast.setErrorText("Please fill in all titles");
    }
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (!matrix.risk_themes.length) {
      return mainStore.toast.setErrorText("Please add at least 1 risk theme");
    }

    const moduleWorkspaceID = mainStore.moduleWorkspaces.list?.find(
      (mw) => mw.themis_module?.identifier === VDD_MODULE_WORKSPACE_IDENTIFIER,
    )?.id;
    const vendorID =
      isTemplate && !isUsing
        ? null
        : path
            .split("/vendor-due-diligence/")[1]
            .split("/build_risk_assessment")[0];

    await mainStore.vendors.createRiskAssessment(
      moduleWorkspaceID,
      matrix,
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      vendorID,
    );
    mainStore.toast.setInfoText("Questionnaire saved!");

    history.goBack();
  }

  return (
    <Button
      disabled={isCurrentWorkspaceArchived}
      size="sm"
      label={`${isUsing ? "Use" : "Save"} ${
        isTemplate ? "Template" : "Questionnaire"
      }`}
      onClick={handleClick}
    />
  );
}

export default observer(RiskAssessmentSaveButton);
