import React, { PropsWithChildren, useState } from "react";

import MonitoringGroupResultDetail from "../components/MonitoringGroupResultInfo/MonitoringGroupResultDetail";

const MonitoringSidebarContext = React.createContext<{
  activeId: number | undefined;
  openSidebarWithId: (id: number) => void;
  closeSidebar: () => void;
}>(undefined!);

function MonitoringSidebarProvider(props: PropsWithChildren) {
  const [activeId, setActiveId] = useState<number | undefined>();
  const value = {
    activeId,
    openSidebarWithId: (id: number) => setActiveId(id),
    closeSidebar: () => setActiveId(undefined),
  };

  return (
    <MonitoringSidebarContext.Provider value={value}>
      {props.children}
      {activeId && <MonitoringGroupResultDetail activeId={activeId} />}
    </MonitoringSidebarContext.Provider>
  );
}
const useMonitoringSidebar = () => React.useContext(MonitoringSidebarContext);

export { MonitoringSidebarProvider, useMonitoringSidebar };
