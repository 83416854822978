import "./styles.scss";

import React from "react";

import { Typography } from "@/components/Elements";

type ErrorInputProps = {
  message: string;
};

export default function ErrorText({ message }: ErrorInputProps) {
  if (!message) {
    return <span className="empty-error-text">&nbsp;</span>;
  }

  return (
    <Typography
      data-testid="field-error"
      label={message}
      size="xs"
      color="generalError"
    />
  );
}
