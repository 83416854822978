import { NOTIFICATION_FREQUENCY } from "@/features/notifications/pages/single/date//types";
import { RELATIVE_TO } from "@/stores/types/notification-rule-types";

import { UnlockTabNotificationFormValues } from "./types";

export const initialCreateValues: UnlockTabNotificationFormValues = {
  criteria: [{ fieldName: "status", fieldValues: ["completed"] }],
  enabled: true,
  canUnlockTest: true,
  frequency: NOTIFICATION_FREQUENCY.ONE_TIME,
  recipients: { columns: [], department_ids: [], user_ids: [] },
  timePeriod: {
    numberOfOneTimeDays: [],
    excludeWeekends: false,
    recurringWeekdays: [],
  },
  condition: RELATIVE_TO.BEFORE,
  triggerColumn: "",
};

export const UNLOCK_NOTIFICATION_RULE = "unlock_record_version";
