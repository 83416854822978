import classNames from "classnames";
import React from "react";

import {
  COLOR,
  THEMIS_ICON_POSITIONS,
  THEMIS_MINITAG_THEMES,
  THEMIS_MINITAG_TYPES,
  THEMIS_TYPOGRAPHY_SIZES,
  THEMIS_TYPOGRAPHY_WEIGHTS,
} from "@/config/theme";

import Icon, { IconName } from "../Icon";
import Typography from "../Typography";

const MINITAG_COLORS: {
  [key in (typeof THEMIS_MINITAG_THEMES)[number]]: keyof typeof COLOR;
} = {
  purple: "accentsNavy",
  seaBlue: "extrasTeal",
  turquoise: "brandingHighlightTurquoise",
  lavender: "brandingHighlightViolet",
  blue: "accentsBlue",
  wine: "accentsPurple",
  violet: "accentsViolet",
  cherry: "extrasFuchsia",
  pink: "accentsPink",
  red: "generalError",
  salmon: "accentsSalmon",
  orange: "accentsOrange",
  green: "extrasGreen",
  mint: "extrasShamrock",
  black: "generalMidnightDark",
  gray: "generalDarkGray",
  navy: "brandingHoverNavy",
} as const;

export interface MiniTagProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  icon?: IconName;
  iconPosition?: (typeof THEMIS_ICON_POSITIONS)[number];
  onIconClick?: () => void;
  label: React.ReactNode;
  theme?: (typeof THEMIS_MINITAG_THEMES)[number];
  type?: (typeof THEMIS_MINITAG_TYPES)[number];
  fontSize?: (typeof THEMIS_TYPOGRAPHY_SIZES)[number];
  fontWeight?: (typeof THEMIS_TYPOGRAPHY_WEIGHTS)[number];
  /**
   * Will make min width the same as height
   */
  square?: boolean;
}

const MiniTag: React.FC<MiniTagProps> = ({
  active = false,
  className,
  icon,
  iconPosition = "left",
  onIconClick = () => {},
  label,
  theme = "purple",
  type = "solid",
  square,
  fontSize = "sm",
  fontWeight = "semiBold",
  ...rest
}) => {
  const iconComponent = icon && (
    <Icon
      name={icon}
      size="de"
      color={active ? "generalWhite" : MINITAG_COLORS[theme]}
      onClick={onIconClick}
    />
  );
  const typographyComponent = (
    <Typography
      label={label}
      size={fontSize}
      weight={fontWeight}
      color={active ? "generalWhite" : MINITAG_COLORS[theme]}
    />
  );

  return (
    <div
      className={classNames(
        "themisMiniTag",
        {
          [`themisMiniTag__${type}-${theme}`]: !active,
          ["themisMiniTag--active"]: active,
          ["themisMiniTag--square"]: square,
        },
        className,
      )}
      {...rest}
    >
      {iconPosition === "left" ? (
        <>
          {iconComponent}
          {typographyComponent}
        </>
      ) : (
        <>
          {typographyComponent}
          {iconComponent}
        </>
      )}
    </div>
  );
};

MiniTag.displayName = "MiniTag";

export default MiniTag;
