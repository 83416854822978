import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  FilePicker,
  FilePickerProps,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  ImagePicker,
  Label,
  Select,
  TextInput,
} from "@themis/ui";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { MonitoringGroupAssetChildResult } from "@/api";
import type { MonitoringResultAttachmentFile } from "@/api/gen/models/MonitoringResultAttachmentFile";
import { Icon } from "@/components/Elements";

import { ENTRY_STATUS_TYPES } from "../../config/monitoringGroupResultStatus";
import { StatusTag } from "../StatusTag";

const formSchema = z.object({
  id: z.number(),
  status: z.string().nullable(),
  comment: z.string().nullable(),
  evidence: z.string().nullable(),
  copy_title_data: z.string().nullable(),
  type: z.string().nullable(),
  sub_type: z.string().nullable(),
  document_type: z.string().nullable(),
  url: z.string().nullable(),
  compliance_finding_uid: z.string().nullable(),
  compliance_hash_group_uid: z.string().nullable(),
  exact_term: z.string().nullable(),
  reason: z.string().nullable(),
  rule: z.string().nullable(),
  recommendation: z.string().nullable(),
  severity: z.string().nullable(),
  enforcement_actions: z.array(z.any()).nullable(),
  monitoring_group_policy_uid: z.string().nullable(),
  monitoring_group_policy_name: z.string().nullable(),
  monitoring_group_asset_child_result_created_at: z.string().nullable(),
});

type EnforcementAction = {
  description: string;
  citations: string[];
  links: string[];
};

const ResultSections = ({
  label,
  content,
}: {
  label: string;
  content: React.ReactNode;
}) => (
  <div className="tw-border-b-1 tw-grid tw-grid-cols-4 tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-neutral-100 tw-py-3">
    <div className="tw-col-span-1 tw-p-2">
      <Label>{label}</Label>
    </div>
    <div className="tw-col-span-3 tw-whitespace-pre-wrap tw-p-2 tw-text-neutral-500">
      {content}
    </div>
  </div>
);

export type MonitoringGroupResultInfoSchema = z.infer<typeof formSchema>;

function MonitoringGroupResultInfoForm({
  onSubmit,
  results,
}: {
  onSubmit: (values: MonitoringGroupResultInfoSchema) => void;
  results?: MonitoringGroupAssetChildResult;
}) {
  const resultFilePicker = useRef<HTMLButtonElement>(null);
  const resultToUpload = useRef<File>();

  const [resultFile, setResultFile] = useState<FilePickerProps["file"]>(
    results?.evidence
      ? {
          name: results?.evidence?.file_name || "",
          url: results?.evidence?.file_url || "",
          type: results?.evidence?.content_type || "",
        }
      : undefined,
  );

  const form = useForm<MonitoringGroupResultInfoSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: results?.id || 0,
      status: results?.status || "",
      comment: results?.comment || "",
      evidence: results?.evidence?.signed_id || null,
      copy_title_data: results?.copy_title_data || "",
      type: results?.type || "",
      sub_type: results?.sub_type || "",
      document_type: results?.document_type || "",
      url: results?.url || "",
      compliance_finding_uid: results?.compliance_finding_uid || "",
      compliance_hash_group_uid: results?.compliance_hash_group_uid || "",
      exact_term: results?.exact_term || "",
      reason: results?.reason || "",
      rule: results?.rule || "",
      recommendation: results?.recommendation || "",
      severity: results?.severity || "",
      enforcement_actions: results?.enforcement_actions || [],
      monitoring_group_policy_uid: results?.monitoring_group_policy_uid || "",
      monitoring_group_policy_name: results?.monitoring_group_policy_name || "",
      monitoring_group_asset_child_result_created_at:
        results?.monitoring_group_asset_child_result_created_at
          ? format(
              parseISO(
                results?.monitoring_group_asset_child_result_created_at || "",
              ),
              "MM/dd/yyyy",
            )
          : "",
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.handleSubmit(onSubmit)();
  };

  const formatEnforcementActions = (
    enforcementActions: EnforcementAction[],
  ) => {
    return enforcementActions
      ?.map((item: EnforcementAction, index: number) => {
        const description = `Description\n${item.description}\n\n`;
        const citations = `Citations\n${item.citations
          .map((citation: string) => `• ${citation}`)
          .join("\n")}\n\n`;
        const links = `Links\n${item.links
          .map((link: string) => `• ${link}`)
          .join("\n")}\n\n`;
        const separator =
          "---------------------------------------------------------------\n\n";
        return (
          description +
          citations +
          links +
          (index < enforcementActions.length - 1 ? separator : "")
        );
      })
      .join("\n\n");
  };

  const getItemByIndex = (
    array: MonitoringResultAttachmentFile[],
    index: number,
  ) => {
    return array[index];
  };

  function handleUploadSuccess(signedIds: string[]) {
    if (!resultToUpload.current) {
      return;
    }

    resultFilePicker.current?.click();

    setResultFile(resultToUpload.current);
    form.setValue("evidence", signedIds[0]);

    resultToUpload.current = undefined;
  }

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit}
        className="long-grid long-grid-cols-2 long-gap-x-6 long-gap-y-4"
      >
        <div className="tw-grid tw-grid-cols-1 tw-items-center tw-gap-x-6 tw-gap-y-3 tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-neutral-100 tw-py-3">
          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Status</FormLabel>
                <FormControl>
                  <Select
                    items={ENTRY_STATUS_TYPES}
                    selected={field.value}
                    onSelect={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="comment"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Optional Comment</FormLabel>
                <FormControl>
                  <TextInput {...field} value={field?.value || ""} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="evidence"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>Evidence</FormLabel>
                <FormControl>
                  <DirectUploadProvider
                    onSuccess={handleUploadSuccess}
                    render={({ handleUpload, uploads }) => (
                      <FilePicker
                        ref={resultFilePicker}
                        className={classNames({
                          "tw-bg-warning-50 tw-text-warning-300":
                            fieldState.error,
                        })}
                        isLoading={["uploading", "waiting"].includes(
                          uploads[0]?.state,
                        )}
                        percentage={Math.round(uploads[0]?.progress) || 0}
                        onSelectFile={(selectedFile) => {
                          // DirectUploadProvider does not return file onSuccess so we have to save a ref of it to use it in handleUploadSuccess
                          resultToUpload.current = selectedFile;
                          handleUpload([selectedFile]);
                        }}
                        onRemoveFile={() => {
                          setResultFile(undefined);
                          field.onChange("");
                        }}
                        file={resultFile}
                      />
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button className="tw-mt-1" type="submit">
            Save Result
          </Button>
        </div>

        {results?.image_attachment?.file_url && (
          <ImagePicker
            readOnly
            className="tw-h-52"
            defaultUrl={results?.image_attachment?.file_url}
          />
        )}
        {results?.url && (
          <ResultSections
            label="URL"
            content={
              results?.url && (
                <a
                  href={results?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="tw-w-[700px] tw-overflow-hidden 
              tw-text-ellipsis tw-whitespace-nowrap
              tw-font-semibold
              tw-text-secondary-300"
                >
                  {results?.url}
                </a>
              )
            }
          />
        )}

        {results?.sub_type !== "Metadata" && (
          <>
            {results?.severity && (
              <ResultSections
                label="Severity"
                content={<StatusTag status={results?.severity} />}
              />
            )}

            {results?.monitoring_group_policy_name && (
              <ResultSections
                label="Policy"
                content={<p>{results?.monitoring_group_policy_name}</p>}
              />
            )}

            {results?.copy_title_data && (
              <ResultSections
                label="Copy"
                content={<p>{results?.copy_title_data}</p>}
              />
            )}

            {results?.reason && (
              <ResultSections
                label="Reason"
                content={<p>{results?.reason}</p>}
              />
            )}

            {results?.rule && (
              <ResultSections label="Rule" content={<p>{results?.rule}</p>} />
            )}

            {results?.recommendation && (
              <ResultSections
                label="Recommendation"
                content={<p>{results?.recommendation}</p>}
              />
            )}

            {results?.enforcement_actions &&
              results?.enforcement_actions?.length > 0 && (
                <ResultSections
                  label="Enforcement Actions"
                  content={
                    <p>
                      {formatEnforcementActions(
                        results?.enforcement_actions as EnforcementAction[],
                      )}
                    </p>
                  }
                />
              )}
          </>
        )}

        {Object.entries(results?.facts || {}).length > 0 && (
          <ResultSections
            label="Source of Truth"
            content={Object.entries(results?.facts || {}).map(
              ([key], index) => {
                return (
                  <div
                    key={index}
                    className="tw-border-b-1 tw-grid tw-grid-cols-4 tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-neutral-100 tw-py-3"
                  >
                    <div className="tw-col-span-3 tw-p-2">
                      <Label>{key.toString() || ""}</Label>
                      {results?.facts_attachments && (
                        <a
                          href={
                            getItemByIndex(results?.facts_attachments, index)
                              ?.file_url
                          }
                          target="_blank"
                          rel="noreferrer"
                          className="tw-w-[700px] tw-overflow-hidden 
                      tw-text-ellipsis tw-whitespace-nowrap
                      tw-px-2 tw-font-semibold
                      tw-text-secondary-300"
                        >
                          {
                            <Icon
                              name="download"
                              color="generalDark"
                              size="de"
                            />
                          }
                        </a>
                      )}
                    </div>
                  </div>
                );
              },
            )}
          />
        )}

        {results?.associated_screenshots?.map((screenshots_attachment) => {
          return (
            screenshots_attachment?.file_url && (
              <ResultSections
                label="Screenshot"
                content={
                  <ImagePicker
                    readOnly
                    className="tw-h-100"
                    defaultUrl={screenshots_attachment?.file_url}
                  />
                }
              />
            )
          );
        })}

        {results?.screenshots_attachments?.map((screenshots_attachment) => {
          return (
            screenshots_attachment?.file_url && (
              <ResultSections
                label="Screenshot"
                content={
                  <ImagePicker
                    readOnly
                    className="tw-h-100 tw-pt-4"
                    defaultUrl={screenshots_attachment?.file_url}
                  />
                }
              />
            )
          );
        })}
      </form>
    </Form>
  );
}

export default observer(MonitoringGroupResultInfoForm);
