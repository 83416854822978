import React from "react";

import { ApprovalStatus, ApprovalStep, Review } from "@/api";
import { Typography } from "@/components/Elements";

type Props = {
  approvalStatuses: (
    ids: number[],
    hasApprovalUsers?: boolean,
  ) => React.ReactNode | null;
  approvalSteps: ApprovalStep[];
  reviews: Review[];
  approvalStatus?: ApprovalStatus;
};

function SteppedApprovals({
  approvalStatuses,
  approvalSteps,
  reviews,
  approvalStatus,
}: Props) {
  // Variables
  const approvalReviews = reviews.filter(
    (review) => review.review_type === "approval",
  );
  const userIdsWithApprovalReviews = approvalReviews.map(
    (item) => item.user_id,
  );
  // @ts-expect-error TS(7006) FIXME: Parameter 'number' implicitly has an 'any' type.
  const isUserIdInApprovalReviews = (number) =>
    userIdsWithApprovalReviews.includes(number);
  const approvalStepId = approvalStatus?.approval_step_id;
  const hasApprovalStepCompleted = approvalStatus?.completed;

  return (
    <div
      className="users-dropdown users-dropdown-approval stepped-dropdown"
      data-testid="stepped-approvals-status"
    >
      {!!approvalSteps &&
        approvalSteps.map((item, index) => {
          const hasApprovalUsers =
            item?.users_ids.some(isUserIdInApprovalReviews) &&
            (item.id !== approvalStepId || hasApprovalStepCompleted);
          return (
            <div
              key={index}
              className="stepped-dropdown__block"
              data-testid="stepped-approval"
            >
              <Typography
                label={`Stepped Approval ${index + 1}`}
                className="stepped-dropdown__title"
                size="xs"
                weight="bold"
                color="generalLightBlue"
              />
              {approvalStatuses(item?.users_ids, hasApprovalUsers)}
            </div>
          );
        })}
    </div>
  );
}

export default SteppedApprovals;
