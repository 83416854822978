import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { useMainStore } from "../../../contexts/Store";
import lightbulb from "../../../images/table-image/icon/lightbulb-icon.svg";
import ListSettings from "../settings/ListSettings";
import ListTitleSettings from "../settings/ListTitleSettings";
import GlobalIdColumn from "../shared/GlobalIdColumn";
import AttestationsSection from "./AttestationsSection";

const headers = [
  {
    field_name: "attestation_status",
    title: "Attestation Status",
    disabled: false,
    width: undefined,
  },
  {
    field_name: "meta.name",
    title: "Training Title",
    icon: null,
    disabled: true,
    width: undefined,
  },
  {
    field_name: "record_version",
    title: "Version",
    icon: null,
    disabled: true,
    width: undefined,
  },
  {
    field_name: "attestation_completion",
    title: "Completion %",
    icon: null,
    disabled: true,
    width: undefined,
  },
  {
    field_name: "initial_attestation_date",
    title: "Attestation Start Date",
    icon: null,
    disabled: true,
    width: undefined,
  },
  {
    field_name: "latest_attestation_date",
    title: "Attestation Due Date",
    icon: null,
    disabled: true,
    width: undefined,
  },
  {
    field_name: "assignees",
    title: "Assignees",
    icon: null,
    disabled: true,
    width: undefined,
  },
];

type Props = {
  showCheckbox?: boolean;
};

function AttestationsList({ showCheckbox }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { moduleWorkspaceID } = mainStore.context;
  const sectionTags = mainStore.sectionTags.orderedList;
  const attestationsList = mainStore.attestations.list;

  // funcs
  const load = () => {
    mainStore.attestations.index(moduleWorkspaceID, "Trainings");
  };

  // Effects
  useEffect(load, [moduleWorkspaceID]);

  // elements
  const renderHeaders = (
    <>
      <GlobalIdColumn />
      {headers.map((header) => (
        <ListTitleSettings
          key={header.field_name}
          width={header.width}
          title={header.title}
          fieldName={header.field_name}
        />
      ))}
    </>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'rows' implicitly has an 'any' type.
  const renderRows = (rows) =>
    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    rows.map((item) => (
      <ListSettings
        key={item.id}
        reload={load}
        headers={headers}
        data={item}
        model="Attestation"
        showCheckbox={showCheckbox}
        globalID={item.global_id}
      />
    ));

  const renderMainContent = (
    <>
      <AttestationsSection
        isDropdownOpen
        rows={renderRows(
          // @ts-expect-error TS(2339) FIXME: Property 'section_tag_id' does not exist on type '... Remove this comment to see the full error message
          attestationsList.filter((item) => !item.section_tag_id),
        )}
        headers={renderHeaders}
        showCheckbox={showCheckbox}
      />

      {sectionTags.map((sectionTag) => (
        <AttestationsSection
          key={sectionTag.id}
          isDropdownOpen
          rows={renderRows(
            attestationsList.filter(
              // @ts-expect-error TS(2339) FIXME: Property 'section_tag_id' does not exist on type '... Remove this comment to see the full error message
              (item) => item.section_tag_id === sectionTag.id,
            ),
          )}
          headers={renderHeaders}
          sectionTag={sectionTag}
          showCheckbox={showCheckbox}
        />
      ))}
    </>
  );

  const renderPlaceholder = (
    <div className="review-attested-policies">
      <h4>
        <img src={lightbulb} alt="lightbulb-icon" />
        Review your attested trainings
      </h4>
      <p>
        Attested trainings are stored here. These trainings can be shared with
        partners and attested to by employees.
      </p>
    </div>
  );

  return attestationsList.length > 0 ? renderMainContent : renderPlaceholder;
}

AttestationsList.defaultProps = {
  showCheckbox: false,
};

export default observer(AttestationsList);
