import "./styles.scss";

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import { IntegrationsAPI } from "@/api/legacy/integrations";
import * as ZendeskConfigAPI from "@/api/legacy/zendesk-config";
import { Button, Flex, Typography } from "@/components/Elements";
import LoadingWrapper from "@/components/loading-wrapper";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import { useMainStore } from "@/contexts/Store";
import { useLoadableData } from "@/hooks/useLoadableData";

import { MappedFields } from "./components/mapped-fields";
import { SelectedFilters } from "./components/selected-filters";
import { initialZendeskConfigValues } from "./constants";
import {
  getMappedFields,
  parseZendeskHashParams,
  parseZendeskSearchParams,
} from "./helpers";
import { ZendeskConfigFormValues } from "./types";

const ZendeskConfiguration: React.FC = () => {
  const mainStore = useMainStore();
  const history = useHistory();

  const { id: zendeskConfigurationId } = useParams<{ id: string }>();

  const [disconnecting, setDisconnecting] = useState(false);

  const {
    loading,
    data: zendeskConfigData,
    errorText,
  } = useLoadableData({
    request: () => {
      const { configuration_id } = parseZendeskSearchParams();

      const configId = zendeskConfigurationId || configuration_id;

      if (configId) {
        return ZendeskConfigAPI.getConfiguration(configId);
      }

      // @ts-expect-error TS(2345) FIXME: Argument of type '{ access_token: string | null; w... Remove this comment to see the full error message
      return ZendeskConfigAPI.createConfiguration(parseZendeskHashParams());
    },
    onError: () =>
      "There was an issue loading the zendesk configuration. Please refresh to try again.",
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<ZendeskConfigFormValues>({
    defaultValues: initialZendeskConfigValues,
  });

  useEffect(() => {
    if (!zendeskConfigData) {
      return;
    }

    reset(() => ({
      selected_tags: zendeskConfigData.zendesk_configuration.selected_tags,
      selected_statuses:
        zendeskConfigData.zendesk_configuration.selected_statuses,
      mapped_fields: getMappedFields(zendeskConfigData),
    }));
  }, [zendeskConfigData]);

  const onSubmit: SubmitHandler<ZendeskConfigFormValues> = async ({
    mapped_fields,
    ...data
  }) => {
    try {
      await ZendeskConfigAPI.saveConfiguration({
        ...data,
        mapped_fields: mapped_fields
          .filter(
            (mappedField) => mappedField.checked && mappedField.internal_name,
          )
          .map(({ internal_name, zendesk_id }) => ({
            internal_name,
            zendesk_id,
          })),
        id: zendeskConfigData.zendesk_configuration.id,
      });

      mainStore.toast.setText(
        "Zendesk and Themis field mapping saved successfully.",
      );

      history.push(
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        mainStore.moduleWorkspaces.moduleInfo[
          zendeskConfigData.zendesk_configuration.module_identifier
        ].url,
      );
    } catch (error) {
      mainStore.toast.setErrorText(
        "An unexpected error occurred saving the mapping.",
      );
    }
  };

  const onError: SubmitErrorHandler<ZendeskConfigFormValues> = (errors) => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (errors.mapped_fields.root) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      mainStore.toast.setErrorText(errors.mapped_fields.root.message);
    }
  };

  const handleDisconnect = async () => {
    setDisconnecting(true);

    try {
      await IntegrationsAPI.disconnectZendesk(Number(zendeskConfigurationId));

      mainStore.toast.setText("Successfully disconnected Zendesk Integration.");

      history.push("/settings/integrations");
    } catch (error) {
      mainStore.toast.setErrorText(
        "There was an issue disconnecting Zendesk Integration, please try again.",
      );
    } finally {
      setDisconnecting(false);
    }
  };

  return (
    <DashboardContent noLeftNav>
      <form
        className="zendesk-configuration__form"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <DashboardHeader
          title="Zendesk Configuration"
          onBackClick={() => {
            history.push("/settings/integrations");
          }}
          RightActionBar={
            <Button
              disabled={isSubmitting || !isDirty}
              aria-label="save-zendesk-fields"
              label={isSubmitting ? "Saving..." : "Save Fields"}
              size="sm"
              type="submit"
            />
          }
        />
        <DashboardContentWrapper>
          <LoadingWrapper
            loading={loading}
            errorText={errorText}
            loadingLayout="table"
          >
            {zendeskConfigData && (
              <>
                {zendeskConfigurationId && (
                  <Flex
                    alignCenter
                    justifyFlexEnd
                    className="sub-header-container"
                  >
                    <Button
                      size="sm"
                      theme="tertiary"
                      label={
                        disconnecting ? "Disconnecting..." : "Disconnect Module"
                      }
                      onClick={handleDisconnect}
                    />
                  </Flex>
                )}
                <Flex column className="zendesk-configuration">
                  <Flex
                    column
                    className="zendesk-configuration__content"
                    rowGap={32}
                  >
                    <SelectedFilters
                      name="selected_tags"
                      title="Zendesk Tags"
                      description="Select & import tags from Zendesk tickets"
                      miniTagTheme="purple"
                      control={control}
                      options={zendeskConfigData.zendesk_configuration.tags}
                    />
                    <SelectedFilters
                      name="selected_statuses"
                      title="Zendesk Statuses"
                      description="Select and import Zendesk tickets according to their status"
                      miniTagTheme="purple"
                      control={control}
                      options={zendeskConfigData.zendesk_configuration.statuses}
                    />
                    <Flex column rowGap={8}>
                      <Typography
                        label="Zendesk Table Fields"
                        size="lg"
                        color="generalDark"
                      />
                      <MappedFields
                        control={control}
                        zendeskConfigData={zendeskConfigData}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </>
            )}
          </LoadingWrapper>
        </DashboardContentWrapper>
      </form>
    </DashboardContent>
  );
};

export default observer(ZendeskConfiguration);
