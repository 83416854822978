import { IconButton } from "@themis/ui";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { PiLinkSimpleBold } from "react-icons/pi";

import { LinkedFinding } from "@/api";

export function RelatedFindingsLinkTooltip({
  linkedFindings,
  globalId,
}: {
  linkedFindings: LinkedFinding[];
  globalId: string;
}) {
  return (
    <IconButton
      Icon={PiLinkSimpleBold}
      size="lg"
      color="transparent"
      variant="vertical"
      aria-label={`Related findings for ${globalId}`}
      aria-describedby={`related-findings-for-${globalId}`}
      data-tooltip-id="tooltip"
      data-tooltip-html={renderToStaticMarkup(
        <div id={`related-findings-for-${globalId}`} className="tw-text-sm">
          This is related to the following findings:
          <br />
          <br />
          <ul>
            {linkedFindings.map((finding) => (
              <li key={finding.id}>• {finding.name}</li>
            ))}
          </ul>
        </div>,
      )}
    />
  );
}
