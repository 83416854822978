import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation, useParams } from "react-router-dom";

import { FindingsRoutes } from "@/features/findings/pages";
import { PreviewRelatedIssueManagementRecords } from "@/features/monitoring-and-testing/components/PreviewRelatedIssueManagmentRecords";
import TestSamples from "@/features/qa/components/TestSamples";

import DetailView from "../../../components/detailView/DetailView";
import AttachedDocumentPreview from "../../../components/table/settings/AttachedDocumentPreview";
import { useMainStore } from "../../../contexts/Store";
import QALinkedDocuments from "./QALinkedDocuments";
import TestDetailsHeader from "./TestDetailsHeader";
import TestProcedures from "./TestProcedures";

type Props = {
  isInternal?: boolean;
};

function TestDetails({ isInternal }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isCompleted, setIsCompleted] = useState(false);
  const [programName, setProgramName] = useState<string | null>(null);
  const [showCheckbox, setShowCheckbox] = useState(false);

  // Hooks
  const { record_version_id: recordVersionID } = useParams<{
    record_version_id: string;
  }>();
  const location = useLocation();

  // Variables
  const { workspaceID } = mainStore.context;
  const recordVersion =
    mainStore.recordVersions.list.find(
      (rv) => rv.id === Number(recordVersionID),
    ) || mainStore.recordVersions.current;
  const isQATestRecordView =
    location.pathname ===
    `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/record-view`;
  const inCompleted =
    mainStore.avroSchemas?.firstValueForField("status", recordVersion?.data) ===
    "completed";
  const fieldTestName = mainStore.avroSchemas?.displayNameForField("test_name");
  const isIssueManagementModuleAdded =
    mainStore.moduleWorkspaces.isModuleAdded("issue_management");

  // Effects
  useEffect(() => {
    if (fieldTestName) {
      setProgramName(fieldTestName);
    }
  }, [fieldTestName]);

  useEffect(() => {
    if (isQATestRecordView) {
      setIsCompleted(inCompleted);
    }
  }, [inCompleted]);

  useEffect(() => {
    if (!location.pathname) {
      return;
    }

    setShowCheckbox(false);
  }, [location.pathname]);

  // functions
  function maxDateFunction(targetField: string) {
    if (targetField === "start_date") {
      return mainStore.avroSchemas.valueForField(
        "target_completion_date",
        recordVersion?.data,
      );
    }
    if (targetField === "period_start_date") {
      return mainStore.avroSchemas.valueForField(
        "period_end_date",
        recordVersion?.data,
      );
    }
  }

  function minDateFunction(targetField: string) {
    if (targetField === "target_completion_date") {
      return mainStore.avroSchemas.valueForField(
        "start_date",
        recordVersion?.data,
      );
    }
    if (targetField === "period_end_date") {
      return mainStore.avroSchemas.valueForField(
        "period_start_date",
        recordVersion?.data,
      );
    }
  }

  return (
    <div className="qa-test-details" data-testid="test-details">
      {!location.pathname.startsWith(
        `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/view-procedures/`,
      ) &&
        !location.pathname.startsWith(
          `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/add-new-procedures/`,
        ) &&
        !location.pathname.endsWith("/new-record") &&
        !location.pathname.startsWith(
          `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/view-issues/`,
        ) &&
        !location.pathname.startsWith(
          `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/add-new-issue/`,
        ) &&
        !location.pathname.startsWith(
          `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/samples/`,
        ) && (
          <TestDetailsHeader
            recordVersionID={recordVersionID}
            isInternal={isInternal}
          />
        )}
      <Switch>
        <Route
          exact
          path="/workspaces/:workspace_id/modules/qa-tests-development/:record_version_id"
        >
          <DetailView
            moduleIdentifier="qa_tests_development"
            disabledFields={["status"]}
            ignoredFields={["testers"]}
            minDateFunction={minDateFunction}
            maxDateFunction={maxDateFunction}
            bottomComponentSlot={
              <PreviewRelatedIssueManagementRecords
                isIssueManagementModuleAdded={isIssueManagementModuleAdded}
              />
            }
          />
        </Route>
        <Route
          exact
          path="/workspaces/:workspace_id/modules/qa-tests/:record_version_id/new-record"
        >
          <DetailView
            moduleIdentifier="qa_tests_development"
            disabledFields={["status"]}
            ignoredFields={["testers"]}
            minDateFunction={minDateFunction}
            maxDateFunction={maxDateFunction}
          />
        </Route>

        <Route
          exact
          path={[
            "/workspaces/:workspace_id/modules/qa-tests/:record_version_id/linked_documents",
            "/workspaces/:workspace_id/modules/qa-tests/:record_version_id/linked_documents/:linked_document_id",
          ]}
        >
          <QALinkedDocuments isCompleted={isCompleted} />
        </Route>

        <Route path="/workspaces/:workspace_id/modules/qa-tests/:record_version_id/findings">
          <FindingsRoutes moduleIdentifier="qa_tests_development" />
        </Route>

        <Route
          exact
          path="/workspaces/:workspace_id/modules/qa-tests/:record_version_id/procedures"
        >
          <TestProcedures
            recordVersionId={Number(recordVersionID)}
            isCompleted={isCompleted}
          />
        </Route>

        <Route
          exact
          path="/workspaces/:workspace_id/modules/qa-tests/:record_version_id/samples"
        >
          <TestSamples
            recordVersionId={Number(recordVersionID)}
            showCheckbox={showCheckbox}
            setShowCheckbox={setShowCheckbox}
          />
        </Route>

        <Route
          exact
          path="/workspaces/:workspace_id/modules/qa-tests/:record_version_id/add-new-procedures/:record_version_id"
        >
          <DetailView
            moduleIdentifier="qa_procedures"
            programName={programName}
          />
        </Route>
        <Route
          exact
          path="/workspaces/:workspace_id/modules/qa-tests/:record_version_id/view-procedures/:record_version_id"
        >
          <DetailView
            showCommentsButton
            moduleIdentifier="qa_procedures"
            programName={programName}
          />
        </Route>
        <Route
          exact
          path="/workspaces/:workspace_id/modules/qa-tests/:record_version_id/add-new-issue/:record_version_id"
        >
          <DetailView
            moduleIdentifier="qa_findings"
            ignoredFields={[]}
            minDateFunction={minDateFunction}
            maxDateFunction={maxDateFunction}
          />
        </Route>
        <Route
          exact
          path="/workspaces/:workspace_id/modules/qa-tests/:record_version_id/view-issues/:record_version_id"
        >
          <DetailView
            showCommentsButton
            moduleIdentifier="qa_findings"
            minDateFunction={minDateFunction}
            maxDateFunction={maxDateFunction}
          />
        </Route>
        <Route
          exact
          path="/workspaces/:workspace_id/modules/qa-tests/:record_version_id/samples/:record_version_id"
        >
          <DetailView showCommentsButton moduleIdentifier="samples" />
        </Route>
        <Route
          exact
          path="/workspaces/:workspace_id/modules/qa-tests/:record_version_id/preview/:attached_document_id"
        >
          <AttachedDocumentPreview />
        </Route>
      </Switch>
    </div>
  );
}

export default observer(TestDetails);
