import "./vendor-checklist-question.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { ChangeEvent, FocusEvent } from "react";
import { Link } from "react-router-dom";

import { Icon } from "@/components/Elements";
import FileInput from "@/components/table/shared/FileInput";
import Switch from "@/components/table/shared/Switch";
import { useMainStore } from "@/contexts/Store";
import responseIcon from "@/images/table-image/icon/response.svg";
import {
  ChecklistQuestion as TChecklistQuestion,
  VendorQuestionnaireFile,
} from "@/stores/types/vendor-types";

type Props = {
  item: TChecklistQuestion;
  savedItem?: number;
  isQuestionnaireCompleted: boolean;
  handleSwitchClick: (id: number, answer: string) => void;
  handleNAClick: (id: number, e: ChangeEvent<HTMLInputElement>) => void;
  handleLocalOnBlur: (
    e: FocusEvent<HTMLTextAreaElement, Element>,
    item: TChecklistQuestion,
  ) => void;
  handleAttachment: (id: string, itemId: number) => void;
  downloadFile: (id: number, name?: string) => void;
  isExternal?: boolean;
};

function VendorChecklistQuestion({
  item,
  savedItem,
  isQuestionnaireCompleted,
  handleSwitchClick,
  handleNAClick,
  handleLocalOnBlur,
  handleAttachment,
  downloadFile,
  isExternal,
}: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  let file: VendorQuestionnaireFile;
  if (typeof item.file === "string") {
    file = { name: "The file will be available after saving", id: -1 };
  } else {
    file = item?.file as VendorQuestionnaireFile;
  }

  const renderNameRow = (
    <div className="vendor-question-item-row name">
      <p className="name">{item.vendor_question?.name}</p>
      <div
        className={classNames("saved-success-msg", {
          active: savedItem === item.id,
        })}
        data-testid="checklist-response-saved"
      >
        <img src={responseIcon} alt="response-icon" />
        <span>Response Saved</span>
      </div>
    </div>
  );

  const renderItemRow = (
    <div className="vendor-question-item-row">
      {isQuestionnaireCompleted ? (
        <div className="vdd-completed-checklist-res">
          <span>Response:</span>
          <div
            className={classNames({
              yes: item.answer === "Yes",
              no: item.answer === "No",
            })}
          >
            {item.answer || "N/A"}
          </div>
        </div>
      ) : (
        <>
          <div
            className="checklist-complete-sidebar-switch"
            data-testid="checklist-complete-sidebar-switch"
          >
            <Switch
              active={item.answer !== "N/A"}
              indeterminate={item.answer === "N/A" || !item.answer}
              checked={item.answer === "Yes"}
              // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
              onChange={() => handleSwitchClick(item.id, item.answer)}
            />
            <span className="switch-value">{item.answer}</span>
          </div>

          <div
            className={classNames("na-container", {
              active: item.answer === "N/A",
            })}
          >
            <label>
              <input
                type="checkbox"
                checked={item.answer === "N/A"}
                onChange={(e) => handleNAClick(item.id, e)}
                data-testid="checklist-complete-sidebar-na"
              />
              <span>N/A</span>
            </label>
          </div>
        </>
      )}
    </div>
  );

  const renderTextArea = (
    <textarea
      defaultValue={item.comment}
      disabled={isQuestionnaireCompleted}
      placeholder={
        isQuestionnaireCompleted
          ? "– No Additional Comments –"
          : "Additional Comments"
      }
      onBlur={(e) => handleLocalOnBlur(e, item)}
      data-testid="checklist-complete-sidebar-comment"
    />
  );

  const renderFileInput = (
    <div
      className={classNames("vdd-file-input", {
        disabled: isQuestionnaireCompleted,
      })}
    >
      <span>
        {file?.name ||
          (isQuestionnaireCompleted
            ? "– No Additional Attachment –"
            : "Additional Attachment")}
      </span>
      <div className="actions checklist-item-file-actions">
        {!isQuestionnaireCompleted && (
          <>
            <FileInput
              handleAttachment={(signedIds) =>
                handleAttachment(signedIds[0], item.id)
              }
              trigger={<Icon name="attachment" color="generalDark" size="de" />}
            />
            {file?.id && (
              <Icon
                name="trash"
                color="generalDark"
                size="de"
                // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
                onClick={() => handleAttachment(null, item.id)}
              />
            )}
          </>
        )}
        {file?.id && (
          <>
            {!isExternal && (
              <Link
                to={`/workspaces/${workspaceID}/modules/vendor-due-diligence/checklist_questions/${item.id}/attachment_view`}
              >
                <Icon name="eye" color="generalDark" size="de" />
              </Link>
            )}
            <Icon
              name="download"
              color="generalDark"
              size="de"
              onClick={() => downloadFile(item.id, file?.name)}
            />
          </>
        )}
      </div>
    </div>
  );

  return (
    <div
      key={item.id}
      className="vendor-question-item"
      data-testid="checklist-complete-sidebar-item"
    >
      {renderNameRow}
      {renderItemRow}
      {renderTextArea}
      {renderFileInput}
    </div>
  );
}

export default observer(VendorChecklistQuestion);
