import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import SectionSelection from "../../shared/SectionSelection";
import Spinner from "../shared/Spinner";

type Props = {
  libraryType: string | null;
};

function RiskRegisterLibraryApplyButton({ libraryType }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [isSaving, setIsSaving] = useState(false);
  // Variables
  const sectionTags = mainStore.sectionTags.orderedList;
  const history = useHistory();
  const {
    libraryRecords,
    data,
    selectedSectionTagID: sectionTagID,
  } = mainStore.riskRegisters;
  const { workspaceID } = mainStore.context;
  const disabled = !libraryRecords.some((item) => item.checked) || isSaving;

  // Functions
  async function handleContinue() {
    setIsSaving(true);

    const moduleWorkspaceID = data?.module_workspace_id;
    const ids = libraryRecords
      .filter((item) => item.checked)
      .map((item) => item.id);

    mainStore.pageLoading.startLoading();
    await mainStore.riskRegisters.createBulk(
      moduleWorkspaceID,
      ids,
      sectionTagID,
      libraryType,
    );
    mainStore.pageLoading.endLoading();
    mainStore.toast.setInfoText("Done!");
    mainStore.riskRegisters.setActiveScreen("AllRisk");
    history.push(
      `/workspaces/${workspaceID}/modules/risk-register?initialSectionID=${sectionTagID}`,
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'section_id' implicitly has an 'any' typ... Remove this comment to see the full error message
  function onSelectSection(section_id) {
    mainStore.riskRegisters.setSelectedSectionTagID(section_id);
  }

  const button = (
    <button
      className="table-button header-action"
      disabled={disabled}
      data-testid="rr-library-apply"
      aria-label="Add to Risk Register"
    >
      Add to Risk Register
      {isSaving && <Spinner />}
    </button>
  );

  return (
    <Popup trigger={button}>
      <SectionSelection
        title="Select a register to add your records"
        sections={sectionTags}
        selectedSection={sectionTagID}
        onSelectSection={onSelectSection}
        handleContinue={handleContinue}
      />
    </Popup>
  );
}

export default observer(RiskRegisterLibraryApplyButton);
