import { observer } from "mobx-react";
import React from "react";

import { Button } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remove: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
};

const ModuleConfig = ({ value, remove }: Props) => {
  const { built_in } = value;
  const mainStore = useMainStore();

  const { isCurrentWorkspaceArchived } = mainStore.workspaces;

  if (built_in) {
    return <div className="module-builtin">Built In Module</div>;
  }
  return (
    <Button
      size="sm"
      disabled={isCurrentWorkspaceArchived}
      className="module-remove-button"
      label="Remove"
      theme="tertiary"
      onClick={remove}
    />
  );
};

export default observer(ModuleConfig);
