import { snakeCase } from "lodash";
import React, { useMemo } from "react";

import { recordTypeForThemisModuleIdentifier } from "@/components/helpers/nameForThemisModuleIdentifier";
import { useMainStore } from "@/contexts/Store";
import {
  ModuleIdentifier,
  TopLevelModule,
} from "@/stores/types/module-workspaces-types";

import SmallButton from "./SmallButton";

type Props = {
  moduleIdentifier: ModuleIdentifier;
};

function ShareLink({ moduleIdentifier }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { moduleWorkspaceID } = mainStore.context;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const companyName = mainStore.companies.company.name
    ?.split(" ")
    ?.join("-")
    .toLowerCase();

  const moduleRecord = recordTypeForThemisModuleIdentifier(moduleIdentifier);
  const singularModuleIdentifier = snakeCase(moduleRecord.singular);

  const modulePath = useMemo(() => {
    switch (moduleIdentifier) {
      case TopLevelModule.COMPLAINTS:
        return "complaints";
      case TopLevelModule.CONFLICTS_OF_INTEREST:
        return "conflict_of_interest";
      default:
        return singularModuleIdentifier;
    }
  }, [moduleIdentifier]);

  // Functions
  async function getToken() {
    return await mainStore.moduleAccessTokens
      .find_or_create(moduleWorkspaceID)
      .then((res) => res);
  }

  async function copyLink() {
    const token = await getToken();
    if (token) {
      const shareLink = `${window.location.host}/${companyName}/submit_${modulePath}/${token}`;
      setTimeout(async () => {
        await navigator.clipboard.writeText(shareLink);
        mainStore.toast.setText(
          `You’ve copied the ${moduleRecord.singular} link!`,
        );
      });
    }
  }

  return (
    <SmallButton
      enabled={hasModuleWriteAccess}
      extraClassNames="link-button"
      title="Share Link"
      onClick={copyLink}
      data-testid="share-link-button"
    />
  );
}

export default ShareLink;
