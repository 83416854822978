import classNames from "classnames";
import React from "react";

import BoxLightIcon from "../../../images/table-image/icon/box-light.svg";
import BoxIcon from "../../../images/table-image/icon/box.svg";
import CheckIcon from "../../../images/table-image/icon/check-gray-back.svg";
import CheckLightIcon from "../../../images/table-image/icon/check-light-gray-back.svg";

type Props = {
  checked?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...args: any[]) => any;
  testID?: string;
};

function PermissionCheckbox({ checked, disabled, testID, onChange }: Props) {
  const handleChange = () => {
    if (!disabled) {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onChange();
    }
  };

  return (
    <div
      className={classNames("permission-checkbox", {
        "permission-checkbox-disabled": disabled,
        "permission-checkbox-checked": checked,
      })}
      data-testid={testID || "permission-checkbox"}
      onClick={handleChange}
    >
      {checked ? (
        <img
          src={disabled ? CheckLightIcon : CheckIcon}
          alt="check"
          data-testid="check-icon"
        />
      ) : (
        <img
          src={disabled ? BoxLightIcon : BoxIcon}
          alt="uncheck"
          data-testid="uncheck-icon"
        />
      )}
    </div>
  );
}

PermissionCheckbox.defaultProps = {
  onChange: () => {},
};

export default PermissionCheckbox;
