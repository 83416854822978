import React from "react";

type Props = {
  displayedItem?: string;
  item?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onItemClick?: (...args: any[]) => any;
};

export const TableMenuItem = ({ displayedItem, item, onItemClick }: Props) => {
  const handleClick = () => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onItemClick(item);
  };

  return (
    <div
      data-testid="table-menu-item"
      className="table-menu-items"
      onClick={handleClick}
    >
      {displayedItem || item}
    </div>
  );
};
