import DocViewer from "@cyntler/react-doc-viewer";
import axios from "axios";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { downloadFile } from "../../../stores/helpers/AttachmentHelper";
import CreativeViewImage from "../shared/creative-view/CreativeViewImage";
import CreativeViewPdf from "../shared/creative-view/CreativeViewPdf";
import CreativeViewTypeError from "../shared/creative-view/CreativeViewTypeError";
import CreativeViewVideo from "../shared/creative-view/CreativeViewVideo";

type Props = {
  documentId?: number;
};

function AttachedDocumentPreview(props: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [numPages, setNumPages] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [url, setURL] = useState<string | null>(null);
  const [fileName, setFileName] = useState(null);
  const [docURI, setDocURI] = useState(null);
  const [contentType, setContentType] = useState(null);

  // Variables
  // @ts-expect-error TS(2339) FIXME: Property 'attached_document_id' does not exist on ... Remove this comment to see the full error message
  const { attached_document_id } = useParams();
  const attachedDocumentID = attached_document_id
    ? Number(attached_document_id)
    : props.documentId;
  const isPdf = contentType === "application/pdf";
  const isImage =
    contentType === "image/jpeg" ||
    contentType === "image/png" ||
    contentType === "image/gif";
  const isVideo =
    contentType === "video/mp4" || contentType === "video/quicktime";
  const isDoc =
    contentType === "application/msword" ||
    contentType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const isPptx =
    contentType ===
    "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  const isMicrosoftPackage = isDoc || isPptx;
  const pdf = {
    url,
    fileType: "application/pdf",
  };

  // Refs
  const zoomComponentRef = useRef(null);
  const fileRef = useRef(null);
  const boundsRef = useRef();
  const pdfRef = useRef();

  // Functions
  // @ts-expect-error TS(7031) FIXME: Binding element 'newNumPages' implicitly has an 'a... Remove this comment to see the full error message
  function onPdfDocumentLoadSuccess({ numPages: newNumPages }) {
    setNumPages(newNumPages);
    handleFileLoaded();
  }

  function handleFileLoaded() {
    setZoomLevel(1);
  }

  async function handleDownload() {
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    const downloadResponse = await axios({
      method: "GET",
      url,
      responseType: "blob",
    });

    const blob = downloadResponse.data;
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    downloadFile(blob, fileName);
  }

  useEffect(() => {
    const fetchData = async () => {
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never[]'.
      if (mainStore.attachedDocuments.current.id !== attachedDocumentID) {
        const fetchedAttachedDocument =
          await mainStore.attachedDocuments.show(attachedDocumentID);
        setContentType(fetchedAttachedDocument?.file?.content_type || null);
        setURL(fetchedAttachedDocument?.file?.url || null);
        setFileName(fetchedAttachedDocument?.file?.name || null);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (attachedDocumentID && isMicrosoftPackage) {
        const uri =
          await mainStore.attachedDocuments.generateExternalURI(
            attachedDocumentID,
          );
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        setDocURI(uri);
      } else {
        setDocURI(null);
      }
    };

    fetchData();
  }, [attachedDocumentID, isMicrosoftPackage]);

  // Rendering
  const renderImage = (
    <CreativeViewImage
      url={url as string}
      zoomComponentRef={zoomComponentRef}
      setZoomLevel={setZoomLevel}
      fileRef={fileRef}
      handleFileLoaded={handleFileLoaded}
    />
  );

  const renderPdf = (
    <CreativeViewPdf
      // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
      pdfRef={pdfRef}
      // @ts-expect-error TS(2322) FIXME: Type '{ url: null; fileType: string; }' is not ass... Remove this comment to see the full error message
      pdf={pdf}
      onPdfDocumentLoadSuccess={onPdfDocumentLoadSuccess}
      // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
      numPages={numPages}
      zoomLevel={zoomLevel}
    />
  );

  const renderDoc = () => {
    if (!docURI) {
      return null;
    }
    const docs = [{ uri: docURI }];

    return (
      <DocViewer
        data-testid="cv-doc-viewer"
        className="file-viewer"
        style={{ width: "80%" }}
        documents={docs}
        config={{
          header: {
            disableHeader: true,
          },
        }}
      />
    );
  };

  const renderMainContent = () => {
    if (!url) {
      return;
    }
    if (docURI) {
      return renderDoc();
    }
    if (isPdf) {
      return renderPdf;
    }
    if (isImage) {
      return renderImage;
    }
    if (isVideo) {
      return (
        <CreativeViewVideo
          handleFileLoaded={handleFileLoaded}
          isControls
          url={url}
        />
      );
    }
    return <CreativeViewTypeError handleDownload={handleDownload} />;
  };

  return (
    <div
      className={classNames(
        "creative-file-container attached-document-preview",
        { pdf: isPdf },
      )}
      data-testid="cv-file-container"
    >
      <div
        className="creative-draggable-bounds"
        // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        ref={boundsRef}
        data-testid="cv-bounds"
      >
        {renderMainContent()}
      </div>
    </div>
  );
}

export default observer(AttachedDocumentPreview);
