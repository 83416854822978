import { RecordVersion } from "@/api";
import { getRepositionPayloadFromIndices } from "@/components/helpers/reordering";
import { MainStore } from "@/stores/Main";
import {
  TopLevelModule,
  TopLevelModuleIdentifier,
} from "@/stores/types/module-workspaces-types";

const useDragAndDropRecords = ({
  themisIdentifier,
  mainStore,
}: {
  themisIdentifier: TopLevelModuleIdentifier;
  mainStore: MainStore;
}) => {
  const { tableName } = mainStore.context;
  const getStore = () => {
    switch (themisIdentifier) {
      case TopLevelModule.ISSUE_MANAGEMENT:
        return mainStore.issueManagement;
      case TopLevelModule.AUDITS:
        return mainStore.themisAudits;
      case TopLevelModule.CONTROL_MAPPING:
        return mainStore.controlMappings;
      case TopLevelModule.KEY_RISK_INDICATORS:
        return mainStore.keyRiskIndicators;
      default:
        return null;
    }
  };
  const onSectionChange = (recordVersionId: number, sectionId: number) => {
    const store = getStore();
    if (store) {
      store.updateSection?.(recordVersionId, sectionId);
    }
  };

  const getVersionsForStatusAndSection = (sectionId: number) =>
    mainStore.recordVersions.list.filter((recordVersion: RecordVersion) => {
      if (recordVersion.section_tag_id !== sectionId) {
        return false;
      }
      const statusValue = mainStore.avroSchemas.valueForField(
        "status",
        recordVersion.data,
      )?.[0];
      const completed = statusValue === "completed";
      return (
        (tableName === "Completed" && completed) || tableName !== "Completed"
      );
    });

  const onRecordMoved = async (
    recordVersionId: number,
    sectionId: number,
    sourceIndex: number,
    destinationIndex: number,
  ) => {
    const relevantRecordVersions = getVersionsForStatusAndSection(sectionId);
    const reposition = getRepositionPayloadFromIndices(
      relevantRecordVersions,
      sourceIndex,
      destinationIndex,
    );
    await mainStore.recordVersions.reorder(recordVersionId, reposition);
  };

  return {
    onSectionChange,
    onRecordMoved,
  };
};

export default useDragAndDropRecords;
