import React, { useState } from "react";

import * as ApiKeysAPI from "@/api/legacy/api-keys";
import { ConfirmPopup } from "@/components/confirm-popup";
import { Flex, IconButton, Typography } from "@/components/Elements";
import APIKeyInformation from "@/components/settings/api-keys/APIKeyInformation";
import { useMainStore } from "@/contexts/Store";

type APIKeyRowProps = {
  title: string;
  id: number;
  refetch: VoidFunction;
};

function APIKeyRow({ title, id, refetch }: APIKeyRowProps) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Functions
  async function handleConfirm() {
    try {
      const deleted = await ApiKeysAPI.deleteApiKey(id);
      refetch();

      if (deleted) {
        mainStore.toast.setInfoText("The API key was successfully deleted.");
      }
    } catch (error) {
      mainStore.toast.setErrorText(
        "An error occurred. Could not delete API key",
      );
      window.console.log(`"APIkey#delete" error ${error}`);
    } finally {
      setShowPopup(false);
    }
  }

  return (
    <Flex alignCenter justifySpaceBetween className="api-key-row">
      <Typography
        label={title}
        size="de"
        weight="semiBold"
        color="generalMidnightDark"
      />
      <Flex alignCenter columnGap={16}>
        <Flex alignCenter>
          <IconButton
            size="sm"
            icon="edit"
            transparent
            onClick={() => setShowModal(true)}
          />
          <APIKeyInformation
            showModal={showModal}
            setShowModal={setShowModal}
            id={id}
            isEdit
            refetch={refetch}
            title={title}
          />
          <ConfirmPopup
            trigger={
              <div>
                <IconButton size="sm" icon="trash" transparent />
              </div>
            }
            title="Delete API Key"
            content={
              <p>
                Once the key is deleted, it no longer works with the API, and
                cannot be recovered.
                <br />
                <br />
                Are you sure you want to proceed?
              </p>
            }
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            onConfirmClick={handleConfirm}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default APIKeyRow;
