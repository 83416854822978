import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import CompanyHeader from "./CompanyHeader";
import CompanyTags, { TagItem, TagModel } from "./CompanyTags";

const tagsTabData: { path: string; items: TagItem; model: TagModel }[] = [
  {
    path: "/tags/departments",
    items: "departments",
    model: "Department",
  },
  {
    path: "/tags/products",
    items: "products",
    model: "Product",
  },
  {
    path: "/tags/frameworks",
    items: "tags",
    model: "Tag",
  },
];

function CompanyTagsRoot() {
  // Variables
  const renderTags = (
    <Switch>
      {tagsTabData.map((tab) => (
        <Route path={tab.path} key={tab.model}>
          <CompanyTags items={tab.items} model={tab.model} />
        </Route>
      ))}

      <Redirect to={tagsTabData[0].path} />
    </Switch>
  );

  return (
    <div className="dashboard-content">
      <CompanyHeader />
      <div className="dashboard-content-wrap">{renderTags}</div>
    </div>
  );
}

export default CompanyTagsRoot;
