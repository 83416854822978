import React, { ReactNode } from "react";

import { useMainStore } from "@/contexts/Store";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";

import { Icon } from "../Elements";

export interface DashboardHeaderProps {
  title: JSX.Element | string;
  LeftActionBar?: JSX.Element;
  RightActionBar?: JSX.Element;
  onBackClick?: () => void;
  dataTestID?: string;
  subHeader?: ReactNode;
  hideFavButton?: boolean;
  passedFavButton?: JSX.Element;
}

const DashboardHeader = ({
  title,
  onBackClick,
  LeftActionBar,
  RightActionBar,
  dataTestID,
  subHeader,
  hideFavButton = false,
  passedFavButton,
}: DashboardHeaderProps) => {
  const mainStore = useMainStore();
  const activeWorkspace = mainStore.users.user.active_workspace;

  return (
    <>
      <div
        className="tw-flex tw-h-12 tw-items-center tw-justify-between tw-bg-neutral-25 tw-pr-6"
        data-testid={dataTestID}
      >
        <div
          className="tw-flex tw-items-center tw-gap-3"
          data-testid="dashboard-header"
        >
          <div
            className="tw-flex tw-items-center tw-gap-1 tw-pl-6 tw-text-base tw-font-semibold tw-tracking-wide tw-text-neutral-500"
            data-testid="dashboard-header-workspace"
          >
            {onBackClick && (
              <div className="go-back-container tw-pt-1">
                <button
                  type="button"
                  onClick={onBackClick}
                  data-testid="back-button"
                  aria-label="back"
                >
                  <Icon name="chevronLeft" color="generalLightBlue" />
                </button>
              </div>
            )}
            <div>{title}</div>
          </div>
          <div data-testid="dashboard-header-left">
            {activeWorkspace?.is_internal && LeftActionBar}
          </div>

          {!hideFavButton && <div>{passedFavButton || <FavoriteButton />}</div>}
        </div>
        <div className="tw-flex tw-items-center tw-gap-1">
          <div data-testid="dashboard-header-right">{RightActionBar}</div>
        </div>
      </div>
      {subHeader}
    </>
  );
};

export default DashboardHeader;
