import React from "react";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: any;
  name: string;
  placeholder: string;
  defaultValue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...args: any[]) => any;
};

function TextAreaInput({
  inputRef,
  name,
  placeholder,
  defaultValue,
  onBlur,
  onChange,
}: Props) {
  return (
    <div className="form-input">
      <textarea
        ref={inputRef}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete="off"
        defaultValue={defaultValue}
      />
    </div>
  );
}

export default TextAreaInput;
