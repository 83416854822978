import "./styles.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

// @ts-expect-error TS(2732) FIXME: Cannot find module '../../finra.json'. Consider us... Remove this comment to see the full error message
import BrexJsonData from "../../../finra.json";

type Props = {
  fieldName: string;
  hasErrors: boolean;
  isBrexCategory: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  errorMessage?: string;
  hasErrorClass?: string;
  locked?: boolean;
  selectedID?: number;
  width: number | string;
};

function FinraBrexCategories({
  fieldName,
  recordVersion,
  isBrexCategory,
  selectedID,
  hasErrors,
  errorMessage,
  locked,
  hasErrorClass,
  width,
}: Props) {
  const BREX_CATEGORY_FIELD_NAME = "category";
  const BREX_SUB_CATEGORY_FIELD_NAME = "sub_category";

  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [query, setQuery] = useState("");

  // Variables
  const categories = isBrexCategory
    ? BrexJsonData.brexCategories
    : BrexJsonData.brexSubCategories;
  const categoryFieldValue = mainStore.avroSchemas.valueForField(
    BREX_CATEGORY_FIELD_NAME,
    recordVersion.data,
  );
  const valueSelected = Boolean(selectedID);
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  // @ts-expect-error TS(7006) FIXME: Parameter 'category' implicitly has an 'any' type.
  let filteredCategories = categories.filter((category) =>
    category.title.toLowerCase().includes(query.toLowerCase()),
  );

  // Additional filtration for SubCategories
  if (!isBrexCategory && categoryFieldValue) {
    filteredCategories = categories.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'subCategory' implicitly has an 'any' ty... Remove this comment to see the full error message
      (subCategory) =>
        subCategory.title.toLowerCase().includes(query.toLowerCase()) &&
        subCategory.category_id === categoryFieldValue,
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleSearch(event) {
    setQuery(event.target.value);
  }

  function handlePopUpClose() {
    setQuery("");
    setShowPopup(false);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'categoryID' implicitly has an 'any' typ... Remove this comment to see the full error message
  function addItemByID(categoryID) {
    if (selectedID !== categoryID) {
      const updates = [
        {
          fieldName,
          value: mainStore.avroSchemas.serializeValue(fieldName, categoryID),
        },
      ];

      // Reset Sub-Category if Category has changed
      if (isBrexCategory) {
        updates.push({
          fieldName: BREX_SUB_CATEGORY_FIELD_NAME,
          value: mainStore.avroSchemas.serializeValue(fieldName, null),
        });
      }

      // update both in the same call to avoid race conditions
      mainStore.recordVersions.updateFields(recordVersion.id, updates);
    }

    setShowPopup(false);
  }

  const triggerClasses = classNames("codes-cells", {
    active: showPopup,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [hasErrorClass]: hasErrors,
    illumination: valueSelected,
    "locked-cell": locked,
    "pointer-events-none": locked || !hasModuleWriteAccess,
    "table-cell--disabled": !hasModuleWriteAccess,
  });

  // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
  const category = categories.find((elem) => elem.id === selectedID);
  const categoryClasses = classNames("codes-element", {
    "not-available": category?.id === -1,
  });

  const renderTrigger = (
    <li className={triggerClasses} style={{ width }}>
      <div className="cell-content">
        {errorMessage && <span>{errorMessage}</span>}

        {!errorMessage && !valueSelected && (
          <input
            onChange={handleSearch}
            value={query}
            placeholder="– Select –"
            className="codes-cells__input"
          />
        )}

        {!errorMessage && valueSelected && (
          <div className={categoryClasses}>{category.title}</div>
        )}
      </div>
    </li>
  );

  return (
    <Popup
      position="bottom left"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={handlePopUpClose}
      keepTooltipInside
    >
      <div
        className="table-dropdown codes-popup-wrap"
        data-testid="table-dropdown"
      >
        {filteredCategories.length > 0 && (
          <ul>
            {
              // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
              filteredCategories.map((elem) => (
                <li
                  className={classNames({ "not-available": elem.id === -1 })}
                  key={elem.id}
                  onClick={() => addItemByID(elem.id)}
                >
                  {elem.title}
                </li>
              ))
            }
          </ul>
        )}
      </div>
    </Popup>
  );
}

FinraBrexCategories.defaultProps = {
  hasErrors: false,
  hasErrorClass: "has-errors",
};

export default observer(FinraBrexCategories);
