import "./styles.scss";

import classNames from "classnames";
import React, { ReactNode } from "react";

const DashboardContent = ({
  children,
  dataTestID,
  noLeftNav,
}: {
  children: ReactNode;
  dataTestID?: string;
  noLeftNav?: boolean;
}) => (
  <div
    className={classNames("new-dashboard-content", {
      "new-dashboard-content--no-left-nav": noLeftNav,
    })}
    data-testid={dataTestID}
  >
    {children}
  </div>
);

export default DashboardContent;
