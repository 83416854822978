import "./questionnaire-builder-question.scss";

import { Draggable, DraggableProvided } from "@akojic27/react-beautiful-dnd";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";

import { Flex } from "@/components/Elements";
import {
  DropdownOption,
  DropdownOptions,
} from "@/components/Elements/Dropdown/Dropdown";
import { getDraggbleIdFromQuestionId } from "@/components/helpers/draggable";
import { useMainStore } from "@/contexts/Store";
import {
  RESPONDER,
  REVIEWER,
  SELECTABLE_QUESTION_TYPES,
} from "@/features/risk-assessment/config";
import draggableIcon from "@/images/table-image/icon/draggable-icon.svg";
import { ConfirmationPopup } from "@/modules/shared/confirmation-popup/ConfirmationPopup";

import {
  Icon,
  IconButton,
  TruncateText,
  Typography,
} from "../../../../../components/Elements";
import {
  Question,
  ResponderType,
  ReviewerType,
} from "../../../types/questionnaire";
import UserAssignment from "../../UserAssignment";
import QuestionnaireBuilderQuestionUserTrigger from "../QuestionnaireBuilderQuestionUserTrigger/QuestionnaireBuilderQuestionUserTrigger";
import QuestionnaireBuilderUserAssignment from "../QuestionnaireBuilderUserAssignment/QuestionnaireBuilderUserAssignment";
import ExpandedQuestionContent from "./expanded-question-content/ExpandedQuestionContent";

type QuestionnaireBuilderQuestionProps = {
  question: Question;
  parentQuestion?: Question;
  riskAreaOptions: DropdownOptions;
  riskRatingOptions: DropdownOption[];
  order: string;
  onChange: (question: Question) => void;
  onDelete: () => void;
  onCopy?: () => void;
  depth?: number;
  isCollapsed?: boolean;
  onCollapseChange?: (questionId: number, isCollapsed: boolean) => void;
  isWeightingEnabled: boolean;
  isScoringEnabled: boolean;
  isNew: boolean;
  isInternal: boolean;
  sectionReviewerIDs?: number[];
  sectionResponderIDs?: number[];
};

function QuestionnaireBuilderQuestion({
  question,
  parentQuestion,
  riskAreaOptions,
  riskRatingOptions,
  order,
  onChange,
  onCopy,
  onDelete,
  depth = 0,
  isCollapsed = false,
  onCollapseChange = () => {},
  isWeightingEnabled,
  isScoringEnabled,
  isNew,
  isInternal,
  sectionReviewerIDs = [],
  sectionResponderIDs = [],
}: QuestionnaireBuilderQuestionProps) {
  const mainStore = useMainStore();

  const nonDestroyedReviewerUserIDS = question.assignments
    .filter((assignment) => !assignment._destroy)
    .map((assignment) => assignment.user_id)
    .concat(sectionReviewerIDs);

  const nonDestroyedResponderIDs = question.responders
    .filter((assignment) => !assignment._destroy)
    .map((assignment) => assignment.user_id)
    .concat(sectionResponderIDs);

  const questionTypeInfo =
    SELECTABLE_QUESTION_TYPES.find(
      (questionType) => questionType.type === question.input_type,
    ) || SELECTABLE_QUESTION_TYPES[0];
  const isTriggerQuestion = depth > 0;

  function onChangeAssignee(
    ids: number[],
    assignmentType: ReviewerType | ResponderType,
  ) {
    const assignmentGroup =
      question[assignmentType === REVIEWER ? "assignments" : "responders"];
    const assigneeIdsToDestroy = assignmentGroup
      .filter((assignment) => !ids.some((id) => id === assignment.user_id))
      .map((assignment) => ({
        ...assignment,
        _destroy: true,
      }));
    onChange({
      ...question,
      [assignmentType === REVIEWER ? "assignments" : "responders"]: [
        ...ids
          .filter(
            (id) =>
              !(
                assignmentType === REVIEWER
                  ? sectionReviewerIDs
                  : sectionResponderIDs
              ).includes(id),
          )
          .map((user_id) => {
            const existingAssignment = assignmentGroup.find(
              (a) => a.user_id === user_id,
            );

            if (existingAssignment) {
              return {
                ...existingAssignment,
                _destroy: false,
              };
            }

            return {
              assignment_type: assignmentType,
              user_id,
            };
          }),
        ...assigneeIdsToDestroy,
      ],
    });
  }

  const renderContent = (provided?: DraggableProvided) => (
    <div
      className={classNames("questionnaire-question", {
        "questionnaire-question__trigger": isTriggerQuestion,
      })}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
    >
      {provided && (
        <div
          {...provided.dragHandleProps}
          className="questionnaire-question__handle"
          data-tooltip-id="tooltip"
          data-tooltip-content="Drag to reorder or to move this question to another section on the left."
          data-tooltip-place="bottom"
          data-testid="questionnaire-question-reorder"
        >
          <img src={draggableIcon} />
        </div>
      )}
      <div className="questionnaire-question__header">
        <div className="questionnaire-question__order-number">{order}</div>
        <div className="questionnaire-question__type-title">
          <Icon name={questionTypeInfo.icon} />
          <Typography label={questionTypeInfo.name} size="sm" />
        </div>
        <div className="questionnaire-question__action-buttons">
          {!isTriggerQuestion && (
            <IconButton size="sm" icon="copy" onClick={onCopy} />
          )}
          <ConfirmationPopup
            text="Are you sure you want to delete this question?"
            onConfirm={onDelete}
            trigger={<IconButton size="sm" icon="trash" />}
          />
          {!isTriggerQuestion && (
            <IconButton
              size="sm"
              icon={isCollapsed ? "chevronDown" : "chevronUp"}
              onClick={() => onCollapseChange(question.id, !isCollapsed)}
            />
          )}
        </div>
      </div>
      <Flex
        alignCenter
        className="questionnaire-question-user-assignment-wrapper"
      >
        {isInternal && (
          <>
            <UserAssignment
              assigneeWord="Respondents"
              tooltip={
                (isNew && "This will only appear in internal questionnaires") ||
                undefined
              }
              assignedUsers={mainStore.users.allUsers.filter((user) =>
                nonDestroyedResponderIDs.some((userID) => userID === user.id),
              )}
              CustomTrigger={QuestionnaireBuilderQuestionUserTrigger}
            >
              <QuestionnaireBuilderUserAssignment
                selectedIDs={nonDestroyedResponderIDs}
                setSelectedIDs={(ids: number[]) =>
                  onChangeAssignee(ids, RESPONDER)
                }
                nonRemovableIDs={sectionResponderIDs}
              />
            </UserAssignment>
            <div className="questionnaire-question-vertical-line questionnaire-seperate-responder-reviewer" />
          </>
        )}

        <UserAssignment
          assignedUsers={mainStore.users.allUsers.filter((user) =>
            nonDestroyedReviewerUserIDS.some((userID) => userID === user.id),
          )}
          CustomTrigger={QuestionnaireBuilderQuestionUserTrigger}
        >
          <QuestionnaireBuilderUserAssignment
            selectedIDs={nonDestroyedReviewerUserIDS}
            setSelectedIDs={(ids: number[]) => onChangeAssignee(ids, REVIEWER)}
            nonRemovableIDs={sectionReviewerIDs}
          />
        </UserAssignment>
      </Flex>
      {isCollapsed ? (
        <Typography
          className="questionnaire-question__text"
          label={
            <TruncateText text={question.text} toLength={70} displayTooltip />
          }
          color="generalMidnightDark"
        />
      ) : (
        <ExpandedQuestionContent
          question={question}
          parentQuestion={parentQuestion}
          riskAreaOptions={riskAreaOptions}
          riskRatingOptions={riskRatingOptions}
          onChange={onChange}
          order={order}
          depth={depth}
          isWeightingEnabled={isWeightingEnabled}
          isScoringEnabled={isScoringEnabled}
          isNew={isNew}
          isInternal={isInternal}
        />
      )}
    </div>
  );

  if (isTriggerQuestion) {
    return renderContent();
  }

  return (
    <Draggable
      draggableId={getDraggbleIdFromQuestionId(question.id)}
      index={parseFloat(order)} // root level questions use numbers exclusively
      dropTargetCalculationMode="pointer"
    >
      {(provided) => renderContent(provided)}
    </Draggable>
  );
}

export default observer(QuestionnaireBuilderQuestion);
