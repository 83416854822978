import { groupBy, orderBy } from "lodash";

import { QuestionRead } from "@/features/risk-assessment/types/questionnaire";
import {
  isRadioAnswer,
  RadioAnswer,
} from "@/features/risk-assessment/types/questionnaire-form";

type OrderedQuestion = QuestionRead & { order: string };

export function removeHiddenQuestions(questions: QuestionRead[]) {
  const answerIds = questions.flatMap(
    (q) => (q.answer as RadioAnswer)?.question_choice_ids,
  );
  return questions.filter(
    (q) =>
      q.depending_question_choice_ids.length === 0 ||
      q.depending_question_choice_ids.some((id) => answerIds.includes(id)),
  );
}

export function removeFollowupQuestions(questions: QuestionRead[]) {
  return questions.filter((q) => !q.followup_ref_question_id);
}

export function patchQuestionsWithOrder(
  unorderedQuestions: QuestionRead[],
): [string, OrderedQuestion[]][] {
  const orderedQuestions = orderBy(unorderedQuestions, "position", "asc");

  const groupedQuestions = Object.entries(
    groupBy(orderedQuestions, "question_group_id"),
  );
  return groupedQuestions.map(
    ([groupId, questions]): [string, OrderedQuestion[]] => {
      return [groupId, orderGroupedQuestions(questions)];
    },
  );
}

function orderGroupedQuestions(
  groupedQuestions: QuestionRead[],
): OrderedQuestion[] {
  const rootQuestions: QuestionRead[] = groupedQuestions.filter((q) => {
    return q.depending_question_choice_ids.length === 0;
  });
  const triggerQuestions: QuestionRead[] = groupedQuestions.filter((q) => {
    return q.depending_question_choice_ids.length > 0;
  });

  return rootQuestions.flatMap((rootQuestion, index) => {
    const orderedQuestion: OrderedQuestion = {
      ...rootQuestion,
      order: String(index + 1),
    };
    return flattenQuestions(orderedQuestion, 0, triggerQuestions);
  });
}

function flattenQuestions(
  parentQuestion: OrderedQuestion,
  depth: number,
  allTriggerQuestions: QuestionRead[],
): OrderedQuestion[] {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'AnswerRead | null' is not assign... Remove this comment to see the full error message
  if (!isRadioAnswer(parentQuestion.answer)) {
    return [parentQuestion];
  }

  const triggerQuestions = allTriggerQuestions
    .filter((tQuestion) => {
      return tQuestion.depending_question_choice_ids.some((choiceId) => {
        return (
          parentQuestion.answer as RadioAnswer
        ).question_choice_ids.includes(choiceId);
      });
    })
    .flatMap((tQuestion, i) =>
      flattenQuestions(
        {
          ...tQuestion,
          order: `${parentQuestion.order}${
            depth % 2 === 0 ? String.fromCharCode(i + 65) : i + 1
          }`,
        },
        depth + 1,
        allTriggerQuestions,
      ),
    );

  return [parentQuestion, ...triggerQuestions];
}
