import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import { calculateRiskRating } from "@/features/risk-assessment/utils/scoring-helpers";

import SimpleOptionSelect from "../shared/SimpleOptionSelect";
import { RISK_RATINGS_COLORS } from "./constants";

const AVAILABLE_RATING_CALCULATION_TYPES = [
  {
    name: "manual",
    displayName: "Manual Rating",
  },
  {
    name: "auto",
    displayName: "Auto Rating",
  },
];

type Props = {
  actions?: React.ReactNode;
  isActive?: boolean;
  isNew?: boolean;
  isTemplate?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matrix?: any;
  vendorID?: string;
  isBulkEdit?: boolean;
};

function RiskAssessmentHeading({
  vendorID,
  matrix,
  isActive,
  isNew,
  isTemplate,
  actions,
  isBulkEdit,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [name, setName] = useState(matrix?.name);

  // Variables
  const { activeWorkspace } = mainStore.context;
  const { currentVendor } = mainStore.vendors;
  const totalWeighting =
    // @ts-expect-error TS(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
    matrix?.risk_themes?.reduce((a, b) => a + (Number(b.weighting) || 0), 0) ||
    0;
  const correctTotalWeighting = totalWeighting === 100;
  const isAutoCalc = matrix?.rating_calculation_type === "auto";
  const ratingCalculationTypeObject = AVAILABLE_RATING_CALCULATION_TYPES.find(
    (item) => item.name === matrix?.rating_calculation_type,
  );
  const activeRiskAssessmentRating = matrix?.risk_assessment_ratings?.find(
    // @ts-expect-error TS(7006) FIXME: Parameter 'rating' implicitly has an 'any' type.
    (rating) => rating.active,
  );

  // effects
  useEffect(() => {
    if (!vendorID || isNew || isTemplate) {
      return;
    }
    if (!currentVendor?.id) {
      mainStore.vendors.getVendorById(vendorID);
      return;
    }

    const fetchVendors = async () => {
      // @ts-expect-error TS(2339) FIXME: Property 'fields' does not exist on type '{}'.
      if (!mainStore.vendors.data?.fields) {
        await mainStore.vendors.index({ workspaceID: activeWorkspace?.id });
      }
    };

    fetchVendors();
  }, [currentVendor?.id]);

  useEffect(() => {
    setName(matrix?.name);
  }, [matrix?.name]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'rating' implicitly has an 'any' type.
  function handleChangeRiskLevel(rating) {
    if (!rating) {
      return;
    }

    const data = {
      ...matrix,
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      risk_assessment_ratings: matrix.risk_assessment_ratings.map((item) => ({
        ...item,
        active: item.name === rating.name,
      })),
    };
    mainStore.vendors.setCurrentRiskAssessment(data);
    if (isNew) {
      return;
    }

    mainStore.vendors.batchUpdateRiskAssessment(matrix.id, data);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleNameChange(e) {
    const { value } = e.target;

    setName(value);
  }

  function handleNameUpdate() {
    if (!name) {
      return;
    }

    mainStore.vendors.setCurrentRiskAssessment({ ...matrix, name });
    if (isNew) {
      return;
    }

    mainStore.vendors.updateRiskAssessment(matrix.id, { name });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
  async function handleChangeRatingCalculationType(type) {
    if (!type) {
      return;
    }

    const data = {
      rating_calculation_type: type.name,
      risk_themes: matrix.risk_themes,
    };

    // auto weighting
    const setAutoWeightingMode =
      type.name === "auto" &&
      // @ts-expect-error TS(7006) FIXME: Parameter 'theme' implicitly has an 'any' type.
      matrix.risk_themes.every((theme) => !theme.weighting);
    if (setAutoWeightingMode) {
      const { length: themesLength } = matrix.risk_themes;
      // @ts-expect-error TS(7006) FIXME: Parameter 'theme' implicitly has an 'any' type.
      data.risk_themes = matrix.risk_themes.map((theme, index) => {
        const value = Math.floor(100 / themesLength);
        const isLast = index === themesLength - 1;

        return {
          ...theme,
          weighting: isLast ? value + (100 % themesLength) : value,
        };
      });
    }

    mainStore.vendors.setCurrentRiskAssessment({ ...matrix, ...data });
    if (isNew) {
      return;
    }

    if (setAutoWeightingMode) {
      await mainStore.vendors.batchUpdateRiskAssessment(
        matrix.id,
        { risk_themes: data.risk_themes },
        false,
      );
    }
    mainStore.vendors.updateRiskAssessment(matrix.id, {
      rating_calculation_type: data.rating_calculation_type,
    });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ratingName' implicitly has an 'any' typ... Remove this comment to see the full error message
  function handleCreateRiskRating(ratingName) {
    if (!ratingName) {
      return;
    }

    const data = {
      ...matrix,
      risk_assessment_ratings: [
        ...matrix.risk_assessment_ratings,
        {
          name: ratingName,
          color_index: RISK_RATINGS_COLORS.length - 1,
          position: matrix.risk_assessment_ratings.length + 1,
        },
      ],
    };
    mainStore.vendors.setCurrentRiskAssessment(data);
    if (isNew) {
      return;
    }

    mainStore.vendors.batchUpdateRiskAssessment(matrix.id, data);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ratingName' implicitly has an 'any' typ... Remove this comment to see the full error message
  function handleDeleteRiskRating(ratingName) {
    if (!ratingName) {
      return;
    }

    const data = {
      ...matrix,
      risk_assessment_ratings: isNew
        ? // @ts-expect-error TS(7006) FIXME: Parameter 'rr' implicitly has an 'any' type.
          matrix.risk_assessment_ratings.filter((rr) => rr.name !== ratingName)
        : // @ts-expect-error TS(7006) FIXME: Parameter 'rr' implicitly has an 'any' type.
          matrix.risk_assessment_ratings.map((rr) =>
            rr.name === ratingName ? { ...rr, _destroy: true } : rr,
          ),
    };

    mainStore.vendors.setCurrentRiskAssessment(data);
    if (isNew) {
      return;
    }

    mainStore.vendors.batchUpdateRiskAssessment(matrix.id, data);
  }

  // elements
  const renderNameInput = (
    <input
      name="name"
      className={classNames("vdd-risk-assessment__name-input", {
        "vdd-filled-input": isActive && name?.length,
        "vendor-view-disabled": !isActive,
      })}
      placeholder={
        isTemplate ? "Enter Risk Assessment Template Name" : "Enter Matrix Name"
      }
      value={name}
      disabled={!isActive}
      onChange={handleNameChange}
      onBlur={handleNameUpdate}
    />
  );

  const renderRatingCalcTypeSelect = (
    <div
      className={classNames(
        "custom-column-option-container vdd-risk-assessment__rating-calc-type-select",
        {
          "vendor-view-disabled": !isActive,
        },
      )}
    >
      <SimpleOptionSelect
        availableOptions={AVAILABLE_RATING_CALCULATION_TYPES}
        selectedOption={ratingCalculationTypeObject}
        handleSelect={handleChangeRatingCalculationType}
        disabled={!isActive}
      />
    </div>
  );

  const renderSelect = (
    <div
      data-testid="select-risk-rating"
      className={classNames(
        "custom-column-option-container vdd-risk-assessment__rating-select",
        {
          "vendor-view-disabled": !isActive,
        },
      )}
    >
      <SimpleOptionSelect
        availableOptions={matrix?.risk_assessment_ratings || []}
        selectedOption={
          activeRiskAssessmentRating
            ? {
                ...activeRiskAssessmentRating,
                styles:
                  RISK_RATINGS_COLORS[activeRiskAssessmentRating.color_index],
              }
            : null
        }
        handleSelect={handleChangeRiskLevel}
        placeholder="-Select Risk Rating-"
        disabled={!isActive}
        enableAddNew
        onAddNew={handleCreateRiskRating}
        enableDelete
        onDelete={handleDeleteRiskRating}
      />
    </div>
  );

  const renderCalculation = () => {
    if (!correctTotalWeighting) {
      return (
        <div
          className="vdd-risk-assessment__auto-calc-error"
          data-testid="auto-calc-content"
        >
          Weightings must add up to 100%
        </div>
      );
    }

    const themes = matrix?.risk_themes || [];
    // @ts-expect-error TS(7006) FIXME: Parameter 'theme' implicitly has an 'any' type.
    const themesWithoutAnswers = themes.filter((theme) =>
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      theme.risk_descriptions.every((item) => !item.selected),
    );

    if (themesWithoutAnswers.length !== 0) {
      return (
        <div
          className="vdd-risk-assessment__auto-calc-error"
          data-testid="auto-calc-content"
        >
          {themesWithoutAnswers.length} / {themes.length} questions remaining
        </div>
      );
    }

    const { correspondingLevel, score: parsedScore } =
      calculateRiskRating(matrix);
    if (!correspondingLevel) {
      return (
        <div
          className="vdd-risk-assessment__auto-calc-error"
          data-testid="auto-calc-content"
        >
          There is no rating corresponding to your score.
        </div>
      );
    }

    const { color_index: colorIndex, name: ratingName } = correspondingLevel;

    return (
      <Popup
        on="hover"
        trigger={
          <div
            className="vdd-risk-assessment__auto-calc-content"
            data-testid="auto-calc-content"
          >
            <span className="value" style={RISK_RATINGS_COLORS[colorIndex]}>
              {ratingName}
            </span>
            <span className="vdd-risk-assessment__auto-calc-content__score">
              {parsedScore}%
            </span>
          </div>
        }
      >
        <div
          className="vdd-ra-auto-calc-popup"
          data-testid="auto-calc-content-popup"
        >
          Score: {parsedScore}%
        </div>
      </Popup>
    );
  };

  const renderTotalWeighting = (
    <div className="vdd-risk-assessment__total-weighting">
      <span>Total Weighting</span>
      <div
        className={classNames({ correct: correctTotalWeighting })}
      >{`${totalWeighting}%`}</div>
    </div>
  );

  return (
    <section
      className="vdd-risk-assessment__heading"
      data-testid="vdd-risk-assessment__heading"
    >
      <div>
        {renderNameInput}
        {!isBulkEdit && renderRatingCalcTypeSelect}
        {!isTemplate && !isAutoCalc && renderSelect}
        {!isBulkEdit && isAutoCalc && renderCalculation()}
        {isBulkEdit && isAutoCalc && renderTotalWeighting}
      </div>
      <div>{actions}</div>
    </section>
  );
}

RiskAssessmentHeading.defaultProps = {
  isNew: false,
  isTemplate: false,
};

export default observer(RiskAssessmentHeading);
