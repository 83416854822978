import "./risk-area-scoring.scss";

import React from "react";

import { Icon, Typography } from "@/components/Elements";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import Table from "@/features/risk-assessment/components/Table/GenericTable/Table";
import TableHeaderCell from "@/features/risk-assessment/components/Table/GenericTable/TableHeaderCell";
import TableRow from "@/features/risk-assessment/components/Table/GenericTable/TableRow";
import { getDefinitionsFromScores } from "@/features/risk-assessment/utils/scoring-helpers";

import { AssessmentScoring } from "../../../types/assessment";
import { RiskArea } from "../../../types/risk-area";
import { RiskMethodology } from "../../../types/risk-methodology";
import { ScoreCell } from "./ScoreCell";

type RiskAreaScoringProps = {
  scoring: AssessmentScoring;
  riskMethodology: RiskMethodology;
  riskAreas: RiskArea[];
};

export default function RiskAreaScoring(props: RiskAreaScoringProps) {
  return (
    <div className="risk-area-scoring">
      <Typography size="lg" color="generalMidnightDark" label="Risk Area" />
      <Table
        tableClassName="risk-area-scoring__table"
        header={
          <>
            <TableHeaderCell
              title="Risk Area"
              leftIcon={<Icon color="extrasBlueGrayDarker" name="book1" />}
              firstDataHeader
            />
            <TableHeaderCell
              title="Overall Risk"
              leftIcon={
                <Icon color="extrasBlueGrayDarker" name="priorityMedium" />
              }
            />
            <TableHeaderCell
              title="Inherent Questions"
              leftIcon={<Icon color="extrasBlueGrayDarker" name="number" />}
            />
            <TableHeaderCell
              title="Inherent Risk"
              leftIcon={
                <Icon color="extrasBlueGrayDarker" name="priorityMedium" />
              }
            />
            <TableHeaderCell
              title="Control Questions"
              leftIcon={<Icon color="extrasBlueGrayDarker" name="number" />}
            />
            <TableHeaderCell
              title="Control Risk"
              leftIcon={
                <Icon color="extrasBlueGrayDarker" name="priorityMedium" />
              }
            />
            <TableHeaderCell
              title="Residual Risk"
              leftIcon={
                <Icon color="extrasBlueGrayDarker" name="priorityMedium" />
              }
              lastDataHeader
            />
          </>
        }
      >
        {props.scoring.risk_area_ratings.map((raRating, rowIndex) => {
          const {
            overallRiskRating,
            controlRiskRating,
            inherentRiskRating,
            residualRiskRating,
          } = getDefinitionsFromScores(raRating, props.riskMethodology);
          const riskArea = props.riskAreas.find(
            (area) => area.id === raRating.risk_area_id,
          );
          return (
            <TableRow key={raRating.risk_area_id} rowIndex={rowIndex}>
              <ContentCell content={riskArea?.name} isFirstDataCell />
              <ScoreCell
                rating={overallRiskRating}
                score={raRating.overall_risk_score}
              />
              <ContentCell
                content={raRating.scored_inherent_question_count || "0"}
              />
              <ScoreCell
                rating={inherentRiskRating}
                score={raRating.inherent_risk_score}
              />
              <ContentCell
                content={raRating.scored_control_question_count || "0"}
              />
              <ScoreCell
                rating={controlRiskRating}
                score={raRating.control_risk_score}
              />
              <ScoreCell rating={residualRiskRating} isLastDataCell />
            </TableRow>
          );
        })}
      </Table>
    </div>
  );
}
