import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import Table from "../Table";
import RiskAssessmentsTable from "./RiskAssessmentsTable";
import RiskAssessmentTemplates from "./RiskAssessmentTemplates";
import VendorEmptyRiskAssessments from "./VendorEmptyRiskAssessments";

type Props = {
  vendorID: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  riskAssessments?: any; // TODO: PropTypes.instanceOf(Array)
};

function RiskAssessments({ riskAssessments, vendorID }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { currentVendor } = mainStore.vendors;
  const status = mainStore.avroSchemas.valueForField(
    "status",
    currentVendor?.data,
  );
  const isVendorActive = status !== "disabled";

  const emptyRiskAssessments = riskAssessments.length === 0;

  return (
    <Table>
      <div className="view-vendor-tables-container">
        {emptyRiskAssessments && isVendorActive && (
          <VendorEmptyRiskAssessments vendorID={vendorID} />
        )}
        {emptyRiskAssessments && !isVendorActive && (
          <div
            className="no-column-wrap vdd-add-first-questionnaire"
            data-testid="create-first-cta"
          >
            <div className="no-column-block">
              <div className="no-column-procedure">
                <div className="no-column-title">
                  <h3>There were no Risk Assessments added</h3>
                </div>
                <p style={{ marginBottom: "24px" }}>
                  You don’t have any Risk Assessments added for this vendor.
                </p>
                <p>
                  To add Risk Assessments here, this vendor needs to be
                  deactivate before any action been taken.
                </p>
              </div>
            </div>
          </div>
        )}
        {!emptyRiskAssessments && (
          <RiskAssessmentsTable
            riskAssessments={riskAssessments}
            vendorID={vendorID}
          />
        )}
        {isVendorActive && <RiskAssessmentTemplates editable={false} />}
      </div>
    </Table>
  );
}

RiskAssessments.defaultProps = {
  templates: [],
  riskAssessments: [],
};

export default observer(RiskAssessments);
