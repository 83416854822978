import { observer } from "mobx-react";
import React, { useState } from "react";

import { useMainStore } from "@/contexts/Store";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { IconButton } from "../../Elements";
import moduleIcons from "../../helpers/moduleIcons";

type Props = {
  identifier: ModuleIdentifier;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
};

const ModuleName = ({ value, identifier }: Props) => {
  // Import MobX stores
  const mainStore = useMainStore();

  const { name, icon } = value;
  const isInternalWorkspace =
    mainStore.context.activeWorkspace?.is_internal || false;

  // states
  const [isEdit, setIsEdit] = useState(false);
  const [moduleName, setModuleNameHelper] = useState(name);

  // functions
  const click = () => setIsEdit(!isEdit);
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const setModuleName = (e) => setModuleNameHelper(e.target.value);

  const updateName = async () => {
    // update name
    await mainStore.moduleWorkspaces.updateName(identifier, moduleName);
    setIsEdit(false);
  };

  const iconPart = () => {
    if (isEdit) {
      return (
        <IconButton
          className="table-button approve module-name-update"
          icon="check"
          transparent
          color="generalWhite"
          onClick={updateName}
          data-testid="check-icon"
        />
      );
    }
    return (
      <IconButton
        icon="edit"
        data-testid="edit-icon"
        color="generalLightBlue"
        transparent
        className="module-name-edit"
        onClick={click}
      />
    );
  };

  const namePart = () => {
    if (isEdit) {
      return (
        <li className="column-input" data-testid="module-name-input">
          <div className="cell-content">
            <input
              type="text"
              data-testid="module-name"
              className="module-name-input"
              value={moduleName}
              onChange={setModuleName}
              onKeyDown={setModuleName}
            />
          </div>
        </li>
      );
    }

    return moduleName;
  };

  return (
    <div className="module-name-container">
      <img
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        src={moduleIcons[icon] || moduleIcons.default}
        alt="logo"
        className="module-icon"
      />
      <div className="module-name">{namePart()}</div>
      <div className="module-icon">{isInternalWorkspace && iconPart()}</div>
    </div>
  );
};

export default observer(ModuleName);
