import { uniqueId } from "lodash";

import { ValueLabelOption } from "@/stores/types/field-types";

// @ts-expect-error TS(7006) FIXME: Parameter 'array' implicitly has an 'any' type.
export const arrayMove = (array, fromIndex, toIndex) => {
  const newArray = [...array];
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < newArray.length) {
    const endIndex = toIndex < 0 ? newArray.length + toIndex : toIndex;

    const [item] = newArray.splice(fromIndex, 1);
    newArray.splice(endIndex, 0, item);
  }

  return newArray;
};

// @ts-expect-error TS(7006) FIXME: Parameter 'array' implicitly has an 'any' type.
export const getIndexByPosition = (array, key, value) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
  array.findIndex((elem) => elem[key] === value);

/**
 *
 * @param pluralItemName - Required if plural doesn't just add an 's', exp. monkey, monkies
 */
export function getCountStringOfItem(
  count: number | undefined,
  itemName: string,
  pluralItemName = `${itemName}s`,
): string {
  if (!count) {
    return `0 ${pluralItemName}`;
  }

  if (count === 1) {
    return `1 ${itemName}`;
  }

  return `${count} ${pluralItemName}`;
}

/**
 *
 * @returns (essentially) globally uniuque integer, if you really need globally unique consider uuid string
 */
export function generateUniqueId(): number {
  return parseInt(`${String(new Date().getTime())}${String(uniqueId())}`);
}

export function trimToLength(string: string, length: number): string {
  if (!string) {
    return "";
  }

  if (string.length <= length) {
    return string;
  }

  return `${string.slice(0, length)}...`;
}

export const isNotItem =
  <T>(itemToRemove: T) =>
  (listItem: T) =>
    listItem !== itemToRemove;

export function toValueLabel<T>(listItem: T): ValueLabelOption<T> {
  return {
    value: listItem,
    label: String(listItem),
  };
}

export function accumulateArrayLengths<T>(sum: number, list: T[]) {
  return sum + list.length;
}

export function accumulateNumberOfNonEmptyArrays<T>(sum: number, list: T[]) {
  return sum + (list.length ? 1 : 0);
}

export function toArrayLength<T>(list: T[]) {
  return list.length;
}

/**
 * Utility function to get the values of an object as an array
 * to be used in `zod.enum(getValues(asConstObject))` instead of
 * `Object.values` to get the correct type
 */
export function getValues<T extends Record<string, unknown>>(obj: T) {
  return Object.values(obj) as [(typeof obj)[keyof T]];
}
