import { observer } from "mobx-react";
import React from "react";
import { generatePath, NavLink } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { ROUTES } from "../pages";
import ModuleTabs from "./ModuleTabs/ModuleTabs";

type Props = {
  questionnaireId: number;
};

function CWCustomerQuestionnaireDetailTabs({ questionnaireId }: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  return (
    <div className="risk-assessment-tabs">
      <ModuleTabs>
        <NavLink
          exact
          to={generatePath(ROUTES.CUSTOMER_QUESTIONNAIRE_DETAIL_REVIEW_PATH, {
            questionnaireId,
            workspace_id: Number(workspaceID),
          })}
        >
          Questionnaire Detail
        </NavLink>
        <NavLink
          exact
          to={generatePath(ROUTES.CUSTOMER_QUESTIONNAIRE_SCORING_PATH, {
            questionnaireId,
            workspace_id: Number(workspaceID),
          })}
        >
          Scoring
        </NavLink>
      </ModuleTabs>
    </div>
  );
}

export default observer(CWCustomerQuestionnaireDetailTabs);
