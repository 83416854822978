import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Spinner from "@/components/table/shared/Spinner";
import { useMainStore } from "@/contexts/Store";

type Props = {
  // set this for testing
  isExistingItem: boolean;
};

function KRILibraryItemSaveButton({ isExistingItem }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const riskCategory =
    mainStore.keyRiskIndicatorsCompanyLibrary.editedRiskCategory;
  const name = mainStore.keyRiskIndicatorsCompanyLibrary.editedName;

  // State
  const { record_id } = useParams<{ record_id: string }>();
  const [isSaving, setIsSaving] = useState(false);

  // Variables
  const { workspaceID } = mainStore.context;
  const companyID = mainStore.companies.company?.id;
  const history = useHistory();

  // Functions
  async function handleClick() {
    setIsSaving(true);

    if (!companyID) {
      return;
    }

    if (isExistingItem) {
      const data = {
        companyID,
        id: Number(record_id),
        name,
        riskCategory,
      };

      const response =
        await mainStore.keyRiskIndicatorsCompanyLibrary.update(data);
      if (!response) {
        return;
      }
      if (response.status === 200) {
        mainStore.toast.setText("Library Item Updated");
        history.push(
          `/workspaces/${workspaceID}/modules/key-risk-indicators/templates`,
        );
      }
    } else {
      const data = { companyID, name, riskCategory };
      const response =
        await mainStore.keyRiskIndicatorsCompanyLibrary.create(data);
      if (!response) {
        return;
      }
      if (response.status === 201) {
        mainStore.toast.setText("Library Item Created");
        history.push(
          `/workspaces/${workspaceID}/modules/key-risk-indicators/templates`,
        );
      }
    }
  }

  return (
    <button className="table-button header-action" onClick={handleClick}>
      Save Library Item
      {isSaving && <Spinner />}
    </button>
  );
}

export default observer(KRILibraryItemSaveButton);
