var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Command as CommandPrimitive } from "cmdk";
import * as React from "react";
import { cn } from "../../lib/utils";
var CommandItem = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement(CommandPrimitive.Item, __assign({ ref: ref, className: cn("tw-relative tw-flex tw-cursor-default tw-select-none tw-items-center tw-rounded-sm tw-px-3 tw-py-1.5 tw-font-sans tw-text-sm tw-font-medium tw-text-neutral-500 tw-outline-none", "aria-selected:tw-bg-primary-25 aria-selected:tw-text-primary-300", "data-[disabled='true']:tw-pointer-events-none data-[disabled='true']:tw-opacity-50", className) }, props)));
});
CommandItem.displayName = CommandPrimitive.Item.displayName;
export { CommandItem };
