import "./questionnaire-builder-question-user-trigger.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";

import { Flex, Icon, Typography } from "@/components/Elements";
import { UsersCircle } from "@/components/users-circle";
import { COLOR } from "@/config/theme";
import { User } from "@/stores/types/user-types";

type UserAssignmentProps = {
  assignedUsers?: User[];
  assigneeWord?: string;
  tooltip?: string | boolean;
  showPopup?: boolean;
  onOpen?: () => void;
  disabled?: boolean;
};

function QuestionnaireBuilderQuestionUserTrigger({
  assignedUsers = [],
  assigneeWord = "Reviewers",
  tooltip,
  showPopup,
  onOpen,
}: UserAssignmentProps) {
  return (
    <Flex className="questionnaire-builder-question-user-trigger">
      <span
        onClick={onOpen}
        className={classNames("user-assignment-wrapper", {
          ["user-assignment-wrapper__active"]: showPopup,
        })}
      >
        <Typography
          label={assigneeWord}
          size="sm"
          color={showPopup ? "generalWhite" : "generalMidnightDark"}
        />
        <Icon
          data-tooltip-id="tooltip"
          data-tooltip-place="top"
          data-tooltip-content={tooltip}
          name="plus"
          color={showPopup ? "generalWhite" : "generalBlack"}
        />
      </span>
      {assignedUsers && (
        <div className="user-assignment-flex">
          {assignedUsers
            .filter((u, index) => index < 3)
            .map((user, index) => (
              <div
                className={classNames({ "user-circle-assignment": index > 0 })}
                key={`user-trigger-${user.id}`}
              >
                <UsersCircle
                  initials={user.initials}
                  iconColorIndex={user.icon_color_index!}
                />
              </div>
            ))}
          {assignedUsers && assignedUsers.length > 3 && (
            <div className={"user-circle-assignment"}>
              <UsersCircle
                initials={`+${assignedUsers.length - 3}`}
                customColor={COLOR.generalDarkGray}
                iconColorIndex={0}
              />
            </div>
          )}
        </div>
      )}
    </Flex>
  );
}

export default observer(QuestionnaireBuilderQuestionUserTrigger);
