import { RAPartner } from "@/features/risk-assessment/types/partners";

export function getPartnerName(
  partners: RAPartner[],
  partnerID: string,
): string {
  const partner = partners.find((p) => p.id === parseInt(partnerID));

  if (!partner) {
    return "";
  }

  const partnerName = partner.record?.account?.name;
  return partnerName ? `${partnerName} -` : "";
}
