import {
  ConfirmationPopup,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  useToast,
} from "@themis/ui";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useParams } from "react-router-dom";

import { RecordType } from "@/api";
import { useDeleteTraining } from "@/api/queries/trainings";
import SlideMenu from "@/components/slideMenu/SlideMenu";
import { useMainStore } from "@/contexts/Store";
import { recordTypeToDisplayName } from "@/features/misc/helpers";
import SendThemisRecord from "@/features/misc/SendThemisRecord";

const DROPDOWN_ITEM_STYLE =
  "tw-flex tw-w-[228px] tw-items-center tw-justify-start tw-gap-1 tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primary-50 tw-px-4 tw-py-[10px] tw-justify-between";

// TODO: REFACTOR INTO THEMIS UI COMPONENT
export function ThemisRecordActionsCell({
  recordType,
  recordId,
}: {
  recordType: string;
  recordId: number;
}) {
  const toast = useToast();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const mainStore = useMainStore();
  const { isIW } = mainStore.context;
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const modelDisplayName = recordTypeToDisplayName[recordType];

  // TODO: Talk to frontend team about how to make dynamic
  const { mutate: deleteRecord } = useDeleteTraining({
    workspaceId: Number(workspace_id),
    onSuccess: () => {
      toast({
        content: `${modelDisplayName} has been removed!`,
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: `Failed to remove ${modelDisplayName.toLowerCase()}!`,
        variant: "error",
      });
    },
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  function handleDelete() {
    setIsConfirmationOpen(false);
    deleteRecord(recordId);
  }

  function handleClickDelete() {
    setIsConfirmationOpen(true);
  }

  function handleCancelDelete() {
    setIsConfirmationOpen(false);
  }

  function closeSendRecordSlideMenu() {
    setShowSlideMenu(false);
  }

  function openSendRecordSlideMenu() {
    setShowSlideMenu(true);
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton
            aria-label={`${modelDisplayName} Actions`}
            aria-describedby={`record-id-${recordId}`}
            Icon={PiDotsThreeOutlineVerticalFill}
            color="transparent"
            className="tw-h-8 tw-w-8 tw-rounded-md"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="tw-py-0">
          {isIW && (
            <DropdownMenuItem
              key="Add-to-shared-workspace"
              onClick={openSendRecordSlideMenu}
              className={DROPDOWN_ITEM_STYLE}
            >
              <span className="tw-text-sm tw-font-medium">
                Send to Workspace
              </span>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            key="Delete"
            aria-label={`Delete ${modelDisplayName}`}
            onClick={handleClickDelete}
            className={`${DROPDOWN_ITEM_STYLE} tw-border-b-0`}
          >
            <span className="tw-text-sm tw-font-medium">Delete</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ConfirmationPopup
        anchor
        align="end"
        title={`Delete ${modelDisplayName}`}
        content="Actions are non-reversible. Are you sure you want to proceed?"
        open={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
      />
      {showSlideMenu && (
        <SlideMenu
          open={showSlideMenu}
          closeSlideMenu={closeSendRecordSlideMenu}
        >
          <SendThemisRecord
            recordID={recordId}
            recordType={recordType as RecordType}
            modelDisplayName={modelDisplayName}
            closeSlideMenu={closeSendRecordSlideMenu}
          />
        </SlideMenu>
      )}
    </>
  );
}
