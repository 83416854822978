import { useQuery } from "@tanstack/react-query";

import { LinkedFindingRecordLink } from "@/features/monitoring-and-testing/types";

import { RecordVersion } from "../gen";
import api from "../legacy/api";

export const QA_TESTS_QUERY_KEY = "qa_tests";

export function useQaTests() {
  return useQuery({
    queryKey: [QA_TESTS_QUERY_KEY],
    queryFn: () =>
      api.get<{
        record_versions: (RecordVersion & {
          linked_finding_record_links: LinkedFindingRecordLink[];
        })[];
      }>("/qa_tests_developments"),
  });
}
