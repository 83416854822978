import "./questionnaire-group.scss";

import { Droppable } from "@akojic27/react-beautiful-dnd";
import classNames from "classnames";
import React from "react";

import { IconButton, MiniTag, Typography } from "@/components/Elements";
import { getDroppableIdFromQuestionnaireGroupId } from "@/components/helpers/draggable";
import { ConfirmationPopup } from "@/modules/shared/confirmation-popup/ConfirmationPopup";
import { getQuestionsRemainingForGroups } from "@/stores/helpers/RiskAssessmentHelpers";

import { QuestionGroup } from "../../../types/questionnaire";
import { FormQuestionGroup } from "../../../types/questionnaire-form";

type QuestionnaireGroupProps = {
  group: QuestionGroup | FormQuestionGroup;
  isActive: boolean;
  isEditing: boolean;
  onSelect: (id: number) => void;
  onNameChange: (id: number, name: string) => void;
  onDelete?: (id: number) => void;
  isDroppable?: boolean;
};

const QuestionnaireGroup = (props: QuestionnaireGroupProps) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'provided' implicitly has an 'any' type.
  const renderContent = (provided?, snapshot?) => (
    <div
      key={props.group.id}
      className={classNames("questionnaire-group", {
        "questionnaire-group--active": props.isActive,
        "questionnaire-group--editing": props.isEditing,
        "questionnaire-group--dragged-on": snapshot?.isDraggingOver,
      })}
      onClick={() => !props.isEditing && props.onSelect(props.group.id)}
      ref={provided?.innerRef}
      {...provided?.droppableProps}
    >
      {props.isEditing && (
        <>
          <input
            autoFocus={props.isActive}
            type="text"
            value={props.group.name}
            onChange={(e) => props.onNameChange(props.group.id, e.target.value)}
          />
          {props.onDelete && (
            <ConfirmationPopup
              text="Are you sure you want to delete this section?"
              onConfirm={() => props.onDelete?.(props.group.id)}
              trigger={
                <IconButton
                  className="questionnaire-group__delete-button"
                  icon="trash"
                  transparent
                />
              }
            />
          )}
        </>
      )}
      {!props.isEditing && (
        <>
          <Typography
            label={props.group.name}
            color={
              props.isActive ? "brandingHighlightViolet" : "generalDarkGray"
            }
          />
          <MiniTag
            style={{ minWidth: "24px" }}
            label={String(
              getQuestionsRemainingForGroups([
                props.group as FormQuestionGroup,
              ]),
            )}
          />
        </>
      )}
      <div style={{ display: "none" }}>{provided?.placeholder}</div>
    </div>
  );
  if (props.isDroppable) {
    return (
      <Droppable
        droppableId={getDroppableIdFromQuestionnaireGroupId(props.group.id)}
      >
        {renderContent}
      </Droppable>
    );
  }
  return renderContent();
};

export default QuestionnaireGroup;
