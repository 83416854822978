import React from "react";

export enum SpinnerSize {
  small = "small",
  default = "default",
}
type SpinnerProps = {
  size?: SpinnerSize;
};
export default function Spinner(props: SpinnerProps) {
  const renderSpinnerBlock = Array(12)
    .fill(0)
    .map((_, id) => (
      <div
        key={id}
        className={`lds-spinner__tick lds-spinner__tick--${
          props.size || SpinnerSize.default
        }`}
      />
    ));

  return (
    <div
      className={`lds-spinner lds-spinner--${
        props.size || SpinnerSize.default
      }`}
    >
      {renderSpinnerBlock}
    </div>
  );
}
