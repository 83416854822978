import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

// @ts-expect-error TS(2307) FIXME: Cannot find module '../../../../assets/docs/risk_a... Remove this comment to see the full error message
import sampleFile from "../../../../assets/docs/risk_assessment_sample.xlsx";
import exampleImage from "../../../images/risk-assessment-example.webp";
import { Button } from "../../Elements";

type Props = {
  riskAssessmentID: number;
};

function ImportMatrix({ riskAssessmentID }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // refs
  const inputRef = useRef(null);

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Functions
  function onPopupOpen() {
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
  }

  function handleDownload() {
    sampleFile.name;
    const link = document.createElement("a");
    link.href = sampleFile;
    link.download = "risk_assessment_import_sample.xlsx";
    link.click();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  async function processCSV(event) {
    const [file] = event.target.files;
    await mainStore.vendors.importMatrix(file, riskAssessmentID);

    onPopupClose();
  }

  // elements
  const renderTrigger = (
    <div>
      <Button size="sm" theme="secondary" label="Import" active={showPopup} />
    </div>
  );

  const renderDownloadButton = (
    <Button
      icon="download"
      size="sm"
      theme="tertiary"
      label="Download Template"
      onClick={handleDownload}
    />
  );

  const renderUploadProvider = (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <DirectUploadProvider
      render={() => (
        <>
          <Button
            icon="upload"
            size="sm"
            label="Import XLSX File"
            theme="primary"
            // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
            onClick={() => inputRef.current.click()}
          />
          <input ref={inputRef} type="file" onChange={processCSV} />
        </>
      )}
    />
  );

  const renderPopupContent = (
    <div className="vdd-risk-assessment__import-popup">
      <p>
        Format excel with a list of questions in the following example format
      </p>
      <ul>
        <li>Each column is a risk level (Low / Medium / High etc.)</li>
        <li>Each row is a risk theme.</li>
        <li>
          The content of a cell will be the text for each level of a given
          theme.
        </li>
      </ul>
      <img src={exampleImage} alt="example-image" />
      <div className="vdd-risk-assessment__import-popup-actions">
        {renderDownloadButton}
        {renderUploadProvider}
      </div>
    </div>
  );

  return (
    <Popup
      position="bottom right"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
      nested
    >
      {renderPopupContent}
    </Popup>
  );
}

export default observer(ImportMatrix);
