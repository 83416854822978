import { observer } from "mobx-react";
import React from "react";
import { useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import downloadIcon from "../../images/table-image/icon/download-white.svg";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setShowPopup?: (...args: any[]) => any;
  customFileExportFunction?: () => void;
};

function ExportFiles({ setShowPopup, customFileExportFunction }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { tableID } = mainStore.context;
  const { user } = mainStore.users;
  const { folder_id } = useParams<{ folder_id: string }>();

  async function exportFiles() {
    if (customFileExportFunction) {
      await customFileExportFunction();
      return;
    }
    if (!tableID || !user.id) {
      return;
    }
    await mainStore.tables.exportFiles(tableID, user.id, parseInt(folder_id));
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setShowPopup(false);
  }

  return (
    <div className="table-dropdown export-table-dropdown">
      <h3>Export Files</h3>
      <p>Click the button below to export all files in this module.</p>
      <p>Your documents will be added to a ZIP file before downloading.</p>
      <button className="export-table-button" onClick={exportFiles}>
        <img src={downloadIcon} alt="download-icon" />
        Export Files
      </button>
    </div>
  );
}

export default observer(ExportFiles);
