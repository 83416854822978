import classNames from "classnames";
import React from "react";

type Props = {
  isInternal: boolean;
  logo: string;
  membersCount: number;
  name: string;
};

function DashboardMenuPopup({ logo, name, isInternal, membersCount }: Props) {
  const frameClasses = classNames({
    collaborative: !isInternal,
  });

  const spanTitle = isInternal ? "Internal" : "Collaborative";

  return (
    <div className="dashboard-popup-element">
      <img className="w-55" src={logo} alt="image-icon" />
      <div className="dashboard-popup-block">
        <h3>{name}</h3>
        <div className="dashboard-popup-text">
          <span className={frameClasses}>{spanTitle}</span>
          {membersCount} members
        </div>
      </div>
    </div>
  );
}

export default DashboardMenuPopup;
