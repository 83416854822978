import {
  Avatar,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@themis/ui";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { getWorkspaceLogo } from "@/components/helpers/getWorkspaceLogo";
import { Workspace } from "@/stores/types/workspace-types";

const DEFAULT_COUNT = 3;

type Props = {
  workspaces: Workspace[];
  displayCount?: number;
  text?: string;
};

function ListWorkspaces({ workspaces, displayCount, text }: Props) {
  const [open, setOpen] = useState(false);
  const countNumber = displayCount || DEFAULT_COUNT;

  const popup = (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger
        asChild
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <Avatar
          size="sm"
          variant="basic"
          color="gray"
          className="tw-cursor-pointe tw-absolute tw-flex tw-h-7 tw-w-7 tw-items-center tw-justify-center tw-rounded-full tw-bg-neutral-50 tw-text-xs tw-font-semibold tw-text-neutral-500"
          style={{ left: `${32 * countNumber}px` }}
        >
          +{workspaces.length - countNumber}
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="center"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        className="tw-max-h-72 tw-overflow-y-auto"
      >
        {workspaces.slice(countNumber, workspaces.length).map((workspace) => (
          <DropdownMenuItem
            className="tw-flex tw-h-[24px] tw-w-full tw-items-center tw-gap-2 tw-py-2.5 tw-pl-2.5 tw-pr-8"
            key={workspace.id}
          >
            <img
              key={workspace.id}
              src={getWorkspaceLogo(workspace).logo}
              alt={workspace?.name}
              className="tw-h-5 tw-w-5 tw-rounded-md"
            />
            <div className="tw-text-sm tw-font-normal tw-text-neutral-500">
              {workspace.name}
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );

  function renderWorkspaces() {
    return (
      <span className="tw-flex tw-h-5 tw-items-center tw-space-x-3 tw-bg-neutral-25 tw-px-3 tw-py-1.5 tw-text-xs tw-font-normal">
        {text && (
          <div className="tw-pr-2 tw-text-xs tw-font-normal tw-text-neutral-300">
            {text}
          </div>
        )}
        <div
          className="tw-relative tw-h-5"
          style={{
            width:
              workspaces.length > countNumber
                ? "92px"
                : `${30 * workspaces.length}px`,
          }}
          data-testid="view-module-users"
        >
          {workspaces.slice(0, countNumber).map((workspace, index) => (
            <img
              key={workspace.id}
              src={getWorkspaceLogo(workspace).logo}
              alt={workspace?.name}
              className="tw-absolute tw-h-7 tw-w-7 tw-cursor-default tw-rounded-md"
              style={{ left: `${31 * index}px` }}
            />
          ))}
          {workspaces.length > countNumber && popup}
        </div>
      </span>
    );
  }

  return renderWorkspaces();
}

export default observer(ListWorkspaces);
