import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import RegularTextInput from "@/components/table/shared/RegularTextInput";
import SimpleOptionSelect from "@/components/table/shared/SimpleOptionSelect";
import { useMainStore } from "@/contexts/Store";

import KRILibraryItemSaveButton from "../components/KRILibraryItemSaveButton";

const KRICompanyLibraryNewPage = () => {
  const history = useHistory();
  const mainStore = useMainStore();

  const companyID = mainStore.companies.company?.id;
  const { libraryRecords } = mainStore.keyRiskIndicatorsCompanyLibrary;

  const { riskCategories } = mainStore.keyRiskIndicatorsCompanyRiskCategories;

  const addNewRiskCategory = (value: string) => {
    if (companyID) {
      mainStore.keyRiskIndicatorsCompanyRiskCategories.create({
        companyID,
        name: value,
      });
    }
  };
  useEffect(() => {
    if (companyID && !libraryRecords.length) {
      mainStore.keyRiskIndicatorsCompanyLibrary.index({ companyID });
    }
  }, [companyID, libraryRecords, mainStore.keyRiskIndicatorsCompanyLibrary]);

  useEffect(() => {
    if (companyID) {
      mainStore.keyRiskIndicatorsCompanyRiskCategories.index({ companyID });
    }
  }, [companyID, mainStore.keyRiskIndicatorsCompanyRiskCategories]);

  const handleSelect = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedOption: any,
    field: "name" | "riskCategory",
  ) => {
    if (field === "name") {
      mainStore.keyRiskIndicatorsCompanyLibrary.setEditedName(selectedOption);
    } else {
      mainStore.keyRiskIndicatorsCompanyLibrary.setEditedRiskCategory(
        selectedOption.name,
      );
    }
  };

  return (
    <DashboardContent>
      <DashboardHeader
        title={"New Library Record"}
        onBackClick={() => history.goBack()}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<KRILibraryItemSaveButton isExistingItem={false} />}
      />
      <DashboardContentWrapper>
        <div className="detail-view kri-library-detail-view template">
          <section>
            <div className="section-row two-cols">
              <div className="column column-input">
                <h4>Risk Category</h4>
                <SimpleOptionSelect
                  placeholder="Select a risk category"
                  selectedOption={{
                    name: mainStore.keyRiskIndicatorsCompanyLibrary
                      .editedRiskCategory,
                    styles: { color: "#353549" },
                  }}
                  availableOptions={riskCategories.map((category) => ({
                    name: category.name,
                  }))}
                  handleSelect={(value) => handleSelect(value, "riskCategory")}
                  enableAddNew
                  onAddNew={addNewRiskCategory}
                />
              </div>
              <div className="column column-input">
                <h4>Name</h4>
                <RegularTextInput
                  initialValue={""}
                  width="100%"
                  handleOnBlur={(value) => handleSelect(value, "name")}
                />
              </div>
            </div>
          </section>
        </div>
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default observer(KRICompanyLibraryNewPage);
