import { observer } from "mobx-react";
import React from "react";
import { DirectUploadProvider } from "react-activestorage-provider";

import { useMainStore } from "@/contexts/Store";

import fileAddGrayIcon from "../../../images/table-image/icon/file-add-gray-icon.svg";
import Spinner from "./Spinner";

type Props = {
  attachmentUpload: (ids: string[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setUpload: (...args: any[]) => any;
  upload: string;
  multiple?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  supportedFileTypes?: any[];
};

function DirectUpload({
  attachmentUpload,
  upload,
  setUpload,
  multiple,
  supportedFileTypes,
}: Props) {
  // Import MobX store
  const mainStore = useMainStore();

  const { themisModuleIdentifier } = mainStore.context;
  const isCreative = themisModuleIdentifier === "marketing";
  const fileTypes = isCreative ? ["jpeg", "png", "pdf"] : supportedFileTypes;

  // @ts-expect-error TS(7031) FIXME: Binding element 'uploads' implicitly has an 'any' ... Remove this comment to see the full error message
  const uploadState = ({ uploads }) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
    const states = uploads.map((elem) => elem.state);
    let [currentState] = states;
    if (states.includes("uploading")) {
      currentState = "uploading";
    } else if (states.includes("waiting")) {
      currentState = "waiting";
      // @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
    } else if (states.filter((state) => state !== "finished").length === 0) {
      currentState = "finished";
    }
    window.console.log("currentState:", currentState);

    // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
    const progresses = uploads.map((elem) => elem.progress);
    let totalProgress =
      // @ts-expect-error TS(7006) FIXME: Parameter 'total' implicitly has an 'any' type.
      progresses.reduce((total, progress) => total + progress, 0) /
      progresses.length;
    if (Number.isNaN(totalProgress)) {
      totalProgress = 100;
    }
    switch (currentState) {
      case "waiting":
        return (
          <div className="uploading">
            <p>0%</p>
            <Spinner />
          </div>
        );
      case "uploading":
        return (
          <div className="uploading">
            <p>{Math.round(totalProgress)}%</p>
            <Spinner />
          </div>
        );
      case "finished":
        return null;
      default:
        if (states.includes("error")) {
          const erroredUploads = uploads.filter(
            // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
            (elem) => elem.state === "error",
          );
          return (
            <>
              {
                // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
                erroredUploads.map((elem) => (
                  <p key={elem.id}>
                    Error uploading {elem.file.name}: {elem.error}
                  </p>
                ))
              }
            </>
          );
        }
        return null;
    }
  };

  return (
    <DirectUploadProvider
      onSuccess={attachmentUpload}
      onError={(e) => {
        mainStore.toast.setInfoText("Error uploading files");
        window.console.error("Error uploading files", e);
      }}
      multiple={multiple}
      render={({ handleUpload, uploads }) => (
        <div data-testid="direct-upload-wrapper">
          <div className="drag-drop-wrap">
            <div className="drag-drop-block">
              {!upload && (
                <div>
                  <img src={fileAddGrayIcon} alt="file-add-gray-icon.svg" />
                  <p>
                    Drag & drop into this box
                    <br />(
                    {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
                    {fileTypes
                      .map((fileType) => fileType.toUpperCase())
                      .join(" / ")}{" "}
                    can be viewed in Themis.)
                    <br />- or -
                  </p>
                  <button>Choose a {isCreative ? "creative" : "file"}</button>
                  <input
                    type="file"
                    // @ts-expect-error TS(2339) FIXME: Property 'files' does not exist on type 'EventTarg... Remove this comment to see the full error message
                    onDrop={(event) => setUpload(event.target.files[0])}
                    onChange={(event) => {
                      setUpload(
                        event.target.files ? event.target.files[0] : null,
                      );
                      handleUpload(event.currentTarget.files);
                    }}
                    multiple={multiple}
                    data-testid="direct-upload-input"
                  />
                </div>
              )}
              {upload && uploadState({ uploads })}
            </div>
          </div>
        </div>
      )}
    />
  );
}

DirectUpload.defaultProps = {
  multiple: false,
  supportedFileTypes: ["pdf", "docx", "png", "jpg", "mov", "mp4", "csv", "gif"],
};

export default observer(DirectUpload);
