import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import warningIcon from "../../../../images/table-image/icon/close-black-icon.svg";
import { userColors } from "../../../constants";
import UsersCircle from "../UsersCircle";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  fieldName?: string;
  isLocked?: boolean;
};

function ViewAssignedUsers({ recordVersion, fieldName, isLocked }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { users } = mainStore.users;
  const selectedIDs = mainStore.avroSchemas.valueForField(
    fieldName,
    recordVersion?.data,
  );
  const filteredUsers = users.filter((user) => selectedIDs?.includes(user.id));

  // @ts-expect-error TS(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
  const onRemove = (userID) => {
    mainStore.recordVersions.update({
      fieldName,
      recordVersionID: recordVersion.id,

      value: mainStore.avroSchemas.serializeValue(
        fieldName,
        // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
        selectedIDs.filter((id) => id !== userID),
      ),
    });
  };

  if (filteredUsers.length === 0) {
    return (
      <div className="table-row empty" data-testid="no-approvers">
        {isLocked ? "No Assigned users added." : "No Assigned users added yet."}
      </div>
    );
  }

  return (
    <>
      {filteredUsers.map((user) => (
        <div key={user.id} className="table-row" data-testid="table-row">
          <UsersCircle
            user={user}
            additionalStyles={{
              background: isLocked
                ? "#C3C3C8"
                : userColors[user.icon_color_index],
            }}
          />
          <div className="policy-approval-status">
            {!isLocked && (
              <div className="table-row-actions">
                <div
                  className="remove"
                  data-testid="remove-approved-user-button"
                  onClick={() => onRemove(user.id)}
                >
                  <img src={warningIcon} alt="remove" />
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

export default observer(ViewAssignedUsers);
