import { useEffect, useState } from "react";

import { TableName } from "@/api";
import { pathMatchesPattern } from "@/utils/routing";

type TableRoutePattern = {
  [key: string]: TableName;
};

const tableRoutePatterns: TableRoutePattern = {
  "/workspaces/:workspace/modules/customer-support/compliance": "Compliance",
  "/workspaces/:workspace/modules/customer-support/support": "Customer Support",
  "/workspaces/:workspace/modules/finra/escalated": "FINRA Escalated",
  "/workspaces/:workspace/modules/finra/review": "FINRA Review",
  "/workspaces/:workspace/modules/policy/archived": "Archived",
  "/workspaces/:workspace/modules/policy/attestation": "PoliciesAttestation",
  "/workspaces/:workspace/modules/policy/drafts": "Drafts",
  "/workspaces/:workspace/modules/policy/finalized": "Finalized",
  "/workspaces/:workspace/modules/procedures/archived": "ProceduresArchived",
  "/workspaces/:workspace/modules/procedures/attestation":
    "ProceduresAttestation",
  "/workspaces/:workspace/modules/procedures/drafts": "ProceduresDrafts",
  "/workspaces/:workspace/modules/procedures/finalized": "ProceduresFinalized",
  "/workspaces/:workspace/modules/risk-assessment": "Partners",
  "/workspaces/:workspace/modules/zendesk/completed": "Completed",
  // Order matters here :(
  "/workspaces/:workspace/modules/policy/:record_version_id": "Drafts",
};

export function useTableDetection(pathname: string): TableName | null {
  // State
  const [tableName, setTableName] = useState<TableName | null>(null);

  const pattern = Object.keys(tableRoutePatterns).find((routePattern) => {
    if (pathMatchesPattern(pathname, routePattern)) {
      return routePattern;
    }

    return null;
  });

  useEffect(() => {
    let newTableName: TableName = "Default";
    if (pattern) {
      newTableName = tableRoutePatterns[pattern];
    }
    setTableName(newTableName);
  }, [pathname]);

  return tableName;
}
