import "./table-header-cell.scss";

import classNames from "classnames";
import React from "react";
import Popup from "reactjs-popup";

import { Icon, Typography } from "@/components/Elements";

type TableHeaderCellProps = {
  title: string;
  identifier?: string;
  firstDataHeader?: boolean;
  lastDataHeader?: boolean;
  leftIcon?: React.ReactElement;
  sortDirection?: SortOptions;
  fixedWidth?: boolean;
  setSort?: (fieldName: string, direction: SortOptions) => void;
};

const TableHeaderCell = (props: TableHeaderCellProps) => {
  const trigger = (
    <th>
      <div
        className={classNames("genric-table-header-cell", {
          "genric-table-header-cell--first-data-cell": props.firstDataHeader,
          "genric-table-header-cell--last-data-cell": props.lastDataHeader,
          "genric-table-header-cell--fixed-width": props.fixedWidth,
        })}
      >
        {props.leftIcon}
        <span className="generic-table-title-block">
          <Typography type="span" label={props.title} size="sm" />
        </span>
        <div className="generic-table-header-sort-icon">
          {props.sortDirection && (
            <Icon
              name={props.sortDirection === "ASC" ? "arrowDown" : "arrowUp"}
              color="generalDark"
            />
          )}
        </div>
      </div>
    </th>
  );

  if (!props.setSort) {
    return trigger;
  }

  return (
    <Popup position="bottom right" trigger={trigger}>
      <div
        className="table-dropdown title-select title-select-generic-table"
        data-testid="column-dropdown"
      >
        <ul>
          <li
            data-testid="sort-asc-button"
            className="enabled"
            onClick={() =>
              props.identifier &&
              props.setSort &&
              props.setSort(props.identifier, "ASC")
            }
          >
            <Icon name="arrowDown" color="generalDarkGray" />
            Sort Ascending
          </li>
          <li
            data-testid="sort-desc-button"
            className="enabled"
            onClick={() =>
              props.identifier &&
              props.setSort &&
              props.setSort(props.identifier, "DESC")
            }
          >
            <Icon name="arrowUp" color="generalDarkGray" />
            Sort Descending
          </li>
        </ul>
      </div>
    </Popup>
  );
};

export default TableHeaderCell;
