import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";

import { User } from "@/stores/types/user-types";

const APP_IDS_BY_ENV: Record<string, string> = {
  // Config is commented out to prevent dev sessions from taking up logrocket capacity
  // To test logrocket changes locally uncomment this configuration and follow these steps
  // https://www.notion.so/Test-Logrocket-locally-ngrok-af58f18db3e9483ba75938377bb48c1e
  // development: 'v3fubn/dev',
  uat: "v3fubn/uat-tpyza",
  preprod: "v3fubn/preprod-ifrh0",
  production: "v3fubn/production-vs6gr",
};

export function initLogRocket() {
  const environment = document.querySelector<HTMLMetaElement>(
    'meta[name="environment"]',
  )?.content;

  const appID = environment && APP_IDS_BY_ENV[environment];
  if (appID === undefined) {
    return;
  }

  LogRocket.init(appID, {
    dom: {
      inputSanitizer: environment === "production",
    },
    network: {
      requestSanitizer: (request) => {
        if (request.url.toLowerCase().indexOf("web_sessions") !== -1) {
          // ignore the request response pair
          return null;
        }

        if (request.headers["X-Session-Token"]) {
          request.headers["X-Session-Token"] = "";
        }

        // otherwise log the request normally
        return request;
      },
    },
  });

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setContext("logRocket", { sessionURL });
    });
  });
}

export function identifyUser(currentUser: Partial<User>) {
  if (currentUser && currentUser.id) {
    LogRocket.identify(currentUser.id.toString(), {
      name: currentUser.full_name || "",
      email: currentUser.email || "",
      department: currentUser.department?.title || "",
    });
  }
}
