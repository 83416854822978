import React from "react";

import { DashboardComponent } from "@/api";
import chartEmptyState from "@/images/dashboard/chart_empty_state.svg";

import { formatMultiKeyBarChartData } from "../../utils";
import { StackedBarChart } from "../charts/StackedBarChart";
import { TruncatedLegend } from "../TruncatedLegend";

export default function IssuesByRisk({
  widgetData,
}: {
  widgetData: DashboardComponent["data"];
}) {
  const formattedWidgetData = formatMultiKeyBarChartData({
    widgetData,
    essentialSeriesKeys: ["low", "medium", "high"],
    hasLowMediumHigh: true,
  });

  return formattedWidgetData.data.length < 1 ? (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Issues by Risk
        </div>
      </div>
      <div>
        <div className="tw-h-[212px] tw-w-full">
          <img
            src={chartEmptyState}
            alt="close-icon"
            className="tw-h-[212px] tw-w-full"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Issues by Risk
        </div>
      </div>
      <TruncatedLegend
        hasLowMediumHigh
        seriesNames={formattedWidgetData.keys}
      />
      <div>
        <div className="tw-h-[190px] tw-w-full">
          <StackedBarChart
            wireKey="total"
            data={formattedWidgetData.data}
            keys={formattedWidgetData.keys}
            colors={formattedWidgetData.colors}
            isMultiXLines
          />
        </div>
      </div>
    </div>
  );
}
