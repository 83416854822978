import "./partners.scss";

import { format, isPast, parseISO } from "date-fns";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiLockSimpleBold } from "react-icons/pi";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { Icon, Typography } from "@/components/Elements";
import { formatPercentage } from "@/components/helpers/number-formatters";
import {
  QuestionnaireRead,
  RAPartner,
  RiskMethodology,
  ROUTES,
} from "@/features/risk-assessment";
import QuestionnaireDueDateModal from "@/features/risk-assessment/components/Questionnaires/QuestionnaireDueDateModal/QuestionnaireDueDateModal";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import Table from "@/features/risk-assessment/components/Table/GenericTable/Table";
import TableHeaderCell from "@/features/risk-assessment/components/Table/GenericTable/TableHeaderCell";
import TableRow from "@/features/risk-assessment/components/Table/GenericTable/TableRow";
import { getDefinitionsFromScores } from "@/features/risk-assessment/utils/scoring-helpers";
import { ActiveWorkspace, User } from "@/stores/types/user-types";

import { ScoreCell } from "../Assessment/AssessmentScoring/ScoreCell";
import MiniTag from "../MiniTag/MiniTag";
import QuestionareExport from "../Questionnaires/QuestionaireExport/QuestionareExport";
import RowOptions from "../RowOptions/RowOptions";
import ActionCell from "../Table/GenericTable/Cells/ActionCell";
import UserCircleCell from "../Table/GenericTable/Cells/UserCircleCell";
import LongTextTableCell from "../Table/GenericTable/LongTextTableCell";
import TableCellSpacer from "../Table/GenericTable/TableCellSpacer";

type Props = {
  setNewDueDate?: (date: Date | undefined, questionnaireID: number) => void;
  partnerQuestionnaires?: Array<QuestionnaireRead>;
  riskMethodologies?: Array<RiskMethodology>;
  activeWorkspace?: ActiveWorkspace;
  partners?: Array<RAPartner>;
  displayPartnerColumn?: boolean;
  onUploadQuestionnaireAnswers: (questionnaireId: number) => void;
  onCopyLink: (externalId: string) => void;
  onDeleteQuestionnaire: (questionnaireId: number) => void;
  onSendQuestionnaire: (questionnaireId: number) => void;
  onSendQuestionnaireToCW: (questionnaireId: number) => void;
  onUnlockReviewQuestionnaire: (
    questionnaireId: number,
    status: string,
  ) => void;
  onCopyPassword: (password: string) => void;
  accessUsers?: User[];
};

const PartnerQuestionnaireSection = ({
  setNewDueDate,
  partnerQuestionnaires,
  riskMethodologies,
  activeWorkspace,
  partners,
  displayPartnerColumn = false,
  onUploadQuestionnaireAnswers,
  onCopyLink,
  onDeleteQuestionnaire,
  onSendQuestionnaire,
  onSendQuestionnaireToCW,
  onUnlockReviewQuestionnaire,
  onCopyPassword,
  accessUsers,
}: Props) => {
  const history = useHistory();
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const [questionnaireToUpdate, setQuestionnaireToUpdate] =
    useState<QuestionnaireRead | null>(null);

  if (!partnerQuestionnaires) {
    return <div />;
  }

  const canImportAnswers = (questionnaire: QuestionnaireRead) => {
    if (!questionnaire.is_internal) {
      return false;
    }
    if (!activeWorkspace?.is_internal) {
      return false;
    }
    if (
      questionnaire.status !== "not_started" &&
      questionnaire.status !== "incomplete"
    ) {
      return false;
    }
    return true;
  };

  const isDueDateInPast = (questionnaire: QuestionnaireRead): boolean => {
    const { due_date, status } = questionnaire;

    if (!due_date || !["incomplete", "not_started"].includes(status)) {
      return false;
    }

    return isPast(parseISO(due_date.toString()));
  };

  return (
    <Table
      className="ra-dashboard-assessments__table"
      header={
        <>
          <TableHeaderCell
            title="Status"
            leftIcon={<Icon name="status" color="extrasBlueGrayDarker" />}
            firstDataHeader
            fixedWidth
          />
          <TableHeaderCell
            title="Questionnaire Name"
            leftIcon={<Icon name="text" color="extrasBlueGrayDarker" />}
          />
          {displayPartnerColumn && (
            <TableHeaderCell
              title="Partner Name"
              leftIcon={<Icon name="status" color="extrasBlueGrayDarker" />}
              fixedWidth
            />
          )}
          <TableHeaderCell
            title="Overall Score"
            leftIcon={<Icon name="status" color="extrasBlueGrayDarker" />}
            fixedWidth
          />
          <TableHeaderCell
            title="Inherent Risk"
            leftIcon={<Icon name="status" color="extrasBlueGrayDarker" />}
            fixedWidth
          />
          <TableHeaderCell
            title="Control Risk"
            leftIcon={<Icon name="status" color="extrasBlueGrayDarker" />}
            fixedWidth
          />
          <TableHeaderCell
            title="Residual Risk"
            leftIcon={<Icon name="status" color="extrasBlueGrayDarker" />}
            fixedWidth
          />
          <TableHeaderCell
            title="Due Date"
            leftIcon={<Icon name="calendar" color="extrasBlueGrayDarker" />}
            fixedWidth
          />
          <TableHeaderCell
            title="Visibility"
            leftIcon={<Icon name="book2" color="extrasBlueGrayDarker" />}
            fixedWidth
          />
          <TableHeaderCell
            title="Percentage Answered"
            leftIcon={<Icon name="status" color="extrasBlueGrayDarker" />}
            fixedWidth
          />
          <TableHeaderCell
            title="Inherent Questions"
            leftIcon={<Icon name="number" color="extrasBlueGrayDarker" />}
            fixedWidth
          />
          <TableHeaderCell
            title="Control Questions"
            leftIcon={<Icon name="number" color="extrasBlueGrayDarker" />}
            fixedWidth
          />
          <TableHeaderCell
            title="Respondents"
            leftIcon={<Icon name="user" color="extrasBlueGrayDarker" />}
            fixedWidth
          />
          <TableHeaderCell
            title="Reviewers"
            leftIcon={<Icon name="user" color="extrasBlueGrayDarker" />}
            fixedWidth
            lastDataHeader
          />
          <TableCellSpacer />
        </>
      }
    >
      <>
        {partnerQuestionnaires.map(
          (questionnaire: QuestionnaireRead, i: number) => {
            const methodology = riskMethodologies?.find(
              (rm) => rm.id === questionnaire.risk_methodology_id,
            );
            const {
              overallRiskRating,
              controlRiskRating,
              inherentRiskRating,
              residualRiskRating,
            } = getDefinitionsFromScores(questionnaire.scoring!, methodology!);
            const partner = partners?.find(
              (p) => p.id === questionnaire.record_version_id,
            );

            const dueInPast = isDueDateInPast(questionnaire);

            return (
              <TableRow key={questionnaire.id} rowIndex={i}>
                <ContentCell
                  content={<MiniTag label={questionnaire.status} />}
                  isFirstDataCell
                />
                <LongTextTableCell
                  endIcon={
                    questionnaire.external_password && (
                      <PiLockSimpleBold
                        data-tooltip-id="tooltip"
                        data-tooltip-content="Password Protected Questionnaire"
                        className="tw-h-4 tw-w-4"
                      />
                    )
                  }
                >
                  {questionnaire.name}
                </LongTextTableCell>
                {displayPartnerColumn && (
                  <ContentCell
                    isLinkCell
                    content={
                      partner?.record?.account?.name || partner?.global_id
                    }
                    onClick={() =>
                      history.push(
                        generatePath(ROUTES.PARTNERS_DETAILS_EDIT_PATH, {
                          record_version_id: partner?.id,
                          workspace_id,
                        }),
                      )
                    }
                  />
                )}
                <ScoreCell
                  rating={overallRiskRating}
                  score={questionnaire?.scoring?.overall_risk_score}
                />
                <ScoreCell
                  rating={inherentRiskRating}
                  score={questionnaire?.scoring?.inherent_risk_score}
                />
                <ScoreCell
                  rating={controlRiskRating}
                  score={questionnaire?.scoring?.control_risk_score}
                />
                <ScoreCell rating={residualRiskRating} />
                <ContentCell
                  hasErrors={dueInPast}
                  content={
                    <Typography
                      weight="semiBold"
                      label={
                        questionnaire.due_date
                          ? format(
                              parseISO(questionnaire.due_date.toString()),
                              "MM/dd/yyyy",
                            )
                          : "N/A"
                      }
                      color={
                        dueInPast ? "generalError" : "extrasBlueGrayDarker"
                      }
                    />
                  }
                />
                <ContentCell
                  content={
                    <MiniTag
                      label={questionnaire.is_internal ? "internal" : "public"}
                    />
                  }
                  isFirstDataCell
                />
                <ContentCell
                  content={
                    <Typography
                      weight="semiBold"
                      label={formatPercentage(
                        questionnaire.completion_percentage,
                      )}
                      color="extrasBlueGrayDarker"
                    />
                  }
                />
                <ContentCell
                  content={
                    <Typography
                      weight="semiBold"
                      label={questionnaire.inherent_questions_count}
                      color="extrasBlueGrayDarker"
                    />
                  }
                />
                <ContentCell
                  content={
                    <Typography
                      weight="semiBold"
                      label={questionnaire.control_questions_count}
                      color="extrasBlueGrayDarker"
                    />
                  }
                />
                <UserCircleCell
                  userIds={questionnaire.responder_ids}
                  accessUsers={accessUsers}
                />
                <UserCircleCell
                  userIds={questionnaire.reviewer_ids}
                  accessUsers={accessUsers}
                />

                <ActionCell>
                  <RowOptions
                    iconOverrideClass="row-options--risk-assessment-table-context-menu-icon"
                    options={[
                      {
                        label:
                          questionnaire.status === "finalized"
                            ? "Unlock Questionnaire"
                            : "Review Questionnaire",
                        onClick: () =>
                          onUnlockReviewQuestionnaire(
                            questionnaire.id,
                            questionnaire.status,
                          ),
                        hide:
                          questionnaire.status === "not_started" ||
                          questionnaire.status === "incomplete",
                      },
                      {
                        divider: true,
                        hide:
                          questionnaire.status === "not_started" ||
                          questionnaire.status === "incomplete",
                      },
                      {
                        label: "Copy Link",
                        hide: questionnaire.is_internal,
                        onClick: () => onCopyLink(questionnaire.external_id),
                      },
                      {
                        label: "Copy Password",
                        hide:
                          questionnaire.is_internal ||
                          !questionnaire.external_password,
                        onClick: () =>
                          onCopyPassword(questionnaire.external_password!),
                      },
                      {
                        divider: true,
                      },
                      {
                        label: "Send Questionnaire",
                        hide: questionnaire.is_internal,
                        onClick: () => onSendQuestionnaire(questionnaire.id),
                      },
                      {
                        label: "Send to Collaborative Workspace",
                        onClick: () =>
                          onSendQuestionnaireToCW(questionnaire.id),
                      },
                      {
                        divider: true,
                      },
                      {
                        label: "Edit Due Date",
                        onClick: () => setQuestionnaireToUpdate(questionnaire),
                        hide: [
                          "ready_for_review",
                          "in_review",
                          "finalized",
                        ].includes(questionnaire.status),
                      },
                      {
                        label:
                          questionnaire.status === "finalized"
                            ? "View Summary"
                            : "View Progress",
                        hide:
                          questionnaire.status === "in_review" ||
                          questionnaire.status === "not_started",
                        onClick: () => {
                          history.push(
                            generatePath(ROUTES.QUESTIONNAIRE_REVIEW_PATH, {
                              questionnaireId: questionnaire.id,
                              workspace_id,
                            }),
                          );
                        },
                      },
                      {
                        label: "View Scoring",
                        onClick: () => {
                          history.push(
                            generatePath(ROUTES.QUESTIONNAIRE_SCORING_PATH, {
                              questionnaireId: questionnaire.id,
                              record_version_id: partner!.id,
                              workspace_id,
                            }),
                          );
                        },
                      },
                      {
                        label: "Answer Questionnaire",
                        hide:
                          !questionnaire.is_internal ||
                          !["not_started", "incomplete"].includes(
                            questionnaire.status,
                          ),
                        onClick: () => {
                          history.push(
                            generatePath(
                              ROUTES.INTERNAL_QUESTIONNAIRE_REVIEW_PATH,
                              {
                                questionnaireId: questionnaire.id,
                                workspace_id,
                              },
                            ),
                          );
                        },
                      },
                      {
                        label: "Export",
                        submenu: {
                          label: "Export Option",
                          menu: (
                            <QuestionareExport
                              questionnaireId={questionnaire.id}
                            />
                          ),
                        },
                      },
                      {
                        label: "Import Answers",
                        hide: !canImportAnswers(questionnaire),
                        onClick: () =>
                          onUploadQuestionnaireAnswers(questionnaire.id),
                      },
                      {
                        divider: true,
                      },
                      {
                        label: "Delete",
                        onClick: () => onDeleteQuestionnaire(questionnaire.id),
                      },
                    ]}
                  />
                </ActionCell>
              </TableRow>
            );
          },
        )}
      </>
      <QuestionnaireDueDateModal
        onClose={(date) => {
          setNewDueDate &&
            setNewDueDate(date, Number(questionnaireToUpdate?.id));
          setQuestionnaireToUpdate(null);
        }}
        questionnaire={questionnaireToUpdate}
      />
    </Table>
  );
};

export default observer(PartnerQuestionnaireSection);
