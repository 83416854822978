import "./group-scoring.scss";

import React, { useEffect, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import {
  AssessmentAPI,
  QuestionnaireAPI,
  RiskMethodologyAPI,
} from "@/api/legacy/risk-assessment";
import ExportBulk from "@/components/dashboard/ExportBulk";
import { Button, Icon, IconButton, Typography } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import Table from "@/features/risk-assessment/components/Table/GenericTable/Table";
import TableHeaderCell from "@/features/risk-assessment/components/Table/GenericTable/TableHeaderCell";
import TableRow from "@/features/risk-assessment/components/Table/GenericTable/TableRow";
import { ROUTES } from "@/features/risk-assessment/pages";
import {
  AssessmentIndex,
  QuestionnaireRead,
  RiskMethodology,
  RiskRating,
} from "@/features/risk-assessment/types";
import { getDefinitionsFromScores } from "@/features/risk-assessment/utils/scoring-helpers";

import { ScoreCell } from "../../Assessment/AssessmentScoring/ScoreCell";
import MiniTag from "../../MiniTag/MiniTag";
import PopupMenu from "../../PopupMenu/PopupMenu";
import PopupMenuItem from "../../PopupMenu/PopupMenuItem";
import RowOptions from "../../RowOptions/RowOptions";
import SummaryContent from "../../Summary/SummaryContent";
import ActionCell from "../../Table/GenericTable/Cells/ActionCell";
import LongTextTableCell from "../../Table/GenericTable/LongTextTableCell";
import TableCellSpacer from "../../Table/GenericTable/TableCellSpacer";

type GroupScoringProps = {
  questionnaireIds: number[];
  assessment: AssessmentIndex;
  riskMethodologyId: number;
  name: string;
  onDelete: (assessmentId: number) => void;
  onAddQuestionnaire: (assessmentId: number, riskMethodologyId: number) => void;
  onRemoveQuestionnaire: (
    assessmentId: number,
    questionnaireId: number,
  ) => void;
  onRenameGroup: (assessmentId: number, name: string) => void;
  onExportGroup: (assessmentId: number, name: string) => void;
};
type Promises = Promise<QuestionnaireRead>[];
export default function GroupScoringSection(props: GroupScoringProps) {
  const mainStore = useMainStore();
  const history = useHistory();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const [questionnaires, setQuestionnaires] = useState<QuestionnaireRead[]>();
  const [methodology, setMethodology] = useState<RiskMethodology>();
  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  async function fetch() {
    try {
      const questionnairePromises: Promises = [];
      props.questionnaireIds.forEach((qid) =>
        questionnairePromises.push(QuestionnaireAPI.get(qid)),
      );
      const questionnairesData = await Promise.all(questionnairePromises);
      if (!methodology) {
        const methodologyData = (
          await RiskMethodologyAPI.get(
            Number(workspace_id),
            props.riskMethodologyId,
          )
        )?.risk_methodology;
        setMethodology(methodologyData);
      }
      setQuestionnaires(questionnairesData);
    } catch (err) {
      mainStore.toast.setErrorFromResponse(
        err,
        "There was an issue loading group scoring data. Please try again",
      );
    }
  }

  const handleSummaryGroup = async (assessmentId: number, summary: string) => {
    await AssessmentAPI.updateSummaryAssessment(
      Number(workspace_id),
      assessmentId,
      summary,
    );
    await fetch();
  };

  const riskRatingSection = (
    rating: RiskRating | undefined,
    scoreKey:
      | "overall_risk_score"
      | "control_risk_score"
      | "inherent_risk_score",
    title: string,
  ) => (
    <div className="scoring-overview--item">
      <p className="scoring-overview--item-row-score tw-flex-grow tw-items-center">
        {rating && props.assessment.scoring
          ? props.assessment.scoring[scoreKey]
          : "--"}
      </p>
      {rating && (
        <div className="tw-flex tw-self-center">
          <MiniTag
            color={rating.color}
            label={`${rating.text}: ${rating.lower_range}-${rating.higher_range}`}
            variant="pill"
          />
        </div>
      )}
      <p className="scoring-overview--item-row-title">{title}</p>
    </div>
  );

  function createScoringOverview() {
    if (methodology) {
      const {
        inherentRiskRating,
        residualRiskRating,
        overallRiskRating,
        controlRiskRating,
      } = getDefinitionsFromScores(props.assessment.scoring!, methodology);

      return (
        <div className="scoring-overview">
          {riskRatingSection(
            overallRiskRating,
            "overall_risk_score",
            "Overall Risk Score & Level",
          )}
          {riskRatingSection(
            inherentRiskRating,
            "inherent_risk_score",
            "Inherent Risk Score & Level",
          )}
          {riskRatingSection(
            controlRiskRating,
            "control_risk_score",
            "Control Risk Score & Level",
          )}
          {residualRiskRating && (
            <div className="scoring-overview--item">
              <div className="scoring-overview--residual-risk-wrapper">
                <div className="scoring-overview--residual-risk-row-pill">
                  <p
                    className="tw-text-4xl tw-font-semibold"
                    style={{ color: residualRiskRating.color }}
                  >
                    {residualRiskRating.text}
                  </p>
                </div>
              </div>
              <p className="scoring-overview--residual-risk-row-title">
                Residual Risk Level
              </p>
            </div>
          )}
        </div>
      );
    }
  }

  useEffect(() => {
    fetch();
  }, [props.questionnaireIds, props.riskMethodologyId]);

  return (
    <div className="group-scoring-section">
      <div className="section-wrapper">
        <div className="section-header">
          <Typography
            size="lg"
            color="generalMidnightDark"
            label={props.name}
          />
          <div className="method">{methodology?.name}</div>
          <PopupMenu
            anchorNode={
              <IconButton
                active={contextMenuOpen}
                icon="moreHorizontal"
                className="group-scoring-section--context-menu-icon"
                onClick={() => setContextMenuOpen(true)}
              />
            }
            open={contextMenuOpen}
            placement="right top"
            onClose={() => {
              setContextMenuOpen(false);
            }}
            mainMenu={
              <>
                <PopupMenuItem
                  label="Rename grouping"
                  onClick={() => {
                    setContextMenuOpen(false);
                    props.onRenameGroup(props.assessment.id, props.name);
                  }}
                />
                <PopupMenuItem divider />
                <PopupMenuItem
                  label="Delete"
                  onClick={() => props.onDelete(props.assessment.id)}
                />
              </>
            }
          />
        </div>
        <div className="section-header-actions">
          <ExportBulk
            customFileExportFunction={() =>
              props.onExportGroup(
                props.assessment?.id,
                `scoring_group_${props.assessment?.name
                  .toLowerCase()
                  .replace(/ /g, "_")}_export.zip`,
              )
            }
            isScoringGroup
            isExportFilesEnabled
            isExportSheetEnabled={false}
          />
          <Button
            label="Add Questionnaire"
            onClick={() =>
              props.onAddQuestionnaire(
                props.assessment.id,
                props.riskMethodologyId,
              )
            }
          />
        </div>
      </div>
      {createScoringOverview()}
      {questionnaires?.length === 0 ? (
        <div className="tw-mt-3 tw-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-neutral-50 tw-py-[35px]">
          There are no questionnaires added
        </div>
      ) : (
        <Table
          className="ra-dashboard-assessments__table"
          header={
            <>
              <TableHeaderCell
                title="Questionnaire Name"
                leftIcon={<Icon name="text" color="extrasBlueGrayDarker" />}
                firstDataHeader
              />
              <TableHeaderCell
                title="Overall Score"
                leftIcon={<Icon name="status" color="extrasBlueGrayDarker" />}
                fixedWidth
              />
              <TableHeaderCell
                title="Inherent Questions"
                leftIcon={<Icon name="number" color="extrasBlueGrayDarker" />}
                fixedWidth
              />
              <TableHeaderCell
                title="Inherent Risk"
                leftIcon={<Icon name="status" color="extrasBlueGrayDarker" />}
                fixedWidth
              />
              <TableHeaderCell
                title="Control Questions"
                leftIcon={<Icon name="number" color="extrasBlueGrayDarker" />}
                fixedWidth
              />
              <TableHeaderCell
                title="Control Risk"
                leftIcon={<Icon name="status" color="extrasBlueGrayDarker" />}
                fixedWidth
              />
              <TableHeaderCell
                title="Residual Risk"
                leftIcon={<Icon name="status" color="extrasBlueGrayDarker" />}
                fixedWidth
                lastDataHeader
              />
              <TableCellSpacer />
            </>
          }
        >
          {methodology &&
            questionnaires?.map(
              (questionnaire: QuestionnaireRead, i: number) => {
                const {
                  overallRiskRating,
                  controlRiskRating,
                  inherentRiskRating,
                  residualRiskRating,
                } = getDefinitionsFromScores(
                  questionnaire.scoring!,
                  methodology,
                );
                return (
                  <TableRow key={questionnaire.id} rowIndex={i}>
                    <LongTextTableCell
                      isLinkCell
                      isFirstDataCell
                      onClick={() =>
                        history.push(
                          generatePath(ROUTES.QUESTIONNAIRE_REVIEW_PATH, {
                            questionnaireId: questionnaire.id,
                            workspace_id,
                          }),
                        )
                      }
                    >
                      {questionnaire.name}
                    </LongTextTableCell>
                    <ScoreCell
                      rating={overallRiskRating}
                      score={questionnaire?.scoring?.overall_risk_score}
                    />
                    <ContentCell
                      content={
                        <Typography
                          weight="semiBold"
                          label={questionnaire.inherent_questions_count}
                          color="extrasBlueGrayDarker"
                        />
                      }
                    />
                    <ScoreCell
                      rating={inherentRiskRating}
                      score={questionnaire?.scoring?.inherent_risk_score}
                    />
                    <ContentCell
                      content={
                        <Typography
                          weight="semiBold"
                          label={questionnaire.control_questions_count}
                          color="extrasBlueGrayDarker"
                        />
                      }
                    />
                    <ScoreCell
                      rating={controlRiskRating}
                      score={questionnaire?.scoring?.control_risk_score}
                    />
                    <ScoreCell rating={residualRiskRating} />

                    <ActionCell>
                      <RowOptions
                        iconOverrideClass="row-options--risk-assessment-table-context-menu-icon"
                        options={[
                          {
                            label: "Remove from this grouping",
                            onClick: () =>
                              props.onRemoveQuestionnaire(
                                props.assessment.id,
                                questionnaire.id,
                              ),
                          },
                        ]}
                      />
                    </ActionCell>
                  </TableRow>
                );
              },
            )}
        </Table>
      )}
      {questionnaires?.length !== 0 && (
        <SummaryContent
          summaryType="assessment"
          summary={props.assessment?.summary || ""}
          assessment={props.assessment}
          onChangeSummary={(assessmentId: number, summary: string) =>
            handleSummaryGroup(assessmentId, summary)
          }
        />
      )}
    </div>
  );
}
