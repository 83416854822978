import { AxiosError } from "axios";
import { useEffect, useState } from "react";

import { RailsErrors } from "@/utils/errors";

export type LoadableData<T> = {
  loading: boolean;
  data: T;
  errorText: string | null;
};

export function useLoadableData<T>(params: {
  request: () => Promise<T>;
  onError: (error: Error | AxiosError<{ errors: RailsErrors }>) => string;
  /**
   * Can be used to halt triggering of fetch request until it is `true`
   * (e.g. if you were waiting for a dependant parameter in your request)
   */
  enabled?: boolean;
}): LoadableData<T> & { refetch: () => Promise<void> } {
  const { request, onError, enabled = true } = params;

  const [loadableData, setLoadableData] = useState<LoadableData<T>>({
    loading: false,
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'T'.
    data: null,
    errorText: "",
  });

  const handleSetState = (newData: Partial<LoadableData<T>>) => {
    setLoadableData((prevState) => ({ ...prevState, ...newData }));
  };

  const handleFetch = async () => {
    handleSetState({ loading: true });

    try {
      const response = await request();

      handleSetState({ data: response, loading: false });
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      const errorText = onError(error);

      handleSetState({ loading: false, errorText });
    }
  };

  useEffect(() => {
    if (enabled) {
      handleFetch();
    }
  }, [enabled]);

  return { ...loadableData, refetch: handleFetch };
}
