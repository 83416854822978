import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { Section } from "@/stores/types/section-tags";

import arrowUp from "../../../images/table-image/icon/arrow-up.svg";
import ArchivedPopupDelete from "../policy/ArchivedPopupDelete";

type Props = {
  activeDateRange?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  afterDelete?: (...args: any[]) => any;
  archived?: boolean;
  attestationID?: number;
  headers?: React.ReactNode;
  isDropdownOpen?: boolean;
  rows?: React.ReactNode;
  sectionTag?: Section;
  showCheckbox?: boolean;
};

function AttestationsSection({
  activeDateRange,
  afterDelete,
  attestationID,
  archived,
  rows,
  sectionTag,
  headers,
  showCheckbox,
  ...props
}: Props) {
  // State
  const [isDropdownOpen, setIsDropdownOpen] = useState(props.isDropdownOpen);

  return (
    <>
      {sectionTag && (
        <div
          className="dropdown-title-wrap archived-title-wrap"
          data-testid="attestation-section"
        >
          <div
            className={classNames("dropdown-title", {
              "dropdown-open": isDropdownOpen,
            })}
          >
            <div className="dropdown-title-block">
              <div className="dropdown-title-click">
                <div
                  className="dropdown-arrow"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <img src={arrowUp} alt="dropdown-arrow" />
                </div>

                {sectionTag.title && <h3>{sectionTag.title}</h3>}
              </div>

              {archived && (
                <>
                  <div className="archived-label">
                    <div className="label-policy">Training</div>
                  </div>

                  <ArchivedPopupDelete
                    recordID={attestationID}
                    tableName="Attestation"
                    afterDelete={afterDelete}
                  />

                  <div className="archived-date">{activeDateRange}</div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {isDropdownOpen && (
        <div className="settings-wrap company-users-settings-container attestation-section-wrap">
          <div className="settings-content-wrap attestation-content-wrap">
            <div className="list-title-table">
              <ul>
                <div className="list-title-table-wrap">
                  {showCheckbox && (
                    <div className="checkbox-title-table attestations" />
                  )}
                  {headers}
                  <span className="stretch-cell" />
                </div>
                {!archived && <div className="list-title-table-block" />}
              </ul>
            </div>
            {rows}
          </div>
        </div>
      )}
    </>
  );
}

AttestationsSection.defaultProps = {
  archived: false,
  afterDelete: () => {},
  headers: [],
  isDropdownOpen: false,
  rows: [],
  showCheckbox: false,
};

export default observer(AttestationsSection);
