import "./question-attachment.scss";

import React from "react";

import { IconButton, Typography } from "@/components/Elements";

type Props = {
  fileName: string;
  onDelete?: () => void;
};

export default function QuestionAttachment(props: Props) {
  return (
    <div className="question-attachment">
      <Typography label={props.fileName} color="brandingHighlightTurquoise" />
      {props.onDelete && (
        <IconButton
          className="question-attachment__action-button"
          icon="trash"
          onClick={props.onDelete}
        />
      )}
    </div>
  );
}
