import React from "react";
import { Control, Controller, ControllerRenderProps } from "react-hook-form";

import { Flex, IconButton, MiniTag, Typography } from "@/components/Elements";
import { SelectInputField } from "@/components/form/react-hook-form/SelectInputField";
import { THEMIS_MINITAG_THEMES } from "@/config/theme";
import { isNotItem, toValueLabel } from "@/utils/utils";

import { ZendeskConfigFormValues } from "../../types";

type SelectedFiltersProps = {
  name: "selected_tags" | "selected_statuses";
  title: string;
  description: string;
  miniTagTheme: (typeof THEMIS_MINITAG_THEMES)[number];
  control: Control<ZendeskConfigFormValues>;
  options: string[];
};

export function SelectedFilters(props: SelectedFiltersProps) {
  return (
    <Flex column rowGap={16}>
      <Flex justifySpaceBetween alignCenter>
        <Flex column rowGap={4}>
          <Typography label={props.title} size="lg" color="generalDark" />
          <Typography
            label={props.description}
            size="sm"
            color="extrasBlueGrayDarker"
          />
        </Flex>
        <SelectInputField
          tooltipError
          name={props.name}
          control={props.control}
          placeholder="- Select -"
          miniTagTheme={props.miniTagTheme}
          multiple
          options={props.options.map(toValueLabel)}
        />
      </Flex>
      <Controller
        control={props.control}
        rules={{
          validate(values: string[]) {
            return (
              props.name === "selected_tags" ||
              !!values.length ||
              "At least 1 selected status required."
            );
          },
        }}
        name={props.name}
        render={({ field }) => (
          <SelectedFiltersTable
            field={field}
            miniTagTheme={props.miniTagTheme}
          />
        )}
      />
    </Flex>
  );
}

function SelectedFiltersTable(
  props: Pick<SelectedFiltersProps, "miniTagTheme"> & {
    field: ControllerRenderProps<
      ZendeskConfigFormValues,
      "selected_statuses" | "selected_tags"
    >;
  },
) {
  return (
    <Flex column>
      {props.field.value.map((selectedFilter) => (
        <Flex
          key={selectedFilter}
          className="zendesk-configuration__table-row"
          justifySpaceBetween
          alignCenter
        >
          <MiniTag theme={props.miniTagTheme} label={selectedFilter} />
          <IconButton
            icon="trash"
            transparent
            onClick={() => {
              props.field.onChange(
                props.field.value.filter(isNotItem(selectedFilter)),
              );
            }}
          />
        </Flex>
      ))}
    </Flex>
  );
}
