import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { useMainStore } from "@/contexts/Store";

import LocationTracker from "../components/LocationTracker";
import AppPopup from "../components/popup/AppPopup";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import UnauthenticatedRoutes from "./UnauthenticatedRoutes";

type Props = {
  isProduction: boolean;
  children?: React.ReactNode;
};

function AppRouter({ isProduction, children }: Props) {
  const mainStore = useMainStore();
  const { accessToken } = mainStore.webSessions;
  const { boot } = useIntercom();

  async function loadAppWideData() {
    if (!accessToken) {
      return;
    }

    await Promise.all([
      mainStore.companies.show(),
      mainStore.users.show(), // could use another serializer to give back a couple more key workspace
      // fields that we currently need workspaces index for
    ]);

    const { user } = mainStore.users;
    const { company } = mainStore.companies;

    if (isProduction) {
      window.pendo.initialize({
        visitor: {
          id: `user-${user.id}`,
          email: user.email,
          full_name: user.full_name,
        },
        account: {
          id: `company-${company.id}`,
          name: company.name,
          is_paying: company.is_paying,
        },
      });

      if (user.user_hash) {
        boot({
          userId: user.id?.toString(),
          userHash: user.user_hash,
          name: user.full_name || "",
          email: user.email,
        });
      }
    }

    mainStore.featureFlags.fetchFeatureFlags();
  }

  useEffect(() => {
    loadAppWideData();
  }, [accessToken]);

  return (
    <Router>
      {Object.keys(mainStore.users.user).length > 0 && <AuthenticatedRoutes />}
      <UnauthenticatedRoutes />
      <LocationTracker />
      <AppPopup />
      {children}
    </Router>
  );
}

export default observer(AppRouter);
