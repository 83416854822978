import { MiniTag, MiniTagProps } from "@themis/ui";
import { capitalize } from "lodash";
import React from "react";

import { findingRiskLevel } from "@/api";

const RISK_LEVEL_COLOR_MAP: Record<string, MiniTagProps["color"]> = {
  [findingRiskLevel.low]: "green",
  [findingRiskLevel.medium]: "yellow",
  [findingRiskLevel.high]: "red",
} as const;

export function RiskLevelTypeTag({ type }: { type: string }) {
  return (
    <MiniTag color={RISK_LEVEL_COLOR_MAP[type]}>{capitalize(type)}</MiniTag>
  );
}
