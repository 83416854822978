import { observer } from "mobx-react";
import React from "react";

import {
  RiskRegisterScoringMatrix,
  ScoringMatrixColumn,
  ScoringMatrixRating,
  ScoringMatrixRow,
} from "@/stores/types/risk-register-types";

import { INHERENT_MATRIX_TITLE, RESIDUAL_MATRIX_TITLE } from "./Constants";
import ScoringDefinitionsTable from "./ScoringDefinitionsTable";
import ScoringMatrix from "./ScoringMatrix";

type Props = {
  editingMode: boolean;
  handleEditSubmit: (matrix: RiskRegisterScoringMatrix) => void;
  matrix: RiskRegisterScoringMatrix | undefined;
  setMatrix: (matrix: RiskRegisterScoringMatrix) => void;
  riskRatings: ScoringMatrixRating[];
  setRiskRatings: (ratings: ScoringMatrixRating[]) => void;
  setEditingMatrixChanged: (changed: boolean) => void;
};

function Scoring({
  matrix,
  editingMode,
  setMatrix,
  riskRatings,
  setRiskRatings,
  handleEditSubmit,
  setEditingMatrixChanged,
}: Props) {
  function handleEditRowsDefinitionsSubmit(data: ScoringMatrixRow[]) {
    if (matrix) {
      setMatrix({
        ...matrix,
        scoring_matrix_rows: data,
      });
    }
  }

  function handleEditColumnsDefinitionsSubmit(data: ScoringMatrixColumn[]) {
    if (matrix) {
      setMatrix({
        ...matrix,
        scoring_matrix_columns: data,
      });
    }
  }

  return (
    <div className="rr-scoring-wrapper">
      {matrix && (
        <div
          className="rr-scoring-container"
          data-testid="rr-scoring-container"
        >
          <div className="rr-scoring-item-container">
            <ScoringMatrix
              matrix={matrix}
              setMatrix={setMatrix}
              editingMode={editingMode}
              disabled={false}
              riskRatings={riskRatings}
              setRiskRatings={setRiskRatings}
              handleEditSubmit={handleEditSubmit}
              setEditingMatrixChanged={setEditingMatrixChanged}
            />
            <ScoringDefinitionsTable
              editingMode={editingMode}
              items={matrix.scoring_matrix_rows}
              title={
                matrix.name === INHERENT_MATRIX_TITLE
                  ? "Impact Definitions"
                  : "Inherent Definitions"
              }
              // @ts-expect-error TS(7006) FIXME: Type (ScoringMatrixRow | ScoringMatrixColumn)[] is not assignable to type ScoringMatrixRow[].
              handleEditSubmit={handleEditRowsDefinitionsSubmit}
            />
            <ScoringDefinitionsTable
              editingMode={editingMode}
              items={matrix.scoring_matrix_columns.slice()}
              title={
                matrix.name === INHERENT_MATRIX_TITLE
                  ? "Likelihood Definitions"
                  : "Control Definitions"
              }
              controlDefinition={matrix.name === RESIDUAL_MATRIX_TITLE}
              handleEditSubmit={handleEditColumnsDefinitionsSubmit}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(Scoring);
