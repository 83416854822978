import classNames from "classnames";
import React from "react";

type Props = {
  width: number | string;
  locked?: boolean;
  pinned?: boolean;
  checklistCount?: number;
  completedChecklistCount?: number;
};

export default function Questionnaires({
  width = 200,
  checklistCount = 0,
  completedChecklistCount = 0,
  locked,
  pinned,
}: Props) {
  return (
    <li
      style={{ width }}
      data-testid="questionnaires-cell"
      className={classNames("questionnaires-cell", {
        "cell-red": completedChecklistCount < checklistCount,
        "locked-cell pointer-events-none": locked,
        pinned,
      })}
    >
      <div className="cell-content">
        {checklistCount === completedChecklistCount && checklistCount > 0
          ? "All Completed"
          : `${completedChecklistCount}/${checklistCount} Completed`}
      </div>
    </li>
  );
}
