import { useToast } from "@themis/ui";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { PiXBold } from "react-icons/pi";
import { useParams } from "react-router-dom";

import {
  useMonitoringGroupResult,
  useMonitoringGroupResultUpdate,
} from "@/api/queries/websiteMonitoring";

import { useMonitoringSidebar } from "../../providers/MonitoringSidebarProvider";
import { isInAPopup } from "../helpers";
import MonitoringGroupResultInfoForm, {
  MonitoringGroupResultInfoSchema,
} from "./MonitoringGroupResultInfoForm";

function HeaderContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-px-7 tw-py-3 ">
      {children}
    </div>
  );
}
function SectionContainer({ children }: { children: React.ReactNode }) {
  return <div className="tw-px-7 tw-py-3">{children}</div>;
}

export default function MonitoringGroupResultDetail({
  activeId,
}: {
  activeId: number;
}) {
  const { monitoringGroupAssetChildId } = useParams<{
    monitoringGroupAssetChildId: string;
  }>();
  const { closeSidebar } = useMonitoringSidebar();
  const {
    data: monitoringGroupResult,
    isPending,
    isError,
  } = useMonitoringGroupResult(activeId);

  const toast = useToast();
  const resultDetailContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", closeOnClickOutside);
    return () => {
      document.removeEventListener("mousedown", closeOnClickOutside);
    };
  });

  const { mutateAsync: updateResult } = useMonitoringGroupResultUpdate({
    monitoringGroupResultId: activeId,
    monitoringGroupAssetChildId: Number(monitoringGroupAssetChildId),
    onSuccess: () => {
      toast({ content: "Result saved successfully!", variant: "success" });
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not update result.",
        variant: "error",
      });
    },
  });

  const handleSubmitForm = async (
    values: Partial<MonitoringGroupResultInfoSchema>,
  ) => {
    const requestBody = {
      data: {
        status: values?.status || null,
        comment: values?.comment || null,
        evidence: values?.evidence || null,
      },
    };

    await updateResult(requestBody);
  };

  const closeOnClickOutside = (e: MouseEvent) => {
    if (
      !resultDetailContainer.current?.contains(e.target as Node) &&
      !isInAPopup(e.target as HTMLElement)
    ) {
      closeSidebar();
    }
  };

  if (isPending || isError) {
    return null;
  }

  return (
    <div
      ref={resultDetailContainer}
      className={classNames("slide-animation tw-z-30 tw-w-[800px]")}
    >
      <HeaderContainer>
        <h3 className="tw-text-base tw-font-semibold tw-text-neutral-500">
          Result Details
        </h3>
        <PiXBold onClick={closeSidebar} className="cursor-pointer" />
      </HeaderContainer>

      <SectionContainer>
        <MonitoringGroupResultInfoForm
          onSubmit={handleSubmitForm}
          results={monitoringGroupResult.data}
        />
      </SectionContainer>
    </div>
  );
}
