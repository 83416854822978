import { Tabs, TabsContent, TabsList, TabsTrigger } from "@themis/ui";
import React, { useMemo, useState } from "react";

import { DashboardComponent } from "@/api";

import { BarChart } from "../charts/BarChart";

export default function LibraryRecordByStages({
  widgetData,
}: {
  widgetData: DashboardComponent["data"];
}) {
  const [tabName, setTabName] = useState("policies");

  const mutated = useMemo(() => {
    if (!widgetData) {
      return {
        policies: {
          count: 0,
          data: [],
        },
        procedures: {
          count: 0,
          data: [],
        },
        documents: {
          count: 0,
          data: [],
        },
      };
    }
    return {
      policies: {
        count:
          Object.keys(widgetData.policy)
            .map((key) => widgetData.policy[key], 0)
            .reduce((a, b) => a + b) || 0,
        data: Object.keys(widgetData.policy).map((key) => ({
          name: key
            .replace(/_/g, " ")
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" "),
          value: widgetData.policy[key],
          color: "#8F92F9",
        })),
      },
      procedures: {
        count:
          Object.keys(widgetData.procedures)
            .map((key) => widgetData.procedures[key], 0)
            .reduce((a, b) => a + b) || 0,
        data: Object.keys(widgetData.procedures).map((key) => ({
          name: key
            .replace(/_/g, " ")
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" "),
          value: widgetData.procedures[key],
          color: "#8F92F9",
        })),
      },
      documents: {
        count:
          Object.keys(widgetData.documents)
            .map((key) => widgetData.documents[key], 0)
            .reduce((a, b) => a + b) || 0,
        data: Object.keys(widgetData.documents).map((key) => ({
          name: key
            .replace(/_/g, " ")
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" "),
          value: widgetData.documents[key],
          color: "#8F92F9",
        })),
      },
    };
  }, [widgetData]);

  return (
    <div className="tw-flex tw-h-[260px] tw-min-w-[400px] tw-flex-grow tw-basis-0 tw-flex-col tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Library Record by Stages
        </div>
      </div>
      <div>
        <Tabs
          value={tabName}
          onValueChange={setTabName}
          className="tw-border-0"
        >
          <TabsList className="tw-h-[34px] tw-gap-4 tw-rounded-none tw-border-0 tw-border-b-2 tw-border-solid tw-border-primaryDim-100 tw-pb-0">
            <TabsTrigger
              value="policies"
              className="tw-rounded-none tw-p-0 tw-text-xs tw-font-medium tw-text-neutral-300 hover:tw-bg-primaryDim-25  data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
            >
              {mutated.policies.count} Policies
            </TabsTrigger>
            <TabsTrigger
              value="procedures"
              className="tw-rounded-none tw-p-0 tw-text-xs tw-font-medium tw-text-neutral-300 hover:tw-bg-primaryDim-25  data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
            >
              {mutated.procedures.count} Procedures
            </TabsTrigger>
            <TabsTrigger
              value="documents"
              className="tw-rounded-none tw-p-0 tw-text-xs tw-font-medium tw-text-neutral-300 hover:tw-bg-primaryDim-25  data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
            >
              {mutated.documents.count} Documents
            </TabsTrigger>
          </TabsList>
          <TabsContent value="policies">
            <div className="tw-h-[204px] tw-w-full">
              <BarChart data={mutated.policies.data} />
            </div>
          </TabsContent>
          <TabsContent value="procedures">
            <div className="tw-h-[204px] tw-w-full">
              <BarChart data={mutated.procedures.data} />
            </div>
          </TabsContent>
          <TabsContent value="documents">
            <div className="tw-h-[204px] tw-w-full">
              <BarChart data={mutated.documents.data} />
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
