import "./questionnaire-builder-panel.scss";

import React, { useState } from "react";

import {
  IconButton,
  MiniTag,
  Typography,
} from "../../../../../components/Elements";
import { Question } from "../../../types/questionnaire";
import SelectableQuestionTypes from "./selectable-questions/SelectableQuestionTypes";

type QuestionnaireBuilderPanelProps = {
  onAddQuestion: (question: Question) => void;
};

function QuestionnaireBuilderPanel(props: QuestionnaireBuilderPanelProps) {
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const [isQuestionTypesOpen, setIsQuestionTypesOpen] = useState(true);

  return (
    <div data-testid="questionnaire-builder-panel">
      {isPanelOpen && (
        <div className="questionnaire-builder-panel open">
          <div className="questionnaire-builder-panel-section">
            <div className="questionnaire-builder-panel-section__header">
              <IconButton
                icon="pushLeft"
                className="questionnaire-builder-panel-section__button"
                onClick={() => setIsPanelOpen(false)}
              />
              <Typography color="generalMidnightDark" label="Hide Panel" />
            </div>
          </div>
          <div className="questionnaire-builder-panel-section">
            <div
              className="questionnaire-builder-panel-section__header"
              onClick={() => setIsQuestionTypesOpen((isOpen) => !isOpen)}
            >
              <IconButton
                icon="feedback"
                className="questionnaire-builder-panel-section__button"
              />
              <Typography color="generalMidnightDark" label="Question Types" />
              <IconButton
                icon={isQuestionTypesOpen ? "chevronDown" : "chevronRight"}
                className="questionnaire-builder-panel-section__button section-button-right"
              />
            </div>
            {isQuestionTypesOpen && (
              <div className="questionnaire-builder-panel-section__content">
                <Typography
                  label="General Question Types"
                  size="lg"
                  color="generalMidnightDark"
                />
                <SelectableQuestionTypes onAddQuestion={props.onAddQuestion} />
              </div>
            )}
          </div>
          <div className="questionnaire-builder-panel-section">
            <div className="questionnaire-builder-panel-section__header">
              <IconButton
                icon="commentLinear"
                className="questionnaire-builder-panel-section__button"
              />
              <Typography color="generalMidnightDark" label="Question Bank" />
            </div>
            <div className="questionnaire-builder-panel-section__content">
              <MiniTag label="Coming Soon" />
            </div>
          </div>
        </div>
      )}
      {!isPanelOpen && (
        <div className="questionnaire-builder-panel">
          <div className="questionnaire-builder-panel-section">
            <IconButton
              icon="pushRight"
              className="questionnaire-builder-panel-section__button"
              onClick={() => setIsPanelOpen(true)}
            />
          </div>
          <div className="questionnaire-builder-panel-section">
            <IconButton
              icon="feedback"
              className="questionnaire-builder-panel-section__button"
              onClick={() => setIsPanelOpen(true)}
            />
          </div>
          <div className="questionnaire-builder-panel-section">
            <IconButton
              icon="commentLinear"
              className="questionnaire-builder-panel-section__button"
              onClick={() => setIsPanelOpen(true)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default QuestionnaireBuilderPanel;
