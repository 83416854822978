import { IconButton } from "@themis/ui";
import React from "react";
import { PiCaretRightBold } from "react-icons/pi";

import { useMonitoringSidebar } from "../providers/MonitoringSidebarProvider";
import CustomTooltip from "./CustomTooltip";

export default function ViewSlideButton({
  monitoringGroupResultId,
}: {
  monitoringGroupResultId: number;
}) {
  const { openSidebarWithId } = useMonitoringSidebar();

  return (
    <CustomTooltip value={"Open result detail"}>
      <IconButton
        Icon={PiCaretRightBold}
        onClick={() => openSidebarWithId(monitoringGroupResultId)}
      />
    </CustomTooltip>
  );
}
