import "./styles.scss";

import React from "react";

import { Flex, Typography } from "@/components/Elements";

import ZendeskConnect from "./components/zendesk-connect";

export function DisconnectedWelcome() {
  return (
    <Flex alignCenter column fullWidth rowGap={24} className="welcome-zendesk">
      <Flex column alignCenter rowGap={8}>
        <Typography
          label="Welcome to the Zendesk Module!"
          weight="semiBold"
          color="generalMidnightDark"
          size="md"
        />
        <Flex column className="welcome-zendesk__description">
          <Typography
            size="sm"
            weight="semiBold"
            color="extrasBlueGrayDarker"
            label="Link your Zendesk account with Themis:"
          />
          <ul className="welcome-zendesk__description-list">
            <Typography
              type="li"
              size="sm"
              weight="semiBold"
              color="extrasBlueGrayDarker"
              label={
                <>
                  Automate the ingestion of Zendesk tickets based on <i>Tags</i>{" "}
                  and <i>Status</i>
                </>
              }
            />
            <Typography
              type="li"
              size="sm"
              weight="semiBold"
              color="extrasBlueGrayDarker"
              label="Map to Themis fields for a seamless integration"
            />
            <Typography
              type="li"
              size="sm"
              weight="semiBold"
              color="extrasBlueGrayDarker"
              label={
                "Keep on top of all complaints and issues that need you and your team's attention"
              }
            />
            <Typography
              type="li"
              size="sm"
              weight="semiBold"
              color="extrasBlueGrayDarker"
              label="Verify that the tickets are handled compliantly"
            />
            <Typography
              type="li"
              size="sm"
              weight="semiBold"
              color="extrasBlueGrayDarker"
              label={"Send from Zendesk Module to Themis' Complaints Module"}
            />
          </ul>
        </Flex>
      </Flex>
      <ZendeskConnect />
    </Flex>
  );
}
