import { get, keys } from "lodash";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import Drawer from "@/components/Drawer";
import Dropdown from "@/components/Elements/Dropdown/Dropdown";
import MultiDropdown from "@/components/MultiDropdown";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modules?: any[];
  permissionSetName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roles?: any[];
  userID?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workspaces?: any[];
};

const RolesView = ({
  userID,
  roles,
  workspaces,
  modules,
  permissionSetName,
}: Props) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const selections = useMemo(
    () =>
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      roles
        .map((role) => {
          const selection = {
            id: role.id,
            role: {
              id: role.permission_set.id,
              name: role.permission_set.name,
              type: role.permission_set.permission_type,
            },
          };

          if (selection.role.type === "company") {
            return {
              ...selection,
              workspaces,
              modules,
            };
          }

          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          const existingWorkspace = workspaces.find(
            (ws) => ws.id === role.workspace_id,
          );

          if (!existingWorkspace) {
            return null;
          }

          const moduleTargets = get(role, "permission_set.data.module.targets");
          const moduleIdentifiers = keys(moduleTargets).filter((key) =>
            get(moduleTargets, `${key}.state.edit`),
          );
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          const selectedModules = modules.filter((module) =>
            moduleIdentifiers.includes(module.id),
          );

          return {
            ...selection,
            workspaces: [existingWorkspace],
            modules: selectedModules,
          };
        })
        .filter(Boolean),
    [workspaces, modules, roles],
  );

  return (
    <>
      <div
        className="roles-placeholder"
        data-testid="roles-view-placeholder"
        onClick={() => setShowDrawer(true)}
      >
        {permissionSetName}
      </div>
      {showDrawer && (
        <Drawer
          title={
            <div className="roles-view-drawer-title">
              View Roles
              <Link to={`/settings/users/${userID}`}>Edit in Detail View</Link>
            </div>
          }
          onClose={() => setShowDrawer(false)}
        >
          <div className="roles-sidepanel-content">
            <div className="roles-sidepanel-warning">
              All content are read-only as role has been set. Click “Edit in
              Detail View” above if you want to make modifications to specific
              workspace OR user role.
            </div>
            {selections.map((selection) => (
              <div
                // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                key={selection.id}
                className="role-group"
                data-testid="role-group"
              >
                <div className="role-widget">
                  <div className="role-widget-caption">User Role</div>
                  <div className="role-widget-content">
                    <Dropdown
                      disabled
                      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                      options={[selection.role]}
                      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                      selected={selection.role}
                    />
                  </div>
                </div>

                <div className="role-widget">
                  <div className="role-widget-caption">
                    {/* @ts-expect-error TS(2531) FIXME: Object is possibly 'null'. */}
                    {selection.role.type === "company"
                      ? "Workspaces"
                      : "Workspace"}
                  </div>
                  <div className="role-widget-content">
                    {/* @ts-expect-error TS(2531) FIXME: Object is possibly 'null'. */}
                    {selection.role.type === "company" && (
                      <MultiDropdown
                        disabled
                        unit="Workspace"
                        options={workspaces}
                        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                        selected={selection.workspaces}
                      />
                    )}

                    {/* @ts-expect-error TS(2531) FIXME: Object is possibly 'null'. */}
                    {selection.role.type === "workspace_module" && (
                      <Dropdown
                        disabled
                        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                        options={selection.workspaces}
                        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                        selected={selection.workspaces[0]}
                      />
                    )}
                  </div>
                </div>

                <div className="role-widget">
                  <div className="role-widget-caption">Accessible Modules</div>
                  <div className="role-widget-content">
                    <MultiDropdown
                      placeholder="Select Modules"
                      disabled
                      options={modules}
                      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                      selected={selection.modules}
                      unit="Module"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Drawer>
      )}
    </>
  );
};

RolesView.defaultProps = {
  roles: [],
  workspaces: [],
  modules: [],
  permissionSetName: "",
};

export default RolesView;
