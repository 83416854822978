import { Button } from "@themis/ui";
import classNames from "classnames";
import { isEqual } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiDownloadSimpleBold } from "react-icons/pi";

import {
  CompanyModuleRecordsReportPathParamsModuleIdentifier,
  RecordVersion,
} from "@/api";
import AddNewSectionButton from "@/components/shared/AddNewSectionButton";
import ExportWorkspacePopup from "@/components/shared/export-workspace-popup/ExportWorkspacePopup";
import IMExportReport from "@/components/table/issue-management/IMExportReport";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useFilters } from "@/hooks/useFilters";
import usePrevious from "@/hooks/usePrevious";
import { useTableData } from "@/hooks/useTableData";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";
import { IndexParams, ModuleStore } from "@/stores/types/module-store-types";
import { TopLevelModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { useLoading } from "../../../hooks/useLoading";
import { useSortingAndPagination } from "../../../hooks/useSortingAndPagination";
import ExportBulk from "../../dashboard/ExportBulk";
import ImportBulk from "../../dashboard/ImportBulk";
import Loading from "../../Loading";
import CreateCTACW from "../shared/CreateCTACW";
import CreateFirstCTA from "../shared/CreateFirstCTA";
import SectionDropdown from "../shared/SectionDropdown";
import Table from "../Table";
import NewSectionTag from "./NewSectionTag";

export type SectionPageProps = {
  approvalFieldName?: string;
  CTASubject: string;
  archived?: boolean;
  archivedWord?: string;
  createNewRecord?: (sectionTagId?: number | null) => void;
  isTopLevelSectionAdded?: boolean;
  maxDateFunction?: (name: string, recordVersion: RecordVersion) => Date | null;
  minDateFunction?: (name: string, recordVersion: RecordVersion) => Date | null;
  moduleIdentifier: TopLevelModuleIdentifier;
  paginated?: boolean;
  recordName?: string;
  rolePrefix?: string;
  selectedTab?: string;
  tabs?: React.ReactNode;
  wrapperClass?: string;
  lockTab?: boolean;
  isDraggable?: boolean;
  onCancelNewSectionTag?: () => void;
};

function SectionPage({
  approvalFieldName,
  CTASubject,
  moduleIdentifier,
  recordName,
  rolePrefix,
  wrapperClass,
  isTopLevelSectionAdded,
  maxDateFunction,
  minDateFunction,
  tabs,
  createNewRecord,
  archived,
  archivedWord,
  paginated = false,
  lockTab = false,
  isDraggable,
  selectedTab,
}: SectionPageProps) {
  // Import MobX stores
  const mainStore = useMainStore();

  useTableData();

  // Variables
  const { activeWorkspace, workspaceID, tableName, moduleWorkspaceID } =
    mainStore.context;
  const { canManageSections } = mainStore.userPermissions;
  const fields = mainStore.fields.visibleFields;
  const recordVersions = mainStore.recordVersions.list;
  const sectionTags = mainStore.sectionTags.orderedList;
  const PLACEHOLDER_SECTION_TAG_ID = 0; // The top section doesn't have an ID, so we use 0 as a placeholder

  // State
  const [showAddNewSectionTag, setAddNewSectionTag] = useState(false);
  const [counts, setCounts] = useState<Record<string, number>>({});

  // Pages should be {section_tag_id: page_number} eg {0: 1, 13: 1, 14: 2} if the third section is on the second page.
  const [pages, setPages] = useState<Record<number, number>>({
    [PLACEHOLDER_SECTION_TAG_ID]: 1,
  });
  const [activeSectionTagId, setActiveSectionTagId] = useState(undefined);

  const moduleStore = mainStore.getStore(moduleIdentifier) as ModuleStore & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
  };

  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
    setSortBy,
  } = useSortingAndPagination({ store: moduleStore, pages });

  const { getTableFiltersParam } = useUpdateFilter();

  const loadData = async () => {
    if (!workspaceID) {
      return;
    }

    const params: IndexParams = {
      tableFilters: getTableFiltersParam(),
      workspaceID,
      tableName: tableName || undefined,
      tab: selectedTab,
      sectionTagId: activeSectionTagId,
      pages,
    };

    if (sortByFieldName) {
      params.sortParams = {
        field_name: sortByFieldName,
        direction: sortByDirection || "ASC",
      };
    }

    await moduleStore.index(params);
  };

  // Hooks
  const loading = useLoading(fields);

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields,
  });

  // Hide NewSectionInput after successful creation
  useEffect(() => {
    if (
      mainStore.sectionTags.current &&
      Object.keys(mainStore.sectionTags.current).length !== 0
    ) {
      setAddNewSectionTag(false);
    }
  }, [mainStore.sectionTags.current]);

  const prevData = usePrevious(moduleStore.data);
  useEffect(() => {
    if (!isEqual(prevData, moduleStore.data) && moduleStore.data.counts) {
      setCounts(moduleStore.data.counts);
    }
  }, [moduleStore.data]);

  const addNewSectionTag = () => {
    setAddNewSectionTag(!showAddNewSectionTag);
  };

  useEffect(() => {
    const prePages: Record<number, number> = {
      [PLACEHOLDER_SECTION_TAG_ID]: 1,
    };
    for (const sectionTag of sectionTags) {
      prePages[sectionTag.id] = 1;
    }
    setPages(prePages);
  }, [sectionTags]);

  useEffect(() => {
    if (paginated) {
      loadData();
    }
  }, [pages, archived, selectedTab]);

  function renderCTA() {
    if (CTASubject === "Audit" && !activeWorkspace?.is_internal) {
      return <CreateCTACW subject="Audit" />;
    }

    return <CreateFirstCTA subject={CTASubject} />;
  }

  function handleCreateRecord(sectionTagId?: number | null) {
    if (createNewRecord) {
      return createNewRecord(sectionTagId);
    }
  }

  function addRecordOnHeader() {
    if (CTASubject === "Audit") {
      return handleCreateRecord(sectionTags[0].id);
    }
    return handleCreateRecord(null);
  }

  return (
    <Table dataTestId="section-page">
      {loading && <Loading loadingLayout="sections" showTableHeader={false} />}

      {!loading && (
        <>
          <div className={classNames("table-header-wrap")}>
            <div className="switch-table-wrap" />
            {tabs}
            <div className="import-export-buttons-container">
              {["issue_management"].includes(moduleIdentifier) ? (
                <ExportWorkspacePopup
                  trigger={
                    <Button
                      LeftIcon={PiDownloadSimpleBold}
                      size="md"
                      color="tertiary"
                    >
                      Export
                    </Button>
                  }
                  downloadFooter={<IMExportReport />}
                  moduleIdentifier={
                    moduleIdentifier as CompanyModuleRecordsReportPathParamsModuleIdentifier
                  }
                  successMessage="Issues are preparing to be exported"
                />
              ) : (
                <ExportBulk moduleIdentifier={moduleIdentifier} />
              )}
              {!lockTab && (
                <ImportBulk topLevelSection={isTopLevelSectionAdded} />
              )}
              {filtersTrigger}
            </div>
          </div>

          {!archived && (
            <AddRecordHeader
              addSection={addNewSectionTag}
              addRecord={addRecordOnHeader}
              recordName={recordName}
              canAddSection
              hideAddRecordButton={
                CTASubject === "Audit" && sectionTags.length === 0
              }
            />
          )}
          {filtersViewEnabled && (
            <div className="filters-wrap">
              <div className="switch-table-wrap" />
              {filtersContent}
            </div>
          )}
          <div
            className={`table-list-wrap ${wrapperClass}`}
            data-testid={`${rolePrefix}-content`}
          >
            {isTopLevelSectionAdded && (
              <SectionDropdown
                archived={archived}
                archivedWord={archivedWord}
                key={-1}
                dropdownTitle=""
                isDropdownOpen
                tableName={tableName || undefined}
                fields={fields}
                recordVersions={recordVersions.filter(
                  (recordVersion) => !recordVersion.section_tag_id,
                )}
                moduleWorkspaceID={moduleWorkspaceID || undefined}
                approvalFieldName={approvalFieldName}
                recordName={recordName}
                rolePrefix={rolePrefix}
                createRecord={handleCreateRecord}
                moduleIdentifier={moduleIdentifier}
                editable
                setCustomColumns={lockTab}
                isTopLevelSection
                maxDateFunction={maxDateFunction}
                minDateFunction={minDateFunction}
                dataTestId="section-page-top-level-section"
                paginated={paginated}
                setPages={setPages}
                page={pages[0]}
                total={counts["0"]}
                setSortByDirection={setSortByDirection}
                setSortByFieldName={setSortByFieldName}
                sortByFieldName={sortByFieldName || undefined}
                sortByDirection={sortByDirection || undefined}
                setSortBy={setSortBy}
                isDraggable={isDraggable}
              />
            )}
            {sectionTags.map((sectionTag) => {
              const filteredRecordVersions = recordVersions.filter(
                (recordVersion) =>
                  recordVersion.section_tag_id === sectionTag.id,
              );

              return (
                <SectionDropdown
                  archived={archived}
                  archivedWord={archivedWord}
                  key={sectionTag.id}
                  dropdownTitle={sectionTag.title}
                  isDropdownOpen
                  tableName={tableName || undefined}
                  fields={fields}
                  recordVersions={filteredRecordVersions}
                  maxDateFunction={maxDateFunction}
                  minDateFunction={minDateFunction}
                  moduleWorkspaceID={moduleWorkspaceID || undefined}
                  approvalFieldName={approvalFieldName}
                  recordName={recordName}
                  setSectionTagId={setActiveSectionTagId}
                  rolePrefix={rolePrefix}
                  createRecord={handleCreateRecord}
                  moduleIdentifier={moduleIdentifier}
                  editable={sectionTag.editable && !lockTab}
                  setCustomColumns={lockTab}
                  sectionTag={sectionTag}
                  paginated={paginated}
                  setPages={setPages}
                  page={pages[sectionTag.id]}
                  total={counts[sectionTag.id]}
                  setSortByDirection={setSortByDirection}
                  setSortByFieldName={setSortByFieldName}
                  sortByFieldName={sortByFieldName || undefined}
                  sortByDirection={sortByDirection || undefined}
                  setSortBy={setSortBy}
                  isDraggable={isDraggable}
                />
              );
            })}

            {showAddNewSectionTag && (
              <NewSectionTag
                moduleWorkspaceID={moduleWorkspaceID!}
                onCancel={() => setAddNewSectionTag(false)}
              />
            )}

            {!archived && fields.length > 0 && canManageSections && (
              <div className="tw-sticky tw-left-[25px] tw-ml-[25px]">
                <AddNewSectionButton onClick={addNewSectionTag} />
              </div>
            )}
            {recordVersions.length < 3 && !lockTab && renderCTA()}
          </div>
        </>
      )}
    </Table>
  );
}

export default observer(SectionPage);
