import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { useMainStore } from "../../../contexts/Store";
import closeIcon from "../../../images/table-image/icon/close-black-icon.svg";
import search from "../../../images/table-image/icon/search-black-two.svg";
import { userColors } from "../../constants";

const RELOAD_TIMEOUT = 1000;

type Props = {
  emailSlideClose?: () => void;
  recordVersionID?: number;
  recordVersionName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reload?: (...args: any[]) => any;
};

function EditAttestationsList({
  emailSlideClose,
  recordVersionID,
  recordVersionName,
  reload,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Refs
  const usersListRef = useRef(null);

  // State
  const [selectedUserIDs, setSelectedUserIDs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [searchUsersActive, setSearchUsersActive] = useState(false);

  // vars
  const filteredUsers = mainStore.users.users
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    .filter((user) => !selectedUserIDs.includes(user.id))
    .filter(
      (searchItems) =>
        searchItems.full_name?.toLowerCase()?.includes(query.toLowerCase()),
    );
  const filteredContacts = mainStore.contacts.list
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    .filter((contacts) => !selectedUserIDs.includes(contacts.id))
    .filter(
      (searchItems) =>
        searchItems.full_name?.toLowerCase()?.includes(query.toLowerCase()),
    );
  const buttonDisabled = useMemo(
    () => selectedUserIDs.length === 0,
    [selectedUserIDs],
  );
  const buttonClasses = classNames({ active: !buttonDisabled });
  const searchClasses = classNames("list-block", {
    "active-search": query.length > 0,
  });

  // Hooks
  useEffect(() => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
    function handleClickOutside(event) {
      if (
        usersListRef.current &&
        // @ts-expect-error TS(2339) FIXME: Property 'contains' does not exist on type 'never'... Remove this comment to see the full error message
        !usersListRef.current.contains(event.target)
      ) {
        setSearchUsersActive(false);
      }
    }

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const { statuses } = mainStore.attestations;
    if (!statuses?.length) {
      return;
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setSelectedUserIDs(statuses.map((item) => item.attester.id));
  }, [mainStore.attestations.statuses]);

  // funcs
  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function searchUsersChange(event) {
    const { value } = event.target;
    setQuery(value);
  }

  async function onSend() {
    if (!recordVersionID) {
      return;
    }

    const newUsersIDs = selectedUserIDs.filter(
      (id) =>
        !mainStore.attestations.statuses.find(
          // @ts-expect-error TS(2339) FIXME: Property 'attester' does not exist on type 'never'... Remove this comment to see the full error message
          (item) => item.attester.id === id,
        ),
    );
    const removedUsersIDs = mainStore.attestations.statuses
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      .filter((item) => !selectedUserIDs.includes(item.attester.id))
      // @ts-expect-error TS(2339) FIXME: Property 'attester' does not exist on type 'never'... Remove this comment to see the full error message
      .map((item) => item.attester.id);
    if (removedUsersIDs.length === 0 && newUsersIDs.length === 0) {
      return;
    }

    setLoading(true);
    if (removedUsersIDs.length > 0) {
      await mainStore.attestations.delete(recordVersionID, {
        user_ids: removedUsersIDs,
      });
    }
    if (newUsersIDs.length > 0) {
      await mainStore.attestations.resend(recordVersionID, {
        user_ids: newUsersIDs,
      });
    }

    // Apparently, we have some logic in model (after_commit), which needs a bit of time to be completed.
    // This timeout will provide that time, without it #reload will return attestations with old state
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    setTimeout(reload, RELOAD_TIMEOUT);
    setLoading(false);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    emailSlideClose();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function addUserById(id) {
    // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
    setSelectedUserIDs([...selectedUserIDs, id]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
  function remove(userID) {
    setSelectedUserIDs(selectedUserIDs.filter((item) => item !== userID));
  }

  // elements
  const searchInput = (
    <label className="form-label" data-testid="add-attester-input">
      <input
        placeholder="– Search here –"
        onChange={searchUsersChange}
        onClick={() => setSearchUsersActive(true)}
      />
      <img src={search} alt="search-icon" />
    </label>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'users' implicitly has an 'any' type.
  const renderUsersList = (users) =>
    users
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      .filter((item) => !selectedUserIDs.includes(item.id))
      // @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
      .map((user) => {
        const style = { background: userColors[user.icon_color_index] };

        return (
          <div
            key={user.id}
            className="email-list-wrap"
            data-testid="list-users"
          >
            <div
              data-testid="email-list-user-li"
              className={searchClasses}
              onClick={() => {
                addUserById(user.id);
              }}
            >
              <div className="users" data-testid="user">
                <div className="users-circle" style={style}>
                  <span>{user.initials}</span>
                </div>
                <div data-testid="user-name" className="user-full-name">
                  {user.full_name}
                </div>
              </div>
            </div>
          </div>
        );
      });

  return (
    <div
      className="table-dropdown table-email-dropdown"
      data-testid="edit-attestation-list-dropdown"
    >
      <div className="email-dropdown-head">
        <h3>Edit Attestation List</h3>
        <div onClick={emailSlideClose}>
          <img src={closeIcon} alt="close-icon" />{" "}
        </div>
      </div>
      <div className="table-email-single-select">
        <div className="table-email-block">
          <div className="table-email-element">{recordVersionName}</div>
        </div>
      </div>
      <hr />
      <ul className="users-menu-attestation edit-attestation-list">
        {searchInput}
        {searchUsersActive &&
          (filteredUsers.length > 0 || filteredContacts.length > 0) && (
            <div className="email-popup-wrap" ref={usersListRef}>
              {renderUsersList(filteredUsers)}
              {renderUsersList(filteredContacts)}
            </div>
          )}

        {[...mainStore.users.users, ...mainStore.contacts.list]
          // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
          .filter((user) => selectedUserIDs.includes(user.id))
          .map((user) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const style: any = {
              background: !user.is_contact && userColors[user.icon_color_index],
              borderColor: user.is_contact && userColors[user.icon_color_index],
            };
            const usersClass = classNames("users-circle", {
              "users-circle-contact": user.is_contact,
            });

            return (
              <li key={`user-${user.id}`} className="table-dropdown-status">
                <div className="status-users">
                  <span className={usersClass} style={style}>
                    {user.initials}
                  </span>
                  {user.greeting}
                </div>
                {!mainStore.attestations.statuses.find(
                  (item) =>
                    // @ts-expect-error TS(2339) FIXME: Property 'status' does not exist on type 'never'.
                    item.status === "attested" &&
                    // @ts-expect-error TS(2339) FIXME: Property 'attester' does not exist on type 'never'... Remove this comment to see the full error message
                    item.attester.id === user.id,
                ) ? (
                  <div className="table-checkbox">
                    <img
                      onClick={() => remove(user.id)}
                      src={closeIcon}
                      alt="close-icon"
                      data-testid="edit-attestation-list-close-icon"
                    />
                    <label htmlFor={`user-${user.id}`} />
                  </div>
                ) : (
                  <div className="status-wrap">
                    <div className="attested">
                      <span>Attested</span>
                    </div>
                  </div>
                )}
              </li>
            );
          })}
      </ul>
      <button
        className={buttonClasses}
        disabled={buttonDisabled}
        onClick={onSend}
      >
        {loading ? "Updating" : "Update"}
      </button>
    </div>
  );
}

export default observer(EditAttestationsList);
