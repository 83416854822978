import * as Sentry from "@sentry/react";
import { handleDownload } from "@themis/ui";
import { cloneDeep, get, omit } from "lodash";
import LogRocket from "logrocket";
import { action, computed, makeObservable, observable } from "mobx";
import { io } from "socket.io-client";

import { CreateBlobMutationResponse } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";
import { identifyUser } from "@/lib/logrocket";
import { toDisplayUser } from "@/stores/helpers/UsersHelpers";
import { formatErrors } from "@/utils/errors";

import { API_URL } from "../../components/constants";
import { MainStore } from "../Main";
import { DisplayUser, User } from "../types/user-types";

export default class UsersStore {
  mainStore: MainStore;

  socket = null;
  subscribedWorkspaceID: number | null = null;
  subscription = null;

  // Observable objects
  user: Partial<User> = {};
  users: User[] = []; // Active Users
  deletedUsers: User[] = []; // Deleted Users
  allUsers: DisplayUser[] = []; // All users including deleted
  userErrors = {};
  resetPasswordIsSent = false;
  newPasswordIsSet = false;
  selectedUser: User | null = null;
  isLoading = false;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      socket: observable,
      user: observable,
      users: observable,
      deletedUsers: observable,
      allUsers: observable,
      userErrors: observable,
      resetPasswordIsSent: observable,
      newPasswordIsSet: observable,
      selectedUser: observable,
      isLoading: observable,
      setUser: action,
      setUsers: action,
      setUserErrors: action,
      setResetPasswordIsSent: action,
      setNewPasswordIsSet: action,
      setSelectedUser: action,
      updateSelectedUser: action,
      allDisplayUsers: computed,
      activeDisplayUsers: computed,
      inactiveDisplayUsers: computed,
    });

    this.mainStore = mainStore;
  }

  async indexForSettings({
    workspaceID,
    showAllUsers = false,
    sortBy = null,
    sortByDirection = null,
  }: {
    workspaceID: string | number;
    showAllUsers?: boolean;
    sortBy?: string | null;
    sortByDirection?: string | null;
  }) {
    try {
      this.isLoading = true;

      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/workspaces/${workspaceID}/users`,
        params: {
          sortBy,
          sortByDirection,
          all_users: showAllUsers,
        },
        headers: this.mainStore.getHeaders(),
      });

      this.isLoading = false;
      this.setUsers(result.data.users);
      return result.data.users;
    } catch (error) {
      window.console.log(`"Users#index" error ${error}`);
    }
  }

  // This method is for getting users for user-selects, comments etc. It should not be used for the others;
  async indexForModules({ workspaceID }: { workspaceID: number }) {
    if (window.location.pathname.includes("/settings/users")) {
      return;
    }
    this.mainStore.contacts.index({
      sortParams: { field_name: "email", direction: "ASC" },
    });

    try {
      const {
        data: { users },
      } = await legacyApi({
        method: "GET",
        url: `${API_URL}/workspaces/${workspaceID}/users`,
        params: {
          brief: true,
        },
        headers: this.mainStore.getHeaders(),
      });

      this.setUsers(users);
    } catch (error) {
      window.console.log(`"Users#indexAll" error ${error}`);
    }
  }

  indexForModuleIfNoUsersPresent(workspaceID: number) {
    if (!this.users.length) {
      return this.indexForModules({ workspaceID });
    }
  }

  show(userID = "current") {
    legacyApi({
      method: "GET",
      url: `${API_URL}/users/${userID}`,
      headers: this.mainStore.getHeaders(),
    }).then((response) => {
      if (response.status === 200) {
        this.setUser(response.data.user);
        this.mainStore.notifications.setUnreadNotificationsCount(
          response.data.user.unread_notifications_count,
        );
      }
    });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'email' implicitly has an 'any' type.
  async create(email, password) {
    const userParams = {
      user: {
        email,
        password,
      },
    };

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/users`,
        headers: this.mainStore.getHeaders(),
        data: userParams,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.setUserErrors(result.response.data.errors);
        return;
      }

      this.setUser(result.data.user);
      this.mainStore.webSessions.setAccessToken(
        result.data.web_session.access_token,
      );
    } catch (error) {
      window.console.log(`"Users#create for Company" error ${error}`);
    }
  }

  /**
   POST /api/react/workspaces/:workspace_id/users/create_with_params
   */
  async createWithParams(
    // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
    workspaceID,
    // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
    params,
    contacts = false,
    refetchList = true,
  ) {
    const roles = get(params, "roles");

    if (!roles) {
      this.mainStore.toast.setErrorText(
        "Please select Role for User before saving",
      );
      return;
    }

    const data = {
      user: omit(params, "roles"),
      skip_default_permission: params.skip_default_permission,
    };

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/workspaces/${workspaceID}/users/create_with_params`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(result?.response);
        return;
      }

      if (roles) {
        await this.assignRoles(result.data.user.id, roles);
      }

      const name = params.email || params.full_name || "User";
      let toastSuccess = `${name} has been added. They will receive an email to sign up.`;
      if (contacts === true) {
        toastSuccess = `${name} has been added.`;
      }
      this.mainStore.toast.setInfoText(toastSuccess);

      // Refetch the list of Users
      if (refetchList && workspaceID) {
        contacts
          ? // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
            this.mainStore.contacts.index({})
          : this.indexForSettings({ workspaceID });
      }

      // If we associated User with Workspace - refetch list of Workspaces
      if (workspaceID) {
        this.mainStore.workspaces.index();
      }

      return result.data.user;
    } catch (error) {
      window.console.log(`"Users#createWithParams for Company" error ${error}`);
    }
  }

  async updateWithParams(
    // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
    companyID,
    // @ts-expect-error TS(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
    userID,
    // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
    params,
    contacts = false,
    refetchList = true,
    workspaceID: null | number = null,
  ) {
    const userParams = {
      user: params,
      company_id: companyID,
    };

    try {
      const result = await legacyApi({
        method: "PUT",
        url: `${API_URL}/users/${userID}`,
        headers: this.mainStore.getHeaders(),
        data: userParams,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(result.response);
        return;
      }

      // Refetch the list of Users
      const activeWorkspaceID = this.mainStore.users.user.active_workspace?.id;
      if (refetchList && activeWorkspaceID) {
        contacts
          ? // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
            this.mainStore.contacts.index({})
          : this.indexForSettings({ workspaceID: activeWorkspaceID });
      }

      // If User was edited from Collaborative Workspace - refetch list of Workspaces
      if (workspaceID) {
        this.mainStore.workspaces.index();
      }

      // if current user updated
      if (userID === this.user.id) {
        this.setUser(result.data.user);
      }
    } catch (error) {
      window.console.log(`"Users#updateWithParams for Company" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
  async assignRoles(userID, role) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/permission_sets/${role.permission_set_id}/applied_permission_sets`,
        headers: this.mainStore.getHeaders(),
        data: {
          user_id: userID,
          ...omit(role, "permission_set_id"),
        },
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        throw response.response.data.errors;
      }
    } catch (error) {
      window.console.log(`"Users#assignRoles for Company" error ${error}`);
      throw error;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
  async unassignRole(userID, roleID) {
    try {
      this.isLoading = true;

      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/permission_sets/applied_permission_sets/${roleID}`,
        headers: this.mainStore.getHeaders(),
      });

      this.isLoading = false;

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        throw response.response.data.errors;
      }
    } catch (error) {
      window.console.log(`"Users#unassignRole for Company" error ${error}`);
      throw error;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async reinvite(workspaceID, userID, contacts = false, refetchList = true) {
    const data = {
      workspace_id: workspaceID,
      user_id: userID,
    };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/users/reinvite`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // Refetch the list of Users
      const activeWorkspaceID = this.mainStore.users.user.active_workspace?.id;
      if (refetchList && activeWorkspaceID) {
        contacts
          ? // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
            this.mainStore.contacts.index({})
          : // @ts-expect-error TS(2339) FIXME: Property 'index' does not exist on type 'UsersStor... Remove this comment to see the full error message
            this.index({ workspaceID: activeWorkspaceID });
      }
    } catch (error) {
      window.console.log(`"Users#reinvite for Company" error ${error}`);
    }
  }

  // DELETE /api/react/workspaces/:workspace_id/users/:user_id
  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async removeFromWorkspace(workspaceID, userID, contacts = false) {
    if (!workspaceID || !userID) {
      window.console.warn(
        `Users#delete => workspaceID(${workspaceID}) and userID(${userID}) should be present.`,
      );
      return;
    }

    const workspace = this.mainStore.workspaces.list.find(
      (ws) => ws.id === workspaceID,
    );
    if (!workspace) {
      window.console.log(
        `"Users#delete" => Workspace with ID (${workspaceID}) not found.`,
      );
      return;
    }

    try {
      const result = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/workspaces/${workspace.id}/users/${userID}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(result.response);
        return;
      }

      // Refetch the list of Users
      contacts
        ? // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
          this.mainStore.contacts.index({})
        : this.indexForSettings({ workspaceID });

      // Refetch list of Workspaces after User was removed
      if (workspace.is_internal) {
        this.mainStore.workspaces.index();
      }
    } catch (error) {
      window.console.log(`"Users#removeFromWorkspace" error ${error}`);
    }
  }

  // DELETE /api/react/companies/:company_id/users/:user_id
  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async removeFromCompany(companyID, workspaceID, userID, contacts = false) {
    if (!companyID || !workspaceID || !userID) {
      window.console.warn(
        `Users#delete => companyID(${companyID}) and workspaceID(${workspaceID}) and userID(${userID}) should be present.`,
      );
      return;
    }

    const workspace = this.mainStore.workspaces.list.find(
      (ws) => ws.id === workspaceID,
    );
    if (!workspace) {
      window.console.log(
        `"Users#delete" => Workspace with ID (${workspaceID}) not found.`,
      );
      return;
    }

    try {
      const result = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/companies/${companyID}/users/${userID}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(result.response);
        return;
      }

      // Refetch the list of Users
      contacts
        ? // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
          this.mainStore.contacts.index({})
        : this.indexForSettings({ workspaceID });

      // Refetch list of Workspaces after User was removed
      if (workspace.is_internal) {
        this.mainStore.workspaces.index();
      }
    } catch (error) {
      window.console.log(`"Users#removeFromCompany" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async exportWorkspaceUsers(workspaceID) {
    this.mainStore.toast.setInfoText("Processing user list download...");
    const params = { workspace_id: workspaceID };

    const result = await legacyApi({
      method: "GET",
      url: `${API_URL}/users/export_workspace_users`,
      headers: this.mainStore.getHeaders(),
      params,
    });
    // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
    if (result.isAxiosError) {
      this.mainStore.toast.setErrorText(
        `An error occurred during export. Please try again or contact Themis support if the problem persists. Details: ${formatErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response?.data?.errors,
        )}`,
      );
      return;
    }

    return result.data;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async exportCompanyUsers(companyID) {
    this.mainStore.toast.setInfoText("Processing user list download...");
    const params = { company_id: companyID };
    const result = await legacyApi({
      method: "GET",
      url: `${API_URL}/users/export_company_users`,
      headers: this.mainStore.getHeaders(),
      params,
    });
    // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
    if (result.isAxiosError) {
      this.mainStore.toast.setErrorText(
        `An error occurred during export. Please try again or contact Themis support if the problem persists. Details: ${formatErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response?.data?.errors,
        )}`,
      );
      return;
    }

    return result.data;
  }

  async resend_email_confirmation() {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/users/resend_email_confirmation`,
        headers: this.mainStore.getHeaders(),
      });

      this.mainStore.toast.setText(response.data.message);
    } catch (error) {
      window.console.log(`"Users#resend_email_confirmation" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'inviteToken' implicitly has an 'any' ty... Remove this comment to see the full error message
  async resend_invite_confirmation(inviteToken, userId, email = "") {
    const params = {
      invite_token: inviteToken,
      user_id: userId,
      email,
    };
    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/users/resend_invite_confirmation`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });
    } catch (error) {
      window.console.log(`"Users#resend_invite_confirmation" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'email' implicitly has an 'any' type.
  resetPassword(email) {
    const userParams = {
      user: {
        email,
      },
    };

    legacyApi({
      method: "POST",
      url: `${API_URL}/users/reset_password`,
      headers: this.mainStore.getHeaders(),
      data: userParams,
    }).then((response) => {
      if (response.status === 200) {
        this.setResetPasswordIsSent(true);
      }
    });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'password' implicitly has an 'any' type.
  async newPassword(password, passwordResetToken) {
    const userParams = {
      user: {
        password,
        password_reset_token: passwordResetToken,
      },
    };

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/users/new_password`,
        headers: this.mainStore.getHeaders(),
        data: userParams,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.setUserErrors(result.response.data.errors);
        return;
      }

      this.setNewPasswordIsSet(true);
    } catch (error) {
      window.console.log(`"Users#newPassword" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'password' implicitly has an 'any' type.
  async newPasswordAuthenticated(password, newPassword) {
    const userParams = {
      user: {
        password,
        new_password: newPassword,
      },
    };

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/users/new_password_authenticated`,
        headers: this.mainStore.getHeaders(),
        data: userParams,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.setNewPasswordIsSet(false);
        return;
      }

      this.setNewPasswordIsSet(true);
    } catch (error) {
      window.console.log(`"Users#newPassword" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'emails' implicitly has an 'any' type.
  async inviteUsers(emails, companyID) {
    const data = {
      emails,
    };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/companies/${companyID}/users/invite`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"Users#inviteUsers" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'invite_token' implicitly has an 'any' t... Remove this comment to see the full error message
  async fetchInvitedUser(invite_token) {
    const data = { invite_token };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/users/fetch_invited_user`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      return response?.data;
    } catch (error) {
      window.console.log(`"Users#fetchInvitedUser" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'invite_token' implicitly has an 'any' t... Remove this comment to see the full error message
  async signUpInvitedUser(invite_token, params) {
    const data = {
      invite_token,
      user: params,
    };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/users/invite_sign_up`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setUser(response.data.user);
      this.mainStore.webSessions.setAccessToken(
        response.data.web_session.access_token,
      );
    } catch (error) {
      window.console.log(`"Users#signUpInvitedUser" error ${error}`);
    }
  }

  async importCsvFile(
    file: File,
    workspace_id: number,
    isUsers: boolean,
    showToastMessage: boolean = true,
  ) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("workspace_id", workspace_id.toString());
    formData.append("is_users", isUsers.toString());

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/users/import`,
        headers: {
          ...this.mainStore.getHeaders(),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        if (result.response.status === 400) {
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          this.mainStore.toast.setErrorFromResponse(result.response);
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        } else if (result.response.status === 403) {
          this.mainStore.toast.setErrorText(
            "You do not have permission to perform this operation",
          );
        } else {
          this.mainStore.toast.setErrorText("An unexpected error occurred!");
        }
        return;
      }

      if (showToastMessage) {
        const toastText = `${
          isUsers ? "Users" : "Contacts"
        } are being created... Please refresh the page momentarily`;
        this.mainStore.toast.setText(toastText);
      }
      return result;
    } catch (error) {
      window.console.log(`"Users#importCsvFile" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'code' implicitly has an 'any' type.
  async validateMFACode(code) {
    const data = { code };

    try {
      const res = await legacyApi({
        method: "POST",
        url: `${API_URL}/users/mfa/validate`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (res.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        return [false, res.response.data];
      }

      return [true, null];
    } catch (error) {
      window.console.log(`"Users#validateMFACode for Company" error ${error}`);
    }
  }

  async enrollMFA() {
    try {
      const res = await legacyApi({
        method: "POST",
        url: `${API_URL}/users/mfa/enroll`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (res.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        return [false, res.response.data || {}];
      }

      return [true, res.data || {}];
    } catch (error) {
      window.console.log(`"Users#enrollMFA for Company" error ${error}`);
    }
  }

  get allDisplayUsers(): DisplayUser[] {
    return this.allUsers.map(toDisplayUser);
  }

  get activeDisplayUsers(): DisplayUser[] {
    return this.users.map(toDisplayUser);
  }

  get inactiveDisplayUsers(): DisplayUser[] {
    return this.deletedUsers.map(toDisplayUser);
  }

  // Actions

  setUser(value: Partial<User>) {
    if (value) {
      if (this.user !== value) {
        identifyUser(value);
        Sentry.setUser({ id: value?.id?.toString() });
      }

      this.user = value;
    } else {
      LogRocket.startNewSession();
      Sentry.setUser(null);
      this.user = {};
    }

    const activeWorkspaceID = value?.active_workspace?.id;

    // When Workspace has changed and there is still a subscription to previous Workspace
    if (
      this.subscribedWorkspaceID &&
      activeWorkspaceID !== this.subscribedWorkspaceID
    ) {
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      this.socket.disconnect();
      this.subscribedWorkspaceID = null;
    }

    if (activeWorkspaceID && !this.subscribedWorkspaceID) {
      this.subscribedWorkspaceID = activeWorkspaceID;

      const broadcastingActions = [
        {
          action: "record_discarded",
          // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
          handle: (data) => {
            if (data.record_id) {
              this.mainStore.records.deleteRecordByID(data.record_id);
            } else {
              window.console.log("record_discarded broadcasting error");
            }
          },
        },
        {
          action: "record_version_created",
          // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
          handle: async (data) => {
            if (data.record_version) {
              const recordVersion =
                await this.mainStore.recordVersions.fetchRecordVersionByID(
                  data.record_version.id,
                );
              if (!recordVersion) {
                return;
              }

              this.mainStore.recordVersions.createUpdateRecordVersion(
                recordVersion,
                true,
              );
            } else {
              window.console.log("record_version_created broadcasting error");
            }
          },
        },
        {
          action: "record_version_updated",
          // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
          handle: async (data) => {
            if (data.record_version) {
              const recordVersion =
                await this.mainStore.recordVersions.fetchRecordVersionByID(
                  data.record_version.id,
                );
              if (!recordVersion) {
                return;
              }

              this.mainStore.recordVersions.createUpdateRecordVersion(
                recordVersion,
                true,
              );
            } else {
              window.console.log("record_version_updated broadcasting error");
            }
          },
        },
        {
          action: "record_version_replaced",
          // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
          handle: async (data) => {
            if (data.previous_record_version && data.new_record_version) {
              const newRecordVersion =
                await this.mainStore.recordVersions.fetchRecordVersionByID(
                  data.new_record_version.id,
                );
              if (!newRecordVersion) {
                return;
              }

              this.mainStore.recordVersions.replaceRecordVersion(
                data.previous_record_version.id,
                newRecordVersion,
              );
            } else {
              window.console.log("record_version_replaced broadcasting error");
            }
          },
        },
        {
          action: "record_version_deleted",
          // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
          handle: (data) => {
            if (data.record_version_id) {
              this.mainStore.recordVersions.deleteRecordVersionByID(
                data.record_version_id,
              );
            } else {
              window.console.log("record_version_deleted broadcasting error");
            }
          },
        },
        {
          action: "vendor_checklist_updated",
          // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
          handle: (data) => {
            if (data.vendor_checklist_id) {
              this.mainStore.vendors.updateVendorChecklistIfPresent(
                data.vendor_checklist_id,
                data.uncompleted_comments_count,
              );
            } else {
              window.console.log("vendor_checklist_updated broadcasting error");
            }
          },
        },
        {
          action: "section_tag_set",
          // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
          handle: (data) => {
            if (data.section_tags) {
              this.mainStore.sectionTags.setList(data.section_tags);
            } else {
              window.console.log("section_tag_set broadcasting error");
            }
          },
        },
        {
          action: "error_toast",
          // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
          handle: (data) => {
            if (data.user_id === this.mainStore.users.user.id) {
              this.mainStore.toast.setErrorText(data.message);
            } else {
              window.console.log("error_toast broadcasting error ");
            }
          },
        },
        {
          action: "success_toast",
          // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
          handle: (data) => {
            if (data.user_id === this.mainStore.users.user.id) {
              this.mainStore.toast.setText(data.message);
            } else {
              window.console.log("success_toast broadcasting error ");
            }
          },
        },
        {
          action: "new_version",
          // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
          handle: (data) => {
            if (data) {
              this.mainStore.toast.setNewVersion();
            } else {
              window.console.log("new_version broadcasting error ");
            }
          },
        },
        {
          action: "file",
          // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
          handle: (data) => {
            const spreadSheetData = data.file;
            const { filename } = data;
            if (spreadSheetData) {
              this.mainStore.toast.setText(
                "Export to Excel completed successfully.",
              );
              const link = document.createElement("a");
              const blob = new Blob([new Uint8Array(spreadSheetData)], {
                type: "application/octet-stream",
              });
              link.href = URL.createObjectURL(blob);
              link.download = filename;
              link.click();
            } else {
              window.console.log("files broadcasting error ");
            }
          },
        },
        {
          action: "file_created",
          handle: (data: CreateBlobMutationResponse) => {
            const spreadsheetFile = data.blob;
            if (spreadsheetFile) {
              handleDownload(
                spreadsheetFile.file_url,
                spreadsheetFile.file_name,
              );
            } else {
              window.console.warn(
                "file_created websocket event does NOT have 'blob' in payload",
              );
            }
          },
        },
        {
          action: "notifications_count_updated",
          // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
          handle: async (data) => {
            if (data) {
              this.mainStore.notifications.setUnreadNotificationsCount(
                data.unread_notifications_count,
              );
            } else {
              window.console.log(
                "notifications_count_updated broadcasting error ",
              );
            }
          },
        },
      ];

      // broadcasting logic
      const url = window.SOCKETS_SERVER_URL || window.location.host;
      // @ts-expect-error TS(2322) FIXME: Type 'Socket<DefaultEventsMap, DefaultEventsMap>' ... Remove this comment to see the full error message
      this.socket = io(url, {
        reconnectionDelayMax: 10000,
        transports: ["polling", "websocket"],
        upgrade: true,
        path: "/socket.io/",
        query: {
          workspace_id: activeWorkspaceID,
        },
        extraHeaders: {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          "X-Session-Token": this.mainStore.getHeaders()?.["X-Session-Token"],
        },
      });
      broadcastingActions.map((item) =>
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        this.socket.on(item.action, item.handle),
      );
    }
  }

  setSelectedUser(value: User) {
    if (value) {
      this.selectedUser = cloneDeep(value);
    } else {
      this.selectedUser = null;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
  updateSelectedUser(fieldName, value) {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    this.selectedUser[fieldName] = value;
  }

  setUsers(value: DisplayUser[] = []) {
    this.users = value.filter((user) => !user.is_inactive);
    this.deletedUsers = value.filter((user) => user.is_inactive);
    this.allUsers = value;
  }

  setUserErrors(value = {}) {
    this.userErrors = value;
  }

  setResetPasswordIsSent(value: boolean) {
    this.resetPasswordIsSent = value;
  }

  setNewPasswordIsSet(value: boolean) {
    this.newPasswordIsSet = value;
  }

  // Store Helpers

  cleanup() {
    this.setUser({});
    this.setUsers([]);
    this.setUserErrors({});
    this.setResetPasswordIsSent(false);
    this.setNewPasswordIsSet(false);
  }
}
