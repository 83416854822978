import { IconButton } from "@themis/ui";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { PiTrashSimpleBold } from "react-icons/pi";
import Popup from "reactjs-popup";

import { AppliedPermissionSet } from "@/api";
import { useMainStore } from "@/contexts/Store";

import Switch from "../shared/Switch";

type Props = {
  model: string;
  title: string | number;
  inTag?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onNo?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onYes?: (...args: any[]) => any;
  roles?: AppliedPermissionSet[];
  trigger?: React.ReactNode;
  showSubtext?: boolean;
};

function DeleteConfirmationDialog({
  roles,
  model,
  title,
  trigger,
  onYes,
  onNo,
  inTag = false,
  showSubtext = true,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [removeFromCompany, setRemoveFromCompany] = useState(false);

  // vars
  const { company } = mainStore.companies;
  const { isCompanySuperAdmin, isCompanyAdmin, isWorkspaceAdmin, companyID } =
    mainStore.context;
  const isUser = model === "User";
  const deleteDisabled =
    isUser &&
    isWorkspaceAdmin &&
    roles?.some(
      (role) =>
        role.permission_set.company_id === companyID &&
        ["Super Admin", "Company Admin"].includes(role.permission_set.name),
    );

  const isInternal = mainStore.context.activeWorkspace?.is_internal === true;
  const isInQATestingModule =
    mainStore.context.themisModuleIdentifier === "qa_tests_development";
  const hideDelete = !isInternal && isInQATestingModule;

  const userRemoveFromCompanyText = useMemo(() => {
    if (isUser && removeFromCompany) {
      const rolesInCompany = roles?.filter(
        (role) => role.permission_set.company_id === companyID,
      );
      if (rolesInCompany && rolesInCompany?.length > 1) {
        return "Deleting this user will remove them from all workspaces, both Internal and Collaborative";
      }

      return "Deleting this user will remove them from the current workspace";
    }

    return null;
  }, [isUser, removeFromCompany, company, roles]);

  function yesButtonPressed() {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    isUser ? onYes({ removeFromCompany }) : onYes();
  }

  function noButtonPressed() {
    setShowPopup(false);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onNo();
  }

  return (
    <div style={{ visibility: hideDelete ? "hidden" : "visible" }}>
      <Popup
        position="bottom right"
        open={showPopup}
        // @ts-expect-error TS(2322) FIXME: Type 'string | number | true | Iterable<ReactNode>... Remove this comment to see the full error message
        trigger={
          trigger || (
            <IconButton
              data-testid="user-delete-trigger"
              color="tertiary"
              size="lg"
              Icon={PiTrashSimpleBold}
              disabled={deleteDisabled}
            />
          )
        }
        onOpen={() => setShowPopup(true)}
        onClose={() => setShowPopup(false)}
        keepTooltipInside
      >
        <div className="table-dropdown" data-testid="confirmation-dialog">
          <h4>Delete {model}</h4>
          {inTag ? (
            <p>
              Deleting {model} - {title} will remove it from all Workspaces and
              Modules where it is an option or where it has been selected. Are
              you sure you would like to continue?
            </p>
          ) : (
            <p>
              Are you sure you want to delete this {model} - {title}?
            </p>
          )}

          {showSubtext && (
            <p style={{ color: "#F95B46" }}>Actions are not reversible.</p>
          )}
          {isUser && (isCompanySuperAdmin || isCompanyAdmin) && (
            <div className="popup-switch-block">
              <p>From Workspace</p>
              <Switch
                active
                indeterminate={false}
                checked={removeFromCompany}
                onChange={(e) => setRemoveFromCompany(e.target.checked)}
              />
              <p>From Company</p>
            </div>
          )}
          {userRemoveFromCompanyText && (
            <p style={{ color: "#F95B46" }}>{userRemoveFromCompanyText}</p>
          )}
          <div>
            <button
              onClick={yesButtonPressed}
              data-testid="confirmation-dialog-confirm"
            >
              Yes
            </button>
            <button
              onClick={noButtonPressed}
              data-testid="confirmation-dialog-reject"
            >
              No
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default observer(DeleteConfirmationDialog);
