import React from "react";
import { FieldValues, useWatch } from "react-hook-form";

import { Flex, Typography } from "@/components/Elements";
import { daysOfTheWeekOptions } from "@/features/notifications/pages/single/date/constants";

export const PSText: React.FC<FieldValues> = ({ control }) => {
  const frequency = useWatch({ control, name: "frequency" });
  const recurringWeekdays = useWatch({
    control,
    name: "timePeriod.recurringWeekdays",
  });
  const excludeWeekends = useWatch({
    control,
    name: "timePeriod.excludeWeekends",
  });

  if (
    !frequency ||
    ["one_time", "months"].includes(frequency) ||
    (frequency === "weeks" && !recurringWeekdays.length)
  ) {
    return null;
  }

  const label = (() => {
    if (frequency === "weeks") {
      const recurringWeekdaysSorted: string[] = [];

      daysOfTheWeekOptions.forEach((day) => {
        if (recurringWeekdays.includes(day.value)) {
          recurringWeekdaysSorted.push(day.value.substring(0, 3).toUpperCase());
        }
      });

      return {
        description: "Recurring weekdays of ",
        weekdays: recurringWeekdaysSorted.join(", "),
      };
    }

    if (frequency === "days") {
      return {
        description: `${excludeWeekends ? "Excluded" : "Included"} Weekends`,
      };
    }
  })();

  return (
    <Flex alignCenter>
      <Typography
        color="generalMidnightDark"
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        label={`PS. ${label.description}`}
      />
      {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
      {label.weekdays && (
        <>
          &nbsp;
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          <Typography label={label.weekdays} color="accentsSkyBlue" />
        </>
      )}
    </Flex>
  );
};
