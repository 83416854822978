import React, { CSSProperties, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import Popup from "reactjs-popup";

import fileAddGrayIcon from "../../../images/table-image/icon/file-add-gray-icon.svg";
import Spinner from "./Spinner";

export const FILE_TYPE = {
  PNG: ".png",
  JPEG: ".jpeg",
  DOCX: ".docx",
  PDF: "application/pdf",
  XML: ".xml",
  EXCEL: ".xlsx",
  POWERPOINT: ".ppt",
  TEXT: ".txt",
  images: "images/*",
} as const;

type FileType = keyof typeof FILE_TYPE;

function mapFileTypesToAcceptProperty(fileTypes: FileType[] | null): string {
  if (!fileTypes) {
    return Object.values(FILE_TYPE).join(", ");
  }

  return fileTypes.map((fileType) => FILE_TYPE[fileType]).join(", ");
}

function mapFileTypesToAcceptPlainText(fileTypes: FileType[]): string {
  return fileTypes.join(" / ");
}

type Props = {
  handleAttachment: (signedIds: string[], file: File) => void;
  trigger: React.ReactNode;
  centerPositioning?: boolean;
  disabled?: boolean;
  triggerStyles?: CSSProperties;
  acceptableFileTypes?: FileType[];
};

function FileInput({
  centerPositioning,
  handleAttachment,
  trigger,
  triggerStyles,
  disabled,
  acceptableFileTypes,
}: Props) {
  // State
  const [upload, setUpload] = useState<File | null>(null);
  const [uploadModalOpened, setUploadModalOpened] = useState(false);

  // Functions
  function onUploadModalOpen() {
    setUpload(null);
    setUploadModalOpened(true);
  }

  function onUploadModalClose() {
    setUploadModalOpened(false);
  }

  function handleOnSuccess(ids: string[]) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'File | null' is not assignable t... Remove this comment to see the full error message
    handleAttachment(ids, upload);
    onUploadModalClose();
  }

  return (
    <Popup
      position={centerPositioning ? "bottom center" : "bottom right"}
      trigger={() => (
        <div style={triggerStyles} data-testid="file-input-popup-trigger">
          {trigger}
        </div>
      )}
      open={uploadModalOpened}
      disabled={disabled}
      onOpen={onUploadModalOpen}
      onClose={onUploadModalClose}
      keepTooltipInside
    >
      <DirectUploadProvider
        onSuccess={handleOnSuccess}
        render={({ handleUpload, uploads }) => (
          <div data-testid="file-input-popup-content">
            <div className="drag-drop-wrap">
              <div className="drag-drop-block">
                {!upload && (
                  <div>
                    <img src={fileAddGrayIcon} alt="file-add-gray-icon.svg" />
                    <p>
                      Drag & drop into this box
                      <br />- or -
                    </p>
                    <button>Choose a file</button>
                    <p>
                      {acceptableFileTypes &&
                        `We accept: ${mapFileTypesToAcceptPlainText(
                          acceptableFileTypes,
                        )}`}
                      {!acceptableFileTypes &&
                        "We accept all kinds of files like images, pdfs, excel, powerpoint, etc."}
                    </p>
                    <input
                      type="file"
                      accept={
                        acceptableFileTypes?.length
                          ? mapFileTypesToAcceptProperty(acceptableFileTypes)
                          : undefined
                      }
                      onDrop={(event) =>
                        setUpload(
                          event.target &&
                            // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                            (event.target as HTMLInputElement).files[0],
                        )
                      }
                      onChange={(event) => {
                        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                        setUpload(event.target.files[0]);
                        handleUpload(event.currentTarget.files);
                      }}
                    />
                  </div>
                )}
                {
                  // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
                  uploads.map((elem) => {
                    switch (elem.state) {
                      case "waiting":
                        return (
                          <div key={elem.id} className="uploading">
                            <p>0%</p>
                            <Spinner />
                          </div>
                        );
                      case "uploading":
                        return (
                          <div key={elem.id} className="uploading">
                            <p>{Math.round(elem.progress)}%</p>
                            <Spinner />
                          </div>
                        );
                      case "error":
                        return (
                          <p key={elem.id}>
                            Error uploading {elem.file.name}: {elem.error}
                          </p>
                        );
                      default:
                        return null;
                    }
                  })
                }
              </div>
            </div>
          </div>
        )}
      />
    </Popup>
  );
}

export default FileInput;
