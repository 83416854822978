import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import classNames from "classnames";
import { isEmpty, map } from "lodash";
import React, { useEffect, useState } from "react";
import { ChartProps, Doughnut } from "react-chartjs-2";

import { Typography } from "@/components/Elements";
import { COLOR } from "@/config/theme";

import NoDataDonut from "../../../../images/home/empty-donut.svg";
import DonutTileLegend from "./DonutTileLegend";

export const DONUT_COLORS = [
  COLOR.extrasSkyBlue,
  COLOR.extrasPersianBlue,
  COLOR.extrasLightNavy,
  COLOR.extrasGrassGreen,
  COLOR.accentsPurple,
  COLOR.accentsPink,
  COLOR.extrasCarmine,
  COLOR.accentsOrange,
  COLOR.accentsSalmon,
  COLOR.extrasFlamingo,
];

ChartJS.register(ArcElement, Tooltip, Legend);

const OPTIONS = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const DATASET_OPTIONS = {
  borderColor: "white",
  hoverBorderColor: "white",
  cutout: "70%",
  rotation: 0,
  borderWidth: 2,
};

type Props = {
  data: {
    label: string;
    count: number;
    color: string;
  }[];
  label: string;
};

const DonutTile = ({ label, data }: Props) => {
  // let the Doughnut grow into its container
  // avoids issues where react-chartjs-2 will render too large a chart
  const [initialized, setInitialized] = useState(false);
  useEffect(() => setInitialized(true), []);

  const hasData = !isEmpty(data);
  const dataWithColor = map(data, (elem, index) => ({
    ...elem,
    color: elem.color || DONUT_COLORS[index],
  }));

  const donutData: ChartProps<"doughnut">["data"] = {
    labels: map(dataWithColor, "label"),
    datasets: [
      {
        ...DATASET_OPTIONS,
        animation: false,
        backgroundColor: map(dataWithColor, "color"),
        label,
        data: map(dataWithColor, "count"),
      },
    ],
  };

  return (
    <div className="donut-tile">
      <Typography
        className="donut-tile__title"
        label={label}
        size="sm"
        weight="bold"
        color="generalMidnightDark"
      />
      {hasData ? (
        <>
          <div
            className={classNames("donut-tile__graph", {
              "donut-tile__graph--initialized": initialized,
            })}
          >
            <Doughnut data={donutData} options={OPTIONS} />
          </div>
          <DonutTileLegend data={dataWithColor} />
        </>
      ) : (
        <>
          <img
            data-testid="donut-tile-no-data"
            className="donut-tile__no-data"
            src={NoDataDonut}
            alt=""
          />
          <Typography label="No data yet" color="generalMidnightDark" />
        </>
      )}
    </div>
  );
};

export default DonutTile;
