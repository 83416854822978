import "./Dropdown.scss";

import classNames from "classnames";
import { kebabCase } from "lodash";
import React, { cloneElement, ReactNode, useState } from "react";
import Popup from "reactjs-popup";

import DropdownTrigger from "@/components/Elements/Dropdown/DropdownTrigger";
import { useMainStore } from "@/contexts/Store";

import { Icon, Typography } from "../";

const DEFAULT_SCREEN = "optionsScreen";
const CUSTOM_VALUE_SCREEN = "customValueScreen";

export function getFilteredOptions(
  options: DropdownOptions,
  searchValue: string,
): DropdownOptions {
  const filteredOptions: DropdownOptions = [];

  let lastGroupName: string;
  options.forEach((option) => {
    if (typeof option === "string") {
      lastGroupName = option;
    } else if (
      option &&
      option.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
    ) {
      if (lastGroupName) {
        filteredOptions.push(lastGroupName);
        // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string'.
        lastGroupName = null;
      }
      filteredOptions.push(option);
    }
  });

  return filteredOptions;
}

export type DropdownOption = {
  id: string | number;
  name: string;
  icon?: string; // not totally sure on this type, should prob switch component to use Icon component
  element?: JSX.Element;
};

export type DropdownOptions = Array<DropdownOption | string>;

type DropdownProps = {
  options?: DropdownOptions;
  placeholder?: ReactNode;
  selected?: string | DropdownOption;
  onChange?: (option: DropdownOption) => void;
  disabled?: boolean;
  className?: string;
  id?: string;
  popupClassName?: string;
  activeClassName?: string;
  optionClassName?: string;
  searchable?: boolean;
  CustomValueContent?: JSX.Element;
  helperText?: string;
  hasTableView?: boolean;
  hasErrors?: boolean;
  width?: number | string;
  hasErrorClass?: string;
  locked?: boolean;
  hasTriggerForTable?: boolean;
};

/**
 * Group options by separating by groups of options with strings for group names
 * @example
 * [
 *   'Group 1',
 *   { id: 1, name: 'Option 1' },
 * ]
 */
function Dropdown({
  options = [],
  selected: _selected,
  disabled = false,
  placeholder,
  onChange = () => {},
  className,
  id,
  popupClassName,
  activeClassName = "",
  optionClassName,
  searchable = false,
  CustomValueContent,
  helperText,
  hasTableView = false,
  width,
  hasErrorClass = "has-errors",
  hasErrors,
  locked,
  hasTriggerForTable,
}: DropdownProps) {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [searchValue, setSearchValue] = useState("");
  const [popupScreen, setPopupScreen] = useState(DEFAULT_SCREEN);
  const [showPopup, setShowPopup] = useState(false);

  // Variables
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;
  const filteredOptions = getFilteredOptions(options, searchValue);
  const disablePopup = disabled || options.length === 0;
  // @ts-expect-error TS(2322) FIXME: Type 'DropdownOption | undefined' is not assignabl... Remove this comment to see the full error message
  const selected: DropdownOption =
    typeof _selected === "string"
      ? (options.find(
          (option) => typeof option !== "string" && option.id === _selected,
        ) as DropdownOption)
      : _selected;

  // Functions
  const handleChange = (option: DropdownOption) => {
    onChange(option);
    handlePopupClose();
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setSearchValue("");
    setPopupScreen(DEFAULT_SCREEN);
  };

  // Elements
  const dropdownTrigger = (
    <div
      id={id}
      className={classNames("dropdown", className, {
        disabled: disablePopup,
        [activeClassName]: showPopup,
      })}
      data-testid="dropdown"
    >
      <DropdownTrigger
        disabled={disabled}
        selected={selected}
        helperText={helperText}
        hasTableView={hasTableView}
        placeholder={placeholder}
      />
    </div>
  );

  return (
    <Popup
      trigger={
        hasTriggerForTable ? (
          <li
            style={{ width }}
            className={classNames("terms-dropdown-field", {
              active: showPopup,
              [hasErrorClass]: hasErrors,
              illumination: showPopup,
              "pointer-events-none": locked || isReadOnly,
              "table-cell--disabled": isReadOnly,
              "locked-cell": locked && isCurrentWorkspaceActive,
            })}
          >
            <div className="cell-content">{dropdownTrigger}</div>
          </li>
        ) : (
          dropdownTrigger
        )
      }
      position={["bottom center", "top center"]}
      open={showPopup}
      arrow={false}
      disabled={disablePopup}
      keepTooltipInside
      onOpen={() => setShowPopup(true)}
      onClose={handlePopupClose}
      className={popupClassName}
    >
      {popupScreen === CUSTOM_VALUE_SCREEN &&
        Boolean(CustomValueContent) &&
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        cloneElement(CustomValueContent, { handlePopupClose })}
      {popupScreen === DEFAULT_SCREEN && options.length > 0 && (
        <div
          className={classNames("dropdown-popup", {
            // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
            [optionClassName]: optionClassName,
          })}
          data-testid="dropdown-popup"
        >
          {searchable && (
            <div className="dropdown-search">
              <input
                data-testid="dropdown-search"
                type="text"
                placeholder="- Search here -"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
              />
              {searchValue && (
                <Icon
                  className="dropdown-search__close-icon"
                  name="close"
                  onClick={() => setSearchValue("")}
                />
              )}
            </div>
          )}
          <div className="dropdown-popup-options">
            {filteredOptions.map((option) => {
              if (typeof option === "string") {
                return (
                  <Typography
                    key={option}
                    label={option}
                    className="dropdown-group-name"
                    color="brandingDimViolet"
                    weight="semiBold"
                  />
                );
              }
              return (
                <div
                  key={option.id}
                  className={classNames("dropdown-option", {
                    active: option.id === selected?.id,
                  })}
                  data-testid={`dropdown-option-${kebabCase(option.name)}`}
                  onClick={() => handleChange(option)}
                >
                  {option.icon && <img src={option.icon} />} {option.name}
                </div>
              );
            })}
            {Boolean(CustomValueContent) && (
              <div
                className="dropdown-option"
                data-testid="dropdown-option-custom-value"
                onClick={() => setPopupScreen(CUSTOM_VALUE_SCREEN)}
              >
                Custom Value
              </div>
            )}
          </div>
        </div>
      )}
    </Popup>
  );
}

export default Dropdown;
