import _ from "lodash";
import React from "react";

import { Typography } from "../../../Elements";

type Props = {
  data: {
    label: string;
    color: string;
  }[];
};

const DonutTileLegend = ({ data }: Props) => {
  // @ts-expect-error TS(2339) FIXME: Property 'count' does not exist on type '{ label: ... Remove this comment to see the full error message
  const nonEmptyValues = _.filter(data, (entry) => entry.count > 0);

  return (
    <div className="donut-tile-legend">
      {_.map(nonEmptyValues, (entry) => (
        <div
          className="donut-tile-legend__item"
          key={entry.label}
          data-testid="donut-tile-legend-item"
        >
          <div
            className="donut-tile-legend__item__marker"
            style={{ backgroundColor: entry.color }}
          />
          <Typography
            label={entry.label}
            size="xxs"
            weight="semiBold"
            color="generalDarkGray"
            title={entry.label}
          />
        </div>
      ))}
    </div>
  );
};

export default DonutTileLegend;
