import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import { MainStore } from "../Main";
import { Company } from "../types/company-types";

export default class CompaniesStore {
  mainStore: MainStore;

  company: Partial<Company> = {};

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      company: observable,
      setCompany: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/companies/:id
  async show(companyID = "current") {
    try {
      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/companies/${companyID}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        return;
      }

      this.setCompany(result.data.company);
      this.mainStore.fieldOptions.setColorSchemes(result.data.color_schemes);
    } catch (error) {
      window.console.log(`"Companies#show" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'tokenenized_workspace_id' implicitly ha... Remove this comment to see the full error message
  async company_from_workspace_id(tokenenized_workspace_id) {
    try {
      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/companies/${tokenenized_workspace_id}/company_from_workspace_id`,
        headers: this.mainStore.getHeaders(),
      });
      this.setCompany(result.data.company);

      return this.company;
    } catch (error) {
      window.console.log(`Companies#company_from_workspace_id ${error}`);
    }
  }

  // POST /api/react/companies
  // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
  create(params) {
    legacyApi({
      method: "POST",
      url: `${API_URL}/companies`,
      headers: this.mainStore.getHeaders(),
      data: params,
    }).then((response) => {
      if (response.status === 201) {
        this.mainStore.users.setUser(response.data.user);
        this.setCompany(response.data.company);
      }
    });
  }

  // PUT /api/react/companies/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async update(companyID, params) {
    try {
      const result = await legacyApi({
        method: "PUT",
        url: `${API_URL}/companies/${companyID}`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(result.response);
        return;
      }

      this.mainStore.toast.setInfoText("Company information has been updated");
      this.setCompany(result.data.company);
      // Refetch data to update Company name and logo on the page
      this.mainStore.workspaces.index();
      this.mainStore.users.show();
    } catch (error) {
      window.console.log(`"Companies#update" error ${error}`);
    }
  }

  // DELETE /api/react/companies/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async delete(companyID) {
    try {
      const result = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/companies/${companyID}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(result.response);
        return false;
      }

      return true;
    } catch (error) {
      window.console.log(`"Companies#delete" error ${error}`);
    }
  }

  setCompany(value: Partial<Company>) {
    if (value) {
      this.company = value;
      this.mainStore.context.setCompanyID(value.id);
    } else {
      this.company = {};
      this.mainStore.context.setCompanyID(null);
    }
  }

  // Store Helpers

  cleanup() {
    this.setCompany({});
  }
}
