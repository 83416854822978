import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import ReportsPage from "@/components/reports/common/ReportsPage";
import SingleSectionSelection from "@/components/reports/workspaceSelection/SingleSectionSelection";
import { useMainStore } from "@/contexts/Store";
import {
  ModuleIdentifier,
  TopLevelModule,
} from "@/stores/types/module-workspaces-types";

import complaintIcon from "../../images/modules/complaints.svg";
import documentsIcon from "../../images/modules/documents.svg";
import policyIcon from "../../images/modules/policies.svg";
import proceduresIcon from "../../images/modules/procedures.svg";
import marketingIcon from "../../images/setting-workspace/icon/Icon-AdvertisingAndMarketing.svg";
import changeManagementIcon from "../../images/setting-workspace/icon/Icon-ChangeManagement.svg";
import conflictOfInterestIcon from "../../images/setting-workspace/icon/Icon-ConflictOfInterest.svg";
import controlMappingIcon from "../../images/setting-workspace/icon/Icon-ControlMapping.svg";
import customerSupportIcon from "../../images/setting-workspace/icon/Icon-CustomerSupport.svg";
import finraIcon from "../../images/setting-workspace/icon/Icon-Finra.svg";
import issueManagementIcon from "../../images/setting-workspace/icon/Icon-IssueManagement.svg";
import qaIcon from "../../images/setting-workspace/icon/Icon-QATesting.svg";
import rrIcon from "../../images/setting-workspace/icon/Icon-RiskRegister.svg";
import trainingIcon from "../../images/setting-workspace/icon/Icon-Training.svg";
import vddIcon from "../../images/setting-workspace/icon/Icon-VendorDueDiligence.svg";
import arrowDownIcon from "../../images/table-image/icon/arrow-down-black.svg";
import { Typography } from "../Elements";
import { nameFromModuleWorkspace } from "../helpers/nameForThemisModuleIdentifier";
import Loading from "../Loading";
import ChangeManagementReportContent from "./changeManagement/ChangeManagementReportContent";
import ComplaintsReportContent from "./complaints/ComplaintsReportContent";
import ConflictsOfInterestReportContent from "./conflictsOfInterest/COIReportContent";
import ControlMappingReportContent from "./controlMapping/ControlMappingReportContent";
import CustomerSupportReportContent from "./customerSupport/CustomerSupportReportContent";
import DocumentsReportContent from "./documents/documents-report-content";
import FinraReportContent from "./finra/finra-report-content";
import IssueManagementReportContent from "./issueManagement/issue-management-report-content";
import MarketingReportContent from "./marketing/MarketingReportContent";
import PoliciesReportContent from "./policies/policies-report-content";
import ProceduresReportContent from "./procedures/procedures-report-content";
import QAReportContent from "./qa/QAReportContent";
import RiskRegisterReportContent from "./risk-register/RiskRegisterReportContent";
import TrainingReportContent from "./training/training-report-content";
import SingleWorkspaceSelection from "./workspaceSelection/SingleWorkspaceSelection";

function WorkspaceReports() {
  const { moduleName: moduleIdentifier } = useParams<{
    moduleName: ModuleIdentifier;
  }>();
  const history = useHistory();
  const mainStore = useMainStore();
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const [currentQAReport, setCurrentQAReport] = useState(
    "Status and Detail Report",
  );

  const activeIdentifiers = useMemo(() => {
    return moduleWorkspaces
      ?.filter((moduleWorkspace) => moduleWorkspace.added)
      ?.map((mw) => mw.themis_module.identifier);
  }, [moduleWorkspaces]);

  const REPORT_MODULES = [
    {
      name: "Policies",
      icon: policyIcon,
      identifier: TopLevelModule.POLICY,
      active: activeIdentifiers.includes(TopLevelModule.POLICY),
      value: () => <PoliciesReportContent />,
    },
    {
      name: "Procedures",
      icon: proceduresIcon,
      identifier: TopLevelModule.PROCEDURES,
      active: activeIdentifiers.includes(TopLevelModule.PROCEDURES),
      value: () => <ProceduresReportContent />,
    },
    {
      name: "Issue Management",
      active: activeIdentifiers.includes(TopLevelModule.ISSUE_MANAGEMENT),
      icon: issueManagementIcon,
      identifier: TopLevelModule.ISSUE_MANAGEMENT,
      value: () => <IssueManagementReportContent />,
    },
    {
      name: "Marketing",
      active: activeIdentifiers.includes(TopLevelModule.MARKETING),
      icon: marketingIcon,
      identifier: TopLevelModule.MARKETING,
      value: () => <MarketingReportContent />,
    },
    {
      name: "Change Management",
      active: activeIdentifiers.includes(TopLevelModule.CHANGE_MANAGEMENT),
      icon: changeManagementIcon,
      identifier: TopLevelModule.CHANGE_MANAGEMENT,
      value: () => <ChangeManagementReportContent />,
    },
    {
      name: "Training",
      active: activeIdentifiers.includes(TopLevelModule.TRAINING),
      icon: trainingIcon,
      identifier: TopLevelModule.TRAINING,
      value: () => <TrainingReportContent />,
    },
    {
      name: "Documents",
      active: activeIdentifiers.includes(TopLevelModule.DOCUMENTS),
      icon: documentsIcon,
      identifier: TopLevelModule.DOCUMENTS,
      value: () => <DocumentsReportContent />,
    },
    {
      name: "Risk Register",
      active: activeIdentifiers.includes(TopLevelModule.RISK_REGISTER),
      icon: rrIcon,
      identifier: TopLevelModule.RISK_REGISTER,
      value: () => <RiskRegisterReportContent />,
    },
    {
      name: "Control Mapping",
      active: activeIdentifiers.includes(TopLevelModule.CONTROL_MAPPING),
      icon: controlMappingIcon,
      identifier: TopLevelModule.CONTROL_MAPPING,
      value: () => <ControlMappingReportContent />,
    },
    {
      name: "Complaints",
      active: activeIdentifiers.includes(TopLevelModule.COMPLAINTS),
      icon: complaintIcon,
      identifier: TopLevelModule.COMPLAINTS,
      value: () => <ComplaintsReportContent />,
    },
    {
      name: "Conflicts of Interest",
      icon: conflictOfInterestIcon,
      identifier: TopLevelModule.CONFLICTS_OF_INTEREST,
      active: activeIdentifiers.includes(TopLevelModule.CONFLICTS_OF_INTEREST),
      value: () => <ConflictsOfInterestReportContent />,
    },
    {
      name: "Finra Review",
      active: activeIdentifiers.includes(TopLevelModule.FINRA_COMPLAINTS),
      icon: finraIcon,
      identifier: TopLevelModule.FINRA_COMPLAINTS,
      value: () => <FinraReportContent />,
    },
    {
      name: "Customer Support",
      active: activeIdentifiers.includes(TopLevelModule.CUSTOMER_SUPPORT),
      icon: customerSupportIcon,
      identifier: TopLevelModule.CUSTOMER_SUPPORT,
      value: () => <CustomerSupportReportContent />,
    },
    {
      name: "Monitoring & Testing",
      active: activeIdentifiers.includes(TopLevelModule.MONITORING_AND_TESTING),
      icon: qaIcon,
      identifier: TopLevelModule.MONITORING_AND_TESTING,
      // @ts-expect-error TS(7006) FIXME: Parameter 'currentReport' implicitly has an 'any' ... Remove this comment to see the full error message
      value: (currentReport) => (
        <QAReportContent currentReport={currentReport} />
      ),
    },
    {
      name: "Vendor Due Diligence",
      active: activeIdentifiers.includes(TopLevelModule.VENDOR_DUE_DILIGENCE),
      icon: vddIcon,
      identifier: TopLevelModule.VENDOR_DUE_DILIGENCE,
      value: () => (
        <ReportsPage
          identifier="vendor_due_diligence"
          defaultDateColumn="created_at"
          defaultGroupColumn="risk_level"
        />
      ),
    },
  ];

  const activeReportModules = REPORT_MODULES.filter(
    (module) => module.active === true,
  );

  // State
  const [showModuleSelection, setShowModuleSelection] = useState(false);
  const [showQASelection, setShowQASelection] = useState(false);

  useEffect(() => {
    const activeWorkspaceId = mainStore.context.activeWorkspace?.id;
    if (activeWorkspaceId) {
      mainStore.reports.setSelectedWorkspaceIDs([activeWorkspaceId]);
      mainStore.reports.updateRefetchRecordsCount();
    }
  }, [mainStore.context.activeWorkspace?.id]);

  const currentModule = useMemo(
    () =>
      activeReportModules.find(
        (activeModule) => activeModule?.identifier === moduleIdentifier,
      ),
    [activeReportModules, moduleIdentifier],
  );

  useEffect(() => {
    if (activeReportModules.length && !moduleIdentifier) {
      history.replace(
        `/workspace-reports/${activeReportModules[0].identifier}`,
      );

      return;
    }

    if (
      !currentModule &&
      activeReportModules.length > 0 &&
      activeReportModules[0].identifier
    ) {
      handleModuleChange(activeReportModules[0]);
    }
  }, [activeReportModules]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'module' implicitly has an 'any' type.
  const handleModuleChange = (module) => {
    setShowModuleSelection(false);
    history.push(`/workspace-reports/${module.identifier}`);
    mainStore.reports.setFieldName({});
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'selectedReport' implicitly has an 'any'... Remove this comment to see the full error message
  const updateCurrentQAReport = (selectedReport) => {
    setCurrentQAReport(selectedReport);
    setShowQASelection(false);
  };

  if (!currentModule || !activeReportModules) {
    return (
      <Loading fullWidth loadingLayout="small-table" showTableHeader={false} />
    );
  }

  return (
    <div className="dashboard-content">
      <div className="dashboard-header">
        <div className="dashboard-header-block">
          <div className="dashboard-header-left">
            <h3>Reports</h3>
          </div>
        </div>
      </div>
      <div className="dashboard-content-wrap">
        <div className="reports-content">
          <div className="reports-header">
            <div className="actions">
              <Popup
                position="bottom right"
                trigger={
                  <button
                    className={classNames("reports-module-dropdown", {
                      opened: showModuleSelection,
                    })}
                    data-testid="reports-module-dropdown"
                  >
                    <div className="module-item">
                      <img
                        className="icon-image"
                        src={currentModule.icon}
                        alt={`${currentModule.name}-icon`}
                      />
                      <span>
                        {" "}
                        {nameFromModuleWorkspace(
                          currentModule.identifier,
                          moduleWorkspaces,
                        )}
                      </span>
                    </div>
                    <img src={arrowDownIcon} alt="open" />
                  </button>
                }
                open={showModuleSelection}
                onOpen={() => setShowModuleSelection(true)}
                onClose={() => setShowModuleSelection(false)}
                keepTooltipInside
              >
                <ul
                  className="table-dropdown reports-dropdown-popup"
                  data-testid="workspace-reports-dropdown"
                >
                  {activeReportModules.map((module) => (
                    <li
                      key={module.name}
                      data-testid={module.name}
                      className={classNames({
                        active: currentModule.value === module.value,
                      })}
                      onClick={() => handleModuleChange(module)}
                    >
                      <div className="module-item">
                        <img
                          className="icon-image"
                          src={module.icon}
                          alt={`${module.name}-icon`}
                        />
                        <span>
                          {nameFromModuleWorkspace(
                            module.identifier,
                            moduleWorkspaces,
                          )}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </Popup>
              {currentModule.identifier === "qa_tests_development" && (
                <Popup
                  position="bottom right"
                  trigger={
                    <button
                      className={classNames("reports-module-dropdown", {
                        opened: showModuleSelection,
                      })}
                      data-testid="qa-test-module-dropdown"
                    >
                      <div className="module-item">
                        <Typography
                          label={currentQAReport}
                          size="xs"
                          weight="semiBold"
                        />
                      </div>
                      <img src={arrowDownIcon} alt="open" />
                    </button>
                  }
                  open={showQASelection}
                  onOpen={() => setShowQASelection(true)}
                  onClose={() => setShowQASelection(false)}
                  keepTooltipInside
                >
                  <ul className="table-dropdown reports-dropdown-popup">
                    <li
                      className={classNames({
                        active: currentQAReport === "Status and Detail Report",
                      })}
                      onClick={() =>
                        updateCurrentQAReport("Status and Detail Report")
                      }
                    >
                      <div className="module-item">
                        <Typography
                          label="Status and Detail Report"
                          size="xs"
                          weight="semiBold"
                        />
                      </div>
                    </li>
                    <li
                      className={classNames({
                        active: currentQAReport === "Timeline Report",
                      })}
                      onClick={() => updateCurrentQAReport("Timeline Report")}
                    >
                      <div className="module-item">
                        <Typography
                          label="Timeline Report"
                          size="xs"
                          weight="semiBold"
                        />
                      </div>
                    </li>
                  </ul>
                </Popup>
              )}
              {mainStore.context.activeWorkspace?.is_internal &&
                currentModule.identifier !== "qa_tests_development" && (
                  <SingleWorkspaceSelection
                    allowAllWorkspacesOption={
                      currentModule.identifier !== "risk_register"
                    }
                  />
                )}
              {currentModule.identifier === "risk_register" && (
                <SingleSectionSelection />
              )}
            </div>
          </div>
          {currentModule.value(currentQAReport)}
        </div>
      </div>
    </div>
  );
}

export default observer(WorkspaceReports);
