import React from "react";

import arrowDownIcon from "../../../images/dashboard/arrow-down-black.svg";

type Props = {
  sectionTitle?: string;
  testDate?: string;
  testName?: string;
};

function TestInfo({ testName, testDate, sectionTitle }: Props) {
  return (
    <div className="test-info-container">
      <div>
        <div>{testName}</div>
        <div className="test-content">
          <div className="test-date">{testDate}</div>
          <div className="divider" />
          {sectionTitle && <div className="fintech-tag">{sectionTitle}</div>}
        </div>
      </div>
      <div className="drop-down">
        <img src={arrowDownIcon} alt="arrow-down-icon" />
      </div>
    </div>
  );
}

export default TestInfo;
