import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import InteractiveDirectUpload from "../table/shared/InteractiveDirectUpload";

type Props = {
  parentRecordVersionID?: number;
  sectionId?: number | null;
  setShowPopup: (show: boolean) => void;
  subModuleDownload?: boolean;
};

function ImportFiles({
  setShowPopup,
  sectionId,
  parentRecordVersionID,
  subModuleDownload,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // vars
  const tableID = subModuleDownload
    ? mainStore.context.subModuleTableID
    : mainStore.context.tableID;
  const { user } = mainStore.users;

  // funcs
  function onSuccess(signedIds: string[]) {
    window.console.log("Successfully uploaded files");
    setShowPopup(false);
    mainStore.tables.importFiles(
      signedIds,
      tableID,
      user.id || null,
      sectionId || null,
      parentRecordVersionID,
    );
  }

  return <InteractiveDirectUpload onSuccess={onSuccess} multiple />;
}

ImportFiles.defaultProps = {
  parentRecordVersionID: -1,
  subModuleDownload: false,
};

export default observer(ImportFiles);
