import { IconButton } from "@themis/ui";
import classNames from "classnames";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useHistory, useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

type Props = {
  recordVersionId?: number;
};

function TestSamplesContextMenu({ recordVersionId }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const history = useHistory();
  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id } = useParams();
  const testRecordVersionId = Number(record_version_id);

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  // Variables
  const { canDeleteRecords } = mainStore.userPermissions;
  const { workspaceID } = mainStore.context;

  const modelName = "Sample";

  // Functions
  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function handlePopUpClose() {
    setIsDropdownOpen(true);
    setIsOpenModal(false);
    setIsDeleteConfirmationOpen(false);
  }

  async function deleteRow() {
    const { company } = mainStore.companies;
    if (company && company.id) {
      await mainStore.qa.deleteTestSample(recordVersionId);
      mainStore.toast.setText(`Test ${modelName.toLowerCase()} deleted!`);
    }
  }

  function deleteRowConfirmation() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(true);
  }

  function handleViewIssueDetailClick() {
    history.push(
      `/workspaces/${workspaceID}/modules/qa-tests/${testRecordVersionId}/samples/${recordVersionId}`,
    );
  }

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="mnt-samples-points-button-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const renderDeleteConfirmation = (
    <div>
      <h4>Delete Test {modelName}</h4>
      <p>
        Are you sure you want to delete this {modelName.toLowerCase()}? This is
        not reversible.
      </p>
      <div className="confirmation">
        <button onClick={deleteRow}>Yes</button>
        <button onClick={noButtonPressed}>No</button>
      </div>
    </div>
  );

  return (
    <div className="list-points">
      <Popup
        position="bottom right"
        trigger={renderTrigger}
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {isDropdownOpen && (
          <div className="table-dropdown policy-dropdown">
            <ul>
              <li
                data-testid="view-detail"
                onClick={handleViewIssueDetailClick}
              >
                View {modelName} Detail
              </li>
              {canDeleteRecords && (
                <>
                  <hr />
                  <li
                    data-testid="mnt-delete-trigger"
                    onClick={deleteRowConfirmation}
                  >
                    Delete
                  </li>
                </>
              )}
            </ul>
          </div>
        )}

        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
    </div>
  );
}

export default TestSamplesContextMenu;
