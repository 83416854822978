import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { getRepositionPayloadFromIndices } from "../../helpers/reordering";
import SectionDragContext from "../shared/SectionDragContext";
import QASection from "./QASection";

type Props = {
  additionalContent?: React.ReactNode;
};

function QA({ additionalContent }: Props) {
  // Import Mobx stores
  const mainStore = useMainStore();

  // Location Tracker
  const location = useLocation();

  // Variables
  const { moduleWorkspaceID, tableName, activeWorkspace } = mainStore.context;
  // @ts-expect-error TS(2339) FIXME: Property 'fields' does not exist on type 'Fields'.
  const { fields, visibleFields } = mainStore.fields;
  const recordVersions = mainStore.recordVersions.list;
  const isInternal = mainStore.context.activeWorkspace?.is_internal;
  const isCompletedTab = location.pathname.endsWith("/completed");

  // Effects
  useEffect(() => {
    if (location.pathname) {
      mainStore.files.cleanup();
      mainStore.clearbit.cleanup();
    }
  }, [location.pathname]);

  // Hooks
  const shouldDisplayNoRecordView = useMemo(
    () =>
      isInternal === false &&
      (fields?.length === 0 || recordVersions?.length === 0),
    [isInternal, fields, recordVersions],
  );

  const onSectionChange = (recordVersionId: number, sectionId: number) => {
    mainStore.qa.updateSection(recordVersionId, sectionId);
  };

  const getVersionsForStatusAndSection = (sectionId: number) =>
    recordVersions.filter((recordVersion) => {
      if (recordVersion.section_tag_id !== sectionId) {
        return false;
      }
      const statusValue = mainStore.avroSchemas.firstValueForField(
        "status",
        recordVersion.data,
      );
      const completed = statusValue === "completed";
      return isCompletedTab ? completed : !completed;
    });

  const onRecordMoved = async (
    // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionId' implicitly has an 'any... Remove this comment to see the full error message
    recordVersionId,
    // @ts-expect-error TS(7006) FIXME: Parameter 'sectionId' implicitly has an 'any' type... Remove this comment to see the full error message
    sectionId,
    // @ts-expect-error TS(7006) FIXME: Parameter 'sourceIndex' implicitly has an 'any' ty... Remove this comment to see the full error message
    sourceIndex,
    // @ts-expect-error TS(7006) FIXME: Parameter 'destinationIndex' implicitly has an 'an... Remove this comment to see the full error message
    destinationIndex,
  ) => {
    const relevantRecordVersions = getVersionsForStatusAndSection(sectionId);
    await mainStore.recordVersions.reorder(
      recordVersionId,
      getRepositionPayloadFromIndices(
        relevantRecordVersions,
        sourceIndex,
        destinationIndex,
      ),
    );
  };

  if (shouldDisplayNoRecordView) {
    return (
      <div
        className="table-list-wrap table-list-wrap-wider height-auto qa-table"
        data-testid="qa-table"
      >
        <div className="no-records-container">
          <div className="no-records-title">Review Testing</div>
          <div className="no-records-text">
            Add Testing here by sharing from the internal workspace. Shared
            Testing are visible here.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="table-list-wrap table-list-wrap-wider height-auto qa-table"
      data-testid="qa-table"
    >
      <SectionDragContext
        onSectionChange={onSectionChange}
        onRecordMoved={onRecordMoved}
      >
        {mainStore.sectionTags.orderedList.map((sectionTag) => {
          const filteredRecordVersions = recordVersions.filter(
            (recordVersion) => {
              if (recordVersion.section_tag_id !== sectionTag.id) {
                return false;
              }
              const statusValue = mainStore.avroSchemas.valueForField(
                "status",
                recordVersion.data,
              )?.[0];
              const completed = statusValue === "completed";
              return isCompletedTab ? completed : !completed;
            },
          );

          return (
            Boolean(!isCompletedTab || filteredRecordVersions.length > 0) && (
              <QASection
                key={sectionTag.id}
                isDropdownOpen
                // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
                tableName={tableName}
                fields={visibleFields}
                recordVersions={filteredRecordVersions}
                // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
                moduleWorkspaceID={moduleWorkspaceID}
                workspaceID={activeWorkspace?.id}
                sectionTag={sectionTag}
                isCompletedTab={isCompletedTab}
              />
            )
          );
        })}
        {additionalContent}
      </SectionDragContext>
    </div>
  );
}

export default observer(QA);
