import "react-circular-progressbar/dist/styles.css";

import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";

import logo from "../../images/full-logo.svg";

type Props = {
  createName?: string;
  value?: number;
};

function Header({ createName, value }: Props) {
  return (
    <div className="header">
      <a href="/">
        <img src={logo} alt="themis-logo" />
      </a>
      <div className="header-create">
        <p>Create Account</p>
        {createName && (
          <div className="header-create-block">
            <div className="progressbar">
              {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
              <CircularProgressbar value={value} strokeWidth={15} />
            </div>
            <h5>{createName}</h5>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
