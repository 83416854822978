import "./expanded-question-content.scss";

import React, { useMemo, useRef, useState } from "react";
import Popup from "reactjs-popup";

import { useAddTextareaHeightBehavior } from "@/components/detailView/helpers";
import { Icon, IconButton, MiniTag, Typography } from "@/components/Elements";
import Dropdown, {
  DropdownOption,
  DropdownOptions,
} from "@/components/Elements/Dropdown/Dropdown";
import ModalWrapper from "@/components/shared/ModalWrapper";
import {
  QUESTION_WEIGHTS,
  RISK_TYPE_OPTIONS,
  SELECTABLE_QUESTION_TYPES,
} from "@/features/risk-assessment/config";
import {
  createNewQuestion,
  markQuestionDestroyed,
} from "@/stores/helpers/RiskAssessmentHelpers";
import { generateUniqueId, trimToLength } from "@/utils/utils";

import {
  Question,
  QuestionType,
  RiskType,
  QuestionChoice as TQuestionChoice,
} from "../../../../types/questionnaire";
import CustomWeightDropdownContent from "../CustomWeightDropdownContent";
import QuestionChoice from "../question-choice/QuestionChoice";
import QuestionnaireBuilderQuestion from "../QuestionnaireBuilderQuestion";

type Props = {
  question: Question;
  parentQuestion?: Question;
  riskAreaOptions: DropdownOptions;
  riskRatingOptions: DropdownOption[];
  order: string;
  onChange: (question: Question) => void;
  depth?: number;
  isScoringEnabled: boolean;
  isWeightingEnabled: boolean;
  isNew: boolean;
  isInternal: boolean;
};

export default function ExpandedQuestionContent({
  question,
  parentQuestion,
  riskAreaOptions,
  riskRatingOptions,
  order,
  onChange,
  depth,
  isScoringEnabled,
  isWeightingEnabled,
  isNew,
  isInternal,
}: Props) {
  const nameRef = useRef<HTMLTextAreaElement | null>(null);
  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);

  const [isTriggerPopupOpen, setIsTriggerPopupOpen] = useState(false);

  const canEditOptions = question.input_type === QuestionType.RADIO;
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const isTriggerQuestion = depth > 0;
  const questionTypeInfo = SELECTABLE_QUESTION_TYPES.find(
    (questionType) => questionType.type === question.input_type,
  );
  const riskArea = riskAreaOptions.find(
    (option) =>
      typeof option !== "string" && question.risk_area_id === Number(option.id),
  );
  const riskType = RISK_TYPE_OPTIONS.find(
    (option) => question.risk_type === option.id,
  );
  const questionWeights = [...QUESTION_WEIGHTS];
  const { weight } = question;
  let customWeight = "";
  if (weight && questionWeights.findIndex((qw) => qw.id === weight) === -1) {
    questionWeights.push({ id: weight, name: `Weighting ${question.weight}X` });
    customWeight = String(weight);
  }
  const questionWeight =
    questionWeights.find((questionW) => questionW.id === weight) ||
    questionWeights[0];
  const activeChoices = useMemo(() => {
    return question.choices.filter((choice) => !choice._destroy);
  }, [question.choices]);
  const activeTriggerQuestions = useMemo(() => {
    return question.triggerQuestions.filter(
      (triggerQuestion) => !triggerQuestion._destroy,
    );
  }, [question.triggerQuestions]);

  const handleOptionTextChange = (text: string, optionId: number) => {
    onChange({
      ...question,
      choices: question.choices.map((option) => {
        if (optionId === option.id) {
          return {
            ...option,
            text,
          };
        }

        return option;
      }),
      triggerQuestions: question.triggerQuestions.map((triggerQuestion) => ({
        ...triggerQuestion,
        triggeredBy: triggerQuestion.triggeredBy.map((triggerChoice) => {
          if (triggerChoice.id === optionId) {
            return {
              id: triggerChoice.id,
              text,
            };
          }

          return triggerChoice;
        }),
      })),
    });
  };

  const handleOptionRatingChange = (rating: string, optionId: number) => {
    onChange({
      ...question,
      choices: question.choices.map((option) => {
        if (optionId === option.id) {
          return {
            ...option,
            base_score: parseInt(rating),
          };
        }

        return option;
      }),
    });
  };

  const handleClickOptionFlag = (isFlagged: boolean, optionId: number) => {
    onChange({
      ...question,
      choices: question.choices.map((option) => {
        if (optionId === option.id) {
          return {
            ...option,
            red_flag: isFlagged,
          };
        }

        return option;
      }),
    });
  };

  const handleRemoveOption = (option: TQuestionChoice) => {
    const newTriggerQuestions: Question[] = [];

    for (const triggerQuestion of question.triggerQuestions) {
      if (
        triggerQuestion.triggeredBy.some(
          (triggerChoice) => triggerChoice.id === option.id,
        )
      ) {
        if (triggerQuestion.isNew && triggerQuestion.triggeredBy.length === 1) {
          continue;
        }

        const changedTriggerQuestion: Question = {
          ...triggerQuestion,
          triggeredBy: triggerQuestion.triggeredBy.filter((triggerChoice) => {
            return triggerChoice.id !== option.id;
          }),
        };
        if (triggerQuestion.triggeredBy.length === 0) {
          changedTriggerQuestion._destroy = true;
        }

        newTriggerQuestions.push(changedTriggerQuestion);
      } else {
        newTriggerQuestions.push(triggerQuestion);
      }
    }

    const newQuestionChoices: TQuestionChoice[] = [];

    for (const choice of question.choices) {
      if (choice.id !== option.id) {
        newQuestionChoices.push(choice);
      } else if (!choice.isNew) {
        newQuestionChoices.push({
          ...choice,
          _destroy: true,
        });
      }
    }

    onChange({
      ...question,
      triggerQuestions: newTriggerQuestions,
      choices: newQuestionChoices,
    });
  };

  const handleAddOption = () => {
    onChange({
      ...question,
      choices: [
        ...question.choices,
        {
          id: generateUniqueId(),
          isNew: true,
          text: "",
          base_score: 1,
        },
      ],
    });
  };

  const handleAddTrigger = (
    triggerChoice: TQuestionChoice,
    type: QuestionType,
  ) => {
    onChange({
      ...question,
      triggerQuestions: [
        ...question.triggerQuestions,
        createNewQuestion(type, {
          triggeredBy: [
            {
              id: triggerChoice.id,
              text: triggerChoice.text,
            },
          ],
        }),
      ],
    });
  };

  const handleTriggerQuestionChange = (changedTriggerQuestion: Question) => {
    onChange({
      ...question,
      triggerQuestions: question.triggerQuestions.map((triggerQuestion) => {
        return changedTriggerQuestion.id === triggerQuestion.id
          ? changedTriggerQuestion
          : triggerQuestion;
      }),
    });
  };

  const handleRemoveTriggerQuestion = (triggerQuestionToRemove: Question) => {
    let newTriggerQuestions: Question[];

    if (triggerQuestionToRemove.isNew) {
      newTriggerQuestions = question.triggerQuestions.filter(
        (triggerQuestion) => {
          return triggerQuestion.id !== triggerQuestionToRemove.id;
        },
      );
    } else {
      newTriggerQuestions = question.triggerQuestions.map((triggerQuestion) => {
        if (triggerQuestion.id !== triggerQuestionToRemove.id) {
          return triggerQuestion;
        }

        return markQuestionDestroyed(triggerQuestionToRemove);
      });
    }

    onChange({
      ...question,
      triggerQuestions: newTriggerQuestions,
    });
  };

  const handleAddChoiceToTriggers = (choice: TQuestionChoice) => {
    onChange({
      ...question,
      triggeredBy: [
        ...question.triggeredBy,
        {
          id: choice.id,
          text: choice.text,
        },
      ],
    });
    setIsTriggerPopupOpen(false);
  };

  const handleRemoveChoiceFromTriggers = (choiceId: number) => {
    onChange({
      ...question,
      triggeredBy: question.triggeredBy.filter(
        (triggerChoice) => triggerChoice.id !== choiceId,
      ),
    });
  };

  const nonTriggerParentQuestionChoices = useMemo(() => {
    return parentQuestion?.choices.filter((choice) => {
      return !question.triggeredBy.some(
        (triggerChoice) => triggerChoice.id === choice.id,
      );
    });
  }, [parentQuestion?.choices, question.triggeredBy]);

  return (
    <div className="expanded-question-content">
      {isTriggerQuestion && (
        <div className="expanded-question-content__trigger-info">
          <Icon name="key" color="generalDarkGray" />
          <Typography label="Trigger" color="generalDarkGray" size="sm" />
          {question.triggeredBy.map((triggerChoice) => (
            <MiniTag
              key={triggerChoice.id}
              label={trimToLength(triggerChoice.text, 10)}
              theme="black"
              icon={question.triggeredBy.length > 1 ? "close" : undefined}
              iconPosition="right"
              onIconClick={() =>
                handleRemoveChoiceFromTriggers(triggerChoice.id)
              }
            />
          ))}
          {Boolean(nonTriggerParentQuestionChoices?.length) && (
            <Popup
              trigger={
                <div>
                  <IconButton
                    className="expanded-question-content__add-trigger"
                    icon="plus"
                  />
                </div>
              }
              position="bottom left"
              keepTooltipInside
              onOpen={() => setIsTriggerPopupOpen(true)}
              onClose={() => setIsTriggerPopupOpen(false)}
              open={isTriggerPopupOpen}
            >
              <ModalWrapper width={200}>
                <ul className="expanded-question-content__add-trigger-options">
                  {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
                  {nonTriggerParentQuestionChoices.map((choice) => (
                    <li
                      key={choice.id}
                      onClick={() => handleAddChoiceToTriggers(choice)}
                    >
                      {trimToLength(choice.text, 20)}
                    </li>
                  ))}
                </ul>
              </ModalWrapper>
            </Popup>
          )}
        </div>
      )}
      <textarea
        placeholder="- Question -"
        ref={nameRef}
        className="expanded-question-content__textarea"
        defaultValue={question.text}
        onBlur={() => onChange({ ...question, text: nameRef.current!.value })}
        // @ts-expect-error TS(2345) FIXME: Argument of type 'MutableRefObject<HTMLTextAreaEle... Remove this comment to see the full error message
        onInput={useAddTextareaHeightBehavior(nameRef, 36)}
      />
      <div className="expanded-question-content-description">
        <textarea
          placeholder="- Description -"
          ref={descriptionRef}
          className="expanded-question-content__textarea"
          defaultValue={question.description}
          onBlur={() =>
            onChange({
              ...question,
              description: descriptionRef.current!.value,
            })
          }
          // @ts-expect-error TS(2345) FIXME: Argument of type 'MutableRefObject<HTMLTextAreaEle... Remove this comment to see the full error message
          onInput={useAddTextareaHeightBehavior(descriptionRef, 36)}
        />
        <Icon
          className="expanded-question-content-description__icon"
          name="shredder"
        />
      </div>
      <div className="expanded-question-content__risk-categories">
        <Dropdown
          options={riskAreaOptions}
          selected={riskArea}
          onChange={(option) =>
            onChange({ ...question, risk_area_id: Number(option.id) })
          }
          placeholder="- Select Risk Area -"
          searchable
        />
        {isScoringEnabled && (
          <Dropdown
            options={RISK_TYPE_OPTIONS}
            selected={
              riskType && {
                ...riskType,
                element: (
                  <MiniTag theme={riskType.theme} label={riskType.name} />
                ),
              }
            }
            onChange={(option) =>
              onChange({ ...question, risk_type: option.id as RiskType })
            }
            placeholder="- Select Inherent / Control -"
          />
        )}
        {isWeightingEnabled && (
          <Dropdown
            options={questionWeights}
            selected={questionWeight}
            onChange={(option) =>
              onChange({ ...question, weight: parseFloat(option.id as string) })
            }
            className="weight-dropdown"
            activeClassName="open-weight-dropdown"
            optionClassName="weight-dropdown-option"
            CustomValueContent={
              <CustomWeightDropdownContent
                onCustomValueAdded={(val: string) => {
                  onChange({ ...question, weight: parseFloat(val) });
                }}
                initialValue={customWeight}
              />
            }
          />
        )}
      </div>
      <div className="expanded-question-content__options">
        {activeChoices?.map((option) => (
          <div key={option.id}>
            <QuestionChoice
              className="expanded-question-content__option"
              option={option}
              // @ts-expect-error TS(2322) FIXME: Type '"number" | "text" | "search" | "anchor" | "l... Remove this comment to see the full error message
              icon={questionTypeInfo.optionIcon}
              disabled={!canEditOptions}
              onNameChange={(name) => handleOptionTextChange(name, option.id)}
              onRatingChange={(rating) =>
                handleOptionRatingChange(rating, option.id)
              }
              onRemove={() => handleRemoveOption(option)}
              onClickFlag={() => {
                handleClickOptionFlag(!option.red_flag, option.id);
              }}
              isFlagged={option.red_flag}
              // @ts-expect-error TS(2322) FIXME: Type '((type: QuestionType) => void) | null' is no... Remove this comment to see the full error message
              onAddTriggerQuestion={
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                depth > 2
                  ? null
                  : (type: QuestionType) => handleAddTrigger(option, type)
              }
              riskRatingOptions={riskRatingOptions}
            />
          </div>
        ))}
      </div>
      {canEditOptions && (
        <div className="expanded-question-content__add-option">
          <button onClick={handleAddOption}>
            <Icon name="plus" color="brandingHighlightViolet" />
            <Typography
              label="Add Option"
              color="brandingHighlightViolet"
              size="sm"
            />
          </button>
        </div>
      )}
      {activeTriggerQuestions.map((triggerQuestion, i) => (
        <QuestionnaireBuilderQuestion
          key={triggerQuestion.id}
          parentQuestion={question}
          question={triggerQuestion}
          onChange={handleTriggerQuestionChange}
          order={`${order}${
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            depth % 2 === 0 ? String.fromCharCode(i + 65) : i + 1
          }`}
          riskAreaOptions={riskAreaOptions}
          riskRatingOptions={riskRatingOptions}
          onDelete={() => {
            handleRemoveTriggerQuestion(triggerQuestion);
          }}
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          depth={depth + 1}
          isScoringEnabled={isScoringEnabled}
          isWeightingEnabled={isWeightingEnabled}
          isNew={isNew}
          isInternal={isInternal}
        />
      ))}
    </div>
  );
}
