import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { relatedRecord } from "@/stores/types/control-types";

type Props = {
  recordVersionID: number;
  items?: relatedRecord[];
  locked?: boolean;
  pinned?: boolean;
  width?: number;
};

function RelatedRisks({
  width,
  recordVersionID,
  items,
  pinned,
  locked,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const history = useHistory();
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const mwID = moduleWorkspaces.find(
    (item) => item.themis_module.identifier === "risk_register",
  )?.id;
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const filteredItems = items.filter(
    (item) => item.module_workspace_id === mwID,
  );
  const { themisModuleIdentifier, workspaceID } = mainStore.context;
  const count = filteredItems.length;
  const liClassNames = classNames("controls-cell", {
    pinned,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "table-cell--disabled": isReadOnly,
    "pointer-events-none": locked || isReadOnly,
  });

  // Functions
  const handleCellClick = () => {
    if (themisModuleIdentifier === "issue_management") {
      return history.push(
        `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/related-risks`,
      );
    }
    history.push(
      `/workspaces/${workspaceID}/modules/risk-register/${recordVersionID}/related-risks`,
    );
  };

  return (
    <li
      className={liClassNames}
      style={{ width }}
      data-testid="rr-control-cell"
      onClick={handleCellClick}
    >
      <div className="cell-content">
        <span
          className={classNames("controls-cell-value", {
            "with-value": count > 0,
          })}
        >
          {count > 0 ? `${count} Risks added` : "- Add Related Risks -"}
        </span>
      </div>
    </li>
  );
}

RelatedRisks.defaultProps = {
  width: 200,
  items: [],
};

export default observer(RelatedRisks);
