import { IconButton, LinkIconButton } from "@themis/ui";
import React from "react";
import { PiCaretRightBold, PiProhibit, PiTrashBold } from "react-icons/pi";

import { Relatable, Taskable, taskableStatus } from "@/api";

export function AssociatedRecordRow({
  taskable,
  relatedRecord,
  onClickDelete,
}: {
  relatedRecord: Relatable | undefined;
  onClickDelete?: (id: number) => void;
  taskable?: Taskable | undefined;
}) {
  return (
    <div className="tw-border-0 tw-border-b tw-border-solid tw-border-neutral-100 tw-py-1 tw-text-neutral-500 first:tw-border-t">
      {relatedRecord ? (
        <div className="tw-flex tw-justify-between">
          <div className="tw-flex tw-items-center tw-gap-2">
            {relatedRecord.name || "No Name"}
          </div>
          <div className="tw-flex tw-gap-1">
            {taskable && (
              <LinkIconButton
                color="transparent"
                Icon={PiCaretRightBold}
                to={relatedRecord.path}
              />
            )}
            {taskable && onClickDelete && (
              <IconButton
                color="transparent"
                Icon={PiTrashBold}
                onClick={() => onClickDelete(taskable.id)}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="tw-flex tw-items-center tw-gap-1 tw-text-neutral-300">
          <PiProhibit />
          {taskable?.status === taskableStatus.deleted
            ? "Record has been deleted"
            : "You do not have access to this record"}
        </div>
      )}
    </div>
  );
}
