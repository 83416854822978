import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import downloadIcon from "../../images/table-image/icon/download-white.svg";
import exportIcon from "../../images/table-image/icon/export-black.svg";
import exportActiveIcon from "../../images/table-image/icon/export-white.svg";

type Props = {
  isRecordView: boolean;
  moduleWorkspaceID?: number;
  recordID?: number;
};

function ExportActivityEvents({
  recordID,
  moduleWorkspaceID,
  isRecordView,
}: Props) {
  const mainStore = useMainStore();
  const [showPopup, setShowPopup] = useState(false);

  async function processExcelDownload() {
    let spreadSheetData;
    if (isRecordView && recordID) {
      spreadSheetData = await mainStore.records.exportActivityEvents(recordID);
    } else if (moduleWorkspaceID) {
      spreadSheetData =
        await mainStore.moduleWorkspaces.exportActivityEvents(
          moduleWorkspaceID,
        );
    } else {
      mainStore.toast.setErrorText(
        "An error occurred. Could not export activities.",
      );
    }

    if (spreadSheetData) {
      const link = document.createElement("a");
      const blob = new Blob([new Uint8Array(spreadSheetData)], {
        type: "application/octet-stream",
      });
      link.href = URL.createObjectURL(blob);
      link.download = "audit_trail.xlsx";
      link.click();
    }
    setShowPopup(false);
  }

  const renderButtonTrigger = (
    <button
      className="import-button"
      style={{
        backgroundColor: !showPopup ? "#E3E3FD" : "#353549",
        color: !showPopup ? "#353549" : "white",
      }}
      data-testid="export-button"
    >
      <img src={!showPopup ? exportIcon : exportActiveIcon} alt="export-icon" />
      Export
    </button>
  );

  return (
    <Popup
      position="bottom right"
      trigger={renderButtonTrigger}
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
      keepTooltipInside
      nested
    >
      <div className="table-dropdown export-table-dropdown">
        <h3>Export Audit Trail to Excel</h3>
        <p>Click the button below to export the activities to an Excel file.</p>
        <button className="export-table-button" onClick={processExcelDownload}>
          <img src={downloadIcon} alt="upload-icon" />
          Export to XLSX File
        </button>
      </div>
    </Popup>
  );
}

export default observer(ExportActivityEvents);
