import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { camelCase, capitalize, isEmpty, startCase, uniq } from "lodash";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import Popup from "reactjs-popup";

import { Field, RecordsByMonthByFieldOptionQueryParams } from "@/api";
import { recordsByMonthByFieldOption } from "@/api/gen/axios/reportsController";
import { useMainStore } from "@/contexts/Store";
import useDidMountEffect from "@/hooks/useDidMount";
import { titleForColor } from "@/stores/helpers/TitleForColorSchemeHelpers";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";
import { FieldOption } from "@/stores/types/field-types";

import { graphColors, graphOptions } from "../../constants";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import ClearFilterField from "../common/ClearFilterField";
import { naColor } from "../common/MetricsPage/constants";
import ExportDashboard from "../common/MetricsPage/dashboards/components/ExportDashboard";
import { mapHeader, moveNAPositionToLast } from "../common/MetricsPage/helpers";
import { Dashboards } from "../common/MetricsPage/types";
import SingleSelectField from "../common/SingleSelectField";
import { DateFilter } from "../dateFilter";
import { legendHeightPlugin } from "./chartHelpers";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

type Props = {
  dateColumnDefault?: string;
  groupColumnDefault?: string;
  identifier?: string;
  tableName?: string;
  yAxisTitle?: string;
  showSelectFields?: boolean;
  showDownload?: boolean;
  showDatePicker?: boolean;
  labelColors?: string[];
  labelPosition?: string;
  maintainAspectRatio?: boolean;
  startDate?: Date;
  endDate?: Date;
  showYAxisLine?: boolean;
  shouldSortData?: boolean;
  dashboard?: Dashboards;
  labels?: string[];
  xAxisLabels?: string[];
  chartData?: Record<string, Record<string, number>>;
  chartTitle?: string;
};

const RecordsByMonthStackedBar = ({
  identifier,
  dateColumnDefault,
  groupColumnDefault,
  tableName,
  yAxisTitle,
  showSelectFields = true,
  showDatePicker = true,
  labelPosition,
  maintainAspectRatio = true,
  startDate,
  endDate,
  showYAxisLine = true,
  shouldSortData,
  dashboard,
  labels,
  xAxisLabels,
  chartData,
  chartTitle,
}: Props) => {
  const mainStore = useMainStore();
  const mwList = mainStore.moduleWorkspaces.list;

  const recordTypePlural = yAxisTitle
    ? yAxisTitle
    : // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      getRecordName(identifier, mwList, false);

  // Fields State
  const [groupByColumnName, setGroupByColumnName] =
    useState(groupColumnDefault);
  const [dateColumnName, setDateColumnName] = useState(dateColumnDefault);
  const [fieldName, setFieldName] = useState<Field>();
  const [fieldValue, setFieldValue] = useState<FieldOption>();
  const [showDatepicker, setShowDatepicker] = useState(false);
  const { allFields } = mainStore.fields;

  const dateFields = useMemo(
    () =>
      allFields?.filter(
        // @ts-expect-error TS(2339) FIXME: Property 'data_type' does not exist on type 'never... Remove this comment to see the full error message
        (field) => field.data_type === "com.askthemis.types.v1.date",
      ),
    [allFields],
  );
  const groupableFields = mainStore.fields.allGroupableFields;
  const { optionFields } = mainStore.fields;
  const allFieldOptions = useMemo(
    // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
    () => mainStore.reports.allCurrentFieldOptions?.[fieldName?.name] || [],
    [mainStore.reports.allCurrentFieldOptions, fieldName],
  );
  const fieldColor = fieldName?.display_name
    ? "generalMidnightDark"
    : "generalDarkGray";
  const fieldDisplayName = fieldName?.display_name
    ? fieldName?.display_name
    : "- Select -";
  const isDisabled = fieldName?.display_name === undefined;
  const fieldValueColor = fieldValue?.display_name
    ? "generalMidnightDark"
    : "generalDarkGray";
  const fieldValueDisplayName = fieldValue?.display_name
    ? fieldValue?.display_name
    : "All";
  const isFilterClearable = !isDisabled;
  const { colorSchemes, selectedWorkspaceIDs } = mainStore.reports;
  const isInCreatedDateDashboard = dashboard === Dashboards.CREATED_DATE;

  // useEffect
  useEffect(() => {
    getRecords();
  }, [
    dateColumnName,
    groupByColumnName,
    selectedWorkspaceIDs,
    fieldValue?.name,
    mainStore.reports.refetchRecordsCount,
    startDate,
    mainStore.reports.imSourceGroupName,
  ]);

  useEffect(() => {
    if (!fieldName) {
      return;
    }
    if (
      mainStore.featureFlags.getIsEnabled(
        FEATURE_FLAG_ID.GENG_TRAINING_MODULE_REVAMP,
      ) &&
      identifier === "training"
    ) {
      return;
    }

    mainStore.reports.getAllFieldOptions({
      workspace_ids: selectedWorkspaceIDs,
      identifier,
    });
  }, [fieldName, selectedWorkspaceIDs]);

  useDidMountEffect(() => {
    setGroupByColumnName(mainStore.reports.imSourceGroupName);
  }, [mainStore.reports.imSourceGroupName]);

  const {
    handleDisplayRange,
    validRange,
    fromValue,
    toValue,
    dayPickerTrigger,
    renderDayPicker,
  } = DateFilter();

  const newOptions = {
    responsive: true,
    maintainAspectRatio,
    plugins: {
      legend: {
        display: true,
        position: labelPosition || "bottom",
        padding: 40,
        title: {
          text: "",
          display: true,
          padding: 5,
        },
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: true,
          borderDash: [6],
          drawBorder: showYAxisLine,
        },
        title: {
          text: recordTypePlural,
          display: true,
        },
        ticks: {
          precision: 0,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  async function getRecords() {
    if (
      !groupByColumnName ||
      !dateColumnName ||
      isEmpty(selectedWorkspaceIDs) ||
      isInCreatedDateDashboard
    ) {
      return;
    }
    if (
      mainStore.featureFlags.getIsEnabled(
        FEATURE_FLAG_ID.GENG_TRAINING_MODULE_REVAMP,
      ) &&
      identifier === "training"
    ) {
      const params: RecordsByMonthByFieldOptionQueryParams = {
        date_field_name: dateColumnName,
        workspace_ids: mainStore.reports.selectedWorkspaceIDs as number[],
        from_date: fromValue,
        to_date: toValue,
        field_name: groupByColumnName,
        filters:
          fieldName && fieldValue
            ? { [fieldName.name]: { any: fieldValue.name } }
            : {},
      };
      const response = await recordsByMonthByFieldOption(
        mainStore.context.companyID!,
        "trainings",
        params,
      );
      mainStore.reports.setRecordsByMonthStackedBar(response.data);
    } else {
      mainStore.reports.getRecordsByMonthByColumnByOtherColumn({
        workspace_ids: selectedWorkspaceIDs,
        date_column_name: dateColumnName,
        identifier,
        group_column_name: groupByColumnName,
        from_value: startDate || fromValue,
        to_value: endDate || toValue,
        table_name: tableName,
        field_name: fieldName?.name,
        field_values: fieldValue?.name,
      });
    }
  }
  const data = mainStore.reports.recordsByMonthStackedBar;

  // @ts-expect-error TS(2339) FIXME: Property 'month' does not exist on type 'never'.
  const X_AXIS_LABELS = xAxisLabels || uniq(data?.map((item) => item.month));
  const dataLabels =
    labels ||
    uniq(
      data
        // @ts-expect-error TS(2339) FIXME: Property 'count' does not exist on type 'never'.
        .filter((item) => item.count !== 0)
        // @ts-expect-error TS(2339) FIXME: Property 'source' does not exist on type 'never'.
        .map((item) => item.source?.toString()),
    );

  const labelData = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
    (label) => {
      if (!isInCreatedDateDashboard) {
        return null;
      }

      // @ts-expect-error TS(7034) FIXME: Variable 'result' implicitly has type 'any[]' in s... Remove this comment to see the full error message
      const result = [];

      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      if (chartData[label]) {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        Object.keys(chartData[label]).forEach((month) => {
          result.push({
            month,
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            count: chartData[label][month],
            source: label,
          });
        });
      }
      // @ts-expect-error TS(7005) FIXME: Variable 'result' implicitly has an 'any[]' type.
      return result;
    },
    [chartData],
  );

  const sortedDataLabels = useMemo(
    () =>
      shouldSortData
        ? moveNAPositionToLast({ labels: dataLabels, data }).labels
        : dataLabels.sort(),
    [dataLabels, chartData],
  );

  const insertData = useMemo(() => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
    const renderDataSets = sortedDataLabels.map((label, index) => {
      const backgroundColor =
        label && label === "N/A"
          ? naColor
          : colorSchemes?.find(
              (colorScheme) =>
                titleForColor(colorScheme.title) === titleForColor(label),
            )?.text_color || graphColors[index];

      return {
        label: isInCreatedDateDashboard
          ? mapHeader(
              mainStore.fieldOptions.all,
              mainStore.reports.imSourceGroupName,
              label,
            )
          : startCase(camelCase(label)) || capitalize(label) || "n/a",
        data:
          labelData(label) ||
          // @ts-expect-error TS(2339) FIXME: Property 'source' does not exist on type 'never'.
          data.filter((item) => item.source?.toString() === label),
        stack: "source",
        borderRadius: 0,
        maxBarThickness: 150,
        backgroundColor,
        borderSkipped: false,
      };
    });

    return {
      options: {
        ...graphOptions,
        ...newOptions,
      },
      data: {
        datasets: renderDataSets,
        labels: X_AXIS_LABELS,
      },
    };
  }, [sortedDataLabels, labelData, data, colorSchemes]);

  const renderChart = (
    <Bar
      data={insertData.data}
      plugins={[legendHeightPlugin]}
      // @ts-expect-error no proper typing was used when building the options object
      options={insertData.options}
      id="stackedBarChart"
      key="type"
    />
  );

  function handleDatepickerClose() {
    setShowDatepicker(false);
    handleDisplayRange();
    if (validRange) {
      getRecords();
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'column' implicitly has an 'any' type.
  const handleDateColumnChange = (column) => {
    setDateColumnName(column.name);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'column' implicitly has an 'any' type.
  const handleGroupByChange = (column) => {
    setGroupByColumnName(column.name);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const handleFilterByChange = (field) => {
    setFieldName(field);
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    setFieldValue();
  };

  const clearFieldValue = () => {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    setFieldValue();
  };

  const resetFilter = () => {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    setFieldName();
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    setFieldValue();
  };
  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldOption' implicitly has an 'any' ty... Remove this comment to see the full error message
  const handleFieldOptionsChange = (fieldOption) => {
    setFieldValue(fieldOption);
  };

  return (
    <div className="report-dashboard-contents">
      <div className="report-dashboard-header-multiple-row">
        <div className="row graph-title-container title-container">
          <div className="graph-title">
            {chartTitle || (recordTypePlural && `${recordTypePlural} by month`)}
          </div>
          <div className="report-dashboard-header-right">
            <div className="date-picker">
              {showDatePicker && (
                <Popup
                  position="bottom right"
                  trigger={dayPickerTrigger}
                  open={showDatepicker}
                  onOpen={() => setShowDatepicker(true)}
                  onClose={() => handleDatepickerClose()}
                  keepTooltipInside
                >
                  {renderDayPicker}
                </Popup>
              )}
            </div>
            <ExportDashboard
              chartId="report-bar-by-month-stacked-bar-chart-container"
              fileDownloadName={`${recordTypePlural}_by_month_${dateColumnName}_${groupByColumnName}_${fieldValueDisplayName}`}
              forTables={false}
            />
          </div>
        </div>
        {showSelectFields && (
          <div className="selects-container">
            <SingleSelectField
              label="Date Column"
              value={startCase(dateColumnName)}
              options={dateFields}
              handleChange={handleDateColumnChange}
              testId="group-select-date-field"
              tooltip="Select a date column from any of the selected workspaces. If the record does does not have a value for this column, or the workspace does not have this column, the record will not be included in the report."
            />
            <SingleSelectField
              label="Group By"
              value={startCase(groupByColumnName)}
              color={fieldColor}
              options={groupableFields}
              isDisabled={false}
              handleChange={handleGroupByChange}
              testId="filter-select-column-name"
              tooltip="Choose a column to group the records by. Each value will be be shown as a different color in the chart."
            />
            <SingleSelectField
              label="Filter By"
              value={fieldDisplayName}
              color={fieldColor}
              options={optionFields}
              isDisabled={false}
              handleChange={handleFilterByChange}
              testId="filter-select-column-name"
              tooltip="To pull records only matching a certain value, first select the a column from any of the selected workspaces."
            />
            {isFilterClearable && (
              <ClearFilterField resetFilter={resetFilter} />
            )}
            <SingleSelectField
              label="Value"
              value={
                isDisabled ? "Select Filter By first" : fieldValueDisplayName
              }
              color={fieldValueColor}
              options={allFieldOptions}
              isDisabled={isDisabled}
              handleChange={handleFieldOptionsChange}
              // @ts-expect-error TS(2322) FIXME: Type '{ label: string; value: string; color: strin... Remove this comment to see the full error message
              clearSelection={clearFieldValue}
              testId="filter-select-value"
              tooltip="Now select a value to filter by. Only records with this value in the specified column will be included in the report. Any records in workspaces without this column, or this column option, will not be shown."
            />
          </div>
        )}
      </div>

      <div
        className="report-bar-chart-container"
        id="report-bar-by-month-stacked-bar-chart-container"
      >
        {renderChart}
        <div />
      </div>
    </div>
  );
};

export default observer(RecordsByMonthStackedBar);
