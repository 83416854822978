import classNames from "classnames";
import React from "react";

type Props = {
  label: string;
  value: string;
  isReadOnly?: boolean;
};

function VendorInputField({ label, value, isReadOnly }: Props) {
  return (
    <div className="input-block">
      <label>{label}</label>
      <input
        type="text"
        value={value}
        readOnly
        className={classNames("vendor-view-disabled", {
          "table-cell--disabled": isReadOnly,
        })}
      />
    </div>
  );
}

export default VendorInputField;
