import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import FileSelect from "../cell-type/file-select/FileSelect";

type Props = {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  recordVersionID: number;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exposeData?: (...args: any[]) => any;
  forceShowUpload?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue?: any[];
  isMultiple?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange?: (...args: any[]) => any;
  trigger?: React.ReactNode;
  useButton?: boolean;
};

function ViewFileSelect({
  initialValue,
  recordVersion,
  title,
  fieldName,
  recordVersionID,
  isMultiple,
  useButton,
  trigger,
  forceShowUpload,
  exposeData,
  onDataChange,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const files = recordVersion?.files || [];
  const processedFiles = recordVersion?.processed_files || [];
  const filesWithIDs = recordVersion?.files_with_ids || [];
  const attachmentGroups =
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    initialValue?.length > 0
      ? initialValue
      : recordVersion?.attachment_groups?.filter(
          // @ts-expect-error TS(7006) FIXME: Parameter 'attachmentGroup' implicitly has an 'any... Remove this comment to see the full error message
          (attachmentGroup) => attachmentGroup.field_name === fieldName,
        );
  const cellError = mainStore.recordVersions?.errorsForField(
    recordVersion?.id,
    fieldName,
  );
  const hasErrors = Boolean(cellError);

  const fileSelectButton = (
    <button
      className={classNames("upload-document", { multiple: isMultiple })}
      disabled={!isMultiple && recordVersion?.attachment_groups?.length > 0}
    >
      Upload Documents
    </button>
  );

  return (
    <FileSelect
      fieldName={fieldName}
      recordVersionID={recordVersionID}
      width="100%"
      // @ts-expect-error TS(2322) FIXME: Type '{ fieldName: string; recordVersionID: number... Remove this comment to see the full error message
      files={files}
      filesWithIds={filesWithIDs}
      attachmentGroups={attachmentGroups}
      processedFiles={processedFiles}
      hasErrors={hasErrors}
      trigger={trigger ? trigger : useButton && fileSelectButton}
      forceShowUpload={forceShowUpload}
      hasErrorClass="detail-view-has-errors"
      exposeData={exposeData}
      title={title}
      onDataChange={onDataChange}
      isMultiple={isMultiple}
    />
  );
}

ViewFileSelect.defaultProps = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  exposeData: (val) => val,
  initialValue: [],
};

export default observer(ViewFileSelect);
