import { isEmpty } from "lodash";

import { ZendeskConfigResponseData } from "@/api/legacy/zendesk-config/types";

import { mappingRules } from "./constants";

export function parseZendeskHashParams() {
  const hashParams = new URLSearchParams(window.location.hash.substring(1));
  const access_token = hashParams.get("access_token");
  const { workspace_id, module_identifier, subdomain } = JSON.parse(
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    hashParams.get("state"),
  ) as { workspace_id: string; module_identifier: string; subdomain: string };

  return { access_token, workspace_id, module_identifier, subdomain };
}

export function parseZendeskSearchParams() {
  const searchParams = new URLSearchParams(window.location.search.substring(1));
  const configuration_id = searchParams.get("configuration_id");

  return { configuration_id };
}

export function getAllZendeskFields(
  zendeskConfigData: ZendeskConfigResponseData,
) {
  return [...zendeskConfigData.zendesk_configuration.fields];
}

export function getZendeskFieldName(
  zendeskId: number,
  zendeskConfigData: ZendeskConfigResponseData,
) {
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  return getAllZendeskFields(zendeskConfigData).find(
    (zendeskField) => zendeskField.id === zendeskId,
  ).title;
}

export function getThemisColumnOptions(
  zendeskId: number,
  zendeskConfigData: ZendeskConfigResponseData,
) {
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const zendeskFieldType = getAllZendeskFields(zendeskConfigData).find(
    (zendeskField) => zendeskField.id === zendeskId,
  ).type;

  return zendeskConfigData.table_fields
    .filter(
      (themisTableField) =>
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        mappingRules[zendeskFieldType]?.includes(themisTableField.data_type) &&
        /**
         * If zendesk field type is `multiselect` then only themis table fields
         * with `is_multiselect` set to `true` are allowed to be mapped
         */
        (zendeskFieldType !== "multiSelect" || themisTableField.is_multiselect),
    )
    .map((themisTableField) => ({
      value: themisTableField.name,
      label: themisTableField.display_name,
    }));
}

export function getMappedFields(zendeskConfigData: ZendeskConfigResponseData) {
  const mappedFields = [
    ...zendeskConfigData.zendesk_configuration.fields.map(({ id }) => ({
      zendesk_id: id,
      checked: false,
      internal_name: "",
    })),
  ];

  const currentlyMappedFields =
    zendeskConfigData.zendesk_configuration.mapped_fields;

  if (!isEmpty(currentlyMappedFields)) {
    currentlyMappedFields.forEach((currentlyMappedField) => {
      const replaceIndex = mappedFields.findIndex(
        (mappedField) =>
          mappedField.zendesk_id === currentlyMappedField.zendesk_id,
      );

      mappedFields[replaceIndex] = { ...currentlyMappedField, checked: true };
    });
  }

  return mappedFields;
}
