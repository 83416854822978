import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { CreateFollowupQuestionMutationRequest } from "@/api/gen/models/CreateFollowupQuestion";

import { GetFollowupQuestionsQueryParams } from "../gen";
import {
  createFollowupQuestion,
  getFollowupQuestions,
} from "../gen/axios/followupQuestionController";

export const FOLLOWUP_QUESTION_QUERY_KEY = "followup_question_query";

export function useFollowupQuestions(params: GetFollowupQuestionsQueryParams) {
  return useQuery({
    queryKey: [FOLLOWUP_QUESTION_QUERY_KEY, params.question_id],
    queryFn: () => getFollowupQuestions(params),
    enabled: !!params,
  });
}

export function useCreateFollowupQuestion({
  questionId,
  onSuccess = () => {},
  onError = () => {},
}: {
  questionId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateFollowupQuestionMutationRequest) =>
      createFollowupQuestion(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [FOLLOWUP_QUESTION_QUERY_KEY, questionId],
      });
      onSuccess();
    },
    onError,
  });
}
