import { PreviewTableColumn } from "@themis/ui";
import { DataWithIdAndCustomFields } from "packages/ui/src/components/PreviewTable/types";
import { IconType } from "react-icons";
import {
  PiCalendarBlankBold,
  PiListBulletsBold,
  PiPaperclipBold,
  PiTextAlignLeftBold,
  PiUserBold,
} from "react-icons/pi";

import {
  FieldConfiguration,
  FieldConfigurationFieldType,
  FieldConfigurationReferenceType,
} from "@/api";

const FIELD_REFERENCE_TYPE_TO_ICON_MAP: Partial<
  Record<
    FieldConfigurationFieldType | FieldConfigurationReferenceType,
    IconType
  >
> = {
  text: PiTextAlignLeftBold,
  textarea: PiTextAlignLeftBold,
  select: PiListBulletsBold,
  date: PiCalendarBlankBold,
  datetime: PiCalendarBlankBold,
  User: PiUserBold,
  Attachment: PiPaperclipBold,
};

export function fieldConfigToColumnsMap<T extends DataWithIdAndCustomFields>(
  fieldConfigs: FieldConfiguration[],
): PreviewTableColumn<T>[] {
  return fieldConfigs.map((fieldConfig) => {
    return {
      isCustomColumn: fieldConfig.custom,
      key: fieldConfig.name as keyof T,
      title: fieldConfig.display_name,
      type: "locked",
      width: fieldConfig.width || 200,
      Icon: FIELD_REFERENCE_TYPE_TO_ICON_MAP[
        fieldConfig.reference_type || fieldConfig.field_type
      ],
    };
  });
}
