import classNames from "classnames";
import React, { useMemo } from "react";

import attachmentIcon from "../../../images/table-image/icon/attachment.svg";
import bookIcon from "../../../images/table-image/icon/book.svg";
import calendarIcon from "../../../images/table-image/icon/calendar-icon.svg";
import contactIcon from "../../../images/table-image/icon/contact.svg";
import downArrowIcon from "../../../images/table-image/icon/down_arrow.svg";
import listBulletIcon from "../../../images/table-image/icon/list-bullet.svg";
import numberIcon from "../../../images/table-image/icon/number.svg";
import tagIcon from "../../../images/table-image/icon/tag.svg";
import textIcon from "../../../images/table-image/icon/text-icon.svg";
import upArrowIcon from "../../../images/table-image/icon/up_arrow.svg";

type Props = {
  fieldName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
  sortable?: boolean;
  sortDirection?: string;
  title?: string;
  width?: number | string;
  minWidth?: number | string;
};

function ListTitleSettings({
  title,
  fieldName,
  width,
  minWidth,
  sortable = false,
  sortDirection,
  onClick,
}: Props) {
  const src = useMemo(() => {
    if (
      ["roles", "role", "type", "enabled", "notification_type"].includes(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        fieldName,
      )
    ) {
      return listBulletIcon;
    }

    if (
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      ["module_config", "indicator"].includes(fieldName)
    ) {
      return "";
    }

    if (
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      ["status", "internal", "module_admin", "workspace"].includes(fieldName)
    ) {
      return bookIcon;
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (["initial_attestation_date", "created_at"].includes(fieldName)) {
      return calendarIcon;
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (["record_version", "attestation_completion"].includes(fieldName)) {
      return numberIcon;
    }

    if (
      [
        "department",
        "username",
        "attestation_status",
        "module_name",
        "tags",
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      ].includes(fieldName)
    ) {
      return tagIcon;
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (["module_admins", "module_users"].includes(fieldName)) {
      return contactIcon;
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (["document", "attachment"].includes(fieldName)) {
      return attachmentIcon;
    }

    return textIcon;
  }, [fieldName]);

  return (
    <li
      style={{ width, minWidth }}
      className={classNames("title-trigger-block", "tw-select-none", {
        sorted: sortDirection,
        "tw-cursor-pointer": sortable,
      })}
      onClick={onClick}
    >
      {src && <img src={src} alt="icon" />}

      {title}

      {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
      {["ASC", "DESC"].includes(sortDirection) && (
        <img
          style={{ marginLeft: "auto" }}
          src={sortDirection === "ASC" ? downArrowIcon : upArrowIcon}
          alt="text-blue-icon"
        />
      )}
    </li>
  );
}

ListTitleSettings.defaultProps = {
  title: "",
  fieldName: "",
  width: 200,
  onClick: () => {},
};

export default ListTitleSettings;
