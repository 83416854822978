import { observer } from "mobx-react";
import React, { useMemo } from "react";

import { useMainStore } from "@/contexts/Store";

import InputCell from "../cell-type/InputCell";

type Props = {
  dataTestId?: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exposeData?: (...args: any[]) => any;
  fieldName?: string;
  initialValue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion?: any;
  recordVersionID?: number;
  type?: string;
  hasReadWriteAccess?: boolean;
};

function ViewInputCell({
  recordVersion,
  fieldName,
  recordVersionID,
  type,
  disabled,
  dataTestId,
  initialValue,
  exposeData,
  onDataChange,
  hasReadWriteAccess,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const value = useMemo(() => {
    return mainStore.avroSchemas.valueForField(fieldName, recordVersion?.data);
  }, [fieldName, recordVersion?.data]);

  const cellError = useMemo(() => {
    return mainStore.recordVersions.errorsForField(
      recordVersion?.id,
      fieldName,
    );
  }, [
    recordVersion?.id,
    fieldName,
    mainStore.recordVersions.cellsErrors,
    mainStore.recordVersions.newRecordVersionErrors,
  ]);

  const hasErrors = Boolean(cellError);

  return (
    <InputCell
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      fieldName={fieldName}
      recordVersionID={recordVersionID}
      width="100%"
      initialValue={initialValue || value}
      disabled={disabled}
      hasErrors={hasErrors}
      errorMessage={cellError?.description}
      type={type}
      dataTestId={dataTestId}
      hasErrorClass="detail-view-has-errors"
      exposeData={exposeData}
      onDataChange={onDataChange}
      hasReadWriteAccess={hasReadWriteAccess}
    />
  );
}

ViewInputCell.defaultProps = {
  dataTestId: "input-cell",
  disabled: false,
  hasReadWriteAccess: false,
  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  exposeData: (val) => val,
};

export default observer(ViewInputCell);
