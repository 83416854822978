import React from "react";
import { PiPlusBold } from "react-icons/pi";

type AddNewSectionButtonProps = {
  onClick?: () => void;
  title?: string;
};

const AddNewSectionButton = (props: AddNewSectionButtonProps) => (
  <div
    className="tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-2"
    onClick={props.onClick}
    data-testid="add-new-section-button"
  >
    <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
    <div className="tw-neutral-300 tw-text-base">
      {props.title ? props.title : "Add New Section"}
    </div>
  </div>
);

export default AddNewSectionButton;
