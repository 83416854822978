import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

type Props = {
  currentURL?: string;
  isVisible?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFileLoading?: (...args: any[]) => any;
};

function CreativeViewFigmaIframe({
  isVisible,
  currentURL,
  setFileLoading,
}: Props) {
  // state
  const [mainFileURL, setMainFileURL] = useState(null);
  const [loaded, setLoaded] = useState(false);

  // effects
  useEffect(() => {
    if (mainFileURL || !currentURL) {
      return;
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setMainFileURL(currentURL);
  }, [currentURL]);

  useEffect(() => {
    if (isVisible && loaded) {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setFileLoading(false);
    }
  }, [isVisible, loaded]);

  return (
    <div
      className={classNames("creative-file-container", { hidden: !isVisible })}
      data-testid="creative-figma-file-container"
    >
      <div className="creative-draggable-bounds">
        {mainFileURL && (
          <iframe
            onLoad={() => setLoaded(true)}
            height="100%"
            width="100%"
            src={`https://www.figma.com/embed?embed_host=astra&url=${mainFileURL}`}
            allowFullScreen
            data-testid="creative-figma-file-iframe"
          />
        )}
      </div>
    </div>
  );
}

CreativeViewFigmaIframe.defaultProps = {
  isVisible: false,
  setFileLoading: () => {},
};

export default observer(CreativeViewFigmaIframe);
