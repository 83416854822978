import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import { useHistory } from "react-router-dom";

import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";

import deleteIcon from "../../../images/table-image/icon/close-icon2.svg";
import { Icon } from "../../Elements";
import LibraryTable from "./LibraryTable";

type Props = {
  moduleWorkspaceID: number;
  libraryType: string;
};

function Library({ moduleWorkspaceID, libraryType }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  // State
  const [search, setSearch] = useState("");
  const [currentFramework, setCurrentFramework] = useState(undefined);
  // Variables
  const history = useHistory();
  const { canDeleteRecords } = mainStore.userPermissions;
  const { activeWorkspace, workspaceID } = mainStore.context;
  const isInternalWorkspace = activeWorkspace?.is_internal;
  const moduleWorkspace = mainStore.moduleWorkspaces.list.find(
    (item) => item.id === moduleWorkspaceID,
  );
  const libraryIsEnabled =
    isInternalWorkspace || moduleWorkspace?.library_enabled;
  const { libraryRecords: rows, data } = mainStore.riskRegisters;
  const tagTitles = mainStore.tags.tags.map((tag) => tag.title);

  const filteredRows = rows.filter((item) => {
    const includesSearch =
      item.risk_type?.title.toLowerCase().includes(search.toLowerCase()) ||
      item.main_category?.title.toLowerCase().includes(search.toLowerCase()) ||
      item.risk_events?.toLowerCase().includes(search.toLowerCase()) ||
      item.description?.toLowerCase().includes(search.toLowerCase()) ||
      item.frameworks?.some((framework) =>
        framework.title.toLowerCase().includes(search.toLowerCase()),
      );

    const includesFramework =
      !currentFramework ||
      item.frameworks?.some(
        (framework) => framework.title === currentFramework,
      );

    return includesSearch && includesFramework;
  });
  // Effects
  useEffect(() => {
    if (!moduleWorkspaceID) {
      return;
    }

    mainStore.riskRegisters.setData({
      ...data,
      module_workspace_id: moduleWorkspaceID,
      record_versions: mainStore.recordVersions.list,
    });
    mainStore.riskRegisters.fetchLibrary(moduleWorkspaceID, libraryType);
  }, [moduleWorkspaceID]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'frameworkObject' implicitly has an 'any... Remove this comment to see the full error message
  const handleFrameworkDropdownClick = (frameworkObject) => {
    setCurrentFramework(frameworkObject.value);
  };

  const clearFilter = () => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setCurrentFramework(null);
  };
  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'rowIds' implicitly has an 'any' type.
  function handleMainCheckboxChange(rowIds, checked) {
    mainStore.riskRegisters.setLibraryRecords(
      rows.map((item) => {
        if (!rowIds.includes(item.id)) {
          return item;
        }
        return { ...item, checked };
      }),
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function handleRegularCheckboxChange(id, checked) {
    mainStore.riskRegisters.setLibraryRecords(
      rows.map((item) => (item.id === id ? { ...item, checked } : item)),
    );
  }

  async function createNewRiskRegister() {
    history.push(
      `/workspaces/${workspaceID}/modules/risk-register/templates/new`,
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function handleDelete(id) {
    mainStore.riskRegisters.deleteLibraryItem(moduleWorkspaceID, id);
  }

  // elements
  const renderSearch = (
    <div className="rr-library-tools">
      <div
        className="controls-search-container rr-library-search-container"
        data-testid="rr-library-search-container"
      >
        <input
          type="text"
          placeholder="Search Risk Event"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          data-testid="rr-library-search-input"
        />
        <Icon name="search" color="generalDark" size="de" />
      </div>
    </div>
  );

  // Filter grouping
  const groupByOptions = {
    Frameworks: {
      name: "Frameworks",
      value: "frameworks",
    },
    L1: {
      name: "L1 Risk Taxonomy",
      value: "risk_type",
    },
    L2: {
      name: "L2 Risk Taxonomy",
      value: "main_category",
    },
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const handleDropdownClick = (e) => {
    switch (e.value) {
      case "L1 Risk Taxonomy":
        setCurrGroupByOption(groupByOptions.L1);
        break;

      case "L2 Risk Taxonomy":
        setCurrGroupByOption(groupByOptions.L2);
        break;

      case "Frameworks":
        setCurrGroupByOption(groupByOptions.Frameworks);
        break;

      default:
        setCurrGroupByOption(groupByOptions.Frameworks);
    }
  };

  const [currGroupByOption, setCurrGroupByOption] = useState(
    groupByOptions.Frameworks,
  );
  const allGroupByNames = Object.values(groupByOptions).map(
    (option) => option.name,
  );

  const groupedRows = groupByFrameworkOrOthers(
    filteredRows,
    currGroupByOption.value,
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'inputRows' implicitly has an 'any' type... Remove this comment to see the full error message
  function groupByFrameworkOrOthers(inputRows, key) {
    const groups = {};

    for (const row of inputRows) {
      if (key === "frameworks") {
        if (row?.frameworks && row.frameworks.length > 0) {
          for (const framework of row.frameworks) {
            const title = framework.title || "~No Framework~";
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            if (!groups[title]) {
              // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              groups[title] = [];
            }
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            groups[title].push(row);
          }
        } else {
          const noFrameworkGroup = "~No Framework~";
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          if (!groups[noFrameworkGroup]) {
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            groups[noFrameworkGroup] = [];
          }
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          groups[noFrameworkGroup].push(row);
        }
      } else {
        const title =
          (row[key] && row[key].title) || `~No ${currGroupByOption.name}~`;
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (!groups[title]) {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          groups[title] = [];
        }
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        groups[title].push(row);
      }
    }

    return groups;
  }
  const sectionTitles = Object.keys(groupedRows).sort();

  return (
    <>
      {libraryIsEnabled && libraryType === "user_generated" && (
        <AddRecordHeader
          recordName={
            rows?.length > 0 ? "library" : "Company Risk Library Item"
          }
          addRecord={createNewRiskRegister}
        />
      )}
      <div className="rr-library-wrapper risk-register-library">
        <div className="filter-header">
          <div className="filter-header-flex">
            <div className="group-by-wrapper column-select">
              <label htmlFor="group-by-dropdown">Grouped by</label>
              <Dropdown
                options={allGroupByNames}
                onChange={handleDropdownClick}
                value={currGroupByOption.name}
              />
            </div>

            <div className="filter-by-wrapper column-select">
              <label>Filter By Framework</label>
              <div className="filter-flex-wrapper">
                <Dropdown
                  options={tagTitles}
                  onChange={handleFrameworkDropdownClick}
                  value={currentFramework}
                  placeholder="- Select -"
                />
                <img src={deleteIcon} onClick={clearFilter} alt="delete-icon" />
              </div>
            </div>

            <div className="filter-header-right" />
          </div>
        </div>
        <div
          className="rr-library-container"
          data-testid="rr-library-container"
        >
          {!libraryIsEnabled && (
            <div>
              Library is disabled for this workspace. Contact Themis to enable
              it.
            </div>
          )}
          {libraryIsEnabled && (
            <>
              {renderSearch}
              {libraryType === "themis_generated" && (
                <div className="library-explainer">
                  The information and materials available through the Themis
                  platform are for informational purposes only and do not
                  constitute legal advice. As legal advice must be tailored to
                  the specific circumstances of each case, nothing provided on
                  this site should be used as a substitute for advice of
                  competent counsel.
                </div>
              )}
              <div className="rr-library-main-content">
                {!rows?.length && libraryType === "user_generated" && (
                  <div className="create-new-record risk-register library empty active">
                    <button
                      type="button"
                      onClick={createNewRiskRegister}
                      data-testid="risk-register-library-add-new"
                    >
                      Add First Company Risk Library Item +
                    </button>
                  </div>
                )}
                {!rows?.length && libraryType === "themis_generated" && (
                  <Loading
                    loadingLayout="table-no-add-new"
                    showTableHeader={false}
                  />
                )}
                {sectionTitles.map((sectionTitle) => (
                  <LibraryTable
                    isOpen={!!currentFramework}
                    key={sectionTitle}
                    canDeleteRecords={canDeleteRecords}
                    isInternalWorkspace={Boolean(isInternalWorkspace)}
                    onDeleteRecord={handleDelete}
                    onSelectAllChange={handleMainCheckboxChange}
                    onSelectChange={handleRegularCheckboxChange}
                    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    rows={groupedRows[sectionTitle]}
                    title={sectionTitle}
                    libraryType={libraryType}
                    onCreate={() => createNewRiskRegister()}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default observer(Library);
