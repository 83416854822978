import { observer } from "mobx-react";
import React from "react";
import { generatePath, useParams } from "react-router-dom";

import {
  useCreateProject,
  useProject,
  useUpdateProject,
} from "@/api/queries/projects";
import { ErrorContainer } from "@/components/ErrorContainer";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";

import ProjectHeaderTabs from "../components/ProjectHeaderTabs";
import ProjectInfo from "../components/ProjectInfo";
import { PROJECTS_ARCHIVE_PATH, PROJECTS_LIST_PATH } from "./ProjectRoutes";

function Project() {
  const {
    context: { companyID },
  } = useMainStore();

  const { workspace_id, project_id } = useParams<{
    workspace_id: string;
    project_id: string;
  }>();

  const {
    data: project,
    isLoading,
    isError,
    error,
  } = useProject({
    workspaceId: Number(workspace_id),
    projectId: Number(project_id),
  });

  const { mutateAsync: createProject } = useCreateProject({
    companyId: Number(companyID),
  });

  const { mutateAsync: updateProject } = useUpdateProject({
    projectId: Number(project_id),
  });

  const isNewProject = isNaN(Number(project_id));
  const isArchived = Boolean(project?.data.archived_at);

  if (isLoading) {
    return (
      <PageContent>
        <Loading loadingLayout="small-table" />
      </PageContent>
    );
  }

  if (isError) {
    return (
      <PageContent>
        <ErrorContainer
          backButtonProps={{
            linkTo: generatePath("/workspaces/:workspace_id/home", {
              workspace_id,
            }),
          }}
        >
          Could not load project.
        </ErrorContainer>
      </PageContent>
    );
  }

  return (
    <PageLayout>
      <Header
        title={
          <div className="tw-align-center tw-flex tw-gap-1">
            <span>{isArchived ? "Archived Projects" : "Projects"}</span>
            {!(isLoading || error) && (
              <>
                <span className="tw-text-xl tw-font-medium tw-text-neutral-200">
                  /
                </span>
                <span className="tw-truncate">
                  {isNewProject ? "Add New Project" : project?.data.name}
                </span>
              </>
            )}
          </div>
        }
        backButtonLinkTo={
          isArchived
            ? generatePath(PROJECTS_ARCHIVE_PATH, { workspace_id })
            : generatePath(PROJECTS_LIST_PATH, { workspace_id })
        }
      />
      <ProjectHeaderTabs />
      <PageContent>
        <ProjectInfo
          data={project?.data}
          createProject={createProject}
          updateProject={updateProject}
          companyID={companyID}
        />
      </PageContent>
    </PageLayout>
  );
}

export default observer(Project);
