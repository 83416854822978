import React from "react";

import { Icon } from "@/components/Elements";

type Props = {
  width?: number | string;
  onFigmaUpload?: () => void;
  onFileUpload: () => void;
  onGoogleDriveUpload?: () => void;
  onSharePointUpload?: () => void;
};

export const FileTypeSelectionPopup = ({
  width,
  onFileUpload,
  onGoogleDriveUpload,
  onFigmaUpload,
  onSharePointUpload,
}: Props) => (
  <div>
    <div
      className="drag-drop-wrap upload-file-wrap"
      data-testid="attachment-cell-modes-dropdown"
      style={{ width }}
    >
      <div
        className="file-select-button main-select-button"
        data-testid="file-select-from-device"
        onClick={() => {
          onFileUpload();
        }}
      >
        Upload from Device
      </div>
      <hr />
      {onFigmaUpload && (
        <div
          className="file-select-button"
          onClick={onFigmaUpload}
          data-testid="attachment-cell-dropdown-upload-figma"
        >
          <Icon name="figma" />
          <span>Upload from Figma</span>
        </div>
      )}
      {onGoogleDriveUpload && (
        <div
          className="file-select-button"
          data-testid="file-select-from-google-drive"
          onClick={onGoogleDriveUpload}
        >
          <Icon name="google" />
          <span>Upload from Google Drive</span>
        </div>
      )}
      {onSharePointUpload && (
        <div
          className="file-select-button"
          data-testid="file-select-from-google-drive"
          onClick={onSharePointUpload}
        >
          <Icon name="sharePoint" disableFlexShrink />
          <span>Upload from Microsoft SharePoint</span>
        </div>
      )}
    </div>
  </div>
);
