var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { cva } from "cva";
import * as React from "react";
import { PiCaretDownBold, PiCheckBold } from "react-icons/pi";
import { cn } from "../../lib/utils";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, } from "../Command";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";
import { Checkbox } from "./Checkbox";
var defaultStyles = "tw-flex tw-w-full tw-bg-neutral-25 tw-px-3 tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-transition-colors";
var fontStyles = "tw-text-sm tw-font-medium tw-font-sans tw-text-neutral-500";
var focusStyles = "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-primary-300";
var readOnlyStyles = "read-only:tw-bg-primaryDim-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
var lockedStyles = "read-only:tw-bg-primary-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
var selectInputVariants = cva({
    base: [defaultStyles, fontStyles, focusStyles],
    variants: {
        size: {
            sm: "tw-h-7",
            md: "tw-h-8",
            lg: "tw-h-9",
        },
    },
    defaultVariants: {
        size: "lg",
    },
});
var Select = React.forwardRef(function (_a, ref) {
    var _b;
    var size = _a.size, _c = _a.placeholder, placeholder = _c === void 0 ? "- Select -" : _c, searchable = _a.searchable, selected = _a.selected, renderSelected = _a.renderSelected, onSelect = _a.onSelect, items = _a.items, className = _a.className, locked = _a.locked, readOnly = _a.readOnly, _d = _a.defaultOpen, defaultOpen = _d === void 0 ? false : _d, _e = _a.hideCaret, hideCaret = _e === void 0 ? false : _e, _f = _a.alignItemList, alignItemList = _f === void 0 ? "start" : _f, classNameList = _a.classNameList, _g = _a.onPopupClosed, onPopupClosed = _g === void 0 ? function () { } : _g, multiple = _a.multiple, props = __rest(_a, ["size", "placeholder", "searchable", "selected", "renderSelected", "onSelect", "items", "className", "locked", "readOnly", "defaultOpen", "hideCaret", "alignItemList", "classNameList", "onPopupClosed", "multiple"]);
    var _h = React.useState(defaultOpen), open = _h[0], setOpen = _h[1];
    var disabled = locked || readOnly;
    var handleSelect = function (newValue) {
        if (multiple) {
            var newItems = selected.includes(newValue)
                ? selected.filter(function (item) { return item !== newValue; })
                : __spreadArray(__spreadArray([], selected, true), [newValue], false);
            onSelect(newItems);
        }
        else {
            setOpen(false);
            // Since we hide the selected item from the list, this ensures list doesn't glitch before closing popup
            setTimeout(function () { return onSelect(newValue); }, 100);
        }
    };
    var renderComponent = function (selectedItem) {
        // If a custom renderSelected is provided, use that
        if (renderSelected) {
            return renderSelected(selectedItem || {});
        }
        var CustomComponent = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.Component;
        // Otherwise we want to use the provided Component or label
        if (CustomComponent) {
            return (React.createElement("div", { key: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.value, className: "tw-mr-1" },
                React.createElement(CustomComponent, __assign({}, selectedItem))));
        }
        return (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.label) || null;
    };
    var renderSingleSelection = function () {
        if (selected) {
            var selectedItem = items.find(function (item) { return item.value === selected; });
            return renderComponent(selectedItem);
        }
        return placeholder;
    };
    var renderMultipleSelection = function () {
        if (multiple && selected.length > 0) {
            var selectedItems = selected;
            var selectedLength = selectedItems.length;
            if (selectedLength > 3) {
                selectedItems = selectedItems.slice(0, 3);
            }
            var selectedComponents = selectedItems.map(function (value) {
                var selectedItem = items.find(function (item) { return item.value === value; });
                return renderComponent(selectedItem);
            });
            if (selectedLength > 3) {
                selectedComponents.push(React.createElement("div", { key: "more", className: "tw-flex tw-min-h-6 tw-min-w-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-neutral-50 tw-text-xxs tw-font-semibold tw-text-neutral-500" },
                    "+",
                    selectedLength - 3));
            }
            return selectedComponents;
        }
        return placeholder;
    };
    return (React.createElement(Popover, { open: open, onOpenChange: setOpen },
        React.createElement(PopoverTrigger, { disabled: disabled, ref: ref, asChild: true },
            React.createElement("button", __assign({ role: "combobox", "aria-expanded": open, className: cn(selectInputVariants({ size: size }), (_b = {
                        "tw-border-neutral-100": !open,
                        "tw-border-primary-300": open,
                        "tw-text-neutral-200": !selected
                    },
                    _b[lockedStyles] = locked,
                    _b[readOnlyStyles] = readOnly,
                    _b["tw-cursor-default"] = disabled,
                    _b), className) }, props),
                multiple ? renderMultipleSelection() : renderSingleSelection(),
                !disabled && !hideCaret && (React.createElement(PiCaretDownBold, { className: cn("tw-ml-auto tw-h-4 tw-w-4 tw-shrink-0 tw-text-neutral-500 tw-transition-transform", { "tw-rotate-180": open }) })))),
        React.createElement(PopoverContent, { align: alignItemList, className: cn("tw-min-w-60", classNameList), onCloseAutoFocus: onPopupClosed },
            React.createElement(Command, { tabIndex: 0, className: "tw-outline-none" },
                searchable && React.createElement(CommandInput, { autoFocus: true, placeholder: "Search..." }),
                React.createElement(CommandList, { className: cn("tw-max-h-72 tw-overflow-y-auto", {
                        "tw-border-t tw-border-solid tw-border-neutral-100": searchable,
                    }, classNameList) },
                    searchable && React.createElement(CommandEmpty, null, "No results."),
                    React.createElement(CommandGroup, null, items.map(function (_a) {
                        var value = _a.value, Component = _a.Component, label = _a.label;
                        var isSelected = multiple
                            ? selected === null || selected === void 0 ? void 0 : selected.includes(value)
                            : selected === value;
                        return (React.createElement(CommandItem, { key: value, value: String(value), keywords: [label], onSelect: handleSelect, className: cn({
                                "tw-bg-secondary-25 tw-text-secondary-300": isSelected,
                            }) },
                            multiple && (React.createElement(Checkbox, { className: "tw-mr-2", checked: isSelected })),
                            Component ? (React.createElement(Component, { value: value, label: label })) : (label),
                            !multiple && (React.createElement(PiCheckBold, { className: cn("tw-ml-auto tw-h-4 tw-w-4", {
                                    "tw-opacity-0": !isSelected,
                                    "tw-text-secondary-300": isSelected,
                                }) }))));
                    })))))));
});
Select.displayName = "Select";
export { Select };
