import React from "react";

import { Workspace } from "@/stores/types/workspace-types";

import { WorkspaceRadioButton } from "./WorkspaceRadioButton";

type WorkspaceRadioButtonsProps = {
  workspaces: Workspace[];
  workspaceId: string;
  onWorkspaceChange: (id: string) => void;
};

export const WorkspaceRadioButtons = (props: WorkspaceRadioButtonsProps) => (
  <div className="workspace-radio-buttons">
    {props.workspaces.map((workspace) => (
      <WorkspaceRadioButton
        key={workspace.id}
        workspace={workspace}
        isChecked={workspace.id.toString() === props.workspaceId}
        onCheck={props.onWorkspaceChange}
      />
    ))}
  </div>
);
