import React, { useState } from "react";

import { getSelectedModules } from "../../../../shared/dynamic-form/ViewRolesListRow";
import AssigningDrawer from "./AssigningDrawer";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editingRole?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modules?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit?: (...args: any[]) => any;
  permissionSetName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  permissionSets?: any[];
};

const RolesEdit = ({
  permissionSetName,
  permissionSets,
  modules,
  editingRole,
  onSubmit,
}: Props) => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      <div
        className="roles-placeholder"
        data-testid="roles-view-placeholder"
        onClick={() => setShowDrawer(true)}
      >
        {permissionSetName}
      </div>
      {showDrawer && (
        <AssigningDrawer
          permissionSets={permissionSets}
          workspaces={[editingRole.workspace]}
          modules={modules}
          initialValues={{
            permissionSet: editingRole.permission_set,
            workspaces: [editingRole.workspace],
            modules: getSelectedModules(modules, editingRole),
          }}
          onClose={() => setShowDrawer(false)}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

RolesEdit.defaultProps = {
  permissionSetName: "",
  permissionSets: [],
  modules: [],
  editingRole: {},
  onSubmit: () => {},
};

export default RolesEdit;
