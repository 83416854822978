import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import Dropdown, {
  DropdownOption,
  DropdownOptions,
} from "@/components/Elements/Dropdown/Dropdown";
import { useMainStore } from "@/contexts/Store";

const SingleSectionSelection = () => {
  // Import Mobx stores
  const mainStore = useMainStore();
  const sectionTags = mainStore.sectionTags.list;
  const { list: moduleWorkspaces } = mainStore.moduleWorkspaces;
  const currentModuleWorkspace = moduleWorkspaces.find(
    (moduleWorkspace) =>
      moduleWorkspace?.themis_module?.identifier === "risk_register",
  );
  const sectionTagDropdownOptions: DropdownOptions = [
    { id: 0, name: "All Risks" },
    ...sectionTags.map((sectionTag) => ({
      id: sectionTag.id as number,
      name: sectionTag.title,
    })),
  ];
  const [currentSelection, setCurrentSelection] = useState(
    sectionTagDropdownOptions[0] as DropdownOption,
  );

  useEffect(() => {
    if (currentModuleWorkspace) {
      mainStore.sectionTags.index(currentModuleWorkspace.id);
    }
  }, [currentModuleWorkspace, mainStore.reports.displayedWorkspace]);

  useEffect(() => {
    if (sectionTagDropdownOptions.length > 0) {
      onChange(sectionTagDropdownOptions[0] as DropdownOption);
    }
  }, [sectionTags]);

  function onChange(option: DropdownOption) {
    setCurrentSelection(option);
    if (option) {
      mainStore.riskRegisters.setCurrentSection(
        mainStore.sectionTags.list.find((section) => section.id === option.id),
      );
    }
  }

  if (sectionTags.length === 0) {
    return <div />;
  }
  return (
    <Dropdown
      className="type-dropdown"
      options={sectionTagDropdownOptions}
      selected={currentSelection}
      onChange={(option) => onChange(option)}
    />
  );
};

export default observer(SingleSectionSelection);
