import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { QuestionnaireAPI, RiskAreaApi } from "@/api/legacy/risk-assessment";
import { useMainStore } from "@/contexts/Store";
import {
  QuestionnaireRead,
  RiskArea,
  RiskMethodology,
} from "@/features/risk-assessment";

function useQuestionnaireData(activeWorkspaceId: number | undefined) {
  const mainStore = useMainStore();
  const { questionnaireId } = useParams<{ questionnaireId: string }>();

  const internalWorspaceId: number | undefined = mainStore.workspaces.list.find(
    (ws) => ws.is_internal,
  )?.id;

  const [questionnaire, setQuestionnaire] = useState<
    QuestionnaireRead | undefined
  >();
  const [riskMethodology, setRiskMethodology] = useState<RiskMethodology>();
  const [riskAreas, setRiskAreas] = useState<RiskArea[]>([]);

  const loadData = async () => {
    try {
      const [questionnaireData, riskAreasData] = await Promise.all([
        QuestionnaireAPI.get(Number(questionnaireId)),
        RiskAreaApi.getAll(String(internalWorspaceId)),
      ]);
      setQuestionnaire(questionnaireData);
      setRiskAreas(riskAreasData);
    } catch (err) {
      mainStore.toast.setErrorFromResponse(err);
    }
  };

  const fetchQuestionnaireData = () => {
    if (questionnaireId) {
      loadData();
    }
  };

  useEffect(() => {
    if (questionnaireId) {
      loadData();
    }
  }, [questionnaireId, activeWorkspaceId]);

  useEffect(() => {
    if (questionnaire) {
      setRiskMethodology(
        mainStore.riskMethodologies.list?.find(
          (rm) => rm.id === questionnaire!.risk_methodology_id,
        ),
      );
    }
  }, [questionnaire]);

  return {
    questionnaire,
    riskMethodology,
    riskAreas,
    setQuestionnaire,
    fetchQuestionnaireData,
  };
}

export default useQuestionnaireData;
