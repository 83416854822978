import dayjs from "dayjs";
import React from "react";

import { Typography } from "../../Elements";
import { formatTimeAfterDate } from "../../helpers/DateFormatters";
import ActionItem from "./ActionItem";

type TitleWithContextItemProps = {
  globalId: string;
  title: React.ReactNode;
  date?: string;
  context: React.ReactNode;
  onClick?: VoidFunction;
};

const TitleWithContextItem = ({
  globalId,
  title,
  date,
  context,
  onClick,
}: TitleWithContextItemProps) => (
  <ActionItem onClick={onClick}>
    <div>
      <Typography
        className="title-context-item__global-id"
        label={globalId}
        color="extrasLightViolet"
        size="sm"
      />
    </div>
    <Typography
      className="title-context-item__title"
      label={title}
      color="generalWhite"
      weight="semiBold"
    />
    <div className="title-context-item__details">
      {date && (
        <Typography
          label={formatTimeAfterDate(dayjs(date).toDate())}
          size="xs"
          color="generalWhite"
          weight="semiBold"
        />
      )}
      {context}
    </div>
  </ActionItem>
);

export default TitleWithContextItem;
