import classNames from "classnames";
import { observer } from "mobx-react";
import Pagination from "rc-pagination";
import React, { useState } from "react";
import { PiPlusBold } from "react-icons/pi";

import ImportBulk from "@/components/dashboard/ImportBulk";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useFilters } from "@/hooks/useFilters";
import { useTableData } from "@/hooks/useTableData";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";
import { IndexParams } from "@/stores/types/module-store-types";

import { useLoading } from "../../../hooks/useLoading";
import { useSortingAndPagination } from "../../../hooks/useSortingAndPagination";
import ExportBulk from "../../dashboard/ExportBulk";
import { Icon } from "../../Elements";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import Loading from "../../Loading";
import MenuActions from "../menu-actions/MenuActions";
import { customColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import ShareLink from "../shared/ShareLink";
import TableMenu from "../shared/tableMenu/TableMenu";
import Table from "../Table";
import CreateFirstOBICTA from "./CreateFirstOBICTA";

function ConflictsOfInterest() {
  // Import MobX stores
  const mainStore = useMainStore();

  useTableData();

  // State
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectMode, setSelectMode] = useState(false);

  // Variables
  const { data, page } = mainStore.conflicts;
  const { workspaceID } = mainStore.context;
  // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
  const moduleWorkspaceID = data?.module_workspace_id;
  const { visibleFields } = mainStore.fields;
  const recordVersions = mainStore.recordVersions.list;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const { getTableFiltersParam } = useUpdateFilter();
  const filters = getTableFiltersParam();

  // Hooks
  const loading = useLoading(visibleFields);

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields: visibleFields,
  });

  // Functions
  const resetMenuActions = () => {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'disabledRow' implicitly has an 'any' ty... Remove this comment to see the full error message
  function isUserEditable(disabledRow) {
    return disabledRow === true ? false : undefined;
  }

  function createNewConflict() {
    if (workspaceID) {
      // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
      mainStore.conflicts.create_blank(workspaceID);
    }
  }

  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    customColumns({});

  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
    setSortBy,
  } = useSortingAndPagination({ store: mainStore.conflicts, page });

  const onPageChange = async (newPage: number) => {
    const params: IndexParams = {};
    params.workspaceID = workspaceID;
    params.page = newPage;

    if (sortByFieldName) {
      params.sortParams = {
        field_name: sortByFieldName,
        direction: sortByDirection || "ASC",
      };
    }

    if (filters) {
      params.tableFilters = filters;
    }

    await mainStore.conflicts.index(params);
  };

  const renderFields = visibleFields.map((field) => {
    return (
      <ModuleTableColumn
        key={field.name}
        field={field}
        setSortBy={setSortBy}
        // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
        setSortByDirection={setSortByDirection}
        setSortByFieldName={setSortByFieldName}
        onDelete={deleteColumn}
        onRename={renameColumn}
      />
    );
  });

  const arrowLeftImage = (
    <Icon name="chevronLeft" color="generalMidnightDark" />
  );
  const arrowRightImage = (
    <Icon name="chevronRight" color="generalMidnightDark" />
  );

  const renderRows = recordVersions.map((recordVersion) => {
    const status = mainStore.avroSchemas.firstValueForField(
      "status",
      recordVersion.data,
    );
    const isReviewed = ["approved", "denied"].includes(status);

    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        moduleWorkspaceID={moduleWorkspaceID}
        recordVersion={recordVersion}
        fields={visibleFields}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
        currentTableName={recordVersion.table_name}
        moduleIdentifier="conflicts_of_interest"
        hasReview={isReviewed}
        isUserEditable={isUserEditable(isReviewed)}
        inSelectMenuActionMode={selectMode}
      />
    );
  });

  return (
    <Table extraClassNames="obi-table">
      {loading && <Loading loadingLayout="table" showTableHeader={false} />}
      {!loading && (
        <>
          <div
            className={classNames("table-header-wrap", {
              "header-with-filters": filtersViewEnabled,
            })}
          >
            {hasModuleWriteAccess && (
              <p>
                Want to share form link to your employees? Click “Share link” on
                the right.
              </p>
            )}
            <div className="import-export-buttons-container">
              <ExportBulk />
              <ImportBulk />
              {hasModuleWriteAccess && (
                <ShareLink moduleIdentifier="conflicts_of_interest" />
              )}
              {filtersTrigger}
            </div>
          </div>
          {filtersViewEnabled && (
            <div className="filters-wrap">
              <div className="switch-table-wrap" />
              {filtersContent}
            </div>
          )}
          <AddRecordHeader addRecord={createNewConflict} recordName="Form" />
          <div
            className="table-list-wrap im-list-wrap obi-content"
            data-testid="conflicts-table"
          >
            <div className="list-title-table">
              <ul>
                <div className="list-column-wrap">
                  <div className="title-first-block table-column-reorder">
                    {visibleFields?.length > 0 && (
                      <TableMenu
                        setShowCheckbox={setShowCheckbox}
                        resetMenuActions={resetMenuActions}
                        selectMode={selectMode}
                        setSelectMode={setSelectMode}
                      />
                    )}
                  </div>
                  {showCheckbox && selectMode ? (
                    <div>
                      <MenuActions resetMenuActions={resetMenuActions} />
                    </div>
                  ) : (
                    <div className="list-title-table-wrap">
                      {renderFields}
                      <span className="stretch-cell" />
                    </div>
                  )}
                </div>
                <AddColumnButton onAdd={createColumn} />
              </ul>
            </div>

            {renderRows}
            <div
              className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
              onClick={createNewConflict}
              data-testid="add-new-record-button"
            >
              <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
              <div className="tw-neutral-300 tw-text-sm">
                Add{" "}
                {getRecordName("conflicts_of_interest", moduleWorkspaces, true)}
                ...
              </div>
            </div>
            {recordVersions.length < 1 && <CreateFirstOBICTA />}
            <Pagination
              onChange={onPageChange}
              current={page}
              // @ts-expect-error TS(2339) FIXME: Property 'count' does not exist on type '{}'.
              total={data?.count}
              prevIcon={arrowLeftImage}
              nextIcon={arrowRightImage}
              showPrevNextJumpers
            />
          </div>
        </>
      )}
    </Table>
  );
}

export default observer(ConflictsOfInterest);
