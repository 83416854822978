import { RecordVersion } from "@/api";

import mainStore from "../Main";
import { ModuleIdentifier } from "../types/module-workspaces-types";

// The logic for the definition of a "locked" record varies by module
// The goal of this method is to ensure that definition is consistent across all areas of the frontend
export const isLockedRecord = (
  recordVersion?: RecordVersion,
  moduleIdentifier?: ModuleIdentifier,
) => {
  if (!recordVersion) {
    return true;
  }

  const identifier =
    moduleIdentifier || mainStore.context.themisModuleIdentifier;
  const { status, table_name: tableName } = recordVersion;

  if (identifier === "new_product_approval") {
    return status === "published";
  }
  if (identifier === "marketing") {
    return status && ["approved", "rejected"].includes(status);
  }
  if (identifier === "policy") {
    return tableName === "Finalized";
  }
  if (identifier === "training") {
    return status === "completed";
  }
  if (identifier === "control_mapping") {
    return status === "completed";
  }
  if (identifier === "procedures") {
    return tableName === "ProceduresFinalized";
  }
  if (identifier === "conflicts_of_interest") {
    return status && ["approved", "denied"].includes(status);
  }
  if (identifier === "audits") {
    return (
      mainStore.avroSchemas.valueForField("approval", recordVersion?.data)
        ?.verifier_id !== undefined
    );
  }
  if (identifier === "qa_tests_development") {
    return status?.includes("completed");
  }
  if (identifier === "qa_procedures") {
    return status && ["completed"].includes(status);
  }
  if (identifier === "qa_findings") {
    return status && ["sent", "closed"].includes(status);
  }
  if (identifier === "risk_register") {
    return status === "completed";
  }
  if (identifier === "documents") {
    return status === "published";
  }
  if (identifier === "issue_management") {
    return status === "closed";
  }
  if (identifier === "action_plans") {
    return status === "closed";
  }
  if (identifier === "zendesk") {
    return status === "verified";
  }
  if (identifier === "complaints") {
    return status === "closed";
  }
  if (identifier === "customer_support") {
    return (
      mainStore.avroSchemas.firstValueForField(
        "customer_support_status",
        recordVersion?.data,
      ) === "complete" || status === "closed"
    );
  }
  if (identifier === "finra") {
    return (
      mainStore.avroSchemas.firstValueForField(
        "escalated_status",
        recordVersion?.data,
      ) === "complete" ||
      mainStore.avroSchemas.firstValueForField(
        "review_status",
        recordVersion?.data,
      ) === "closed"
    );
  }

  return null;
};
