import React from "react";

import DataTile from "./DataTile";
import DonutTile from "./DonutTile";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tile?: any;
};

const Tile = ({ tile }: Props) => {
  switch (tile.component) {
    case "data-tile":
      return <DataTile label={tile.label} data={tile.count} />;
    case "donut-tile":
      return <DonutTile label={tile.label} data={tile.data} />;
    default:
      return null;
  }
};

export default Tile;
