import axios, { AxiosError } from "axios";
import { stringify } from "qs";

import { containsError, RailsErrors } from "@/utils/errors";

import { MFA_ERROR_MESSAGE } from "./api";

const legacyApi = axios.create({
  paramsSerializer: (params) => {
    return stringify(params, {
      arrayFormat: "brackets",
      skipNulls: true,
      filter: (prefix, value) => {
        if (prefix === "" || Array.isArray(value)) {
          return value;
        }

        if (typeof value === "object") {
          return JSON.stringify(value);
        }

        return value;
      },
    });
  },
});

legacyApi.interceptors.response.use(async (e) => {
  const { default: mainStore } = await import("../../stores/Main");

  if (axios.isAxiosError<{ errors: RailsErrors }>(e)) {
    const { response } = e;
    const errors = response?.data.errors;

    if (response?.status === 422 && errors?.invalid_authenticity_token) {
      mainStore.toast.setErrorFromResponse(e.response);
    }

    if (response?.status === 403) {
      let message = errors?.message || errors?.error;

      if (Array.isArray(message)) {
        message = message.join(", ");
      }

      mainStore.toast.setErrorText(message || "Access Denied");
    }
  }

  return e;
});

legacyApi.interceptors.response.use(
  undefined,
  async (e: Error | AxiosError) => {
    const { default: mainStore } = await import("../../stores/Main");

    if (axios.isAxiosError<{ errors: RailsErrors }>(e)) {
      const { response } = e;
      const errors = response?.data.errors || {};

      if (containsError(errors, "base", MFA_ERROR_MESSAGE)) {
        return {};
      }

      if (response?.status === 401) {
        if (mainStore.webSessions.accessToken) {
          mainStore.webSessions.setBanner(
            "Your session timed out due to inactivity.",
          );
        }
        mainStore.webSessions.removeAccessToken();
      }
    }

    return e;
  },
);

export default legacyApi;
