import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import RiskTypesSelect from "../cell-type/RiskTypesSelect";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  recordVersionID: number;
  fieldName?: string;
  locked?: boolean;
};

function ViewRiskTypesSelect({
  recordVersion,
  fieldName,
  recordVersionID,
  locked,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const selectedOptions = mainStore.avroSchemas.valueForField(
    fieldName,
    recordVersion.data,
  );
  const cellError = mainStore.recordVersions.errorsForField(
    recordVersion.id,
    fieldName,
  );
  const hasErrors = Boolean(cellError);

  return (
    <RiskTypesSelect
      fieldName={fieldName}
      recordVersionID={recordVersionID}
      selectedOptions={selectedOptions}
      width="100%"
      hasErrors={hasErrors}
      errorMessage={cellError?.description}
      locked={locked}
      hasErrorClass="detail-view-has-errors"
    />
  );
}

export default observer(ViewRiskTypesSelect);
