import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import { Icon } from "@/components/Elements";
import { FIELD_TYPES_CUSTOM_COLUMN } from "@/components/helpers/fieldTypes";
import { iconForDataType } from "@/components/table/shared/ModuleTableColumn";
import { useMainStore } from "@/contexts/Store";

import plusBlackIcon from "../../../../images/table-image/icon/plus-black.svg";
import plusIcon from "../../../../images/table-image/icon/plus.svg";
import Switch from "../Switch";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAdd: (...args: any[]) => any;
};

function AddColumnButton({ onAdd }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [columnName, setColumnName] = useState("");
  const [isMultiselect, setIsMultiselect] = useState(true);
  const [onlyInternalWorkspace, setOnlyInternalWorkspace] = useState(true);
  const [include, setInclude] = useState<{
    users: boolean;
    contacts: boolean;
  } | null>(null);
  const [columnType, setColumnType] = useState(null);
  const [isRequired, setIsRequired] = useState(false);

  const { canManageColumns } = mainStore.userPermissions;
  const { isCurrentWorkspaceArchived } = mainStore.workspaces;

  // Variables
  const isInternal = mainStore.context.activeWorkspace?.is_internal;
  const selectedType =
    columnType &&
    FIELD_TYPES_CUSTOM_COLUMN.find(
      (fieldType) => fieldType.value === columnType,
    );

  // Functions
  function closePopUp() {
    setShowPopup(false);
    setColumnType(null);
    setColumnName("");
    setIsRequired(false);
  }

  function openPopUp() {
    setShowPopup(true);
  }

  function handleSubmit() {
    onAdd({
      data_type: columnType,
      display_name: columnName,
      is_multiselect: isMultiselect,
      only_internal_workspace: onlyInternalWorkspace,
      is_required: isRequired,
      ...(include ? { include_field: include } : {}),
    });

    closePopUp();
  }

  useEffect(() => {
    if (
      selectedType &&
      // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type 'never'.
      selectedType.value === "com.askthemis.types.v1.tag_user"
    ) {
      setInclude({ users: true, contacts: false });
    } else {
      setInclude(null);
    }
    // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type 'never'.
  }, [selectedType?.value]);

  // Rendering
  const renderTrigger = (
    <div
      className={classNames("list-plus create-column-trigger", {
        active: showPopup,
        "pointer-events-none": !canManageColumns || isCurrentWorkspaceArchived,
      })}
      onMouseDown={() => setShowPopup(false)}
      data-testid="create-column-trigger"
    >
      <div
        className={classNames(
          {
            hidden: !canManageColumns,
            "create-column-button--disabled": isCurrentWorkspaceArchived,
          },
          "create-column-button",
        )}
        data-tooltip-id="tooltip"
        data-tooltip-content="Add custom column"
        data-tooltip-place="right"
      >
        <img src={showPopup ? plusIcon : plusBlackIcon} alt="plus-icon" />
      </div>
    </div>
  );

  const renderTitleBlock = (
    <div className="title-input-block">
      <input
        type="text"
        value={columnName}
        maxLength={40}
        placeholder="Enter Column Name"
        data-testid="create-column-name-input"
        onChange={(e) => setColumnName(e.target.value)}
      />
    </div>
  );

  const renderColumnTypeContent = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type 'null'.
    const { value, label } = selectedType;

    return (
      <div className="title-block">
        <h5>Property</h5>
        <ul>
          <li className="enabled">
            <Icon
              // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
              name={iconForDataType(selectedType.value)}
              color="generalMidnightDark"
            />
            {label}
            <button
              className="column-type-switch"
              type="button"
              onClick={() => setColumnType(null)}
            >
              <Icon name="switch" color="generalMidnightDark" />
            </button>
          </li>
        </ul>

        {value === "com.askthemis.types.v1.text" && (
          <p className="column-type-description">
            Free text allows you to optionally prefill each new cell with a
            default value
          </p>
        )}

        {value === "com.askthemis.types.v1.tag_user" && (
          <>
            <hr className="add-column-seperator" />
            <div className="column-type-option-additional-content">
              <h5>User type</h5>
              <div className="switch-row-container">
                <p>Users</p>
                <Switch
                  active
                  indeterminate={false}
                  checked={include?.users}
                  onChange={(event) =>
                    // @ts-expect-error TS(2345) FIXME: Argument of type '(prev: { users: boolean; contact... Remove this comment to see the full error message
                    setInclude((prev) => ({
                      ...prev,
                      users: event.target.checked,
                    }))
                  }
                />
              </div>
              <div className="switch-row-container">
                <p>Contacts</p>
                <Switch
                  active
                  indeterminate={false}
                  checked={include?.contacts}
                  onChange={(event) =>
                    // @ts-expect-error TS(2345) FIXME: Argument of type '(prev: { users: boolean; contact... Remove this comment to see the full error message
                    setInclude((prev) => ({
                      ...prev,
                      contacts: event.target.checked,
                    }))
                  }
                />
              </div>
            </div>
            <hr className="add-column-seperator" />
          </>
        )}

        {[
          "com.askthemis.types.v1.option",
          "com.askthemis.types.v1.tag_user",
        ].includes(value) && (
          <div className="column-type-option-additional-content">
            <h5>Options</h5>
            <div className="switch-row-container">
              <p>Allow Multiple Selection</p>
              <Switch
                active
                indeterminate={false}
                checked={isMultiselect}
                onChange={(event) => setIsMultiselect(event.target.checked)}
              />
            </div>
          </div>
        )}

        {value === "com.askthemis.types.v1.attachment" && (
          <div className="column-type-option-additional-content">
            <h5>Options</h5>
            <div className="switch-row-container">
              <p>Allow Multiple Attachments</p>
              <Switch
                active
                indeterminate={false}
                checked={isMultiselect}
                onChange={(event) => setIsMultiselect(event.target.checked)}
              />
            </div>
          </div>
        )}

        {value === "com.askthemis.types.v1.link" && (
          <p className="column-type-description">
            Link allows you to copy and paste external URL for quick access /
            reference
          </p>
        )}

        {isInternal && (
          <div className="column-type-option-additional-content">
            <div className="switch-row-container">
              <p>Add to Internal Workspace Only</p>
              <Switch
                active
                indeterminate={false}
                checked={onlyInternalWorkspace}
                onChange={(event) =>
                  setOnlyInternalWorkspace(event.target.checked)
                }
              />
            </div>
          </div>
        )}
        <li
          className="enabled required-switch-row"
          data-tooltip-left="Required columns must be completed in order to finalize or lock a record."
        >
          Required Column
          <div className="is-required-switch">
            <Switch
              active
              indeterminate={false}
              checked={isRequired}
              onChange={() => setIsRequired(!isRequired)}
            />
          </div>
        </li>
      </div>
    );
  };

  const renderColumnTypes = (
    <ul>
      {FIELD_TYPES_CUSTOM_COLUMN.map((type) => (
        <li
          className="enabled"
          key={type.value}
          // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          onClick={() => setColumnType(type.value)}
          data-testid={`create-${type.code}-column-type-item`}
        >
          <Icon
            name={iconForDataType(type.value)}
            color="generalMidnightDark"
          />
          {type.label}
        </li>
      ))}
    </ul>
  );

  const renderSubmitButton = (
    <button
      type="button"
      disabled={
        !columnName ||
        !columnType ||
        columnName.length > 40 ||
        // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type 'never'.
        (selectedType?.value === "com.askthemis.types.v1.tag_user" &&
          Boolean(include) &&
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          !include.users &&
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          !include.contacts)
      }
      className="add-column-button"
      onClick={handleSubmit}
      data-testid="create-column-submit"
    >
      Create Column
    </button>
  );

  return (
    <Popup
      position="bottom right"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={openPopUp}
      onClose={closePopUp}
      keepTooltipInside
    >
      <div
        className="table-dropdown title-select custom-columns-popup"
        data-testid="create-column-popup"
      >
        {renderTitleBlock}
        {columnType ? renderColumnTypeContent() : renderColumnTypes}
        {renderSubmitButton}
      </div>
    </Popup>
  );
}

export default observer(AddColumnButton);
