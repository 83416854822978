import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import { MainStore } from "../Main";

export default class ModuleAccessTokensStore {
  mainStore: MainStore;

  // Observable objects
  list = new Set();
  workspaceID = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      list: observable,
      workspaceID: observable,

      setList: action,
      setWorkspaceID: action,
    });

    this.mainStore = mainStore;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'token' implicitly has an 'any' type.
  async fetch_fields(token) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_access_tokens/${token}/fields_public`,
        headers: this.mainStore.getHeaders(),
      });
      this.mainStore.fields.setList(response.data.fields);
      this.mainStore.fieldOptions.setAll(response.data.options);
      this.setWorkspaceID(response.data.workspace_id);
      return response.data;
    } catch (error) {
      window.console.log(`"AccessTokens#fields_public" error ${error}`);
    }
  }

  /**
   * POST /api/react/module_access_tokens/:token/record_versions
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
  async create(params, files, token) {
    const data = {
      record_version: {
        data: params,
      },
      attachments: files,
    };

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_access_tokens/${token}/record_versions`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        return result.response.data;
      }

      return result.data;
    } catch (error) {
      window.console.log(
        `"ModuleAccessTokens#createRecordVersion" error ${error}`,
      );
      return null;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async find_or_create(moduleWorkspaceID) {
    const params = { module_workspace_id: moduleWorkspaceID };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/module_access_tokens/find_or_create`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return;
      }

      this.setList({ [moduleWorkspaceID]: response.data.token });

      return response.data.token;
    } catch (error) {
      window.console.log(
        `"WebAccessTokens#FindOrCreate for ModuleAccessTokens" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setList(value) {
    this.list.add(value);
  }

  resetList() {
    this.list = new Set();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setWorkspaceID(value) {
    this.workspaceID = value;
  }

  // Store Helpers

  cleanup() {
    this.resetList();
    this.setWorkspaceID(null);
  }
}
