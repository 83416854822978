import "./popup-menu.scss";

import classNames from "classnames";
import * as React from "react";
import Popup from "reactjs-popup";
import { PopupPosition } from "reactjs-popup/dist/types";

import ChevronLeft from "@/assets/svgs/chevron-left";

import PopupMenuHeader from "./PopupMenuHeader";

type PopupMenuProps = {
  anchorNode: JSX.Element;
  open: boolean;
  placement: PopupPosition;
  onClose: () => void;
  onGoBack?: () => void;
  goBackHeaderLabel?: string;
  showSubMenu?: boolean;
  mainMenu: React.ReactNode;
  menuStyle?: React.CSSProperties;
  subMenu?: React.ReactNode;
  children?: React.ReactNode;
};

export default function PopupMenu(props: PopupMenuProps) {
  return (
    <Popup
      open={props.open}
      position={props.placement || "bottom center"}
      trigger={<div>{props.anchorNode}</div>}
      onClose={props.onClose}
      arrow={false}
      repositionOnResize
      closeOnDocumentClick
      closeOnEscape
    >
      <div
        className={classNames("ra-popup-menu", {
          "ra-popup-menu--fullwidth": props.showSubMenu,
        })}
        style={props.menuStyle}
      >
        {props.showSubMenu ? (
          <>
            <PopupMenuHeader
              icon={<ChevronLeft className="icon" width="16" height="16" />}
              label={props.goBackHeaderLabel}
              onClick={props.onGoBack}
            />
            <div className="popup-menu-section-wrapper">{props.subMenu}</div>
          </>
        ) : (
          <div className="popup-menu-section-wrapper">{props.mainMenu}</div>
        )}
      </div>
    </Popup>
  );
}
