import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  IconButton,
} from "@themis/ui";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { PiPencilSimpleLineBold } from "react-icons/pi";
import { useParams } from "react-router-dom";

import { useDashboardComponents } from "@/api/queries/dashboardComponents";
import { useWorkspaces } from "@/api/queries/workspaces";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import { DATE_FORMAT } from "@/constants";
import IssuesByRisk from "@/features/home/components/widgets/IssuesByRisk";
import IssuesBySource from "@/features/home/components/widgets/IssuesBySource";
import IssuesWithDueDatesApproachingStat from "@/features/home/components/widgets/IssuesWithDueDatesApproachingStat";
import LibraryRecordByStages from "@/features/home/components/widgets/LibraryRecordByStages";
import LibraryRecordsWithDueDatesApproaching from "@/features/home/components/widgets/LibraryRecordsWithDueDatesApproaching";
import WorkDistributionByModules from "@/features/home/components/widgets/WorkDistributionByModules";
import WorkflowsWithDueDatesApproaching from "@/features/home/components/widgets/WorkflowsWithDueDatesApproaching";
import { useSearchParams } from "@/hooks/useSearchParams";
import mainStore from "@/stores/Main";

import { CustomTimeRangeModal } from "../components/CustomTimeRangeModal";
import { HomeHeader } from "../components/HomeHeader";
import { HomeTabs } from "../components/HomeTabs";
import ListWorkspaces from "../components/ListWorkspaces";
import { TimeFrameSelect } from "../components/TimeFrameSelect";
import { getSelectedWorkspaces, widgetSessionStorageKey } from "../utils";

export type HomePageSearchParams = {
  days?: string;
  start_date?: string;
  end_date?: string;
  workspace_ids?: string[];
  sessionId?: string;
};

const DEFAULT_TIME_FRAME = "30" as const;

export const CUSTOM_TIME_FRAME = "custom" as const;

const TIME_FRAME_OPTIONS = [
  { value: "1", label: "1 Day" },
  { value: "7", label: "1 Week" },
  { value: "30", label: "1 Month" },
  { value: "90", label: "3 Months" },
  { value: "180", label: "6 Months" },
  { value: "365", label: "1 Year" },
  { value: CUSTOM_TIME_FRAME, label: "Custom Time Range" },
];

const isStringEmptyObject = (str: string) => str === "{}";

export function CompanyOutlook() {
  const [isCustomTimeFrameModalOpen, setIsCustomTimeFrameModalOpen] =
    useState(false);

  const { workspace_id } = useParams<{ workspace_id: string }>();

  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const moduleWorkspacesNames = useMemo(() => {
    const list = {} as { [key: string]: string };
    moduleWorkspaces?.forEach((mw) => {
      list[mw.themis_module.identifier] = mw.name;
    });
    return list;
  }, [moduleWorkspaces]);

  const [searchParams, setSearchParams] =
    useSearchParams<HomePageSearchParams>();

  const currentUserID = String(mainStore.users.user.id);
  const selectedTimeFrame = searchParams.days || DEFAULT_TIME_FRAME;

  const { data: widgetData } = useDashboardComponents({
    workspaceId: Number(workspace_id),
    systemName: "outlook",
    ...searchParams,
  });

  const { data: workspacesData } = useWorkspaces();

  useEffect(() => {
    if (
      (!searchParams.sessionId && searchParams.workspace_ids) ||
      (searchParams.sessionId && searchParams.sessionId !== currentUserID)
    ) {
      setSearchParams({ ...{}, sessionId: currentUserID }, true, true);
    }
    const searchParamsStr = JSON.stringify(searchParams);

    if (isStringEmptyObject(searchParamsStr)) {
      return;
    }

    // We want save search params to local storage every time it changes
    sessionStorage.setItem(
      widgetSessionStorageKey(currentUserID),
      searchParamsStr,
    );
  }, [searchParams]);

  useEffect(() => {
    const storageParams = sessionStorage.getItem(
      widgetSessionStorageKey(currentUserID),
    );
    const searchParamsStr = JSON.stringify(searchParams);

    if (!isStringEmptyObject(searchParamsStr) || !storageParams) {
      return;
    }

    // If local storage exists and there's no search params, we want to apply saved params to URL
    setSearchParams(JSON.parse(storageParams));
  }, [searchParams]);

  function getWidgetData(systemName: string) {
    return widgetData?.data[0].components.find(
      ({ system_name }) => system_name === systemName,
    )?.data;
  }

  function handleTimeFrameSelect(value: string) {
    if (value === CUSTOM_TIME_FRAME) {
      setIsCustomTimeFrameModalOpen(true);

      return;
    }

    setSearchParams({ ...searchParams, days: value }, true, true);
  }

  function handleSetCustomTimeRange({
    start_date,
    end_date,
  }: {
    start_date: string;
    end_date: string;
  }) {
    setIsCustomTimeFrameModalOpen(false);

    setSearchParams(
      {
        ...searchParams,
        days: CUSTOM_TIME_FRAME,
        start_date,
        end_date,
      },
      true,
      true,
    );
  }

  const selectedWorkspaceIds = getSelectedWorkspaces({
    selectedWorkspaceIds: searchParams.workspace_ids,
    currentWorkspaceId: Number(workspace_id),
  });

  const selectedWorkspaces = workspacesData?.workspaces.filter(({ id }) =>
    selectedWorkspaceIds.includes(id),
  );

  return (
    <PageLayout>
      <HomeHeader hasManageWorkspaces />
      <div>
        <HomeTabs />
      </div>
      <PageContent>
        <div className="tw-flex tw-w-full tw-flex-col tw-gap-[14px]">
          <header className="tw-flex tw-flex-col tw-items-start tw-gap-3 tw-self-stretch">
            <section className="tw-flex tw-items-center tw-justify-between tw-self-stretch">
              <div className="tw-flex tw-items-center tw-gap-2">
                <p className="tw-truncate tw-text-xs tw-text-neutral-300">
                  Data used from these workspaces
                </p>
                <div className="tw-flex tw-items-center tw-gap-1">
                  <ListWorkspaces
                    workspaces={selectedWorkspaces || []}
                    displayCount={4}
                  />
                </div>
              </div>
              <div className="tw-flex tw-items-center tw-gap-2">
                {searchParams.days === CUSTOM_TIME_FRAME && (
                  <div className="tw-flex tw-items-center tw-gap-1">
                    <p className="tw-text-xs tw-font-medium tw-text-neutral-300">
                      {dayjs(searchParams.start_date).format(DATE_FORMAT)} -{" "}
                      {dayjs(searchParams.end_date).format(DATE_FORMAT)}
                    </p>
                    <IconButton
                      size="sm"
                      color="transparent"
                      Icon={PiPencilSimpleLineBold}
                      onClick={() => setIsCustomTimeFrameModalOpen(true)}
                    />
                  </div>
                )}
                <TimeFrameSelect
                  options={TIME_FRAME_OPTIONS}
                  selectedValue={selectedTimeFrame}
                  onSelect={handleTimeFrameSelect}
                />
                {isCustomTimeFrameModalOpen && (
                  <CustomTimeRangeModal
                    onClose={() => setIsCustomTimeFrameModalOpen(false)}
                    onSetCustomTimeRange={handleSetCustomTimeRange}
                  />
                )}
              </div>
            </section>
            <section className="tw-flex tw-items-center tw-justify-between tw-self-stretch">
              <h2 className="tw-text-base tw-font-semibold tw-text-neutral-500">
                Workspace Overview
              </h2>
            </section>
          </header>
          <div className="tw-flex tw-w-full tw-flex-col tw-gap-4">
            <div className="tw-flex tw-flex-wrap tw-gap-4">
              <LibraryRecordsWithDueDatesApproaching
                widgetData={getWidgetData(
                  "library_records_with_due_dates_approaching",
                )}
                moduleWorkspacesNames={moduleWorkspacesNames}
              />
              <WorkflowsWithDueDatesApproaching
                widgetData={getWidgetData(
                  "workflows_with_due_dates_approaching",
                )}
                moduleWorkspacesNames={moduleWorkspacesNames}
              />
            </div>
            <div className="tw-flex tw-flex-wrap tw-gap-4">
              <LibraryRecordByStages
                widgetData={getWidgetData("library_record_by_stage")}
              />
              <WorkDistributionByModules
                widgetData={getWidgetData("work_distribution_by_modules")}
                moduleWorkspacesNames={moduleWorkspacesNames}
              />
            </div>
            <div className="tw-flex tw-flex-wrap tw-gap-4">
              <Accordion
                type="single"
                collapsible
                defaultValue="item-1"
                className="tw-mb-4 tw-w-full"
              >
                <AccordionItem value="item-1">
                  <AccordionTrigger iconPosition="left">
                    <span className="tw-text-base tw-font-semibold">
                      Issue Management
                    </span>
                  </AccordionTrigger>
                  <AccordionContent className="tw-flex">
                    <div className="tw-min-w-screen tw-flex tw-w-screen tw-flex-wrap tw-gap-4 tw-p-1">
                      <IssuesWithDueDatesApproachingStat
                        overdueData={getWidgetData("overdue_issues")}
                        widgetData={getWidgetData(
                          "issues_with_due_dates_approaching",
                        )}
                      />
                      <IssuesBySource
                        widgetData={getWidgetData("issues_due_date_by_sources")}
                      />
                      <IssuesByRisk
                        widgetData={getWidgetData(
                          "issues_due_date_by_risk_levels",
                        )}
                      />
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </PageContent>
    </PageLayout>
  );
}
