import { kebabCase } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import {
  Link,
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import ComplaintsActionBar from "@/components/table/complaints/ComplaintsActionBar";
import ConflictsOfInterestActionBar from "@/components/table/conflicts-of-interest/ConflictsOfInterestActionBar";
import ControlMappingActionBar from "@/components/table/control-mapping/ControlMappingActionBar";
import ProceduresActionBar from "@/components/table/procedure/ProceduresActionBar";
import TrainingActionBar from "@/components/table/training/TrainingActionBar";
import { useMainStore } from "@/contexts/Store";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";
import { MainStore } from "@/stores/Main";
import { pathMatchesPattern, patternToRoute } from "@/utils/routing";

import { Icon, MiniTag } from "../Elements";
import {
  getRecordName,
  nameFromModuleWorkspace,
} from "../helpers/nameForThemisModuleIdentifier";
import StepsSaveButton from "../manage-approvals/StepsSaveButton";
import ExportQuestionnaire from "../settings/ExportQuestionnaire";
import ExportRiskAssessment from "../settings/ExportRiskAssessment";
import ExportRiskAssessmentData from "../settings/ExportRiskAssessmentData";
import ChangeManagementActionBar from "../table/change-management/ChangeManagementActionBar";
import TopCustomerSupportComplaintsContextMenu from "../table/customer-support/TopCustomerSupportComplaintsContextMenu";
import DocumentsActionBar from "../table/documents/DocumentsActionBar";
import TopFinraComplaintsContextMenu from "../table/finra-complaints/TopFinraComplaintsContextMenu";
import IssueManagementActionBar from "../table/issue-management/IssueManagementActionBar";
import PolicyActionBar from "../table/policy/PolicyActionBar";
import RiskRegisterActionBar from "../table/risk-register/RiskRegisterActionBar";
import HeadSelect from "../table/shared/HeadSelect";
import {
  inactiveVDDUrl,
  RATemplatesVDDUrl,
  templatesVDDUrl,
} from "../table/vendor-due-diligence/urlPaths";
import AuditDetailViewHeaderButton from "./actions/AuditDetailViewHeaderButton";
import BuildVendorSaveButton from "./actions/BuildVendorSaveButton";
import ChangeManagementSectionSaveButton from "./actions/ChangeManagementSectionSaveButton";
import CMLibraryItemSaveButton from "./actions/CMLibraryItemSaveButton";
import IMControlsSaveButton from "./actions/IMControlsSaveButton";
import IMOperationalControlsSaveButton from "./actions/IMOperationalControlsSaveButton";
import IMRelatedRisksSaveButton from "./actions/IMRelatedRisksSaveButton";
import MarketingCreativeViewButton from "./actions/MarketingCreativeViewButton";
import QaTestDetailViewHeaderButton from "./actions/QaTestDetailViewHeaderButton";
import RiskAssessmentSaveButton from "./actions/RiskAssessmentSaveButton";
import RiskRegisterControlsBackButton from "./actions/RiskRegisterControlsBackButton";
import RiskRegisterLibraryItemSaveButton from "./actions/RiskRegisterLibraryItemSaveButton";
import RiskRegisterOperationalControlsSaveButton from "./actions/RiskRegisterOperationalControlsSaveButton";
import SaveVendorOnExit from "./actions/SaveVendorOnExit";
import ViewModuleUsers from "./ViewModuleUsers";

// @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspacesParam' implicitly has a... Remove this comment to see the full error message
const generateDashboardPages = (moduleWorkspacesParam) => {
  const moduleWorkspaces = moduleWorkspacesParam || [];

  return [
    {
      path: "/workspaces/:workspace_id/modules/policy/attachment_view/:record_version_id/:file_id",
      name: "View Document",
    },
    {
      path: "/workspaces/:workspace_id/modules/policy/:record_version_id/historical-versions",
      name: "View Previous Versions",
    },
    {
      path: "/workspaces/:workspace_id/modules/:module_identifier/audit_trail",
      name: "Module Level Audit Trail",
    },
    {
      path: "/workspaces/:workspace_id/modules/:module_identifier/:record_version_id/audit_trail",
      name: "Record Level Audit Trail",
    },
    {
      path: "/workspaces/:workspace_id/modules/:module_identifier/:tab/:record_version_id/audit_trail",
      name: "Record Level Audit Trail",
    },
    {
      path: "/workspaces/:workspace_id/modules/policy/drafts",
      name: getRecordName("policy", moduleWorkspaces, false),
    },
    {
      path: "/workspaces/:workspace_id/modules/policy/attestation",
      name: `${getRecordName(
        "policy",
        moduleWorkspaces,
        false,
      )} Attestation Status`,
    },
    {
      path: "/workspaces/:workspace_id/modules/policy/finalized",
      name: `Finalized ${getRecordName("policy", moduleWorkspaces, false)}`,
    },
    {
      path: "/workspaces/:workspace_id/modules/policy/archived",
      name: `Archived ${getRecordName("policy", moduleWorkspaces, false)}`,
    },
    {
      path: "/workspaces/:workspace_id/modules/policy/:policy_id",
      name: `${getRecordName("policy", moduleWorkspaces, true)} Detail`,
    },
    {
      path: "/workspaces/:workspace_id/modules/policy",
      name: getRecordName("policy", moduleWorkspaces, true),
    },
    {
      path: "/workspaces/:workspace_id/modules/procedures/attachment_view/:record_version_id/:file_id",
      name: "View Document",
    },
    {
      path: "/workspaces/:workspace_id/modules/procedures/:record_version_id/historical-versions",
      name: "View Previous Versions",
    },
    {
      path: "/workspaces/:workspace_id/modules/procedures/drafts",
      name: nameFromModuleWorkspace("procedures", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/procedures/finalized",
      name: nameFromModuleWorkspace("procedures", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/procedures/attestation",
      name: nameFromModuleWorkspace("procedures", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/procedures/archived",
      name: `Archived ${getRecordName("procedures", moduleWorkspaces, false)}`,
    },
    {
      path: "/workspaces/:workspace_id/modules/procedures/:record_version_id",
      name: `${getRecordName("procedures", moduleWorkspaces, true)} Detail`,
    },
    {
      path: "/workspaces/:workspace_id/modules/procedures",
      name: nameFromModuleWorkspace("procedures", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/marketing/attachment_view",
      name: (mainStore: MainStore) => {
        const { current } = mainStore.recordVersions;

        if (current && !current.meta?.name && !current.global_id) {
          return nameFromModuleWorkspace("marketing", moduleWorkspaces);
        }

        return (
          <div className="marketing-header-title">
            {current?.meta?.name}
            {current?.global_id && <MiniTag label={current.global_id} />}
          </div>
        );
      },
    },
    {
      path: "/workspaces/:workspace_id/modules/marketing/completed",
      name: nameFromModuleWorkspace("marketing", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/marketing/manage-approvals-steps",
      name: "Manage Approvals",
    },
    {
      path: "/workspaces/:workspace_id/modules/marketing/:record_version_id",
      name: `${nameFromModuleWorkspace(
        "marketing",
        moduleWorkspaces,
        // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 3.
        true,
      )} Detail`,
    },
    {
      path: "/workspaces/:workspace_id/modules/marketing",
      name: nameFromModuleWorkspace("marketing", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/training/archived",
      name: "View Archived",
    },
    {
      path: "/workspaces/:workspace_id/modules/training/:record_version_id/historical-versions",
      name: "View Previous Versions",
    },
    {
      path: "/workspaces/:workspace_id/modules/training/published",
      name: nameFromModuleWorkspace("training", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/training/attestations",
      name: nameFromModuleWorkspace("training", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/training/:record_version_id",
      name: `${getRecordName("training", moduleWorkspaces, false)} Detail`,
    },
    {
      path: "/workspaces/:workspace_id/modules/training",
      name: nameFromModuleWorkspace("training", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/complaints/completed",
      name: nameFromModuleWorkspace("complaints", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/finra/review",
      name: "Finra Complaints",
    },
    {
      path: "/workspaces/:workspace_id/modules/finra/escalated",
      name: "Finra Complaints",
    },
    {
      path: "/workspaces/:workspace_id/modules/finra/:complaint_id",
      name: "Finra Complaint Details",
    },
    {
      path: "/workspaces/:workspace_id/modules/customer-support/support",
      name: nameFromModuleWorkspace("customer_support", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/customer-support/compliance",
      name: nameFromModuleWorkspace("customer_support", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/customer-support/:complaint_id",
      name: `${getRecordName(
        "customer_support",
        moduleWorkspaces,
        true,
      )} Detail`,
      identifier: "customer_support",
    },
    {
      path: "/workspaces/:workspace_id/modules/complaints/:complaint_id",
      identifier: "complaints",
      name: (mainStore: MainStore) => {
        if (mainStore.context.tableName === "Default") {
          return "Complaint Detail";
        }
        return getRecordName("complaints", moduleWorkspaces, false);
      },
    },
    {
      path: "/workspaces/:workspace_id/modules/complaints",
      name: nameFromModuleWorkspace("complaints", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/finra",
      name: nameFromModuleWorkspace("finra", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/audits/:record_id/documents/create",
      name: "Add New Document",
    },
    {
      path: "/workspaces/:workspace_id/modules/audits/:record_id/documents/:document_id/update",
      name: "Update Document",
    },
    {
      path: "/workspaces/:workspace_id/modules/audits/:record_id",
      name: `${getRecordName("audits", moduleWorkspaces, true)} Details`,
    },
    {
      path: "/workspaces/:workspace_id/modules/qa-testing/:record_id/documents/create",
      name: "Add New Document",
      identifier: "qa_tests_development",
    },
    {
      path: "/workspaces/:workspace_id/modules/qa-testing/:record_id/documents/:document_id/update",
      name: "Update Document",
    },
    {
      path: "/workspaces/:workspace_id/modules/qa-testing/:record_id",
      name: `${getRecordName(
        "qa_tests_development",
        moduleWorkspaces,
        true,
      )} Details`,
    },
    {
      path: "/workspaces/:workspace_id/modules/audits",
      name: nameFromModuleWorkspace("audits", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/qa-testing",
      name: nameFromModuleWorkspace("qa_tests_development", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/conflicts-of-interest/attachment_view/:conflict_id/:file_id",
      name: `View ${getRecordName(
        "conflicts_of_interest",
        moduleWorkspaces,
        true,
      )}`,
    },
    {
      path: "/workspaces/:workspace_id/modules/conflicts-of-interest/:conflict_id",
      name: `${getRecordName(
        "conflicts_of_interest",
        moduleWorkspaces,
        true,
      )} Details`,
    },
    {
      path: "/workspaces/:workspace_id/modules/conflicts-of-interest",
      name: nameFromModuleWorkspace("conflicts_of_interest", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/change-management/:record_id/checklists/create",
      name: "Create Checklist",
    },
    {
      path: "/workspaces/:workspace_id/modules/change-management/checklists/:checklist_id/update",
      name: "Edit Checklist",
    },
    {
      path: "/workspaces/:workspace_id/modules/change-management/checklists-templates/:checklist_id/update",
      name: "Edit Template",
    },
    {
      path: "/workspaces/:workspace_id/modules/change-management/add-new-approval",
      name: `Add ${getRecordName(
        "new_product_approval",
        moduleWorkspaces,
        true,
      )}`,
    },
    {
      path: "/workspaces/:workspace_id/modules/change-management/edit-approval/:section_id",
      name: `Edit ${getRecordName(
        "new_product_approval",
        moduleWorkspaces,
        true,
      )}`,
    },
    {
      path: "/workspaces/:workspace_id/modules/change-management/section-view/:section_id/attachment_view/:file_id",
      name: "View Document",
    },
    {
      path: "/workspaces/:workspace_id/modules/change-management/attachment_view/:record_version_id/:file_id",
      name: `${getRecordName(
        "new_product_approval",
        moduleWorkspaces,
        true,
      )} Details`,
    },
    {
      path: "/workspaces/:workspace_id/modules/change-management/:record_version_id",
      name: `${getRecordName(
        "new_product_approval",
        moduleWorkspaces,
        true,
      )} Detail`,
    },
    {
      path: "/workspaces/:workspace_id/modules/change-management",
      name: nameFromModuleWorkspace("new_product_approval", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/issue-management/completed",
      name: "Completed",
    },
    {
      path: "/workspaces/:workspace_id/modules/issue-management/drafts",
      name: nameFromModuleWorkspace("issue_management", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/issue-management/all",
      name: nameFromModuleWorkspace("issue_management", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/issue-management/metrics",
      name: nameFromModuleWorkspace("issue_management", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/issue-management/:parent_record_version_id/action-plans/:record_version_id",
      name: `${getRecordName("action_plans", moduleWorkspaces, true)} Details`,
    },
    {
      path: "/workspaces/:workspace_id/modules/issue-management/new-record",
      name: `Add New ${getRecordName(
        "issue_management",
        moduleWorkspaces,
        true,
      )}`,
    },
    {
      path: "/workspaces/:workspace_id/modules/issue-management/:issue_management_id",
      name: `${getRecordName(
        "issue_management",
        moduleWorkspaces,
        true,
      )} Details`,
    },
    {
      path: "/workspaces/:workspace_id/modules/issue-management",
      name: nameFromModuleWorkspace("issue_management", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/control-mapping/templates",
      name: "Company Control Library",
    },
    {
      path: "/workspaces/:workspace_id/modules/control-mapping/themis-control-library",
      name: "Themis Control Library",
    },
    {
      path: "/workspaces/:workspace_id/modules/control-mapping/completed",
      name: nameFromModuleWorkspace("control_mapping", moduleWorkspaces),
      identifier: "control_mapping",
    },
    {
      path: "/workspaces/:workspace_id/modules/control-mapping/:record_version_id/historical-versions",
      name: "View Previous Versions",
    },
    {
      path: "/workspaces/:workspace_id/modules/control-mapping/archived",
      name: "View Archived",
    },
    {
      path: "/workspaces/:workspace_id/modules/control-mapping/:record_version_id",
      name: `${getRecordName(
        "control_mapping",
        moduleWorkspaces,
        true,
      )} Details`,
    },
    {
      path: "/workspaces/:workspace_id/modules/control-mapping/:record_version_id/module_assessments",
      name: "Control Assessment",
    },
    {
      path: "/workspaces/:workspace_id/modules/control-mapping",
      name: nameFromModuleWorkspace("control_mapping", moduleWorkspaces),
      identifier: "control_mapping",
    },
    {
      path: "/workspaces/:workspace_id/modules/documents/folder/:document_id",
      name: (mainStore: MainStore) => {
        const defaultName = nameFromModuleWorkspace(
          "documents",
          moduleWorkspaces,
        );
        const { folderID } = mainStore.documents;

        if (!folderID) {
          return defaultName;
        }

        const sectionTag = mainStore.sectionTags.currentFolder;

        return `${nameFromModuleWorkspace(
          "documents",
          moduleWorkspaces,
        )}/${sectionTag?.path}`;
      },
    },
    {
      path: "/workspaces/:workspace_id/modules/documents/archived",
      name: `Archived ${nameFromModuleWorkspace(
        "documents",
        moduleWorkspaces,
      )}`,
    },
    {
      path: "/workspaces/:workspace_id/modules/documents/search",
      name: `All ${nameFromModuleWorkspace("documents", moduleWorkspaces)}`,
    },
    {
      path: "/workspaces/:workspace_id/modules/documents/:record_version_id",
      name: `${getRecordName("documents", moduleWorkspaces, true)} Detail`,
    },
    {
      path: "/workspaces/:workspace_id/modules/documents",
      name: nameFromModuleWorkspace("documents", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/active",
      name: nameFromModuleWorkspace("vendor_due_diligence", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/templates/create",
      name: "New Template",
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/templates/:checklist_id/update",
      name: "Edit Template",
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/:record_id/templates/:checklist_id/use",
      name: "Use Template",
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/:record_id/questionnaires/create",
      name: "New Questionnaire",
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/questionnaires/:checklist_id/update",
      name: "Edit Questionnaire",
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/questionnaires/:checklist_id/complete",
      name: "Onboarding Questionnaire",
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/contracts",
      name: `${nameFromModuleWorkspace(
        "vendor_due_diligence",
        moduleWorkspaces,
      )} | Contracts`,
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/templates",
      name: `${nameFromModuleWorkspace(
        "vendor_due_diligence",
        moduleWorkspaces,
      )} | Questionnaire Templates`,
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/risk-assessment-templates",
      name: `${nameFromModuleWorkspace(
        "vendor_due_diligence",
        moduleWorkspaces,
      )} | Risk Assessment Templates`,
      identifier: "vendor_due_diligence",
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/inactive-vendors",
      name: `${nameFromModuleWorkspace(
        "vendor_due_diligence",
        moduleWorkspaces,
      )} | Inactive Vendors`,
      identifier: "vendor_due_diligence",
    },
    {
      path: "/workspaces/:workspace_id/modules/add-new-vendor",
      name: `Add New ${getRecordName(
        "vendor_due_diligence",
        moduleWorkspaces,
        true,
      )}`,
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/:vendor_id/build_risk_assessment/create",
      name: "Create Risk Assessment",
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/:vendor_id/build_risk_assessment/:risk_assessment_id/update",
      name: "Update Risk Assessment",
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/build_risk_assessment/templates/create",
      name: "Create Risk Assessment Template",
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/build_risk_assessment/templates/:risk_assessment_id/update",
      name: "Update Risk Assessment Template",
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/:vendor_id/build_risk_assessment/:risk_assessment_id/templates/use",
      name: "Apply Risk Assessment Template",
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/audit_trail",
      name: "Module Level Audit Trail",
    },
    {
      path: "/workspaces/:workspace_id/modules/vendor-due-diligence/:vendor_id",
      name: `${getRecordName(
        "vendor_due_diligence",
        moduleWorkspaces,
        true,
      )} - `,
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register/templates",
      name: "Company Risk Library",
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register/scoring",
      name: nameFromModuleWorkspace("risk_register", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register/themis-risk-library",
      name: "Themis Risk Library",
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register/active",
      name: nameFromModuleWorkspace("risk_register", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register/completed",
      name: nameFromModuleWorkspace("risk_register", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register/:record_version_id/controls",
      name: `${getRecordName("risk_register", moduleWorkspaces, true)} Detail`,
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register/:record_version_id/operational-controls",
      name: `${getRecordName("risk_register", moduleWorkspaces, true)} Detail`,
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register/:record_version_id/version-history",
      name: `${getRecordName("risk_register", moduleWorkspaces, true)} Detail`,
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register/:record_version_id",
      name: `${getRecordName("risk_register", moduleWorkspaces, true)} Detail`,
      identifier: "risk_register",
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register",
      name: nameFromModuleWorkspace("risk_register", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register-history",
      name: nameFromModuleWorkspace("risk_register", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register-scoring-inherent",
      name: nameFromModuleWorkspace("risk_register", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register-scoring-residual",
      name: nameFromModuleWorkspace("risk_register", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register/themis-risk-library",
      name: "Themis Risk Library",
    },
    {
      path: "/workspaces/:workspace_id/modules/risk-register/active",
      name: `Active ${nameFromModuleWorkspace(
        "risk_register",
        moduleWorkspaces,
      )}`,
    },
    {
      path: "/workspaces/:workspace_id/modules/qa-tests-development/:record_version_id",
      name: `${getRecordName(
        "qa_tests_development",
        moduleWorkspaces,
        true,
      )} Detail`,
    },
    {
      path: "/workspaces/:workspace_id/modules/qa-tests-development",
      name: nameFromModuleWorkspace("qa_tests_development", moduleWorkspaces),
    },
    {
      path: "/workspaces/:workspace_id/modules/qa-tests/:section_tag_id/new-record",
      name: `Add New ${getRecordName(
        "qa_tests_development",
        moduleWorkspaces,
        true,
      )}`,
    },
    {
      path: "/workspaces/:workspace_id/modules/qa-tests",
      name: nameFromModuleWorkspace("qa_tests_development", moduleWorkspaces),
      identifier: "qa_tests_development",
    },
    {
      path: "/workspaces/:workspace_id/modules/qa-tests/completed",
      name: nameFromModuleWorkspace("qa_tests_development", moduleWorkspaces),
      identifier: "qa_tests_development",
    },
    {
      path: "/workspaces/:workspace_id/modules/qa-tests/:record_version_id",
      name: `${getRecordName(
        "qa_tests_development",
        moduleWorkspaces,
        true,
      )} Detail`,
      identifier: "qa_tests_development",
    },
    {
      path: "/workspaces/:workspace_id/modules/qa-tests/:record_version_id/add-new-procedures/:procedures_id",
      name: "Add New Procedure Step",
    },
    {
      path: "/workspaces/:workspace_id/modules/qa-tests/:record_version_id/view-procedures/:procedures_id",
      name: "View Procedure Step Detail",
    },
    {
      path: "/workspaces/:workspace_id/modules/zendesk",
      name: nameFromModuleWorkspace("zendesk", moduleWorkspaces),
    },
  ];
};

function DashboardHeader() {
  // Import MobX stores
  const mainStore = useMainStore();

  const { workspaceID } = mainStore.context;

  // State
  const location = useLocation();
  const history = useHistory();
  const { tableName } = mainStore.context;
  const { isSupport, themisModuleIdentifier } = mainStore.context;
  const isPolicyBaseLevel = [
    `/workspaces/${workspaceID}/modules/policy/finalized`,
    `/workspaces/${workspaceID}/modules/policy/drafts`,
    `/workspaces/${workspaceID}/modules/policy/attestation`,
  ].includes(location.pathname);
  const isDocumentsBaseLevel = [
    `/workspaces/${workspaceID}/modules/documents`,
    `/workspaces/${workspaceID}/modules/documents/archived`,
  ].includes(location.pathname);
  const isDocuments = location.pathname.includes("/modules/documents");
  const isProceduresBaseLevel = [
    `/workspaces/${workspaceID}/modules/procedures/finalized`,
    `/workspaces/${workspaceID}/modules/procedures/drafts`,
    `/workspaces/${workspaceID}/modules/procedures/attestation`,
  ].includes(location.pathname);
  const isDetailView = /\/modules\/[^/]+\/\d+$/.test(location.pathname);
  const finalizedPolicyLevel =
    location.pathname.endsWith("/archived") ||
    location.pathname.endsWith("/historical-versions") ||
    location.pathname.includes(
      `/${kebabCase(
        // @ts-expect-error TS(2345) FIXME: Argument of type '"complaints" | "customer_support... Remove this comment to see the full error message
        themisModuleIdentifier,
      )}/audit_trail`,
    );

  // Variables
  const activeWorkspace = mainStore.users.user.active_workspace;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const vendorName = mainStore.vendors?.currentVendor?.record?.account?.name;
  let vendorID = null;

  if (
    pathMatchesPattern(
      location.pathname,
      "/workspaces/:workspace_id/modules/vendor-due-diligence/",
    )
  ) {
    vendorID = mainStore.vendors?.currentVendor?.id;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'from' implicitly has an 'any' type.
  const goBack = async (from) => {
    if (
      from === "qa" &&
      (location.pathname.includes("qa-tests-development") ||
        location.pathname.endsWith("procedures") ||
        location.pathname.endsWith("findings") ||
        location.pathname.endsWith("documents"))
    ) {
      history.push(`/workspaces/${workspaceID}/modules/qa-tests`);
    } else if (location.pathname.includes("folder")) {
      const sectionTag = mainStore.sectionTags.currentFolder;
      history.push(`${sectionTag?.back_path}`);
    } else {
      history.goBack();
    }
  };

  function renderBackButton() {
    return (
      <>
        {themisModuleIdentifier === "policy" && finalizedPolicyLevel && (
          <div className="archive-head-link" data-testid="back-button">
            <Link
              to={`/workspaces/${workspaceID}/modules/${kebabCase(
                themisModuleIdentifier,
              )}/drafts`}
            >
              <Icon name="chevronLeft" color="generalLightBlue" />
            </Link>
          </div>
        )}

        {!isProceduresBaseLevel && themisModuleIdentifier === "procedures" && (
          <div className="archive-head-link" data-testid="back-button">
            <button onClick={goBack} data-testid="go-back-button">
              <Icon name="chevronLeft" color="generalLightBlue" />
            </button>
          </div>
        )}

        {themisModuleIdentifier === "marketing" &&
          location.pathname !==
            `/workspaces/${workspaceID}/modules/marketing` &&
          !location.pathname.includes("/completed") && (
            <div className="archive-head-link" data-testid="back-button">
              <button onClick={goBack} data-testid="go-back-button">
                <Icon name="chevronLeft" color="generalLightBlue" />
              </button>
            </div>
          )}

        {location.pathname.includes("/checklists/create") && (
          <div className="archive-head-link" data-testid="back-button">
            <Link to={`/workspaces/${workspaceID}/modules/change-management`}>
              <Icon name="chevronLeft" color="generalLightBlue" />
            </Link>
          </div>
        )}

        {pathMatchesPattern(
          location.pathname,
          "/workspaces/:workspace_id/modules/change-management/attachment_view/",
        ) && (
          <div className="archive-head-link" data-testid="back-button">
            <button onClick={goBack} data-testid="go-back-button">
              <Icon name="chevronLeft" color="generalLightBlue" />
            </button>
          </div>
        )}

        {/* VDD */}
        {/* link to main page */}
        {isVendorEdit && <SaveVendorOnExit />}

        {/* link back */}
        {location.pathname.includes("/vendor-due-diligence/") &&
          !isVendorEdit &&
          !isFirstLevelVendorPage && (
            <div className="archive-head-link" data-testid="back-button">
              <button onClick={goBack}>
                <Icon name="chevronLeft" color="generalLightBlue" />
              </button>
            </div>
          )}

        {location.pathname.includes("/conflicts-of-interest/") &&
          !location.pathname.endsWith("/conflicts-of-interest/") && (
            <div className="archive-head-link" data-testid="back-button">
              <button onClick={goBack} data-testid="go-back-button">
                <Icon name="chevronLeft" color="generalLightBlue" />
              </button>
            </div>
          )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/documents/`,
        ) &&
          !location.pathname.endsWith("/search") && (
            <div className="archive-head-link" data-testid="back-button">
              <button onClick={goBack}>
                <Icon name="chevronLeft" color="generalLightBlue" />
              </button>
            </div>
          )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/training/`,
        ) &&
          !location.pathname.includes("/published") &&
          !location.pathname.includes("/attestations") && (
            <div className="archive-head-link" data-testid="back-button">
              <button onClick={goBack}>
                <Icon name="chevronLeft" color="generalLightBlue" />
              </button>
            </div>
          )}

        {/* Risk Register */}
        {location.pathname.includes("/risk-register") &&
          (location.pathname.includes("/operational-controls") ||
            location.pathname.includes("/version-history") ||
            location.pathname.match(/\/modules\/risk-register\/\d+$/)) &&
          !location.pathname.includes("/operational-controls-add-new") && (
            <div className="archive-head-link" data-testid="back-button">
              <Link to={`/workspaces/${workspaceID}/modules/risk-register`}>
                <Icon name="chevronLeft" color="generalLightBlue" />
              </Link>
            </div>
          )}

        {(location.pathname.includes("/operational-controls-add-new") ||
          location.pathname.includes("/related-risks-add-new")) && (
          <div className="archive-head-link" data-testid="back-button">
            <button onClick={goBack}>
              <Icon name="chevronLeft" color="generalLightBlue" />
            </button>
          </div>
        )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/risk-register/templates`,
        ) && (
          <div className="archive-head-link" data-testid="back-button">
            <button onClick={goBack}>
              <Icon name="chevronLeft" color="generalLightBlue" />
            </button>
          </div>
        )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/risk-register/themis-risk-library`,
        ) && (
          <div className="archive-head-link" data-testid="back-button">
            <button onClick={goBack}>
              <Icon name="chevronLeft" color="generalLightBlue" />
            </button>
          </div>
        )}

        {pathMatchesPattern(
          location.pathname,
          "/workspaces/:workspace_id/modules/risk-register",
        ) &&
          location.pathname.endsWith("/controls") && (
            <RiskRegisterControlsBackButton />
          )}

        {/* CM */}
        {location.pathname !==
          `/workspaces/${workspaceID}/modules/control-mapping` &&
          location.pathname !==
            `/workspaces/${workspaceID}/modules/control-mapping/templates` &&
          location.pathname !==
            `/workspaces/${workspaceID}/modules/control-mapping/themis-control-library` &&
          location.pathname !==
            `/workspaces/${workspaceID}/modules/control-mapping/completed` &&
          themisModuleIdentifier === "control_mapping" && (
            <div className="archive-head-link" data-testid="back-button">
              <button onClick={goBack}>
                <Icon name="chevronLeft" color="generalLightBlue" />
              </button>
            </div>
          )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/policy/`,
        ) &&
          !isPolicyBaseLevel &&
          !finalizedPolicyLevel && (
            <div className="archive-head-link" data-testid="back-button">
              <Link to={`/workspaces/${workspaceID}/modules/policy/drafts`}>
                <Icon name="chevronLeft" color="generalLightBlue" />
              </Link>
            </div>
          )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/audits/`,
        ) && (
          <div className="archive-head-link" data-testid="back-button">
            <button onClick={goBack}>
              <Icon name="chevronLeft" color="generalLightBlue" />
            </button>
          </div>
        )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/qa-testing/`,
        ) && (
          <div className="archive-head-link" data-testid="back-button">
            <button onClick={goBack}>
              <Icon name="chevronLeft" color="generalLightBlue" />
            </button>
          </div>
        )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/zendesk/`,
        ) &&
          location.pathname !==
            `/workspaces/${workspaceID}/modules/zendesk/completed` && (
            <div className="archive-head-link" data-testid="back-button">
              <button onClick={goBack}>
                <Icon name="chevronLeft" color="generalLightBlue" />
              </button>
            </div>
          )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/finra/escalated/`,
        ) && (
          <div className="archive-head-link" data-testid="back-button">
            <Link
              to={`/workspaces/${workspaceID}/modules/finra/escalated`}
              data-testid="head-link-complaints-escalated"
            >
              <Icon name="chevronLeft" color="generalLightBlue" />
            </Link>
          </div>
        )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/finra/`,
        ) &&
          isDetailView && (
            <div className="archive-head-link" data-testid="back-button">
              <button onClick={goBack}>
                <Icon name="chevronLeft" color="generalLightBlue" />
              </button>
            </div>
          )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/customer-support/support/`,
        ) && (
          <div className="archive-head-link" data-testid="back-button">
            <Link
              to={`/workspaces/${workspaceID}/modules/customer-support/support`}
              data-testid="head-link-complaints-customer-support"
            >
              <Icon name="chevronLeft" color="generalLightBlue" />
            </Link>
          </div>
        )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/customer-support/`,
        ) &&
          !location.pathname.includes(
            `/workspaces/${workspaceID}/modules/customer-support/compliance`,
          ) &&
          !location.pathname.includes(
            `/workspaces/${workspaceID}/modules/customer-support/support`,
          ) && (
            <div
              className="archive-head-link"
              data-testid="head-link-complaints-compliance"
            >
              <button onClick={goBack}>
                <Icon name="chevronLeft" color="generalLightBlue" />
              </button>
            </div>
          )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/complaints/`,
        ) &&
          location.pathname !==
            `/workspaces/${workspaceID}/modules/complaints/completed` &&
          tableName === "Default" && (
            <div className="archive-head-link" data-testid="back-button">
              <Link
                to={`/workspaces/${workspaceID}/modules/complaints`}
                data-testid="go-back-complaints"
              >
                <Icon name="chevronLeft" color="generalLightBlue" />
              </Link>
            </div>
          )}

        {location.pathname !==
          `/workspaces/${workspaceID}/modules/issue-management/drafts` &&
          location.pathname !==
            `/workspaces/${workspaceID}/modules/issue-management/completed` &&
          location.pathname !==
            `/workspaces/${workspaceID}/modules/issue-management/metrics` &&
          location.pathname !==
            `/workspaces/${workspaceID}/modules/issue-management/all` &&
          !location.pathname.includes("/action-plans") &&
          !location.pathname.includes("/operational-controls-add-new") &&
          !location.pathname.includes("/related-risks-add-new") &&
          themisModuleIdentifier === "issue_management" && (
            <div className="archive-head-link" data-testid="back-button">
              <Link
                to={`/workspaces/${workspaceID}/modules/issue-management/drafts`}
                data-testid="go-back-issue-management"
              >
                <Icon name="chevronLeft" color="generalLightBlue" />
              </Link>
            </div>
          )}

        {location.pathname.includes("/action-plans") &&
          themisModuleIdentifier === "issue_management" && (
            <div className="archive-head-link" data-testid="back-button">
              <div className="move-back" onClick={goBack}>
                <Icon name="chevronLeft" color="generalLightBlue" size="md" />
              </div>
            </div>
          )}

        {/* QA */}
        {location.pathname.startsWith(
          `/workspaces/${workspaceID}/modules/qa-tests/`,
        ) &&
          !location.pathname.includes("completed") && (
            <div className="archive-head-link" data-testid="back-button">
              <button onClick={() => goBack("qa")}>
                <Icon name="chevronLeft" color="generalLightBlue" />
              </button>
            </div>
          )}

        {location.pathname.includes(
          `/workspaces/${workspaceID}/modules/qa-tests-development/`,
        ) && (
          <div className="archive-head-link" data-testid="back-button">
            {/* @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0. */}
            <button onClick={() => goBack()} data-testid="go-back-button">
              <Icon name="chevronLeft" color="generalLightBlue" />
            </button>
          </div>
        )}

        {location.pathname.startsWith(
          `/workspaces/${workspaceID}/modules/change-management/`,
        ) &&
          !location.pathname.includes("attachment_view") &&
          !location.pathname.includes("/checklists/create") && (
            <div className="archive-head-link" data-testid="back-button">
              <div className="move-back" onClick={goBack}>
                <Icon name="chevronLeft" color="generalLightBlue" size="md" />
              </div>
            </div>
          )}
      </>
    );
  }

  const isVendorEdit =
    location.pathname === `/workspaces/${workspaceID}/modules/add-new-vendor` ||
    (![
      `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendorID}/contracts`,
      `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendorID}/questionnaires`,
      `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendorID}/risk_assessment`,
      `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendorID}/documents`,
    ].includes(location.pathname) &&
      location.pathname.startsWith(
        `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendorID}`,
      ) &&
      !location.pathname.includes("/build_risk_assessment") &&
      !location.pathname.includes("/contracts/"));
  const isFirstLevelVendorPage = [
    `/workspaces/${workspaceID}/modules/vendor-due-diligence/active`,
    `/workspaces/${workspaceID}/modules/vendor-due-diligence/inactive-vendors`,
    `/workspaces/${workspaceID}/modules/vendor-due-diligence/contracts`,
    `/workspaces/${workspaceID}/modules/vendor-due-diligence/templates`,
    `/workspaces/${workspaceID}/modules/vendor-due-diligence/risk-assessment-templates`,
  ].includes(location.pathname);

  const title = (
    <Switch>
      {generateDashboardPages(moduleWorkspaces).map((page) => {
        let name =
          (typeof page.name === "function"
            ? page.name(mainStore)
            : page.name) || "";
        if (
          vendorName &&
          page.name ===
            `${getRecordName(
              "vendor_due_diligence",
              moduleWorkspaces,
              true,
            )} - `
        ) {
          name += ` ${vendorName}`;
        }

        return (
          <Route key={page.path} path={page.path}>
            <div
              className="dashboard-header-workspace"
              data-testid="dashboard-header-workspace"
            >
              <h3>{name}</h3>
            </div>
          </Route>
        );
      })}
    </Switch>
  );

  function shouldHideModuleUsersBasedOnPath(pathname: string) {
    const hidePathsStart = [
      `/workspaces/${workspaceID}/modules/add-new-vendor`,
      `/workspaces/${workspaceID}/modules/vendor-due-diligence/questionnaires/`,
      `/workspaces/${workspaceID}/modules/finra/review/`,
      `/workspaces/${workspaceID}/modules/finra/escalated/`,
      `/workspaces/${workspaceID}/modules/policy/drafts/`,
      `/workspaces/${workspaceID}/modules/documents/`,
      `/workspaces/${workspaceID}/modules/policy/audit_trail`,
      `/workspaces/${workspaceID}/modules/qa-tests/`,
      `/workspaces/${workspaceID}/modules/change-management/add-new-approval`,
      `/workspaces/${workspaceID}/modules/change-management/edit-approval/`,
      `/workspaces/${workspaceID}/modules/change-management/`,
    ];

    const hidePathsExact = [
      `/workspaces/${workspaceID}/modules/archived`,
      `/workspaces/${workspaceID}/modules/change-management/checklists/create`,
      patternToRoute(inactiveVDDUrl, { workspace_id: workspaceID }),
      patternToRoute(templatesVDDUrl, { workspace_id: workspaceID }),
      patternToRoute(RATemplatesVDDUrl, { workspace_id: workspaceID }),
    ];

    const hidePathsEnd = ["/historical-versions", "/manage-approvals-steps"];

    const hidePathsIncludes = [
      "/attachment_view",
      "/modules/issue-management/drafts/",
    ];

    return (
      hidePathsStart.some((path) => pathname.startsWith(path)) ||
      hidePathsExact.includes(pathname) ||
      hidePathsEnd.some((path) => pathname.endsWith(path)) ||
      hidePathsIncludes.some((path) => pathname.includes(path)) ||
      /\d+$/.test(pathname)
    );
  }

  return (
    <div className="dashboard-header">
      <div data-testid="dashboard-header" className="dashboard-header-block">
        <div className="dashboard-header-left">
          {renderBackButton()}
          {title}

          {!shouldHideModuleUsersBasedOnPath(location.pathname) &&
            activeWorkspace?.is_internal &&
            !isSupport && (
              <div className="tw-ml-3 tw-mr-3">
                <ViewModuleUsers />
              </div>
            )}
          <FavoriteButton />
        </div>
        <div className="dashboard-header-right">
          {!location.pathname.startsWith(
            `/workspaces/${workspaceID}/modules/add-new-vendor`,
          ) &&
            !location.pathname.startsWith(
              `/workspaces/${workspaceID}/modules/add-new-vendor`,
            ) &&
            !location.pathname.startsWith(
              `/workspaces/${workspaceID}/modules/vendor-due-diligence/questionnaires/`,
            ) &&
            !location.pathname.startsWith(
              `/workspaces/${workspaceID}/modules/finra/review/`,
            ) &&
            !location.pathname.startsWith(
              `/workspaces/${workspaceID}/modules/finra/escalated/`,
            ) &&
            !location.pathname.startsWith(
              `/workspaces/${workspaceID}/modules/customer-support/support/`,
            ) &&
            !location.pathname.startsWith(
              `/workspaces/${workspaceID}/modules/customer-support/compliance/`,
            ) &&
            !location.pathname.startsWith(
              `/workspaces/${workspaceID}/modules/policy/drafts/`,
            ) &&
            ![
              "/historical-versions",
              `/workspaces/${workspaceID}/modules/risk-register/templates`,
            ].includes(location.pathname) &&
            !location.pathname.startsWith(
              `/workspaces/${workspaceID}/modules/change-management/add-new-approval`,
            ) &&
            !location.pathname.startsWith(
              `/workspaces/${workspaceID}/modules/change-management/edit-approval/`,
            ) &&
            !location.pathname.startsWith(
              `/workspaces/${workspaceID}/modules/change-management/`,
            ) &&
            !(
              location.pathname.startsWith(
                `/workspaces/${workspaceID}/modules/audits`,
              ) && location.pathname.endsWith("/documents")
            ) &&
            !(
              location.pathname.startsWith(
                `/workspaces/${workspaceID}/modules/audits`,
              ) && location.pathname.endsWith("/audit_trail")
            ) &&
            !matchPath(
              location.pathname,
              "/workspaces/:workspace_id/modules/:module_identifier/:record_version_id/findings",
            ) &&
            !location.pathname.endsWith("/controls") &&
            !location.pathname.endsWith("/operational-controls") &&
            !location.pathname.endsWith("/operational-controls-add-new") &&
            !location.pathname.endsWith("/related-risks") &&
            !location.pathname.endsWith("/related-risks-add-new") &&
            !location.pathname.includes("/attachment_view") &&
            !location.pathname.includes("/marketing/") &&
            !location.pathname.includes(
              `/workspaces/${workspaceID}/modules/issue-management/drafts/`,
            ) &&
            !(themisModuleIdentifier === "policy" && !isPolicyBaseLevel) &&
            !(
              themisModuleIdentifier === "procedures" && !isProceduresBaseLevel
            ) &&
            !(
              themisModuleIdentifier === "documents" && !isDocumentsBaseLevel
            ) &&
            !isDetailView && <HeadSelect />}
          {location.pathname.startsWith(
            `/workspaces/${workspaceID}/modules/vendor-due-diligence/questionnaires`,
          ) &&
            location.pathname.endsWith("complete") && <ExportQuestionnaire />}
          {location.pathname.startsWith(
            `/workspaces/${workspaceID}/modules/vendor-due-diligence`,
          ) &&
            location.pathname.endsWith("/update") &&
            location.pathname.includes("build_risk_assessment") &&
            !location.pathname.includes("templates") && (
              <ExportRiskAssessment />
            )}
          {location.pathname.startsWith(
            `/workspaces/${workspaceID}/modules/vendors/build_risk_assessment/templates/`,
          ) &&
            location.pathname.endsWith("/update") && (
              <ExportRiskAssessmentData />
            )}
          {location.pathname.startsWith(
            `/workspaces/${workspaceID}/modules/finra/`,
          ) && (
            <div className="dashboard-header-right">
              <TopFinraComplaintsContextMenu />
            </div>
          )}

          {location.pathname.startsWith(
            `/workspaces/${workspaceID}/modules/customer-support/`,
          ) && <TopCustomerSupportComplaintsContextMenu />}

          {themisModuleIdentifier === "policy" && !isPolicyBaseLevel && (
            <PolicyActionBar />
          )}

          {themisModuleIdentifier === "procedures" &&
            !isProceduresBaseLevel && <ProceduresActionBar />}

          {isDocuments && isDetailView && <DocumentsActionBar />}

          {location.pathname.includes("/complaints") && isDetailView && (
            <ComplaintsActionBar />
          )}

          {location.pathname.includes("/training") && isDetailView && (
            <TrainingActionBar />
          )}

          {location.pathname.includes("/control-mapping") && isDetailView && (
            <ControlMappingActionBar />
          )}

          {location.pathname.includes("/conflicts-of-interest") &&
            isDetailView && <ConflictsOfInterestActionBar />}

          {location.pathname.includes("/issue-management") && isDetailView && (
            <IssueManagementActionBar />
          )}
          {/* Actions for Build Vendor */}
          {location.pathname.includes(
            `/workspaces/${workspaceID}/modules/add-new-vendor`,
          ) && <BuildVendorSaveButton />}
          {/* Actions for Risk Assessment */}
          {location.pathname.includes("/build_risk_assessment/") &&
            !location.pathname.includes("/update") && (
              <RiskAssessmentSaveButton path={location.pathname} />
            )}
          {location.pathname.includes(
            `/workspaces/${workspaceID}/modules/risk-register/templates/new`,
          ) && <RiskRegisterLibraryItemSaveButton isExistingItem={false} />}
          {location.pathname.includes(
            `/workspaces/${workspaceID}/modules/risk-register/templates/`,
          ) &&
            !location.pathname.includes("new") && (
              <RiskRegisterLibraryItemSaveButton isExistingItem />
            )}
          {location.pathname.includes("/risk-register") &&
            location.pathname.endsWith("/operational-controls-add-new") && (
              <RiskRegisterOperationalControlsSaveButton />
            )}
          {location.pathname.includes("/risk-register") && isDetailView && (
            <RiskRegisterActionBar />
          )}
          {location.pathname.includes(
            `/workspaces/${workspaceID}/modules/control-mapping/templates/`,
          ) && <CMLibraryItemSaveButton />}
          {pathMatchesPattern(
            location.pathname,
            "/workspaces/:workspace_id/modules/marketing/",
          ) &&
            !location.pathname.includes("audit_trail") &&
            !location.pathname.includes("manage-approvals-steps") &&
            location.pathname !==
              `/workspaces/${workspaceID}/modules/marketing/completed` &&
            location.pathname !==
              `/workspaces/${workspaceID}/modules/marketing` &&
            !location.pathname.startsWith(
              `/workspaces/${workspaceID}/modules/marketing/attachment_view/`,
            ) && <MarketingCreativeViewButton />}

          {location.pathname ===
            `/workspaces/${workspaceID}/modules/marketing/manage-approvals-steps` && (
            <StepsSaveButton />
          )}

          {/* Actions for IM */}
          {location.pathname.includes("/issue-management") &&
            location.pathname.endsWith("/controls") && <IMControlsSaveButton />}

          {location.pathname.includes("/issue-management") &&
            location.pathname.endsWith("/operational-controls-add-new") && (
              <IMOperationalControlsSaveButton />
            )}
          {location.pathname.includes("/issue-management") &&
            location.pathname.endsWith("/related-risks-add-new") && (
              <IMRelatedRisksSaveButton />
            )}
          {/* Actions for Audits -> DetailView */}
          {matchPath(location.pathname, {
            path: "/workspaces/:workspace_id/modules/audits/:record_id",
            exact: true,
          }) && <AuditDetailViewHeaderButton />}
          {/* Actions for QA -> DetailView */}
          {location.pathname.startsWith(
            `/workspaces/${workspaceID}/modules/qa-testing/`,
          ) &&
            !location.pathname.includes("documents") && (
              <QaTestDetailViewHeaderButton />
            )}
          {(location.pathname.includes(
            `/workspaces/${workspaceID}/modules/change-management/add-new-approval`,
          ) ||
            location.pathname.includes(
              `/workspaces/${workspaceID}/modules/change-management/edit-approval/`,
            )) && <ChangeManagementSectionSaveButton />}
          {location.pathname.startsWith(
            `/workspaces/${workspaceID}/modules/change-management/`,
          ) &&
            !location.pathname.includes("attachment_view") &&
            !location.pathname.includes("checklists") &&
            !location.pathname.includes("audit_trail") &&
            !location.pathname.includes("/add-new-approval") &&
            !location.pathname.includes("edit-approval/") && (
              <ChangeManagementActionBar />
            )}
        </div>
      </div>
    </div>
  );
}

export default observer(DashboardHeader);
