import React from "react";
import { Route, Switch } from "react-router-dom";

import AttestationsList from "../../../components/table/policy/AttestationsList";
import CreateCTA from "../../../components/table/policy/CreateCTA";
import Policies from "../../../components/table/policy/Policies";
import CWCreateFirstCTA from "../../../components/table/shared/create-first-cta/CWCreateFirstCTA";

type Props = {
  isInternal?: boolean;
  recordName?: string;
  recordNamePlural?: string;
  showCTA?: boolean;
  showCheckbox?: boolean;
};

function PoliciesAttestation({
  showCTA,
  isInternal,
  showCheckbox,
  recordName,
  recordNamePlural,
}: Props) {
  return (
    <div className="policies-table-section" data-testid="policies-attestation">
      <Switch>
        <Route
          exact
          path="/workspaces/:workspace_id/modules/policy/attestation"
        >
          <div className="table-list-wrap no-margin table-list-wrap-wider policy-table">
            <Policies showCheckbox={showCheckbox} />

            <AttestationsList group="Policies" title={recordNamePlural} />

            {showCTA && isInternal && <CreateCTA />}

            {showCTA && !isInternal && (
              <CWCreateFirstCTA
                title={`Add / Share ${recordNamePlural}`}
                sentences={[
                  `Create ${recordNamePlural} for the collaborative workspace by clicking "Add New ${recordName}" on the top to start.`,
                  `Shared ${recordNamePlural} are visible here.`,
                ]}
              />
            )}
          </div>
        </Route>
      </Switch>
    </div>
  );
}

export default PoliciesAttestation;
