import React from "react";

import { Button } from "@/components/Elements";
import { IntegrationItem } from "@/components/settings/integrations/types";
import Spinner from "@/components/table/shared/Spinner";

function RenderIntegrationItem({
  icon,
  title,
  subtitle,
  enabled = undefined,
  onConnect,
  onConfigure = undefined,
  onDisconnect = undefined,
  loadingDisconnect,
}: IntegrationItem) {
  return (
    <div className="integration-item-wrap">
      <div className="integration-logo">
        <img src={icon} alt="warning-icon" />
      </div>
      <div>
        <div className="integration-title">{title}</div>
        <div className="integration-subtitle">{subtitle}</div>
      </div>
      <div className="integration-button-container">
        {loadingDisconnect && <Spinner />}

        {!loadingDisconnect && !enabled && (
          <Button label="Connect" theme="primary" onClick={onConnect} />
        )}

        {!loadingDisconnect && enabled && onConfigure && (
          <Button label="Configure" theme="tertiary" onClick={onConfigure} />
        )}

        {!loadingDisconnect && enabled && (
          <Button label="Disconnect" theme="tertiary" onClick={onDisconnect} />
        )}
      </div>
    </div>
  );
}

export default RenderIntegrationItem;
