import { useQuery } from "@tanstack/react-query";

import { User } from "@/stores/types/user-types";

import { ListOfCompanyUsersQueryParams } from "../gen";
import { listOfCompanyUsers } from "../gen/axios/usersController";
import api from "../legacy/api";

export const USERS_QUERY_KEY = "users";
export const COMPANY_USERS_QUERY_KEY = "company_users";

export function useCompanyUsers(
  companyId: number,
  params?: ListOfCompanyUsersQueryParams,
) {
  return useQuery({
    queryKey: [COMPANY_USERS_QUERY_KEY, companyId, params],
    queryFn: () => listOfCompanyUsers(companyId, params),
    enabled: !!companyId,
  });
}

export function useUser(userId: number | "current") {
  return useQuery({
    queryKey: [USERS_QUERY_KEY, userId],
    queryFn: () => api.get<{ user: User }>(`/users/${userId}`),
    enabled: !!userId,
  });
}
