import classNames from "classnames";
import { observer } from "mobx-react";
import Pagination from "rc-pagination";
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'rc-p... Remove this comment to see the full error message
import EN_US from "rc-pagination/lib/locale/en_US";
import React, { useState } from "react";
import { PiCaretUpBold } from "react-icons/pi";

import { Field, RecordVersion, TableName } from "@/api";
import SectionTag from "@/components/table/shared/SectionTag";
import { useMainStore } from "@/contexts/Store";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";
import { IndexParams } from "@/stores/types/module-store-types";
import { Section } from "@/stores/types/section-tags";

import { useSortingAndPagination } from "../../../hooks/useSortingAndPagination";
import { isLockedRecord } from "../../../stores/helpers/RecordVersionHelper";
import { Icon } from "../../Elements";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import MenuActions from "../menu-actions/MenuActions";
import { customColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import ReorderableTable from "../shared/ReorderableTable";
import SectionPopupSettings from "../shared/SectionPopupSettings";
import Sharing from "../shared/Sharing";
import TableMenu from "../shared/tableMenu/TableMenu";

type Props = {
  recordVersions: RecordVersion[];
  currentScreen?: string;
  fields?: Field[];
  isDragAndDrop?: boolean;
  isDropdownOpen?: boolean;
  moduleWorkspaceID?: number;
  pageID?: number;
  sectionTag?: Section;
  tableName?: TableName;
};

function MarketingSection({
  recordVersions,
  currentScreen,
  fields,
  isDragAndDrop,
  moduleWorkspaceID,
  pageID,
  sectionTag,
  tableName,
  isDropdownOpen: isDropdownOpenProp,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(isDropdownOpenProp);
  const [selectMode, setSelectMode] = useState(false);

  // Variables
  const sectionTableID = `section-table-${sectionTag?.id || 0}`;
  const { activeWorkspace, isAdmin } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;

  const { user } = mainStore.users;
  const isActiveScreen = currentScreen === "Active";
  const isReviewScreen = currentScreen === "ReviewProcess";
  // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const page = mainStore.creatives.pages[pageID];
  const count =
    // @ts-expect-error TS(2339) FIXME: Property 'counts' does not exist on type '{}'.
    mainStore.creatives.data.counts && mainStore.creatives.data.counts[pageID];

  const arrowLeftImage = (
    <Icon name="chevronLeft" color="generalMidnightDark" />
  );
  const arrowRightImage = (
    <Icon name="chevronRight" color="generalMidnightDark" />
  );
  const { getTableFiltersParam } = useUpdateFilter();
  const filters = getTableFiltersParam();

  // Hooks
  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    customColumns({
      sectionTableID,
    });

  // Hooks
  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
  } = useSortingAndPagination({
    store: mainStore.creatives,
    sectionTagId: sectionTag?.id,
  });

  const onPageChange = async (newPage: number) => {
    const params: IndexParams = {};
    params.workspaceID = activeWorkspace?.id;
    if (pageID !== undefined) {
      params.pages = { [pageID]: newPage };
    }
    params.tableName = tableName;
    params.fromUseSortingAndPagination = true;
    if (sectionTag?.id) {
      params.sectionTagId = sectionTag.id;
    }

    if (filters) {
      params.tableFilters = filters;
    }

    if (sortByFieldName) {
      params.sortParams = {
        field_name: sortByFieldName,
        direction: sortByDirection || "ASC",
      };
    }

    await mainStore.creatives.index(params);
  };

  // Functions
  const resetMenuActions = () => {
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  const dropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const addNewMarketing = async () => {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    await mainStore.creatives.create(activeWorkspace.id, sectionTag?.id);
  };

  const renameClick = () => {
    setIsEditMode(true);
  };

  const deleteSectionTag = () => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    mainStore.sectionTags.delete(moduleWorkspaceID, sectionTag.id);
    mainStore.toast.setText("Section has been deleted!");
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderFirstBlock = (recordVersion) => {
    if (!isReviewScreen) {
      return;
    }

    if (recordVersion.status === "reviewing") {
      return <div className="marketing-status-letter reviewing">R</div>;
    }
    if (recordVersion.status === "request_change") {
      return <div className="marketing-status-letter request-change">C</div>;
    }

    return;
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
  const setAdditionalRowClass = (recordVersion) => {
    if (!isActiveScreen) {
      return null;
    }

    const isApprover = (
      mainStore.avroSchemas.valueForField("approvers", recordVersion?.data) ||
      []
    ).includes(user.id);
    const isSubmitter =
      mainStore.avroSchemas.firstValueForField(
        "submitter",
        recordVersion?.data,
      ) === user.id;

    if (recordVersion.status === "reviewing" && isApprover) {
      return "marketing-highlight-row-approver";
    }
    if (recordVersion.status === "request_change" && isSubmitter) {
      return "marketing-highlight-row-submitter";
    }

    return null;
  };

  // elements
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderRow = (recordVersion, idx) => {
    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        recordVersion={recordVersion}
        fields={fields}
        moduleWorkspaceID={moduleWorkspaceID}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
        currentTableName={tableName}
        moduleIdentifier="marketing"
        renderFirstBlock={renderFirstBlock(recordVersion)}
        // @ts-expect-error TS(2322) FIXME: Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
        isApprovedMarketing={isLockedRecord(recordVersion)}
        // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        additionalRowClassName={setAdditionalRowClass(recordVersion)}
        inSelectMenuActionMode={selectMode}
        isDraggable
        order={idx}
        isSorted={!!sortByFieldName}
      />
    );
  };

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const renderFields = fields.map((field) => {
    return (
      <ModuleTableColumn
        key={field.name}
        field={field}
        // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
        setSortByDirection={setSortByDirection}
        setSortByFieldName={setSortByFieldName}
        onDelete={deleteColumn}
        onRename={renameColumn}
      />
    );
  });

  return (
    <>
      {sectionTag && (
        <div className="tw-ml-[18px] tw-flex" data-testid="dropdown-section">
          <div
            className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-p-[7px] hover:tw-rounded-md hover:tw-bg-neutral-100"
            onClick={dropdownClick}
          >
            <PiCaretUpBold
              className={classNames(
                "tw-h-[18px] tw-w-[18px] tw-origin-center  tw-text-neutral-500",
                {
                  "tw-rotate-180": !isDropdownOpen,
                  "tw-rotate-0 ": isDropdownOpen,
                },
              )}
            />
          </div>
          {sectionTag?.title && (
            <SectionTag
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              sectionTagID={sectionTag?.id}
              sectionTagTitle={sectionTag?.title}
            />
          )}

          {sectionTag.editable && isActiveScreen && !isEditMode && (
            <>
              {isAdmin && (
                <Sharing
                  moduleWorkspaceID={moduleWorkspaceID}
                  objectID={sectionTag.id}
                  selectedDepartmentIDs={sectionTag.department_ids}
                  selectedUserIDs={sectionTag.user_ids}
                  tableName={tableName}
                  allowSharing
                />
              )}

              {canManageSections && (
                <SectionPopupSettings
                  onRename={renameClick}
                  onDelete={deleteSectionTag}
                />
              )}
            </>
          )}
        </div>
      )}

      {isDropdownOpen && (
        <div
          className={classNames("dropdown-table", { draggable: isDragAndDrop })}
          data-testid="procedures-dropdown-table"
          id={sectionTableID}
        >
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="procedures-list-column-first-blocks table-column-reorder">
                  {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
                  {fields.length > 0 && (
                    <TableMenu
                      setShowCheckbox={setSelectMode}
                      resetMenuActions={resetMenuActions}
                      selectMode={selectMode}
                      setSelectMode={setSelectMode}
                    />
                  )}
                </div>
                <div className="list-title-table-wrap">
                  {selectMode ? (
                    <div>
                      <MenuActions resetMenuActions={resetMenuActions} />
                    </div>
                  ) : (
                    <div className="list-title-table-wrap">
                      {renderFields}
                      <span className="stretch-cell" />
                    </div>
                  )}
                </div>
              </div>
              <AddColumnButton onAdd={createColumn} />
            </ul>
          </div>

          <ReorderableTable
            recordVersions={recordVersions}
            renderer={renderRow}
            sectionTag={sectionTag}
            isSorted={!!sortByFieldName}
            newRowName={getRecordName("marketing", moduleWorkspaces, true)}
            onAddNewRow={isActiveScreen ? addNewMarketing : undefined}
          />

          <Pagination
            onChange={onPageChange}
            current={page}
            total={count}
            prevIcon={arrowLeftImage}
            nextIcon={arrowRightImage}
            showPrevNextJumpers
            locale={EN_US}
          />
        </div>
      )}
    </>
  );
}

export default observer(MarketingSection);
