import { EventNotificationFormValues } from "@/features/notifications/pages/single/event/types";

export const initialCreateValues: EventNotificationFormValues = {
  enabled: true,
  // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string'.
  event: null,
  recipients: {
    department_ids: [],
    user_ids: [],
    columns: [],
  },
};
