import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";

import { attachmentFileType } from "@/api";
import { buildUploadPayload } from "@/components/helpers/AttachmentGroupsHelper";
import { useMainStore } from "@/contexts/Store";

import Spinner from "../Spinner";

type Props = {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion?: any;
  recordVersionID: number;
};

function ViewLogoCell({ fieldName, recordVersionID, recordVersion }: Props) {
  // Import Mobx stores
  const mainStore = useMainStore();

  // State
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  // Variables
  const { url } = mainStore.files;
  const attachmentGroups = recordVersion.attachment_groups.filter(
    // @ts-expect-error TS(7006) FIXME: Parameter 'attachmentGroup' implicitly has an 'any... Remove this comment to see the full error message
    (attachmentGroup) => attachmentGroup.field_name === fieldName,
  );
  const { currentLogo } = mainStore.clearbit;
  const dragDropClasses = classNames("drag-drop-wrap", {
    // @ts-expect-error TS(2339) FIXME: Property 'config' does not exist on type '{}'.
    active: currentLogo?.config || url,
  });
  const attachmentGroup = attachmentGroups[attachmentGroups.length - 1];
  const attachmentGroupID = attachmentGroup?.id;

  // Effects
  useEffect(() => {
    // @ts-expect-error TS(2339) FIXME: Property 'config' does not exist on type '{}'.
    if (currentLogo?.config) {
      setImagePreviewUrl(null);
    }
    // @ts-expect-error TS(2339) FIXME: Property 'config' does not exist on type '{}'.
  }, [currentLogo?.config]);

  useEffect(() => {
    if (!attachmentGroupID) {
      return;
    }

    const fetchFile = async () => {
      await mainStore.files.fetchFile({
        recordVersionID,
        attachmentGroupID,
        fieldName,
      });
    };

    fetchFile();
  }, [attachmentGroupID]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'signedIDs' implicitly has an 'any' type... Remove this comment to see the full error message
  async function handleAttachment(signedIDs) {
    if (!signedIDs) {
      return;
    }

    const fileType = attachmentFileType.direct_upload;
    const [signedID] = signedIDs;
    const externalURL = undefined;
    const deleteExistingAttachments = true;

    const payload = buildUploadPayload(
      fieldName,
      fileType,
      signedID,
      externalURL,
      deleteExistingAttachments,
    );
    recordVersionID &&
      payload &&
      (await mainStore.attachmentGroups.create({ recordVersionID, payload }));
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function showPreview(event) {
    const reader = new FileReader();
    const [file] = event.target.files;

    reader.onloadend = () => {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | ArrayBuffer | null' is ... Remove this comment to see the full error message
      setImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  }

  return (
    <div className="file-block" data-testid="view-logo">
      <DirectUploadProvider
        onSuccess={(signedIDs) => handleAttachment(signedIDs)}
        render={({ handleUpload, uploads }) => (
          <div>
            <div className={dragDropClasses}>
              <div className="drag-drop-block">
                {uploads.length === 0 && (
                  <div className="input-container">
                    {/* @ts-expect-error TS(2339) FIXME: Property 'config' does not exist on type '{}'. */}
                    {!url && !imagePreviewUrl && !currentLogo?.config && (
                      <div className="empty-logo">
                        Logo will show here when Website
                        <br /> name has been selected
                      </div>
                    )}
                    {/* @ts-expect-error TS(2339) FIXME: Property 'config' does not exist on type '{}'. */}
                    {(imagePreviewUrl || currentLogo?.config || url) && (
                      <img
                        className="logo-img"
                        data-testid="build-vendor-uploaded-logo"
                        // @ts-expect-error TS(2339) FIXME: Property 'config' does not exist on type '{}'.
                        src={imagePreviewUrl || currentLogo?.config?.url || url}
                        alt="logo"
                      />
                    )}
                    <input
                      type="file"
                      data-testid="direct-file-input"
                      accept=".jpg,.jpeg,.png"
                      onDrop={(event) => showPreview(event)}
                      onChange={(event) => {
                        event.preventDefault();
                        showPreview(event);
                        handleUpload(event.currentTarget.files);
                      }}
                    />
                  </div>
                )}

                {
                  // @ts-expect-error TS(7006) FIXME: Parameter 'upload' implicitly has an 'any' type.
                  uploads.map((upload) => {
                    switch (upload.state) {
                      case "waiting":
                        return (
                          <div key={upload.id} className="uploading">
                            <p>0%</p>
                            <Spinner />
                          </div>
                        );
                      case "uploading":
                        return (
                          <div key={upload.id} className="uploading">
                            <p>{Math.round(upload.progress)}%</p>
                            <Spinner />
                          </div>
                        );
                      case "error":
                        return (
                          <p key={upload.id}>
                            Error uploading {upload.file.name}: {upload.error}
                          </p>
                        );
                      default:
                        return null;
                    }
                  })
                }
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
}

export default observer(ViewLogoCell);
