import dayjs from "dayjs";
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'down... Remove this comment to see the full error message
import download from "downloadjs";
import html2canvas from "html2canvas";
import * as htmlToImage from "html-to-image";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL, graphOptions } from "../../../components/constants";
import { formJsonFromTable } from "../common/MetricsPage/helpers";

export const legendHeightPlugin = {
  id: "legend-spacing",
  // @ts-expect-error TS(7006) FIXME: Parameter 'chart' implicitly has an 'any' type.
  beforeInit(chart) {
    const originalFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 40;
    };
  },
};

// @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export const floatingBarData = (data) => {
  let currentCount = 0;

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  return data.map((item) => {
    if (item.count > 0) {
      const lowerLimit = (currentCount + item.count) / 30;
      const upperLimit = currentCount + item.count - item.count / 30;

      return { ...item, count: [lowerLimit, upperLimit] };
    }
    currentCount += item.count;
    return item;
  });
};

// @ts-expect-error TS(7006) FIXME: Parameter 'recordVersions' implicitly has an 'any'... Remove this comment to see the full error message
const getTimelineChartLabels = (recordVersions) =>
  // @ts-expect-error TS(7031) FIXME: Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
  recordVersions.map(({ data }) => {
    const testName = data.test_name ? data.test_name?.value : "";
    const startDate = data.start_date
      ? dayjs(data.start_date.value).format("MM/DD/YYYY")
      : "";
    const endDate = data.target_completion_date
      ? dayjs(data.target_completion_date.value).format("MM/DD/YYYY")
      : "";

    return [testName, "", `${startDate} - ${endDate}`];
  });

// @ts-expect-error TS(7006) FIXME: Parameter 'recordVersions' implicitly has an 'any'... Remove this comment to see the full error message
const getTimelineChartDatasets = (recordVersions) =>
  // @ts-expect-error TS(7031) FIXME: Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
  recordVersions.map(({ data }) => {
    const startDate = data.start_date ? data.start_date.value : "";
    const endDate = data.target_completion_date
      ? data.target_completion_date.value
      : "";

    return [startDate, endDate];
  });

// @ts-expect-error TS(7006) FIXME: Parameter 'recordVersions' implicitly has an 'any'... Remove this comment to see the full error message
export const qaTimelineChartData = (recordVersions, fromValue, toValue) => {
  const chartOptions = {
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: true,
        },
        ticks: {
          precision: 0,
        },
      },
      x: {
        min: dayjs(fromValue).startOf("month").format("YYYY-MM-DD"),
        max: dayjs(toValue).startOf("month").format("YYYY-MM-DD"),
        type: "time",
        time: {
          unit: "month",
        },
        grid: {
          borderDash: [6],
        },
      },
    },
  };

  const options = {};

  return {
    chartData: {
      labels: getTimelineChartLabels(recordVersions),
      datasets: [
        {
          data: getTimelineChartDatasets(recordVersions),
          stack: "source",
          categoryPercentage: 0.5,
          backgroundColor: [
            "#9B9BED",
            "#97DAEE",
            "#E3AEFF",
            "#6D8CF9",
            "#FFBECA",
            "#E99FFB",
            "#6217C1",
          ],
          maxBarThickness: 150,
          borderRadius: 0,
          borderSkipped: false,
        },
      ],
    },
    options: { ...options, ...graphOptions, ...chartOptions },
  };
};

// @ts-expect-error TS(7006) FIXME: Parameter 'mainStore' implicitly has an 'any' type... Remove this comment to see the full error message
export const downloadChart = async (mainStore, elementId, name) => {
  window.devicePixelRatio = 2;
  const element = document.getElementById(elementId);
  if (element) {
    const canvas = await html2canvas(element, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
    });

    const a = document.createElement("a");
    const url_base64 = canvas.toDataURL("image/png");

    a.href = url_base64;
    a.download = name;
    a.click();
  } else {
    mainStore.toast.setErrorText("Unable to download chart");
  }
};

// @ts-expect-error TS(7006) FIXME: Parameter 'mainStore' implicitly has an 'any' type... Remove this comment to see the full error message
export const downloadGraph = async (mainStore, chartId, chartTitle) => {
  // @ts-expect-error TS(2322) FIXME: Type 'HTMLElement | null' is not assignable to typ... Remove this comment to see the full error message
  const chart: HTMLElement = document.getElementById(`${chartId}`);
  try {
    const dataUrl = await htmlToImage.toJpeg(chart, {
      backgroundColor: "white",
    });
    download(dataUrl, `${chartTitle}`);
    mainStore.toast.setInfoText("Chart image downloaded");
  } catch (err) {
    mainStore.toast.setErrorText("Unable to download chart image");
  }
};

// @ts-expect-error TS(7006) FIXME: Parameter 'mainStore' implicitly has an 'any' type... Remove this comment to see the full error message
export const copyGraph = async (mainStore, chartId) => {
  // @ts-expect-error TS(2322) FIXME: Type 'HTMLElement | null' is not assignable to typ... Remove this comment to see the full error message
  const chart: HTMLElement = document.getElementById(`${chartId}`);
  try {
    const imageBlob = await htmlToImage.toBlob(chart, {
      backgroundColor: "white",
    });
    const clipboardItem = new ClipboardItem({
      // @ts-expect-error TS(2322) FIXME: Type 'Blob | null' is not assignable to type 'stri... Remove this comment to see the full error message
      "image/png": imageBlob,
    });
    navigator.clipboard.write([clipboardItem]);
    mainStore.toast.setInfoText("Chart image copied to clipboard");
  } catch (err) {
    mainStore.toast.setErrorText("Unable to copy chart image to clipboard");
  }
};

export const downloadTableXlsx = async (
  // @ts-expect-error TS(7006) FIXME: Parameter 'mainStore' implicitly has an 'any' type... Remove this comment to see the full error message
  mainStore,
  chartId: string,
  chartTitle: string,
  workspaceID: number,
) => {
  // @ts-expect-error TS(2322) FIXME: Type 'HTMLElement | null' is not assignable to typ... Remove this comment to see the full error message
  const table: HTMLElement = document.getElementById(chartId);
  const tableObject: object = formJsonFromTable(table);

  const params = {
    json_data: JSON.stringify(tableObject),
    workspace_id: workspaceID,
  };

  try {
    mainStore.toast.setText("Processing XLSX download...");

    const response = await legacyApi({
      method: "POST",
      url: `${API_URL}/report/export_to_excel`,
      headers: mainStore.getHeaders(),
      data: params,
    });

    // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
    if (response.isAxiosError) {
      mainStore.toast.setErrorFromResponse(
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        response.response,
        "There was a problem with the download. Try Copy Table Data as an alternative.",
      );
      return;
    }

    const link: HTMLAnchorElement = document.createElement("a");
    const blob: Blob = new Blob([new Uint8Array(response.data)], {
      type: "application/octet-stream",
    });

    link.href = URL.createObjectURL(blob);
    link.download = `${chartTitle}.xlsx`;
    link.click();
    mainStore.toast.setInfoText("XLSX downloaded");
  } catch (err) {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    mainStore.toast.setErrorText(err.response);
  }
};

export const downloadTableImage = async (
  // @ts-expect-error TS(7006) FIXME: Parameter 'mainStore' implicitly has an 'any' type... Remove this comment to see the full error message
  mainStore,
  chartId: string,
  chartTitle: string,
) => {
  // @ts-expect-error TS(2322) FIXME: Type 'HTMLElement | null' is not assignable to typ... Remove this comment to see the full error message
  const table: HTMLElement = document.getElementById(chartId);
  try {
    const dataUrl = await htmlToImage.toJpeg(table, {
      backgroundColor: "white",
      style: { margin: "0" },
    });
    download(dataUrl, `${chartTitle}`);
    mainStore.toast.setInfoText("Table image downloaded");
  } catch (err) {
    mainStore.toast.setErrorText("Unable to copy table image to clipboard");
  }
};

// @ts-expect-error TS(7006) FIXME: Parameter 'mainStore' implicitly has an 'any' type... Remove this comment to see the full error message
export const copyTableImage = async (mainStore, chartId: string) => {
  // @ts-expect-error TS(2322) FIXME: Type 'HTMLElement | null' is not assignable to typ... Remove this comment to see the full error message
  const table: HTMLElement = document.getElementById(chartId);
  try {
    // @ts-expect-error TS(2322) FIXME: Type 'Blob | null' is not assignable to type 'Blob... Remove this comment to see the full error message
    const imageBlob: Blob = await htmlToImage.toBlob(table, {
      backgroundColor: "white",
      style: { margin: "0" },
    });

    const clipboardItem: ClipboardItem = await new ClipboardItem({
      "image/png": imageBlob,
    });

    navigator.clipboard.write([clipboardItem]);
    mainStore.toast.setInfoText("Table image copied to clipboard");
  } catch (err) {
    mainStore.toast.setErrorText("Unable to copy table image to clipboard");
  }
};

// @ts-expect-error TS(7006) FIXME: Parameter 'mainStore' implicitly has an 'any' type... Remove this comment to see the full error message
export const copyTableData = async (mainStore, chartId: string) => {
  // @ts-expect-error TS(2322) FIXME: Type 'HTMLElement | null' is not assignable to typ... Remove this comment to see the full error message
  const table: HTMLElement = document.getElementById(chartId);
  try {
    const clipboardItem: ClipboardItem = await new ClipboardItem({
      "text/html": new Blob([table.outerHTML], { type: "text/html" }),
      "text/plain": new Blob([table.innerText], { type: "text/plain" }),
    });

    navigator.clipboard.write([clipboardItem]);
    mainStore.toast.setInfoText("Table data copied to clipboard");
  } catch (err) {
    mainStore.toast.setErrorText("Unable to copy table data to clipboard");
  }
};

// @ts-expect-error TS(7006) FIXME: Parameter 'yAxesLabels' implicitly has an 'any' ty... Remove this comment to see the full error message
export const lineChartScaleOptions = (yAxesLabels) => {
  return yAxesLabels?.length > 0
    ? {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: true,
            borderDash: [6],
            drawBorder: false,
          },
          title: {
            display: true,
            text: yAxesLabels[0],
            color: "#8080A3",
            font: {
              size: 12,
              weight: "bold",
            },
          },
          type: "linear" as const,
          display: true,
          position: "left" as const,
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
        },
        y1: {
          grid: {
            display: true,
            borderDash: [6],
            drawBorder: false,
          },
          title: {
            display: true,
            text: yAxesLabels[1],
            color: "#8080A3",
            font: {
              size: 12,
              weight: "bold",
            },
          },
          type: "linear" as const,
          display: true,

          position: "right" as const,
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
        },
      }
    : {
        y: {
          grid: {
            display: true,
            borderDash: [6],
            drawBorder: false,
          },
          type: "linear" as const,
          display: true,
          position: "left" as const,
          beginAtZero: true,
          ticks: {
            stepSize: 1,
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      };
};
