import { useQueryClient } from "@tanstack/react-query";
import React, { useRef, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { AccountsApi } from "@/api/legacy/accounts/AccountsApi";
import { ACCOUNTS_QUERY_KEY } from "@/api/queries/accounts";
import { Button, Typography } from "@/components/Elements";
import ModalWrapper from "@/components/shared/ModalWrapper";
import { useMainStore } from "@/contexts/Store";

const AccountImport = () => {
  const mainStore = useMainStore();
  const fileInputRef = useRef<HTMLInputElement>();
  const [showPopup, setShowPopup] = useState(false);
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();
  const queryClient = useQueryClient();

  function onPopupOpen() {
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
  }

  const onCreation = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setShowPopup(false);

      if (!event.target.files) {
        return;
      }

      await AccountsApi.importAccounts(
        Number(workspace_id),
        event.target.files[0],
      );
      mainStore.toast.setInfoText("Successfully imported accounts");

      queryClient.invalidateQueries({
        queryKey: ACCOUNTS_QUERY_KEY.all,
      });
    } catch (err) {
      mainStore.toast.setErrorFromResponse(err);
    }
  };

  return (
    <Popup
      on="click"
      position="bottom right"
      open={showPopup}
      onOpen={() => onPopupOpen()}
      onClose={() => onPopupClose()}
      trigger={
        <div>
          <Button label="Import" theme="tertiary" />
        </div>
      }
    >
      {
        <ModalWrapper width={400}>
          <div className="tw-flex tw-items-start">
            <div className="tw-mr-3 tw-flex tw-min-h-5 tw-min-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-400 tw-text-white">
              1
            </div>
            <div>
              <div className={"tw-mb-2"}>
                <Typography
                  weight="semiBold"
                  size="de"
                  label="Download XLSX file"
                  color="generalMidnightDark"
                />
              </div>
              <Button
                icon="download"
                label="Download"
                onClick={() =>
                  AccountsApi.downloadTemplate(Number(workspace_id))
                }
              />
            </div>
          </div>
          <div className="tw-mt-5 tw-flex tw-items-start">
            <div className="tw-mr-3 tw-flex tw-min-h-5 tw-min-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-400 tw-text-white">
              2
            </div>
            <div>
              <div className={"tw-mb-2"}>
                <Typography
                  weight="semiBold"
                  size="de"
                  label="Add / edit information in XLSX template"
                  color="generalMidnightDark"
                  className="tw-mb-2"
                />
              </div>
              <Typography
                size="de"
                label="The first line in your CSV/XLSX file must contain the column headers as shown in the template"
              />
            </div>
          </div>
          <div className="tw-mt-5 tw-flex tw-items-start">
            <div className="tw-mr-3 tw-flex tw-min-h-5 tw-min-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-400 tw-text-white">
              3
            </div>
            <div>
              <div className={"tw-mb-2"}>
                <Typography
                  weight="semiBold"
                  size="de"
                  label="Import the updated file"
                  color="generalMidnightDark"
                  className="tw-mb-2"
                />
              </div>
              <DirectUploadProvider
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                onSuccess={null}
                render={() => (
                  <div>
                    <Button
                      icon="upload"
                      label="Import XLSX"
                      onClick={() => fileInputRef.current?.click()}
                    />
                    <input
                      // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<HTMLInputElement | undefine... Remove this comment to see the full error message
                      ref={fileInputRef}
                      type="file"
                      onChange={onCreation}
                      className="tw-hidden"
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </ModalWrapper>
      }
    </Popup>
  );
};

export default AccountImport;
