import "./ZendeskConnect.scss";

import { observer } from "mobx-react";
import React from "react";
import { useForm } from "react-hook-form";

import * as ZendeskConfigAPI from "@/api/legacy/zendesk-config";
import { Button, Flex, Typography } from "@/components/Elements";
import ErrorText from "@/components/form/react-hook-form/ErrorText";
import TextInput from "@/components/form/react-hook-form/TextInput";
import { useMainStore } from "@/contexts/Store";
import logo from "@/images/logo.svg";
import {
  ModuleIdentifier,
  TopLevelModule,
} from "@/stores/types/module-workspaces-types";

type ZendeskConnectFormValues = {
  zendeskSubdomain: string;
  integratedModule: ModuleIdentifier;
};

const initialValues: ZendeskConnectFormValues = {
  zendeskSubdomain: "",
  integratedModule: TopLevelModule.ZENDESK,
};

function ZendeskConnect() {
  const mainStore = useMainStore();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    defaultValues: initialValues,
  });

  const onSubmit = async (data: ZendeskConnectFormValues) => {
    try {
      const response = await ZendeskConfigAPI.connect({
        // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
        workspace_id: mainStore.context.workspaceID,
        subdomain: data.zendeskSubdomain,
        module_identifier: data.integratedModule,
        user_app_host: window.location.origin,
      });

      reset({}, { keepValues: true });

      window.location.assign(response.url);
    } catch (error) {
      mainStore.toast.setErrorText(
        "An unexpected error occurred while connecting to zendesk.",
      );
    }
  };

  return (
    <Flex column alignCenter className="zendesk-connect">
      <form className="zendesk-connect__form" onSubmit={handleSubmit(onSubmit)}>
        <Flex column alignCenter className="zendesk-connect__card" rowGap={24}>
          <img src={logo} alt="logo" className="zendesk-connect__logo" />
          <Flex column rowGap={4} alignCenter>
            <Typography
              size="xl"
              label="Zendesk Integration"
              color="generalMidnightDark"
            />
            <Typography
              className="zendesk-connect__description"
              size="sm"
              label="Your subdomain is part of the URL address you use to access your Zendesk account. An example would be https://themis123.zendesk.com, where “themis123” is your subdomain."
            />
          </Flex>
          <Flex column rowGap={8} className="zendesk-connect__input">
            <Flex column rowGap={4}>
              <Typography
                label="Zendesk Subdomain"
                size="xs"
                weight="semiBold"
              />
              <TextInput
                data-testid="subdomain"
                label="Zendesk Subdomain"
                name="zendeskSubdomain"
                register={register}
                placeHolder="- Type Here -"
              />
              {/* @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
              <ErrorText message={errors.zendeskSubdomain?.message} />
            </Flex>
          </Flex>
          <Button
            type="submit"
            label={isSubmitting ? "Connecting..." : "Connect"}
            disabled={isSubmitting || !isDirty}
          />
        </Flex>
      </form>
    </Flex>
  );
}

export default observer(ZendeskConnect);
