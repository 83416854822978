import { MiniTag, MiniTagProps } from "@themis/ui";
import { capitalize } from "lodash";
import React from "react";

import { issueManagementRecordRiskLevel } from "@/api";

const RISK_LEVEL_COLOR_MAP: Record<string, MiniTagProps["color"]> = {
  [issueManagementRecordRiskLevel.low]: "green",
  [issueManagementRecordRiskLevel.medium]: "yellow",
  [issueManagementRecordRiskLevel.high]: "red",
} as const;

export function IssueManagementRiskLevelTag({
  riskLevel,
}: {
  riskLevel: string;
}) {
  return (
    <MiniTag color={RISK_LEVEL_COLOR_MAP[riskLevel]}>
      {capitalize(riskLevel)}
    </MiniTag>
  );
}
