import { ConfirmationPopup, IconButton, useToast } from "@themis/ui";
import axios from "axios";
import React, { useState } from "react";
import { PiDownloadSimpleBold, PiTrashSimpleBold } from "react-icons/pi";
import { useParams } from "react-router-dom";

import { File } from "@/api";
import { useDeleteDocument } from "@/api/queries/moduleAssessments";
import { downloadFile } from "@/stores/helpers/AttachmentHelper";

function DocumentsActionCell({
  documentId,
  documentFile,
}: {
  documentId: number;
  documentFile: File;
}) {
  const toast = useToast();
  const { moduleAssessmentId } = useParams<{
    moduleAssessmentId: string;
  }>();
  const { mutate: deleteDocument } = useDeleteDocument({
    moduleAssessmentId: Number(moduleAssessmentId),
    onSuccess: () => {
      toast({
        content: "Document has been removed!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to remove document!",
        variant: "error",
      });
    },
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  function handleDelete() {
    setIsConfirmationOpen(false);
    deleteDocument(documentId);
  }

  function handleClickDelete() {
    setIsConfirmationOpen(true);
  }

  function handleCancelDelete() {
    setIsConfirmationOpen(false);
  }

  async function handleClickDownload() {
    if (!documentFile) {
      return;
    }

    const downloadResponse = await axios({
      method: "GET",
      url: documentFile.file_url,
      responseType: "blob",
    });

    const blob = downloadResponse.data;
    downloadFile(blob, documentFile.file_name);
  }

  return (
    <>
      <IconButton
        Icon={PiDownloadSimpleBold}
        size="lg"
        color="tertiary"
        onClick={handleClickDownload}
      />
      <ConfirmationPopup
        anchor
        align="end"
        title="Delete Document"
        content="Actions are non-reversible. Are you sure you want to proceed?"
        open={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
      >
        <IconButton
          Icon={PiTrashSimpleBold}
          size="lg"
          color="tertiary"
          onClick={handleClickDelete}
        />
      </ConfirmationPopup>
    </>
  );
}

export default DocumentsActionCell;
