import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { useMainStore } from "@/contexts/Store";

import Textarea from "./Textarea";

type Props = {
  fieldName: string;
  errorMessage?: string;
  hasErrorClass?: string;
  hasErrors?: boolean;
  initialValue?: number | string;
  locked: boolean;
  maxLength?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange?: (...args: any[]) => any;
  recordVersionID?: number;
  hasReadWriteAccess?: boolean;
};

function TextareaCell({
  fieldName,
  recordVersionID,
  initialValue,
  locked = false,
  hasErrors,
  maxLength,
  errorMessage,
  hasErrorClass = "has-errors",
  onDataChange,
  hasReadWriteAccess,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const { isCurrentWorkspaceArchived } = mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly =
    !hasReadWriteAccess &&
    (!hasModuleWriteAccess || isCurrentWorkspaceArchived);

  // State
  const [value, setValue] = useState("");

  // Effects
  useEffect(() => {
    if (initialValue) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | number' is not assignab... Remove this comment to see the full error message
      setValue(initialValue);
    } else {
      setValue("");
    }
  }, [initialValue]);

  function onBlur() {
    persistData();
  }

  function persistData() {
    if (value === initialValue) {
      return;
    }

    const serializeValue = mainStore.avroSchemas.serializeValue(
      fieldName,
      value,
    );

    if (typeof onDataChange === "function") {
      onDataChange(fieldName, serializeValue);
    } else {
      mainStore.recordVersions.update({
        fieldName,
        recordVersionID,
        value: serializeValue,
      });
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleChange(event) {
    setValue(event.target.value);
  }

  const textareaClassNames = classNames(
    "detail-long-text",
    hasErrorClass && { [hasErrorClass]: hasErrors },
    {
      "table-cell--disabled": isReadOnly,
    },
  );

  let textareaPlaceholder;

  if (errorMessage === undefined) {
    if (locked) {
      textareaPlaceholder = "N/A";
    } else {
      textareaPlaceholder = "Type here";
    }
  } else {
    textareaPlaceholder = errorMessage;
  }

  return (
    <div className="textarea-block">
      <Textarea
        disabled={locked}
        onChange={handleChange}
        onBlur={onBlur}
        value={value}
        placeholder={textareaPlaceholder}
        maxLength={maxLength}
        className={textareaClassNames}
        dataTestID="textarea-input"
      />
    </div>
  );
}

export default observer(TextareaCell);
