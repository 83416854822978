import { ReactPortal, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
function PortalSlideMenu({ children }) {
  const el = useRef(document.createElement("div"));
  const [dynamic] = useState(!el.current.parentElement);

  useEffect(() => {
    if (dynamic) {
      document.body.appendChild(el.current);
    }
    return () => {
      if (dynamic && el.current.parentElement) {
        el.current.parentElement.removeChild(el.current);
      }
    };
  }, []);

  return createPortal(children, el.current) as ReactPortal;
}

export default PortalSlideMenu;
