import "./flex-dashboard-content-wrapper.scss";

import React, { HTMLProps, PropsWithChildren, ReactNode } from "react";

import ModuleHeader from "../ModuleHeader/ModuleHeader";

type Props = HTMLProps<HTMLDivElement> &
  PropsWithChildren & {
    ModuleHeaderContent?: ReactNode;
  };

export default function FlexDashboardContentWrapper(props: Props) {
  return (
    <div className="flex-dashboard-content-wrapper">
      {props.ModuleHeaderContent && (
        <ModuleHeader>{props.ModuleHeaderContent}</ModuleHeader>
      )}
      {props.children}
    </div>
  );
}
