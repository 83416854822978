import { IconButton, TextInput } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiCaretUpBold, PiCheckBold, PiXBold } from "react-icons/pi";

import { useMainStore } from "@/contexts/Store";

type Props = {
  initialValue?: string;
  moduleWorkspaceID?: number;
  takeAfterAction?: () => void;
  onCancel?: () => void;
};

function NewSectionTag({
  initialValue,
  moduleWorkspaceID,
  takeAfterAction = () => {},
  onCancel = () => {},
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [title, setTitle] = useState("");

  // Effects
  useEffect(() => {
    if (
      mainStore.sectionTags.current &&
      mainStore.sectionTags.current.title === title
    ) {
      setTitle("");
    } else {
      setTitle("eg. Finance / Project ABC / Committee Charters");
    }
  }, [mainStore.sectionTags.current]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function onTitleChange(event) {
    setTitle(event.target.value);
  }

  async function createTag() {
    await mainStore.sectionTags.create(moduleWorkspaceID, { title });
    takeAfterAction();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  async function handleKeyDown(event) {
    if (event.key === "Enter") {
      if (title && title.length > 0 && moduleWorkspaceID) {
        await createTag();
      }
    }
  }

  async function doneClick() {
    if (title && title.length > 0 && moduleWorkspaceID) {
      await createTag();
    }
  }

  return (
    <div
      className="tw-sticky tw-left-[18px] tw-mb-2 tw-flex tw-gap-2"
      data-testid="new-section-tag"
    >
      <div className="tw-flex tw-items-center tw-justify-center tw-p-[7px] hover:tw-rounded-md hover:tw-bg-neutral-100">
        <PiCaretUpBold
          className={classNames(
            "tw-h-[18px] tw-w-[18px] tw-origin-center tw-text-neutral-500",
          )}
        />
      </div>
      <div className="tw-flex tw-items-center tw-justify-center tw-gap-2">
        <TextInput
          data-testid="new-section-name-input"
          value={title}
          onChange={onTitleChange}
          onKeyDown={handleKeyDown}
          defaultValue={initialValue}
          className="tw-w-[304px]"
          size="md"
          autoFocus
        />
        <IconButton
          data-testid="save-new-section-button"
          color="secondary"
          Icon={PiCheckBold}
          onClick={doneClick}
          size="md"
        />
        <IconButton
          color="tertiary"
          Icon={PiXBold}
          onClick={onCancel}
          size="md"
        />
      </div>
    </div>
  );
}

export default observer(NewSectionTag);
