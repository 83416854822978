import React, { useState } from "react";

type Props = {
  defaultTitle: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (...args: any[]) => any;
};

const FolderRenameForm = ({ defaultTitle, onSubmit }: Props) => {
  const [title, setTitle] = useState(defaultTitle);

  const isSubmitDisabled = !title || title === defaultTitle;

  const handleSubmit = () => onSubmit({ title });

  return (
    <div
      className="table-dropdown folder-form rename-form"
      data-testid="folder-rename-form"
    >
      <div className="form-row">
        <div className="form-label">Folder Name</div>
        <div className="form-element">
          <input
            type="text"
            placeholder="Enter a name"
            value={title}
            data-testid="folder-name-input"
            onChange={(evt) => setTitle(evt.target.value)}
          />
        </div>
      </div>

      <div className="form-row">
        <button
          className="form-submit-button"
          data-testid="form-submit-button"
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default FolderRenameForm;
