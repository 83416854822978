import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { useMainStore } from "@/contexts/Store";
import { SelectOption } from "@/stores/types/field-types";

import ItemsSelect from "../ItemsSelect";

type Props = {
  name: string;
  onChange: (name: string, value?: SelectOption) => void;
  value?: { id: number };
};

function DepartmentsSelect({ name, value, onChange }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Functions
  function refreshDepartments() {
    if (company?.id) {
      mainStore.departments.index(company.id);
    }
  }

  function handleChange(newName: string, newValue: number | string) {
    onChange(
      newName,
      departments.find((item) => item.id === newValue),
    );
  }

  // Variables
  const { company } = mainStore.companies;
  const { departments } = mainStore.departments;
  const options = departments.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  // Effects
  useEffect(refreshDepartments, [company]);

  return (
    <ItemsSelect
      name={name}
      value={value?.id}
      options={options}
      onChange={handleChange}
      placeholder="- Optional -"
      onPopUpOpen={refreshDepartments}
    />
  );
}

export default observer(DepartmentsSelect);
