import { HeaderTabs } from "@themis/ui";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ControlLibraryApplyButton from "@/components/dashboard/actions/ControlLibraryApplyButton";
import {
  getSelectedTab,
  getTabByKey,
  getTabByURL,
} from "@/components/helpers/Tabs";
import ControlMappingLibraryTemplateButton from "@/components/table/control-mapping/ControlMappingLibraryTemplateButton";
import { useMainStore } from "@/contexts/Store";
import { useTableData } from "@/hooks/useTableData";

import Table from "../Table";
import ControlMappingTable from "./ControlMappingTable";
import Library from "./Library";
import LibraryImport from "./LibraryImport";

type Props = {
  defaultScreen?: string;
};

const TABS = [
  {
    name: "Active",
    key: "active",
    value: "/modules/control-mapping",
    dataTestId: "cm-tab-Active-trigger",
  },
  {
    name: "Completed",
    key: "completed",
    value: "/modules/control-mapping/completed",
    dataTestId: "cm-tab-Completed-trigger",
  },
  {
    name: "Company Library",
    key: "company-library",
    value: "/modules/control-mapping/company-library",
    dataTestId: "cm-tab-ControlLibrary-trigger",
  },
  {
    name: "Themis Control Library",
    key: "themis-control-library",
    value: "/modules/control-mapping/themis-control-library",
    dataTestId: "cm-tab-ThemisControlLibrary-trigger",
  },
];

function ControlMapping({ defaultScreen = "active" }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  useTableData();

  // State
  const [activeScreen, setActiveScreen] = useState(defaultScreen);

  // Variables
  const history = useHistory();
  const location = useLocation();
  const { company } = mainStore.companies;

  // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
  const moduleWorkspaceID = mainStore.controlMappings.data?.module_workspace_id;
  const { workspaceID, isIW: isInternalWorkspace } = mainStore.context;

  // effects
  useEffect(() => {
    if (!company?.id || mainStore.controlCategories.controlCategories?.length) {
      return;
    }

    mainStore.controlCategories.index(company.id);
  }, [company]);

  useEffect(() => {
    if (
      !company?.id ||
      mainStore.controlMappingTypes.controlMappingTypes?.length
    ) {
      return;
    }

    mainStore.controlMappingTypes.index(company.id);
  }, [company]);

  useEffect(() => {
    const selectedTab = getSelectedTab(TABS, false);

    if (!selectedTab) {
      return;
    }

    const newScreen = selectedTab.key;

    if (activeScreen === newScreen) {
      return;
    }

    setActiveScreen(newScreen);
  }, [location.pathname]);

  function handleScreenChange(newScreen: string) {
    mainStore.controlMappings.index({
      workspaceID,
      tab: newScreen,
    });

    setActiveScreen(newScreen);

    const selectedTab = getTabByKey(TABS, newScreen);

    history.push(`/workspaces/${workspaceID}${selectedTab?.value}`);
  }

  const tabs = (
    <HeaderTabs
      tabs={TABS}
      selectedTab={getSelectedTab(TABS)?.value || ""}
      onSelectTab={(url) =>
        handleScreenChange(getTabByURL(TABS, url)!.key || "")
      }
      dataTestId="cm-main-tabs"
    />
  );

  const renderHeader = (
    <div className="table-header-wrap">
      {tabs}
      <div className="import-export-buttons-container">
        {activeScreen === "company-library" && (
          <>
            {isInternalWorkspace && (
              <LibraryImport moduleWorkspaceID={moduleWorkspaceID} />
            )}
            <ControlLibraryApplyButton
              libraryType="company"
              handleScreenChange={handleScreenChange}
            />
          </>
        )}
        {activeScreen === "themis-control-library" && (
          <>
            <ControlLibraryApplyButton
              libraryType="themis"
              handleScreenChange={handleScreenChange}
            />
            {isInternalWorkspace && (
              <ControlMappingLibraryTemplateButton
                handleScreenChange={handleScreenChange}
              />
            )}
          </>
        )}
      </div>
    </div>
  );

  return (
    <Table>
      {activeScreen !== "active" &&
        activeScreen !== "completed" &&
        renderHeader}
      {activeScreen === "active" && (
        <ControlMappingTable tabs={tabs} completed={false} lockTab={false} />
      )}
      {activeScreen === "completed" && (
        <ControlMappingTable tabs={tabs} completed lockTab />
      )}
      {activeScreen === "themis-control-library" && (
        <Library moduleWorkspaceID={moduleWorkspaceID} libraryType="themis" />
      )}
      {activeScreen === "company-library" && (
        <Library moduleWorkspaceID={moduleWorkspaceID} libraryType="company" />
      )}
      {(activeScreen === "company-library" ||
        activeScreen === "themis-control-library") && (
        <>
          {/* @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message */}
          {activeScreen === "templates" && isInternalWorkspace && (
            <LibraryImport moduleWorkspaceID={moduleWorkspaceID} />
          )}
        </>
      )}
    </Table>
  );
}

export default observer(ControlMapping);
