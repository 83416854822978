import axios, { AxiosError } from "axios";

import { containsError, RailsErrors } from "@/utils/errors";

import { API_URL } from "../../components/constants";

export const MFA_ERROR_MESSAGE = "MFA challenge in process";

const api = axios.create({ baseURL: API_URL });

api.interceptors.request.use(async (request) => {
  const { default: mainStore } = await import("../../stores/Main");

  const contentType = request.headers.getContentType();
  if (!contentType) {
    request.headers["Content-Type"] = "application/json";
  }

  const csrfTokenElement = document.querySelector<HTMLMetaElement>(
    'meta[name="csrf-token"]',
  );
  if (csrfTokenElement) {
    request.headers["X-CSRF-Token"] = csrfTokenElement.content;
  }

  const accessToken = mainStore.webSessions.getAccessToken();
  if (accessToken && accessToken.length > 0) {
    request.headers["X-Session-Token"] = accessToken;
  }

  return request;
});

api.interceptors.response.use(undefined, async (e: Error | AxiosError) => {
  const { default: mainStore } = await import("../../stores/Main");

  if (axios.isAxiosError<{ errors: RailsErrors }>(e)) {
    const { response } = e;
    const errors = response?.data.errors || {};
    const status = response?.status;
    const responseConfig = response?.config;
    const baseURL = responseConfig?.baseURL;
    const url = responseConfig?.url;

    if (status === 401) {
      // check on why this returns empty object? copied from webSessionsStore
      if (containsError(errors, "base", MFA_ERROR_MESSAGE)) {
        return {};
      }

      if (url && !url.includes("/forms/questionnaires/assessment-templates")) {
        mainStore.webSessions.handleAuthenticationError();
      }
    } else if (status === 403) {
      // GET /api/react/record_versions/:id should NOT show toast on 403
      const expression = new RegExp(/^record_versions\/\d+$/);
      if (baseURL === "/api/react" && url && expression.test(url)) {
        return;
      }

      let message = errors.message || errors.error;

      if (Array.isArray(message)) {
        message = message.join(", ");
      }

      mainStore.toast.setErrorText(message || "Access Denied");
    } else if (status === 422 && errors.invalid_authenticity_token) {
      mainStore.toast.setErrorFromResponse(response);
    }
  }

  // TODO: Consolidate axios error and js Error into one type: ThemisError
  throw e;
});

export default api;
