import { action, makeObservable, observable } from "mobx";

import { MainStore } from "../Main";

export default class NotificationsStore {
  mainStore: MainStore;
  isOpen = false;
  unreadNotificationsCount = 0;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      isOpen: observable,
      setIsOpen: action,
      unreadNotificationsCount: observable,
      setUnreadNotificationsCount: action,
    });
    this.mainStore = mainStore;
  }

  setUnreadNotificationsCount(value: number) {
    this.unreadNotificationsCount = value;
  }

  setIsOpen(value: boolean) {
    this.isOpen = value;
  }

  cleanup() {
    this.setIsOpen(false);
    this.setUnreadNotificationsCount(0);
  }
}
