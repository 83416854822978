import { Avatar, IconButton, TextInput } from "@themis/ui";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { PiTrashSimpleBold } from "react-icons/pi";
import Popup from "reactjs-popup";

import { userColors } from "@/components/constants";
import { validateEmail } from "@/components/helpers/EmailValidator";
import { useMainStore } from "@/contexts/Store";
import { Assignment } from "@/features/risk-assessment/types";
import lightbulb from "@/images/table-image/icon/lightbulb-white-icon.svg";
import search from "@/images/table-image/icon/search-black-two.svg";

type Props = {
  title: string;
  questionReviewers?: Assignment[];
  onIdsSelectionChange: (args: number[]) => void;
  onEmailsSelectionChange: (args: string[]) => void;
};

export function SearchInput({
  title,
  questionReviewers,
  onIdsSelectionChange,
  onEmailsSelectionChange,
}: Props) {
  const mainStore = useMainStore();
  const [typedEmail, setTypedEmail] = useState("");
  const [query, setQuery] = useState("");
  const [searchUser, setSearchUser] = useState(false);

  const assigneesIds = questionReviewers?.map((item) => item.user_id) || [];
  const [selectedUserIDs, setSelectedUserIDs] =
    useState<number[]>(assigneesIds);

  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

  const [showPopup, setShowPopup] = useState(false);

  const userRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (userRef.current && !userRef.current.contains(event.target as Node)) {
        setSearchUser(false);
      }
    }

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedUserIDs.length > 0 || selectedEmails.length > 0) {
      onIdsSelectionChange(selectedUserIDs);
      onEmailsSelectionChange(selectedEmails);
    } else {
      onIdsSelectionChange([]);
      onEmailsSelectionChange([]);
    }
  }, [selectedUserIDs, selectedEmails]);

  function addUserById(id: number) {
    setSelectedUserIDs([...selectedUserIDs, id]);
  }

  function removeUserById(id: number) {
    const newSelectionUser = selectedUserIDs.filter(
      (element) => element !== id,
    );
    setSelectedUserIDs(newSelectionUser);
  }

  function addEmail(email: string) {
    setSelectedEmails([...selectedEmails, email]);
  }

  function removeEmail(email: string) {
    const newSelectionEmail = selectedEmails.filter(
      (element) => element !== email,
    );
    setSelectedEmails(newSelectionEmail);
  }

  function handleEmailKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" || event.key === " " || event.type === "click") {
      if (validateEmail(typedEmail)) {
        addEmail(typedEmail);
        setTypedEmail("");
        setQuery("");
      }
    }
  }

  function searchUserClick() {
    setSearchUser(true);
    setShowPopup(false);
  }

  function searchUserEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value);
    if (event.target.value !== " ") {
      setTypedEmail(event.target.value);
    } else {
      setTypedEmail("");
    }
  }

  const searchInput = (
    <div className="tw-font-meidum tw-line-clamp-1 tw-flex tw-w-full tw-cursor-default tw-cursor-default tw-items-center tw-gap-2 tw-text-sm tw-text-neutral-300">
      <TextInput
        className=""
        size="lg"
        placeholder="Search here"
        value={typedEmail}
        onChange={searchUserEmailChange}
        onClick={searchUserClick}
        onKeyDown={handleEmailKeyDown}
      />
      <img src={search} alt="search-icon" className="tw-absolute tw-right-8" />
    </div>
  );

  const searchClasses = classNames("list-block", {
    "active-search": query.length > 0,
  });

  const filteredUsers = mainStore.users.users
    .filter((user) => !selectedUserIDs.includes(user.id))
    .filter(
      (searchItems) =>
        searchItems.full_name?.toLowerCase().includes(query.toLowerCase()),
    );

  const filteredContacts = mainStore.contacts.list
    .filter((contacts) => !selectedUserIDs.includes(contacts.id))
    .filter(
      (searchItems) =>
        searchItems.full_name?.toLowerCase().includes(query.toLowerCase()),
    );

  const listUsersElements = filteredUsers.slice(0, 1).map((elem) => (
    <div className="email-list-wrap" key={elem.id} data-testid="list-users">
      {filteredUsers
        .filter((element) => !selectedUserIDs.includes(element.id))
        .map((user) => {
          const style = { background: userColors[user.icon_color_index] };

          return (
            <div
              key={user.id}
              data-testid="email-list-user-li"
              className={searchClasses}
              onClick={() => {
                addUserById(user.id);
              }}
            >
              <div className="users" data-testid="user">
                <div className="users-circle" style={style}>
                  <span>{user.initials}</span>
                </div>
                <div data-testid="user-name" className="user-full-name">
                  {user.full_name}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  ));

  const listContactsElements = filteredContacts.slice(0, 1).map((elem) => (
    <div className="email-list-wrap" key={elem.id} data-testid="list-contacts">
      {filteredContacts
        .filter((element) => !selectedUserIDs.includes(element.id))
        .map((contact) => {
          const style = { borderColor: userColors[contact.icon_color_index] };

          return (
            <div
              key={contact.id}
              className={searchClasses}
              onClick={() => {
                addUserById(contact.id);
              }}
            >
              <div className="users" data-testid="contact">
                <div
                  className="users-circle users-circle-contact"
                  style={style}
                >
                  <span>{contact.initials}</span>
                </div>
                <div data-testid="contact-name">{contact.full_name}</div>
              </div>
            </div>
          );
        })}
    </div>
  ));

  return (
    <div className="email-dropdown-block">
      <div className="tw-font-meidum tw-line-clamp-1 tw-cursor-default tw-px-5 tw-text-sm tw-text-neutral-300">
        {title}
        <div className="tw-py-2 ">
          <Popup
            position="bottom left"
            trigger={() => searchInput}
            open={showPopup}
            onOpen={() => setShowPopup(true)}
            onClose={() => setShowPopup(false)}
            keepTooltipInside
            className="hit-popup"
            // @ts-expect-error TS(2322) FIXME: Type '"" | "hover"' is not assignable to type 'Eve... Remove this comment to see the full error message
            on={!searchUser ? "hover" : ""}
            arrow={false}
            offsetY={18}
          >
            <div className="hint-wrap">
              <img src={lightbulb} alt="lightbulb" /> To add email, type email
              above and hit “Enter”.{" "}
            </div>
          </Popup>
        </div>
        {searchUser &&
          (filteredUsers.length > 0 || filteredContacts.length > 0) && (
            <div className="email-popup-wrap" ref={userRef}>
              {listUsersElements}
              {listContactsElements}
            </div>
          )}
        <div className="list-wrap-block">
          {mainStore.users.users
            .filter((element) => selectedUserIDs.includes(element.id))
            .map((user) => {
              return (
                <div
                  key={`view-module-users-${user.id}`}
                  className="tw-box-border tw-flex tw-w-full tw-items-center tw-justify-between tw-pt-2"
                >
                  <div className="tw-flex tw-items-center tw-gap-1">
                    <Avatar colorIndex={user.icon_color_index} size="sm">
                      {user.initials}
                    </Avatar>
                    <span className="tw-text-neutral-500">
                      {user.full_name}
                    </span>
                  </div>
                  <IconButton
                    data-testid="user-delete-trigger"
                    color="transparent"
                    size="sm"
                    Icon={PiTrashSimpleBold}
                    onClick={() => {
                      removeUserById(user.id);
                    }}
                  />
                </div>
              );
            })}

          {mainStore.contacts.list
            .filter((element) => selectedUserIDs.includes(element.id))
            .map((contact) => {
              return (
                <div
                  key={`view-module-users-${contact.id}`}
                  className="tw-box-border tw-flex tw-w-full tw-items-center tw-justify-between tw-pt-2"
                >
                  <div className="tw-flex tw-items-center tw-gap-1.5 tw-overflow-hidden">
                    <Avatar
                      style={{
                        borderColor: userColors[contact.icon_color_index],
                      }}
                      className={classNames("", {
                        "tw-border-1 tw-border-solid tw-bg-white tw-text-neutral-500":
                          contact.is_contact,
                      })}
                      size="sm"
                    >
                      {contact.initials}
                    </Avatar>
                    <span className="tw-text-neutral-500">
                      {contact.full_name}
                    </span>
                  </div>
                  <IconButton
                    data-testid="user-delete-trigger"
                    color="transparent"
                    size="sm"
                    Icon={PiTrashSimpleBold}
                    onClick={() => {
                      removeUserById(contact.id);
                    }}
                  />
                </div>
              );
            })}

          {selectedEmails.map((email) => (
            <div
              key={`view-module-users-${email}`}
              className="tw-box-border tw-flex tw-w-full tw-items-center tw-justify-between tw-pt-2"
            >
              <div className="tw-flex tw-items-center tw-gap-1.5 tw-overflow-hidden">
                <span className="tw-text-neutral-500">{email}</span>
              </div>
              <IconButton
                data-testid="user-delete-trigger"
                color="transparent"
                size="sm"
                Icon={PiTrashSimpleBold}
                onClick={() => {
                  removeEmail(email);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
