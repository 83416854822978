import { RecordVersion } from "@/api";

export const getApproversForCurrentStep = (recordVersion?: RecordVersion) => {
  if (!recordVersion) {
    return [];
  }

  const approvalSteps = recordVersion.approval_config?.approval_steps;
  const currentStepID = recordVersion.approval_status?.approval_step_id;
  const currentStep = approvalSteps?.find((step) => step.id === currentStepID);
  const currentStepApproversIDs = currentStep?.users_ids || [];
  return currentStepApproversIDs;
};
