import "./upload-questionnaire-modal.scss";

import React, { useRef } from "react";
import Popup from "reactjs-popup";

import { Button, Typography } from "@/components/Elements";
import ModalWrapper from "@/components/shared/ModalWrapper";

type UploadQuestionnaireModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onFileAdded: (file: File) => void;
};

const UploadQuestionnaireModal = (props: UploadQuestionnaireModalProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Popup open={props.isOpen} onClose={props.onClose} closeOnDocumentClick>
      <ModalWrapper width={400}>
        <div className="upload-questionnaire-modal">
          <Typography
            label="Upload completed XLSX file"
            weight="semiBold"
            size="lg"
            color="generalMidnightDark"
          />
          <Typography
            label="This file should be the XLSX file downloaded using the Export option of the questionnaire."
            size="de"
            color="generalDuskDark"
          />
          <Button
            label="Upload XLSX"
            onClick={() => inputRef.current?.click()}
          />
          <input
            type="file"
            ref={inputRef}
            className="upload-questionnaire-modal__input"
            onChange={(event) =>
              event.target.files && props.onFileAdded(event.target.files[0])
            }
            accept=".xlsx"
          />
        </div>
      </ModalWrapper>
    </Popup>
  );
};

export default UploadQuestionnaireModal;
