import "./risk-methodology-builder.scss";

import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { ResidualRisk } from "../../../types/risk-methodology";

type MatrixCellProps = {
  index: number;
  residualRisk: ResidualRisk;
  residualRisks: Array<ResidualRisk>;
  updateCell?: (residualRisk: ResidualRisk) => void;
};

function MatrixCell({
  residualRisk,
  index,
  residualRisks,
  updateCell,
}: MatrixCellProps) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleUpdateCell = (residualRiskItem: ResidualRisk) => {
    setIsPopupOpen(false);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    updateCell(residualRiskItem);
  };

  return (
    <div
      className="matrix-cell matrix-cell__colorful"
      key={`matrix-cell-${index}`}
    >
      <span className="matrix-cell-content">
        <span
          className="matrix-cell-color-ball"
          style={{ backgroundColor: residualRisk.color }}
        />
        <span className="space-before-ball"> {residualRisk.text} </span>
      </span>
      <span>
        <Popup
          position="bottom right"
          trigger={<span className="matrix-cell-dropdown" />}
          keepTooltipInside
          open={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          onOpen={() => setIsPopupOpen(true)}
        >
          <div className="matrix-options-wrapper">
            {residualRisks.map((residualRiskItem) => (
              <div
                onClick={() => handleUpdateCell(residualRiskItem)}
                key={residualRiskItem.id}
              >
                <span className="matrix-cell-content rarrmv-selection-padding">
                  <span
                    className="matrix-cell-color-ball"
                    style={{ backgroundColor: residualRiskItem.color }}
                  />
                  <span className="space-before-ball">
                    {" "}
                    {residualRiskItem.text}{" "}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </Popup>
      </span>
    </div>
  );
}
export default observer(MatrixCell);
