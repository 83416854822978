import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { ACCOUNTS_QUERY_KEY } from "@/api/queries/accounts";

import { ModifyContactRequest } from "../gen";
import {
  createAccountContact,
  deleteContact,
  listOfAccountContacts,
  showContact,
  updateContact,
} from "../gen/axios/contactsController";

export const CONTACTS_QUERY_KEY = "contacts";

export function useContacts(accountId: number) {
  return useQuery({
    queryKey: [CONTACTS_QUERY_KEY, { accountId }],
    queryFn: () => listOfAccountContacts(accountId),
    enabled: !!accountId,
  });
}

export function useContact(contactId: number) {
  return useQuery({
    queryKey: [CONTACTS_QUERY_KEY, { contactId }],
    queryFn: () => showContact(contactId),
    enabled: !!contactId,
  });
}

export function useCreateContact({
  accountId,
  onSuccess = () => {},
  onError = () => {},
}: {
  accountId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (contact: ModifyContactRequest) =>
      createAccountContact(accountId, { contact }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CONTACTS_QUERY_KEY, { accountId }],
      });
      onSuccess();
    },
    onError,
  });
}

export function useUpdateContact({
  contactId,
  onSuccess = () => {},
  onError = () => {},
}: {
  contactId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (contact: ModifyContactRequest) =>
      updateContact(contactId, { contact }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CONTACTS_QUERY_KEY, { contactId }],
      });
      onSuccess();
    },
    onError,
  });
}

export function useDeleteContact({
  accountId,
  onSuccess = () => {},
  onError = () => {},
}: {
  accountId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (contactId: number) => deleteContact(contactId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CONTACTS_QUERY_KEY, { accountId }],
      });

      queryClient.invalidateQueries({
        queryKey: ACCOUNTS_QUERY_KEY.detail(Number(accountId)),
      });
      onSuccess();
    },
    onError,
  });
}
