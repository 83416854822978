import { PreviewTableColumn } from "@themis/ui";
import React from "react";
import { PiChartBar, PiHashStraight, PiTextT } from "react-icons/pi";

import { RiskRegisterActionsCell } from "../components/ScoringTable/RiskRegisterActionsCell";
import RiskRegisterScoreTag from "../components/ScoringTable/RiskRegisterScoreTag";
import { AggregateRegisterScores } from "../types/AggregateRegisterScores";
import { RiskRegisterGroupItem } from "../types/RiskRegisterGroupItem";

export const SCORING_GROUP_COLUMNS: PreviewTableColumn<
  RiskRegisterGroupItem &
    AggregateRegisterScores & {
      remove: (registerId: number) => void;
    }
>[] = [
  {
    key: "name",
    title: "Register Name",
    type: "locked",
    width: 268,
    Icon: PiTextT,
  },
  {
    title: "Weighting",
    type: "locked",
    width: 200,
    Component: (item) => <div>{item.weight}%</div>,
    Icon: PiHashStraight,
  },
  {
    key: "overall_score",
    title: "Overall Score",
    type: "locked",
    width: 200,
    Icon: PiChartBar,
  },
  {
    title: "Inherent Risk",
    type: "locked",
    width: 200,
    Component: (item) => (
      <RiskRegisterScoreTag
        score={item.inherent_score}
        label={item.aggregate_scores?.inherent?.title}
        color={item.aggregate_scores?.inherent?.color}
      />
    ),
    Icon: PiChartBar,
  },
  {
    title: "Residual Risk",
    type: "locked",
    width: 200,
    Component: (item) => (
      <RiskRegisterScoreTag
        score={item.residual_risk_score}
        label={item.aggregate_scores?.residual?.title}
        color={item.aggregate_scores?.residual?.color}
      />
    ),
    Icon: PiChartBar,
  },
  {
    type: "actions",
    Component: (item) => (
      <RiskRegisterActionsCell
        riskRegisterId={item.id}
        onRemove={item.remove}
      />
    ),
  },
] as const;
