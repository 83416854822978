import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { useMainStore } from "@/contexts/Store";

import RiskRegisterContextMenu from "./RiskRegisterContextMenu";

function RiskRegisterActionBar() {
  // Import MobX stores
  const mainStore = useMainStore();
  const path = location.pathname;
  const recordVersionID = Number(path.substring(path.lastIndexOf("/") + 1));
  if (!recordVersionID) {
    return;
  }

  const recordVersionList = mainStore.recordVersions.list.find(
    (record_version) => record_version.id === recordVersionID,
  );
  const recordVersionFetch = mainStore.recordVersions.current;
  const recordVersion = recordVersionList || recordVersionFetch;

  useEffect(() => {
    const fetchData = async () => {
      if (recordVersionList) {
        return;
      }
      await mainStore.recordVersions.fetch(recordVersionID);
    };

    fetchData();
  }, [recordVersionID]);

  if (!recordVersion) {
    return null;
  }
  return (
    <RiskRegisterContextMenu
      recordVersionID={recordVersionID}
      showOnlyIcon={false}
    />
  );
}

export default observer(RiskRegisterActionBar);
