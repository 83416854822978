import { observer } from "mobx-react";
import React, { useState } from "react";

import { useMainStore } from "@/contexts/Store";

import Spinner from "../shared/Spinner";

type ScreenType = "ThemisRiskLibrary" | "Templates";

type Props = {
  handleScreenChange: (screen: ScreenType, fromDialog?: boolean) => void;
};

function RiskRegisterLibraryTemplateButton({ handleScreenChange }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [isSaving, setIsSaving] = useState(false);
  // Variables
  const { libraryRecords } = mainStore.riskRegisters;
  const { moduleWorkspaceID } = mainStore.context;
  const disabled = !libraryRecords.some((item) => item.checked) || isSaving;

  // Functions
  async function handleClick() {
    if (!moduleWorkspaceID) {
      return;
    }

    setIsSaving(true);
    const ids = libraryRecords
      .filter((item) => item.checked)
      .map((item) => item.id);

    mainStore.pageLoading.startLoading();
    await mainStore.riskRegisters.createBulkCompanyLibraryItems(
      moduleWorkspaceID,
      ids,
    );
    mainStore.pageLoading.endLoading();
    handleScreenChange("Templates");
  }

  return (
    <button
      className="table-button header-action blue-light"
      onClick={handleClick}
      disabled={disabled}
      data-testid="rr-library-apply"
      aria-label="Add To Company Library"
    >
      Add To Company Library
      {isSaving && <Spinner />}
    </button>
  );
}

export default observer(RiskRegisterLibraryTemplateButton);
