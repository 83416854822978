import React, { useState } from "react";

import { Button } from "@/components/Elements";

type CustomWeightDropdownContentProps = {
  handlePopupClose?: () => void;
  onCustomValueAdded: (val: string) => void;
  initialValue: string;
};

function CustomWeightDropdownContent({
  handlePopupClose,
  onCustomValueAdded,
  initialValue,
}: CustomWeightDropdownContentProps) {
  const [customValue, setCustomValue] = useState(initialValue);
  const handleConfirm = () => {
    onCustomValueAdded(customValue);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handlePopupClose();
  };

  const handleChange = (inputValue: string) => {
    const sanitizedValue = inputValue
      .replace(/[^\d.]/g, "")
      .replace(/(\..*)\./g, "$1");

    const parts = sanitizedValue.split(".");
    if (parts.length > 1) {
      parts[1] = parts[1].slice(0, 2);
    }

    const formattedValue = parts.join(".");
    setCustomValue(formattedValue);
  };

  return (
    <div className="dropdown-popup dropdown-popup__custom-value">
      <div className="dropdown-custom-value-title">Custom Value</div>
      <div className="dropdown-custom-value-desc">
        Please enter float values up to two decimals
      </div>
      <input
        data-testid="dropdown-search"
        type="text"
        placeholder="- Type Value -"
        value={customValue}
        onChange={(val) => handleChange(val.target.value)}
        className="dropdown-custom-value-input"
      />
      <Button
        label="Confirm"
        disabled={customValue?.length === 0 || parseFloat(customValue) <= 0}
        onClick={handleConfirm}
      />
    </div>
  );
}

export default CustomWeightDropdownContent;
