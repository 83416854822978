import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { attachmentFileType, RecordVersion } from "@/api";
import { Icon } from "@/components/Elements";
import {
  getAllAttachments,
  hasFigmaAttachment,
  maxAttachmentVersion,
} from "@/components/helpers/AttachmentGroupsHelper";
import { formatDate, stringToDate } from "@/components/helpers/DateFormatters";
import { useMainStore } from "@/contexts/Store";

import commentActiveIcon from "../../../../../images/table-image/icon/comment-white.svg";
import { getApproversForCurrentStep } from "../../helpers/approvers";

type Props = {
  commentsMode?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleCommentsToggle?: (...args: any[]) => any;

  recordVersion: RecordVersion;
  renderBackToSubmiter?: React.ReactNode;
  renderCommentsFigmaSwitch?: React.ReactNode;
  renderPushToApprovers?: React.ReactNode;
  renderTakeScreenshot?: React.ReactNode;
  renderMarketingCreativeViewStages?: React.ReactNode;
  renderCreativeViewSubmitterReviewers?: React.ReactNode;
  renderCreativeViewPendingReviewers?: React.ReactNode;
  renderCreativeViewPendingChange?: React.ReactNode;
};

function MultipleAttachmentsToolbar({
  recordVersion,
  commentsMode,
  handleCommentsToggle,
  renderCommentsFigmaSwitch,
  renderBackToSubmiter,
  renderPushToApprovers,
  renderTakeScreenshot,
  renderMarketingCreativeViewStages,
  renderCreativeViewSubmitterReviewers,
  renderCreativeViewPendingReviewers,
  renderCreativeViewPendingChange,
}: Props) {
  // States
  const [showPopup, setShowPopup] = useState(false);
  const [denyReason, setDenyReason] = useState("");
  const [showMessagePopup, setShowMessagePopup] = useState(false);

  // Import MobX stores
  const mainStore = useMainStore();
  const {
    attachmentID: currentAttachmentID,
    attachmentGroupID: currentAttachmentGroupID,
  } = mainStore.files;
  const { list: attachmentGroups } = mainStore.attachmentGroups;
  const { themisModuleIdentifier, workspaceID } = mainStore.context;
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  // Variables
  const history = useHistory();
  const allAttachments = getAllAttachments(attachmentGroups);
  const currentAttachment = allAttachments.find(
    (attachment) => attachment.id === currentAttachmentID,
  );
  const currentAttachmentGroup = attachmentGroups.find(
    (attachmentGroup) => attachmentGroup.id === currentAttachmentGroupID,
  );
  const latestAttachmentVersion = maxAttachmentVersion(
    currentAttachmentGroup?.attachments,
  );
  const isLatestVersion =
    currentAttachment?.version === latestAttachmentVersion;

  const hasFigmaAttachmentGroup = hasFigmaAttachment(attachmentGroups);
  const isFigmaActiveFile =
    currentAttachment?.file_type === attachmentFileType.figma;
  const isMarketingModule = themisModuleIdentifier === "marketing";
  const isVDD = themisModuleIdentifier === "vendor_due_diligence";

  // This logic is specific to the marketing module and if we have other modules that use this component, we will need to change this logic
  const currentUserID = mainStore.users.user.id;
  const currentUserRecordVersionReview = recordVersion?.reviews?.find(
    (item) => item.user_id === currentUserID,
  );
  const currentUserRecordVersionSubmitter =
    recordVersion?.data?.submitter?.ids.includes(currentUserID);
  const approversIDs = mainStore.avroSchemas.valueForField(
    "approvers",
    recordVersion.data,
  );
  const currentStepAproversIDs = getApproversForCurrentStep(recordVersion);

  const isApprover =
    approversIDs?.includes(currentUserID) ||
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    currentStepAproversIDs.includes(currentUserID);

  const ownersIDs = mainStore.avroSchemas.valueForField(
    "owner",
    recordVersion.data,
  );

  const lastClosedAt = mainStore.avroSchemas.valueForField(
    "last_closed_at",
    recordVersion.data,
  );
  const isOwner = ownersIDs?.includes(currentUserID);

  const canSeePushToApproversButton =
    isMarketingModule &&
    currentUserRecordVersionSubmitter &&
    recordVersion.status &&
    // recordVersion.status === "new";
    ["new", "request_change"].includes(recordVersion.status);
  const canSeeApproveButton =
    !currentUserRecordVersionReview &&
    isLatestVersion &&
    isApprover &&
    recordVersion.status &&
    ["reviewing", "approved"].includes(recordVersion.status);
  const canSeeUnapproveButton =
    Boolean(currentUserRecordVersionReview?.id) &&
    (isApprover || isOwner) &&
    recordVersion.status === "approved";
  const canSeeDenyButton =
    canSeeApproveButton && recordVersion.status === "reviewing";
  const reviews = recordVersion?.reviews?.find(
    (review) =>
      review.user_id === currentUserID && review.review_type === "approval",
  );
  const canSeeApprovedState = Boolean(reviews);
  const canSubmitterSeeApprovedState =
    isMarketingModule &&
    currentUserRecordVersionSubmitter &&
    recordVersion.status &&
    recordVersion.status === "approved";
  const canSeeBackToSubmitterButton =
    isMarketingModule &&
    isApprover &&
    !canSeeApprovedState &&
    recordVersion.status === "reviewing";

  const doneStatus =
    recordVersion.status &&
    ["rejected", "approved"].includes(recordVersion.status);
  const changeRequestStatus =
    recordVersion.status && recordVersion.status === "request_change";

  const creativeComments = classNames(
    "creative-view-comments-icon no-mr-multiple",
    { active: commentsMode && !showMessagePopup },
  );
  const { contentType } = mainStore.files;

  const isDoc = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
  ].includes(contentType);
  const isPptx =
    contentType ===
    "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  const isMicrosoftPackage = isDoc || isPptx;

  // @ts-expect-error TS(7006) FIXME: Parameter 'updatedRecordVersion' implicitly has an... Remove this comment to see the full error message
  const handleRedirect = (updatedRecordVersion) => {
    const approvedMessage =
      "Approved - Marketing material moved to the Completed Tab!";
    const rejectedMessage =
      "Marketing material moved to the Completed Tab as Denied!";
    const newStatus = mainStore.avroSchemas.firstValueForField(
      "status",
      updatedRecordVersion?.data,
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Field[]' is not assignable to pa... Remove this comment to see the full error message
      mainStore.creatives.fields,
    );

    switch (newStatus) {
      case "approved":
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ showThumbsUp: boolean; }' is n... Remove this comment to see the full error message
        mainStore.toast.setInfoText(approvedMessage, { showThumbsUp: true });
        mainStore.creatives.index({
          workspaceID,
          tab: "Completed",
        });
        history.push(`/workspaces/${workspaceID}/modules/marketing/completed`);
        break;

      case "rejected":
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ showThumbsUp: boolean; }' is n... Remove this comment to see the full error message
        mainStore.toast.setInfoText(rejectedMessage, { showThumbsUp: true });
        mainStore.creatives.index({
          workspaceID,
          tab: "Completed",
        });
        history.push(`/workspaces/${workspaceID}/modules/marketing/completed`);
        break;

      case "reviewing":
        mainStore.creatives.index({
          workspaceID,
          tab: "Active",
        });
        history.push(`/workspaces/${workspaceID}/modules/marketing`);
        break;

      default:
        break;
    }

    mainStore.pageLoading.endLoading();
  };

  const handleApprove = async () => {
    const approvedRecordVersion = await mainStore.creatives.approve(
      recordVersion.id,
    );
    handleRedirect(approvedRecordVersion);
  };

  const handleUnlock = () => {
    mainStore.creatives.unlock(recordVersion.id);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const changeDenyReason = (event) => {
    setDenyReason(event.target.value);
  };

  const updateDenyReason = async () => {
    if (recordVersion.id) {
      await mainStore.recordVersions.update({
        fieldName: "deny_reason",
        recordVersionID: recordVersion.id,
        value: mainStore.avroSchemas.serializeValue("deny_reason", denyReason),
      });

      const rejectedRecordVersion = await mainStore.creatives.deny(
        recordVersion.id,
      );
      handleRedirect(rejectedRecordVersion);
    }

    mainStore.pageLoading.endLoading();
  };

  const renderToolbarApproval = (
    <>
      {currentUserRecordVersionReview && canSeeUnapproveButton && (
        <button
          onClick={handleUnlock}
          type="button"
          className="unapproved-dark"
          data-testid="comment-sidebar-trigger-unapprove"
        >
          Reopen
        </button>
      )}

      {canSeeApproveButton && (
        <button
          type="button"
          style={{ background: "#27aae1" }}
          className={classNames("tw-flex tw-items-center tw-bg-secondary-300", {
            "mr-l":
              !currentUserRecordVersionReview &&
              recordVersion.status === "Approved",
          })}
          onClick={handleApprove}
          data-testid="cv-toolbar-multiple-approve"
        >
          <Icon name="thumbsUp" color="generalWhite" />
          Approve
        </button>
      )}
    </>
  );

  const renderToolbarDeniedLabel = (
    <div
      className="denied-label"
      data-testid="cv-toolbar-multiple-rejected-label"
    >
      This creative was denied!
    </div>
  );

  const renderToolbarDeny = canSeeDenyButton && (
    <div>
      <Popup
        position="bottom right"
        trigger={() => (
          <button
            type="button"
            style={{ background: "#eb2e4e" }}
            className={classNames("tw-flex tw-items-center tw-bg-warning-300", {
              active: showPopup,
            })}
            data-testid="cv-toolbar-multiple-deny-popup"
          >
            <Icon name="thumbsDown" color="generalWhite" />
            Deny
          </button>
        )}
        keepTooltipInside
        open={showPopup}
        onOpen={() => setShowPopup(true)}
        onClose={() => setShowPopup(false)}
      >
        <div className="table-dropdown file-changes ">
          <h4>Deny Reason</h4>
          <p>
            Describe why this creative has been denied. This will be saved
            within the record.
          </p>
          <textarea
            placeholder="Please type your denial reason"
            value={denyReason}
            onChange={changeDenyReason}
            data-testid="cv-toolbar-multiple-deny-textarea"
          />
          <div className="confirmation deny-confirnation">
            <button
              type="button"
              className="deny-confirnation"
              data-testid="cv-toolbar-multiple-deny-confirm"
              disabled={!denyReason || !hasModuleWriteAccess}
              onClick={() => {
                updateDenyReason();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );

  const renderToolbarComments = (
    <div
      className={creativeComments}
      onClick={handleCommentsToggle}
      data-testid="comment-sidebar-trigger"
    >
      <img src={commentActiveIcon} className="comments-icon" alt="comments" />
      Comment
    </div>
  );

  const renderMessageForNotSupported = (
    <Popup
      trigger={renderToolbarComments}
      position="bottom center"
      open={showMessagePopup}
      onOpen={() => setShowMessagePopup(true)}
      onClose={() => setShowMessagePopup(false)}
      keepTooltipInside
    >
      <div className="table-dropdown dark-mode">
        <span>Not available for doc / docx file types</span>
      </div>
    </Popup>
  );

  if (!attachmentGroups?.length) {
    return null;
  }

  return (
    <div
      className="creative-toolbar creative-toolbar-multiple"
      data-testid="cv-toolbar-multiple-container"
    >
      <div
        className="creative-toolbar-center"
        data-testid="cv-toolbar-multiple-center"
      >
        {isMarketingModule && renderMarketingCreativeViewStages}
        {isMarketingModule &&
          hasFigmaAttachmentGroup &&
          renderCommentsFigmaSwitch}
      </div>

      <div
        className="creative-toolbar-right"
        data-testid="cv-toolbar-multiple-right"
      >
        {isMarketingModule &&
          recordVersion.status === "Rejected" &&
          renderToolbarDeniedLabel}
        {isMarketingModule &&
          (canSeeApprovedState || canSubmitterSeeApprovedState) && (
            <div
              className="tw-pr-2 tw-text-xs tw-font-semibold tw-text-neutral-300"
              data-testid="cv-toolbar-multiple-current-label-approve"
            >
              {`Approved on ${formatDate(
                stringToDate(
                  canSeeApprovedState ? reviews?.approved_at : lastClosedAt,
                ),
              )}`}
            </div>
          )}
        {!isVDD &&
          isLatestVersion &&
          !isFigmaActiveFile &&
          isMicrosoftPackage &&
          renderMessageForNotSupported}
        {isFigmaActiveFile && !commentsMode && renderTakeScreenshot}
        {canSeePushToApproversButton && renderPushToApprovers}
        {canSeeBackToSubmitterButton && renderBackToSubmiter}
        {isMarketingModule && renderToolbarApproval}
        {isMarketingModule && renderToolbarDeny}
        {isMarketingModule && renderCreativeViewSubmitterReviewers}
        {isMarketingModule &&
          currentUserRecordVersionSubmitter &&
          !doneStatus &&
          !changeRequestStatus &&
          renderCreativeViewPendingReviewers}
        {isMarketingModule &&
          !currentUserRecordVersionSubmitter &&
          changeRequestStatus &&
          renderCreativeViewPendingChange}
      </div>
    </div>
  );
}

export default observer(MultipleAttachmentsToolbar);
