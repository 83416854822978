import { TabProps } from "@themis/ui";

export const getSelectedTab = (
  tabs: TabProps[],
  defaultToFirst = true,
  pathname = "",
) => {
  const location = pathname || window.location.pathname;
  const pathSegments = location.split("/");
  let previousPathTab = null;

  const lastPathTab = tabs.find((tab) => {
    if (tab.key === pathSegments[pathSegments.length - 2]) {
      previousPathTab = tab;
    }
    return tab.key === pathSegments[pathSegments.length - 1];
  });

  const selectedTab = lastPathTab || previousPathTab;

  return selectedTab || (defaultToFirst ? tabs[0] : undefined);
};

export const getTabByURL = (tabs: TabProps[], url: string) => {
  return tabs.find((tab) => tab.value === url);
};

export const getTabByKey = (tabs: TabProps[], key: string) => {
  return tabs.find((tab) => tab.key === key);
};

export const getTabByName = (tabs: TabProps[], name: string) => {
  return tabs.find((tab) => tab.name === name);
};
