import classNames from "classnames";
import React, { useState } from "react";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import Popup from "reactjs-popup";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRename?: (...args: any[]) => any;
  tableName?: string;
};

function ProcedurePopupSettings({ onDelete, onRename, tableName }: Props) {
  // State
  const [showPopup, setShowPopup] = useState(false);
  const [step, setStep] = useState(1);

  function handleRename() {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onRename();
    setShowPopup(false);
  }

  function onOpen() {
    setShowPopup(true);
  }

  function onClose() {
    setShowPopup(false);
    setStep(1);
  }

  if (tableName !== "ProceduresDrafts") {
    return null;
  }

  return (
    <Popup
      position="bottom left"
      trigger={
        <div
          className={classNames(
            "tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-md tw-p-[7px] hover:tw-bg-neutral-100",
            {
              "tw-bg-neutral-100": showPopup,
            },
          )}
          data-tooltip-id="tooltip"
          data-tooltip-content="Manage section"
          data-tooltip-place="top"
        >
          <PiDotsThreeOutlineFill
            className="tw-h-[18px] tw-w-[18px] tw-text-neutral-500"
            data-testid="procedures-points-icon"
          />
        </div>
      }
      open={showPopup}
      onOpen={onOpen}
      onClose={onClose}
      keepTooltipInside
    >
      <div
        className="procedures-popup-settings"
        data-testid="procedures-popup-settings"
        data-tooltip-id="tooltip"
        data-tooltip-content="Section Options"
        data-tooltip-place="top"
      >
        {step === 1 && (
          <div className="select-small-wrap">
            <ul>
              <li data-testid="rename-section-button" onClick={handleRename}>
                Rename
              </li>
              <li
                data-testid="delete-section-button"
                onClick={() => setStep(2)}
              >
                Delete Section
              </li>
            </ul>
          </div>
        )}
        {step === 2 && (
          <div className="table-dropdown">
            <h4>Delete Section</h4>
            <p>
              Do you want to delete this procedure section? Any procedures
              within will be permanently deleted.
            </p>
            <div className="confirmation">
              <button
                onClick={onDelete}
                data-testid="procedure-delete-confirm-yes"
              >
                Yes
              </button>
              <button onClick={() => setShowPopup(false)}>No</button>
            </div>
          </div>
        )}
      </div>
    </Popup>
  );
}

export default ProcedurePopupSettings;
