import classNames from "classnames";
import React from "react";

import { THEMIS_COLORFUL_ICONS, THEMIS_ICONS } from "@/assets";
import { COLOR, THEMIS_ICON_SIZES } from "@/config/theme";

export type IconName = keyof typeof THEMIS_ICONS;
export type IconSize = (typeof THEMIS_ICON_SIZES)[number];

type IconProps = React.ComponentPropsWithoutRef<"svg"> & {
  className?: string;
  color?: keyof typeof COLOR;
  name?: IconName;
  size?: IconSize;
  fill?: string;
  spaceLeft?: boolean;
  spaceRight?: boolean;
  disableFlexShrink?: boolean;
};

const Icon: React.FC<IconProps> = ({
  className,
  color,
  name = "anchor",
  size = "de",
  spaceLeft,
  spaceRight,
  disableFlexShrink,
  ...rest
}) => {
  const Component = THEMIS_ICONS[name];

  if (!Component) {
    return null;
  }

  return (
    <Component
      className={classNames("themisIcon", `themisIcon--${size}`, className, {
        [`themisColor__${color}`]:
          color && !Object.keys(THEMIS_COLORFUL_ICONS).includes(name),
        "themisIcon--clickable": rest.onClick,
        "themisIcon--space-left": spaceLeft,
        "themisIcon--space-right": spaceRight,
        "themisIcon--disable-flex-shrink": disableFlexShrink,
      })}
      {...rest}
    />
  );
};

Icon.displayName = "Icon";

export default Icon;
