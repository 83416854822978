import { PreviewTableColumn } from "@themis/ui";
import React from "react";

import { LinkedFinding } from "@/api";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { PreviewRelatedFindingsNameCell } from "../components/PreviewRelatedFindings/PreviewRelatedFindingsNameCell";
import { SourceModuleCell } from "../components/SourceModuleCell";

export const PREVIEW_RELATED_FINDINGS_COLUMNS: PreviewTableColumn<LinkedFinding>[] =
  [
    {
      key: "name",
      title: "Name",
      type: "locked",
      width: 360,
      isTruncated: true,
      Component: (linkedFinding) => (
        <PreviewRelatedFindingsNameCell {...linkedFinding} />
      ),
    },
    {
      key: "record_name",
      title: "Parent Record",
      type: "locked",
      width: 200,
    },
    {
      key: "themis_module_identifier",
      title: "Source Module",
      type: "locked",
      width: 200,
      isTruncated: true,
      Component: ({ themis_module_identifier }) => (
        <SourceModuleCell
          moduleIdentifier={themis_module_identifier as ModuleIdentifier}
        />
      ),
    },
  ] as const;
