import { observer } from "mobx-react";
import React from "react";

import { FinraBrexCategories } from "@/components/table/finra-complaints/finnra-complaints-cells";
import { useMainStore } from "@/contexts/Store";

type Props = {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  isBrexCategory?: boolean;
};

function ViewFinraBrexCategories({
  recordVersion,
  fieldName,
  isBrexCategory,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const value = mainStore.avroSchemas.valueForField(
    fieldName,
    recordVersion.data,
  );
  const cellError = mainStore.recordVersions.errorsForField(
    recordVersion.id,
    fieldName,
  );
  const hasErrors = Boolean(cellError);

  return (
    <FinraBrexCategories
      fieldName={fieldName}
      recordVersion={recordVersion}
      // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
      isBrexCategory={isBrexCategory}
      width="100%"
      hasErrors={hasErrors}
      errorMessage={cellError?.description}
      selectedID={value}
      hasErrorClass="detail-view-has-errors"
    />
  );
}

export default observer(ViewFinraBrexCategories);
