import { DragDropContext, Droppable } from "@akojic27/react-beautiful-dnd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { Button, Typography } from "@/components/Elements";
import { RiskAssessment } from "@/stores/types/vendor-types";
import { arrayMove } from "@/utils/utils";

import ModalWrapper from "../../shared/ModalWrapper";
import { RISK_RATINGS_COLORS } from "../constants";
import RiskAssessmentMethodologyRow from "./RiskAssessmentMethodologyRow";

type Props = {
  matrix?: RiskAssessment;
  isMethodologyModalOpened?: boolean;
  onClose?: () => void;
  onSave?: (matrix: object) => void;
};

const RiskAssessmentMethodology = ({
  matrix,
  isMethodologyModalOpened,
  onClose,
  onSave,
}: Props) => {
  // state
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [localRatings, setLocalRatings] = useState([]);

  // vars
  // @ts-expect-error TS(2339) FIXME: Property 'risk_assessment_ratings' does not exist ... Remove this comment to see the full error message
  const { risk_assessment_ratings: ratings } = matrix;
  const discardedRatings =
    // @ts-expect-error TS(2339) FIXME: Property '_destroy' does not exist on type 'never'... Remove this comment to see the full error message
    localRatings?.filter((rating) => rating._destroy) || [];
  const nonDiscardedRatings =
    // @ts-expect-error TS(2339) FIXME: Property '_destroy' does not exist on type 'never'... Remove this comment to see the full error message
    localRatings?.filter((rating) => !rating._destroy) || [];

  // effects
  useEffect(() => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    setLocalRatings(ratings.map((item, index) => ({ ...item, listID: index })));
  }, [ratings]);

  useEffect(() => {
    setIsSubmitEnabled(checkFormValidity());
  }, [localRatings]);

  // funcs
  // @ts-expect-error TS(7006) FIXME: Parameter 'newRatings' implicitly has an 'any' typ... Remove this comment to see the full error message
  function setRatings(newRatings) {
    setLocalRatings(newRatings);
  }

  function handleAddRating() {
    // listID is needed for ratings to have something uniq to use as 'key'. Without it inputs will not get reset after d&d
    const data = {
      name: "",
      color_index: RISK_RATINGS_COLORS.length - 1,
      // @ts-expect-error TS(2339) FIXME: Property 'listID' does not exist on type 'never'.
      listID: (Math.max(...localRatings.map((item) => item.listID)) || 0) + 1,
    };
    setRatings([...localRatings, data]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
  function handleRemoveRating(index, id = null) {
    if (!id) {
      return setRatings(
        localRatings.filter((_, idx) => idx !== getRealIndex(index)),
      );
    }

    setRatings(
      localRatings.map((item) =>
        // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
        item.id === id ? { ...item, _destroy: true } : item,
      ),
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
  function handleUpdateRating(index, data) {
    setRatings(
      localRatings.map((rating, idx) =>
        // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        idx === getRealIndex(index) ? { ...rating, ...data } : rating,
      ),
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'nonDiscardedIndex' implicitly has an 'a... Remove this comment to see the full error message
  function getRealIndex(nonDiscardedIndex) {
    return localRatings.findIndex(
      (item) => item === nonDiscardedRatings[nonDiscardedIndex],
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function onDragEnd(event) {
    const { source, destination } = event;
    const { index: sourceIndex } = source;
    const { index: destinationIndex } = destination;

    if (sourceIndex === destinationIndex) {
      return;
    }

    let newList = arrayMove(nonDiscardedRatings, sourceIndex, destinationIndex);
    if (discardedRatings?.length) {
      newList = [...newList, ...discardedRatings];
    }

    setRatings(newList);
  }

  function checkFormValidity() {
    if (!nonDiscardedRatings?.length) {
      return false;
    }
    // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
    if (nonDiscardedRatings.some((rating) => !rating.name)) {
      return false;
    }

    const scoresValidity = nonDiscardedRatings.map((rating, index) => {
      // @ts-expect-error TS(2339) FIXME: Property 'lower_range' does not exist on type 'nev... Remove this comment to see the full error message
      const lowerRange = index === 0 ? 1 : rating.lower_range;
      const higherRange =
        // @ts-expect-error TS(2339) FIXME: Property 'higher_range' does not exist on type 'ne... Remove this comment to see the full error message
        index === nonDiscardedRatings.length - 1 ? 100 : rating.higher_range;

      if (!lowerRange || !higherRange) {
        return false;
      }
      if (
        lowerRange < 1 ||
        lowerRange > 100 ||
        higherRange < 1 ||
        higherRange > 100
      ) {
        return false;
      }
      if (higherRange <= lowerRange) {
        return false;
      }
      if (
        index < nonDiscardedRatings.length - 1 &&
        // @ts-expect-error TS(2339) FIXME: Property 'lower_range' does not exist on type 'nev... Remove this comment to see the full error message
        higherRange + 1 !== nonDiscardedRatings[index + 1].lower_range
      ) {
        return false;
      }

      return true;
    });
    if (scoresValidity.some((item) => !item)) {
      return false;
    }

    return true;
  }

  function handleSubmit() {
    const ratingsToSave = localRatings.map((rating, index) => {
      // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      const defaultObject = { ...rating, position: index };
      if (nonDiscardedRatings.indexOf(rating) === 0) {
        return { ...defaultObject, lower_range: 1 };
      }
      if (
        nonDiscardedRatings.indexOf(rating) ===
        nonDiscardedRatings.length - 1
      ) {
        return { ...defaultObject, higher_range: 100 };
      }

      return defaultObject;
    });

    setIsSubmitEnabled(false);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onSave({ ...matrix, risk_assessment_ratings: ratingsToSave });
  }

  // elements
  const heading = (
    <div className="input-group-row">
      <Typography
        label="Risk Rating"
        color="generalMidnightDark"
        size="lg"
        weight="semiBold"
      />
      <Button
        className="input-group-row__button"
        label="Add New Rating"
        onClick={handleAddRating}
      />
    </div>
  );

  return (
    <ModalWrapper isOpened={isMethodologyModalOpened} onClose={onClose}>
      <div className="risk-methodology-builder vdd-ra-methodology-modal-content">
        <div className="questionnaire-builder__content">
          {heading}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="risk-ratings-container">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="droppable-zone"
                >
                  {nonDiscardedRatings.map((rating, index) => (
                    <RiskAssessmentMethodologyRow
                      // @ts-expect-error TS(2339) FIXME: Property 'listID' does not exist on type 'never'.
                      key={rating.listID}
                      rating={rating}
                      index={index}
                      ratingsLength={nonDiscardedRatings.length}
                      handleUpdateRating={handleUpdateRating}
                      // @ts-expect-error TS(2322) FIXME: Type '(index: any, id?: null) => void' is not assi... Remove this comment to see the full error message
                      handleRemoveRating={handleRemoveRating}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <Button
          className="input-group-row__button submit-btn"
          label="Save and Exit"
          onClick={handleSubmit}
          disabled={!isSubmitEnabled}
        />
      </div>
    </ModalWrapper>
  );
};

export default observer(RiskAssessmentMethodology);
