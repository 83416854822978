import React from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

import trashIcon from "../../../images/table-image/icon/delete-icon.svg";
import { LibraryRecord } from "../../../stores/types/risk-register-types";
import { Icon } from "../../Elements";
import Checkbox from "../shared/Checkbox";
import ConfirmationDialog from "../shared/ConfirmationDialog";

type LibraryTableRowProps = {
  record: LibraryRecord;
  workspaceID: number | null;
  canDeleteRecords: boolean;
  showDeletePrompt: boolean;
  onChange: (id: number, checked: boolean) => void;
  onDelete: () => void;
  onDeletePromptChange: (show: boolean) => void;
  isEditable: boolean;
  hasModuleWriteAccess: boolean;
};

const LibraryTableRow = (props: LibraryTableRowProps) => (
  <div className="rr-library-table-row-container" key={props.record.id}>
    <div
      key={props.record.id}
      className="rr-library-table-row"
      data-testid="rr-library-row"
    >
      <Checkbox
        checked={props.record.checked}
        onChange={(e) => props.onChange(props.record.id, e.target.checked)}
        data-testid="rr-library-regular-checkbox"
        disabled={!props.hasModuleWriteAccess}
        padding
      />
      <div
        className="rr-library-table-cell option risk-type"
        data-testid="rr-library-cell-risk-type"
      >
        <span>{props.record.risk_type?.title || "-No L1 Risk Taxonomy-"}</span>
      </div>
      <div
        className="rr-library-table-cell option"
        data-testid="rr-library-cell-category"
      >
        <span>
          {props.record.main_category?.title || "-No L2 Risk Taxonomy-"}
        </span>
      </div>

      <div
        className="rr-library-table-cell option"
        data-testid="rr-library-cell-framework"
      >
        {props.record.frameworks?.map((framework) => (
          <span key={framework.id}>{framework.title}</span>
        )) || <span>-No Frameworks-</span>}
      </div>
      <div
        className="rr-library-table-cell"
        data-testid="rr-library-cell-event"
      >
        <span>{props.record.risk_events || "-No Event-"}</span>
      </div>
      <div
        className="rr-library-table-cell"
        data-testid="rr-library-cell-description"
      >
        <span>{props.record.description || "-No Description-"}</span>
      </div>
      <div
        className="rr-library-table-cell"
        data-testid="rr-library-cell-reference"
      >
        <span>{props.record.reference || "-No Reference-"}</span>
      </div>
    </div>

    {props.isEditable && (
      <div
        className="rr-library-table-row-actions"
        data-testid="rr-library-table-row-actions"
      >
        <Link
          data-testid="detail-view"
          to={`/workspaces/${props.workspaceID}/modules/risk-register/templates/${props.record.id}`}
        >
          <Icon
            data-testid="detail-view"
            name="edit"
            color="generalDark"
            size="de"
          />
        </Link>

        <Popup
          position="bottom right"
          open={props.showDeletePrompt}
          trigger={() => (
            <button
              type="button"
              data-testid="rr-library-table-row-actions-delete-trigger"
              disabled={!props.canDeleteRecords}
            >
              <img src={trashIcon} alt="trash-icon" />
            </button>
          )}
          disabled={!props.canDeleteRecords}
          onOpen={() => props.onDeletePromptChange(true)}
          onClose={() => props.onDeletePromptChange(false)}
        >
          <div
            className="table-dropdown success-dropdown"
            data-testid="rr-library-table-row-actions-delete-popup"
          >
            <ConfirmationDialog
              heading="Delete Library Item"
              content="Are you sure you want to delete this library item? Actions are not reversible."
              handleConfirm={props.onDelete}
              handleReject={() => props.onDeletePromptChange(false)}
            />
          </div>
        </Popup>
      </div>
    )}
  </div>
);

export default LibraryTableRow;
