import { orderBy } from "lodash";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { Workspace } from "@/stores/types/workspace-types";

import { Button } from "../../../Elements";
import { getWorkspaceLogo } from "../../../helpers/getWorkspaceLogo";
import { iconForThemisModuleIdentifier } from "../../../helpers/iconForThemisModuleIdentifier";
import AssigningDrawer from "../../settings/columns/users/RolesSelect/AssigningDrawer";
import { getPayload } from "../../settings/columns/users/RolesSelect/Create";
import ViewRolesListRow, { getSelectedModules } from "./ViewRolesListRow";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
};

function ViewRolesSelect({ user }: Props) {
  const mainStore = useMainStore();
  const { search } = useLocation();

  const { activeWorkspace, isAdmin, isCW, isWorkspaceAdmin } =
    mainStore.context;
  const { company } = mainStore.companies;
  const { modules: allModules, permissionSets: allPermissionSets } =
    mainStore.permissions;
  const { user: currentUser, isLoading } = mainStore.users;

  const { id: userID, roles: allRoles } = user;
  const isDisabled = userID === currentUser.id;
  const params = new URLSearchParams(search);
  const isInternalUserView = params.get("in") === "1";

  const allWorkspaces = useMemo(() => {
    if (!isInternalUserView) {
      return mainStore.workspaces.list;
    }

    return mainStore.workspaces.list.filter(
      (workspace) => workspace.is_internal,
    );
  }, [isInternalUserView, mainStore.workspaces.list]);

  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [editingRole, setEditingRole] = useState(null);

  const permissionSets = useMemo(() => {
    const allRolesPermissionSetIDs = allRoles.map(
      // @ts-expect-error TS(7006) FIXME: Parameter 'role' implicitly has an 'any' type.
      (role) => role.permission_set_id,
    );
    const res = allPermissionSets.filter(
      (ps) => !allRolesPermissionSetIDs.includes(ps.id),
    );
    if ((isAdmin && isCW) || isWorkspaceAdmin) {
      return res.filter((ps) => ps.permission_type === "workspace_module");
    }

    return res;
  }, [allPermissionSets, isAdmin, isCW, isWorkspaceAdmin, allRoles]);

  const workspaces = useMemo(() => {
    const workspacesWithoutIcon = isCW
      ? [activeWorkspace]
      : allWorkspaces.filter((ws) => ws.company_id === company.id);

    return workspacesWithoutIcon.map((ws) => ({
      ...ws,
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Workspace | ActiveWorkspace | nu... Remove this comment to see the full error message
      icon: getWorkspaceLogo(ws).logo,
    }));
  }, [allWorkspaces, activeWorkspace, isCW, company]) as Workspace[];

  const unassignedWorkspaces = useMemo(() => {
    const assignedWorkspaceIds = allRoles
      // @ts-expect-error TS(7006) FIXME: Parameter 'role' implicitly has an 'any' type.
      .map((role) => role.workspace_id)
      .filter(Boolean);
    return workspaces.filter((ws) => !assignedWorkspaceIds.includes(ws.id));
  }, [allRoles, workspaces]);

  const modules = useMemo(
    () =>
      allModules.map((module) => ({
        // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        ...module,
        // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
        icon: iconForThemisModuleIdentifier(module.id),
      })),
    [allModules],
  );

  const roles = useMemo(() => {
    const workspaceIDs = workspaces.map((ws) => ws.id);
    return orderBy(
      allRoles
        .filter(
          // @ts-expect-error TS(7006) FIXME: Parameter 'role' implicitly has an 'any' type.
          (role) =>
            workspaceIDs.includes(role.workspace_id) ||
            role.permission_set.permission_type === "company",
        )
        // @ts-expect-error TS(7006) FIXME: Parameter 'role' implicitly has an 'any' type.
        .map((role) => {
          const workspace = workspaces.find(
            (ws) => ws.id === role.workspace_id,
          );

          return { ...role, workspace };
        }),
      ["created_at"],
      ["asc"],
    );
  }, [allRoles, workspaces]);

  const handeRefreshUsers = () => {
    if (activeWorkspace?.id) {
      mainStore.users.indexForSettings({
        workspaceID: activeWorkspace.id,
        showAllUsers: true,
      });
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'selection' implicitly has an 'any' type... Remove this comment to see the full error message
  const handleCreate = async (selection) => {
    try {
      const payload = getPayload(selection);
      await mainStore.users.assignRoles(userID, payload);
      mainStore.toast.setInfoText("User role has been created.");
      setEditingRole(null);
      handeRefreshUsers();
    } catch (error) {
      mainStore.toast.setErrorText("Failed to create user role.");
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'selection' implicitly has an 'any' type... Remove this comment to see the full error message
  const handleEdit = async (selection) => {
    try {
      const payload = getPayload(selection);

      if (
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        (editingRole.permission_set.parent_permission_id ||
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          editingRole.permission_set.id) !== payload.permission_set_id
      ) {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        await mainStore.users.unassignRole(userID, editingRole.id);
      }

      await mainStore.users.assignRoles(userID, payload);
      mainStore.toast.setInfoText("User role has been updated.");
      setEditingRole(null);
      handeRefreshUsers();
    } catch {
      mainStore.toast.setErrorText("Failed to update user role.");
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'role' implicitly has an 'any' type.
  const handleDelete = async (role) => {
    try {
      await mainStore.users.unassignRole(userID, role.id);
      mainStore.toast.setInfoText("User role has been deleted.");
      handeRefreshUsers();
    } catch {
      mainStore.toast.setErrorText("Failed to delete user role.");
    }
  };

  return (
    <section className="user-roles-section">
      <div className="section-header user-roles-section-header">
        <h3>Role</h3>

        {!isDisabled && (
          <Button
            icon="plus"
            size="sm"
            label="Add New Role"
            onClick={() => setShowAddDrawer(true)}
          />
        )}
      </div>

      {roles.length === 0 ? (
        <div className="table-row empty" data-testid="user-roles-empty-message">
          No Roles associated yet. Click &quot;Add New Role&quot; to add one
          here.
        </div>
      ) : (
        <div className="user-roles-list">
          {roles.map((role) => (
            <ViewRolesListRow
              key={role.id}
              role={role}
              workspaces={workspaces}
              modules={modules}
              isDisabled={isDisabled}
              isDeleteDisabled={
                isLoading ||
                roles.length <= 1 ||
                (isAdmin &&
                  isCW &&
                  role.permission_set.permission_type === "company")
              }
              onEdit={() => setEditingRole(role)}
              onDelete={() => handleDelete(role)}
            />
          ))}
        </div>
      )}

      {editingRole && (
        <AssigningDrawer
          permissionSets={permissionSets}
          // @ts-expect-error TS(2339) FIXME: Property 'workspace' does not exist on type 'never... Remove this comment to see the full error message
          workspaces={[editingRole.workspace]}
          modules={modules}
          mode="edit"
          initialValues={{
            // @ts-expect-error TS(2339) FIXME: Property 'permission_set' does not exist on type '... Remove this comment to see the full error message
            permissionSet: editingRole.permission_set,
            // @ts-expect-error TS(2339) FIXME: Property 'workspace' does not exist on type 'never... Remove this comment to see the full error message
            workspaces: [editingRole.workspace],
            modules: getSelectedModules(modules, editingRole),
          }}
          onClose={() => setEditingRole(null)}
          onSubmit={handleEdit}
        />
      )}

      {showAddDrawer && (
        <AssigningDrawer
          permissionSets={permissionSets}
          workspaces={unassignedWorkspaces}
          modules={modules}
          mode="create"
          initialValues={{
            permissionSet: null,
            workspaces: [],
            modules: [],
          }}
          onClose={() => setShowAddDrawer(false)}
          onSubmit={handleCreate}
        />
      )}
    </section>
  );
}

export default observer(ViewRolesSelect);
