import { Avatar, Popover, PopoverContent, PopoverTrigger } from "@themis/ui";
import classNames from "classnames";
import React from "react";
import { PiCheckBold, PiPlusCircle, PiUserCircleDashed } from "react-icons/pi";

import { CompanyUser } from "@/api";

type MultiUserSelectProps = {
  users: CompanyUser[];
  selectedUserIds: number[];
  onSelect: (selectedUserIds: number[]) => void;
};

export default function MultiUserSelect({
  users,
  selectedUserIds,
  onSelect,
}: MultiUserSelectProps) {
  const usersForDropdown = users.filter(
    (user) =>
      user.status === "Active" ||
      user.is_active ||
      selectedUserIds.includes(user.id),
  );

  function handleSelect(userId: number) {
    onSelect(
      selectedUserIds.includes(userId)
        ? selectedUserIds.filter((id) => id !== userId)
        : [...selectedUserIds, userId],
    );
  }

  return (
    <div>
      <Popover>
        <PopoverTrigger asChild>
          <div className="focus-visible:tw-ring-ring tw-mt-1 tw-flex tw-h-9 tw-cursor-pointer tw-items-center tw-space-x-1 tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-bg-neutral-25 tw-px-3 tw-font-sans tw-font-semibold tw-transition-colors focus-visible:tw-border-primary-300 focus-visible:tw-outline-none focus-visible:tw-ring-1">
            {selectedUserIds.length ? (
              selectedUserIds.map((userId) => {
                const user = usersForDropdown.find((u) => u.id === userId);
                return (
                  <Avatar
                    size="md"
                    colorIndex={user?.icon_color_index}
                    key={user?.id}
                    variant={user?.is_active ? undefined : "pending"}
                  >
                    {user?.initials}
                  </Avatar>
                );
              })
            ) : (
              <>
                {[...Array(3)].map((_, i) => (
                  <PiUserCircleDashed key={i} size="1.3rem" />
                ))}
                <PiPlusCircle size="1.3rem" />
              </>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent align="start">
          <div className="tw-max-h-72 tw-w-[240px] tw-overflow-hidden tw-overflow-y-auto tw-py-2">
            {usersForDropdown.map((user) => {
              const isSelected = selectedUserIds.includes(user.id);
              return (
                <div
                  onClick={() => handleSelect(user.id)}
                  key={user.id}
                  className="tw-relative tw-flex tw-cursor-default tw-select-none tw-items-center tw-rounded-sm tw-px-3 tw-py-1.5 tw-font-sans tw-text-sm tw-text-neutral-500 tw-outline-none hover:tw-bg-primary-25 hover:tw-text-primary-300"
                >
                  <span className="tw-flex tw-items-center tw-space-x-2">
                    <Avatar
                      size="md"
                      colorIndex={user.icon_color_index}
                      variant={user.is_active ? undefined : "pending"}
                    >
                      {user.initials}
                    </Avatar>
                    <span className="tw-truncate">{user.full_name}</span>
                  </span>
                  <PiCheckBold
                    className={classNames("tw-ml-auto tw-h-4 tw-w-4", {
                      "tw-opacity-0": !isSelected,
                      "tw-text-secondary-300": isSelected,
                    })}
                  />
                </div>
              );
            })}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
