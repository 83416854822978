import "./errors.scss";

import React from "react";

import { Button, Typography } from "@/components/Elements";
import ThemisLogo from "@/images/logo.svg";

type Props = {
  loadingError: string;
};

function PageErroredReload({ loadingError }: Props) {
  return (
    <div className="page-full-screen-error">
      <a href="/home">
        <img src={ThemisLogo} alt="themis-logo" />
      </a>
      <Typography
        label={loadingError}
        color="generalMidnightDark"
        size="lg"
        weight="semiBold"
      />
      <Button onClick={() => window.location.reload()} label="Reload Page" />
    </div>
  );
}

export default PageErroredReload;
