import { PreviewTable, PreviewTableColumn } from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";
import { generatePath, useRouteMatch } from "react-router-dom";

import { Task } from "@/api";
import { useTasks } from "@/api/queries/tasks";
import { ErrorContainer } from "@/components/ErrorContainer";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";
import { Filter, FilterOperand } from "@/hooks/useFilterSort";

import { TaskStatus } from "../../config/status";
import { useMyTasksFilterSort } from "../../hooks/useMyTasksFilterSort";
import { MY_TASKS_ROUTES } from "../../pages";
import { TasksEmptyState } from "../TasksEmptyState";

type Props = {
  tableColumns: PreviewTableColumn<Task>[];
  responsive?: boolean;
};

function TasksTable({ tableColumns, responsive = false }: Props) {
  const {
    context: { activeWorkspace, companyID, workspaceID },
    users: { user },
  } = useMainStore();

  const isActiveWorkspaceInternal = activeWorkspace?.is_internal;

  const isAssignedToMeRoute = Boolean(
    useRouteMatch(MY_TASKS_ROUTES.assignedToMeView),
  );

  const isCreatedByMeRoute = Boolean(
    useRouteMatch(MY_TASKS_ROUTES.createdByMeView),
  );

  const isAssignedToOthersRoute = Boolean(
    useRouteMatch(MY_TASKS_ROUTES.assignedToOthersView),
  );

  const anyUserIdFilter: Filter = {
    operand: FilterOperand.ANY,
    value: [String(user.id)],
  };

  const notUserIdFilter: Filter = {
    operand: FilterOperand.NOT,
    value: [String(user.id)],
  };

  const { listRequestQueryParams, sorting, applyFilter } = useMyTasksFilterSort(
    {
      initialFilters: {
        status: {
          operand: FilterOperand.ANY,
          value: [
            TaskStatus.not_started.value,
            TaskStatus.in_progress.value,
            TaskStatus.done.value,
          ],
        },
        // Internal WSs show tasks in all WSs, CW show only tasks related to that WS
        ...(isActiveWorkspaceInternal === false && {
          workspace_id: {
            operand: FilterOperand.ANY,
            value: [String(activeWorkspace?.id)],
          },
        }),
        ...(isAssignedToMeRoute && {
          collaborator_ids: anyUserIdFilter,
        }),
        ...(isCreatedByMeRoute && {
          created_by_id: anyUserIdFilter,
        }),
        ...(isAssignedToOthersRoute && {
          created_by_id: anyUserIdFilter,
          assignee_id: notUserIdFilter,
        }),
      },
      initialSorting: {
        columnKey: "due_date",
        direction: "asc",
      },
    },
  );

  const {
    data: tasksData,
    isPending,
    isError,
  } = useTasks(Number(companyID), listRequestQueryParams);

  if (isError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/home", {
            workspace_id: workspaceID!,
          }),
        }}
      >
        Could not load Tasks.
      </ErrorContainer>
    );
  }

  if (!tasksData && isPending) {
    return <Loading loadingLayout="table-no-add-new" />;
  }

  return tasksData.data.length ? (
    <PreviewTable
      responsive={responsive}
      columns={tableColumns}
      rows={tasksData.data}
      sort={sorting}
      onSortChange={(sort) => applyFilter({ sort })}
    />
  ) : (
    <TasksEmptyState />
  );
}

export default observer(TasksTable);
