import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import { MainStore } from "../Main";

export default class AttestationsStore {
  mainStore: MainStore;

  // Observable objects
  list = [];
  attestedDocuments = [];
  attestedDocumentsError = false;
  attestedDocumentsNotFoundError = false;
  statuses = [];
  lastSentDate = null;
  notAttestedUsers = [];
  moduleName = "";

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      list: observable,
      attestedDocuments: observable,
      attestedDocumentsError: observable,
      attestedDocumentsNotFoundError: observable,
      statuses: observable,
      lastSentDate: observable,
      notAttestedUsers: observable,
      moduleName: observable,
      setList: action,
      setAttestedDocuments: action,
      setAttestedDocumentsError: action,
      setAttestedDocumentsNotFoundError: action,
      setStatuses: action,
      setLastSentDate: action,
      setNotAttestedUsers: action,
      setModuleName: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/module_workspaces/:module_workspace_id/:group/attestations
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async index(moduleWorkspaceID, group) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/${group}/attestations`,
        headers: this.mainStore.getHeaders(),
      });

      this.setList(response.data.attestations);
    } catch (error) {
      window.console.log(`"AttestationsStore#index" error ${error}`);
    }
  }

  // GET /api/react/attestations/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'attestationID' implicitly has an 'any' ... Remove this comment to see the full error message
  async show(attestationID) {
    try {
      // Reset state before request
      this.setAttestedDocuments([]);
      this.setAttestedDocumentsError(false);
      this.setAttestedDocumentsNotFoundError(false);

      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/attestations/${attestationID}`,
        headers: this.mainStore.getHeaders(),
        validateStatus: false,
      });
      if (response.status === 404) {
        this.setAttestedDocumentsNotFoundError(true);
      } else {
        this.setModuleName(response.data.module_name);
        this.setAttestedDocuments(response.data.attested_documents);
      }
    } catch (error) {
      this.setAttestedDocumentsError(true);
      window.console.log(`"AttestationsStore#show" error ${error}`);
    }
  }

  // POST /api/react/attestations/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'attestationID' implicitly has an 'any' ... Remove this comment to see the full error message
  async attest(attestationID, documentID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/attestations/${attestationID}/documents/${documentID}/attest`,
        headers: this.mainStore.getHeaders(),
      });

      this.setAttestedDocuments(response.data.attested_documents);
    } catch (error) {
      this.setAttestedDocumentsError(true);
      window.console.log(`"AttestationsStore#attest" error ${error}`);
    }
  }

  // GET /api/react/record_versions/:id/attestations/not_attested
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async fetchNotAttested(recordVersionID) {
    this.setNotAttestedUsers([]);

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/record_versions/${recordVersionID}/attestations/not_attested`,
        headers: this.mainStore.getHeaders(),
      });

      this.setNotAttestedUsers(response.data.users);
    } catch (error) {
      window.console.log(`"AttestationsStore#fetchNotAttested" error ${error}`);
    }
  }

  // GET /api/react/record_versions/:id/attestation_statuses
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async fetchStatuses(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/record_versions/${recordVersionID}/attestation_statuses`,
        headers: this.mainStore.getHeaders(),
      });

      this.setStatuses(response.data.attestation_statuses);
      this.setLastSentDate(response.data.last_sent_date);
    } catch (error) {
      window.console.log(`"AttestationsStore#fetchStatuses" error ${error}`);
    }
  }

  // POST /api/react/record_versions/:id/attestations/resend
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async resend(recordVersionID, attestationListParams) {
    const params = { attestation_list: attestationListParams };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionID}/attestations/resend`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (!response.isAxiosError) {
        this.mainStore.toast.setInfoText(
          "Attestation was successfully resent!",
        );
      }
    } catch (error) {
      window.console.log(`"AttestationsStore#resend" error ${error}`);
    }
  }

  // DELETE /api/react/record_versions/:id/attestations/resend
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID, attestationListParams) {
    const params = { attestation_list: attestationListParams };

    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/record_versions/${recordVersionID}/attestations`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (!response.isAxiosError) {
        this.mainStore.toast.setInfoText("Attestations successfully deleted!");
        this.setNotAttestedUsers(
          this.notAttestedUsers.filter(
            // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
            (user) => !attestationListParams.user_ids.includes(user.id),
          ),
        );
      }
    } catch (error) {
      window.console.log(`"AttestationsStore#resend" error ${error}`);
    }
  }

  // Actions

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setList(value) {
    if (value) {
      this.list = value;
    } else {
      this.list = [];
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAttestedDocuments(value) {
    this.attestedDocuments = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAttestedDocumentsError(value) {
    this.attestedDocumentsError = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAttestedDocumentsNotFoundError(value) {
    this.attestedDocumentsNotFoundError = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setStatuses(value) {
    this.statuses = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setLastSentDate(value) {
    if (value) {
      this.lastSentDate = value;
    } else {
      this.lastSentDate = null;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setNotAttestedUsers(value) {
    this.notAttestedUsers = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setModuleName(value) {
    this.moduleName = value || "";
  }

  // Store Helpers

  cleanup() {
    this.setList([]);
    this.setAttestedDocuments([]);
    this.setAttestedDocumentsError(false);
    this.setAttestedDocumentsNotFoundError(false);
    this.setStatuses([]);
    this.setLastSentDate(null);
    this.setNotAttestedUsers([]);
    this.setModuleName("");
  }
}
