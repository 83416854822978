import React from "react";

import { Icon } from "../../Elements";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetFilter?: (...args: any[]) => any;
};

function ClearFilterField({ resetFilter }: Props) {
  return (
    <div
      className="report-header-item report-clearing-filter"
      onClick={resetFilter}
    >
      <Icon name="close" className="report-header-icon" color="generalError" />
      Clear
    </div>
  );
}

export default ClearFilterField;
