import { observer } from "mobx-react";
import React, { useMemo } from "react";

import { useMainStore } from "@/contexts/Store";
import { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import Select from "../cell-type/Select";

type Props = {
  disabled?: boolean;
  fieldName: string;
  allowDelete?: boolean;
  dataTestId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exposeData?: (...args: any[]) => any;
  isEditable?: boolean;
  isFinraDisputeAmount?: boolean;
  moduleIdentifier?: ModuleIdentifier;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion?: any;
  recordVersionID?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedOptionsParent?: any[];
  title?: string;
  constrained?: boolean;
  hasReadWriteAccess?: boolean;
  selectedIDs?: number[];
};

function ViewSelect({
  disabled,
  recordVersion,
  fieldName,
  title,
  recordVersionID,
  isFinraDisputeAmount,
  allowDelete,
  isEditable,
  dataTestId,
  moduleIdentifier,
  exposeData,
  selectedOptionsParent,
  onDataChange,
  constrained,
  hasReadWriteAccess,
  selectedIDs,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { themisModuleIdentifier } = mainStore.context;
  const value = useMemo(() => {
    return mainStore.avroSchemas.valueForField(fieldName, recordVersion?.data);
  }, [moduleIdentifier, recordVersion?.data]);

  let dollarAmount = null;

  const selectedOptions = useMemo(() => {
    if (isFinraDisputeAmount) {
      const option = value?.option;
      // Override for FINRA Dispute Amount
      dollarAmount = value?.dollar_amount;
      return option ? [option] : [];
    }
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (selectedOptionsParent?.length > 0) {
      return selectedOptionsParent;
    }

    if (selectedIDs && selectedIDs.length > 0) {
      return selectedIDs;
    }
    return mainStore.avroSchemas.valueForField(fieldName, recordVersion?.data);
  }, [selectedOptionsParent, isFinraDisputeAmount, recordVersion?.data]);

  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
  const isMultiSelect = useMemo(() => {
    return mainStore.avroSchemas.isMultiselectField(fieldName);
  });

  const cellError = useMemo(() => {
    return mainStore.recordVersions.errorsForField(
      recordVersion?.id,
      fieldName,
    );
  }, [
    recordVersion?.id,
    fieldName,
    mainStore.recordVersions.cellsErrors,
    mainStore.recordVersions.newRecordVersionErrors,
  ]);

  const hasErrors = Boolean(cellError);

  return (
    <Select
      disabled={disabled}
      fieldName={fieldName}
      columnTitle={title}
      hasErrors={hasErrors}
      errorMessage={cellError?.description}
      isUserEditable={isEditable}
      recordVersionID={recordVersionID}
      selectedOptions={selectedOptions || []}
      isFinraDisputeAmount={isFinraDisputeAmount}
      // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string | nu... Remove this comment to see the full error message
      dollarAmount={dollarAmount}
      width="100%"
      moduleIdentifier={
        themisModuleIdentifier === "control_mapping"
          ? "control_mapping"
          : "complaints"
      }
      isMultiSelect={isMultiSelect}
      allowDelete={allowDelete}
      hasErrorClass="detail-view-has-errors"
      dataTestId={dataTestId}
      exposeData={exposeData}
      onDataChange={onDataChange}
      constrained={constrained}
      hasReadWriteAccess={hasReadWriteAccess}
    />
  );
}

ViewSelect.defaultProps = {
  isEditable: true,
  constrained: false,
  isFinraDisputeAmount: false,
  hasReadWriteAccess: false,
  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  exposeData: (val) => val,
  selectedOptionsParent: [],
};

export default observer(ViewSelect);
