import React from "react";
import { generatePath, useParams } from "react-router-dom";

import { useProjects } from "@/api/queries/projects";
import { Header } from "@/components/Layout/Header";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";
import { FilterOperand } from "@/hooks/useFilterSort";
import mainStore from "@/stores/Main";

import ProjectsTable from "../components/ProjectsTable/ProjectsTable";
import { useProjectsFilterSort } from "../hooks/useProjectsFilterSort";
import { PROJECTS_LIST_PATH } from "./ProjectRoutes";

function ArchivedProjects() {
  const companyId = mainStore.companies.company?.id;
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const { listRequestQueryParams } = useProjectsFilterSort({
    initialFilters: {
      archived_at: {
        operand: FilterOperand.PRESENT,
      },
    },
  });

  const { data: projects, isPending } = useProjects(
    Number(companyId),
    listRequestQueryParams,
  );

  if (isPending) {
    return <Loading loadingLayout="table" />;
  }

  return (
    <PageLayout>
      <Header
        title={
          <div className="tw-flex tw-items-center tw-gap-2">
            Archived Projects
          </div>
        }
        backButtonLinkTo={generatePath(PROJECTS_LIST_PATH, {
          workspace_id,
        })}
      />
      {projects?.data.length ? (
        <ProjectsTable
          projects={projects.data}
          workspace_id={workspace_id}
          companyId={companyId}
        />
      ) : (
        "No archived projects"
      )}
    </PageLayout>
  );
}

export default ArchivedProjects;
