import classNames from "classnames";
import React, { useState } from "react";
import { PiListBullets } from "react-icons/pi";

import listBulletActiveIcon from "../../images/table-image/icon/list-bullet-active.svg";
import listBulletIcon from "../../images/table-image/icon/list-bullet.svg";

type Section = {
  id: number | "unassigned";
  title: string;
};

type SectionSelectionProps = {
  title: string;
  sections: Section[];
  selectedSection: number | "unassigned" | null;
  onSelectSection: (sectionId: number | "unassigned") => void;
  handleContinue: () => void;
  topLevelSection?: boolean;
};

const SectionSelection: React.FC<SectionSelectionProps> = ({
  title,
  sections,
  selectedSection,
  onSelectSection,
  handleContinue,
  topLevelSection,
}) => {
  const [isContinueClicked, setIsContinueClicked] = useState(false);

  const handleButtonClick = () => {
    setIsContinueClicked(true);
    handleContinue();
  };

  return (
    <div className="select-small-wrap file-import-section-select-dropdown">
      <h4>{title}</h4>
      <ul>
        {topLevelSection && (
          <li
            className={classNames({ active: selectedSection === "unassigned" })}
            onClick={() => onSelectSection("unassigned")}
          >
            <img
              src={
                selectedSection === "unassigned"
                  ? listBulletActiveIcon
                  : listBulletIcon
              }
              alt="column-icon"
            />
            Top Level Section
          </li>
        )}
        {sections.length > 0 &&
          sections.map((section) => (
            <li
              className={classNames({ active: section.id === selectedSection })}
              key={section.id}
              onClick={() => onSelectSection(section.id)}
            >
              <PiListBullets className="tw-h-4 tw-w-4 hover:tw-stroke-[#6d71f9]" />
              <span className="option-text">{section.title}</span>
            </li>
          ))}
      </ul>
      <div>
        <button
          className="table-button header-action"
          data-testid="continue-button"
          disabled={!selectedSection || isContinueClicked}
          onClick={handleButtonClick}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default SectionSelection;
