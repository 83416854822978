import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-date-fns";
import classNames from "classnames";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";

import { useMainStore } from "@/contexts/Store";

import { qaTimelineChartData } from "../sharedGraphs/chartHelpers";
import RecordsByMonthHorizontalStackedBar from "../sharedGraphs/records-by-month-horizontal-bar";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

type Props = {
  groupColumnDefault?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectComponents?: any;
};

const QAReportTimeline = ({ groupColumnDefault, selectComponents }: Props) => {
  const mainStore = useMainStore();

  useEffect(() => {
    mainStore.reports.setGroupFieldName(groupColumnDefault);
    mainStore.reports.setFromValue(
      dayjs().subtract(6, "M").format("YYYY-MM-DD"),
    );
    mainStore.reports.setToValue(dayjs().add(6, "M").format("YYYY-MM-DD"));
  }, []);

  const qaTimelineData = useMemo(
    () => mainStore.reports.qaTimeline,
    [
      mainStore.reports.groupFieldName,
      // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'Record<str... Remove this comment to see the full error message
      mainStore.reports.fieldValue?.name,
      mainStore.reports.fromValue,
      mainStore.reports.toValue,
    ],
  );

  const headerClass = classNames("report-dashboard-header no-top-padding");

  return (
    <div>
      <div className={headerClass}>
        <div className="report-dashboard-header-block">{selectComponents}</div>
      </div>
      <div className="report-bar-chart-container">
        {Object.keys(qaTimelineData).map((groupedByOption, index) => {
          const recordVersions = qaTimelineData[groupedByOption];
          const { chartData, options } = qaTimelineChartData(
            recordVersions,
            mainStore.reports.fromValue,
            mainStore.reports.toValue,
          );

          return (
            <RecordsByMonthHorizontalStackedBar
              key={index}
              index={index}
              groupedByField={groupedByOption}
              chartData={chartData}
              chartOptions={options}
              showDatepicker
              chartTitle={`${capitalize(
                mainStore.reports.groupFieldName,
              )}: ${groupedByOption}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default observer(QAReportTimeline);
