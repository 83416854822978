import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  TextInput,
} from "@themis/ui";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Contact } from "@/api";

const formSchema = z.object({
  first_name: z.string().min(1, "First name is required"),
  last_name: z.string().min(1, "Last name is required"),
  middle_name: z.string(),
  phone: z.string(),
  email: z.string().email({ message: "Valid email is required" }),
});

type ContactInfoSchema = z.infer<typeof formSchema>;

function ContactInfoForm({
  onSubmit,
  defaultValues,
}: {
  onSubmit: (values: ContactInfoSchema) => void;
  defaultValues?: Contact;
}) {
  const form = useForm<ContactInfoSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      first_name: defaultValues?.first_name || "",
      middle_name: defaultValues?.middle_name || "",
      last_name: defaultValues?.last_name || "",
      phone: defaultValues?.phone || "",
      email: defaultValues?.email || "",
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.handleSubmit(onSubmit)();
  };

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit}
        className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-3"
      >
        <FormField
          required
          control={form.control}
          name="first_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>First Name</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="middle_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Middle Name</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="last_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last Name</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Phone</FormLabel>
              <FormControl>
                <TextInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="tw-col-span-2 tw-grid tw-grid-cols-subgrid">
          <Button
            className="tw-col-start-2 tw-mt-5 tw-place-self-end"
            type="submit"
          >
            Save Contact
          </Button>
        </div>
      </form>
    </Form>
  );
}

export { ContactInfoForm, type ContactInfoSchema };
