import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";

import { useMainStore } from "@/contexts/Store";

import MultipleSelectField from "../common/MultipleSelectField";
import SingleSelectField from "../common/SingleSelectField";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultGroupedBy?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionFields?: any[];
  showField?: boolean;
};

const QAReportSelectFields = ({
  defaultGroupedBy,
  optionFields,
  showField,
}: Props) => {
  const mainStore = useMainStore();
  const moduleWorkspaceID = mainStore.moduleWorkspaces.list?.find(
    (mw) => mw.themis_module?.identifier === "qa_tests_development",
  )?.id;

  const [groupedByField, setGroupedByField] = useState(defaultGroupedBy);

  useEffect(() => {
    if (!moduleWorkspaceID) {
      return;
    }

    mainStore.reports.getFieldOptions(moduleWorkspaceID);
  }, [mainStore.reports.fieldName, moduleWorkspaceID]);

  const fieldOptions = useMemo(
    () =>
      mainStore.reports.currentFieldOptions?.[
        // @ts-expect-error TS(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
        mainStore.reports.fieldName?.name
      ],
    [mainStore.reports.currentFieldOptions, mainStore.reports.fieldName],
  );

  const fieldColor = mainStore.reports.fieldName.display_name
    ? "generalMidnightDark"
    : "generalDarkGray";
  const fieldDisplayName = mainStore.reports.fieldName.display_name
    ? mainStore.reports.fieldName.display_name
    : "- Select -";
  const isDisabled = mainStore.reports.fieldName.display_name === undefined;
  const fieldValueColor =
    mainStore.reports.fieldValue.length > 0
      ? "generalMidnightDark"
      : "generalDarkGray";
  const fieldValueDisplayName =
    mainStore.reports.fieldValue.length > 0 ? mainStore.reports.fieldValue : [];

  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const handleFieldChange = (field) => {
    mainStore.reports.setFieldName(field);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldOption' implicitly has an 'any' ty... Remove this comment to see the full error message
  const handleFieldOptionsChange = (fieldOption) => {
    if (
      fieldValueDisplayName
        .map((item) => item.display_name)
        .includes(fieldOption.display_name)
    ) {
      const newSelected = mainStore.reports.fieldValue.filter(
        (item) => item.display_name !== fieldOption.display_name,
      );
      mainStore.reports.setFieldValue(newSelected);
    } else {
      mainStore.reports.setFieldValue([
        ...mainStore.reports.fieldValue,
        fieldOption,
      ]);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const handleChangeGroupBy = (field) => {
    setGroupedByField(field);
    mainStore.reports.setGroupFieldName(field.name);
  };

  return (
    <div className="report-dashboard-header-left">
      {showField && (
        <>
          <SingleSelectField
            label="Filter By"
            value={fieldDisplayName}
            color={fieldColor}
            options={optionFields}
            isDisabled={false}
            handleChange={handleFieldChange}
            testId="qa-select-field-name"
          />
          <MultipleSelectField
            label="Value"
            value={isDisabled ? "Please select column" : "All"}
            selectedOptions={fieldValueDisplayName}
            color={fieldValueColor}
            options={fieldOptions}
            isDisabled={isDisabled}
            handleChange={handleFieldOptionsChange}
            testId="qa-select-field-value"
          />
        </>
      )}
      <SingleSelectField
        label="Grouped By"
        value={groupedByField.display_name}
        color="generalMidnightDark"
        options={optionFields}
        isDisabled={false}
        handleChange={handleChangeGroupBy}
        testId="qa-select-grouped-by-field"
      />
    </div>
  );
};

QAReportSelectFields.defaultProps = {
  showField: true,
};

export default observer(QAReportSelectFields);
