import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { COLOR } from "@/config/theme";
import { useMainStore } from "@/contexts/Store";

import warningIcon from "../../../../images/table-image/icon/warning-icon.svg";
import Portal from "./Portal";

type Props = {
  fieldName: string;
  hasErrors?: boolean;
  recordVersionID: number;
  width: number | string;
  disabled?: boolean;
  errorMessage?: string;
  hasErrorClass?: string;
  initialValue?: number | string;
  isPrivate?: boolean;
  locked?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange?: (...args: any[]) => any;
  pinned?: boolean;
  readOnly?: boolean;
};

function LongInputCell({
  disabled,
  errorMessage,
  fieldName,
  hasErrorClass = "has-errors",
  hasErrors,
  initialValue,
  locked,
  onDataChange,
  pinned,
  readOnly,
  recordVersionID,
  width,
  ...props
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  // State
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [coords, setCoords] = useState<{
    left: number;
    top: number;
    width: number;
    color: string;
    background: string;
    height: number;
    isScroll: boolean;
  } | null>(null);

  // Constants
  const textArea = useRef(null);
  const cellRef = useRef<HTMLLIElement>(null);
  const cellTextRef = useRef<HTMLDivElement>(null);
  const lockedCell = locked || isReadOnly || readOnly;

  // Effects
  useEffect(() => {
    setValue(initialValue?.toString().trim() || "");
  }, [initialValue]);

  useEffect(() => {
    if (isActive && textArea.current) {
      // @ts-expect-error TS(2339) FIXME: Property 'focus' does not exist on type 'never'.
      textArea.current.focus();
      // @ts-expect-error TS(2339) FIXME: Property 'selectionStart' does not exist on type '... Remove this comment to see the full error message
      if (typeof textArea.current.selectionStart === "number") {
        // @ts-expect-error TS(2339) FIXME: Property 'selectionStart' does not exist on type '... Remove this comment to see the full error message
        textArea.current.selectionStart = textArea.current.selectionEnd =
          // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type 'never'.
          textArea.current.value.length;
        // @ts-expect-error TS(2339) FIXME: Property 'createTextRange' does not exist on type ... Remove this comment to see the full error message
      } else if (typeof textArea.current.createTextRange !== "undefined") {
        // @ts-expect-error TS(2339) FIXME: Property 'createTextRange' does not exist on type ... Remove this comment to see the full error message
        const range = textArea.current.createTextRange();
        range.collapse(false);
        range.select();
      }
    }
  }, [isActive]);

  useEffect(() => {
    if (!showPopup) {
      setCoords(null);
    }
  }, [showPopup]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Escape") {
      onBlur();
    }
  };

  const onBlur = () => {
    setIsActive(false);
    persistData();
  };

  const handleClick = () => {
    if (!disabled) {
      setIsActive(true);
      setShowPopup(false);
    }
  };

  const persistData = () => {
    if (value === initialValue) {
      return;
    }

    if (typeof onDataChange === "function") {
      onDataChange(fieldName, value);
    } else {
      mainStore.recordVersions.update({
        recordVersionID,
        fieldName,
        value: mainStore.avroSchemas.serializeValue(fieldName, value),
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleShowPopup = () => {
    if (!cellRef.current || !cellTextRef.current) {
      return;
    }

    const isTextOverflowing =
      cellTextRef.current.scrollHeight > cellTextRef.current.clientHeight;

    if (!isTextOverflowing) {
      return;
    }

    const {
      x,
      y,
      width: cellWidth,
      height,
    } = cellRef.current.getBoundingClientRect();

    const color = lockedCell
      ? COLOR.brandingDimViolet
      : COLOR.generalMidnightDark;
    const background = lockedCell
      ? COLOR.brandingHoverViolet
      : COLOR.generalWhite;
    const isScroll = cellTextRef.current.scrollHeight > height - 15;

    setCoords({
      left: x,
      top: y,
      width: cellWidth,
      color,
      background,
      height: cellTextRef.current.scrollHeight + height,
      isScroll,
    });
    setShowPopup(true);
  };

  const liClassNames = classNames("cell long-text-cell", {
    active: isActive,
    [hasErrorClass]: hasErrors,
    "read-only": readOnly,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "table-cell--disabled": isReadOnly,
    "active-outline": isActive,
    pinned,
  });

  const inputPlaceholder = locked ? "N/A" : "Type here";

  // Makes sure data-private attribute is absent if isPrivate is not true
  const isPrivate = props.isPrivate ? "redact" : undefined;

  if (errorMessage && !isActive) {
    return (
      <li style={{ width }} className={liClassNames} onClick={handleClick}>
        <div className="cell-content">
          <img
            src={warningIcon}
            alt="warning-icon"
            style={{ marginRight: 4 }}
          />
          {errorMessage}
        </div>
      </li>
    );
  }

  if (locked || !isActive) {
    return (
      <li
        style={{ width }}
        className={liClassNames}
        data-testid="long-input-cell-inactive"
        ref={cellRef}
        onMouseEnter={handleShowPopup}
        onMouseLeave={() => setShowPopup(false)}
        onClick={handleClick}
      >
        <div className="cell-content">
          <div
            className={classNames({
              "long-cell-text": true,
              placeholder: !value,
            })}
            data-testid="input-cell"
            data-private={isPrivate}
            ref={cellTextRef}
          >
            {value || inputPlaceholder}
          </div>
        </div>
        {showPopup && coords && (
          <Portal>
            <div style={coords} className="long-cell-text-popup">
              <textarea
                value={value}
                style={coords}
                className={classNames("long-cell-textarea long-text-textarea", {
                  "long-cell-textarea-locked": locked,
                  "tw-overflow-y-auto": coords.isScroll,
                  "tw-overflow-hidden": !coords.isScroll,
                })}
              />
            </div>
          </Portal>
        )}
      </li>
    );
  }

  return (
    <li
      style={{ width }}
      className={liClassNames}
      data-testid="long-input-cell-active"
      onBlur={onBlur}
    >
      <div className="cell-content">
        <textarea
          ref={textArea}
          readOnly={readOnly || locked}
          className={classNames("long-cell-textarea", {
            "long-cell-textarea-locked": locked,
          })}
          data-testid="input-cell"
          disabled={disabled}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={inputPlaceholder}
          data-private={isPrivate}
        />
      </div>
    </li>
  );
}

export default LongInputCell;
