import { parseISO } from "date-fns";
import { filter, isEqual } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { REVIEWER, ROUTES } from "@/features/risk-assessment";
import { usePreventUnsavedChanges } from "@/hooks/usePreventUnsavedChanges";

import { QuestionnaireAPI } from "../../../api/legacy/risk-assessment/QuestionnaireApi";
import ViewModuleUsers from "../../../components/dashboard/ViewModuleUsers";
import { Button } from "../../../components/Elements";
import Loading from "../../../components/Loading";
import DashboardContent from "../../../components/shared/DashboardContent";
import DashboardContentWrapper from "../../../components/shared/DashboardContentWrapper";
import DashboardHeader from "../../../components/shared/DashboardHeader";
import { useMainStore } from "../../../contexts/Store";
import {
  areGroupsValidForSubmit,
  copyQuestion,
  mapQuestionsToGroups,
  markQuestionDestroyed,
  prepareQuestionnaireUpsertRequest,
} from "../../../stores/helpers/RiskAssessmentHelpers";
import QuestionnaireBuilder from "../components/Questionnaires/QuestionnaireBuilder/QuestionnaireBuilder";
import {
  Question,
  QuestionGroup,
  Questionnaire,
  ResponderType,
  ReviewerType,
} from "../types/questionnaire";

function EditQuestionnaireTemplatePage() {
  const history = useHistory();
  const { templateId } = useParams<{ templateId: string }>();
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>(null);
  const [activeGroupId, setActiveGroupId] = useState<number>(0);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const originalQuestionnaireRef = useRef<Questionnaire | null>(null);
  const activeGroup = questionnaire?.groups?.find(
    (group) => group.id === activeGroupId,
  );

  useEffect(() => {
    QuestionnaireAPI.get(Number(templateId))
      .then((_questionnaire) => {
        const mappedQuestionnaire: Questionnaire = {
          ..._questionnaire,
          groups: mapQuestionsToGroups(
            _questionnaire.questions,
            _questionnaire.question_groups,
          ),
        };
        _questionnaire.due_date &&
          setDueDate(parseISO(_questionnaire.due_date.toString()));
        originalQuestionnaireRef.current = mappedQuestionnaire;
        setQuestionnaire(mappedQuestionnaire);
        setActiveGroupId(mappedQuestionnaire.groups[0].id);
      })
      .catch((error: unknown) => {
        mainStore.toast.setErrorFromResponse(error);
        history.replace(
          generatePath(ROUTES.QUESTIONNAIRE_TEMPLATE_PATH, {
            workspace_id: Number(workspaceID),
          }),
        );
      });
  }, [templateId]);

  usePreventUnsavedChanges(
    [!isEqual(originalQuestionnaireRef.current, questionnaire)],
    [questionnaire, originalQuestionnaireRef.current],
  );

  const setAssignedIDs = (
    ids: number[],
    assignmentType: ReviewerType | ResponderType,
  ) => {
    if (!activeGroup) {
      return;
    }
    const generalAssignments =
      activeGroup[assignmentType === REVIEWER ? "assignments" : "responders"];
    const assignmentUserIDs = generalAssignments.map((group) => group.user_id);
    ids.forEach((id) => {
      if (!assignmentUserIDs.includes(id)) {
        generalAssignments.push({
          user_id: id,
          assignment_type: assignmentType,
        });
      }
    });
    generalAssignments.forEach((assignment) => {
      if (!ids.includes(assignment.user_id)) {
        assignment._destroy = true;
      }
      if (ids.includes(assignment.user_id) && assignment._destroy) {
        assignment._destroy = false;
      }
    });

    const index = questionnaire.groups.findIndex(
      (group) => group.id === activeGroupId,
    );
    const newQuestionnaire = { ...questionnaire };
    newQuestionnaire.groups[index] = activeGroup;
    setQuestionnaire(newQuestionnaire);
  };

  const onSave = async (): Promise<void> => {
    try {
      if (!questionnaire) {
        return;
      }

      if (!questionnaire.name.trim()) {
        return mainStore.toast.setErrorText("Name required");
      }

      if (!areGroupsValidForSubmit(questionnaire.groups)) {
        return mainStore.toast.setErrorText(
          "Names for each question and question choice are required",
        );
      }

      setIsSaving(true);
      const editedQuestionnaire = await QuestionnaireAPI.edit(
        questionnaire.id,
        prepareQuestionnaireUpsertRequest(
          questionnaire.name,
          questionnaire.risk_methodology_id,
          questionnaire.groups,
          questionnaire.is_internal,
          true,
          false,
          false,
          dueDate,
        ),
      );
      const mappedQuestionnaire = {
        ...editedQuestionnaire,
        groups: mapQuestionsToGroups(
          editedQuestionnaire.questions,
          editedQuestionnaire.question_groups,
        ),
      };
      originalQuestionnaireRef.current = mappedQuestionnaire;
      setQuestionnaire(mappedQuestionnaire);
      if (
        !mappedQuestionnaire.groups.find((group) => group.id === activeGroupId)
      ) {
        setActiveGroupId(mappedQuestionnaire.groups.at(-1)?.id || 0);
      }
      mainStore.toast.setInfoText("Template saved!");
    } catch (error: unknown) {
      mainStore.toast.setErrorFromResponse(error);
    } finally {
      setIsSaving(false);
    }
  };

  const onNameChange = (name: string): void => {
    setQuestionnaire((previousQuestionnaire) => ({
      ...previousQuestionnaire,
      name,
    }));
  };

  const onRiskMethodologyChange = (risk_methodology_id: number): void => {
    setQuestionnaire({
      ...questionnaire,
      risk_methodology_id,
    });
  };

  const onQuestionChange = (changedQuestion: Question): void => {
    setQuestionnaire((previousQuestionnaire) => ({
      ...previousQuestionnaire,
      groups: previousQuestionnaire.groups.map((group) => {
        return group.id === activeGroupId
          ? {
              ...group,
              questions: group.questions.map((question) => {
                return question.id === changedQuestion.id
                  ? changedQuestion
                  : question;
              }),
            }
          : group;
      }),
    }));
  };

  const onQuestionReorder = (questions: Question[]) => {
    setQuestionnaire((previousQuestionnaire) => ({
      ...previousQuestionnaire,
      groups: previousQuestionnaire.groups.map((group) => {
        return group.id === activeGroupId
          ? {
              ...group,
              questions,
            }
          : group;
      }),
    }));
  };

  const onQuestionGroupChange = (newGroupId: number, question: Question) => {
    setQuestionnaire((previousQuestionnaire) => ({
      ...previousQuestionnaire,
      groups: previousQuestionnaire.groups.map((group) => {
        if (group.id === activeGroupId) {
          return {
            ...group,
            questions: filter(group.questions, (q) => q.id !== question.id),
          };
        } else if (group.id === newGroupId) {
          return {
            ...group,
            questions: [...group.questions, question],
          };
        }
        return group;
      }),
    }));
  };

  const onQuestionCopy = (questionToCopy: Question): void => {
    setQuestionnaire({
      ...questionnaire,
      groups: questionnaire.groups.map((group) => {
        return group.id === activeGroupId
          ? {
              ...group,
              questions: group.questions.flatMap((question) => {
                return question.id === questionToCopy.id
                  ? [questionToCopy, copyQuestion(questionToCopy)]
                  : question;
              }),
            }
          : group;
      }),
    });
  };

  const onQuestionDelete = (questionToDelete: Question): void => {
    setQuestionnaire({
      ...questionnaire,
      // @ts-expect-error TS(2322) FIXME: Type '{ questions: (Question<QuestionType> | null)... Remove this comment to see the full error message
      groups: questionnaire.groups.map((group) => {
        return group.id === activeGroupId
          ? {
              ...group,
              questions: group.questions
                .map((question) => {
                  if (question.id !== questionToDelete.id) {
                    return question;
                  }

                  return question.isNew
                    ? null
                    : markQuestionDestroyed(question);
                })
                .filter((question) => Boolean(question)),
            }
          : group;
      }),
    });
  };

  const onQuestionAdd = (question: Question) => {
    setQuestionnaire({
      ...questionnaire,
      groups: questionnaire.groups.map((group) => {
        return group.id === activeGroupId
          ? {
              ...group,
              questions: group.questions.concat(structuredClone(question)),
            }
          : group;
      }),
    });
  };

  const onGroupAdd = (group: QuestionGroup) => {
    setQuestionnaire((currQuestionnaire) => {
      return {
        ...currQuestionnaire,
        groups: currQuestionnaire.groups.concat(group),
      };
    });
  };

  const onGroupDelete = (groupId: number) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type '(currQuestionnaire: Questionnair... Remove this comment to see the full error message
    setQuestionnaire((currQuestionnaire) => {
      return {
        ...currQuestionnaire,
        groups: currQuestionnaire.groups
          .map((group) => {
            if (group.id !== groupId) {
              return group;
            }

            return group.isNew
              ? null
              : {
                  ...group,
                  _destroy: true,
                };
          })
          .filter((group) => Boolean(group)),
      };
    });
  };

  const onGroupNameChange = (groupId: number, groupName: string) => {
    setQuestionnaire({
      ...questionnaire,
      groups: questionnaire.groups.map((group) => {
        return group.id === groupId
          ? {
              ...group,
              name: groupName,
            }
          : group;
      }),
    });
  };

  const activeGroups = useMemo(() => {
    return questionnaire?.groups.filter((group) => !group._destroy);
  }, [questionnaire?.groups]);

  return (
    <DashboardContent>
      <DashboardHeader
        title="Edit Questionnaire Template"
        onBackClick={() =>
          history.push(
            generatePath(ROUTES.QUESTIONNAIRE_TEMPLATE_PATH, {
              workspace_id: Number(workspaceID),
            }),
          )
        }
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={
          <Button
            label={isSaving ? "Saving Template" : "Save Template"}
            onClick={onSave}
            disabled={isSaving}
          />
        }
      />
      <DashboardContentWrapper>
        {/* temporary scroll solution wrapping in .table-wrap */}
        <div className="table-wrap">
          {!questionnaire && <Loading loadingLayout="table" />}
          {questionnaire && (
            <QuestionnaireBuilder
              groups={activeGroups}
              activeGroupId={activeGroupId}
              setActiveGroupId={setActiveGroupId}
              onGroupAdd={onGroupAdd}
              onGroupDelete={onGroupDelete}
              onGroupNameChange={onGroupNameChange}
              onQuestionAdd={onQuestionAdd}
              onQuestionCopy={onQuestionCopy}
              onQuestionChange={onQuestionChange}
              onQuestionDelete={onQuestionDelete}
              onQuestionReorder={onQuestionReorder}
              onQuestionGroupChange={onQuestionGroupChange}
              name={questionnaire.name}
              onNameChange={onNameChange}
              riskMethodologyId={questionnaire.risk_methodology_id}
              onRiskMethodologyChange={onRiskMethodologyChange}
              setAssignedIDs={setAssignedIDs}
              isInternal={questionnaire.is_internal}
              dueDate={dueDate}
              setDueDate={setDueDate}
            />
          )}
        </div>
      </DashboardContentWrapper>
    </DashboardContent>
  );
}

export default observer(EditQuestionnaireTemplatePage);
