import React from "react";
import {
  Bar,
  ComposedChart,
  LabelList,
  Line,
  ResponsiveContainer,
  XAxis,
} from "recharts";

const labelWidth = 32;
const labelHeight = 24;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RoundedBar = (props: any) => {
  const { x, width, y, height, payload } = props;
  const barXStart = x + width / 2 - labelWidth / 2;
  return (
    <g>
      <rect
        x={barXStart}
        y={y}
        rx={4}
        ry={4}
        width={24}
        height={height}
        fill={payload.color}
      />
      <rect
        x={barXStart}
        y={y + height / 2}
        width={24}
        height={height / 2}
        fill={payload.color}
      />
    </g>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomAxisTick = (props: any) => {
  const { x, y, payload, isMultiXLines } = props;
  const name: string = payload?.value?.toString() || "";
  const words = isMultiXLines ? name.split("-") : [name];

  return (
    <text
      x={x}
      y={y + 8}
      width={124}
      textAnchor="middle"
      fill="#353549"
      fontFamily="Poppins"
      fontSize={10}
      fontWeight={500}
    >
      {words.map((line: string, index: number) => (
        <tspan x={x} dy={index * 14} key={index}>
          {line}
        </tspan>
      ))}
    </text>
  );
};

export type BarChartProps = {
  wireKey?: "none" | string;
  data: {
    name: string;
    value: number | string;
    color: string;
  }[];
  isMultiXLines?: boolean;
};

export const BarChart = ({
  wireKey = "none",
  data,
  isMultiXLines,
}: BarChartProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const FloatingValueLabel = (props: any) => {
    const { x, y, width, value } = props;
    const labelXStart = x + width / 2 - labelWidth / 2 - 4;

    const barValue = data.find((d) => d.name === value)?.value;
    return (
      <g>
        <rect
          x={labelXStart}
          y={y - labelWidth}
          rx={4}
          ry={4}
          width={labelWidth}
          height={labelHeight}
          fill="#F6F6F9"
        />
        <text
          x={x + width / 2 - 4}
          y={y - 15}
          fill="#353549"
          textAnchor="middle"
          fontFamily="Poppins"
          fontWeight={600}
          fontSize={12}
        >
          {barValue}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={data}
        margin={{
          top: 40,
        }}
      >
        <XAxis
          interval={0}
          dataKey="name"
          tickLine={false}
          tick={<CustomAxisTick isMultiXLines={isMultiXLines} />}
          axisLine={{
            stroke: "#E4E4EB",
            strokeWidth: 1,
          }}
        />
        {wireKey !== "none" && (
          <Line
            type="linear"
            dataKey={wireKey}
            stroke="#313181"
            strokeWidth={2}
            dot={false}
          />
        )}
        <Bar dataKey="value" minPointSize={0} shape={RoundedBar}>
          <LabelList dataKey="name" content={FloatingValueLabel} />
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  );
};
