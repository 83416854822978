import React from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { recordTypeForThemisModuleIdentifier } from "../../helpers/nameForThemisModuleIdentifier";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeny: (...args: any[]) => any;
  recordVersionID: number;
};

function PolicyDeleteFlow({ onDeny, recordVersionID }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const history = useHistory();
  const { themisModuleIdentifier, workspaceID } = mainStore.context;
  const recordName =
    // @ts-expect-error TS(2345) FIXME: Argument of type '"audits" | "new_product_approval... Remove this comment to see the full error message
    recordTypeForThemisModuleIdentifier(themisModuleIdentifier).singular ||
    "Policy";

  async function onConfirm() {
    // Delete RecordVersion
    const { active_workspace: workspace } = mainStore.users.user;
    if (workspace && workspace.id) {
      const currentRecordVersion = mainStore.recordVersions.list.find(
        (recordVersion) => recordVersion.id === recordVersionID,
      );

      try {
        const store =
          themisModuleIdentifier === "policy"
            ? mainStore.policies
            : mainStore.procedures;
        await store.delete(recordVersionID);
        const previousVersion = mainStore.recordVersions.list.find(
          (recordVersion) =>
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            recordVersion.record_id === currentRecordVersion.record.id &&
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            recordVersion.version === currentRecordVersion.version - 1,
        );
        if (
          previousVersion &&
          (previousVersion.table_name === "Finalized" ||
            previousVersion.table_name === "ProceduresFinalized")
        ) {
          mainStore.toast.setInfoText(
            "Draft has been deleted. Reverting to previously finalized version. (Please archive finalized record to remove it completely).",
          );
        } else {
          mainStore.toast.setText(`${recordName} has been deleted!`);
        }

        if (themisModuleIdentifier === "policy") {
          history.push(`/workspaces/${workspaceID}/modules/policy/drafts`);
        } else if (themisModuleIdentifier === "procedures") {
          history.push(`/workspaces/${workspaceID}/modules/procedures/drafts`);
        }
      } catch {
        mainStore.toast.setErrorText(
          `An unexpected error occured while attempting to delete this ${recordName}.`,
        );
      }
    }
  }

  return (
    <div className="table-dropdown success-dropdown">
      <div>
        <h4>Delete {recordName}</h4>
        <p>
          Are you sure you want to delete this {recordName}? Actions are not
          reversible.
        </p>
        <div className="confirmation">
          <button onClick={onConfirm} data-testid="policy-dropdown-delete-yes">
            Yes
          </button>
          <button onClick={onDeny}>No</button>
        </div>
      </div>
    </div>
  );
}

export default PolicyDeleteFlow;
