import React from "react";

import { Icon } from "../../Elements";

type LibraryTableColumnProps = {
  title: string;
};

const LibraryTableColumn = (props: LibraryTableColumnProps) => (
  <div className="rr-library-table-column" data-testid="rr-library-column">
    <Icon name="book1" color="generalDarkGray" size="de" />
    {props.title}
  </div>
);

export default LibraryTableColumn;
