import classNames from "classnames";
import React, { useMemo } from "react";

import {
  THEMIS_BUTTON_THEMES,
  THEMIS_TYPOGRAPHY_WEIGHTS,
} from "@/config/theme";

import Icon, { IconName } from "../Icon";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  icon?: IconName;
  iconPosition?: string;
  label: React.ReactNode;
  size?: "sm" | "de";
  theme?: (typeof THEMIS_BUTTON_THEMES)[number];
  fontWeight?: (typeof THEMIS_TYPOGRAPHY_WEIGHTS)[number];
};

const Button: React.FC<ButtonProps> = ({
  active,
  className = "",
  disabled,
  icon,
  iconPosition = "left",
  label,
  size = "de",
  theme = "primary",
  fontWeight,
  ...rest
}) => {
  const iconColor = useMemo(() => {
    if (disabled) {
      return "generalDarkGray";
    }

    if (active) {
      return "generalWhite";
    }

    if (theme === "tertiary") {
      return "generalMidnightDark";
    }

    return "generalWhite";
  }, [active, disabled, theme]);

  const iconComponent = icon && <Icon name={icon} color={iconColor} />;

  return (
    <button
      className={classNames(
        "themisButton",
        `themisButton__${theme}`,
        `themisButton__${size}`,
        !disabled && active && "themisButton--active",
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      {iconPosition === "left" ? (
        <>
          {iconComponent}
          {label}
        </>
      ) : (
        <>
          {label}
          {iconComponent}
        </>
      )}
    </button>
  );
};

Button.displayName = "Button";

export default Button;
