import { PreviewTable } from "@themis/ui";
import React from "react";
import { useRouteMatch } from "react-router-dom";

import { Contact } from "@/api";

import { CONTACTS_COLUMNS } from "../../config/contactsTable";

function AccountContacts({ contacts }: { contacts: Contact[] }) {
  const { url } = useRouteMatch();

  return (
    <PreviewTable
      responsive
      addRow={{
        text: "Add Contact",
        linkTo: (location) => ({
          pathname: `${url}/new`,
          state: { from: location.pathname },
        }),
      }}
      columns={CONTACTS_COLUMNS}
      rows={contacts}
    />
  );
}

export { AccountContacts };
