import "./flex-dashboard-content.scss";

import React, { HTMLProps, PropsWithChildren } from "react";

type Props = PropsWithChildren & HTMLProps<HTMLDivElement>;

export default function FlexDashboardContent({ children, ...rest }: Props) {
  return (
    <div className="flex-dashboard-content" {...rest}>
      {children}
    </div>
  );
}
